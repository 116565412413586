import { Flip } from '../../app.animations';
import { SQLService } from '../../services/sql.service';
import { NewJob } from '../../models/job';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { SelectAddressPage } from '../../addresses/select-address/select-address.page';
import { SelectTaxPage } from '../../taxes/select-tax/select-tax.page';
import { SelectJobPage } from '../../jobs/select-job/select-job.page';
import { SelectSalaryPage } from '../../salary/select-salary/select-salary.page';
import { SelectDeductionPage } from '../../deductions/select-deduction/select-deduction.page';
import { SelectSchedulePage } from '../../schedule/select-schedule/select-schedule.page';
import { SelectEmployeePage } from '../../employees/select-employee/select-employee.page';
import { SelectPayeePage } from '../../payees/select-payee/select-payee.page';
@Component({
  selector: 'ng-cheques-new-job',
  templateUrl: './new-job.component.html',
  styleUrls: ['./new-job.component.styl'],
  animations: [ Flip ]
})
export class NewJobComponent implements OnInit {
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public location: Location,
    private params: NavParams,
    public sql: SQLService,
    public modalController: ModalController
  ) {
    this.multiple = params.get('select_multiple');
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.open();
    });
  }
  _c = 0;
  job = new NewJob();
  id: number;
  multiple: boolean;
  async add_employee() {
    const m = await this.modalController.create({
      component: SelectEmployeePage, 
      componentProps: {select_multiple: true}
    });
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data){
      if(a.data == -1){
        this.sql.hapticsImpactLight();
        this.delete_employee();
      } else {
        this.sql.hapticsImpactMedium();
        this.job.employee_ids=a.data;
        this.get_employees();
        this._c=1;
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_employee(employee) {
    this.router.navigate(['folder','Employee', employee.employee_id]); 
  }
  delete_employee() {
    this.job.employee_ids=null;
    this.job.employees=null;
    // this.sql.set( 'Jobs', 'job_id', this.job.job_id, 'employee_ids', null);
  }
  delete_job() {
    if(!this.job.job_id){
      this.end();
    } else if(confirm(this.sql.word.Delete_Job_Confirmation)) {
      this.sql.delete('Jobs','job_id',this.job.job_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  get_employees() {
    console.log('this.job.employee_ids',this.job.employee_ids);
    if(typeof this.job.employee_ids === 'string'){
      this.job.employee_ids=this.job.employee_ids.split(',');
    } else if (typeof this.job.employee_ids === 'number') {
      this.job.employee_ids=[this.job.employee_ids];
    }
    this.job.employees=[];
    for(let a of this.job.employee_ids) {
      this.sql.view('Employees','employee_id',a,(data)=>{
        for(let d of data)if(true)this.job.employees.push(d);
      });
    }
  }

  async add_salary() {
    const m = await this.modalController.create({component: SelectSalaryPage});
    await m.present();
    let a = await m.onDidDismiss();
    if (a.data) {
      if(a.data == -1){
        this.sql.hapticsImpactLight();
        this.delete_salary();
      } else {
        this.sql.hapticsImpactMedium();
        this.job.salaries=[a.data];
        this.job.salary_ids=a.data.salary_id;
        this._c=1;  
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_salary(salary) {
    this.router.navigate(['folder','Salary', salary.salary_id]); 
  }
  delete_salary() {
    this.job.salaries = null;
    this.job.salary_ids = null;
    // this.sql.set( 'Jobs', 'job_id', this.job.job_id, 'salary_ids', null);
  }

  async add_payees() {
    const m = await this.modalController.create({component: SelectPayeePage, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data){
      if(a.data==-1){
        this.sql.hapticsImpactLight();
        this.delete_payee();
      } else {
        this.sql.hapticsImpactMedium();
        this.job.payee_ids = [a.data];
        this.get_payees();
        this._c=1;  
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_payee(employee) {
    this.router.navigate(['folder','Employee', employee.employee_id]); 
  }
  delete_payee() {
    this.job.payee_ids = null;
    this.job.payees=null;
    // this.sql.set( 'Jobs', 'job_id', this.job.job_id, 'payee_ids', null);
  }
  get_payees() {
    if(typeof this.job.payee_ids === 'string'){
      this.job.payee_ids=this.job.payee_ids.split(',');
    } else if (typeof this.job.payee_ids === 'number') {
      this.job.payee_ids=[this.job.payee_ids];
    }
    this.job.payees=[];
    for(let a of this.job.payee_ids) {
      this.sql.view('Payees','payee_id',a,(data)=>{
        for(let d of data)if(true)this.job.payees.push(d);
      });
    }
  }

  async add_address() {
    const m = await this.modalController.create({component: SelectAddressPage, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      if(a.data == -1) {
        this.sql.hapticsImpactLight();
        this.job.address_ids = null;
      } else {
        this.sql.hapticsImpactMedium();
        this.job.address_ids = a.data;
        this.get_address();
        this._c=1;
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_address(address) {
    this.router.navigate(['folder','Address', address.address_id]); 
  }
  delete_address() {
    this.job.addresses = null;
    // this.sql.set( 'Jobs', 'job_id', this.job.job_id, 'address_ids', null);
  }
  get_address() {
    if(typeof this.job.address_ids === 'string'){
      this.job.address_ids=this.job.address_ids.split(',');
    } else if (typeof this.job.address_ids === 'number') {
      this.job.address_ids=[this.job.address_ids];
    }
    this.job.addresses=[];
    for(let a of this.job.address_ids) {
      this.sql.view('Addresses','address_id',a,(data)=>{
        for(let d of data)if(true)this.job.addresses.push(d);
      });
    }
  }

  async add_taxes() {
    const m = await this.modalController.create({component: SelectTaxPage});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data){
      if (a.data == -1) {
        this.sql.hapticsImpactLight();
        this.job.tax_ids = null;
        this.job.taxes=null;
      } else {
        this.sql.hapticsImpactMedium();
        this.job.tax_ids = [a.data];
        this.get_taxes();
        this._c=1;
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_taxes(tax) {
    this.router.navigate(['folder','Tax', tax.job_id]);
  }
  delete_taxes() {
    this.job.taxes = null;
    // this.sql.set( 'Jobs', 'job_id', this.job.job_id, 'tax_ids', null);
  }
  get_taxes() {
    if(typeof this.job.tax_ids === 'string'){
      this.job.tax_ids=this.job.tax_ids.split(',');
    } else if (typeof this.job.tax_ids === 'number') {
      this.job.tax_ids=[this.job.tax_ids];
    }
    this.job.taxes=[];
    for(let a of this.job.tax_ids) {
      this.sql.view('Taxes','tax_id',a,(data)=>{
        for(let d of data)if(true)this.job.taxes.push(d);
      });
    }
  }

  async add_schedule() {
    const m = await this.modalController.create({component: SelectSchedulePage});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data){
      if(a.data==-1){
        this.sql.hapticsImpactLight();
        this.job.schedule_ids = null;
        this.job.schedules=null;
      } else {
        this.sql.hapticsImpactMedium();
        this.job.schedule_ids = [a.data];
        this._c=1;
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_schedule(schedule_) {
    this.router.navigate(['folder','Schedule', schedule_.schedule_id]); 
  }
  delete_schedule() {
    this.job.schedules = null;
    // this.sql.set( 'Jobs', 'job_id', this.job.job_id, 'schedule_ids', null);
  }
  get_schedule() {
    if(typeof this.job.schedule_ids === 'string'){
      this.job.schedule_ids=this.job.schedule_ids.split(',');
    } else if (typeof this.job.schedule_ids === 'number') {
      this.job.schedule_ids=[this.job.schedule_ids];
    }
    this.job.schedules=[];
    for(let a of this.job.schedule_ids) {
      this.sql.view('Schedules','schedule_id',a,(data)=>{
        for(let d of data)if(true)this.job.schedules.push(d);
      });
    }
  }

  async add_deduction() {
    const m = await this.modalController.create({component: SelectDeductionPage});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1) {
        this.job.deduction_ids = null;
        this.job.deductions = null;
      } else {
        this.sql.hapticsImpactMedium();
        this.job.deductions = a.data;
        this._c=1;
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_deduction(deduction) {
    this.router.navigate(['folder','Deduction', deduction.deduction_id]);
  }
  delete_deduction() {
    this.job.deductions = null; 
    // this.sql.set( 'Jobs', 'job_id', this.job.job_id, 'deductions', null);
  }
  get_deduction() {
    if(typeof this.job.deduction_ids === 'string'){
      this.job.deduction_ids=this.job.deduction_ids.split(',');
    } else if (typeof this.job.deduction_ids === 'number') {
      this.job.deduction_ids=[this.job.deduction_ids];
    }
    this.job.deductions=[];
    for(let a of this.job.deduction_ids) {
      this.sql.view('Deductions','deduction_id',a,(data)=>{
        for(let d of data)if(true)this.job.deductions.push(d);
      });
    }
  }
  update() { this.sql.update("Jobs",this.job); }
  end(id?) {
    this.sql.get_temporary('page',(page)=>{
      if (this._c>0) {
        if (confirm(this.sql.word.Discard_Confirmation)) {
          setTimeout( () => { this.job = new NewJob(); }, 500);
          this.router.navigate(['folder','Jobs']);
        }
      } else {
        if(page) {
          this.router.navigate(['folder',page.location,page.id,'Address',id]);
          setTimeout( () => { this.sql.remove_temporary('page'); }, 600);
        } else {
          this.router.navigate(['folder','Jobs']);
        }
      }
    });
  }
  save(cb?) {
    for(let s of ['salary','employee','payee','address','taxes','schedule','deduction'])
      if(this.job[s+'_ids']&&typeof this.job[s+'_ids']=='object')
        this.job[s+'_ids'] = this.job[s+'_ids'].join(',');
    this.sql.add("Jobs",this.job,(insertId)=>{
      if(!insertId) return;
      this.job = new NewJob();
      this._c=0;
      this.end(insertId);
    });
  } 
  set_temp() {
    if (this.job.job_id) {
      this.sql.set_temporary('page',{location:'Job',id:this.job.job_id});
    } else {
      this.save((id) =>{
        if(!id)return;
        this.job.job_id=id;
        this.sql.set_temporary('page',{location:'Job',id:id});
      });
    }
  }
  get_the_job() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida) return;
    this.sql.view('Jobs','job_id',ida,(e)=>{
      var f=Object.entries(e[0]); let n = new NewJob();
      for(let t of f)n[t[0]]=t[1];
      if(n.salaries&&n.salary_ids>0)
        this.sql.view('Salaries','salary_id',n.salary_ids,(a)=>{n.salaries=a;});
      this.job = n;
      this.get_employees();
      this.get_payees();
    });
  }
  open() {
    this.job.start_date = new Date(Date.now()).toISOString();
    this.job.indefinite_payroll_enabled=true;
    this.job.enabled=true;
    this.get_the_job();
  }
  ngOnInit() {
    // this.open();
  }
}