export class NewCompanies {
	constructor(
		public company_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public name?: string,
		public image?: string,
		public description?: string,
		public enabled?: boolean,
		public address_id?: number,
		public addresses?: any,
		public date?: any,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Companies = '\
	CREATE TABLE IF NOT EXISTS "Companies" (\
		company_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		address_id INTEGER DEFAULT 0,\
		name TEXT,\
		image TEXT,\
		description TEXT,\
		enabled INTEGER DEFAULT 0,\
		date TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';