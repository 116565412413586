export class NewLeave {
	constructor(
		public leave_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public deduction_id?: number,
		public employee_id?: number,
		public employee_ids?: any,
		public employees?: any,
		public payees?: any,
		public payee_id?: any,
		public payee_ids?: any,
		public title?: string,
		public description?: string,
		public active?: boolean,
		public paid?: boolean,
		public paid_amount?: number,
		public overtime_amount?: number,
		public overtime_percentage?: number,
		public always_enabled?: boolean,
		public enabled?: boolean,
		public currency?: string,
		public reason?: string,
		public note?: string,
		public paytype?: string,
		public start_date?: any,
		public start_time?: any,
		public end_date?: any,
		public end_time?: any,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Leaves = '\
	CREATE TABLE IF NOT EXISTS "Leaves" (\
		leave_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		deduction_id INTEGER,\
		employee_id INTEGER,\
		payee_id INTEGER,\
		title TEXT,\
		description TEXT,\
		beginning INTEGER,\
		ending INTEGER,\
		active INTEGER DEFAULT 0,\
		paid INTEGER DEFAULT 0,\
		paid_amount INTEGER DEFAULT 0,\
		overtime_amount INTEGER DEFAULT 0,\
		overtime_percentage INTEGER DEFAULT 0,\
		employee_ids TEXT,\
		employees TEXT,\
		payees TEXT,\
		payee_ids TEXT,\
		always_enabled INTEGER DEFAULT 0,\
		enabled INTEGER DEFAULT 0,\
		currency TEXT,\
		reason TEXT,\
		note TEXT,\
		paytype TEXT,\
		start_date TIMESTAMP,\
		start_time TIMESTAMP,\
		end_date TIMESTAMP,\
		end_time TIMESTAMP,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';