import { SQLService } from '../../services/sql.service';
import { Flip } from '../../app.animations';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { Location } from '@angular/common';
@Component({
  selector: 'ng-cheques-select-tax',
  templateUrl: './select-tax.page.html',
  styleUrls: ['./select-tax.page.styl'],
  animations: [ Flip ]
})
export class SelectTaxPage implements OnInit {
  constructor(
    private router: Router,
    public sql: SQLService,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    private params: NavParams,
    private location: Location
  ) {
    var s = params.get('selected');
    if(s)this.selected = s.split(',');
    this.multiple = params.get('select_multiple');
    // this.router.events.subscribe((eve) => {
    //   if(eve instanceof NavigationEnd) this.get_taxes();
    // });
  }
  count = -1;
  taxes = [];
  selected = [];
  multiple: boolean;
  filter_paid = false;
  sort_by_money = false;
  output = new EventEmitter();
  ngOnInit() {
   this.get_taxes();
  }
  get_taxes(event?) {
    this.sql.fetch('Taxes',(e)=>{
      this.taxes=e;
      this.count=e.length;
      setTimeout(()=>{if (event) event.target.complete();},1234);
    });
  }
  add() {
    this.router.navigate(['folder','Tax','New']);
    this.navCtrl.dismiss();
  }
  off() {
    // this.location.back();
    if(this.multiple){
      this.done_multiple();
     } else {
      this.navCtrl.dismiss();
     }
  }
  select_none() {this.navCtrl.dismiss(-1);}
  select(e) {
    if(this.multiple){
      this.toggle_item(e);
    } else {
      this.navCtrl.dismiss(e.tax_id);
    }
  }
  done_multiple() {
    this.navCtrl.dismiss(this.selected.join(','));
  }
  toggle_item(item) {
    if( this.selected.includes(item.tax_id) ) {
      this.deselect_item(item);
    } else {
      this.select_item(item);
    }
  }
  select_item(item) {
    console.log(item.tax_id);
    this.selected.push(item.tax_id);
  }
  deselect_item(item) {
    var new_selection=[];
    for(var i = 0; i < this.selected.length; ++i){
      if(this.selected[i]!=item.tax_id){
        new_selection.push(this.selected[i]);
      }
    }
    this.selected = new_selection;
  }
  select_all() {
    var new_selection=[];
    for (var i = 0; i < this.taxes.length; ++i) {
      new_selection.push(this.taxes[i].tax_id);
    }
    this.selected=new_selection;
  }
  deselect_all() {
   this.selected = []; 
  }
}