import { SelectEmployeePage } from '../../employees/select-employee/select-employee.page';
import { SelectJobPage } from '../../jobs/select-job/select-job.page';
import { SQLService } from '../../services/sql.service';
import { TimesheetService } from '../../services/timesheet.service';
import { Flip, fade } from '../../app.animations';
import { NewTimesheet } from '../../models/timesheet';
import { NewEmployee } from '../../models/employee';
import { ModalController, Platform } from '@ionic/angular';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { MenuController } from '@ionic/angular';
@Component({
  selector: 'ng-cheques-submit-timesheet',
  animations: [ Flip, fade ],
  templateUrl: './submit-timesheet.component.html',
  styleUrls: ['./submit-timesheet.component.styl']
})
export class SubmitTimesheetComponent implements OnInit {
  constructor(
    private router: Router,
    public sql: SQLService,
    private ts: TimesheetService,
    private location: Location,
    private menu: MenuController,
    private route: ActivatedRoute,
    private modalController: ModalController
  ) { 
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) {
        this.show='window';
      }
    });
  }
  _c=0;
  show='window';
  timesheets = [];
  newemployee = new NewEmployee();
  timesheet = new NewTimesheet();
  @Input('uuid') uuid:string;
  @Input('job') job:string;
  @Input('employee') employee:string;
  @Input('start') start_date:string;
  @Input('end') end_date:string;
  @Input('title') title:string;
  @Input('notes') notes:string;
  end() {
    if (this._c>0) {
      if (confirm(this.sql.word.Discard_Confirmation)) {
        this.newemployee = new NewEmployee();
        this.timesheet = new NewTimesheet();
        this.router.navigate(['folder','Timesheets']);
      }
    } else {
      this.router.navigate(['folder','Timesheets']);
    }
  }
  async add_employee() {
    const m = await this.modalController.create({component: SelectEmployeePage, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    this.timesheet.employees = a.data;
    if(a.data)this.sql.hapticsImpactMedium();
  }
  edit_employee(employee) {
    this.router.navigate(['folder','Employee', employee.employee_id]); 
  }
  delete_employee() {
    this.timesheet.employees = null;
    this.sql.query('UPDATE Timesheets WHERE timesheet_id = '+this.timesheet.timesheet_id+' SET employees = NULL');
  }
  async add_job() {
    const m = await this.modalController.create({component: SelectJobPage});
    await m.present();
    let a = await m.onDidDismiss();
    this.timesheet.jobs = a.data;
    if(a.data)this.sql.hapticsImpactMedium();
  }
  edit_job(job) {
    this.router.navigate(['folder','Job', job.job_id]);
  }
  delete_job() {
    this.timesheet.jobs = null;
    this.sql.query('UPDATE Timesheets WHERE timesheet_id = '+this.timesheet.timesheet_id+' SET jobs = NULL');
  }
  get_timesheet() {
    let ida = this.route.snapshot.paramMap.get("id");
    if (!ida) return;
    this.sql.view('Timesheets','timesheet_id',ida,(e)=>{
      var f=Object.entries(e[0]); let n = new NewTimesheet();
      for(let t of f)n[t[0]]=t[1]; this.timesheet=n;
    });
  }
  save() {
    this.sql.add("Timesheets",this.timesheet);
    this.timesheet = new NewTimesheet();
    this._c=0;
    this.show='done';
  }
  view_employee() {
 
  }
  send_details( ) {

  }
  exit() {
    this.router.navigate(['folder','Dashboard']); 
  }
  done() {

  }
  open() {
    this.timesheet.uuid = this.uuid ? this.uuid : this.route.snapshot.paramMap.get("uuid");
    this.timesheet.jobs = this.job ? this.job : this.route.snapshot.paramMap.get("job");
    this.timesheet.employees = this.employee ? this.employee : this.route.snapshot.paramMap.get("employee");
    this.timesheet.start_date = this.start_date ? this.start_date : this.route.snapshot.paramMap.get("start_date");
    this.timesheet.end_date = this.end_date ? this.end_date : this.route.snapshot.paramMap.get("end_date");
    this.timesheet.name = this.title ? this.title : this.route.snapshot.paramMap.get("title");
    this.timesheet.description = this.notes ? this.notes : this.route.snapshot.paramMap.get("notes");
    if(!this.timesheet.start_date)this.timesheet.start_date=new Date(Date.now()).toISOString();
    this.timesheet.enabled = true;
    if(this.timesheet.timer_active){
      this.timesheet.end_date = new Date(Date.now()).toISOString();
      this.timesheet.end_time = new Date(Date.now()).toISOString();
    }
  }
  ngOnInit() {
    this.open()
    this.menu.close('first');
    this.get_timesheet();
  }
}