import { Component, OnInit, OnDestroy } from '@angular/core';
import { SQLService } from '../services/sql.service';
import { PopoverController} from '@ionic/angular';
import { Router, NavigationEnd } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { PayeeMenuComponent } from './payee-menu/payee-menu.component';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { Browser } from '@capacitor/browser';
@Component({
  selector: 'ng-cheques-payees',
  templateUrl: './payees.component.html',
  styleUrls: ['./payees.component.styl']
})
export class PayeesComponent implements OnInit {
  constructor(
    private router: Router,
    public popoverController: PopoverController,
    private callNumber: CallNumber,
    private navCtrl: ModalController,
    public sql: SQLService
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_payees();
    });
  }
  count=-1;
  search:string;
  multiple:boolean;
  payees= [];
  selected= [];
  ngOnInit() {this.get_payees();}
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:PayeeMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {
        enabled: item.enabled,
        phone: item.phone,
        skype: item.skype
      },
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='enable')this.enable(item);
      if(data=='disable')this.disable(item);
      if(data=='call')this.phonecall(item);
      if(data=='skypecall')this.skypecall(item);
      if(data=='delete')this.delete(item);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  phonecall(item) { if(item.phone)this.callNumber.callNumber(item.phone, true); }
  async skypecall(item) { if(item.skype)await Browser.open({ url:'skype:'+item.skype+'?call' }); }
  get_payees(event?) {
    this.sql.fetch('Payees',(e)=>{
      if(e)this.payees=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  import() {
    this.router.navigate(['folder','Import','New']);
  }
  edit(payee, item?) {
    if(this.multiple){
      this.toggle_item(payee);
    } else {
      this.router.navigate(['folder','Payee',payee.payee_id]);
    }
    if(item)item.closeSlidingItems();
  }
  disable(payee,i?){
    this.sql.set('Payees','payee_id',payee.payee_id,'enabled',0,(res)=>{
      payee.enabled = false;
      if(i)i.closeSlidingItems();
    });
  }
  enable(payee,i?){
    this.sql.set('Payees','payee_id',payee.payee_id,'enabled',1,(res)=>{
      payee.enabled = true;
      if(i)i.closeSlidingItems();
    });
  }
  delete(payee, item?) {
    if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('Payees','payee_id',payee.payee_id);
      this.get_payees();
    } else { if(item)item.closeSlidingItems(); }
  }
  select(e) {
    if(this.multiple){
      this.toggle_item(e);
    } else {
      this.navCtrl.dismiss(e.payee_id);
    }
  }
  done_multiple() {
    this.navCtrl.dismiss(this.selected.join(','));
  }
  toggle_item(item) {
    if( this.selected.includes(item.payee_id) ) {
      this.deselect_item(item);
    } else {
      this.select_item(item);
    }
  }
  select_none() {this.navCtrl.dismiss(-1);}
  select_item(item) {
    console.log(item.payee_id);
    this.selected.push(item.payee_id);
  }
  deselect_item(item) {
    var new_selection=[];
    for(var i = 0; i < this.selected.length; ++i){
      if(this.selected[i]!=item.payee_id){
        new_selection.push(this.selected[i]);
      }
    }
    this.selected = new_selection;
  }
  select_all() {
    var new_selection=[];
    for (var i = 0; i < this.payees.length; ++i) {
      new_selection.push(this.payees[i].payee_id);
    }
    this.selected=new_selection;
  }
  delete_selected() {
     if(!this.selected || this.selected.length==0){
      alert(this.sql.word.Select_Multiple_Cheques_Prompt);
    } else {
      if(confirm(this.sql.word.Payee_Multiple_Delete_Confirmation)) {
        var i = 0;
        for(let c of this.selected){
          this.sql.delete('Payees','payee_id',c,(Done)=>{i++;
            if(i==this.selected.length){
              this.get_payees();
              this.selected = [];
            }
          });
        }
      }
    }
  }
  deselect_all() {
   this.selected = []; 
  }
  off() {
    // this.location.back();
    if(this.multiple){
      this.done_multiple();
     } else {
      this.navCtrl.dismiss();
     }
  }
  add() {
    this.sql.count('Payees',(c)=>{
      if(this.sql.Trial && this.sql.Trial_Payees!=-1 && c>=this.sql.Trial_Payees) {
        this.sql.show_purhchase_modal();
      } else {
        this.router.navigate(['folder','Payee', 'New']);
      }
    });
  }
  end() {this.router.navigate(['folder','Dashboard']);}
  filter(e) {this.search=e.target.value;}
}