import { environment } from '../../../../environments/environment';
import { SelectAddressPage } from '../../../addresses/select-address/select-address.page';
import { SelectEmployeePage } from '../../../employees/select-employee/select-employee.page';
import { SQLService } from '../../../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { Location } from '@angular/common';
@Component({
  selector: 'app-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.styl'],
})
export class SelectCompanyComponent implements OnInit {
  constructor(
    private navCtrl: ModalController,
    public location: Location,
    public sql: SQLService,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_companies();
    });
  }
  count = -1; 
  company = [];
  search:string;
  default_postage:string;
  filter(e) { this.search=e.target.value; }
  go(p) {this.router.navigate(['folder','Company',p.postage_id]);}
  add() {this.router.navigate(['folder','Company','New']);}
  end() {this.location.back()}
  get_companies(event?) {
    this.sql.b_fetch('Companies',(e)=>{
      this.company=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  select_none() {
    this.navCtrl.dismiss(-1);
  }
  edit(company, item?) {
    this.router.navigate(['folder','Company',company.company_id]);
  }
  delete(company, item) {
    if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.b_delete('Companies','company_id',company.company_id);
      this.get_companies();
    } else { item.closeSlidingItems(); }
  }
  ngOnInit() {
    this.get_companies();
  }
}
