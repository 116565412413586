export class NewIncome {
	constructor(
		public income_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public employee_id?: number,
		public title?: string,
		public description?: string,
		public amount?: string,
		public currency?: string,
		public date?: any,
		public repeat?: any,
		public key?: any,
		public data?: any,
		public fiat?: any,
		public alwaysEnabled?: boolean,
		public start_date?: any,
		public start_time?: any,
		public end_date?: any,
		public end_time?: any,
		public enabled?: boolean,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Incomes = '\
	CREATE TABLE IF NOT EXISTS "Incomes" (\
		income_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER,\
		mod_id INTEGER,\
		employee_id INTEGER,\
		title TEXT,\
		description TEXT,\
		amount TEXT,\
		currency TEXT,\
		date TIMESTAMP,\
		repeat TEXT,\
		key TEXT,\
		data TEXT,\
		fiat TEXT,\
		alwaysEnabled INTEGER,\
		start_date TIMESTAMP,\
		start_time TIMESTAMP,\
		end_date TIMESTAMP,\
		end_time TIMESTAMP,\
		enabled INTEGER,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';