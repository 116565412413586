import * as Currencies from '../../../../environments/currencies';
import * as Symbols from '../../../../environments/micr';
import { Component, OnInit, OnDestroy, OnChanges, Output, EventEmitter, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SQLService } from '../../../services/sql.service';
@Component({
  selector: 'app-single-cheque',
  templateUrl: './single-cheque.component.html',
  styleUrls: ['./single-cheque.component.scss'],
})
export class SingleChequeComponent implements OnInit,OnChanges,OnDestroy {
  constructor(
      private sql: SQLService, 
      private santitize: DomSanitizer
    ) { 
  }
  @Input('RenderMICR') RenderMICR = false;
  @Input('ImageMICR') ImageMICR = true;
  @Input('color') color: string;
  @Input('font') font_family: string;
  @Input('cheque') cheque: any;
  @Input('micr') micr: any;
  @Input('secret') secret = '';
  @Input('scale') scale=0.9;
  @Input('fold_line') fold_line: boolean;
  @Input('rotate') rotate: boolean;
  @Input('monochrome') monochrome: boolean;
  @Input('gradients') gradients: boolean;
  @Input('borders') borders: boolean;
  @Input('docugard') docugard: boolean;
  @Input('fontsize') fontsize: number;
  @Input('memosize') memosize: number;
  @Input('bold') bold: boolean;
  @Input('italic') italic: boolean;
  @Input('voidcheck') voidcheck: boolean;
  @Input('template') template: string;
  @Input('micr_top') micr_top: string;
  @Input('micr_right') micr_right: string;
  @Input('micr_bottom') micr_bottom: string;
  @Input('micr_left') micr_left: string;
  @Input('design') design={};
  @Input('margin_top') top;
  @Input('layers') layers: any;
  @Input('translations') translations = this.sql.word;
  @Input('margin_bottom') bottom;
  @Input('signature_line') signature_line=true;
  @Input('designer') designer=false;
  @Input('border') border = false;
  @Input('fiat') fiat = false;
  @Input('pttof') pay_to_the_order_of_line = true;
  @Input('cheque_background') cheque_background: any;
  @Input('background_position') background_position: any;
  @Input('show_cheque_number') show_cheque_number: boolean = true;
  @Input('show_cheque_date') show_cheque_date: boolean = true;
  @Input('extra_line_style') extra_line_style: string = 'star';//''//'star'//'line'
  @Input('show_micr') show_micr: boolean = true;
  @Input('cheque_background_image_repeat') cheque_background_image_repeat = false;
  @Input('cheque_background_image_cover') cheque_background_image_cover = true;
  @Input('cheque_text_color') cheque_text_color: string;
  @Input('cheque_background_image') cheque_background_image: any;
  @Input('cheque_logo_image') cheque_logo_image: any;
  @Input('cheque_header_image') cheque_header_image: any;
  @Input('cheque_bank_image') cheque_bank_image: any;
  @Output('loaded') loaded = new EventEmitter<boolean>();
  m: any;
  images = [];
  selected = [];
  currencies: any;
  selector_top: string;
  selector_left: string;
  selector_bottom: string;
  selector_right: string;
  selector_height: string;
  selector_width: string;
  name_line_width: string;
  qr_code_url: string;
  qr_code_url_r: string;
  custom_stylesheet: any;
  numbers_background: any;
  show_cheque_background_image: boolean;
  show_cheque_background: boolean;
  show_cheque_logo_image: boolean;
  show_cheque_header_image: boolean;
  show_bank_image: boolean;
  clicker(e, element) {
    if(this.sql.verbose)console.log('Design','Element', element, 'mousedown', e);
    if(this.selected.length>-1) {
      this.selected.push(element);
    } else {
      this.selected[0]=element;
    }
    var target = document.querySelector('.'+element);
    this.selector_top = target['style'].top;
    this.selector_left = target['style'].left;
    this.selector_bottom = target['style'].bottom;
    this.selector_right = target['style'].right;
    this.selector_height = target['offsetHeight']+'px';
    this.selector_width = target['offsetWidth']+'px';
  }
  set_font(F=this.font_family) {
    if(this.sql.more_verbose)console.log('Rendering Font',F);
    if(F){
      this.custom_stylesheet = this.santitize.bypassSecurityTrustHtml("*,div,span{ font-family:'"+F+"' !important; }");
    } else {
      this.custom_stylesheet = '';     
    }
  }
  imagesLoaded(img) {
    this.images.push(img);
    if(this.ImageMICR && this.RenderMICR)if(this.images.length==3) this.loaded.emit(true);
    if(!this.ImageMICR||!this.RenderMICR)if(this.images.length==2) this.loaded.emit(true);
  }
  noper(e, element) { if(this.sql.verbose)console.log('mouseup', e, element); }
  deselect_all() { this.selected = []; }
  add_fontsize(a){ return Number(Number(a)+Number(this.fontsize)); }
  reset_imgs() {
    this.show_cheque_background=false;
    this.cheque_background_image=false;
    this.show_cheque_background_image=false;
    this.cheque_logo_image=false;
    this.show_cheque_logo_image=false;
    this.cheque_header_image=false;
    this.show_cheque_header_image=false;
    this.cheque_bank_image=false;
    this.show_bank_image=false;
    this.cheque_background=false;
    this.cheque_background_image=false;
    this.cheque_logo_image=false;
    this.cheque_header_image=false;
    this.design["date"]["top"]='50px';
    this.design["date"]["right"]='30px';
    // this.design["date"]["top"]='42.5px';
    // this.design["date"]["right"]='81px';
    this.design['date']['individual']=false;
    this.design['date']['alternative']=false;
    this.design['date']['alternative_2']=false;
    this.design["date_label"]["display"]=false;
    this.design["payline"]["prefix"]=false;
    this.design["payline"]["borders"]=false;
    this.design["payline"]["top"]=false;
    this.design["payline"]["left"]=false;
    this.design["payline"]["right"]=false;
    this.design["payline"]["bottom"]=false;
    this.design["bank_account_holder"]["top"]=false;
    this.design["bank_account_holder"]["left"]=false;
    this.design["printed_id"]["top"]=false;
    this.design["printed_id"]["right"]=false;
    this.design["payment_name"]["left"]='0mm';
    this.design["payment_name"]["top"]=false;
    this.design["payment_name"]["bottom"]=false;
    this.design["payment_name"]["right"]=false;
    this.design["bank_name"]["top"]=false;
    this.design["bank_name"]["left"]=false;
    this.design["bank_name"]["right"]=false;
    this.design["bank_name"]["bottom"]=false;
    this.design["bank_address"]["top"]=false;
    this.design["bank_address"]["left"]=false;
    this.design["bank_address"]["right"]=false;
    this.design["bank_address"]["bottom"]=false;
    this.design["bank_address_2"]["top"]=false;
    this.design["bank_address_2"]["left"]=false;
    this.design["bank_address_2"]["right"]=false;
    this.design["bank_address_2"]["bottom"]=false;
    this.design["bank_address_3"]["top"]=false;
    this.design["bank_address_3"]["left"]=false;
    this.design["bank_address_3"]["right"]=false;
    this.design["bank_address_3"]["bottom"]=false;
    this.design["written_amount"]["top"]=false;
    this.design["written_amount"]["right"]=false;
    this.design["written_amount"]["bottom"]=false;
    this.design["written_amount"]["left"]=false;
    this.design["written_amount_line"]["width"]=false;
    this.design["written_amount_line"]["top"]=false;
    this.design["written_amount_line"]["right"]=false;
    this.design["written_amount_line"]["bottom"]=false;
    this.design["written_amount_line"]["left"]=false;
    this.design["written_amount_line2"]["top"]=false;
    this.design["written_amount_line2"]["right"]=false;
    this.design["written_amount_line2"]["bottom"]=false;
    this.design["written_amount_line2"]["left"]=false;
    this.design["amount_number"]["borders"]=true;
    this.design["amount_number"]["background"]=true;
    this.design["amount_number"]["top"]=false;
    this.design["amount_number"]["right"]=false;
    this.design["amount_number"]["bottom"]=false;
    this.design["amount_number"]["left"]=false;
    this.design["amounts"]["fontsize"]=false;
    this.design["amount_number"]["left"]=false;
    this.design["memo"]["top"]=false;
    this.design["memo"]["right"]=false;
    this.design["memo"]["bottom"]=false;
    this.design["memo"]["left"]=false;
    this.design["memo"]["width"]=false;
    this.design["signature"]["width"]=false;
    this.design["signature"]["top"]=false;
    this.design["signature"]["right"]=false;
    this.design["signature"]["bottom"]=false;
    this.design["signature"]["left"]=false;
    this.design["micr"]["top"]=false;
    this.design["micr"]["right"]=false;
    this.design["micr"]["bottom"]=false;
    this.design["micr"]["left"]=false;
    this.design["address_data"]["class"]='';
    this.design["multiline_written_amount_chars"]=0;
    this.design["written_amount"]["index"]=true;
    this.design["written_amount_line"]["borders"]=true;
    this.design["written_amount_line"]["overflow"]=true;
    this.design["bank_address"]["grouping"]=false;
    this.design["bank_address_2"]["grouping"]=false;
    this.design["bank_address_3"]["grouping"]=false;
    this.design["signature"]["image"]["top"]=false;
    this.design["signature"]["image"]["right"]=false;
    this.design["signature"]["image"]["bottom"]=false;
    this.design["signature"]["image"]["left"]=false;
    this.design["signature"]["info"]["top"]=false;
    this.design["signature"]["info"]["right"]=false;
    this.design["signature"]["info"]["bottom"]=false;
    this.design["signature"]["info"]["left"]=false;
    this.design["signature"]["desc"]["top"]=false;
    this.design["signature"]["desc"]["right"]=false;
    this.design["signature"]["desc"]["bottom"]=false;
    this.design["signature"]["desc"]["left"]=false;
    this.design["public_image"]["zindex"]='1';
    this.design['public_image']['QRcolorDark']="#000000";
    this.design['public_image']['QRcolorLight']="#FFFFFF";
    this.design['public_image']['QRCodeMargin']="1";
    this.design["key_image"]["zindex"]='1';
    this.design['key_image']['QRcolorDark']="#000000";
    this.design['key_image']['QRcolorLight']="#FFFFFF";
    this.design['key_image']['QRCodeMargin']="1";
    this.design["amounts"]["symbol"]=true;
    this.design["memo"]["label"]=true;
    this.design["memo"]["border"]=true;
    this.design["signature"]["border"]=true;
    this.design["signature"]["label"]=true;
    this.design["micr"]["background"]=true;
  }
  ngOnDestroy() {
    this.reset_imgs();
  }
  ngOnInit() {
    this.reset_imgs();
    this.m = Symbols.MICR;
    this.currencies = Currencies;
    this.name_line_width=Number( document.querySelector('.payline_name')['offsetWidth']+8)+'px';
    if(this.design&&this.design['top'])this.top=this.design['top']; 
    if(this.design&&this.design['bottom'])this.bottom=this.design['bottom'];
    this.check_borders();
    if(this.cheque_background&&this.cheque_background!="0"&&this.cheque_background!="undefined"&&this.cheque_background!=undefined&&this.cheque_background!=0){
      this.cheque_background=this.santitize.bypassSecurityTrustStyle(this.cheque_background&&this.cheque_background!='0'?"url('"+this.cheque_background+"')":"none");
      this.show_cheque_background=true;
    } else {
      this.show_cheque_background=false;
    }
    if(this.cheque_background_image&&this.cheque_background_image!="0"&&this.cheque_background_image!=0){
      this.cheque_background_image=this.santitize.bypassSecurityTrustStyle(this.cheque_background_image&&this.cheque_background_image!='0'?"url('"+this.cheque_background_image+"')":"none");
      this.monochrome=false;
      this.show_cheque_background_image=true;
    } else {
      this.show_cheque_background_image=false;
    }
    if(this.cheque_header_image&&this.cheque_header_image!="0"&&this.cheque_header_image!=0){
      this.cheque_header_image=this.santitize.bypassSecurityTrustStyle(this.cheque_header_image&&this.cheque_header_image!='0'?"url('"+this.cheque_header_image+"')":"none");
      this.show_cheque_header_image=true;
    } else {
      this.cheque_header_image=false;
      this.show_cheque_header_image=false;
    }
    if(this.cheque_logo_image&&this.cheque_logo_image!="0"&&this.cheque_logo_image!=0){
      this.cheque_logo_image=this.santitize.bypassSecurityTrustStyle(this.cheque_logo_image&&this.cheque_logo_image!='0'?"url('"+this.cheque_logo_image+"')":"none");
      this.show_cheque_logo_image=true;
    } else {
      this.cheque_logo_image=false;
      this.show_cheque_logo_image=false;
    }
    if(this.cheque.cheque_logo_image&&this.cheque.logo_image!="0"&&this.cheque.logo_image!=0){
      this.cheque.cheque_logo_image=this.santitize.bypassSecurityTrustStyle(this.cheque.cheque_logo_image&&this.cheque.cheque_logo_image!='0'?"url('"+this.cheque.cheque_logo_image+"')":"none");
      this.cheque_logo_image=true;
    } else {
      this.cheque_logo_image=false;
      this.show_cheque_logo_image=false;
    }
    if(this.cheque_bank_image&&this.cheque_bank_image!="0"&&this.cheque_bank_image!=0){
      this.cheque_bank_image=this.santitize.bypassSecurityTrustStyle(this.cheque_bank_image&&this.cheque_bank_image!='0'?"url('"+this.cheque_bank_image+"')":"none");
      this.show_bank_image=true;
    } else {
      this.cheque_bank_image=false;
      this.show_bank_image=false;
    }
    if(this.micr_left){this.micr_right='auto';} else if (this.micr_right) { this.micr_left='auto'; }
    if(this.micr_bottom){this.micr_top='auto';} else if (this.micr_top) { this.micr_bottom='auto'; }
    if(this.isNumeric(this.micr_top)||this.micr_top=='0')this.micr_top=this.micr_top+'mm';
    if(this.isNumeric(this.micr_right)||this.micr_right=='0')this.micr_right=this.micr_right+'mm';
    if(this.isNumeric(this.micr_bottom)||this.micr_bottom=='0')this.micr_bottom=Number(this.micr_bottom+(((this.design&&this.design['docugard'])||this.docugard)?-1.5:1.5))+'mm';
    if(this.isNumeric(this.micr_left)||this.micr_left=='0')this.micr_left=Number(Number(this.micr_left)+16)+'px';
    this.background();
  }
  isNumeric(value) {return /^\d+$/.test(value);}
  background(C=this.color?this.color:"#ffefe2") {
    if(this.design['monochrome']||this.monochrome||this.show_cheque_background_image){ //||this.show_cheque_background
      return;
    } else {
      if(C=='brown')C='#FFEFE2';
      if(C=='blue')C='#97D6DB';
      if(C=='black')C='WHITE';
      if(C=='green')C='#D8EFDD';
      if(C=='gold')C='#FBE6BB';
      if(C=='purple')C='#FFEAF1';
      if(C=='pink')C='rgb(250, 232, 255)';
      if(C=='red')C='#E9CDBF';
      if(this.color=='transparent') {
        if(this.sql.more_verbose)console.log('Background Color: Transparent');
        this.cheque_background=this.santitize.bypassSecurityTrustStyle("transparent !important");
        this.numbers_background=this.santitize.bypassSecurityTrustStyle("transparent !important");
      } else {
        if(this.gradients) {
          if(this.sql.more_verbose)console.log('Background Gradients');
          this.loaded.emit();
          this.color=C;
          if(this.sql.verbose)console.log('Rendering Background to Color:',C);
          var c = "linear-gradient(180deg, white 0%, "+this.color+" 10%)";
          this.show_cheque_background=true;
          if(c)this.cheque_background= this.santitize.bypassSecurityTrustStyle(c);
          var b = "linear-gradient(to bottom, "+this.color+" ,white 17.8%)";
          if(b)this.numbers_background = this.santitize.bypassSecurityTrustStyle(b);
          if(this.sql.more_verbose)console.log('Done Rendering Background Colors', this.cheque_background, this.numbers_background);  
        } else {
          this.color = C;
          if(this.sql.more_verbose)console.log('Background Color', C);
          this.show_cheque_background=true;
          this.cheque_background= this.santitize.bypassSecurityTrustStyle(C);
          this.numbers_background = this.santitize.bypassSecurityTrustStyle('white');
        }
      }
    }
  }
  ngOnChanges(ngOnChanges) {
    if(ngOnChanges.font_family)this.set_font();
    if(this.micr_left) {
      this.micr_right='auto';
    } else if (this.micr_right) {
      this.micr_left='auto'; 
    }
    if(this.micr_bottom) {
      this.micr_top='auto';
    } else if (this.micr_top) {
      this.micr_bottom='auto'; 
    }
    if(ngOnChanges.cheque_background){
      if(this.cheque_background&&this.cheque_background!="0"&&this.cheque_background!="undefined"&&this.cheque_background!=undefined&&this.cheque_background!=0){
        this.cheque_background=this.santitize.bypassSecurityTrustStyle(this.cheque_background&&this.cheque_background!='0'?"url('"+this.cheque_background+"')":"none");
        this.show_cheque_background=true;
      } else {
        this.show_cheque_background=false;
      }
    }
    if(ngOnChanges.cheque_background_image&&this.cheque_background_image&&this.cheque_background_image!="0"&&this.cheque_background_image!=0){
      this.check_borders();
      this.cheque_background_image=this.santitize.bypassSecurityTrustStyle(this.cheque_background_image&&this.cheque_background_image!='0'?"url('"+this.cheque_background_image+"')":"none");
      this.monochrome=false;
      this.show_cheque_background_image=true;
    } else {
      this.cheque_background_image=false;
      this.show_cheque_background_image=false;
    }
    if(ngOnChanges.design||ngOnChanges.template||ngOnChanges.cheque_logo_image)if(this.cheque_logo_image&&this.cheque_logo_image!="0"&&this.cheque_logo_image!=0){
      this.cheque_logo_image=this.santitize.bypassSecurityTrustStyle(this.cheque_logo_image&&this.cheque_logo_image!="0"?"url('"+this.cheque_logo_image+"')":"none");
      this.show_cheque_logo_image=true; 
    } else {
      this.cheque_logo_image=false;
      this.show_cheque_logo_image=false;
    }
    if(ngOnChanges.design||ngOnChanges.template||ngOnChanges.cheque_header_image)if(this.cheque_header_image&&this.cheque_header_image!="0"&&this.cheque_header_image!=0){
      this.cheque_header_image=this.santitize.bypassSecurityTrustStyle(this.cheque_header_image&&this.cheque_header_image!="0"?"url('"+this.cheque_header_image+"')":"none");
      this.show_cheque_header_image=true;
    } else {
      this.cheque_header_image=false;
      this.show_cheque_header_image=false;
    }
    if(ngOnChanges.design||ngOnChanges.template||ngOnChanges.cheque_bank_image)if(this.cheque_bank_image&&this.cheque_bank_image!="0"&&this.cheque_bank_image!=0){
      this.cheque_bank_image=this.santitize.bypassSecurityTrustStyle(this.cheque_bank_image&&this.cheque_bank_image!="0"?"url('"+this.cheque_bank_image+"')":"none");
      this.show_bank_image=true;
    } else {
      this.cheque_bank_image=false;
      this.show_bank_image=false;
    }
    if(ngOnChanges.micr_top&&this.isNumeric(this.micr_top)||this.micr_top=='0')this.micr_top=this.micr_top+'mm';
    if(ngOnChanges.micr_right&&this.isNumeric(this.micr_right)||this.micr_right=='0')this.micr_right=this.micr_right+'mm';
    if(ngOnChanges.cheque&&this.cheque.bank_image)this.cheque.bank_image=this.santitize.bypassSecurityTrustStyle(this.cheque.bank_image?"url('"+this.cheque.bank_image+"')":"none");
    if(ngOnChanges.cheque&&this.cheque.cheque_logo_image)this.cheque.cheque_logo_image=this.santitize.bypassSecurityTrustStyle(this.cheque.cheque_logo_image?"url('"+this.cheque.cheque_logo_image+"')":"none");
    if(ngOnChanges.cheque&&this.cheque.cheque_header_image)this.cheque.cheque_header_image=this.santitize.bypassSecurityTrustStyle(this.cheque.cheque_header_image?"url('"+this.cheque.cheque_header_image+"')":"none");
    if(ngOnChanges.micr_bottom&&this.isNumeric(this.micr_bottom)||this.micr_bottom=='0')this.micr_bottom=Number(this.micr_bottom+(((this.design&&this.design['docugard'])||this.docugard)?-1.5:1.5))+'mm';
    if(ngOnChanges.micr_left&&this.isNumeric(this.micr_left)||this.micr_left=='0')this.micr_left=Number(Number(this.micr_left)+16)+'px';
    if(ngOnChanges.color||ngOnChanges.cheque_background||ngOnChanges.gradients)this.background();
  }
  check_borders() {
    if(this.show_cheque_background) {

    } else if (this.template=="Cheque_Table"||this.template=="Posted_Cheque"||this.template=="SingleCheque"||this.template=="Three_Cheques"||this.template=="DocuGard_Mailer_Top"||this.template=="DocuGard_Mailer_Middle"||this.template=="DocuGard_Mailer_Bottom"||this.template=="DocuGard_Table_Top"||this.template=="DocuGard_Table_Middle"||this.template=="DocuGard_Table_Bottom"||this.template=="DocuGard_Cheque_Top"||this.template=="DocuGard_Cheque_Middle"||this.template=="DocuGard_Cheque_Bottom"||this.template=="DocuGard_Three_Cheques") {
      this.design["payline"]["borders"]=true;
      this.design["written_amount_line"]["borders"]=true;
      this.design["amount_number"]["borders"]=true;
    }
  }
}