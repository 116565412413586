import { SQLService } from '../../../services/sql.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'ng-cheques-moderator-qr',
  templateUrl: './new-qr-moderator.component.html',
  styleUrls: ['./new-qr-moderator.component.styl']
})
export class NewQRModeratorComponent implements OnInit {
  constructor(
    public sql: SQLService,
    private navCtrl: ModalController,
    private router: Router) {
  }
  list:string;
  end(e) {
    if (this.navCtrl) this.navCtrl.dismiss(e);
  }
  ngOnInit() {
  }
}