import { environment } from '../../../../environments/environment';
import { SQLService } from '../../../services/sql.service';
import { NewBackup } from '../../../models/backup';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'ng-cheques-new-ftp-backup',
  templateUrl: './new-ftp-backup.component.html',
  styleUrls: ['./new-ftp-backup.component.styl']
})
export class NewFTPBackupComponent implements OnInit {
  constructor(
    public sql: SQLService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {}
  _c=0;
  ftp = new NewBackup();
  currencies = Object.keys(environment.currencies);
  save() {
    this.sql.add("Backups",this.ftp);
    this.ftp = new NewBackup();
    this._c=0;
    this.end();
  }
  end() {
   if (this._c>0) {
      if (confirm(this.sql.word.Discard_Confirmation)) {
        this.ftp = new NewBackup();
        this.router.navigate(['folder','Settings','Backup']);
      }
    } else {
      this.router.navigate(['folder','Settings','Backup']);
    }
  }
  get_ftp_backup() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida) return;
    this.sql.view('Backups','backup_id',ida,(e)=>{
      var f = Object.entries(e[0]); let n=new NewBackup();
      for( let t of f) n[t[0] ]=t[1]; this.ftp = n;
    });
  }
  ngOnInit() {
    this.ftp.secure=false;
    this.ftp.auto=true;
    this.get_ftp_backup();
  }
}