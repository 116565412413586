import { ChequeComponent } from './cheque/cheque.component';
import { AddressesComponent } from './addresses/addresses.component';
import { GenerateChequeComponent } from './cheque/generate-cheque/generate-cheque.component';
import { GenerateMultipleChequesComponent } from './cheque/generate-cheque/multiple-cheques/multiple-cheques.component';
import { PrintChequesComponent } from './cheque/print-cheques/print-cheques.component';
import { PrintAddressComponent } from './addresses/print-address/print-address.component';
import { ViewChequeComponent } from './cheque/generate-cheque/view-cheque.component';
import { ChequeEntriesComponent } from './cheque/cheque-entries/cheque-entries.component';
import { NewChequeEntryComponent } from './cheque/cheque-entries/new-cheque-entry/new-cheque-entry.component';
import { SettingsComponent } from './settings/settings.component';
import { BackupSettingsComponent } from './settings/backup/backup-settings.component';
import { NewMySQLBackupComponent } from './settings/backup/new-mysql-backup/new-mysql-backup.component';
import { NewCloudBackupComponent } from './settings/backup/new-cloud-backup/new-cloud-backup.component';
import { SelectBackupPage } from './settings/backup/select-backup/select-backup.page';
import { SelectBackupTypePage } from './settings/backup/select-backup-type/select-backup-type.page';
import { NewFTPBackupComponent } from './settings/backup/new-ftp-backup/new-ftp-backup.component';
import { SplashComponent } from './dashboard/splash-screen/splash.component';
import { IntroComponent } from './dashboard/intro/intro.component';
import { IntroFirstPageComponent } from './dashboard/intro/pages/1-first/1-first.page';
import { IntroSecondPageComponent } from './dashboard/intro/pages/2-second/2-second.page';
import { IntroThirdPageComponent } from './dashboard/intro/pages/3-third/3-third.page';
import { IntroFourthPageComponent } from './dashboard/intro/pages/4-fourth/4-fourth.page';
import { IntroFithPageComponent } from './dashboard/intro/pages/5-fith/5-fith.page';
import { IntroSixthPageComponent } from './dashboard/intro/pages/6-sixth/6-sixth.page';
import { IntroSeventhPageComponent } from './dashboard/intro/pages/7-seventh/7-seventh.page';
import { IntroEighthPageComponent } from './dashboard/intro/pages/8-eighth/8-eighth.page';
import { IntroNinthPageComponent } from './dashboard/intro/pages/9-ninth/9-ninth.page';
import { PurchaseComponent } from './dashboard/purchase/purchase.component';
import { BitCoinPurchaseComponent } from './dashboard/purchase/bitcoin/bitcoin.component';
import { AdministratorsComponent } from './settings/administrators/administrators.component';
import { NewAdministratorComponent } from './settings/administrators/new-administrator/new-administrator.component';
import { SelectAdministratorPage } from './settings/administrators/select-administrator/select-administrator.page';
import { BankAccountsComponent } from './settings/bank-accounts/bank-accounts.component';
import { NewBankAccountComponent } from './settings/bank-accounts/new-bank-account/new-bank-account.component';
import { ChequeDesignerComponent } from './cheque/designs/cheque-designer/cheque-designer.component';
import { ChequeDesignsComponent } from './cheque/designs/cheque-designs.component';
import { SelectChequeDesignsPage } from './cheque/designs/select-design/select-design.page';
import { PostageComponent } from './settings/postage/postage.component';
import { SelectPostageComponent } from './settings/postage/select-postage/select-postage.component';
import { PostageMenuComponent } from './settings/postage/postage-menu/postage-menu.component';
import { NewPostageComponent } from './settings/postage/new-postage/new-postage.component';
import { DeductionsComponent } from './deductions/deductions.component';
import { EmployeesComponent } from './employees/employees.component';
import { LeavesComponent } from './leaves/leaves.component';
import { PaymentsComponent } from './payments/payments.component';
import { PayeesComponent } from './payees/payees.component';
import { PayrollComponent } from './payroll/payroll.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { SalaryComponent } from './salary/salary.component';
import { SchedulesComponent } from './schedule/schedules.component';
import { NewSchedulePage } from './schedule/new-schedule/new-schedule.component';
import { SelectSchedulePage } from './schedule/select-schedule/select-schedule.page';
import { TimesheetComponent } from './timesheet/timesheet.component';
import { VacationsComponent } from './vacations/vacations.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TaxesComponent } from './taxes/taxes.component';
import { JobsComponent } from './jobs/jobs.component';
import { ChartsComponent } from './charts/charts.component';
import { BonusesComponent } from './bonuses/bonuses.component';
import { NewBonusComponent } from './bonuses/new-bonus/new-bonus.component';
import { SelectBonusPage } from './bonuses/select-bonus/select-bonus.page';
import { NewJobComponent } from './jobs/new-job/new-job.component';
import { NewChequePage } from './cheque/new-cheque/new-cheque.component';
import { NewAddressPage } from './addresses/new-address/new-address.component';
import { NewDeductionPage } from './deductions/new-deduction/new-deduction.component';
import { NewEmployeePage } from './employees/new-employee/new-employee.component'; 
import { SelectAddressPage } from './addresses/select-address/select-address.page';
import { SelectLeavesPage } from './leaves/select-leave/select-leave.page';
import { SelectHolidayPage } from './settings/holidays/select-holiday/select-holiday.page';
import { SelectEmployeePage } from './employees/select-employee/select-employee.page';
import { SelectBankPage } from './settings/bank-accounts/select-bank/select-bank.page';
import { SelectDeductionPage } from './deductions/select-deduction/select-deduction.page';
import { SelectSalaryPage } from './salary/select-salary/select-salary.page';
import { SelectPayeePage } from './payees/select-payee/select-payee.page';
import { SelectTaxPage } from './taxes/select-tax/select-tax.page';
import { SelectJobPage } from './jobs/select-job/select-job.page';
import { NewInvoicePage } from './invoices/new-invoice/new-invoice.component';
import { ImportsComponent } from './settings/import/import.component';
import { PreviewImportComponent } from './settings/import/new-import/preview-import/preview-import.component';
import { ExportsComponent } from './settings/export/export.component';
import { NewImportComponent } from './settings/import/new-import/new-import.component';
import { AddImportComponent } from './settings/import/new-import/add-import/add-import.component';
import { NewExportComponent } from './settings/export/new-export/new-export.component';
import { AddExportComponent } from './settings/export/new-export/add-export/add-export.component';
import { SelectImportFieldsPage } from './settings/import/select-import-fields/select-import-fields.page';
import { NewLeavePage } from './leaves/new-leave/new-leave.component';
import { NewPaymentPage } from './payments/new-payment/new-payment.component';
import { NewPayrollPage } from './payroll/new-payroll/new-payroll.component';
import { PrintPayrollPage } from './payroll/print-payroll/print-payroll.component';
import { PreviewPaymentFieldsPage } from './payroll/preview-payroll/preview-payment/preview-payment.component';
import { PreviewPayrollComponent } from './payroll/preview-payroll/preview-payroll.component';
import { NewSalaryPage } from './salary/new-salary/new-salary.component';
import { NewTaxPage } from './taxes/new-tax/new-tax.component';
import { NewTimesheetPage } from './timesheet/new-timesheet/new-timesheet.component';
import { NewTimesheetEntryComponent } from './timesheet/timesheet-entries/new-timesheet-entry/new-timesheet-entry.component';
import { TimesheetEntriesComponent } from './timesheet/timesheet-entries/timesheet-entries.component';
import { NewVacationPage } from './vacations/new-vacation/new-vacation.component';
import { NewPayeeComponent } from './payees/new-payee/new-payee.component';
import { HolidaysComponent } from './settings/holidays/holidays.component';
import { NewHolidayComponent } from './settings/holidays/new-holiday/new-holiday.component';
import { ModeratorsComponent } from './settings/moderators/moderators.component';
import { NewModeratorComponent } from './settings/moderators/new-moderator/new-moderator.component';
import { SelectModeratorPage } from './settings/moderators/select-moderator/select-moderator.page';
import { SubmitTimesheetComponent } from './timesheet/submit-timesheet/submit-timesheet.component';
import { SignatureComponent } from './settings/signature-pad/signature-pad.component';
import { ViewSignatureComponent } from './settings/view-signature/view-signature.component';
import { SelectSignaturePage } from './settings/select-signature/select-signature.page';
import { PrintersComponent } from './settings/printers/printers.component';
import { NewPrinterComponent } from './settings/printers/new-printer/new-printer.component';
import { PrintJobsComponent } from './settings/printers/jobs/jobs.component';
import { ViewPrintJobComponent } from './settings/printers/view-printer/view-printer.component';
import { ColorPickerComponent } from './cheque/designs/color-picker/color-picker.component';
import { FontPickerComponent } from './cheque/designs/font-picker/font-picker.component';
import { SelectPaperPage } from './cheque/designs/paper-picker/paper-picker.component';
import { ViewCsvComponent } from './settings/import/view-csv/view-csv.component';
import { FaxesComponent } from './settings/faxes/faxes.component';
import { PhoneComponent } from './settings/faxes/phone/phone.component';
import { MessagesComponent } from './settings/messages/messages.component';
import { NewMessageComponent } from './settings/messages/new-message/new-message.component';
import { NewFaxComponent } from './settings/faxes/new-fax/new-fax.component';
import { NewPaypalPaymentsComponent } from './settings/paypal-payments/new-paypal-payments/new-paypal-payments.component';
import { SelectPaypalPaymentsComponent } from './settings/paypal-payments/select-paypal-payments/select-paypal-payments.component';
import { CompaniesComponent } from './settings/companies/companies.component';
import { NewCompanyComponent } from './settings/companies/new-company/new-company.component';
import { InsuranceComponent } from './insurance/insurance.component';
import { NewInsurancePage } from './insurance/new-insurance/new-insurance.component';
import { SelectInsurancePage } from './insurance/select-insurance/select-insurance.page';
import { SelectCompanyComponent } from './settings/companies/select-company/select-company.component';
import { PaypalPaymentsComponent } from './settings/paypal-payments/paypal-payments.component';
import { CreditCardComponent } from './settings/deposit/credit-card/credit-card.component';
import { PaypalComponent } from './settings/deposit/paypal/paypal.component';
import { InteracComponent } from './settings/deposit/interac/interac.component';
import { BitcoinComponent } from './settings/deposit/bitcoin/bitcoin.component';
import { NewDepositComponent } from './settings/deposit/new-deposit/new-deposit.component';
import { ListDepositsComponent } from './settings/deposit/list-deposits/list-deposits.component';
import { ViewDepositComponent } from './settings/deposit/view-deposit/view-deposit.component';
import { KeysComponent } from './settings/keys/keys.component';
import { ScanKeyComponent } from './settings/keys/scan-key/scan-key.component';
import { NewKeyComponent } from './settings/keys/new-key/new-key.component';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { NgModule } from '@angular/core';
const routes: Routes = [
  { path: '', redirectTo:environment.inital_pin_prompt?'folder/Splash':'folder/Dashboard',  pathMatch: 'full' },
  { path: ':folder/Cheques',                                      component: ChequeComponent },
  { path: ':folder/Checks',                                       component: ChequeComponent },
  { path: ':folder/Cheque/New',                                   component: NewChequePage },
  { path: ':folder/Check/New',                                    component: NewChequePage },
  { path: ':folder/New/Cheque',                                   component: NewChequePage },
  { path: ':folder/New/Check',                                    component: NewChequePage },
  { path: ':folder/Write/Cheque',                                 component: NewChequePage },
  { path: ':folder/Write/Check',                                  component: NewChequePage },
  { path: ':folder/Create/Cheque',                                component: NewChequePage },
  { path: ':folder/Create/Check',                                 component: NewChequePage },
  { path: ':folder/Add/Cheque',                                   component: NewChequePage },
  { path: ':folder/Add/Check',                                    component: NewChequePage },
  { path: ':folder/Cheque/:id',                                   component: NewChequePage },
  { path: ':folder/Cheque/:id/Cheque',                            component: NewChequePage },
  { path: ':folder/Cheque/:id/Bank/:bankid',                      component: NewChequePage },
  { path: ':folder/Cheque/:id/Payee/:payeeid',                    component: NewChequePage },
  { path: ':folder/Cheque/New/Payee/:payeeid',                    component: NewChequePage },
  { path: ':folder/Cheque/:id/Address/:addressid',                component: NewChequePage },
  { path: ':folder/Cheque/:id/Employee/:employeeid',              component: NewChequePage },
  { path: ':folder/Cheque/New/Employee/:employeeid',              component: NewChequePage },
  { path: ':folder/Cheque/New/Amount/:amount/:currency',          component: NewChequePage },
  { path: ':folder/Cheque/:id/Design/:designid',                  component: NewChequePage },
  { path: ':folder/Cheque/:cheque_id/NewEntry/',                  component: ChequeEntriesComponent },
  { path: ':folder/Cheque/:cheque_id/Entries/',                   component: ChequeEntriesComponent },
  { path: ':folder/ChequeEntries/:cheque_id',                     component: ChequeEntriesComponent },
  { path: ':folder/ChequeEntry/New/:cheque_id',                   component: NewChequeEntryComponent },
  { path: ':folder/ChequeEntry/New',                              component: NewChequeEntryComponent },
  { path: ':folder/Cheque/:cheque_id/Entry/:id',                  component: NewChequeEntryComponent },
  { path: ':folder/ChequeEntry/:id',                              component: NewChequeEntryComponent },
  { path: ':folder/Print/Cheques',                                component: PrintChequesComponent },
  { path: ':folder/Print/Checks',                                 component: PrintChequesComponent },
  { path: ':folder/Print/Cheque/:id/Design/:did',                 component: GenerateChequeComponent },
  { path: ':folder/Print/Cheque/:id/Design',                      component: GenerateChequeComponent },
  { path: ':folder/Print/Check/:id/Design/:did',                  component: GenerateChequeComponent },
  { path: ':folder/Print/Check/:id/Design',                       component: GenerateChequeComponent },
  { path: ':folder/Print/Cheque/:id',                             component: GenerateChequeComponent },
  { path: ':folder/Print/Check/:id',                              component: GenerateChequeComponent },
  { path: ':folder/Print/Cheque/:id/Print',                       component: GenerateChequeComponent },
  { path: ':folder/Print/Check/:id/Print',                        component: GenerateChequeComponent },
  { path: ':folder/View/Cheque/:id',                              component: GenerateChequeComponent },
  { path: ':folder/View/Check/:id',                               component: GenerateChequeComponent },
  { path: ':folder/View/Cheque/:id/Print',                        component: GenerateChequeComponent },
  { path: ':folder/View/Check/:id/Print',                         component: GenerateChequeComponent },
  { path: ':folder/Print/Many/Checks/:ids',                       component: GenerateMultipleChequesComponent },
  { path: ':folder/Print/Address',                                component: PrintAddressComponent },
  { path: ':folder/Print/Addresses',                              component: PrintAddressComponent },
  { path: ':folder/Print/Address/:id',                            component: PrintAddressComponent },
  { path: ':folder/Print/Address/:id/Print',                      component: PrintAddressComponent },
  { path: ':folder/View/Address/:id',                             component: PrintAddressComponent },
  { path: ':folder/View/Address/:id/Print',                       component: PrintAddressComponent },
  { path: ':folder/Dashboard',                                    component: DashboardComponent },
  { path: ':folder/Dashboards',                                   component: DashboardComponent },
  { path: ':folder/Dashboard/:ShowMenu',                          component: DashboardComponent },
  { path: ':folder/Splash',                                       component: SplashComponent },
  { path: ':folder/Intro',                                        component: IntroComponent },
  { path: ':folder/Intro/1',                                      component: IntroFirstPageComponent },
  { path: ':folder/Intro/2',                                      component: IntroSecondPageComponent },
  { path: ':folder/Intro/3',                                      component: IntroThirdPageComponent },
  { path: ':folder/Intro/4',                                      component: IntroFourthPageComponent },
  { path: ':folder/Intro/5',                                      component: IntroFithPageComponent },
  { path: ':folder/Intro/6',                                      component: IntroSixthPageComponent },
  { path: ':folder/Intro/7',                                      component: IntroSeventhPageComponent },
  { path: ':folder/Intro/8',                                      component: IntroEighthPageComponent },
  { path: ':folder/Intro/9',                                      component: IntroNinthPageComponent },
  { path: ':folder/Accounts',                                     component: BankAccountsComponent },
  { path: ':folder/Account/New',                                  component: NewBankAccountComponent },
  { path: ':folder/Accounts/New',                                 component: NewBankAccountComponent },
  { path: ':folder/Account/:id',                                  component: NewBankAccountComponent },
  { path: ':folder/Accounts/Select',                              component: SelectBankPage },
  { path: ':folder/Accounts/Select/:id/:table/:model',            component: SelectBankPage },
  { path: ':folder/Address',                                      component: AddressesComponent },
  { path: ':folder/Addresses',                                    component: AddressesComponent },
  { path: ':folder/Address/New',                                  component: NewAddressPage },
  { path: ':folder/Address/:id',                                  component: NewAddressPage },
  { path: ':folder/Address/Select/:id/:table/:model',             component: SelectAddressPage },
  { path: ':folder/Administrator',                                component: AdministratorsComponent },
  { path: ':folder/Administrators',                               component: AdministratorsComponent },
  { path: ':folder/Administrators/New',                           component: NewAdministratorComponent },
  { path: ':folder/Administrator/New',                            component: NewAdministratorComponent },
  { path: ':folder/Administrator/Select/:id/:table/:model',       component: SelectAdministratorPage },
  { path: ':folder/Administrator/:id',                            component: NewAdministratorComponent },
  { path: ':folder/Moderators',                                   component: ModeratorsComponent },
  { path: ':folder/Moderator/:id',                                component: ModeratorsComponent },
  { path: ':folder/Moderator/New',                                component: NewModeratorComponent },
  { path: ':folder/Moderators/New',                               component: NewModeratorComponent },
  { path: ':folder/Moderator/Select/:id/:table/:model',           component: SelectModeratorPage },
  { path: ':folder/Bonuses',                                      component: BonusesComponent },
  { path: ':folder/Bonus/New',                                    component: NewBonusComponent },
  { path: ':folder/Bonus/:id',                                    component: NewBonusComponent },
  { path: ':folder/Bonus/Select/:id/:table/:model',               component: SelectBonusPage },
  { path: ':folder/Deductions',                                   component: DeductionsComponent },
  { path: ':folder/Deduction/New',                                component: NewDeductionPage },
  { path: ':folder/Deduction/:id',                                component: NewDeductionPage },
  { path: ':folder/Deduction/Select/:id/:table/:model',           component: SelectDeductionPage },
  { path: ':folder/Companies',                                    component: CompaniesComponent },
  { path: ':folder/Company/New',                                  component: NewCompanyComponent },
  { path: ':folder/Company/:id',                                  component: NewCompanyComponent },
  { path: ':folder/Company/Select/:id/:table/:model',             component: SelectCompanyComponent },
  { path: ':folder/Postage',                                      component: PostageComponent },
  { path: ':folder/Settings/Postage',                             component: PostageComponent },
  { path: ':folder/Postage/Select/:id/:table/:model',             component: SelectPostageComponent },
  { path: ':folder/Postage/:id',                                  component: NewPostageComponent },
  { path: ':folder/Postage/New',                                  component: NewPostageComponent },
  { path: ':folder/Employee',                                     component: EmployeesComponent },
  { path: ':folder/Employees',                                    component: EmployeesComponent },
  { path: ':folder/Employee/New',                                 component: NewEmployeePage },
  { path: ':folder/Employee/Select',                              component: SelectEmployeePage },
  { path: ':folder/Employee/Select/:id/:table/:model',            component: SelectEmployeePage },
  { path: ':folder/Employee/New/Job',                             component: SelectJobPage },
  { path: ':folder/Employee/New/Job/:id/:table/:model',           component: SelectJobPage },
  { path: ':folder/Employee/New/Address',                         component: SelectAddressPage },
  { path: ':folder/Employee/New/Address/:id/:table/:model',       component: SelectAddressPage },
  { path: ':folder/Employee/New/Salary',                          component: SelectSalaryPage },
  { path: ':folder/Employee/New/Salary/:id/:table/:model',        component: SelectSalaryPage },
  { path: ':folder/Employee/New/Deduction',                       component: SelectDeductionPage },
  { path: ':folder/Employee/New/Deduction/:id/:table/:model',     component: SelectDeductionPage },
  { path: ':folder/Employee/New/Tax',                             component: SelectTaxPage },
  { path: ':folder/Employee/New/Tax/:id/:table/:model',           component: SelectTaxPage },
  { path: ':folder/Employee/:id',                                 component: NewEmployeePage },
  { path: ':folder/Employee/:id/Address/:addressid',              component: NewEmployeePage },
  { path: ':folder/Employee/:id/Salary/:salaryid',                component: NewEmployeePage },
  { path: ':folder/Holidays',                                     component: HolidaysComponent },
  { path: ':folder/Holiday/New',                                  component: NewHolidayComponent },
  { path: ':folder/Holidays/New',                                 component: NewHolidayComponent },
  { path: ':folder/Holidays/Select',                              component: SelectHolidayPage },
  { path: ':folder/Holidays/Select/:id/:table/:model',            component: SelectHolidayPage },
  { path: ':folder/Insurance',                                    component: InsuranceComponent },
  { path: ':folder/Policies',                                     component: InsuranceComponent },
  { path: ':folder/Insurance/New',                                component: NewInsurancePage },
  { path: ':folder/Insurance/Select/:id/:table/:model',           component: SelectInsurancePage },
  { path: ':folder/Insurance/:id',                                component: NewInsurancePage },
  { path: ':folder/Insurance/:id/Payee/:payeeid',                 component: NewInsurancePage },
  { path: ':folder/Insurance/New/Payee/:payeeid',                 component: NewInsurancePage },
  { path: ':folder/Insurance/:id/Employee/:employeeid',           component: NewInsurancePage },
  { path: ':folder/Insurance/New/Employee/:employeeid',           component: NewInsurancePage },
  { path: ':folder/Invoices',                                     component: InvoicesComponent },
  { path: ':folder/Invoice',                                      component: InvoicesComponent },
  { path: ':folder/Invoice/New',                                  component: NewInvoicePage },
  { path: ':folder/Invoice/:id',                                  component: NewInvoicePage },
  { path: ':folder/Imports',                                      component: ImportsComponent },
  { path: ':folder/Import/New',                                   component: NewImportComponent },
  { path: ':folder/Import/New/:type',                             component: NewImportComponent },
  { path: ':folder/Import/New/Edit',                              component: AddImportComponent },
  { path: ':folder/Import/New/Edit/:id',                          component: AddImportComponent },
  { path: ':folder/Import/Preview',                               component: PreviewImportComponent },
  { path: ':folder/Import/Preview/:id',                           component: PreviewImportComponent },
  { path: ':folder/Exports',                                      component: ExportsComponent },
  { path: ':folder/Export/New',                                   component: NewExportComponent },
  { path: ':folder/Export/New/:type',                             component: NewExportComponent },
  { path: ':folder/Export/New/Edit',                              component: AddExportComponent },
  { path: ':folder/Export/New/Edit/:id',                          component: AddExportComponent },
  { path: ':folder/Import/:id',                                   component: SelectImportFieldsPage },
  { path: ':folder/Charts',                                       component: ChartsComponent },
  { path: ':folder/Job',                                          component: JobsComponent },
  { path: ':folder/Jobs',                                         component: JobsComponent },
  { path: ':folder/Job/New',                                      component: NewJobComponent },
  { path: ':folder/Job/:id',                                      component: NewJobComponent },
  { path: ':folder/Job/Select/:id/:table/:model',                 component: SelectJobPage },
  { path: ':folder/Leaves',                                       component: LeavesComponent },
  { path: ':folder/Leave/New',                                    component: NewLeavePage },
  { path: ':folder/Leave/:id',                                    component: NewLeavePage },
  { path: ':folder/Payee',                                        component: PayeesComponent },
  { path: ':folder/Payees',                                       component: PayeesComponent },
  { path: ':folder/Payee/New',                                    component: NewPayeeComponent },
  { path: ':folder/Payees/New',                                   component: NewPayeeComponent },
  { path: ':folder/Payee/:id',                                    component: NewPayeeComponent },
  { path: ':folder/Payee/:id/Address/:addressid',                 component: NewPayeeComponent },
  { path: ':folder/Payee/:id/Salary/:salaryid',                   component: NewPayeeComponent },
  { path: ':folder/Payees/:id',                                   component: NewPayeeComponent },
  { path: ':folder/Payees/Select',                                component: SelectPayeePage },
  { path: ':folder/Payments',                                     component: PaymentsComponent },
  { path: ':folder/Payment',                                      component: PaymentsComponent },
  { path: ':folder/Payment/New',                                  component: NewPaymentPage },
  { path: ':folder/Payment/:id',                                  component: NewPaymentPage },
  { path: ':folder/Payment/New/Employee/:employeeid',             component: NewPaymentPage },
  { path: ':folder/Payment/:id/Employee/:employeeid',             component: NewPaymentPage },
  { path: ':folder/Payment/New/Payee/:payeeid',                   component: NewPaymentPage },
  { path: ':folder/Payment/:id/Payee/:payeeid',                   component: NewPaymentPage },
  { path: ':folder/Payroll',                                      component: PayrollComponent },
  { path: ':folder/Payrolls',                                     component: PayrollComponent },
  { path: ':folder/Payroll/New',                                  component: NewPayrollPage },
  { path: ':folder/Payroll/Print/:id',                            component: PrintPayrollPage },
  { path: ':folder/Payroll/:id',                                  component: NewPayrollPage },
  { path: ':folder/Payroll/:id/Bank/:bankid',                     component: NewPayrollPage },
  { path: ':folder/Payroll/:id/Employees/:employeeid',            component: NewPayrollPage },
  { path: ':folder/Payroll/:id/Payees/:payeeid',                  component: NewPayrollPage },
  { path: ':folder/Payroll/:id/Jobs/:jobid',                      component: NewPayrollPage },
  { path: ':folder/View/Payroll/:id',                             component: NewPayrollPage },
  { path: ':folder/Payroll/:id/Preview',                          component: PreviewPayrollComponent },
  { path: ':folder/Payroll/:id/Preview/Field',                    component: PreviewPaymentFieldsPage },
  { path: ':folder/Payroll/:id/Preview/Field/:field',             component: PreviewPaymentFieldsPage },
  { path: ':folder/Salary',                                       component: SalaryComponent },
  { path: ':folder/Salaries',                                     component: SalaryComponent },
  { path: ':folder/Salary/New',                                   component: NewSalaryPage },
  { path: ':folder/Salary/Select/:id/:table/:model',              component: SelectSalaryPage },
  { path: ':folder/Salary/:id',                                   component: NewSalaryPage },
  { path: ':folder/Schedule',                                     component: SchedulesComponent },
  { path: ':folder/Schedules',                                    component: SchedulesComponent },
  { path: ':folder/Schedule/New',                                 component: NewSchedulePage },
  { path: ':folder/Schedule/Select/:id/:table/:model',            component: SelectSchedulePage },
  { path: ':folder/Schedule/:id',                                 component: NewSchedulePage },
  { path: ':folder/Schedule/:id/Payee/:payeeid',                  component: NewSchedulePage },
  { path: ':folder/Schedule/New/Payee/:payeeid',                  component: NewSchedulePage },
  { path: ':folder/Schedule/:id/Employee/:employeeid',            component: NewSchedulePage },
  { path: ':folder/Schedule/New/Employee/:employeeid',            component: NewSchedulePage },
  { path: ':folder/Settings',                                     component: SettingsComponent },
  { path: ':folder/Settings/Backup',                              component: BackupSettingsComponent },
  { path: ':folder/Settings/Backup/Select',                       component: SelectBackupPage },
  { path: ':folder/Settings/Backup/Type',                         component: SelectBackupTypePage },
  { path: ':folder/Settings/Backup/FTP',                          component: NewFTPBackupComponent },
  { path: ':folder/Settings/Backup/FTP/:id',                      component: NewFTPBackupComponent },
  { path: ':folder/Settings/Backup/MySQL',                        component: NewMySQLBackupComponent },
  { path: ':folder/Settings/Backup/MySQL/:id',                    component: NewMySQLBackupComponent },
  { path: ':folder/Settings/Backup/Cloud',                        component: NewCloudBackupComponent },
  { path: ':folder/Settings/Backup/:tt',                          component: NewCloudBackupComponent },
  { path: ':folder/Settings/Backup/:tt/:id',                      component: NewCloudBackupComponent },
  { path: ':folder/Settings/Import/FTP',                          component: NewImportComponent },
  { path: ':folder/Settings/Backup/iCloud',                       component: NewImportComponent },
  { path: ':folder/Settings/Backup/GoogleDrive',                  component: NewImportComponent },
  { path: ':folder/Settings/Backup/OneDrive',                     component: NewImportComponent },
  { path: ':folder/Settings/PayPal',                              component: PaypalPaymentsComponent },
  { path: ':folder/Settings/PayPal/Select',                       component: SelectPaypalPaymentsComponent },
  { path: ':folder/Settings/PayPal/New',                          component: NewPaypalPaymentsComponent },
  { path: ':folder/Settings/PayPal/:id',                          component: NewPaypalPaymentsComponent },
  { path: ':folder/Keys',                                         component: KeysComponent },
  { path: ':folder/Keys/Scan',                                    component: ScanKeyComponent },
  { path: ':folder/Keys/Scan/:key',                               component: ScanKeyComponent },
  { path: ':folder/Scan/Key',                                     component: ScanKeyComponent },
  { path: ':folder/Scan/Key/:key',                                component: ScanKeyComponent },
  { path: ':folder/Keys/:id',                                     component: NewKeyComponent },
  { path: ':folder/Cheque/Design/:cheque_id',                     component: ChequeDesignerComponent },
  { path: ':folder/Designer/',                                    component: ChequeDesignerComponent },
  { path: ':folder/Design/',                                      component: ChequeDesignerComponent },
  { path: ':folder/Designer/:design_id',                          component: ChequeDesignerComponent },
  { path: ':folder/Design/:design_id',                            component: ChequeDesignerComponent },
  { path: ':folder/Cheque/Designer/',                             component: ChequeDesignerComponent },
  { path: ':folder/ChequeDesigner/:cheque_id',                    component: ChequeDesignerComponent },
  { path: ':folder/Cheque/Designer/:cheque_id',                   component: ChequeDesignerComponent },
  { path: ':folder/ChequeDesigns',                                component: ChequeDesignsComponent },
  { path: ':folder/ChequeDesigns/Select/',                        component: SelectChequeDesignsPage },
  { path: ':folder/Taxes',                                        component: TaxesComponent },
  { path: ':folder/Tax/:id',                                      component: NewTaxPage },
  { path: ':folder/Tax/New',                                      component: NewTaxPage },
  { path: ':folder/Taxes/:id',                                    component: NewTaxPage },
  { path: ':folder/Taxes/New',                                    component: NewTaxPage },
  { path: ':folder/Tax/Select/:id/:table/:model',                 component: SelectTaxPage },
  { path: ':folder/Timesheets',                                   component: TimesheetComponent },
  { path: ':folder/Timesheet/New',                                component: NewTimesheetPage },
  { path: ':folder/Timesheet/New/:id',                            component: NewTimesheetPage },
  { path: ':folder/Timesheet/New/:id/Employee/:employeeid',       component: NewTimesheetPage },
  { path: ':folder/Timesheet/New/:id/Payee/:payeeid',             component: NewTimesheetPage },
  { path: ':folder/Timesheet/Entries',                            component: TimesheetEntriesComponent },
  { path: ':folder/Timesheets/Entries',                           component: TimesheetEntriesComponent },
  { path: ':folder/Timesheet/Entries/:id',                        component: TimesheetEntriesComponent },
  { path: ':folder/Timesheets/Entries/:id',                       component: TimesheetEntriesComponent },
  { path: ':folder/Timesheet/Entries/:id/:sd',                    component: TimesheetEntriesComponent },
  { path: ':folder/Timesheets/Entries/:id/:sd',                   component: TimesheetEntriesComponent },
  { path: ':folder/Timesheet/Entry/:id',                          component: NewTimesheetEntryComponent },
  { path: ':folder/Timesheet/Entry/:id/:sd',                      component: NewTimesheetEntryComponent },
  { path: ':folder/Timesheet/NewEntry',                           component: NewTimesheetEntryComponent },
  { path: ':folder/Timesheet/:id',                                component: NewTimesheetPage },
  { path: ':folder/Timesheet/:id/Employee/:employeeid',           component: NewTimesheetPage },
  { path: ':folder/Timesheet/:id/Payee/:payeeid',                 component: NewTimesheetPage },
  { path: ':folder/Signature',                                    component: SignatureComponent },
  { path: ':folder/Signatures',                                   component: SignatureComponent },
  { path: ':folder/Signature/New',                                component: SignatureComponent },
  { path: ':folder/Signature/View/:id',                           component: ViewSignatureComponent },
  { path: ':folder/Signature/Select',                             component: SelectSignaturePage },
  { path: ':folder/Phone',                                        component: PhoneComponent },
  { path: ':folder/Phone/:nu',                                    component: PhoneComponent },
  { path: ':folder/Call',                                         component: PhoneComponent },
  { path: ':folder/Call/:nu',                                     component: PhoneComponent },
  { path: ':folder/Messages',                                     component: MessagesComponent },
  { path: ':folder/Message/New',                                  component: NewMessageComponent },
  { path: ':folder/Messages/New',                                 component: NewMessageComponent },
  { path: ':folder/Message/:id',                                  component: NewMessageComponent },
  { path: ':folder/Messages/:id',                                 component: NewMessageComponent },
  { path: ':folder/Faxes',                                        component: FaxesComponent },
  { path: ':folder/Fax/New',                                      component: NewFaxComponent },
  { path: ':folder/Faxes/New',                                    component: NewFaxComponent },
  { path: ':folder/Fax/:id',                                      component: NewFaxComponent },
  { path: ':folder/Faxes/:id',                                    component: NewFaxComponent },
  { path: ':folder/Printers',                                     component: PrintersComponent },
  { path: ':folder/Printer/New',                                  component: NewPrinterComponent },
  { path: ':folder/Printers/New',                                 component: NewPrinterComponent },
  { path: ':folder/Printer/:id',                                  component: NewPrinterComponent },
  { path: ':folder/Printers/:id',                                 component: NewPrinterComponent },
  { path: ':folder/PrintJobs',                                    component: PrintJobsComponent },
  { path: ':folder/PrintJobs/:id',                                component: ViewPrintJobComponent },
  { path: ':folder/ColorPicker',                                  component: ColorPickerComponent },
  { path: ':folder/FontPicker',                                   component: FontPickerComponent },
  { path: ':folder/PaperPicker',                                  component: SelectPaperPage },
  { path: ':folder/Deposit/Card',                                 component: CreditCardComponent },
  { path: ':folder/Deposit/PayPal',                               component: PaypalComponent },
  { path: ':folder/Deposit/Interac',                              component: InteracComponent },
  { path: ':folder/Deposit/BitCoin',                              component: BitcoinComponent },
  { path: ':folder/Deposits/',                                    component: ListDepositsComponent },
  { path: ':folder/Deposit/New',                                  component: NewDepositComponent },
  { path: ':folder/Deposit/:id',                                  component: ViewDepositComponent },
  { path: ':folder/Timesheet/Submit',                             component: SubmitTimesheetComponent },
  { path: ':folder/Timesheet/Submission',                         component: SubmitTimesheetComponent },
  { path: ':folder/Timesheet/Submission/:id/:uuid/:job/:employee/:start_date/:end_date/:title/:notes',
    component: SubmitTimesheetComponent },
  { path: ':folder/Vacations',                                    component: VacationsComponent },
  { path: ':folder/Vacation',                                     component: VacationsComponent },
  { path: ':folder/Vacation/New',                                 component: NewVacationPage },
  { path: ':folder/Vacation/:id',                                 component: NewVacationPage },
  { path: ':folder/CSV/:id',                                      component: ViewCsvComponent },
  { path: 'Login',                                                component: SplashComponent },
  { path: ':folder/Login',                                        component: SplashComponent },
  { path: 'Logout',                                               component: SplashComponent },
  { path: ':folder/Logout',                                       component: SplashComponent },
  { path: ':folder/Purchase',                                     component: PurchaseComponent },
  { path: ':folder/Purchase/BitCoin',                             component: BitCoinPurchaseComponent },
  { path: ':folder/:id', loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule) }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}