import { SQLService } from '../../../services/sql.service';
import { Flip } from '../../../app.animations';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router,NavigationEnd,ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController, NavParams } from '@ionic/angular';
@Component({
  selector: 'app-select-holiday',
  templateUrl: './select-holiday.page.html',
  styleUrls: ['./select-holiday.page.styl'],
  animations: [ Flip ]
})
export class SelectHolidayPage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    private params: NavParams,
    public sql: SQLService,
    private location: Location
  ) {
    var s=params.get('selected');
    if(s)this.selected=s.split(',');
    this.multiple=params.get('select_multiple');
    this.router.events.subscribe((eve)=>{
      if(eve instanceof NavigationEnd)this.get_holidays();
    });
  }
  count = -1;
  holidays = [];
  output = new EventEmitter();
  search:string;
  selected = [];
  multiple: boolean;
  filter(e) { this.search=e.target.value; }
  @Input('set_table') table:string;
  @Input('set_model') model:string;
  @Input('set_model_id') id:string;
  ngOnInit() {
    this.get_holidays();
    this.id = this.route.snapshot.paramMap.get("id");
    this.table = this.route.snapshot.paramMap.get("table");
    this.model = this.route.snapshot.paramMap.get("model");
  }
  get_holidays(event?) {
    this.sql.fetch('Holidays',(e)=>{
      this.holidays=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  select_none() {this.navCtrl.dismiss(-1);}
  select(e) { 
    if ( this.id ) {
      let a = 'holiday';
      this.sql.query('UPDATE '+this.table+' '+( this.id?'WHERE '+this.model+' = '+this.id:'' )+' SET '+a+'_id = '+e[a+'_id']);
      this.location.back();
    } else {
      this.navCtrl.dismiss(e);
    }
  }
  add() {
    this.router.navigate(['folder','Holiday','New']);
    this.navCtrl.dismiss();
  }
  off() {
    this.navCtrl.dismiss();
  }
}