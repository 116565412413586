import { SQLService } from '../../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { PopoverController} from '@ionic/angular';
import { Router,NavigationEnd } from '@angular/router';
import { BankMenuComponent } from './bank-menu/bank-menu.component';
@Component({
  selector: 'ng-cheques-bank-accounts',
  templateUrl: './bank-accounts.component.html',
  styleUrls: ['./bank-accounts.component.styl']
})
export class BankAccountsComponent implements OnInit {
  count = -1;
  constructor(
    public sql: SQLService,
    public popoverController: PopoverController,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_banks();
    });
  }
  banks = [];
  search: string;
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:BankMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='enable')this.enable(item);
      if(data=='disable')this.disable(item);
      if(data=='delete')this.delete(item);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  filter(e) { this.search=e.target.value; }
  add() { 
    this.sql.count('BankAccounts',(c)=>{
      if(this.sql.Trial && this.sql.Trial_Bank_Accounts!=-1 && c>=this.sql.Trial_Bank_Accounts) {
        this.sql.show_purhchase_modal();
      } else {
        this.router.navigate(['folder','Account','New']); 
      }
    });
  }
  get_banks(event?) {
    this.sql.fetch('BankAccounts',(e)=>{
      if(e)this.banks=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  edit(bank, item?) {
    this.router.navigate(['folder','Account',bank.bank_id]);
  }
  disable(bank,i?){
    this.sql.set('BankAccounts','bank_id',bank.bank_id,'enabled',0,(res)=>{
      bank.enabled = false;
      if(i)i.closeSlidingItems();
    });
  }
  enable(bank,i?){
    this.sql.set('BankAccounts','bank_id',bank.bank_id,'enabled',1,(res)=>{
      bank.enabled = true;
      if(i)i.closeSlidingItems();
    });
  }
  delete(bank, item?) { 
    if(confirm(this.sql.word.Discard_Bank_Confirmation)) {
      this.sql.delete('BankAccounts','bank_id',bank.bank_id);
      this.get_banks();
    } else { if(item)item.closeSlidingItems(); }
  }
  ngOnInit() {
    this.get_banks();
  }
}