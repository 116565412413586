import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { NewChequeEntry } from '../../../models/chequeentries';
import { Currencies } from '../../../../environments/currencies';
import { environment } from '../../../../environments/environment';
import { SQLService } from '../../../services/sql.service';
import { TimesheetService } from '../../../services/timesheet.service';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
@Component({
  selector: 'app-new-cheque-entry',
  templateUrl: './new-cheque-entry.component.html',
  styleUrls: ['./new-cheque-entry.component.styl'],
})
export class NewChequeEntryComponent implements OnInit, AfterViewInit {
  constructor(
    private router: Router,
    private sql: SQLService,
    private ts: TimesheetService,
    private navCtrl: ModalController,
    private route: ActivatedRoute,
    private modalController: ModalController
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) {
        this.open();
      }
    });
  }
  _c=0;
  currencies = environment.currencies;
  Currencies = Object.keys(environment.currencies);
  Currency = {};
  ChequeEntry = new NewChequeEntry();
  not_updated = true;
  decimal_places = '0.01';
  select_currency: string ;
  param_id: string;
  param_cheque_id: string;
  param_currency: string;
  starting_poisition = '0';
  ngOnInit() {
    this.open();
  }
  get_cheque_entries(cb?) {
    this.ChequeEntry = new NewChequeEntry();
    this.ChequeEntry.enabled = true;
    this.ChequeEntry.mode = 'add';
    this.param_id = this.route.snapshot.paramMap.get("id");
    this.param_cheque_id = this.route.snapshot.paramMap.get("cheque_id");
    this.param_currency = this.route.snapshot.paramMap.get("currency");
    if(this.param_currency)this.select_currency=this.param_currency;
    if (!this.param_id) { 
      if(this.param_cheque_id){
        this.ChequeEntry.cheque_id = Number(this.param_cheque_id);
        if(cb)cb(this.ChequeEntry);
      } else {
        console.error('Cheque ID',this.sql.word.Error,this.param_cheque_id);
      }
    } else {
      this.count_cheque_entries();
      this.sql.view('ChequeEntry','cheque_entry_id',this.param_id,(e)=>{
        var f=Object.entries(e[0]);
        let n = new NewChequeEntry();
        for(let t of f)n[t[0]]=t[1];
        this.ChequeEntry=n;
        if(cb)cb(this.ChequeEntry);
      });
    }
  }
  count_cheque_entries(cb?) {
    this.sql.count("ChequeEntry WHERE cheque_id = "+this.ChequeEntry.cheque_id,(items)=>{
      var a = items+1;
      this.starting_poisition = ''+a;
      if(cb)cb(items);
    });
  }
  open() {
    this.get_cheque_entries(()=>{
      this.set_currency(this.select_currency?this.select_currency:this.sql.Currency);
    });
  }
  save(e=true) {
    if(!this.ChequeEntry.cheque_entry_id) {
      console.log('add',"ChequeEntry",this.ChequeEntry);
      this.ChequeEntry.number=this.starting_poisition;
      this.sql.add("ChequeEntry",this.ChequeEntry,(id)=>{
        this.not_updated=false;
        this.ChequeEntry.cheque_entry_id=id;
        this._c=0;
        if(e)this.end();
      });
    } else {
      this.sql.updateObj("ChequeEntry",this.ChequeEntry,(insertId)=>{
        setTimeout(()=>{
          insertId = this.ChequeEntry.cheque_entry_id;
          this._c=0;
          if(e)this.end();
        },500);
      });
    }
  }
  change_currency(c) {
    if(!c||this.ChequeEntry.currency==c) {
      //
    } else if (!this.ChequeEntry.amount) {
      this.set_currency(c);
    } else {
      this.sql.exchange_currency(this.ChequeEntry.amount,this.ChequeEntry.currency,c,(amount)=>{
        this.ChequeEntry.amount=amount;
        this.set_currency(c);
      });
    }
    this._c=1;
  }
  set_currency(c) {
    this.ChequeEntry.currency=c;
    if(!this.Currency[this.ChequeEntry.currency].fiat) {
      this.ChequeEntry.fiat='0';
    } else {
      this.ChequeEntry.fiat='1';
    }
    this.set_decimal_places();
  }
  amount(event) {
    if(!event.target.value||event.target.value==0||isNaN(event.target.value)){
      if(isNaN(event.target.value)){
        var am = event.target.value.split('.');
        this.ChequeEntry.amount = am[0]+'.'+am[1];
      } else {
        this.ChequeEntry.amount = '0.00';
      }
    } else {
      var amount=this.sql.amount(event.target.value, null, this.ChequeEntry.currency);
      if(this.sql.max_cheque_amount>0&&amount>this.sql.max_cheque_amount) {
        alert(this.sql.word.Max_Amount_Message+' '+this.sql.CurrencySymbol+this.sql.max_cheque_amount+' '+this.sql.Currency);
        this.ChequeEntry.amount = this.sql.max_cheque_amount;
        if(this.sql.Trial)this.sql.show_purhchase_modal();
      } else {
        this.ChequeEntry.amount = amount;
      }
    }
  }
  set_decimal_places() {
    this.decimal_places = '0.';
    for (var i = 0; i < this.Currency[this.ChequeEntry.currency].decimal_places; ++i) {
      this.decimal_places=this.decimal_places+'0';
      if(i==this.Currency[this.ChequeEntry.currency].decimal_places)this.decimal_places=this.decimal_places+'1';
    }
  }
  end() {
    // this.router.navigate(['folder','Cheque',this.ChequeEntry.cheque_id]);
    this.navCtrl.dismiss({
      cheque_id:this.ChequeEntry.cheque_id,
      cheque_entry_id:this.ChequeEntry.cheque_entry_id,
      mode:this.ChequeEntry.mode,
      total:Number(this.ChequeEntry.amount)
    });
  }
  ngAfterViewInit() {
    this.Currency = Currencies;
    this.set_currency(this.sql.Currency);
  }
}