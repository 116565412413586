export class NewTimer {
	constructor(
		public timer_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public timesheet_id?: number,
		public start_date?: any,
		public start_time?: any,
		public end_date?: any,
		public end_time?: any,
		public name?: string,
		public description?: string,
		public time?: number,
		public completed?: boolean,
		public days?: number,
		public hours?: number,
		public minutes?: number,
		public seconds?: number,
		public timer?: any,
		public timer_active?: number,
		public enabled?: boolean,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Timers = '\
	CREATE TABLE IF NOT EXISTS "Timer" (\
		timer_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		timesheet_id INTEGER DEFAULT 0,\
		start_date TIMESTAMP,\
		start_time TIMESTAMP,\
		end_date TIMESTAMP,\
		end_time TIMESTAMP,\
		name TEXT,\
		description TEXT,\
		time TEXT,\
		completed INTEGER DEFAULT 0,\
		days INTEGER,\
		hours INTEGER,\
		minutes INTEGER,\
		seconds INTEGER,\
		timer TEXT,\
		timer_active INTEGER DEFAULT 0,\
		enabled INTEGER DEFAULT 0,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';