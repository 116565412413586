export class NewFax {
	constructor(
		public fax_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public name?: string,
		public email?: string,
		public phone?: string,
		public addresses?: any,
		public address_ids?: any,
		public employees?: any,
		public cheques?: any,
		public enable_header?: boolean,
		public header?: any,
		public enable_footer?: boolean,
		public footer?: any,
		public content?: string,
		public image?: string,
		public enabled?: boolean,
		public date?: any,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Faxes = '\
	CREATE TABLE IF NOT EXISTS "Faxes" (\
		fax_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		name TEXT,\
		email TEXT,\
		phone TEXT,\
		addresses TEXT,\
		address_ids TEXT,\
		employees TEXT,\
		cheques TEXT,\
		content TEXT,\
		enable_header INTEGER,\
		header TEXT,\
		footer TEXT,\
		enable_footer INTEGER,\
		image TEXT,\
		enabled INTEGER DEFAULT 0,\
		date TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';