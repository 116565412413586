import { SQLService } from '../../services/sql.service';
import { PopoverController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TimesheetService } from '../../services/timesheet.service';
@Component({
  selector: 'ng-cheques-timesheet-menu',
  templateUrl: './timesheet-menu.component.html',
  styleUrls: ['./timesheet-menu.component.styl']
})
export class TimesheetMenuComponent implements OnInit {
  constructor(
    public sql: SQLService,
    private ts: TimesheetService,
    public popover: PopoverController,
    private router: Router) {
  }
  list:string;
  @Input('timer_active') timer_active:any;
  @Input('enabled') enabled:boolean;
  dismissPopover(e) {
    if (this.popover) this.popover.dismiss(e);
  }
  enable() { this.dismissPopover('enable'); }
  disable() { this.dismissPopover('disable'); }
  play() { this.dismissPopover('play'); }
  pause() { this.dismissPopover('pause'); }
  delete() { this.dismissPopover('delete'); }
  ngOnInit() { }
}