import { Component, AfterViewInit, OnChanges, Output, EventEmitter, Input } from '@angular/core';
import { SQLService } from '../../../services/sql.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-docugard-mailer-top',
  templateUrl: './docugard-mailer-top.component.html',
  styleUrls: ['./docugard-mailer-top.component.scss'],
})
export class DocugardMailerTopComponent implements AfterViewInit, OnChanges {
  constructor(private sql: SQLService,
    private santitize: DomSanitizer) { }
  @Output('loaded') loaded = new EventEmitter<boolean>();
  @Input('RenderMICR') RenderMICR = false;
  @Input('ImageMICR') ImageMICR = true;
  @Input('show') show = 'table';
  @Input('cheque') cheque: any;
  @Input('design') design: any;
  @Input('designer') designer: any;
  @Input('rotate') rotate: boolean;
  @Input('borders') borders: boolean;
  @Input('voidcheck') voidcheck: boolean;
  @Input('font') font: any;
  @Input('fiat') fiat: boolean;
  @Input('color') color: any;
  @Input('micr') micr: any;
  @Input('micr_top') micr_top: string;
  @Input('micr_right') micr_right: string;
  @Input('micr_bottom') micr_bottom: string;
  @Input('micr_left') micr_left: string;
  @Input('secret') secret = '';
  @Input('scale') scale = 1;
  @Input('fold_line') fold_line: boolean;
  @Input('template') template: string;
  @Input('monochrome') monochrome: boolean;
  @Input('gradients') gradients: boolean;
  @Input('margin_bottom') bottom = '0cm';
  @Input('fontsize') fontsize: boolean;
  @Input('layers') layers: any;
  @Input('translations') translations = this.sql.word;
  @Input('bold') bold: boolean;
  @Input('italic') italic: boolean;
  @Input('margin_top') top = '0cm';
  @Input('show_cheque_number') show_cheque_number: boolean = true;
  @Input('show_cheque_date') show_cheque_date: boolean = true;
  @Input('show_micr') show_micr: boolean = true;
  @Input('cheque_background_image_repeat') cheque_background_image_repeat = false;
  @Input('background_position') background_position: string;
  @Input('cheque_text_color') cheque_text_color: string;
  @Input('cheque_background_image') cheque_background_image: any;
  @Input('cheque_logo_image') cheque_logo_image: any;
  @Input('cheque_header_image') cheque_header_image: any;
  @Input('cheque_bank_image') cheque_bank_image: any;
  @Input('doc_color') doc_color: string;
  @Input("address_top") address_top: string;
  @Input("address_right") address_right: string;
  @Input("address_bottom") address_bottom: string;
  @Input("address_left") address_left: string;
  @Input("table_top") table_top: string;
  @Input("table_right") table_right: string;
  @Input("table_bottom") table_bottom: string;
  @Input("table_left") table_left: string;
  @Input('lines') lines = [];
  show_title_matrix=[];
  design_company_name_top = "auto";
  design_company_name_right = "auto";
  design_company_name_bottom = "89mm";
  design_company_name_left = "20mm";
  design_company_address_top = "auto";
  design_company_address_right = "auto";
  design_company_address_bottom = "85mm";
  design_company_address_left = "20mm";
  design_company_address_2_top = "auto";
  design_company_address_2_right = "auto";
  design_company_address_2_bottom = "81mm";
  design_company_address_2_left = "20mm";
  design_company_city_top = "auto";
  design_company_city_right = "auto";
  design_company_city_bottom = "77mm";
  design_company_city_left = "20mm";
  design_company_country_top = "auto";
  design_company_country_right = "auto";
  design_company_country_bottom = "73mm";
  design_company_country_left = "20mm";
  design_postmark_img_top = "auto";
  design_postmark_img_right = "5mm";
  design_postmark_img_bottom = "73mm";
  design_postmark_img_left = "auto";
  design_postmark_qr_top = "18mm";
  design_postmark_qr_right = "50mm";
  design_postmark_qr_bottom = "auto";
  design_postmark_qr_left = "auto";
  design_postmark_id_top = "30mm";
  design_postmark_id_right = "auto";
  design_postmark_id_bottom = "auto";
  design_postmark_id_left = "30mm";
  design_address_name_top = "auto";
  design_address_name_right = "auto";
  design_address_name_bottom = "40mm";
  design_address_name_left = "20mm";
  design_address_top = "auto";
  design_address_right = "auto";
  design_address_bottom = "36mm";
  design_address_left = "20mm";
  design_address_line_2_top = "auto";
  design_address_line_2_right = "auto";
  design_address_line_2_bottom = "32mm";
  design_address_line_2_left = "20mm";
  design_address_line_3_top = "auto";
  design_address_line_3_right = "auto";
  design_address_line_3_bottom = "28mm";
  design_address_line_3_left = "20mm";
  design_address_line_4_top = "auto";
  design_address_line_4_right = "auto";
  design_address_line_4_bottom = "24mm";
  design_address_line_4_left = "20mm";
  loaded_component() {
    this.loaded.emit(true);
  }
  ngAfterViewInit() {
    for(let i=0; i < this.sql.show_docu_title; i++)this.show_title_matrix.push(true);
    this.design["payment_name"]["right"]="0mm";
    this.design["payment_name"]["left"]="0mm";
    this.design["amount_number"]["borders"]=true;
    this.design["amount_number"]["background"]=true;
    this.loaded.emit(true);
  }
  ngOnChanges(ngOnChanges) {
    if(ngOnChanges.cheque_background_image&&this.cheque_background_image){
      this.cheque_background_image=this.santitize.bypassSecurityTrustStyle(this.cheque_background_image&&this.cheque_background_image!='0'?"url('"+this.cheque_background_image+"')":"none");
    }
  }

}
