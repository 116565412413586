import { Component, AfterViewInit, OnChanges, Output, EventEmitter, Input } from '@angular/core';
import { SQLService } from '../../../services/sql.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-docugard-cheque-top',
  templateUrl: './docugard-cheque-top.component.html',
  styleUrls: ['./docugard-cheque-top.component.scss'],
})
export class DocugardChequeTopComponent implements AfterViewInit, OnChanges {
  constructor(private sql: SQLService,
    private santitize: DomSanitizer) { }
  @Output('loaded') loaded = new EventEmitter<boolean>();
  @Input('RenderMICR') RenderMICR = false;
  @Input('ImageMICR') ImageMICR = true;
  @Input('show') show = 'table';
  @Input('cheque') cheque: any;
  @Input('design') design: any;
  @Input('designer') designer: any;
  @Input('rotate') rotate: boolean;
  @Input('borders') borders: boolean;
  @Input('voidcheck') voidcheck: boolean;
  @Input('font') font: any;
  @Input('fiat') fiat: boolean;
  @Input('color') color: any;
  @Input('micr') micr: any;
  @Input('micr_top') micr_top: string;
  @Input('micr_right') micr_right: string;
  @Input('micr_bottom') micr_bottom: string;
  @Input('micr_left') micr_left: string;
  @Input('secret') secret = '';
  @Input('scale') scale =1;
  @Input('fold_line') fold_line: boolean;
  @Input('template') template: string;
  @Input('monochrome') monochrome: boolean;
  @Input('gradients') gradients: boolean;
  @Input('margin_bottom') bottom = '0mm';
  @Input('margin_top') top = '0mm';
  @Input('fontsize') fontsize: boolean;
  @Input('layers') layers: any;
  @Input('translations') translations = this.sql.word;
  @Input('bold') bold: boolean;
  @Input('italic') italic: boolean;
  @Input('show_cheque_number') show_cheque_number: boolean = true;
  @Input('show_cheque_date') show_cheque_date: boolean = true;
  @Input('show_micr') show_micr: boolean = true;
  @Input('cheque_background_image_repeat') cheque_background_image_repeat = false;
  @Input('background_position') background_position: string;
  @Input('cheque_text_color') cheque_text_color: string;
  @Input('cheque_background_image') cheque_background_image: any;
  @Input('cheque_logo_image') cheque_logo_image: any;
  @Input('cheque_header_image') cheque_header_image: any;
  @Input('cheque_bank_image') cheque_bank_image: any;
  @Input('doc_color') doc_color: string;
  show_title_matrix=[];
  loaded_component() {this.loaded.emit(true);}
  ngAfterViewInit() {
    for(let i=0; i < this.sql.show_docu_title; i++)this.show_title_matrix.push(true);
    this.design["payment_name"]["right"]="0mm";
    this.design["payment_name"]["left"]="0mm";
    this.design["amount_number"]["borders"]=true;
    this.design["amount_number"]["background"]=true;
    this.design["key_image"]["top"]="151px";
    this.design["key_image"]["left"]="6.3mm";
    this.design["key_image"]["width"]="92";
    this.design["public_image"]["top"]="35.5mm";
    this.design["public_image"]["width"]="92";
    this.design["public_image"]["right"]="6mm";
    this.design["public_image"]["left"]="auto";
    this.design["public_image"]["bottom"]="auto";
    this.design["currency_name"]["left"]='33mm';
    this.design["address_data"]["left"]='33mm';
    this.loaded.emit(true);
  }
  ngOnChanges(ngOnChanges) {
    if(ngOnChanges.cheque_background_image&&this.cheque_background_image){
      this.cheque_background_image=this.santitize.bypassSecurityTrustStyle(this.cheque_background_image&&this.cheque_background_image!='0'?"url('"+this.cheque_background_image+"')":"none");
    }
  }
}