export class NewPayPal {
	constructor(
		public paypal_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public name?: string,
		public title?: string,
		public email?: string,
		public username?: string,
		public currency?: string,
		public sandbox_api_key?: string,
		public sandbox_api_username?: string,
		public sandbox_api_signature?: string,
		public sandbox_api_password?: string,
		public production_api_key?: string,
		public production_api_username?: string,
		public production_api_signature?: string,
		public production_api_password?: string,
		public email_subject?: string,
		public email_message?: string,
		public payment_note?: string,
		public max_transfer_amount?: number,
		public max_total_amount?: number,
		public total_amount?: number,
		public total_transfers?: number,
		public production_mode?: number,
		public production?: number,
		public enabled?: boolean,
		public modified?: any,
		public created?: any
	) {}
}

export const Create_PayPals = '\
	CREATE TABLE IF NOT EXISTS "PayPals" (\
		paypal_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		name TEXT,\
		title TEXT,\
		email TEXT,\
		username TEXT,\
		currency TEXT,\
		sandbox_api_key TEXT,\
		sandbox_api_username TEXT,\
		sandbox_api_signature TEXT,\
		sandbox_api_password TEXT,\
		sandbox_email TEXT,\
		sandbox_client_id TEXT,\
		sandbox_api_secret TEXT,\
		production_api_key TEXT,\
		production_api_username TEXT,\
		production_api_signature TEXT,\
		production_api_password TEXT,\
		production_email TEXT,\
		production_client_id TEXT,\
		production_api_secret TEXT,\
		email_subject TEXT,\
		email_message TEXT,\
		payment_note TEXT,\
		max_transfer_amount INTEGER DEFAULT 0,\
		max_total_amount INTEGER DEFAULT 0,\
		total_amount INTEGER DEFAULT 0,\
		total_transfers INTEGER DEFAULT 0,\
		production_mode INTEGER DEFAULT 0,\
		production INTEGER DEFAULT 0,\
		enabled INTEGER DEFAULT 0,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';