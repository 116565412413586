import { CustomizePage } from './customize/customize.page';
import { PurchaseComponent } from './purchase/purchase.component';
import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MenuController, PopoverController} from '@ionic/angular';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AppComponent } from '../app.component';
import { SQLService } from '../services/sql.service';
import { PopoverComponent } from './popover/popover.component';
import { PopoverAccountsComponent } from './popover-accounts/popover-accounts.component';
import { PopoverTimesheetComponent } from './popover-timesheet/popover-timesheet.component';
import { ChequeMenuComponent } from '../cheque/cheque-menu/cheque-menu.component';
import { EmployeeMenuComponent } from '../employees/employee-menu/employee-menu.component';
import { TimesheetMenuComponent } from '../timesheet/timesheet-menu/timesheet-menu.component';
import { PayrollMenuComponent } from '../payroll/payroll-menu/payroll-menu.component';
import { TimesheetService } from '../services/timesheet.service';
import { NewTimesheetEntry } from '../models/timesheetentries';
import { ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { fade,lock,turn } from '../app.animations';
import { ToastController } from '@ionic/angular';
import { NotificationService } from '../services/notification.service';
import * as SQLModels from "../models";
@Component({
  selector: 'ng-cheques-dashboard',
  animations: [fade,lock,turn],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.styl']
})
export class DashboardComponent implements OnInit {
  constructor(
    public sanitize: DomSanitizer,
    public router: Router,
    public app: AppComponent,
    public sql: SQLService,
    public plt: Platform,
    public menu: MenuController,
    private ts: TimesheetService,
    public notif: NotificationService,
    public toastController: ToastController,
    public modalController: ModalController,
    public popover: PopoverController,
    public activatedRoute: ActivatedRoute
  ) {
    this.router.events.subscribe(value => {
      if(value instanceof NavigationEnd){
        var url = this.router.url.toString();
        var urls=url.split('/');
        if(urls[2]=='Dashboard'){
          this.sql.dashboard_loaded=false;
        }
        setTimeout(() => this.slides.update(), 100);
      }
    });
  }
  l=0;
  count=[];
  cheques=[];
  employees=[];
  timesheets=[];
  showInfo=[];
  payrolls=[];
  holidays=[];
  invoices=[];
  salaries=[];
  payments=[];
  mobile:boolean;
  Message:any;
  Next_Holiday:any;
  DashboardOrder:any;
  Payroll_Title:string;
  one_second_timer: any;
  Next_Holiday_Date:any;
  auto_interval:any;
  selected_timesheet:any;
  ShowChequesSlider=true;
  ShowEmployeeSlider=true;
  ShowTimesheetSlider=true;
  ShowPayrollSlider=true;
  ShowPrints=false;
  interval:any;
  swiper_free = {
    spaceBetween: 0,
    freeMode: true,
    pagination: false
  };
  TrialToaster: any;
  @ViewChild('scroller') pallet: ElementRef;
  @ViewChild('slides') slides;
  @HostListener('window:resize')
  onResize() {
    setTimeout(() => this.slides.update(), 100);
  }
  @HostListener("wheel", ["$event"])
  public onScroll(event: WheelEvent) {
    if(this.pallet&&this.pallet.nativeElement){
      this.pallet.nativeElement.scrollLeft+=event.deltaY;
    }
  }
  async present_side_menu(ev: any) {
    var menu = await this.popover.create({component: PopoverComponent,cssClass: 'dashboard-popover',event: ev,translucent: true});
    await menu.present();
    var role = await menu.onDidDismiss();
    if(role.data=='login')this.sql.show_login_modal();
    if(role.data=='signup')this.sql.show_signup_modal();
    if(role.data=='customize')this.customize_dashboard();
    if(role.data=='purchase')this.beggg_for_money();
  }
  async present_accounts_menu(ev: any) {
    if(this.sql.Unlimited||this.sql.Subscription) { 
      this.app.present_accounts_menu(ev);  
    } else {
      this.sql.show_purhchase_modal();
    }
  }
  async present_timesheet_popover(ev: any,timesheet) {
    var menu = await this.popover.create({
      component: PopoverTimesheetComponent,
      componentProps: {enabled: timesheet.enabled, timer_active: timesheet.timer_active},
      cssClass:'dashboard-popover-timesheet',
      event: ev,
      translucent: true
    });
    await menu.present();
    var role = await menu.onDidDismiss();
    if(role.data=='stop')this.stop_timesheet(timesheet);
    if(role.data=='start')this.start_timesheet(timesheet);
    if(role.data=='delete')this.delete_timesheet(timesheet);
    if(role.data=='enable'){
      this.sql.set('Timesheets','timesheet_id',timesheet.timesheet_id,'enabled',1,(res)=>{
        timesheet.enabled = true;
      });
    }
    if(role.data=='disable') {
      this.sql.set('Timesheets','timesheet_id',timesheet.timesheet_id,'enabled',0,(res)=>{
        timesheet.enabled = false;
      });
    }
  }
  start_timesheet(timesheet) {
    timesheet.timer_active=1;
    var d = new Date(Date.now()).toISOString();
    if (!timesheet.start_date) timesheet.start_date = d;
    if (!timesheet.start_time) timesheet.start_time = d;
    if (!timesheet.name) timesheet.name = this.sql.word.Untitled_Entry;
  }
  skip_setup() {
    this.sql.setup_complete=true;
    this.sql.save_setting('setup_complete','true');
  }
  stop_timesheet(timesheet) {

  }
  pause_timesheet(timesheet) {

  }
  delete_timesheet(timesheet) {

  }
  async customize_dashboard() {
    const m = await this.modalController.create({component: CustomizePage, componentProps: {}});
    await m.present();
    var a = await m.onDidDismiss();
    if(a&&a.data&&a.data.order)this.DashboardOrder=a.data.order;
  }
  beggg_for_money() {
    this.sql.show_purhchase_modal();
  }
  async open(event?) {
    this.sql.get_setting('lockdown',(s)=>{this.sql.locked=s.option=="1"?true:false;});
    this.sql.fetch("Holidays",(h)=>{this.holidays=h;});
    this.sql.fetch("Invoices",(i)=>{this.invoices=i;});
    this.sql.fetch("Salaries",(i)=>{this.salaries=i;});
    this.sql.fetch("Cheques",(c)=>{this.cheques=c;});
    this.sql.fetch("Employees",(c)=>{this.employees = c;});
    this.sql.fetch('Timesheets',(e)=>{
      this.count['Timesheets']=e.length;
      this.calc_sec(e,(d)=>{
        this.timesheets = d;
        this.second_check();
      });
    });
    this.sql.fetch("Payrolls",(p)=>{this.payrolls=p;});
    this.sql.fetch("Payments",(p)=>{this.payments=p;});
    this.sql.count('Holidays',(d)=>{this.count['Holidays']=d;});
    this.sql.count('Cheques',(d)=>{this.count['Cheques']=d;});
    this.sql.count('Employees',(d)=>{this.count['Employees']=d;});
    this.sql.count('Payrolls',(d)=>{this.count['Payrolls']=d;});
    this.sql.count('Payees',(d)=>{this.count['Payees']=d;});
    this.sql.count('Payments',(d)=>{this.count['Payments']=d;});
    this.sql.count('Invoices',(d)=>{this.count['Invoices']=d;});
    this.sql.count('Deductions',(d)=>{this.count['Deductions']=d;});
    this.sql.count('Jobs',(d)=>{this.count['Jobs']=d;});
    this.sql.count('Salaries',(d)=>{this.count['Salaries']=d;});
    this.sql.count('Schedules',(d)=>{this.count['Schedules']=d;});
    this.sql.count('Vacations',(d)=>{this.count['Vacations']=d;});
    this.sql.count('Insurance',(d)=>{this.count['Insurance']=d;});
    this.sql.dashboard_loaded=true;
    setTimeout(()=>{
      if (event) event.target.complete();
    },1234);
  }
  ngOnInit() { 
    SplashScreen.hide(); 
    if (this.plt.is('capacitor')) {
      this.mobile=true;
    } else if (this.plt.is('electron')) {
      this.mobile=false;
    } else {
      this.mobile=false;
    }
    this.interval=setInterval(()=>{
      if(!this.sql.dashboard_loaded)this.open();
      this.ShowPrints=this.sql.show_prints_chip;
    },1000);
    setTimeout(()=>{this.sql.trigger_trial_notification();},15000);
  }
  change_default_company(){
    this.sql.dashboard_loaded=false;
    if(this.sql.verbose)console.log('change_default_company: ',this.sql.dashboard_loaded);
  }
  ngOnDestroy(){
    clearInterval(this.interval);
    clearInterval(this.one_second_timer);
  }
  remaining_prints() {
    // this.notif.send('Title','Body');
    this.sql.alert_remaining_prints();
  }
  view_intro(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Intro']);}
  view_cheques(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Cheques']);}
  view_employees(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Employee']);}
  view_payees(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Payee']);}
  view_invoices(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Invoice']);}
  view_jobs(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Job']);}
  view_vacations(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Vacation']);}
  view_payments(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Payment']);}
  view_holidays(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Holidays']);}
  view_schedules(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Schedule']);}
  view_timesheets(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Timesheets']);}
  view_payrolls(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Payrolls']);}
  view_policies(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Insurance']);}
  view_messages(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Messages']);}
  view_cheque(a?){if(!a)this.view_cheques();if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Cheque',a.cheque_id]);}
  print_cheque(a?){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Print','Cheque',a.cheque_id]);}
  view_employee(a?){if(!a)this.view_employees();if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Employee',a.employee_id]);}
  view_payee(a?){if(!a)this.view_payees();if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Payee',a.payee_id]);}
  view_holiday(a?){if(!a)this.view_holidays();if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Holiday',a.holiday_id]);}
  view_invoice(a?){if(!a)this.view_invoices();if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Invoice',a.invoice_id]);}
  view_job(a?){if(!a)this.view_jobs();if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Job',a.job_id]);}
  view_vacation(a?){if(!a)this.view_vacations();if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Vacation',a.vacation_id]);}
  view_payment(a?){if(!a)this.view_payments();if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Payment',a.payment_id]);}
  view_schedule(a?){if(!a)this.view_schedules();if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Schedule',a.schedule_id]);}
  view_timesheet(a?){if(!a)this.view_timesheets();if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Timesheet',a.timesheet_id]);}
  view_policy(a?){if(!a)this.view_timesheets();if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Policies',a.timesheet_id]);}
  view_payroll(a?){if(!a)this.view_payrolls();if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Payroll',a.payroll_id]);}
  view_message(a?){if(!a)this.view_messages();if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Messages',a.message_id]);}
  view_next_payroll(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Payroll',this.sql.Next_Payroll_ID]);}
  view_salary(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Salary']);}
  view_deductions(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Deductions']);}
  add_deduction(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Deduction','New']);}
  add_employee(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Employee','New']);}
  add_salary(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Salary','New']); }
  add_payee(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Payee','New']);}
  add_payment(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Payment','New']);}
  add_payroll(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Payroll','New']);}
  add_cheque(){if(this.sql.locked)return; this.l=1;this.router.navigate(['folder','Cheque','New']);}
  add_vacation(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Vacation','New']);}
  add_schedule(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Schedule','New']);}
  add_policy(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Insurance','New']);}
  add_job(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Job','New']);}
  add_timesheet(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Timesheet','New']);}
  add_timer(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Timesheet','New','Start']);}
  add_invoice(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Invoice','New']); }
  add_holiday(){if(this.sql.locked)return;this.l=1;this.router.navigate(['folder','Holiday','New']); }
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async cheque_popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popover.create({
      component:ChequeMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='delete'){
        if(confirm(this.sql.word.Cheque_Delete_Confirmation)) {
          this.sql.delete('Cheques','cheque_id',item.cheque_id,()=>{
            this.sql.fetch("Cheques",(c)=>{this.cheques=c;});
            this.sql.count('Cheques',(d)=>{this.count['Cheques']=d;});
          });
        }
      }
      if(data=='print')this.router.navigate(['folder','Print','Cheque',item.cheque_id,'Print']);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  async employee_popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popover.create({
      component:EmployeeMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='delete'){
        let s = 'this employee';
        if(item.first_name&&item.last_name)s=item.first_name+' '+item.last_name;
        if(confirm(this.sql.word.Delete_Employee_Confirmation+s+'?')) {
          this.sql.delete('Employees','employee_id',item.employee_id,()=>{
            this.sql.fetch("Employees",(c)=>{this.employees = c;});
            this.sql.count('Employees',(d)=>{this.count['Employees']=d;});
          });
        }
      }
      if(data=='disable'){
        this.sql.set('Employees','employee_id',item.employee_id,'enabled',0,(res)=>{
          item.enabled = false;
          if(item)item.closeSlidingItems();
        });
      }
      if(data=='enable'){
        this.sql.set('Employees','employee_id',item.employee_id,'enabled',1,(res)=>{
          item.enabled = true;
          if(item)item.closeSlidingItems();
        });
      }
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  async timesheet_popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popover.create({
      component:TimesheetMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='delete'){
        if(confirm(this.sql.word.Delete_Timesheet_Confirmation)) {
          this.sql.delete('Timesheets','timesheet_id',item.timesheet_id,()=>{
            this.sql.fetch('Timesheets',(e)=>{
              this.count['Timesheets']=e.length;
              this.calc_sec(e,(d)=>{
                this.timesheets = d;
                this.second_check();
              });
            });
          });
        }
      }
      if(data=='enable'){
        this.sql.set('Timesheets','timesheet_id',item.timesheet_id,'enabled',1,(res)=>{
          item.enabled = true;
        });
      }
      if(data=='disable'){
        this.sql.set('Timesheets','timesheet_id',item.timesheet_id,'enabled',0,(res)=>{
          item.enabled = false;
        });
      }
      if(data=='play')this.start_timer(item, false);
      if(data=='pause')this.stop_timer(item, false);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  async payroll_popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popover.create({
      component:PayrollMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='delete'){
        if(confirm(this.sql.word.Delete_Payroll_Confirmation)) {
          this.sql.delete('Payrolls','payroll_id',item.payroll_id,()=>{
            this.sql.fetch("Payrolls",(p)=>{this.payrolls=p;});
            this.sql.count('Payrolls',(d)=>{this.count['Payrolls']=d;});
          });
        }
      }
      if(data=='enable'){
        this.sql.set('Payrolls','payroll_id',item.payroll_id,'enabled',1,(res)=>{
          item.enabled = true;
        });
      }
      if(data=='disable'){
        this.sql.set('Payrolls','payroll_id',item.payroll_id,'enabled',0,(res)=>{
          item.enabled = false;
        });
      }
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  calc_sec(z,cb) {
    var i=0, d=[];
    for(let e of z) {
      i++;
      this.ts.calculate_seconds(e.timesheet_id,(h,m,s)=>{
        e['seconds']=s; 
        e['minutes']=m; 
        e['hours']=h;
        e=this.ts.set_timer(e);
        d.push(e);
        if(i==z.length)cb(d);
      });  
    }
  }
  second_check() {
    if(!this.one_second_timer) {
      this.one_second_timer = setInterval(()=>{
        for (var i = 0; i < this.timesheets.length; ++i) {
          if(this.timesheets[i].timer_active==1) {
            this.timesheets[i].seconds=this.timesheets[i].seconds+1
            if(this.timesheets[i].seconds>59){
              this.timesheets[i].seconds=0
              this.timesheets[i].minutes=this.timesheets[i].minutes+1
            }
            if(this.timesheets[i].minutes>59){
              this.timesheets[i].minutes=0
              this.timesheets[i].seconds=0
              this.timesheets[i].hours=this.timesheets[i].hours+1
            }
            if(this.timesheets[i].hours>23){
              this.timesheets[i].hours=0
              this.timesheets[i].minutes=0
              this.timesheets[i].seconds=0
              this.timesheets[i].days=this.timesheets[i].days+1
            }
            this.timesheets[i]=this.ts.set_timer(this.timesheets[i]);
          }
        }
      },1000);
    }
  }
  start_timer(ts, item?) {
    ts.timer_active=1;
    var now = new Date(Date.now()).toISOString();
    if (!ts.start_date) this.sql.set( "Timesheets", "timesheet_id", ts.timesheet_id, "start_date", now);
    if (!ts.start_time) this.sql.set( "Timesheets", "timesheet_id", ts.timesheet_id, "start_time", now);
    if (!ts.name) this.sql.set( "Timesheets", "timesheet_id", ts.timesheet_id, "name", this.sql.word.Untitled_Entry);
    this.sql.set( "Timesheets", "timesheet_id", ts.timesheet_id, "timer_active", 1);
    this.new_timer(ts);
    if(item)item.close(); 
  }
  new_timer(timesheet) {
    var entry = new NewTimesheetEntry();
      entry.start_date=new Date(Date.now()).toISOString();
      entry.start_time=new Date(Date.now()).toISOString();  
      entry.timesheet_id=timesheet.timesheet_id;
      this.sql.add("TimesheetEntry",entry,(id)=>{if(!id)return;
        timesheet.active_timer=id;
        // this.save(()=>{
          console.log('this.timesheet.active_timer',timesheet.active_timer)
          // this.get_entries();
        // });
      });
  }
  stop_timer(ts, item?) {
    var d = new Date(Date.now()).toISOString();
    ts.end_date = d;
    ts.end_time = d;
    ts.timer_active=0;
    var now = new Date(Date.now()).toISOString();
    this.ts.kill_timer(ts.active_timer,()=>{
      this.sql.set( "Timesheets", "timesheet_id", ts.timesheet_id, "end_date", now);
      this.sql.set( "Timesheets", "timesheet_id", ts.timesheet_id, "end_time", now);
      if (!ts.timer_active) this.sql.set( "Timesheets", "timesheet_id", ts.timesheet_id, "name", this.sql.word.Untitled_Entry);
      this.sql.set( "Timesheets", "timesheet_id", ts.timesheet_id, "timer", ts.timer);
      this.sql.set( "Timesheets", "timesheet_id", ts.timesheet_id, "timer_active", -1);
    });
    if(item)item.close(); 
  }
}