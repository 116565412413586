export class NewVacation {
	constructor(
		public vacation_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public employee_id?: number,
		public name?: string,
		public description?: string,
		public start_date?: any,
		public start_time?: any,
		public end_date?: any,
		public end_time?: any,
		public repeat?: boolean,
		public paid?: boolean,
		public employees?: any,
		public employee_ids?: any,
		public payees?: any,
		public payee_id?: any,
		public payee_ids?: any,
		public overtime_amount?: number,
		public overtime_percentage?: number,
		public paytype?: string,
		public currency?: string,
		public always_enabled?: boolean,
		public enabled?: boolean,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Vacations = '\
	CREATE TABLE IF NOT EXISTS "Vacations" (\
		vacation_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		employee_id INTEGER,\
		name TEXT,\
		description TEXT,\
		employees TEXT,\
		payees TEXT,\
		employee_ids TEXT,\
		payee_id TEXT,\
		payee_ids TEXT,\
		start_date TIMESTAMP,\
		start_time TIMESTAMP,\
		end_date TIMESTAMP,\
		end_time TIMESTAMP,\
		repeat INTEGER DEFAULT 0,\
		paid INTEGER DEFAULT 0,\
		overtime_amount INTEGER,\
		overtime_percentage INTEGER,\
		paytype TEXT,\
		currency TEXT,\
		always_enabled INTEGER DEFAULT 0,\
		enabled INTEGER DEFAULT 0,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';