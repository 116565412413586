export class NewVersion {
	constructor(
		public version_id?: number,
		public device_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public name?: string,
		public date?: any,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Versions = '\
	CREATE TABLE IF NOT EXISTS "Faxes" (\
		version_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		device_id INTEGER DEFAULT 0,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		name TEXT,\
		date TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';