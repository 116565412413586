export class NewTax {
	constructor(
		public tax_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public employee_id?: number,
		public amount?: string,
		public percentage?: number,
		public frequency?: string,
		public single_use?: boolean,
		public total_collected?: number,
		public currency?: string,
		public title?: string,
		public paytype?: string,
		public description?: string,
		public always_enabled?: boolean,
		public start_date?: any,
		public start_time?: any,
		public end_date?: any,
		public end_time?: any,
		public enabled?: boolean,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Taxes = '\
	CREATE TABLE IF NOT EXISTS "Taxes" (\
		tax_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		employee_id INTEGER,\
		title TEXT,\
		description TEXT,\
		paytype TEXT,\
		amount VARCHAR(100),\
		percentage VARCHAR(100),\
		currency VARCHAR(100),\
		frequency VARCHAR(100),\
		enabled INTEGER DEFAULT 0,\
		always_enabled INTEGER DEFAULT 0,\
		single_use INTEGER DEFAULT 0,\
		total_collected INTEGER DEFAULT 0,\
		start_date TIMESTAMP,\
		start_time TIMESTAMP,\
		end_date TIMESTAMP,\
		end_time TIMESTAMP,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';