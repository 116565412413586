import { SQLService } from '../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { PopoverController} from '@ionic/angular';
import { ScheduleMenuComponent } from './schedule-menu/schedule-menu.component';
@Component({
  selector: 'ng-cheques-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.styl']
})
export class SchedulesComponent implements OnInit {
  search:string;
  schedules=[];
  count = -1;
  constructor(
    private router: Router,
    public popoverController: PopoverController,
    public sql: SQLService
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_schedules();
    });
  }
  filter(e) { this.search=e.target.value; }
  end() {this.router.navigate(['folder','Dashboard']);}
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:ScheduleMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='enable')this.enable(item);
      if(data=='disable')this.disable(item);
      if(data=='delete')this.delete(item, false);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  get_schedules(event?) {
    this.sql.fetch('Schedules',(e)=>{
      if(e)this.schedules=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  edit(schedule, item?) {
    this.router.navigate(['folder','Schedule',schedule.schedule_id]);
    if(item) item.closeSlidingItems();
  }
  enable(schedule, item?){
    this.sql.set('Schedules','schedule_id',schedule.schedule_id,'enabled',1,(res)=>{
      schedule.enabled = true;
      if(item) item.closeSlidingItems();
    });
  }
  disable(schedule, item?){
    this.sql.set('Schedules','schedule_id',schedule.schedule_id,'enabled',0,(res)=>{
      schedule.enabled = false;
      if(item) item.closeSlidingItems();
    });
  }
  delete(schedule, item?) { 
    if(confirm(this.sql.word.Delete_Schedule_Confirmation)) {
      this.sql.delete('Schedules','schedule_id',schedule.schedule_id);
      this.get_schedules();
    } else { if(item) item.closeSlidingItems(); }
  }
  add() {
    this.sql.count('Schedules',(c)=>{
      if(this.sql.Trial && this.sql.Trial_Schedules!=-1 && c>=this.sql.Trial_Schedules) {
        this.sql.show_purhchase_modal();
      } else {this.router.navigate(['folder','Schedule','New']);}
    });
  }
  ngOnInit() {
    //this.get_schedules();
  }
}