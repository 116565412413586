import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
  transform(value, prop, order: "asc" | "desc" = "asc") {
    return value.sort((a, b) => {
      if (order === "asc") {
        return a[prop] - b[prop];
      } else if (order === "desc") {
        return b[prop] - a[prop];
      }
      return 0;
    });
  }
}