export class NewInsurance {
	constructor(
		public insurance_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public employee_id?: number,
		public employees?: any,
		public payee_id?: number,
		public payees?: any,
		public address_id?: number,
		public addresses?: any,
		public name?: string,
		public description?: string,
		public currency?: string,
		public copay_amount?: any,
		public fiat?: any,
		public date?: any,
		public claim_date?: any,
		public start_date?: any,
		public end_date?: any,
		public dead?: boolean,
		public claimed?: boolean,
		public paid?: boolean,
		public enabled?: boolean,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Insurance = '\
	CREATE TABLE IF NOT EXISTS "Insurance" (\
		insurance_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER,\
		mod_id INTEGER,\
		employee_id INTEGER,\
		payee_id INTEGER,\
		address_id INTEGER,\
		name TEXT,\
		description TEXT,\
		currency TEXT,\
		copay_amount TEXT,\
		fiat TEXT,\
		date TIMESTAMP,\
		claim_date TIMESTAMP,\
		start_date TIMESTAMP,\
		end_date TIMESTAMP,\
		dead INTEGER,\
		claimed INTEGER,\
		paid INTEGER,\
		enabled INTEGER,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';