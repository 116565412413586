export class NewJob {
	constructor(
		public job_id?: number,
		public employee_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public title?: string,
		public description?: string,
		public employee_count?: number,
		public salaries_count?: string,
		public schedules_count?: string,
		public employee_ids?: any,
		public salary_ids?: any,
		public payee_ids?: any,
		public bank_ids?: any,
		public address_ids?: any,
		public tax_ids?: any,
		public schedule_ids?: any,
		public deduction_ids?: any,
		public deductions_count?: string,
		public employed?: number,
		public hours?: number,
		public payments?: number,
		public total?: number,
		public currency?: string,
		public enabled?: boolean,
		public payees?: any,
		public employees?: any,
		public addresses?: any,
		public taxes?: any,
		public jobs?: any,
		public salaries?: any,
		public schedules?: any,
		public deductions?: any,
		public indefinite_payroll_enabled?: boolean,
		public always_enabled?: boolean,
		public start_date?: any,
		public start_time?: any,
		public end_date?: any,
		public end_time?: any,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Jobs = '\
	CREATE TABLE IF NOT EXISTS "Jobs" (\
		job_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		employee_id INTEGER,\
		admin_id INTEGER,\
		mod_id INTEGER,\
		title TEXT,\
		description TEXT,\
		employee_count INTEGER DEFAULT 0,\
		salaries_count INTEGER DEFAULT 0,\
		schedules_count INTEGER DEFAULT 0,\
		employee_ids TEXT,\
		salary_ids TEXT,\
		payee_ids TEXT,\
		bank_ids TEXT,\
		address_ids TEXT,\
		tax_ids TEXT,\
		schedule_ids TEXT,\
		deduction_ids TEXT,\
		indefinite_payroll_enabled INTEGER DEFAULT 0,\
		always_enabled INTEGER DEFAULT 0,\
		deductions_count INTEGER DEFAULT 0,\
		employed INTEGER DEFAULT 0,\
		enabled INTEGER DEFAULT 0,\
		hours TEXT,\
		payments TEXT,\
		payees TEXT,\
		employees TEXT,\
		addresses TEXT,\
		taxes TEXT,\
		jobs TEXT,\
		salaries TEXT,\
		schedules TEXT,\
		deductions TEXT,\
		total TEXT,\
		currency TEXT,\
		start_date TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		start_time TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		end_date TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		end_time TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';