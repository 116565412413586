import { SQLService } from '../../../services/sql.service';
import { Flip } from '../../../app.animations';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute,NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-select-backup',
  templateUrl: './select-backup.page.html',
  styleUrls: ['./select-backup.page.styl'],
  animations: [ Flip ]
})
export class SelectBackupPage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    public sql: SQLService,
    private location: Location
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_backup_settings();
    });
  }
  count = -1;
  backups = [];
  output = new EventEmitter();
  @Input('set_table') table:string;
  @Input('set_model') model:string;
  @Input('set_model_id') id:string;
  add(){this.router.navigate(['folder','Settings','Backup','Type']);}
  ngOnInit() {
    this.get_backup_settings();
    this.id = this.route.snapshot.paramMap.get("id");
    this.table = this.route.snapshot.paramMap.get("table");
    this.model = this.route.snapshot.paramMap.get("model");
  }
  get_backup_settings(event?) {
    this.sql.fetch('Backups',(e)=>{
      this.backups=e;
      this.count=e.length;
      setTimeout(()=>{if (event) event.target.complete();},1234);
    });
  }
  select(e) { 
    if ( this.id ) {
      let a = 'backup';
      this.sql.query('UPDATE '+this.table+' '+( this.id?'WHERE '+this.model+' = '+this.id:'' )+' SET '+a+'_id = '+e[a+'_id']);
      this.location.back();
    } else {
      this.navCtrl.dismiss(e);
    }
  }
  off() { this.navCtrl.dismiss(); }
}