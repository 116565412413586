import { SQLService } from '../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { PopoverController} from '@ionic/angular';
import { SelectJobPage } from '../jobs/select-job/select-job.page';
import { ModalController, NavParams } from '@ionic/angular';
import { EmployeeMenuComponent } from './employee-menu/employee-menu.component';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { Browser } from '@capacitor/browser';
@Component({
  selector: 'ng-cheques-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.styl']
})
export class EmployeesComponent implements OnInit {
  constructor(
    private router: Router,
    public sql: SQLService,
    private callNumber: CallNumber,
    public navCtrl: ModalController,
    public popoverController: PopoverController,
    public modalController: ModalController
  ) { 
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_employees();
    });
  }
  count=-1;
  search:string;
  employees= [];
  selected= [];
  multiple: boolean;
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:EmployeeMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {
        enabled: item.enabled,
        phone: item.phone,
        skype: item.skype
      },
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='enable')this.enable(item);
      if(data=='disable')this.disable(item);
      if(data=='call')this.phonecall(item);
      if(data=='skypecall')this.skypecall(item);
      if(data=='delete')this.delete_employee(item);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  phonecall(item) { if(item.phone)this.callNumber.callNumber(item.phone, true); }
  async skypecall(item) { if(item.skype)await Browser.open({ url:'skype:'+item.skype+'?call' }); }
  get_employees(event?) {
    this.sql.fetch('Employees',(e)=>{
      if(e)this.employees=e;
      this.count=e.length;
      if(this.count==0)this.multiple=false;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  edit_employee(employee, item?) {
    if(this.multiple){
      this.toggle_item(employee);
    } else {
      this.router.navigate(['folder','Employee',employee.employee_id]);
    }
  }
  delete_employee(employee, item?) { 
    let s = 'this employee';
    if(employee.first_name&&employee.last_name)s=employee.first_name+' '+employee.last_name;
    if(confirm(this.sql.word.Delete_Employee_Confirmation+s+'?')) {
      this.sql.delete('Employees','employee_id',employee.employee_id);
      this.get_employees();
    } else { if(item)item.closeSlidingItems(); }
  }
  async reassign_employee(employee, item) { 
    const m = await this.modalController.create({component: SelectJobPage, componentProps: {}});
    await m.present();
    var a = await m.onDidDismiss();
    employee.addresses = a.data;
    if(a.data)this.count=1;
    this.get_employees();
    if(item)item.closeSlidingItems();
  }
  unemploy_employee(employee, item?) { 
    let s = 'this employee';
    if(employee.first_name&&employee.last_name)s=employee.first_name+' '+employee.last_name;
    if(confirm(this.sql.word.Fire_Employee_Confirmation+s+'?')) {
      this.sql.delete('Employees','employee_id',employee.employee_id);
      this.get_employees();
    } else { if(item)item.closeSlidingItems(); }
  }
  disable(employee,item?){
    this.sql.set('Employees','employee_id',employee.employee_id,'enabled',0,(res)=>{
      employee.enabled = false;
      if(item)item.closeSlidingItems();
    });
  }
  enable(employee,item?){
    this.sql.set('Employees','employee_id',employee.employee_id,'enabled',1,(res)=>{
      employee.enabled = true;
      if(item)item.closeSlidingItems();
    });
  }
  select(e){
    if(this.multiple){
      this.toggle_item(e);
    } else {
      this.navCtrl.dismiss(e.employee_id);
    }
  }
  insure(employee,item?){
    this.router.navigate(['folder','Insurance','New','Employee',employee.employee_id]);
  }
  done_multiple() {
    this.navCtrl.dismiss(this.selected.join(','));
  }
  toggle_item(item) {
    if( this.selected.includes(item.employee_id) ) {
      this.deselect_item(item);
    } else {
      this.select_item(item);
    }
  }
  select_none() {this.navCtrl.dismiss(-1);}
  select_item(item) {
    console.log(item.employee_id);
    this.selected.push(item.employee_id);
  }
  deselect_item(item) {
    var new_selection=[];
    for(var i = 0; i < this.selected.length; ++i){
      if(this.selected[i]!=item.employee_id){
        new_selection.push(this.selected[i]);
      }
    }
    this.selected = new_selection;
  }
  select_all() {
    var new_selection=[];
    for (var i = 0; i < this.employees.length; ++i) {
      new_selection.push(this.employees[i].employee_id);
    }
    this.selected=new_selection;
  }
  delete_selected() {
     if(!this.selected || this.selected.length==0){
      alert(this.sql.word.Select_Multiple_Cheques_Prompt);
    } else {
      if(confirm(this.sql.word.Employee_Multiple_Delete_Confirmation)) {
        var i = 0;
        for(let c of this.selected){
          this.sql.delete('Employees','employee_id',c,(Done)=>{i++;
            if(i==this.selected.length){
              this.get_employees();
              this.selected = [];
            }
          });
        }
      }
    }
  }
  deselect_all() {
   this.selected = []; 
  }
  off() {
    // this.location.back();
    if(this.multiple){
      this.done_multiple();
     } else {
      this.navCtrl.dismiss();
     }
  }
  add() { 
    this.sql.count('Employees',(c)=>{
      if(this.sql.Trial && this.sql.Trial_Employees!=-1 && c>=this.sql.Trial_Employees) {
        this.sql.show_purhchase_modal();
      } else {this.router.navigate(['folder','Employee','New']);}
    });
  }
  end() {this.router.navigate(['folder','Dashboard']);}
  filter(e) {this.search=e.target.value;}
  ngOnInit() {
    this.get_employees();
  }
}