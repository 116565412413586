import { environment } from '../../../environments/environment';
import { PaperSizes } from '../../../environments/papersizes';
import { fade } from '../../app.animations';
import { SQLService } from '../../services/sql.service';
import { NewAddress } from '../../models/address';
import { NewCheque } from '../../models/cheque';
import { PurchaseComponent } from '../../dashboard/purchase/purchase.component';
import { SelectPaperPage } from '../../cheque/designs/paper-picker/paper-picker.component';
import { GenerateChequeComponent } from '../../cheque/generate-cheque/generate-cheque.component';
import { AllSCSS } from '../../cheque/templates_html';
import { Component, Directive, Input, OnInit, AfterViewInit, OnChanges, OnDestroy, ElementRef, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Router,NavigationEnd,ActivatedRoute } from '@angular/router';
import { DatePipe,Location,CurrencyPipe } from '@angular/common';
import { Gesture, GestureController, NavParams,IonContent, ModalController, Platform } from '@ionic/angular';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import * as Hammer from 'hammerjs';
@Component({
  selector: 'app-print-address',
  templateUrl: './print-address.component.html',
  styleUrls: ['./print-address.component.scss'],
})
export class PrintAddressComponent implements OnInit, OnDestroy {
  @Output('image')      exporter = new EventEmitter();
  @Output('html')       html = new EventEmitter();
  @Input('download')    _download:boolean = false;
  @Input('designer')    _designer:boolean = false;
  @Input('font')        document_font:string;
  @Input('print_scale') print_scale:number;
  @Input('scale') scale = 1;
  @Input('printing')    printing = '';
  @Input('destroying')  destroying = false;
  address             = new NewAddress();
  cheque              = new NewCheque();
  rotate              = true;
  has_html            = false;
  monochrome          = false;
  slug                = "10";
  page_size           = 'Number 10 Envelope';
  page_width          = '241mm';
  page_height         = '105mm';
  printer_size        = 'A4';
  printer_width       = '210mm';
  printer_height      = '297mm';
  papers = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public sql: SQLService,
    private params: NavParams,
    public modalController: ModalController,
    private platform:Platform,
    private screenOrientation: ScreenOrientation,
    private changeDetectorRef: ChangeDetectorRef,
    private gestureCtrl: GestureController
  ) { 
    this.id=params.get('address_id');
    this.modal=params.get('modal');
    this.cheque_id=params.get('cheque_id');
    this.is_tablet=this.platform.is('tablet');
   }
  id:any;
  modal:any;
  cheque_id:any;
  design:any = {};
  showingRender=true;
  is_tablet=false;
  document_settings={
    author: this.sql.Title+' v.'+this.sql.version+' d:'+this.sql.date_man,
    title: this.sql.word.Address,
    subject: this.sql.word.PDF_Subject,
    keywords: this.sql.word.PDF_Keywords
  }
  print_now() { 
    if(this.sql.Trial){this.beggar();return;}
    this.printing = 'now';
    console.log('printing', this.printing);
    setTimeout(()=>{this.printing = '';},400);
  }
  print() { 
    if(this.sql.Trial){this.beggar();return;}
    this.printing = 'canvas';
    setTimeout(()=>{this.printing = '';},400);
  }
  refresh() { 
    this.printing = 'refresh';
    setTimeout(()=>{this.printing = '';},400);
  }
  edit() {
    this.router.navigate(['folder','Address',this.address.address_id]);
  }
  exported_html(html?) {
    this.html.emit(html);
    console.log('html',html)
    this.has_html=html?true:false;
    if(!html) return;
    if(this.sql.more_verbose)console.log('HTML Exported',html);
  }
  exported_image(event?) {
    this.address.image = event;
    this.exporter.emit(event);
    if(!event) return;
    if(this.sql.more_verbose)console.log('Image Exported',event);
  }
  get_addresses(){
    var ida=this.route.snapshot.paramMap.get("id");
    this.id=ida?ida:this.id;
    if(!this.id)return;
    this.sql.view('Addresses','address_id',this.id,(e)=>{
      if(e[0]){
        var f=Object.entries(e[0]); let n = new NewAddress();
        for(let t of f)n[t[0]]=t[1];
        this.address = n;
    }
    });
  }
  get_cheque() {
    var ida = this.route.snapshot.paramMap.get("cheque_id");
    this.cheque_id=ida?ida:this.id;
    if(!this.cheque_id) return;
    this.sql.view('Cheques','cheque_id',this.cheque_id,(e)=>{
      if(e[0]){
        var f=Object.entries(e[0]); let n = new NewCheque();
        for(let t of f)n[t[0]]=t[1];
        this.cheque = n;
      }
    });
  }
  async select_paper() {
    const m = await this.modalController.create({
      component: SelectPaperPage, 
      componentProps: {
        printer:this.printer_size,
        printer_width:this.printer_width,
        printer_height:this.printer_height,
        name:this.page_size,
        width:this.page_width,
        height:this.page_height,
        rotate:this.rotate,
        monochrome:this.monochrome
      }
    });
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      if(this.sql.verbose)console.log('Paper picked: ',a.data);
      this.printer_size = a.data.printer;
      this.printer_width = a.data.printer_width;
      this.printer_height = a.data.printer_height;
      this.page_size = a.data.name;
      this.page_width = a.data.width;
      this.page_height = a.data.height;
      this.rotate = a.data.rotate;
      this.slug = a.data.slug;
      this.monochrome = a.data.monochrome;
      this.sql.hapticsImpactMedium();
      this.refresh();
    } else {
      if(this.sql.more_verbose)console.log(a.data,'No Paper Selected! Please check paper environment variables settings or select your page again.');
      this.sql.hapticsError();
    }
  }
  zoom() {
    if(this.is_tablet)return;
    if(this.scale==0.45){
        this.scale=1;
    }else{
        this.scale=0.45;
    }
  }
  mail() {
    this.beggar();
  }
  async beggar() {
    const m = await this.modalController.create({
      component: PurchaseComponent, 
      componentProps: {}
    });
    await m.present();
    var a = await m.onDidDismiss();
  }
  ngOnInit() {
    this.papers=PaperSizes;
    this.get_addresses();
    // this.get_cheque();
    this.destroying = false;
    if(GenerateChequeComponent['design'])
      this.design = GenerateChequeComponent['design'];
  }
  ngOnDestroy() {
    this.destroying = true;
  }
  end() {
    this.modalController.dismiss();
  }
}
