import { SelectEmployeePage } from '../../employees/select-employee/select-employee.page';
import { SelectJobPage } from '../../jobs/select-job/select-job.page';
import { SelectPayeePage } from '../../payees/select-payee/select-payee.page';
import { SQLService } from '../../services/sql.service';
import { TimesheetService } from '../../services/timesheet.service';
import { NewTimesheetEntry } from '../../models/timesheetentries';
import { NewTimer } from '../../models/timer';
import { NewTimesheet } from '../../models/timesheet';
import { ModalController, Platform } from '@ionic/angular';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
@Component({
	selector: 'ng-cheques-new-timesheet',
	templateUrl: './new-timesheet.component.html',
	styleUrls: ['./new-timesheet.component.styl']
})
export class NewTimesheetPage implements OnDestroy {
	constructor(
		private router: Router,
		private sql: SQLService,
		private location: Location,
		private route: ActivatedRoute,
		private ts: TimesheetService,
		private modalController: ModalController
	) {
		this.router.events.subscribe((e) => {
			if(e instanceof NavigationEnd) {
				this.open();
			}
			if(e instanceof NavigationStart) {
				clearInterval(this.one_second_timer);
			}
		});
	}
	_c=0;
	count_entries=0;
	not_updated = true;
	one_second_timer:any;
	timesheet = new NewTimesheet();
	end() {
		if (this._c>0) {
			if (confirm(this.sql.word.Discard_Confirmation)) {
				this.timesheet = new NewTimesheet();
				this.router.navigate(['folder','Timesheets']);
			}
		} else {
			this.router.navigate(['folder','Timesheets']);
		}
	}
	async add_employee() {
		const m = await this.modalController.create({component: SelectEmployeePage, componentProps: {select_multiple: true}});
		await m.present();
		var a = await m.onDidDismiss();
		if(a.data){
			if(a.data==-1){
				this.delete_employee();
				this.sql.hapticsImpactLight();
			} else {
				this.timesheet.employee_ids = a.data;
				this.get_employees();
				this._c=1;
				this.sql.hapticsImpactMedium();  
			}
		}
		this.sql.remove_temporary('page');
	}
	edit_employee(employee) {
		this.router.navigate(['folder','Employee', employee.employee_id]); 
	}
	delete_employee() {
		this.timesheet.employees = null;
		this.timesheet.employee_ids = null;
		// this.sql.set( 'Timesheets', 'timesheet_id', this.timesheet.timesheet_id, 'employee_ids', null);
	}
	get_employees() {
		if(!this.timesheet.employee_ids)return;
		if(typeof this.timesheet.employee_ids === 'string'){
			this.timesheet.employee_ids=this.timesheet.employee_ids.split(',');
		} else if (typeof this.timesheet.employee_ids === 'number') {
			this.timesheet.employee_ids=[this.timesheet.employee_ids];
		}
		this.timesheet.employees=[];
		for(let a of this.timesheet.employee_ids) {
			this.sql.view('Employees','employee_id',a,(data)=>{
				for(let d of data)this.timesheet.employees.push(d);
			});
		}
	}
	async add_payee() {
		const m = await this.modalController.create({component: SelectPayeePage, componentProps: {select_multiple: true}});
		await m.present();
		var a = await m.onDidDismiss();
		if(a.data) {
			if(a.data==-1) {
				this.delete_payee();
				this.sql.hapticsImpactLight();
			} else {
				this.timesheet.payee_ids = a.data;
				this._c=1;
				this.get_payees();
				this.sql.hapticsImpactMedium();  
			}
		}
		this.sql.remove_temporary('page');
	}
	edit_payee(payee) {
		this.router.navigate(['folder','Payee', payee.payee_id]); 
	}
	delete_payee() {
		this.timesheet.payee_ids = null;
		this.timesheet.payees = null;
		// this.sql.set( 'Timesheets', 'timesheet_id', this.timesheet.timesheet_id, 'payee_ids', null);
	}
	get_payees() {
		if(!this.timesheet.payee_ids)return;
		if(typeof this.timesheet.payee_ids === 'string'){
			this.timesheet.payee_ids=this.timesheet.payee_ids.split(',');
		} else if (typeof this.timesheet.payee_ids === 'number') {
			this.timesheet.payee_ids=[this.timesheet.payee_ids];
		}
		this.timesheet.payees=[];
		for(let a of this.timesheet.payee_ids) {
			this.sql.view('Payees','payee_id',a,(data)=>{
				for(let d of data)this.timesheet.payees.push(d);
			});
		}
	}
	get_entries() {
		this.sql.count('TimesheetEntry WHERE timesheet_id = '+this.timesheet.timesheet_id,(data)=>{
			this.count_entries=data;
		});
	}
	delete_timesheet() {
		if(!this.timesheet.timesheet_id){
			this.end();
		} else if(confirm(this.sql.word.Delete_Timesheet_Confirmation)) {
			this.sql.delete('Timesheets','timesheet_id',this.timesheet.timesheet_id,()=>{
				this._c=0;
				this.end();
			});
		}
	}
	// async add_employee() {
	//   const m = await this.modalController.create({component: SelectEmployeePage, componentProps: {}});
	//   await m.present();
	//   let a = await m.onDidDismiss();
	//   this.timesheet.employees = a.data;
	//   if(a.data)this._c=1;
	//   if(a.data)this.sql.hapticsImpactMedium();
	// }
	// edit_employee(employee) {
	//   this.router.navigate(['folder','Employee', employee.employee_id]); 
	// }
	// delete_employee() {
	//   this.timesheet.employees = null;
	//   this.sql.query('UPDATE Timesheets WHERE timesheet_id = '+this.timesheet.timesheet_id+' SET employees = NULL');
	// }
	async add_job() {
		const m = await this.modalController.create({component: SelectJobPage});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data){
			if(a.data==-1){
				this.delete_job();
				this.sql.hapticsImpactLight();
			} else {
				this._c=1;
				this.timesheet.jobs = a.data;
				this.sql.hapticsImpactMedium();  
			}
		}
	}
	edit_job(job) {
		this.router.navigate(['folder','Job', job.job_id]);
	}
	delete_job() {
		this.timesheet.jobs = null;
		// this.sql.set( 'Timesheets', 'timesheet_id', this.timesheet.timesheet_id, 'jobs', null);
	}
	// async add_payee() {
	//   const m = await this.modalController.create({component: SelectPayeePage, componentProps: {}});
	//   await m.present();
	//   var a = await m.onDidDismiss();
	//   this.timesheet.payees = a.data;
	//   if(a.data)this._c=1;
	//   if(a.data)this.sql.hapticsImpactMedium();
	// }
	// edit_payee(payee) {
	//   this.router.navigate(['folder','Payee', payee.payee_id]); 
	// }
	// delete_payee() {
	//   this.timesheet.payees = null;
	//   this.sql.query('UPDATE Timesheets WHERE timesheet_id = '+this.timesheet.timesheet_id+' SET payees = NULL');
	// }
	save(end=true,cb?) {
		var now = new Date();
		this.timesheet.last_saved = now.getSeconds();
		for(let s of ['employee','payee'])
			if(this.timesheet[s+'_ids']&&typeof this.timesheet[s+'_ids']=='object')
				this.timesheet[s+'_ids'] = this.timesheet[s+'_ids'].join(',');
		this.sql.add("Timesheets",this.timesheet,(insertId)=>{
			if(!insertId)return
			this._c=0;
			if(!cb && end){
				this.end();
				this.timesheet = new NewTimesheet();
			}
			if(cb) {
				this.timesheet.timesheet_id = insertId;
				cb(insertId);
			}
		});
	}
	get_properties() {
		for (var i of ['employee','payee']) {
			var a = this.route.snapshot.paramMap.get(i+'id');
			if (a) this.timesheet[i+'_ids']=a;
		}
		this.get_employees();
		this.get_payees();
	}
	get_timesheet() {
		var ida = this.route.snapshot.paramMap.get("id");
		if (!ida) { 
			this.get_properties();
			return;
		} else if (ida=='Start'||ida=='Go') {
			this.start();
			this.get_properties();
			return;
		} else if (ida=='New'){
			this.get_properties();
		} else {
			this.sql.view('Timesheets','timesheet_id',ida,(e)=>{
				var f=Object.entries(e[0]);
				let n = new NewTimesheet();
				for(let t of f)n[t[0]]=t[1];
		        var a = n;
		        this.ts.calculate_seconds(n.timesheet_id,(h,m,s)=>{
		          a['seconds']=s;
		          a['minutes']=m;
		          a['hours']=h;
		          a=this.set_timer(a);
					this.timesheet=n;
					this.get_properties();
					this.get_entries();
			        if(this.timesheet.timer_active==1){
						this.start_timer();
					}
		        });
			});
		}
	}
	set_timer(a) {
		a.timer='';
		a.timer=a.timer+''+(a.days?a.days+':':'');
		a.timer=a.timer+''+(a.hours>0?a.hours:a.days>0?'00':'0')+':';
		a.timer=a.timer+''+(a.minutes>0?(a.minutes<10)?'0'+a.minutes:a.minutes:'00')+':';
		a.timer=a.timer+''+(a.seconds>0?(a.seconds<10)?'0'+a.seconds:a.seconds:'00');
		if(a.timer_active==1){
			a.end_date = null;
			a.end_time = null;
		}
		return a;
	}
	start( autosave=true ) {
		this.timesheet.timer_active=1;
		var d = new Date(Date.now()).toISOString();
		if (!this.timesheet.start_date)this.timesheet.start_date = d;
		if (!this.timesheet.start_time)this.timesheet.start_time = d;
		this.timesheet.last_start_time = d;
		this.timesheet.end_date = null;
		this.timesheet.end_time = null;
		this.start_timer();
		if (autosave) this.save(false,(r)=>{
			this.new_timer();
		});
	}
	stop( autosave=true ) {
		this.timesheet.timer_active=-1;
		clearInterval(this.one_second_timer);
		var d = new Date(Date.now()).toISOString();
		this.timesheet.end_date = d;
		this.timesheet.end_time = d;
		this.ts.kill_timer(this.timesheet.active_timer,()=>{
			if (autosave) {
				this.save(false,(r)=>{
					// this.get_timesheet();
				});	
			} else {
				// this.get_timesheet();
			}
		});
	}
	new_timer() {
		var entry = new NewTimesheetEntry();
			entry.start_date=new Date(Date.now()).toISOString();
			entry.start_time=new Date(Date.now()).toISOString();  
			entry.timesheet_id=this.timesheet.timesheet_id;
			this.sql.add("TimesheetEntry",entry,(id)=>{if(!id)return;
				this.timesheet.active_timer=id;
				this.save(false,()=>{
					this.get_entries();
				});
			});
	}
	
	start_timer() {
		if(this.timesheet.timer_active==-1)return;
		clearInterval(this.one_second_timer);
		this.one_second_timer = setInterval(()=>{
			this.timesheet.seconds=this.timesheet.seconds+1
			if(this.timesheet.seconds>59){
				this.timesheet.seconds=0
				this.timesheet.minutes=this.timesheet.minutes+1
			}
			if(this.timesheet.minutes>59){
				this.timesheet.minutes=0
				this.timesheet.hours=this.timesheet.hours+1
			}
			if(this.timesheet.hours>23){
				this.timesheet.hours=0
				this.timesheet.days=this.timesheet.days+1
			}
			this.timesheet=this.set_timer(this.timesheet);
		},1000);
	}
	open() {
		this.timesheet = new NewTimesheet();
		this.timesheet.timer_active=-1;
		this.timesheet.seconds=0;
		this.timesheet.minutes=0;
		this.timesheet.hours=0;
		this.timesheet.days=0;
		this.timesheet.start_date = new Date(Date.now()).toISOString();
		// this.timesheet.start_time = new Date(Date.now()).toISOString();
		this.timesheet.enabled = true;
		this.get_timesheet();
	}
	set_temp() {
		if (this.timesheet.timesheet_id) {
			this.sql.set_temporary('page',{location:'Timesheet',id:this.timesheet.timesheet_id});
		} else {
			this.save(false,(id)=>{
				if(!id)return;
				this.timesheet.timesheet_id=id;
				this.sql.set_temporary('page',{location:'Timesheet',id:id});
			});
		}
	}
	view_timers() {
		this.router.navigate(['folder','Timesheets','Entries',this.timesheet.timesheet_id]);
	}
	ngOnDestroy() { clearInterval(this.one_second_timer); }
}