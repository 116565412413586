import { environment } from '../../../environments/environment';
import { SQLService } from '../../services/sql.service';
import { BitCoinPurchaseComponent } from './bitcoin/bitcoin.component';
import { PaymentsService } from '../../services/payments.service';
import { TermsOfServiceComponent } from '../../settings/licences/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from '../../settings/licences/privacy-policy/privacy-policy.component';
import { EULAComponent } from '../../settings/licences/eula/eula.component';
import { TermsOfUseComponent } from '../../settings/licences/terms-of-use/terms-of-use.component'
import { DisclaimerComponent } from '../../settings/licences/disclaimer/disclaimer.component'
import { RefundsComponent } from '../../settings/licences/refunds/refunds.component'
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { Flip,fade } from '../../app.animations';
import { Component, OnDestroy, OnInit, Input, EventEmitter,ViewChild, ElementRef } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController, AlertController, Platform } from '@ionic/angular';
import { ProductKeyComponent } from '../../settings/product-key/product-key.component';
import * as Languages from '../../settings/licences/eula/languages';
import { InAppPurchase2, IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import $ from 'jquery';
declare var Stripe;
@Component({
	selector: 'app-purchase',
	templateUrl: './purchase.component.html',
	styleUrls: ['./purchase.component.styl'],
	animations: [ Flip,fade ]
})
export class PurchaseComponent implements OnInit, OnDestroy {
	constructor(
		private router: Router,
		public sql: SQLService,
		private route: ActivatedRoute,
		private navCtrl: ModalController,
		private alertController: AlertController,
		private current_platform: Platform,
		private payments: PaymentsService,
		private store: InAppPurchase2,
		private location: Location
	) {
		if(this.current_platform.is('ios')){this.platform='ios'}
		if(this.current_platform.is('android')){this.platform='google'}
	}
	@ViewChild('paypalButton') paypalButton: ElementRef;
	@ViewChild('ngxPayPal') ngxPayPal: ElementRef;
	platform: string;
	selected: any;
	count = -1;
	output = new EventEmitter();
	showSuccess: boolean;
	showCancel: boolean;
	showError: boolean;
	show_btc: boolean;
	licence_key = false;
	show_designs = false;
	restore_purchases = false;
	enable_stripe: boolean;
	enable_paypal: boolean;
	enable_paypal_btn: boolean;
	enable_mailout_prompt: boolean;
	paypal_active: boolean;
	enable_bitcoin: boolean;
	enable_buy: boolean;
	enable_ultimate: boolean;
	enable_override: boolean;
	show_purchases= true;
	img_loaded: boolean;
	TOSAgree: boolean;
	btc_address: string;
	_b: string;
	mode = 'apple';
	designs = [];
	products = [];
	purchases = [];
	categories = []; 
	confirm_purchase = [];
	async tos() {
		const m = await this.navCtrl.create({component: TermsOfServiceComponent, componentProps: {force:true}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {
		  this.sql.hapticsImpactLight();
		  console.log('Check Key', a.data);
		}
	}
	async pp() {
		const m = await this.navCtrl.create({component: PrivacyPolicyComponent, componentProps: {force:true}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {
		  this.sql.hapticsImpactLight();
		  console.log('Check Key', a.data);
		}
	}
	async eula() {
		const m = await this.navCtrl.create({component: EULAComponent, componentProps: {force:true}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {
		  this.sql.hapticsImpactLight();
		  console.log('Check Key', a.data);
		}
	}
	async tou() {
		const m = await this.navCtrl.create({component: TermsOfUseComponent, componentProps: {force:true}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {
		  this.sql.hapticsImpactLight();
		}
	}
	async dis() {
		const m = await this.navCtrl.create({component: DisclaimerComponent, componentProps: {force:true}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {
		  this.sql.hapticsImpactLight();
		}
	}
	async ref() {
		const m = await this.navCtrl.create({component: RefundsComponent, componentProps: {force:true}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {
		  this.sql.hapticsImpactLight();
		}
	}
	toggle_tos() {
		this.TOSAgree=this.TOSAgree?false:true;
	}
	async enter_licence_key() {
		const m = await this.navCtrl.create({component: ProductKeyComponent, componentProps: {force:true}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data){
			this.sql.hapticsImpactLight();
			this.get_purchased(false,()=>{
				setTimeout(()=>{this.close();},1923);
			});
		}
	}
	ngOnInit() {
		this.enable_buy = this.sql.enable_buy;
		this.enable_ultimate = this.sql.enable_ultimate;
		this.enable_override = this.sql.enable_override;
		this.categories = [
			{ alias: 'Subscriptions', mode: 'subscription', color: 'light_red', enabled: true },
			{ alias: 'Single_Cheques', mode: 'print', color: 'light_blue', enabled: true },
			{ alias: 'Product_Upgrades', mode: 'premium', color: 'light_purple', enabled: this.enable_ultimate }
		];
		if(environment.stripe_key && this.sql.stripe)this.enable_stripe=true;
		if(environment.btc_address && this.sql.bitcoin)this.enable_bitcoin=true;
		if(environment.paypal_client_id)this.enable_paypal=true;
		if(this.enable_buy){
			this.stripe_setup();
			this.btc_address = environment.btc_address;
		}
		this.payments.Setup();
		this.get_purchased();
		if(this.show_designs)this.get_designs();
		this.licence_key=this.sql.licence_key;
		if(this.current_platform.is('electron')) {
		  // this.licence_key = true;
		  this.restore_purchases = false;
		} else {
		  // this.licence_key = false;
		  this.restore_purchases = true;
		}
		$(document).ready(()=>{
			
	    });
	}
	cancel_subscription(item?) {
		this.confirm_purchase=[];
		if(item.active==1){
			if(confirm(this.sql.word.Remove_Subscription_Word)){
				this.show_purchases=false;
				this.sql.cancel_order(item.order_id,(order)=>{
					item.active=0;
					for(let i=0;i<this.products.length;i++) {
						if(this.products[i].id==item.product)this.products[i].active=false;
					}
					this.sql.setup_orders();
					this.purchases = [];
					setTimeout( () => {
						this.products = [];
						this.get_purchased(false);
						this.show_purchases=true;
					},1000);
				});
			}
		} else {
			var a;
			for(let p of this.products) {
				if(p.id==item.product)this.selected = p;
			}
			this.platform_buy();
		}
	}
	ngOnDestroy() {
		this.products = [];
		this.selected = [];
		this.purchases = [];
	}
	get_designs(event?) {
		this.sql.fetch('Designs',(e)=>{
			var n = [];
			for(let a of e)n.push(a);
			this.designs=n; this.count=n.length;
			setTimeout(()=>{
				if (event) event.target.complete();
			},1234);
		});
	}
	get_purchased(event?,cb?) {
		this.products = [];
		this.selected = [];
		for(let p of this.sql.products){
			p['purchased']=false;
			this.products.push(p);
		}
		this.sql.get_purchases((items)=>{
			if(items.length>0) {
				this.purchases = items;
				for (var j = 0; j < this.products.length; ++j) {
					for (var i = 0; i < this.purchases.length; ++i) {
						this.products[j]['active']=0;
						this.products[j]['purchased']=false;
						if (this.purchases[i].product==this.products[j].id) {
							if(this.purchases[i].active=='1'){
								this.products[j]['active']=true;
							} else { 
								this.products[j]['active']=false;
								if(this.selected==[]){
									this.selected=this.products[i];
									this.RunPayPalConfig();
								}
							}
							this.products[j]['purchased']=true;
						}
						if(this.purchases.length-1==i && this.products.length-1==j){
							if(this.selected==[]){
								this.selected=this.products[0];
								this.RunPayPalConfig();
							}
							if(cb)cb(this.products);
							setTimeout(()=>{if(event)event.target.complete();},1234);
							return;
						}
					}
				}
			} else {
				this.purchases = [];
				if(this.selected==[]){
					this.selected=this.products[0];
					this.RunPayPalConfig();
				}
				setTimeout(()=>{if (event) event.target.complete();},1234);
			}
		});
	}
	off() {
		this.get_purchased();
		this.navCtrl.dismiss();
	}
	confirm_buy(id) {
		if(this.sql.Unlimited) {
			alert(this.sql.word.Cheques_Subscription_Word);
			return;
		} else {
			if(this.confirm_purchase[id]){
				this.platform_buy();
			} else {
				this.confirm_purchase=[];
				this.confirm_purchase[id]=true;	
			}
		}
	}
	_cl() {
		this.confirm_purchase=[];
	}
	override() {
		if(!this.selected.alias)return;
		if(confirm(this.sql.word.Purchase_Confirmation+' '+this.selected.alias)==true) {
			this.sql.has_ordered(this.selected.id, (purchase_id)=>{
				var id = (purchase_id&&this.selected.type!='print') ? purchase_id : this.selected.id;
				this.payments.override(id, (purchase)=>{
					this.post_purchase(this.selected);
					console.log('Override purchase', this.selected, purchase);
				});
			});
		}
	}
	purchase() {
		if(!this.selected.alias)return;
		if(confirm(this.sql.word.Purchase_Confirmation+' '+this.selected.alias)==true) {
			this.sql.has_ordered(this.selected.id, (purchase_id)=>{
				var id = (purchase_id&&this.selected.type!='print') ? purchase_id : this.selected.id;
				this.payments.product_sold(id, (purchase)=>{
					this.post_purchase(this.selected);
					console.log('Purchase', this.selected, purchase);
				});
			});
		}
	}
	buy() {
		if(!this.selected.alias)return;
		if(confirm(this.sql.word.Purchase_Confirmation+' '+this.selected.alias)==true) {
			this.sql.has_ordered(this.selected.id, (purchase_id)=>{
				var id = (purchase_id&&this.selected.type!='print') ? purchase_id : this.selected.id;
				this.payments.product_sold(id, (purchase)=>{
					this.post_purchase(this.selected);
					console.log('New purchase', this.selected, purchase);
				});
			});
		}
	}
	select(product,f) {
		if(!this.sql.logged_in && this.sql.model=='web'){
			this.sql.show_signup_modal();
			this.sql.hapticsImpactMedium();
		} else {
			if (product.purchased&&product.active&&product.mode!='print') {
				alert(this.sql.word.You_Own_This_Product);
			} else {
				this.selected=product;
				this.confirm_buy(product.id);
				this.RunPayPalConfig();
				this.sql.hapticsImpactLight();
			}
			this.sql.hapticsImpactMedium();
		}
	}
	select_(product,f) {
		if(!this.sql.logged_in && this.sql.model=='web'){
			this.sql.hapticsImpactMedium();
			this.sql.show_signup_modal();
		} else {
			if (this.selected==product && product.active&&product.mode!='print') {
				alert(this.sql.word.You_Own_This_Product);
			} else {
				if (this.selected==product) this.confirm_buy(product.id);
			}
			this.selected=product;
			this.sql.hapticsImpactLight();
		}
	}
	post_purchase(item?) {
		this.sql.Purchase_Successful();
		this.get_purchased(false,()=>{
			setTimeout(()=>{this.close();},1923);
			return;
		});
	}
	platform_buy() {
		this.confirm_purchase=[];
		var lang = Languages[this.sql.Language.toLowerCase()].translation;
		this.present_terms(lang,(result)=>{
			if(result) {
				if(this.selected&&this.enable_override){
					this.override();
				} else if(this.selected&&this.current_platform.is('ios')){
					this.mode = 'apple';
					this.apple();
				} else if(this.selected&&(this.current_platform.is('android')||this.current_platform.is('capacitor')||this.current_platform.is('cordova'))){
					this.mode = 'google';
					this.google();
				} else {
					this.paypal();
				}
			} else {
				return;
			}
		});
	}
	async present_terms(t,cb?) {
		const alert = await this.alertController.create({
			header: this.sql.word.Terms_Required_Word,
			buttons: [
				{
					text:this.sql.word.Cancel,
					role:'cancel',
					handler: (data) => {
						if(data)if(cb)cb(false);
					}
				},{
					text:this.sql.word.Confirm,
					role:'agree',
					handler: (data) => {
						if(data)if(cb)cb(true);
					}
				}
			],
		  	inputs: [
				{
					type: 'textarea',
					placeholder: t.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, ' ').trim(),
					id: 'largealertbox',
					attributes: {
						disabled: true,
					}
				}
			],
		});
		if(this.sql.model!='electron'){
			await alert.present();
		}
	}
	apple() {
		if(this.selected){
			if(this.sql.verbose)console.log('Apple', this.selected);
			this.payments.apple_purchase(this.selected,(result)=>{
				if(result==true)this.post_purchase(this.selected);
			});	
		} else {
			alert(this.sql.word.Select_Multiple_Items_Prompt);
			this.sql.hapticsError();
		}
	}
	google() {
		if(this.selected){
			if(this.sql.verbose)console.log('Google', this.selected);
			this.payments.purchase(this.selected,(result)=>{
				if(result==true)this.post_purchase(this.selected);
			});
		} else {
			alert(this.sql.word.Select_Multiple_Items_Prompt);
			this.sql.hapticsError();
		}
	}
	paypal() {
		this.mode='paypal';
		this.paypalButton.nativeElement.click();
		this.ngxPayPal.nativeElement.click();
		this.enable_paypal_btn=true;
		// alert(this.sql.word.Select_PayPal);
	}
	restore_purchase() {
		this.store.refresh();
		this.sql.setup_orders();
	}
	async bitcoin() {
		if(this.selected){
			const m = await this.navCtrl.create({component: BitCoinPurchaseComponent, componentProps: {
				id: this.selected.id,
				name: this.selected.alias,
				price: this.selected.price
			}});
			await m.present();
			var a = await m.onDidDismiss();	
		}
	}
	public payPalConfig ? : IPayPalConfig;
	private RunPayPalConfig(): void {
		if(this.selected){
			this.payPalConfig = {
				currency: this.selected.currency,
				clientId: environment.paypal_client_id,
				createOrderOnClient: (data) => <ICreateOrderRequest> {
					intent: 'CAPTURE',
					purchase_units: [{
						amount: {
							currency_code: this.selected.currency,
							value: this.selected.price,
							breakdown: {
								item_total: {
									currency_code: this.selected.currency,
									value: this.selected.price
								}
							}
						},
						items: [{
							name: this.payments.flip_name(this.selected.id),
							quantity: '1',
							category: 'DIGITAL_GOODS',
							unit_amount: {
								currency_code: this.selected.currency,
								value: this.selected.price,
							}
						}]
					}]
				},
				advanced: {
					commit: 'true'
				},
				style: {
					label: 'checkout',
					layout: 'horizontal',
					tagline: false,
					color: 'black'
				},
				onApprove: (data, actions) => {
					if(this.sql.verbose)console.log('onApprove - transaction was approved, but not authorized', data, actions);
					actions.order.get().then(details => {
						if(this.sql.verbose)console.log('onApprove - you can get full order details inside onApprove: ', details);
					});
				},
				onClientAuthorization: (data) => {
					if(this.sql.verbose)console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
					this.sql.has_ordered(this.selected.id, (purchase_id)=>{
						var id = (purchase_id&&this.selected.type!='print') ? purchase_id : this.selected.id;
						this.payments.product_sold(id, (purchase)=>{
							this.post_purchase(this.selected);
							if(this.sql.verbose)console.log('PayPal purchase complete', this.selected, purchase, data);
						});
					});
					this.showSuccess = true;
				},
				onCancel: (data, actions) => {
					if(this.sql.verbose)console.log('PayPal OnCancel', data, actions);
					this.paypal_active = false;
					this.showCancel = true;
				},
				onError: (err) => {
					if(this.sql.verbose)console.log('PayPal OnError', err);
					this.showError = true;
				},
				onClick: (data, actions) => {
					if(this.sql.verbose)console.log('PayPal onClick', data, actions);
					this.paypal_active = true;
					this.reset();
				}
			};
			if(this.sql.more_verbose)console.log(this.payPalConfig);
		}
	}
	card: any;
	stripe: any;
	stripe_setup() {
		if(this.enable_stripe) {
			this.stripe = Stripe(environment.stripe_key);
			let elements = this.stripe.elements();
			var style = {
			  base: {
				color: '#32325d',
				lineHeight: '24px',
				fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
				  color: '#aab7c4'
				}
			  },
			  invalid: {
				color: '#fa755a',
				iconColor: '#fa755a'
			  }
			}
			this.card = elements.create('card', { style: style });
			this.card.mount('#card-element');
			this.card.addEventListener('change', event => {
			  var displayError = document.getElementById('card-errors');
			  if (event.error) {
				displayError.textContent = event.error.message;
			  } else {
				displayError.textContent = ''; 
			  }
			});
			var form = document.getElementById('payment-form');
			form.addEventListener('submit', event => {
			  event.preventDefault();
			  this.stripe.createSource(this.card).then(result => {
				if (result.error) {
				  var errorElement = document.getElementById('card-errors');
				  errorElement.textContent = result.error.message;
				} else {
				  console.log('Stripe', result);
				}
			  });
			});
		}
	}
	remaining_prints(){this.confirm_purchase=[];alert(this.sql.remaining_prints());}
	cheques_unlimited(){alert(this.sql.word.Cheques_Unlimited_Word+' '+this.sql.word.You_Own_This_Product);}
	cheques_subscription(item?) {
		var bought = false;
		for(let p of this.purchases) {
			if(p.product==item.product)bought=true;
		}
		if(!bought) {
			this.select(item,true);
			this.platform_buy();
		} else {
			this.cancel_subscription(item);
		}
	}
	remove_cheques(item,force?,cb?){
		if(force||confirm(this.sql.word.Delete_Confirmation)){
			if(force||this.sql.enable_override){
				var v = "DELETE FROM Orders WHERE product = '"+item.product+"'";
				this.sql.b_query(v,(item)=>{
					this.sql.setup_orders(()=>{
						this.purchases = [];
						if(cb)cb();
						setTimeout( () => {
							this.products = [];
							this.get_purchased(false);
							this.show_purchases=true;
						},1000);
					});
				});
			}
		}
	}
	close() {this.off();}
	async terms() {
		const m = await this.navCtrl.create({component: TermsAndConditionsComponent, componentProps: { }});
		await m.present();
		var a = await m.onDidDismiss();
	}
	async serial() {
		setTimeout(() => {this.sql.hapticsImpactHeavy();},440);
		const m = await this.navCtrl.create({component: ProductKeyComponent, componentProps: {}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {this.sql.hapticsImpactLight();}
	}
	reset() {
		this.showSuccess = false;
		this.showCancel = false;
		this.showError = false;
	}
}