export const Currencies = {
	"AUD":{
		name:"Australian Dollars",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"AUD",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"CAD":{
		name:"Canadian Dollars",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"CAD",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"EUR":{
		name:"Euros",
		unit:"Euro",
		units:"Euros",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"EUR",
		icon:"€",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"GBP":{
		name:"Great British Pounds",
		unit:"Pound",
		units:"Pounds",
		subunit:"Pence",
		subunits:"Pence",
		symbol:"GBP",
		icon:"£",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"USD":{
		name:"US Dollars",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"USD",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"BTC":{
		name:"Bitcoins",
		unit:"Bitcoin",
		units:"Bitcoins",
		subunit:"Millibit",
		subunits:"Millibits",
		micounits:"Satoshi",
		symbol:"BTC",
		icon:"₿",
		decimal_places:8,
		fiat:false,
		paypal:false
	},
	"DOGE":{
		name:"Dogecoin",
		unit:"Dogecoin",
		units:"Dogecoins",
		subunit:"Part",
		subunits:"Parts",
		symbol:"DOGE",
		icon:"Ɖ",
		decimal_places:4,
		fiat:false,
		paypal:false
	},
	"ETH":{
		name:"Ethereum",
		unit:"Ethereum",
		units:"Etherea",
		subunit:"Part",
		subunits:"Parts",
		symbol:"ETH",
		icon:"E",
		decimal_places:6,
		fiat:false,
		paypal:false
	},
	"BCH":{
		name:"Bitcoin Cash",
		unit:"Bitcoin Cash",
		units:"Bitcoins Cash",
		subunit:"Bitcoin Cent",
		subunits:"Bitcoin Cents",
		symbol:"BCH",
		icon:"₿",
		decimal_places:5,
		fiat:false,
		paypal:false
	},
	"DASH":{
		name:"Dash",
		unit:"Dash",
		units:"Dash",
		subunit:"Part",
		subunits:"Parts",
		symbol:"DASH",
		icon:"D",
		decimal_places:5,
		fiat:false,
		paypal:false
	},
	"AED":{
		name:"Emirati Dirhams",
		unit:"Dirham",
		units:"Darahim",
		subunit:"Fils",
		subunits:"Fulūs",
		symbol:"AED",
		icon:"د.إ",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"AFN":{
		name:"Afghan Afghanis",
		unit:"Afghani",
		units:"Afghanis",
		subunit:"Pūl",
		subunits:"Pūls",
		symbol:"AFN",
		icon:"Af",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"ALL":{
		name:"Albanian Lekët",
		unit:"Lek",
		units:"Leke",
		subunit:"Qindarkë",
		subunits:"Qindarka",
		symbol:"ALL",
		icon:"L",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"AMD":{
		name:"Armenian Drams",
		unit:"Dram",
		units:"Drams",
		subunit:"Luma",
		subunits:"Luma",
		symbol:"AMD",
		icon:"֏",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"ANG":{
		name:"Netherlands Antillean Guilders",
		unit:"Guilder",
		units:"Guilders",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"ANG",
		icon:"ƒ",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"AOA":{
		name:"Angolan Kwanza",
		unit:"Kwanza",
		units:"Kwanzas",
		subunit:"Céntimo",
		subunits:"Céntimos",
		symbol:"AOA",
		icon:"Kz",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"ARS":{
		name:"Argentine Pesos",
		unit:"Peso",
		units:"Pesos",
		subunit:"Centavo",
		subunits:"Centavos",
		symbol:"ARS",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"AWG":{
		name:"Aruban Florins",
		unit:"Florin",
		units:"Florins",
		subunit:"Quattrino",
		subunits:"Quattrini",
		symbol:"AWG",
		icon:"ƒ",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"AZN":{
		name:"Azerbaijani Manat",
		unit:"Manat",
		units:"Manats",
		subunit:"Qapik",
		subunits:"Qapik",
		symbol:"AZN",
		icon:"₼",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"BAM":{
		name:"Bosnia-Herzegovina Convertible Mark",
		unit:"Mark",
		units:"Marks",
		subunit:"Fening",
		subunits:"Fenings",
		symbol:"BAM",
		icon:"KM",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"BBD":{
		name:"Bajan Dollars",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"BBD",
		icon:"Bds$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"BDT":{
		name:"Bangladeshi Taka",
		unit:"Taka",
		units:"Takas",
		subunit:"Paisa",
		subunits:"Pice",
		symbol:"BDT",
		icon:"৳",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"BGN":{
		name:"Bulgarian Lev",
		unit:"Lev",
		units:"Levs",
		subunit:"Stotinka",
		subunits:"Stotinki",
		symbol:"BGN",
		icon:"Лв.",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"BHD":{
		name:"Bahraini Dinars",
		unit:"Dinar",
		units:"Dinars",
		subunit:"Fils",
		subunits:"Fulūs",
		symbol:"BHD",
		icon:"$",
		decimal_places:3,
		fiat:true,
		paypal:false
	},
	"BIF":{
		name:"Burundian Francs",
		unit:"Franc",
		units:"Francs",
		subunit:"Centime",
		subunits:"Centimes",
		symbol:"BIF",
		icon:"FBu",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"BMD":{
		name:"Bermudan Dollars",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"BMD",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"BND":{
		name:"Brunei Dollars",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"BND",
		icon:"B$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"BOB":{
		name:"Bolivian Boliviano",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"BOB",
		icon:"Bs.",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"BRL":{
		name:"Brazilian Real",
		unit:"Real",
		units:"Reals",
		subunit:"Centavo",
		subunits:"Centavos",
		symbol:"BRL",
		icon:"R$",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"BSD":{
		name:"Bahamian Dollar",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"BSD",
		icon:"B$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"BTN":{
		name:"Bhutan Ngultrum",
		unit:"Ngultrum",
		units:"Ngultrums",
		subunit:"Chhertrum",
		subunits:"Chhertum",
		symbol:"BTN",
		icon:"Nu.",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"BWP":{
		name:"Botswanan Pula",
		unit:"Pula",
		units:"Pulas",
		subunit:"Thebe",
		subunits:"Thebes",
		symbol:"BWP",
		icon:"P",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"BYN":{
		name:"Belarusian Ruble",
		unit:"Ruble",
		units:"Rubles",
		subunit:"Kopek",
		subunits:"Kopeks",
		symbol:"BYN",
		icon:"Br",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"BZD":{
		name:"Belize Dollar",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"BZD",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"CDF":{
		name:"Congolese Francs",
		unit:"Franc",
		units:"Francs",
		subunit:"Centime",
		subunits:"Centimes",
		symbol:"CDF",
		icon:"FC",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"CHE":{
		name:"Swiss Francs [CHE]",
		unit:"Franc",
		units:"Francs",
		subunit:"Centime",
		subunits:"Centimes",
		symbol:"CHE",
		icon:"fr.",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"CHF":{
		name:"Swiss Francs [CHF]",
		unit:"Franc",
		units:"Francs",
		subunit:"Centime",
		subunits:"Centimes",
		symbol:"CHF",
		icon:"fr.",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"CHW":{
		name:"Swiss Francs [CHW]",
		unit:"Franc",
		units:"Francs",
		subunit:"Centime",
		subunits:"Centimes",
		symbol:"CHW",
		icon:"fr.",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"CLF":{
		name:"Chilean Unit of Account",
		unit:"Unit",
		units:"Units",
		subunit:"Point",
		subunits:"Points",
		symbol:"CLF",
		icon:"UF",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"CLP":{
		name:"Chilean Pesos",
		unit:"Peso",
		units:"Pesos",
		subunit:"Centavo",
		subunits:"Centavos",
		symbol:"CLP",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"CNY":{
		name:"Chinese Yuan",
		unit:"Yuan",
		units:"Yuan",
		subunit:"Fēn",
		subunits:"Fēn",
		symbol:"CNY",
		icon:"¥",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"COP":{
		name:"Colombian Pesos",
		unit:"Peso",
		units:"Pesos",
		subunit:"Centavo",
		subunits:"Centavos",
		symbol:"COP",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"COU":{
		name:"COU",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"COU",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"CRC":{
		name:"Costa Rican Colones",
		unit:"Colón",
		units:"Colóns",
		subunit:"Céntimo",
		subunits:"Céntimos",
		symbol:"CRC",
		icon:"₡",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"CUC":{
		name:"Cuban Convertible Pesos",
		unit:"Peso",
		units:"Pesos",
		subunit:"Centavo",
		subunits:"Centavos",
		symbol:"CUC",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"CUP":{
		name:"Cuban Pesos",
		unit:"Peso",
		units:"Pesos",
		subunit:"Centavo",
		subunits:"Centavos",
		symbol:"CUP",
		icon:"₱",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"CVE":{
		name:"Cape Verdean Escudo",
		unit:"Escudo",
		units:"Escudos",
		subunit:"Centavo",
		subunits:"Centavos",
		symbol:"CVE",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"CZK":{
		name:"Czech Koruna",
		unit:"Koruna",
		units:"Korunas",
		subunit:"Haléř",
		subunits:"Haléřů",
		symbol:"CZK",
		icon:"Kč",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"DJF":{
		name:"Djiboutian Francs",
		unit:"Franc",
		units:"Francs",
		subunit:"Centime",
		subunits:"Centimes",
		symbol:"DJF",
		icon:"Fdj",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"DKK":{
		name:"Danish Krones",
		unit:"Krone",
		units:"Krones",
		subunit:"Øre",
		subunits:"Øre",
		symbol:"DKK",
		icon:"Kr.",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"DOP":{
		name:"Dominican Pesos",
		unit:"Peso",
		units:"Pesos",
		subunit:"Centavo",
		subunits:"Centavos",
		symbol:"DOP",
		icon:"Rd$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"DZD":{
		name:"Algerian Dinars",
		unit:"Dinar",
		units:"Dinars",
		subunit:"Centime",
		subunits:"Centimes",
		symbol:"DZD",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"EGP":{
		name:"Egyptian Pounds",
		unit:"Pound",
		units:"Pounds",
		subunit:"Piastre",
		subunits:"Piastres",
		symbol:"EGP",
		icon:"ج.م",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"ERN":{
		name:"Eritrean Nakfas",
		unit:"Nakfa",
		units:"Nakfas",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"ERN",
		icon:"ናቕፋ",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"ETB":{
		name:"Ethiopian Birrs",
		unit:"Birr",
		units:"Birrs",
		subunit:"Santim",
		subunits:"Santim",
		symbol:"ETB",
		icon:"ብር",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"FJD":{
		name:"Fijian Dollars",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"FJD",
		icon:"FJ$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"FKP":{
		name:"Falkland Island Pounds",
		unit:"Pound",
		units:"Pounds",
		subunit:"Penny",
		subunits:"Pennies",
		symbol:"FKP",
		icon:"£",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"GEL":{
		name:"Georgian Lari",
		unit:"Lari",
		units:"Lari",
		subunit:"Tetri",
		subunits:"Tetri",
		symbol:"GEL",
		icon:"ლ",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"GHS":{
		name:"Ghanaian Cedis",
		unit:"Cedi",
		units:"Cedis",
		subunit:"Pesewa",
		subunits:"Pesewas",
		symbol:"GHS",
		icon:"GH¢",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"GIP":{
		name:"Gibraltar Pounds",
		unit:"Pound",
		units:"Pounds",
		subunit:"Penny",
		subunits:"Pennies",
		symbol:"GIP",
		icon:"£",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"GMD":{
		name:"Gambian Dalasi",
		unit:"Dalasi",
		units:"Dalasis",
		subunit:"Butut",
		subunits:"Bututs",
		symbol:"GMD",
		icon:"D",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"GNF":{
		name:"Guinean Franc",
		unit:"Franc",
		units:"Francs",
		subunit:"Centime",
		subunits:"Centimes",
		symbol:"GNF",
		icon:"GFr",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"GTQ":{
		name:"Guatemalan Quetzal",
		unit:"Quetzal",
		units:"Quetzales",
		subunit:"Centavo",
		subunits:"Centavos",
		symbol:"GTQ",
		icon:"Q",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"GYD":{
		name:"Guyanaese Dollar",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"GYD",
		icon:"G$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"HKD":{
		name:"Hong Kong Dollars",
		unit:"HK Dollar",
		units:"HK Dollars",
		subunit:"Sin",
		subunits:"Sin",
		symbol:"HKD",
		icon:"元",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"HNL":{
		name:"Honduran Lempira",
		unit:"Lempira",
		units:"Lempiras",
		subunit:"Centavo",
		subunits:"Centavos",
		symbol:"HNL",
		icon:"L",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"HRK":{
		name:"Croatian Kuna",
		unit:"Kuna",
		units:"Kuna",
		subunit:"Lipa",
		subunits:"Lipa",
		symbol:"HRK",
		icon:"kn",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"HTG":{
		name:"Haitian Gourde",
		unit:"Gourde",
		units:"Gourdes",
		subunit:"Centime",
		subunits:"Centimes",
		symbol:"HTG",
		icon:"G",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"HUF":{
		name:"Hungarian Forints",
		unit:"Forint",
		units:"Forints",
		subunit:"Fillér",
		subunits:"Fillér",
		symbol:"HUF",
		icon:"Ft",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"IDR":{
		name:"Indonesian Rupiah",
		unit:"Rupiah",
		units:"Rupiah",
		subunit:"Sen",
		subunits:"Sen",
		symbol:"IDR",
		icon:"Rp",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"ILS":{
		name:"Israeli New Shekels",
		unit:"Shekel",
		units:"Shekels",
		subunit:"Agora",
		subunits:"Agoras",
		symbol:"ILS",
		icon:"₪",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"INR":{
		name:"Indian Rupees",
		unit:"Rupee",
		units:"Rupees",
		subunit:"Paisa",
		subunits:"Paise",
		symbol:"INR",
		icon:"₹",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"IQD":{
		name:"Iraqi Dinars",
		unit:"Dinar",
		units:"Dinars",
		subunit:"Fils",
		subunits:"Fulūs",
		symbol:"IQD",
		icon:"ع.د",
		decimal_places:3,
		fiat:true,
		paypal:false
	},
	"IRR":{
		name:"Iranian Rials",
		unit:"Rial",
		units:"Rials",
		subunit:"More",
		subunits:"More",
		symbol:"IRR",
		icon:"﷼",
		decimal_places:0,
		fiat:true,
		paypal:false
	},
	"ISK":{
		name:"Icelandic Krónas",
		unit:"Króna",
		units:"Krónur",
		subunit:"Eyrir",
		subunits:"Aurar",
		symbol:"ISK",
		icon:"kr",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"JMD":{
		name:"Jamaican Dollars",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"JMD",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"JOD":{
		name:"Jordanian Dinars",
		unit:"Dinar",
		units:"Dinars",
		subunit:"Qirsh",
		subunits:"Qirsh",
		symbol:"JOD",
		icon:"د.ا",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"JPY":{
		name:"Japanese Yen",
		unit:"Yen",
		units:"Yen",
		subunit:"Rin",
		subunits:"Rin",
		symbol:"JPY",
		icon:"JP¥",
		decimal_places:3,
		fiat:true,
		paypal:true
	},
	"KES":{
		name:"Kenyan Shillings",
		unit:"Shilling",
		units:"Shillings",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"KES",
		icon:"K",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"KGS":{
		name:"Kyrgyzstani som",
		unit:"Som",
		units:"Som",
		subunit:"Tyiyn",
		subunits:"Tyiyn",
		symbol:"KGS",
		icon:"Лв",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"KHR":{
		name:"Cambodian Riels",
		unit:"Riel",
		units:"Rieles",
		subunit:"Kak",
		subunits:"Kaks",
		symbol:"KHR",
		icon:"៛",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"KMF":{
		name:"Comorian Francs",
		unit:"Franc",
		units:"Francs",
		subunit:"Centime",
		subunits:"Centimes",
		symbol:"KMF",
		icon:"CF",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"KPW":{
		name:"North Korean Won",
		unit:"Won",
		units:"Won",
		subunit:"Chon",
		subunits:"Chon",
		symbol:"KPW",
		icon:"₩",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"KRW":{
		name:"South Korean Won",
		unit:"Won",
		units:"Won",
		subunit:"Jeon",
		subunits:"Jeon",
		symbol:"KRW",
		icon:"₩",
		decimal_places:0,
		fiat:true,
		paypal:false
	},
	"KWD":{
		name:"Kuwaiti Dinars",
		unit:"Dinar",
		units:"Dinars",
		subunit:"Fils",
		subunits:"Fulūs",
		symbol:"KWD",
		icon:"$",
		decimal_places:3,
		fiat:true,
		paypal:false
	},
	"KYD":{
		name:"Cayman Islands Dollars",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"KYD",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"KZT":{
		name:"Kazakhstani Tenge",
		unit:"Tenge",
		units:"Tenges",
		subunit:"Tiyn",
		subunits:"Tiyn",
		symbol:"KZT",
		icon:"₸",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"LAK":{
		name:"Laotian Kip",
		unit:"Kip",
		units:"Kip",
		subunit:"Att",
		subunits:"Att",
		symbol:"LAK",
		icon:"₭",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"LBP":{
		name:"Lebanese Pounds",
		unit:"Pound",
		symbol:"LBP",
		units:"Pounds",
		subunit:"Piastre",
		subunits:"Piastres",
		icon:"ل.ل.‎",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"LKR":{
		name:"Sri Lankan Rupees",
		unit:"Rupee",
		units:"Rupees",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"LKR",
		icon:"ය",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"LRD":{
		name:"Liberian Dollar",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"LRD",
		icon:"L$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"LSL":{
		name:"Lesotho Loti",
		unit:"Loti",
		units:"Loti",
		subunit:"Sente",
		subunits:"Lisente",
		symbol:"LSL",
		icon:"L",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"LYD":{
		name:"Libyan Dinars",
		unit:"Dinar",
		units:"Dinars",
		subunit:"Dirham",
		subunits:"Dirhams",
		symbol:"LYD",
		icon:"ل.د",
		decimal_places:3,
		fiat:true,
		paypal:false
	},
	"MAD":{
		name:"Moroccan Dirhams",
		unit:"Dirham",
		units:"Dirhams",
		subunit:"Centime",
		subunits:"Centimes",
		symbol:"MAD",
		icon:"د. م",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"MDL":{
		name:"Moldovan Lei",
		unit:"Leu",
		units:"Leus",
		subunit:"Ban",
		subunits:"Bani",
		symbol:"MDL",
		icon:"L",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"MGA":{
		name:"Malagasy Ariary",
		unit:"Ariary",
		units:"Ariary",
		subunit:"Iraimbilanja",
		subunits:"Iraimbilanja",
		symbol:"MGA",
		icon:"Ar",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"MKD":{
		name:"Macedonian Denars",
		unit:"Denar",
		units:"Denars",
		subunit:"Deni",
		subunits:"Deni",
		symbol:"MKD",
		icon:"Ден",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"MMK":{
		name:"Myanmar Kyats",
		unit:"Kyat",
		units:"Kyats",
		subunit:"Pya",
		subunits:"Pya",
		symbol:"MMK",
		icon:"K",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"MNT":{
		name:"Mongolian Tughriks",
		unit:"Tughrik",
		units:"Tughriks",
		subunit:"Möngö",
		subunits:"Möngö",
		symbol:"MNT",
		icon:"₮",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"MOP":{
		name:"Macanese Patacas",
		unit:"Pataca",
		units:"Patacas",
		subunit:"Avos",
		subunits:"Avos",
		symbol:"MOP",
		icon:"MOP$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"MUR":{
		name:"Mauritian Rupees",
		unit:"Rupee",
		units:"Rupees",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"MUR",
		icon:"₨",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"MVR":{
		name:"Maldivian Rufiyaa",
		unit:"Rufiyaa",
		units:"Rufiyaas",
		subunit:"Laari",
		subunits:"Laari",
		symbol:"MVR",
		icon:".ރ",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"MWK":{
		name:"Malawian Kwachas",
		unit:"Kwacha",
		units:"Kwachas",
		subunit:"Tambala",
		subunits:"Tambala",
		symbol:"MWK",
		icon:"MK",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"MXN":{
		name:"Mexican Pesos",
		unit:"Peso",
		units:"Pesos",
		subunit:"Centavo",
		subunits:"Centavos",
		symbol:"MXN",
		icon:"Mex$",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"MYR":{
		name:"Malaysian Ringgits",
		unit:"Ringgits",
		units:"Ringgits",
		subunit:"Sen",
		subunits:"Sen",
		symbol:"MYR",
		icon:"RM",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"MZN":{
		name:"Mozambican Metical",
		unit:"Metical",
		units:"Meticais",
		subunit:"Centavo",
		subunits:"Centavos",
		symbol:"MZN",
		icon:"MT",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"NAD":{
		name:"Namibian Dollars",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"NAD",
		icon:"N$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"NGN":{
		name:"Nigerian Nairas",
		unit:"Naira",
		units:"Nairas",
		subunit:"Kobo",
		subunits:"Kobo",
		symbol:"NGN",
		icon:"₦",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"NIO":{
		name:"Nicaraguan Córdobas",
		unit:"Córdoba",
		units:"Córdobas",
		subunit:"Centavo",
		subunits:"Centavos",
		symbol:"NIO",
		icon:"C$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"NOK":{
		name:"Norwegian Krones",
		unit:"Krone",
		units:"Krones",
		subunit:"Øre",
		subunits:"Øre",
		symbol:"NOK",
		icon:"kr",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"NPR":{
		name:"Nepalese Rupees",
		unit:"Rupee",
		units:"Rupees",
		subunit:"Paisa",
		subunits:"Paisas",
		symbol:"NPR",
		icon:"Re.",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"NZD":{
		name:"New Zealand Dollar",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"NZD",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"OMR":{
		name:"Omani Rials",
		unit:"Rial",
		units:"Rials",
		subunit:"Baisa",
		subunits:"Baisa",
		symbol:"OMR",
		icon:"ر.ع.",
		decimal_places:4,
		fiat:true,
		paypal:false
	},
	"PAB":{
		name:"Panamanian Balboa",
		unit:"Balboa",
		units:"Balboas",
		subunit:"Centesimo",
		subunits:"Centesimos",
		symbol:"PAB",
		icon:"B/.",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"PEN":{
		name:"Sol",
		unit:"Sol",
		units:"Soles",
		subunit:"Céntimo",
		subunits:"Céntimos",
		symbol:"PEN",
		icon:"S",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"PGK":{
		name:"Papua New Guinean Kina",
		unit:"Kina",
		units:"Kinas",
		subunit:"Toea",
		subunits:"Toea",
		symbol:"PGK",
		icon:"K",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"PHP":{
		name:"Philippine Pesos",
		unit:"Peso",
		units:"Pesos",
		subunit:"Sentimo",
		subunits:"Sentimos",
		symbol:"PHP",
		icon:"₱",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"PKR":{
		name:"Pakistani Rupees",
		unit:"Rupee",
		units:"Rupees",
		subunit:"Paisa",
		subunits:"Paisas",
		symbol:"PKR",
		icon:"₨",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"PLN":{
		name:"Poland Złoty",
		unit:"Złoty",
		units:"Złotys",
		subunit:"Grosz",
		subunits:"Grosze",
		symbol:"PLN",
		icon:"zł",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"PYG":{
		name:"Paraguayan Guarani",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Céntimo",
		subunits:"Céntimos",
		symbol:"PYG",
		icon:"₲",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"QAR":{
		name:"Qatari Rials",
		unit:"Rial",
		units:"Rials",
		subunit:"Dirham",
		subunits:"Dirhams",
		symbol:"QAR",
		icon:"QR",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"RON":{
		name:"Romanian Lei",
		unit:"Leu",
		units:"Leus",
		subunit:"Ban",
		subunits:"Bani",
		symbol:"RON",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"RSD":{
		name:"Serbian Dinars",
		unit:"Dinar",
		units:"Dinars",
		subunit:"Para",
		subunits:"Para",
		symbol:"RSD",
		icon:"din",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"RUB":{
		name:"Russian Rubles",
		unit:"Ruble",
		units:"Rubles",
		subunit:"Kopek",
		subunits:"Kopeks",
		symbol:"RUB",
		icon:"₽",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"RWF":{
		name:"Rwandan Francs",
		unit:"Franc",
		units:"Francs",
		subunit:"Centime",
		subunits:"Centimes",
		symbol:"RWF",
		icon:"r₣",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"SAR":{
		name:"Saudi Riyals",
		unit:"Riyal",
		units:"Riyals",
		subunit:"Halalah",
		subunits:"Halalas",
		symbol:"SAR",
		icon:"SR",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"SBD":{
		name:"Solomon Islands Dollar",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"SBD",
		icon:"Si$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"SCR":{
		name:"Seychellois Rupees",
		unit:"Rupee",
		units:"Rupees",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"SCR",
		icon:"SR",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"SDG":{
		name:"Sudanese Pounds",
		unit:"Pound",
		units:"Pounds",
		subunit:"Piastre",
		subunits:"Piastres",
		symbol:"SDG",
		icon:"ج.س.",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"SEK":{
		name:"Swedish Kronor",
		unit:"Krona",
		units:"Kronor",
		subunit:"Öre",
		subunits:"Ören",
		symbol:"SEK",
		icon:"kr",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"SGD":{
		name:"Singapore Dollars",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"SGD",
		icon:"S$",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"SHP":{
		name:"Saint Helenian Pounds",
		unit:"Pound",
		units:"Pounds",
		subunit:"Pence",
		subunits:"Pence",
		symbol:"SHP",
		icon:"£",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"SLL":{
		name:"Sierra Leonean Leones",
		unit:"Leone",
		units:"Leones",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"SLL",
		icon:"Le",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"SOS":{
		name:"Somali Shillings",
		unit:"Shilling",
		units:"Shillings",
		subunit:"Senti",
		subunits:"Senti",
		symbol:"SOS",
		icon:"Sh.so.",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"SRD":{
		name:"Surinamese Dollars",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"SRD",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"SSP":{
		name:"South Sudanese Pounds",
		unit:"Pound",
		units:"Pounds",
		subunit:"Piastre",
		subunits:"Piastres",
		symbol:"SSP",
		icon:"£",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"STN":{
		name:"Sao Tomean Dobras",
		unit:"Dobra",
		units:"Dobras",
		subunit:"Cêntimo",
		subunits:"Cêntimos",
		symbol:"STN",
		icon:"Db",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"SVC":{
		name:"Salvadoran Colones",
		unit:"Colón",
		units:"Colóns",
		subunit:"Centavo",
		subunits:"Centavos",
		symbol:"SVC",
		icon:"₡",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"SYP":{
		name:"Syrian Pounds",
		unit:"Pound",
		units:"Pounds",
		subunit:"Piastre",
		subunits:"Piastres",
		symbol:"SYP",
		icon:"LS",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"SZL":{
		name:"Swazi Lilangeni",
		unit:"Lilangeni",
		units:"Lilangenis",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"SZL",
		icon:"E",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"THB":{
		name:"Thai Baht",
		unit:"Baht",
		units:"Bahts",
		subunit:"Satang",
		subunits:"Satang",
		symbol:"THB",
		icon:"฿",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"TJS":{
		name:"Tajikistani Somonis",
		unit:"Somoni",
		units:"Somonis",
		subunit:"Diram",
		subunits:"Dirams",
		symbol:"TJS",
		icon:"ЅM",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"TMT":{
		name:"Turkmenistani Manats",
		unit:"Manat",
		units:"Manats",
		subunit:"Tenge",
		subunits:"Tenge",
		symbol:"TMT",
		icon:"T",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"TND":{
		name:"Tunisian Dinars",
		unit:"Dinar",
		units:"Dinars",
		subunit:"Milim",
		subunits:"Millimes",
		symbol:"TND",
		icon:"د.ت",
		decimal_places:4,
		fiat:true,
		paypal:false
	},
	"TOP":{
		name:"Tongan Paʻanga",
		unit:"paʻanga",
		units:"paʻangas",
		subunit:"Seniti",
		subunits:"Seniti",
		symbol:"TOP",
		icon:"PT",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"TRY":{
		name:"Turkish Lire",
		unit:"Lira",
		units:"Liras",
		subunit:"Kuruş",
		subunits:"Kuruş",
		symbol:"TRY",
		icon:"₺",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"TTD":{
		name:"Trinidad & Tobago Dollars",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"TTD",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"TWD":{
		name:"New Taiwan Dollars",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"TWD",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:true
	},
	"TZS":{
		name:"Tanzanian Shillings",
		unit:"Shilling",
		units:"Shillings",
		subunit:"Senti",
		subunits:"Senti",
		symbol:"TZS",
		icon:"TSh",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"UAH":{
		name:"Ukrainian Hryvni",
		unit:"Hryvnia",
		units:"Hryvnias",
		subunit:"Kopiyka",
		subunits:"Kopiyky",
		symbol:"UAH",
		icon:"₴",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"UGX":{
		name:"Ugandan Shillings",
		unit:"Shilling",
		units:"Shillings",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"UGX",
		icon:"USh",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"USN":{
		name:"US Dollars (Nexy Day) [USN]",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"USN",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"UYI":{
		name:"Uruguayan Pesos [UYI]",
		unit:"Peso",
		units:"Pesos",
		subunit:"Centesimo",
		subunits:"Centesimos",
		symbol:"UYI",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"UYU":{
		name:"Uruguayan Pesos [UYU]",
		unit:"Peso",
		units:"Pesos",
		subunit:"Centesimo",
		subunits:"Centesimos",
		symbol:"UYU",
		icon:"$U",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"UZS":{
		name:"Uzbekistani Sums",
		unit:"Som",
		units:"Soms",
		subunit:"Tyiyn",
		subunits:"Tyiyn",
		symbol:"UZS",
		icon:"so'm",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"VES":{
		name:"Venezuelan Bolívares",
		unit:"Bolivar",
		units:"Bolivars",
		subunit:"Céntimo",
		subunits:"Céntimos",
		symbol:"VES",
		icon:"Bs",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"VND":{
		name:"Vietnamese Dongs",
		unit:"Dong",
		units:"Dongs",
		subunit:"Hào",
		subunits:"Hào",
		symbol:"VND",
		icon:"₫",
		decimal_places:1,
		fiat:true,
		paypal:false
	},
	"VUV":{
		name:"Ni-Vanuatu Vatu",
		unit:"Vatu",
		units:"Vatus",
		subunit:"More",
		subunits:"More",
		symbol:"VUV",
		icon:"VT",
		decimal_places:0,
		fiat:true,
		paypal:false
	},
	"WST":{
		name:"Samoan Tala",
		unit:"Tala",
		units:"Talas",
		subunit:"Sene",
		subunits:"Senes",
		symbol:"WST",
		icon:"T",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"XAF":{
		name:"Central African CFA Francs",
		unit:"Franc",
		units:"Francs",
		subunit:"Centime",
		subunits:"Centimes",
		symbol:"XAF",
		icon:"FCFA",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"XAG":{
		name:"Silver Ounces [XAG]",
		unit:"Silver",
		units:"Silvers",
		subunit:"Point",
		subunits:"Points",
		symbol:"XAG",
		icon:"oz.",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"XAU":{
		name:"Gold Ounces [XAU]",
		unit:"Gold",
		units:"Golds",
		subunit:"Point",
		subunits:"Points",
		symbol:"XAU",
		icon:"oz.",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"XPD":{
		name:"Palladium Ounces [XPD]",
		unit:"Palladium",
		units:"Palladiums",
		subunit:"Point",
		subunits:"Points",
		symbol:"XPD",
		icon:"oz.",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"XPT":{
		name:"Platinum Ounces [XPT]",
		unit:"Platinum",
		units:"Platinums",
		subunit:"Point",
		subunits:"Points",
		symbol:"XPT",
		icon:"oz.",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"XCD":{
		name:"East Caribbean Dollars",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"XCD",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"XOF":{
		name:"West African CFA Francs",
		unit:"Franc",
		units:"Francs",
		subunit:"Centime",
		subunits:"Centimes",
		symbol:"XOF",
		icon:"CFA",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"XPF":{
		name:"CFP Francs",
		unit:"Franc",
		units:"Francs",
		subunit:"Centime",
		subunits:"Centimes",
		symbol:"XPF",
		icon:"₣",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"YER":{
		name:"Yemeni Rials",
		unit:"Rial",
		units:"Rials",
		subunit:"Fils",
		subunits:"Fulūs",
		symbol:"YER",
		icon:"﷼",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"ZAR":{
		name:"South African Rand",
		unit:"Rand",
		units:"Rands",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"ZAR",
		icon:"R",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"ZMW":{
		name:"Zambian Kwacha",
		unit:"Kwacha",
		units:"Kwachas",
		subunit:"Ngwee",
		subunits:"Ngwees",
		symbol:"ZMW",
		icon:"ZK",
		decimal_places:2,
		fiat:true,
		paypal:false
	},
	"ZWL":{
		name:"Zimbabwean Dollars",
		unit:"Dollar",
		units:"Dollars",
		subunit:"Cent",
		subunits:"Cents",
		symbol:"ZWL",
		icon:"$",
		decimal_places:2,
		fiat:true,
		paypal:false
	}
};