export class NewDesign {
	constructor(
		public design_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public default_design?: boolean,
		public voidcheck?: any,
		public title?: string,
		public monochrome?: boolean,
		public borders?: any,
		public cutlines?: boolean,
		public show_micr?: boolean,
		public show_cheque_number?: boolean,
		public show_cheque_date?: boolean,
		public show_cheque_layers?: boolean,
		public print_scale?: number,
		public preview?: string,
		public paper?: string,
		public top?: string,
		public bottom?: string,
		public rotate?: boolean,
		public gradients?: boolean,
		public docugard?: boolean,
		public page_size?: string,
		public page_slug?: string,
		public page_width?: string,
		public page_height?: string,
		public doc_color?: string,
		public cheque_font?: string,
		public fontsize?: number,
		public RenderMICR?: any,
		public ImageMICR?: any,
		public micr_top?: string,
		public micr_right?: string,
		public micr_bottom?: string,
		public micr_left?: string,
		public page_top?: string,
		public page_right?: string,
		public page_bottom?: string,
		public page_left?: string,
		public table_top?: string,
		public table_right?: string,
		public table_bottom?: string,
		public table_left?: string,
		public address_top?: string,
		public address_right?: string,
		public address_bottom?: string,
		public address_left?: string,
		public template?: any,
		public fold_line?: any,
		public scale?: any,
		public numbers?: any,
		public micr?: any,
		public date?: any,
		public layers?: any,
		public printing?: any,
		public color?: any,
		public secret?: any,
		public bold?: any,
		public pro?: boolean,
		public italic?: any,
		public cheque_background_image_repeat?: any,
		public background_position?: string,
		public cheque_background_image?: string,
		public cheque_background_color?: string,
		public cheque_logo_image?: string,
		public cheque_header_image?: string,
		public cheque_bank_image?: string,
		public description?: string,
		public cheque_text_color?: string,
		public background_colour?: string,
		public background_image?: string,
		public font?: string,
		public design_pattern?: string,
		public editable?: boolean,
		public enabled?: boolean,
		public modified?: any,
		public created?: any
	){}
}
export const Create_Designs = '\
	CREATE TABLE IF NOT EXISTS "Designs" (\
		design_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		default_design INTEGER DEFAULT 0,\
		voidcheck INTEGER DEFAULT 0,\
		fold_line INTEGER DEFAULT 0,\
		title TEXT,\
		monochrome BOOLEAN,\
		borders BOOLEAN,\
		cutlines BOOLEAN,\
		show_micr BOOLEAN,\
		show_cheque_number BOOLEAN,\
		show_cheque_date BOOLEAN,\
		show_cheque_layers BOOLEAN,\
		print_scale INTEGER DEFAULT 1,\
		preview TEXT,\
		paper TEXT,\
		top TEXT,\
		bottom TEXT,\
		rotate BOOLEAN,\
		gradients BOOLEAN,\
		docugard BOOLEAN,\
		page_size TEXT,\
		page_slug TEXT,\
		page_width TEXT,\
		page_height TEXT,\
		doc_color TEXT,\
		cheque_font TEXT,\
		template TEXT,\
		scale TEXT,\
		numbers TEXT,\
		micr TEXT,\
		date TEXT,\
		layers TEXT,\
		printing TEXT,\
		color TEXT,\
		secret TEXT,\
		micr_top TEXT,\
		micr_right TEXT,\
		micr_bottom TEXT,\
		micr_left TEXT,\
		page_top TEXT,\
		page_right TEXT,\
		page_bottom TEXT,\
		page_left TEXT,\
		table_top TEXT,\
		table_right TEXT,\
		table_bottom TEXT,\
		table_left TEXT,\
		address_top TEXT,\
		address_right TEXT,\
		address_bottom TEXT,\
		address_left TEXT,\
		fontsize INTEGER DEFAULT 0,\
		RenderMICR BOOLEAN DEFAULT 0,\
		ImageMICR BOOLEAN DEFAULT 1,\
		pro BOOLEAN DEFAULT 0,\
		bold BOOLEAN,\
		italic BOOLEAN,\
		cheque_background_image_repeat BOOLEAN,\
		background_position TEXT,\
		cheque_background_image TEXT,\
		cheque_background_color TEXT,\
		cheque_logo_image TEXT,\
		cheque_header_image TEXT,\
		cheque_bank_image TEXT,\
		description TEXT,\
		cheque_text_color TEXT,\
		background_colour TEXT,\
		background_image TEXT,\
		font TEXT,\
		design_pattern TEXT,\
		editable INTEGER DEFAULT 1,\
		enabled INTEGER DEFAULT 1,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';