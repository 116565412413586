import { Component, OnInit } from '@angular/core';
import { NewDeposits } from '../../../models/deposits';
import { environment } from '../../../../environments/environment';
import { SelectAddressPage } from '../../../addresses/select-address/select-address.page';
import { SelectEmployeePage } from '../../../employees/select-employee/select-employee.page';
import { SQLService } from '../../../services/sql.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
@Component({
  selector: 'app-new-deposit',
  templateUrl: './new-deposit.component.html',
  styleUrls: ['./new-deposit.component.scss'],
})
export class NewDepositComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public sql: SQLService,
    private location: Location,
    public modalController: ModalController
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.open();
    });
  }
  currencies:any
  deposit = new NewDeposits();
  _c=0;
  end() {
    if (this._c>0) {
      if(confirm(this.sql.word.Discard_Confirmation)) {
        this.deposit = new NewDeposits();
        this.router.navigate(['folder','Deposits']);
      }
    } else { 
      this.router.navigate(['folder','Deposits']);
    }
  }
  save() {
    this.sql.add("Deposits",this.deposit);
    this.deposit = new NewDeposits();
    this._c=0;
    this.end();
  }
  update() { this.sql.update("Deposits",this.deposit); }
  get_administrator() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida) return;
    this.sql.view('Deposits','deposit_id',ida,(e)=>{
      var f = Object.entries(e[0]); let n=new NewDeposits();
      for( let t of f) n[t[0] ]=t[1]; this.deposit = n;
    });
  }
  open() {
    this.currencies = Object.keys(environment.currencies);
    this.deposit.currency = this.sql.Currency;
    this.deposit.enabled = true;
    this.get_administrator();
  }
  ngOnInit() {
    this.open();
  }
}
