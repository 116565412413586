export class NewBank {
	constructor(
		public enabled?: boolean,
		public bank_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public name?: string,
		public description?: string,
		public account_holder?: string,
		public address_line_one?: string,
		public address_line_two?: string,
		public address_line_three?: string,
		public city?: string,
		public country?: string,
		public province?: string,
		public postal_code?: string,
		public image?: string,
		public signature?: string,
		public cheque_starting_id?: number,
		public total?: number,
		public total_limit?: number,
		public payment_payroll_limit?: number,
		public payment_amount_limit?: number,
		public enable_limits?: string,
		public currency?: string,
		public payments?: number,
		public number_protection?: boolean,
		public default_account?: boolean,
		public transit_number?: string,
		public institution_number?: string,
		public designation_number?: string,
		public account_number?: string,
		public modified?: any,
		public created?: any
	){}
}
export const Create_BankAccounts = '\
	CREATE TABLE IF NOT EXISTS "BankAccounts" (\
		bank_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 1,\
		mod_id INTEGER DEFAULT 0,\
		name TEXT,\
		address TEXT,\
		address_2 TEXT,\
		country TEXT,\
		province TEXT,\
		address_line_one TEXT,\
		address_line_two TEXT,\
		address_line_three TEXT,\
		image TEXT,\
		signature TEXT,\
		cheque_starting_id INTEGER DEFAULT 1,\
		city TEXT,\
		postal_code TEXT,\
		description TEXT,\
		account_holder TEXT,\
		total INTEGER DEFAULT 0,\
		total_limit INTEGER,\
		payment_payroll_limit INTEGER,\
		payment_amount_limit INTEGER,\
		enable_limits INTEGER DEFAULT 0,\
		currency TEXT,\
		payments TEXT,\
		number_protection INTEGER DEFAULT 0,\
		default_account BOOLEAN,\
		transit_number TEXT,\
		institution_number TEXT,\
		designation_number TEXT,\
		account_number TEXT,\
		enabled INTEGER DEFAULT 0,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';