import { Component, OnInit, OnDestroy } from '@angular/core';
import { SQLService } from '../services/sql.service';
import { PayrollService } from '../services/payroll.service';
import { Router, NavigationEnd } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { ModalController,NavParams } from '@ionic/angular';
import { InsuranceMenuComponent } from './insurance-menu/insurance-menu.component';
@Component({
  selector: 'ng-cheques-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.styl']
})
export class InsuranceComponent implements OnInit, OnDestroy {
  showInfo = [];
  insured = [];
  showList = [];
  count = -1;
  search: string;
  selected =[];
  multiple: boolean;
  verifiedName: boolean;
  constructor(
    private router: Router,
    private params: NavParams,
    public popoverController: PopoverController,
    private payroll: PayrollService,
    private navCtrl: ModalController,
    public sql: SQLService
  ) {
    this.multiple = params.get('select_multiple');
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_policies();
    });
  }
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:InsuranceMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='enable')this.enable(item);
      if(data=='disable')this.disable(item);
      if(data=='delete')this.delete(item, false);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  filter(e) { this.search=e.target.value; }
  add() {
    this.router.navigate(['folder','Insurance', 'New']);
  }
  select(e) {
    if(this.multiple){
      this.toggle_item(e);
    } else {
      this.navCtrl.dismiss(e);
    }
  }
  toggle_item(policy) {
    if( this.selected.includes(policy.insurance_id) ) {
      this.deselect_item(policy);
    } else {
      this.select_item(policy);
    }
  }
  select_item(policy) {
    this.selected.push(policy.insurance_id);
  }
  deselect_item(policy) {
    var new_selection=[];
    for(var i = 0; i < this.selected.length; ++i){
      if(this.selected[i]!=policy.insurance_id){
        new_selection.push(this.selected[i]);
      }
    }
    this.selected = new_selection;
  }
  select_all() {
    var new_selection=[];
    for (var i = 0; i < this.insured.length; ++i) {
      new_selection.push(this.insured[i].insurance_id);
    }
    this.selected=new_selection;
  }
  deselect_all() {
   this.selected = []; 
  }
  delete_selected() {
     if(!this.selected || this.selected.length==0){
      alert(this.sql.word.Select_Multiple_Policies_Prompt);
    } else {
      if(confirm(this.sql.word.Policies_Multiple_Delete_Confirmation)) {
        var i = 0;
        for(let policy_id of this.selected) {
          this.sql.delete('Insurance','insurance_id',policy_id,(Done)=>{i++;
            if(i==this.selected.length) {
              this.get_policies(null,()=>{
                this.selected = [];  
              });
            }
          });
        }
      }
    }
  }
  enable(policy){
    this.sql.set('Insurance','insurance_id',policy.insurance_id,'enabled',1,(res)=>{
      policy.enabled = true;
    });
  }
  disable(policy){
    this.sql.set('Insurance','insurance_id',policy.insurance_id,'enabled',0,(res)=>{
      policy.enabled = false;
    });
  }
  end() {this.router.navigate(['folder','Dashboard']);}
  get_policies(event?, cb?) {
    this.sql.fetch('Insurance',(e)=>{
      if(e)this.insured=e;
      this.count=e.length;
      if(cb)cb();
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  edit(policy, item?) {
    if(this.multiple){
      this.toggle_item(policy);
    } else {
      this.router.navigate(['folder','Insurance',policy.insurance_id]);
    }
    if(item)item.closeSlidingItems();
  }
  delete(policy, item?) { 
    if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('Insurance','insurance_id',policy.insurance_id);
      this.get_policies();
    } else { if(item)item.closeSlidingItems(); }
  }
  ngOnInit() {
    this.get_policies();
  }
  ngOnDestroy(){
    this.multiple=false;
  }
}