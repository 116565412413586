import { SQLService } from '../../services/sql.service';
import { PopoverController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'ng-cheques-dashboard-popover-timesheet',
  templateUrl: './popover-timesheet.component.html',
  styleUrls: ['./popover-timesheet.component.styl']
})
export class PopoverTimesheetComponent implements OnInit {
  constructor(
    public sql: SQLService,
    public popover: PopoverController,
    private router: Router) {
  }
  dismissPopover(e) {
    if (this.popover) this.popover.dismiss(e);
  }
  stop() {
    this.dismissPopover('stop');
  }
  start() {
    this.dismissPopover('start');
  }
  delete() {
    this.dismissPopover('delete');
  }
  ngOnInit() {
    
  }
}