import * as Hammer from 'hammerjs';
import {HammerGestureConfig} from '@angular/platform-browser';
import { Injectable } from "@angular/core";
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
	overrides = {
		'pan': { direction: Hammer.DIRECTION_All },
		'swipeleft': { direction: Hammer.DIRECTION_LEFT },
		'swiperight': { direction: Hammer.DIRECTION_RIGHT },
		'swipeup': { direction: Hammer.DIRECTION_UP },
		'swipedown': { direction: Hammer.DIRECTION_DOWN },
		'swipe': {direction: Hammer.DIRECTION_HORIZONTAL},
		'swipeupdown': {direction: Hammer.DIRECTION_VERTICAL}
	}
}