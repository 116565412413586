import { SQLService } from '../../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { PopoverController} from '@ionic/angular';
import { Location } from '@angular/common';
import { Router,NavigationEnd } from '@angular/router';
import { PayPalMenuComponent } from './paypal-menu/paypal-menu.component';
@Component({
  selector: 'app-paypal-payments',
  templateUrl: './paypal-payments.component.html',
  styleUrls: ['./paypal-payments.component.scss'],
})
export class PaypalPaymentsComponent implements OnInit {
  constructor(
    public sql: SQLService,
    public popoverController: PopoverController,
    public location: Location,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_paypals();
    });
  }
  count = -1; 
  paypals = [];
  search:string;
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:PayPalMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled, production_mode: item.production_mode},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='delete')this.delete_paypal(item);
      if(data=='disable')this.disable(item);
      if(data=='enable')this.enable(item);
      if(data=='sandbox')this.disable_production(item);
      if(data=='production')this.enable_production(item);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  filter(e) {this.search=e.target.value;}
  add() {
    if((this.sql.Trial||!this.sql.paypal_payouts)) {
      alert(this.sql.word.PayPal_Payouts_Word);
      this.sql.show_purhchase_modal();
    } else {
      this.router.navigate(['folder','Settings','PayPal','New']);
    }
  }
  end() {this.location.back()}
  get_paypals(event?) {
    this.sql.fetch('PayPals',(e)=>{
      this.paypals=e;
      this.count=e.length;
      setTimeout(()=>{
        if(event)event.target.complete();
      },1234);
    });
  }
  enable_production(p, item?) {
    this.sql.set('PayPals','paypal_id',p.paypal_id,'production_mode',1,(res)=>{
     p.production_mode=true;
     if(item)item.closeSlidingItems(); 
    });
  }
  disable_production(p, item?) {
    this.sql.set('PayPals','paypal_id',p.paypal_id,'production_mode',0,(res)=>{
     p.production_mode=false;
     if(item)item.closeSlidingItems(); 
    });
  }
  disable(p,item?){
    this.sql.set('PayPals','paypal_id',p.paypal_id,'enabled',0,(res)=>{
      p.enabled = false;
      if(item)item.closeSlidingItems(); 
    });
  }
  enable(p,item?){
    this.sql.set('PayPals','paypal_id',p.paypal_id,'enabled',1,(res)=>{
      p.enabled = true;
      if(item)item.closeSlidingItems(); 
    });
  }
  edit_paypal(p, item?) {
    console.log(p.paypal_id, p)
    this.router.navigate(['folder','Settings','PayPal',p.paypal_id]);
    if(item)item.closeSlidingItems(); 
  }
  delete_paypal(p, item?) {
    if(confirm(this.sql.word.Discard_Confirmation)) {
      this.sql.delete('PayPals','paypal_id',p.paypal_id);
      this.get_paypals();
    } else { if(item)item.closeSlidingItems(); }
  }
  ngOnInit() {
    this.get_paypals();
  }
}