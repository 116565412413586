import { Component, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { SQLService } from '../../../services/sql.service';
import { backgrounds, backgrounds_lowrez } from '../../../../environments/logos';
@Component({
  selector: 'app-cheque-golden-weave',
  templateUrl: './cheque-golden-weave.component.html',
  styleUrls: ['./cheque-golden-weave.component.scss'],
})
export class ChequeGoldenWeaveComponent implements AfterViewInit {
  constructor(private sql: SQLService) { }
  @Output('loaded') loaded = new EventEmitter<boolean>();
  @Input('RenderMICR') RenderMICR = false;
  @Input('ImageMICR') ImageMICR = true;
  @Input('show') show = 'table';
  @Input('cheque') cheque: any;
  @Input('design') design: any;
  @Input('designer') designer: any;
  @Input('rotate') rotate: boolean;
  @Input('borders') borders: boolean;
  @Input('voidcheck') voidcheck: boolean;
  @Input('font') font: any;
  @Input('fiat') fiat: boolean;
  @Input('color') color: any;
  @Input('micr') micr: any;
  micr_top=false;
  micr_right=false;
  micr_bottom='5mm';
  micr_left='5mm';
  @Input('secret') secret = '';
  @Input('scale') scale =1;
  @Input('fold_line') fold_line: boolean;
  @Input('template') template: string;
  @Input('monochrome') monochrome: boolean;
  @Input('gradients') gradients: boolean;
  @Input('margin_bottom') bottom = '0mm';
  @Input('margin_top') top = '0mm';
  @Input('fontsize') fontsize: boolean;
  @Input('layers') layers: any;
  @Input('translations') translations = this.sql.word;
  @Input('bold') bold: boolean;
  @Input('italic') italic: boolean;
  @Input('show_cheque_number') show_cheque_number: boolean = true;
  @Input('show_cheque_date') show_cheque_date: boolean = true;
  @Input('show_micr') show_micr: boolean = true;
  @Input('cheque_background_image_repeat') cheque_background_image_repeat = false;
  @Input('background_position') background_position: string;
  @Input('cheque_text_color') cheque_text_color: string;
  @Input('cheque_background_image') cheque_background_image: any;
  @Input('cheque_logo_image') cheque_logo_image: any;
  @Input('cheque_header_image') cheque_header_image: any;
  @Input('cheque_bank_image') cheque_bank_image: any;
  @Input('doc_color') doc_color: string = 'gold';
  bg_image: string;
  loaded_component() {this.loaded.emit(true);}
  ngAfterViewInit() {
    this.design["height"]='69mm';
    this.design["date"]["top"]='19.5mm';
    this.design["date"]["right"]='22.8mm';
    this.design["date_label"]["display"]=false;
    this.design["payline"]["prefix"]=false;
    this.design["payline"]["borders"]=false;
    this.design["payline"]["left"]='132px';
    this.design["payline"]["right"]='27px';
    this.design["payline"]["bottom"]='4px';
    this.design["printed_id"]["top"]='8mm';
    this.design["printed_id"]["right"]='7mm';
    this.design["payment_name"]["left"]="-8mm";
    this.design["payment_name"]["bottom"]="-9mm";
    this.design["bank_account_holder"]["top"]="20px";
    this.design["bank_account_holder"]["left"]="25px";
    this.design["written_amount"]["top"]='23.7mm';
    this.design["written_amount"]["right"]=false;
    this.design["written_amount"]["bottom"]='31mm';
    this.design["written_amount"]["left"]='12mm';
    this.design["written_amount_line"]["width"]='550px';
    this.design["written_amount"]["index"]=false;
    this.design["written_amount_line"]["borders"]=false;
    this.design["written_amount_line"]["overflow"]=true;
    this.design["written_amount_line"]["top"]='0px';
    this.design["written_amount_line"]["right"]='0px';
    this.design["written_amount_line"]["bottom"]='0px';
    this.design["written_amount_line"]["left"]='0px';
    this.design["multiline_written_amount_chars"]=49;
    this.design["written_amount_line2"]["top"]='3.2mm'
    this.design["written_amount_line2"]["right"]='0mm'
    this.design["written_amount_line2"]["bottom"]='0mm'
    this.design["written_amount_line2"]["left"]='-5.5mm'
    this.design["amounts"]["fontsize"]="15px";
    this.design["amount_number"]["top"]="7.83mm";
    this.design["amount_number"]["right"]="auto";
    this.design["amount_number"]["left"]="0.49mm";
    this.design["amount_number"]["borders"]=false;
    this.design["amount_number"]["background"]=false;
    this.design["amounts"]["symbol"]=false;
    this.design["memo"]["label"]=true;
    this.design["memo"]["border"]=true;
    this.design["memo"]["class"]='golden_weave';
    this.design["memo"]["left"]='auto';
    this.design["memo"]["bottom"]='22.5mm';
    this.design["memo"]["right"]='6.5mm';
    this.design["memo"]["width"]='43.5mm';
    this.design["memo"]["lineheight"]='28px';
    this.design["memo"]["fontsize"]='10px';
    this.design["public_image"]["top"]='5mm';
    this.design["public_image"]["left"]='92mm';
    this.design["public_image"]["width"]='77';
    this.design["public_image"]["zindex"]='1';
    this.design['public_image']['QRcolorDark']="#000000";
    this.design['public_image']['QRcolorLight']="#F6DFC1";
    this.design['public_image']['QRCodeMargin']="1";
    this.design["key_image"]["width"]='77';
    this.design["key_image"]["left"]='5.5mm';
    this.design["key_image"]["top"]='5mm';
    this.design["key_image"]["zindex"]='1';
    this.design['key_image']['QRcolorDark']="#000000";
    this.design['key_image']['QRcolorLight']="#F6DFC1";
    this.design['key_image']['QRCodeMargin']="1";
    this.design["currency_name"]["class"]='golden_weave';
    this.design["currency_name"]["top"]='12mm';
    this.design["currency_name"]["left"]='21mm';
    this.design["currency_name"]["width"]='53mm';
    this.design["address_data"]["top"]='42.5mm';
    this.design["address_data"]["right"]='75mm';
    this.design["address_data"]["bottom"]='auto';
    this.design["address_data"]["left"]='6.3mm';
    this.design["address_data"]["width"]='113mm';
    this.design["bank_image"]["top"]="170px";
    this.design["bank_name"]["top"]="166px";
    this.design["bank_name"]["left"]="95px";
    this.design["bank_address"]["top"]="181px";
    this.design["bank_address"]["left"]="95px";
    this.design["bank_address_2"]["top"]="192px";
    this.design["bank_address_2"]["left"]="95px";
    this.design["bank_address_3"]["top"]="203px";
    this.design["bank_address_3"]["left"]="95px";
    this.design["signature"]["width"]='260px';
    this.design["signature"]["container_width"]='46mm';
    this.design["signature"]["border"]=false;
    this.design["signature"]["label"]=false;
    this.design["signature"]["right"]='auto';
    this.design["signature"]["bottom"]='12.2mm';
    this.design["signature"]["left"]='99.5mm';
    this.design["micr"]["top"]='auto';
    this.design["micr"]["right"]='auto';
    this.design["micr"]["bottom"]='5mm';
    this.design["micr"]["left"]='5mm';
    this.design["micr"]["background"]=true;
    if(this.sql.model=='web') {
      var img = "goldenweave";
      this.sql.load_background(img,(base64Img)=>{
        this.bg_image=base64Img;
      },this.sql.production);
    } else {
      this.bg_image = this.sql.production?backgrounds.goldenweave:backgrounds_lowrez.goldenweave;
    }
}
}