export const Cheque_Green_Wave_Properties = {
	id:26,
	top: '0mm',
	bottom: '0mm',
	title: 'Cheque_Green_Wave',
	borders: false,
	monochrome: false,
	font: 'Helvetica',
	enabled: true,
	default: false,
	docugard: false,
	background: '/assets/backgrounds/Green_Wave.png',
	preview: '/assets/backgrounds/Green_Wave.png',
	description: 'Cheque_Green_Wave_Window',
	show_cheque_number: true,
	show_cheque_date: true,
	pro:true,
	show_micr: true
}
export function Cheque_Green_Wave(cheque,words,symbol) {
	return [{
		style: 'DocugardBox',
		layout: 'noBorders',
		table: {
			widths: [ 470 ],
			body: [
				[
					{
						layout: 'noBorders',
						margin: [ 10, 12, 10, 0 ],
						table: {
							widths: [280,150],
							body: [
								[
									{ fontSize: 10, bold: true, text: cheque.bank_account_holder },
									{ fontSize: 11, text: cheque.number }
								],
							],
						}
					},
				],
				[
					{
						layout: 'noBorders',
						table: {
							widths: [290,24,'auto'],
							body: [
								[
									{ fontSize: 10, text: '' },
									{ margin: [ 0, 4, 0, 0 ], fontSize: 9, text: words.CHEQUE_PRINT_DATE },
									{ margin: [ 0, 3, 0, 0 ], fontSize: 11, text: cheque.date }
								],
							]
						}
					},
				],
				[
					{
						margin: [ 0, 12, 0, 0 ],
						columns: [
							{
							  width: 70,
							  margin: [ 14, 0, 0, 0 ],
							  text: { fontSize: 8, text: words.CHEQUE_PRINT_PAYTOTHEORDEROF },
							},
							{
							  width: 229,
							  margin: [ 2, 2, 0, 0 ],
							  text: { fontSize: 14, text: cheque.name },
							},
							{
							  width: 11,
							  text: { fontSize: 13, text: symbol,alignment:'center' },
							},
							{
							  width: 100,
							  text: { fontSize: 13, text: cheque.amount }
							}
						]
					},
				],
				[
					{
						margin: [ 13, 0, 0, 0 ],
						columns: [
							{
							  width: 375,
							  margin: [ 2, 0, 0, 0 ],
							  text: { fontSize: 11, text: (cheque.written_amount)?cheque.written_amount.toUpperCase():'' },
							},
							{
							  width: 80,
							  text: { fontSize: 9, text: words.CHEQUE_PRINT_AMOUNT_INDEX }
							}
						]
					},
				],
				[
					{
						layout: 'noBorders',
						margin: [ 13, 0, 0, 0 ],
						table: {
							widths: [30,300],
							body: [
								[
									cheque.bank_image?{
										width: cheque.bank_image?29:0,
										margin: [ 0, 0, 0, 0 ],
										image: cheque.bank_image
									}:{},
									{
										layout: 'noBorders',
										margin: [ 0, 0, 0, 0 ],
										table: {
											widths: ['*'],
											body: [
												[
													{ lineHeight: 0.5, fontSize: 11, text: cheque.bank_name }
												],
												[
													{ lineHeight: 0.5, fontSize: 9, text: cheque.bank_address }
												],
												[
													{ lineHeight: 0.5, fontSize: 9, text: cheque.bank_address_2 }
												],
												[
													{ lineHeight: 0.5, fontSize: 9, text: (cheque.bank_city?cheque.bank_city+',':'') + (cheque.bank_country?cheque.bank_country+' ':'') + (cheque.bank_postal?cheque.bank_postal:'') }
												],
											]
										}
									}
								],
							]
						}
					},
				],
				[
					{
						layout: 'noBorders',
						margin: [ 13, 0, 0, 0 ],
						table: {
							widths: [30,220,170],
							body: [
								[
									{ margin: [ 0, 4, 0, 0 ], fontSize: 9, text: words.CHEQUE_PRINT_MEMO },
									{ margin: [ 0, 2, 0, 0 ], fontSize: 11, text: cheque.printed_memo },
									{ fontSize: 5, text: words.CHEQUE_PRINT_SIGNATUREMP, fillColor: '#fff', lineHeight:4 }
								],
							]
						}
					},
				],
				[
					{
						layout: 'noBorders',
						margin: [ 10, 12, 10, 0 ],
						table: {
							widths: [280,150],
							body: [
								[
									{ font: "MICR", fillColor: '#fff', fontSize: 19, alignment: 'center', text: cheque.identifier },
									{ fontSize: 9, text: words.CHEQUE_PRINT_CREDIT, opacity: 0.4, alignment: 'right' }
								],
							]
						}
					}
				],
			]
		}
	}]
}