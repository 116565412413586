import { SQLService } from '../../../services/sql.service';
import { Flip } from '../../../app.animations';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController, NavParams } from '@ionic/angular';
@Component({
  selector: 'app-select-paypal-payments',
  templateUrl: './select-paypal-payments.component.html',
  styleUrls: ['./select-paypal-payments.component.scss'],
  animations: [ Flip ]
})
export class SelectPaypalPaymentsComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    private params: NavParams,
    public sql: SQLService,
    private location: Location
  ) {
    var s = params.get('selected');
    if(s)this.selected = s.split(',');
    this.multiple = params.get('select_multiple');
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_paypals();
    });
  }
  count = -1;
  paypals = [];
  output = new EventEmitter();
  selected = [];
  multiple: boolean;
  ngOnInit() {
    this.get_paypals();
  }
  get_paypals(event?) {
    this.sql.fetch('PayPals',(e)=>{
      this.paypals=e;
      this.count=e.length;
      setTimeout(()=>{if (event) event.target.complete();},1234);
    });
  }
  select(e) {
    if(this.multiple){
      this.toggle_item(e);
    } else {
      this.navCtrl.dismiss(e.paypal_id);
    }
  }
  done_multiple() {
    this.navCtrl.dismiss(this.selected.join(','));
  }
  toggle_item(item) {
    if( this.selected.includes(item.paypal_id) ) {
      this.deselect_item(item);
    } else {
      this.select_item(item);
    }
  }
  select_none() {this.navCtrl.dismiss(-1);}
  select_item(item) {
    this.selected.push(item.paypal_id);
  }
  deselect_item(item) {
    var new_selection=[];
    for(var i = 0; i < this.selected.length; ++i){
      if(this.selected[i]!=item.paypal_id){
        new_selection.push(this.selected[i]);
      }
    }
    this.selected = new_selection;
  }
  select_all() {
    var new_selection=[];
    for (var i = 0; i < this.paypals.length; ++i) {
      new_selection.push(this.paypals[i].paypal_id);
    }
    this.selected=new_selection;
  }
  deselect_all() {
   this.selected = []; 
  }
  add() {
    if((this.sql.Trial||!this.sql.paypal_payouts)) {
      alert(this.sql.word.PayPal_Payouts_Word);
      this.sql.show_purhchase_modal();
    } else {
      this.router.navigate(['folder','Settings','PayPal','New']);
    }
    this.navCtrl.dismiss();
  }
  edit(paypal, item?) {
    this.router.navigate(['folder','Settings','PayPal',paypal.paypal_id]);
    this.navCtrl.dismiss();
  }
  delete(paypal, item) {
    if(confirm(this.sql.word.Discard_Bank_Confirmation)) {
      this.sql.delete('PayPals','paypal_id',paypal.paypal_id);
      this.get_paypals();
    } else { item.closeSlidingItems(); }
  }
  off() {
    if(this.multiple){
      this.done_multiple();
     } else {
      this.navCtrl.dismiss();
     }
  }
}