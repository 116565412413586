import { Component, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { SQLService } from '../../../services/sql.service';
import { backgrounds, backgrounds_lowrez } from '../../../../environments/logos';
@Component({
  selector: 'app-cheque-green-sun',
  templateUrl: './cheque-green-sun.component.html',
  styleUrls: ['./cheque-green-sun.component.scss'],
})
export class ChequeGreenSunComponent implements AfterViewInit {
  constructor(private sql: SQLService) { } 
  @Output('loaded') loaded = new EventEmitter<boolean>();
  @Input('RenderMICR') RenderMICR = false;
  @Input('ImageMICR') ImageMICR = true;
  @Input('show') show = 'table';
  @Input('cheque') cheque: any;
  @Input('design') design: any;
  @Input('designer') designer: any;
  @Input('rotate') rotate: boolean;
  @Input('borders') borders: boolean;
  @Input('voidcheck') voidcheck: boolean;
  @Input('font') font: any;
  @Input('fiat') fiat: boolean;
  @Input('color') color: any;
  @Input('micr') micr: any;
  micr_top=false;
  micr_right=false;
  micr_bottom='6.9mm';
  micr_left='9.4mm';
  @Input('secret') secret = '';
  @Input('scale') scale =1;
  @Input('fold_line') fold_line: boolean;
  @Input('template') template: string;
  @Input('monochrome') monochrome: boolean;
  @Input('gradients') gradients: boolean;
  @Input('margin_bottom') bottom = '0mm';
  @Input('margin_top') top = '0mm';
  @Input('fontsize') fontsize: boolean;
  @Input('layers') layers: any;
  @Input('translations') translations = this.sql.word;
  @Input('bold') bold: boolean;
  @Input('italic') italic: boolean;
  @Input('show_cheque_number') show_cheque_number: boolean = true;
  @Input('show_cheque_date') show_cheque_date: boolean = true;
  @Input('show_micr') show_micr: boolean = true;
  @Input('cheque_background_image_repeat') cheque_background_image_repeat = false;
  @Input('background_position') background_position: string;
  @Input('cheque_text_color') cheque_text_color: string;
  @Input('cheque_background_image') cheque_background_image: any;
  @Input('cheque_logo_image') cheque_logo_image: any;
  @Input('cheque_header_image') cheque_header_image: any;
  @Input('cheque_bank_image') cheque_bank_image: any;
  @Input('doc_color') doc_color: string;
  bg_image: string;
  loaded_component() {this.loaded.emit(true);}
  ngAfterViewInit() {
    this.design["height"]='69mm';
    this.design["date"]["top"]='17mm';
    this.design["date"]["right"]='39.1mm';
    this.design["date"]["individual"]=true;
    this.design["date_label"]["display"]=false;
    this.design["date_label"]["alternative"]=false;
    this.design["date_label"]["alternative_2"]=false;
    this.design["payline"]["prefix"]=false;
    this.design["payline"]["borders"]=false;
    this.design["bank_name"]["textalign"]='left';
    this.design["bank_name"]["top"]='-3mm';
    this.design["bank_name"]["right"]='-45mm';
    this.design["bank_name"]["left"]='17.7mm';
    this.design["bank_address"]["grouping"]=true;
    this.design["bank_address_2"]["grouping"]=true;
    this.design["bank_address_3"]["grouping"]=true;
    this.design["bank_image"]["enabled"]=false;
    this.design["bank_image"]["top"]='-30mm';
    this.design["bank_image"]["left"]='0.6mm';
    this.design["bank"]["width"]='98.5mm';
    this.design["payline"]["left"]='132px';
    this.design["payline"]["right"]='27px';
    this.design["payline"]["bottom"]='4px';
    this.design["printed_id"]["top"]='6.1mm';
    this.design["printed_id"]["right"]='7.2mm';
    this.design["payment_name"]["left"]="9mm";
    this.design["payment_name"]["bottom"]="2.3mm";
    this.design["written_amount"]["top"]=false;
    this.design["written_amount"]["right"]=false;
    this.design["written_amount"]["bottom"]='31mm';
    this.design["written_amount"]["left"]='8.5mm';
    this.design["written_amount"]["index"]=false;
    this.design["written_amount_line"]["borders"]=false;
    this.design["written_amount_line"]["overflow"]=true;
    this.design["written_amount_line"]["top"]='0px';
    this.design["written_amount_line"]["right"]='0px';
    this.design["written_amount_line"]["bottom"]='0px';
    this.design["written_amount_line"]["left"]='0px';
    this.design["written_amount_line"]["width"]='550px';
    this.design["multiline_written_amount_chars"]=61;
    this.design["written_amount_line2"]["top"]='4mm'
    this.design["written_amount_line2"]["right"]='0mm'
    this.design["written_amount_line2"]["bottom"]='0mm'
    this.design["written_amount_line2"]["left"]='0mm'
    this.design["amount_number"]["borders"]=false;
    this.design["amount_number"]["background"]=false;
    this.design["amount_number"]["top"]="0mm";
    this.design["amount_number"]["right"]="auto";
    this.design["amount_number"]["left"]="0mm";
    this.design["amounts"]["symbol"]=false;
    this.design["memo"]["label"]=false;
    this.design["memo"]["border"]=false;
    this.design["memo"]["left"]='12mm';
    this.design["memo"]["bottom"]='20mm';
    this.design["public_image"]["top"]='29.5mm';
    this.design["public_image"]["left"]='125.5mm';
    this.design["public_image"]["width"]='77';
    this.design["public_image"]["zindex"]='1';
    this.design['public_image']['QRcolorDark']="#232323";
    this.design['public_image']['QRcolorLight']="#DAE7CC";
    this.design['public_image']['QRCodeMargin']="1";
    this.design["key_image"]["width"]='77';
    this.design["key_image"]["left"]='8.5mm';
    this.design["key_image"]["top"]='8.4mm';
    this.design["key_image"]["zindex"]='1';
    this.design['key_image']['QRcolorDark']="#232323";
    this.design['key_image']['QRcolorLight']="#DAE7CC";
    this.design['key_image']['QRCodeMargin']="1";
    this.design["currency_name"]["top"]='16.9mm';
    this.design["currency_name"]["left"]='25.8mm';
    this.design["currency_name"]["width"]='80mm';
    this.design["address_data"]["top"]='27.6mm';
    this.design["address_data"]["right"]='75mm';
    this.design["address_data"]["bottom"]='auto';
    this.design["address_data"]["left"]='8.3mm';
    this.design["address_data"]["width"]='113mm';
    this.design["signature"]["contianer_width"]='60mm';
    this.design["signature"]["width"]='180px';
    this.design["signature"]["border"]=false;
    this.design["signature"]["label"]=false;
    this.design["signature"]["right"]='auto';
    this.design["signature"]["bottom"]='22.6mm';
    this.design["signature"]["left"]='93mm';
    this.design["signature"]["info"]["top"]='3.2mm';
    this.design["signature"]["desc"]["top"]='3.2mm';
    this.design["micr"]["top"]='auto';
    this.design["micr"]["right"]='auto';
    this.design["micr"]["bottom"]='6.9mm';
    this.design["micr"]["left"]='9.4mm';
    this.design["micr"]["background"]=true;
    if(this.sql.model=='web') {
      var img = "greensun";
      this.sql.load_background(img,(base64Img)=>{
        this.bg_image=base64Img;
      },this.sql.production);
    } else {
      this.bg_image = this.sql.production?backgrounds.greensun:backgrounds_lowrez.greensun;
    }
}
}