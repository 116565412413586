export class NewRegistration {
	constructor(
		public registration_id?: number,
		public device_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public username?: string,
		public email?: string,
		public registration_key?: string,
		public registration_name?: string,
		public registered?: boolean,
		public expiry?: any,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Registrations = '\
	CREATE TABLE IF NOT EXISTS "Registrations" (\
		registration_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		device_id INTEGER DEFAULT 0,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		username TEXT,\
		email TEXT,\
		registration_key TEXT,\
		registration_name TEXT,\
		registered INTEGER DEFAULT 0,\
		enabled INTEGER DEFAULT 0,\
		expiry TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';