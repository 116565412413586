export class NewOrder {
	constructor(
		public order_id?: number,
		public type?: string,
		public product?: string,
		public price?: string,
		public shipped?: boolean,
		public delivered?: boolean,
		public active?: string,
		public cheques?: number,
		public months?: number,
		public currency?: any,
		public expiry?: any,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Orders = '\
	CREATE TABLE IF NOT EXISTS "Orders" (\
		order_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		type TEXT,\
		product TEXT,\
		price TEXT,\
		shipped TEXT,\
		delivered TEXT,\
		active TEXT,\
		cheques INTEGER,\
		months INTEGER,\
		currency TEXT,\
		expiry TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';