import { environment } from '../../../environments/environment';
import { SQLService } from '../../services/sql.service';
import { Flip,fade } from '../../app.animations';
import { Component, OnDestroy, OnInit, Input, EventEmitter,ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { TermsOfServiceComponent } from '../../settings/licences/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from '../../settings/licences/privacy-policy/privacy-policy.component';
import { EULAComponent } from '../../settings/licences/eula/eula.component';
import { TermsOfUseComponent } from '../../settings/licences/terms-of-use/terms-of-use.component'
import { DisclaimerComponent } from '../../settings/licences/disclaimer/disclaimer.component'
import { RefundsComponent } from '../../settings/licences/refunds/refunds.component'
import { SignInComponent } from '../sign-in/sign-in.component';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { NewLogin } from '../../models/login'
declare var Stripe;
@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.component.html',
	styleUrls: ['./sign-up.component.styl'],
	animations: [ Flip,fade ]
})
export class SignUpComponent implements OnInit, OnDestroy {
	constructor(
		private router: Router,
		public sql: SQLService,
		private route: ActivatedRoute,
		private navCtrl: ModalController,
		private current_platform: Platform,
		private location: Location
	) {
		// this.router.events.subscribe((eve) => {
			//if(eve instanceof NavigationEnd) this.get_purchase();
		// });
	}
	login = new NewLogin();
	platform: string;
	signup: boolean;
	ngOnInit() {
		
	}
	ngOnDestroy() {

	}
	sign_in() {
		this.navCtrl.dismiss('signin');
		// this.show_signin();
	}
	register() {
		if(this.login.password&&this.login.username&&this.login.email) {
			if(this.login.password==this.login.password_conf) {
				if(this.login.terms) {
					this.sql.signup(this.login.email, this.login.username, this.login.password, (res)=>{
						if (this.sql.verbose) console.log('Login Complete ',res);
						if (res.result.code >= 500) {
							alert(this.sql.word.PrinterError+' '+(res.error?res.error:'')+' ('+(res.code?res.code:'')+')');
							console.log('Signup Error ', res);
						} else if(res.result) {
							// this.sql.set_temporary('authorization', res.result, 13000);
							this.sql.cookie_service('authorization', res.result, 13000);
							this.navCtrl.dismiss(true);
							this.sql.renderMenu();
						}
					});
				} else {
					alert(this.sql.word.Terms_Required_Word);
				}
			} else {
				alert(this.sql.word.Pass_Updated_Fail);
			}
		} else {
			alert(this.sql.word.PrinterError);
		}
	}
	off() {
		this.navCtrl.dismiss(false);
	}
	close() {this.off();}
	async show_signin() {
		const m = await this.navCtrl.create({component: SignInComponent, componentProps: {force:true}});
		await m.present();
		this.sql.showingLogin = true;
		let a = await m.onDidDismiss();
		if(a.data) {
		  this.sql.hapticsImpactLight();
		}
	}
	async tos() {
		const m = await this.navCtrl.create({component: TermsOfServiceComponent, componentProps: {force:true}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {
		  this.sql.hapticsImpactLight();
		  console.log('Check Key', a.data);
		}
	}
	async pp() {
		const m = await this.navCtrl.create({component: PrivacyPolicyComponent, componentProps: {force:true}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {
		  this.sql.hapticsImpactLight();
		  console.log('Check Key', a.data);
		}
	}
	async eula() {
		const m = await this.navCtrl.create({component: EULAComponent, componentProps: {force:true}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {
		  this.sql.hapticsImpactLight();
		  console.log('Check Key', a.data);
		}
	}
	async tou() {
		const m = await this.navCtrl.create({component: TermsOfUseComponent, componentProps: {force:true}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {
		  this.sql.hapticsImpactLight();
		}
	}
	async dis() {
		const m = await this.navCtrl.create({component: DisclaimerComponent, componentProps: {force:true}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {
		  this.sql.hapticsImpactLight();
		}
	}
	async ref() {
		const m = await this.navCtrl.create({component: RefundsComponent, componentProps: {force:true}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {
		  this.sql.hapticsImpactLight();
		}
	}
}