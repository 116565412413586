import { environment } from '../../../../environments/environment';
import { SQLService } from '../../../services/sql.service';
import { NewBackup } from '../../../models/backup';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';


@Component({
  selector: 'ng-cheques-new-cloud-backup',
  templateUrl: './new-cloud-backup.component.html',
  styleUrls: ['./new-cloud-backup.component.styl']
})
export class NewCloudBackupComponent implements OnInit {
  constructor(
    public sql: SQLService,
    private route: ActivatedRoute,
    private router: Router,
    private iab: InAppBrowser,
    private location: Location
  ) {}
  _c=0;
  cloud = new NewBackup();
  currencies = Object.keys(environment.currencies);

  onedrive:any;
  googledrive:any;
  save() {
    this.sql.add("Backups",this.cloud);
    this.cloud = new NewBackup();
    this._c=0;
    this.end();
  }
  end() {
   if (this._c>0) {
      if (confirm(this.sql.word.Discard_Confirmation)) {
        this.cloud = new NewBackup();
        this.router.navigate(['folder','Settings','Backup']);
      }
    } else {
      this.router.navigate(['folder','Settings','Backup']);
    }
  }
  get_mysql_backup() {
    var ida = this.route.snapshot.paramMap.get("id");
    if(!ida) return;
    this.sql.view('Backups','backup_id',ida,(e)=>{
      var f = Object.entries(e[0]); let n=new NewBackup();
      for( let t of f) n[t[0] ]=t[1]; this.cloud = n;
    });
  }
  open_onedrive() {
    var URI ='https://onedrive.com';
    this.onedrive = this.iab.create(URI);
    this.onedrive.close();
  }
  open_googledrive() {
    var URI ='https://drive.google.com';
    this.googledrive = this.iab.create(URI);
    this.googledrive.close();
  }
  open_icloud() {
    var URI ='https://icloud.com';
  }
  close_icloud() {}
  close_onedrive() {this.onedrive.close();}
  close_googledrive() {this.googledrive.close();}
  select() {
    switch(this.cloud.type) {
      case 'OneDrive':
        this.open_onedrive();
      break;
      case 'Google Drive':
        this.open_googledrive();
      break;
      case 'iCloud':
        this.open_icloud();
      break;
      case 'MySQL':
        this.get_mysql_backup();
      break;
    }
  }
  ngOnInit() {
    this.cloud.type='OneDrive';
    var tt = this.route.snapshot.paramMap.get("tt");
    if(tt)this.cloud.type=tt;
    this.cloud.auto=true;
    this.select();
  }
}