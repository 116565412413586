import { Component, OnInit, Input } from '@angular/core';
import { SQLService } from '../../services/sql.service';
import { DatePipe } from '@angular/common';
import { NewChequeEntry } from '../../models/chequeentries';
import { TimesheetService } from '../../services/timesheet.service';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { Gesture, GestureController, NavParams, ModalController, PopoverController} from '@ionic/angular';
import { Location } from '@angular/common';
import { ChequeEntryMenuComponent } from './cheque-entry-menu/cheque-entry-menu.component';
import { NewChequeEntryComponent } from './new-cheque-entry/new-cheque-entry.component';
@Component({
  selector: 'app-cheque-entries',
  templateUrl: './cheque-entries.component.html',
  styleUrls: ['./cheque-entries.component.styl'],
})
export class ChequeEntriesComponent implements OnInit {
  add_timer_with_a_page = false;
  one_second_timer: any;
  cheque_entries = [];
  search:string;
  total_amount = 0;
  yearly_amount = 1337;
  count = -1;
  cheque_id: any;
  cheque_fiat: boolean;
  cheque_currency: string;
  constructor(
    public datePipe: DatePipe,
    private router: Router,
    private params: NavParams,
    private location: Location,
    public popoverController: PopoverController,
    private modalController: ModalController,
    private gestureCtrl: GestureController,
    private route: ActivatedRoute,
    private ts: TimesheetService,
    private navCtrl: ModalController,
    public sql: SQLService
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) {
        this.get_cheque_entries();
      }
    });
  }
  @Input('set_table') table:string;
  @Input('set_model') model:string;
  @Input('set_model_id') id:string;
  ngOnInit() {
    this.table = this.route.snapshot.paramMap.get("table");
    this.model = this.route.snapshot.paramMap.get("model");
    this.cheque_id = this.route.snapshot.paramMap.get("id");
    this.cheque_id=this.params.get('id');
    this.get_cheque_currency();
    this.get_cheque_entries();
  }
  filter(e) { 
    this.search=e.target.value;
  }
  get_cheque_entries(event?) {
    var qq = 'ChequeEntry';
    if (this.cheque_id) {
      qq='ChequeEntry WHERE cheque_id = '+this.cheque_id;
      this.sql.fetch(qq,(e)=>{
        this.cheque_entries=e;
        this.count=e.length;
        if(this.count>0){
          this.add_total_together(e);
        } else {
          this.total_amount=0;
        }
        setTimeout(()=>{
          if (event) event.target.complete();
        },1234);
      });
    }
  }
  get_cheque_currency() {
    if(this.cheque_id){
      this.sql.fetch('Cheques WHERE cheque_id = '+this.cheque_id,(a)=>{
        console.log('cheque currency',a);
        if(a.fiat)this.cheque_fiat=a.fiat;
        if(a.currency)this.cheque_currency=a.currency;
      });
    }
  }
  add_total_together(e) {
    this.total_amount=0;
    for(let i of e) {
      if(i&&i.amount>0){
        if(i.mode=='add')this.total_amount=Number(Number(this.total_amount)+Number(i.amount));
        if(i.mode=='subtract')this.total_amount=Number(Number(this.total_amount)-Number(i.amount));
      }
    }
  }
  count_cheque_entries(cb?) {
    if(this.cheque_id)this.sql.count("ChequeEntry WHERE cheque_id = "+this.cheque_id,(items)=>{
      console.log('ChequeEntrys count', items);
      if(cb)cb(items);
    });
  }
  select_none() {
    this.navCtrl.dismiss({id:-1,count:Number(this.count),total:Number(this.total_amount)});
  }
  select(e) { 
    if (this.id) {
      let a = 'cheque';
      this.sql.query('UPDATE '+this.table+' '+( this.id?'WHERE '+this.model+' = '+this.id:'' )+' SET '+a+'_id = '+e[a+'_id']);
      this.location.back();
    }
    this.navCtrl.dismiss({id:e,count:Number(this.count),total:Number(this.total_amount)});
  }
  trash() {
    this.navCtrl.dismiss({id:1,count:Number(this.count),total:Number(this.total_amount)});
  }
  end() {
    // this.router.navigate(['folder','Cheque',this.cheque_id]);
    this.navCtrl.dismiss({count:Number(this.count),total:Number(this.total_amount)});
  }
  async add() {
    const m = await this.modalController.create({component: NewChequeEntryComponent, componentProps: {
      id: this.cheque_id
    }});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data){
      if(a.data.id==-1){
        this.sql.hapticsImpactLight();
      } else {
        this.sql.hapticsImpactMedium();
      }
      this.get_cheque_entries();
    }
  }
  edit(ts, item?) {
    var ce = ['folder','ChequeEntry',ts.cheque_entry_id];
    if(this.navCtrl)this.navCtrl.dismiss();
    this.router.navigate(ce);
    if(item)item.close();
  }
  delete(ts, item?) {
    if(confirm(this.sql.word.Delete_Timesheet_Confirmation)) {
      this.sql.delete('ChequeEntry','cheque_entry_id',ts.cheque_entry_id);
      this.get_cheque_entries();
    } else {
      if(item)item.close();
    }
  }
  handleReorder(ev) {
    var children = ev.target.children;
    var entry = ev.detail.to;
    ev.detail.complete();
    var i = 0;
    for(var c of children) { i++;
      this.sql.query("UPDATE ChequeEntry SET number = "+i+" WHERE cheque_entry_id = "+c.attributes.name.value+';');
    }
  }
  done() { this.end(); }
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?,event?) {
    if(this.showing_popover)return;
    if(this.sql.model=='ios'||this.sql.model=='electron'||this.sql.model=='web') {
      this.showing_popover=true;
      if(event){event.preventDefault();
        if(this.sql.model=='electron'||this.sql.model=='web')this.lpevent=event;
      }
      this.sql.hapticsImpactMedium();
      var popover = await this.popoverController.create({
        component:ChequeEntryMenuComponent,
        cssClass: 'popover_container',
        translucent: true,
        componentProps: {enabled: item.enabled},
        event: this.lpevent?this.lpevent:false
      });
      popover.present();
      var { data } = await popover.onWillDismiss();
      if (data && item) {
        this.sql.hapticsImpactLight();
        if(data=='delete')this.delete(item);
        // if(data=='print')this.print(item);
      }
      this.showing_popover=false;
      this.lpevent=null;
    }
  }
}