import * as ChequeTemplates from '../../templates';
import * as converter from 'number-to-words';
import { NewCheque } from '../../../models/cheque';
import { SQLService } from '../../../services/sql.service';
import { NewDesignObjects } from '../../../models/designobjects';
import { Currencies } from '../../../../environments/currencies';
import { environment } from '../../../../environments/environment';
import { PaperSizes } from '../../../../environments/papersizes';
import { PurchaseComponent } from '../../../dashboard/purchase/purchase.component';
import { PrintAddressComponent } from '../../../addresses/print-address/print-address.component';
import { SelectChequeDesignsPage } from '../../designs/select-design/select-design.page';
import { NumberToWord, kananda, english, telagu, hindi, marathi } from "multilingual-number-to-words";
import { Component, ElementRef, AfterViewInit, AfterContentInit, OnChanges, SimpleChanges, OnDestroy, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { DatePipe,CurrencyPipe } from '@angular/common';
import { Platform } from '@ionic/angular';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { LoadingController,ModalController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
	selector: 'ng-multiple-cheques',
	templateUrl: './multiple-cheques.component.html',
	styleUrls: ['./multiple-cheques.component.styl']
})
export class GenerateMultipleChequesComponent implements AfterContentInit, OnChanges, OnDestroy {
	constructor(
		private sql: SQLService,
		private router: Router,
		private platform:Platform,
		private sanitizer:DomSanitizer,
		private changeDetectorRef: ChangeDetectorRef,
		private modalController: ModalController,
		private loadingController: LoadingController,
		private activatedRoute: ActivatedRoute
	) {}
	cheques=[];
	pages=[];
	count  = 1;
	index  = 1;
	paper  = 0;
	swiper_free = {
		spaceBetween:0,
		freeMode:false,
		pagination:true
	};
	full_page_html='';
	loaded = false;
	errors = false;
	conversion_errors = false;
	loading:any;
	loaded_count = 0;
	total_amount = 0;
	@ViewChild('Slider', { static: false }) slider: IonSlides; 
	@Input('cheques') cheque_ids: any;
	getIndex(event) { 
		this.index=event.srcElement.swiper.activeIndex+1; 
	}
	all_in_one() {
		var ar = this.sql.amount(this.total_amount,null,this.sql.Currency);
		this.conversion_error_check();
		this.router.navigate(['folder','Cheque','New','Amount',ar,this.sql.Currency]);
	}
	check_requirements(cb) {
		var reqs = false, rest=[], last_errors=0, most:any;
		for(let c of this.pages) {
			this.sql.double_check_cheque_requirements((result,errs)=>{
				if (!result) {
					reqs = true;
					rest.push(c);
					if(errs>last_errors){
						last_errors=errs;
						most=c;
					}
				}
			},c,-1,false);	
		}
		if(reqs){
			this.sql.double_check_cheque_requirements(cb,most,-1,true,rest.length+' '+this.sql.word.Prints);
		} else {
			cb(true);
		}
	}
	print() {
		if(this.loaded){
			if(this.full_page_html){
				this.sql.hapticsImpactHeavy();
				this.check_requirements((r)=>{
					if(r)this.print_now();
				});
			} else {
			  this.sql.err('No HTML / Canvas Elements Were Found! This could be a routing error, try printing from another page. This could be a database error, is your DB full or overloaded?')
			}	
		}
	}
	print_now() {
		this.sql.Print(this.full_page_html,(PrintResult)=>{
			if(PrintResult){
				this.sql.PrintSuccess();
				if(this.sql.verbose)console.log('Print Result:', PrintResult);
				this.router.navigate(['folder','Cheques']);
			}
		},this.paper);
	}
	rendered(cheque) {
		this.pages.push(cheque.cheque);
		this.full_page_html = this.full_page_html+'<!-- PAGE BREAK -->'+cheque.html;
		if(this.sql.more_verbose){console.log('cheque_html',cheque.html);console.log('full_page_html',this.full_page_html);}
		if(cheque.cheque.currency!=this.sql.Currency) {
			this.sql.exchange_currency(cheque.cheque.amount,cheque.cheque.currency,this.sql.Currency,(amount)=>{
				if(amount!='error') {
					this.total_amount = Number(Number(this.total_amount)+Number(amount));	
				} else {
					this.conversion_errors = true;
				}
			});
		} else {
			this.total_amount = Number(Number(this.total_amount)+Number(cheque.cheque.amount));
		}
		setTimeout( () => {
			if(this.count-1==this.loaded_count){
				this.loaded = true;
				// this.print() // AUTOPRINT [READY TO PRINT]
			}
			this.loaded_count=this.loaded_count+1;
		},320);
	}
	rendered_image(new_image) {
		this.pages.push(this.sanitizer.bypassSecurityTrustResourceUrl(new_image));
	}
	conversion_error_check() {
		if(this.conversion_errors)alert(this.sql.word.Some_Conversion_Rates_Could_Not_Be_Retrieved);
	}
	off()  {
		this.modalController.dismiss();
		this.router.navigate(['folder','Cheques']);
		this.ngOnDestroy();
	}
	next() {
		// this.slider.slideNext()
	}
	prev() {
		// this.slider.slidePrev()
	}
	async open() {
		this.errors=false;
		var n =this.activatedRoute.snapshot.paramMap.get('ids');
		if(n)this.cheque_ids=n;
		if(!this.cheque_ids)return;
		this.cheques = this.cheque_ids.split(',');
		this.count = this.cheques.length;
		if(this.count==0)this.router.navigate(['folder','Cheques']);
		console.log('Printing multiple cheques:', n, this.cheques);
	}
	ngAfterContentInit() {
		this.open();
	}
	ngOnChanges(item) {

	}
	ngOnDestroy() {
		this.full_page_html = '';
		this.pages = [];
		this.count = 0;
		this.loaded = false;
		this.errors = false;
		this.loaded_count = 0;
		this.cheque_ids = 0;
		this.total_amount = 0;
	}
}