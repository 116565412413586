export class NewModerator  {
	constructor(
		public mod_id?: number,
		public username?: string,
		public image?: string,
		public first_name?: string,
		public last_name?: string,
		public birthday?: string,
		public email?: string,
		public password?: string,
		public phone?: string,
		public employees?: any,
		public addresses?: any,
		public address_ids?: any,
		public currency?: string,
		public address_id?: number,
		public total_paid?: number,
		public moderations?: number,
		public savings?: number,
		public payment_payroll_limit?: number,
		public payment_amount_limit?: number,
		public total_limit?: number,
		public description?: string,
		public moderation?: string,
		public create?: boolean,
		public edit?: boolean,
		public destroy?: boolean,
		public enabled?: boolean,
		public day_start_time?: any,
		public day_end_time?: any,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Moderators = '\
	CREATE TABLE IF NOT EXISTS "Moderators" (\
		mod_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		uuid TEXT,\
		username TEXT,\
		image TEXT,\
		first_name TEXT,\
		last_name TEXT,\
		full_name TEXT,\
		birthday TEXT,\
		email TEXT,\
		password TEXT,\
		phone TEXT,\
		employees TEXT,\
		addresses TEXT,\
		address_ids TEXT,\
		currency TEXT,\
		address_id INTEGER DEFAULT 0,\
		total_paid INTEGER DEFAULT 0,\
		moderations INTEGER DEFAULT 0,\
		savings INTEGER DEFAULT 0,\
		payment_payroll_limit INTEGER DEFAULT 0,\
		payment_amount_limit INTEGER DEFAULT 0,\
		total_limit INTEGER DEFAULT 0,\
		description INTEGER DEFAULT 0,\
		moderation INTEGER DEFAULT 0,\
		guicreate INTEGER DEFAULT 0,\
		guiedit INTEGER DEFAULT 0,\
		guidestroy INTEGER DEFAULT 0,\
		enabled INTEGER DEFAULT 0,\
		day_start_time TEXT,\
		day_end_time TEXT,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';