import { environment } from '../../../environments/environment';
import { SQLService } from '../../services/sql.service';
import { NewTax } from '../../models/tax';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
@Component({
  selector: 'ng-cheques-new-tax',
  templateUrl: './new-tax.component.html',
  styleUrls: ['./new-tax.component.styl']
})
export class NewTaxPage implements OnInit {
  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    public sql: SQLService
    ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.open();
    });
  }
  tax = new NewTax();
  currencies = Object.keys(environment.currencies);
  _c=0;
  end() {
    if (this._c>0) {
      if (confirm(this.sql.word.Discard_Confirmation)) {
        this.tax = new NewTax();
        this.router.navigate(['folder','Taxes']);
      }
    } else {
      this.router.navigate(['folder','Taxes']);
    }
  }
  delete_tax() {
    if(!this.tax.tax_id){
      this.end();
    } else if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('Taxes','tax_id',this.tax.tax_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  save(end=true) {
    if(this.tax.percentage>100){
      alert(this.sql.word.Percentage_Full_Error);
      return;
    } else {
      this.sql.add("Taxes",this.tax,(insertId)=>{
        if(!insertId)return;
        if(end) {
          this.tax = new NewTax();
          this._c=0;
          this.end();
        }
      });  
    }
  }
  amount(event) {
    if(!event.target.value||event.target.value==0){
      this.tax.amount='0.00';
    } else {
      this.tax.amount=this.sql.amount(event.target.value,null,this.tax.currency);
    }
  }
  get_tax_deductions() {
    let ida=this.route.snapshot.paramMap.get("id");
    if(!ida||ida=='New')return;
    this.sql.view('Taxes','tax_id',ida,(e)=>{
      var f=Object.entries(e[0]); let n = new NewTax();
      for(let t of f)n[t[0]]=t[1];
        this.tax=n;
        console.log(this.tax);
    });
  }
  open() {
    var d = new Date(Date.now()).toISOString();
    this.tax.start_date = d;
    var date = new Date();
    var start_time = date.setHours(9,0,0);
    this.tax.start_time = new Date(start_time).toISOString();
    var date = new Date();
    var end_time = date.setHours(17,0,0);
    this.tax.end_time = new Date(end_time).toISOString();
    this.tax.enabled = true;
    this.tax.always_enabled=true;
    this.tax.frequency='fortnite';
    this.tax.paytype='percentage';
    this.tax.currency=this.sql.Currency; 
    this.get_tax_deductions();
  }
  ngOnInit() {
    // this.tax.currency=this.sql.Currency;
  }
}