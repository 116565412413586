import { NewModerator } from '../../../models/moderator';
import { environment } from '../../../../environments/environment';
import { SelectAddressPage } from '../../../addresses/select-address/select-address.page';
import { SelectEmployeePage } from '../../../employees/select-employee/select-employee.page';
import { SQLService } from '../../../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { Location } from '@angular/common';
@Component({
  selector: 'ng-cheques-new-moderator',
  templateUrl: './new-moderator.component.html',
  styleUrls: ['./new-moderator.component.styl']
})
export class NewModeratorComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public sql: SQLService,
    private location: Location,
    public modalController: ModalController
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) {
        this.moderator.currency = this.sql.Currency;
        this.moderator.enabled = true;
      }
    });
  }
  _c=0;
  moderator = new NewModerator();
  currencies:any;
  async add_address() {
    const m = await this.modalController.create({component: SelectAddressPage, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1) {
        this.delete_address();
        this.sql.hapticsImpactLight();
      } else {
        this.moderator.address_ids = a.data;
        this.sql.view('Addresses','address_id',a.data,(a)=>{this.moderator.addresses=a;});
        this._c=1;
        this.sql.hapticsImpactMedium();  
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_address(address) {
    this.router.navigate(['folder','Address', address.address_id]); 
  }
  delete_address() {
    this.moderator.addresses = null;
    // this.sql.set( 'Moderators', 'mod_id', this.moderator.mod_id, 'addresses', null);
  }
  delete_moderator() {
    if(!this.moderator.mod_id){
      this.end();
    } else if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('Moderators','mod_id',this.moderator.mod_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  async add_employee() {
    const m = await this.modalController.create({component: SelectEmployeePage, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1) {
        this.delete_employee();
        this.sql.hapticsImpactLight();
      } else {
        this.moderator.employees = a.data;
        this._c=1;
        this.sql.hapticsImpactMedium();
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_employee(employee) {
    this.router.navigate(['folder','Employee', employee.employee_id]); 
  }
  delete_employee() {
    this.moderator.employees = null;
    // this.sql.set( 'Moderators', 'mod_id', this.moderator.mod_id, 'employees', null);
  }
  end() {
    if (this._c>0) {
      if(confirm(this.sql.word.Discard_Confirmation)) {
        this.moderator = new NewModerator();
        this.router.navigate(['folder','Moderators']);
      }
    } else {
      this.router.navigate(['folder','Moderators']);
    }
  }
  save(end=true) {
    this.sql.add("Moderators",this.moderator,(insertId)=>{
      if(!insertId)return;
      if(end){
        this.moderator = new NewModerator();
        this._c=0;
        this.end();
      }
    });
  }
  update() { this.sql.update("Moderators",this.moderator); }
  get_moderators() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida) return;
    this.sql.view('Moderators','mod_id',ida,(e)=>{
      var f = Object.entries(e[0]); let n=new NewModerator();
      for( let t of f) n[t[0] ]=t[1]; this.moderator = n;
    });
  }
  open() {
    this.currencies = Object.keys(environment.currencies);
    this.moderator.currency = this.sql.Currency;
    this.moderator.enabled = true;
    this.get_moderators();
  }
  ngOnInit() {
    this.open();
  }
}