import { SQLService } from '../../services/sql.service';
import { PayrollService } from '../../services/payroll.service';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { Location } from '@angular/common';
import { ToastController } from '@ionic/angular';
import { PreviewPaymentFieldsPage } from './preview-payment/preview-payment.component';
@Component({
  selector: 'ng-cheques-preview-payroll',
  templateUrl: './preview-payroll.component.html',
  styleUrls: ['./preview-payroll.component.styl']
})
export class PreviewPayrollComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    public location: Location,
    public sql: SQLService,
    public route: ActivatedRoute,
    public payroll: PayrollService,
    private toast: ToastController,
    private navCtrl: ModalController,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.open();
    });
  }
  payments = [];
  count = -1;
  payroll_id:any;
  search:string;
  ran:boolean;
  show_payments=-1;
  ngOnDestroy() {
    this.payments = [];
    this.count = -1;
    this.payroll_id = 0;
    this.search = '';
    this.show_payments=-1;
    clearInterval(this.payroll.inter);
  }
  filter(e) {
    this.search=e.target.value;
  }
  add() {
    this.router.navigate(['folder','Payroll','New']);
  }
  end() {
    if(this.payroll_id)this.router.navigate(['folder','Payroll',this.payroll_id]);
    if(!this.payroll_id)this.router.navigate(['folder','Payroll']);
    this.ngOnDestroy();
  }
  ngAfterViewInit(){
    this.open()
  }
  ngOnInit(){
    this.ran = false;
  }
  save() {
    if(this.payments) {
      if(this.payroll_id) {
        this.sql.view('Payrolls','payroll_id',this.payroll_id,(a)=>{
          a=a[0];
          if(a.payment_method=='cheque'){
            this.payroll.generate_cheques(this.payments,(Cheques)=>{
              if(this.sql.verbose)console.log('Generated New Cheques:',Cheques);
              if(Cheques)this.router.navigate(['folder','Cheques']);
            });
          }else if(a.payment_method=='payment'){
            this.payroll.run_payroll(this.payroll_id,true,(result) => {
              if(result)this.router.navigate(['folder','Payments']);
            });    
          }else if(a.payment_method=='paypal'){
            this.payroll.run_payroll(this.payroll_id,true,(result) => {
              if(result)this.router.navigate(['folder','Payments']);
            });
          }
        });
      } else {
        // this.payroll.generate_cheques(this.payments,(Cheques)=>{
        //   if(this.sql.verbose)console.log('Generated New Cheques:',Cheques);
        //   if(Cheques)this.router.navigate(['folder','Cheques']);
        // });
        this.multiples(0, true);
      }
    } else {
      alert(this.sql.word.No_Payments_Created);
    }
  }
  multiples(o=0,save=false,cb?) {
    this.sql.fetch('Payrolls',(p)=>{
      if(p&&p[o]&&p[o].payroll_id) {
        if(p[o].payroll_id)this.payroll.run_payroll(p[o].payroll_id,save,(PayrollResult)=>{
          if(save){
            if(p.length>o) this.multiples(o+1, save, cb);
            if(p.length-1==o && this.payments.length>0){
              if(cb)cb();
              if(PayrollResult)this.router.navigate(['folder','Cheques']);
            }
          } else if(PayrollResult){
            for(let a of PayrollResult) this.payments.push(a);
            if(p.length>o) this.multiples(o+1, save, cb);
            console.log(p.length-1,o,this.payments.length);
            if(p.length-1==o && this.payments.length>0){
              console.log('Payments', this.payments);
              this.show_payments=1;
              this.ran=false;
              if(cb)cb();
            }
            if(p.length-1==o && this.payments.length==0){
              console.log('No payments', this.payments);
              this.show_payments=0;  
            }
          }
        });
      }
    });
  }
  open(event?){
    this.payments=[];
    if(event)this.ran=false;
    if(this.ran)return;
    this.ran=true;
    let ida=this.route.snapshot.paramMap.get("id");
    if(!ida)return;
    if(ida=='All') {
      this.multiples(0,false,()=>{
        if(event)setTimeout(()=>{event.target.complete();},333);
      });
    } else {
      this.payroll_id=ida;
      this.payroll.run_payroll(this.payroll_id,false,(payments)=>{
        this.payments=payments;
        if(this.payments.length>0){
          this.show_payments=1;
        }
        if(this.payments.length==0)this.show_payments=0;
        if(event)setTimeout(()=>{event.target.complete();},333);
      });
    }
  }
  async preview(item){
    const m = await this.navCtrl.create({component: PreviewPaymentFieldsPage, componentProps: {
      data: item
    }});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data){
    }
  }
  async doneImport(count,type) {
    this.router.navigate(['folder',type])
    const toast = await this.toast.create({
      message: this.sql.word.Successfully_Imported+' '+count+' '+this.sql.word[type],
      duration: 2000
    });
    toast.present();
  }
}