import { SQLService } from '../../services/sql.service';
import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { FaxMenuComponent } from './fax-menu/fax-menu.component';
import { PopoverController} from '@ionic/angular';
import { Router,NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-faxes',
  templateUrl: './faxes.component.html',
  styleUrls: ['./faxes.component.scss'],
})
export class FaxesComponent implements OnInit {
 constructor(
    public sql: SQLService,
    public popoverController: PopoverController,
    public location: Location,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_faxes();
    });
  }
  count = -1;
  faxes = [];
  search: string;
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:FaxMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='send')this.send(item);
      if(data=='delete')this.delete(item);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  filter(e) { this.search=e.target.value; }
  end() {this.location.back()}
  get_faxes(event?) {
    this.sql.fetch('Faxes',(e)=>{
      this.faxes=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  edit(file, item?) {
    this.router.navigate(['folder','Fax',file.fax_id]);
  }
  delete(file, item?) { 
    if(confirm(this.sql.word.Delete_Fax_Confirmation)) {
      if(item)item.closeSlidingItems();
    } else { if(item)item.closeSlidingItems(); }
  }
  send(fax, item?) { 
    if(confirm(this.sql.word.Send_Fax_Confirmation)) {
      if(item)item.closeSlidingItems();
    } else { if(item)item.closeSlidingItems(); }
  }
  add() {
    this.router.navigate(['folder','Fax','New']);
  }
  ngOnInit() {
    if(!this.sql.fax)this.end();
    this.get_faxes();
  }
}
