import { SQLService } from './sql.service';
import { NewCheque } from '../models/cheque'; 
import { NewPayment } from '../models/payment';
import { NewPayroll } from '../models/payroll'; 
import { NewTimesheetEntry } from '../models/timesheetentries';
import { environment } from '../../environments/environment';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { Globalization } from '@ionic-native/globalization/ngx';
import * as converter from 'number-to-words'; 
import { Injectable } from '@angular/core';
@Injectable()
export class TimesheetService {
	constructor(
	private http: HttpClient,
	public sql: SQLService ) {}
	public verbose = environment.verbose;
	public Currency_Rates = environment.currencies;
	more_verbose = true;
	timesheetentry = new NewTimesheetEntry();
	timesheet_entries = [];
	count = -1;
	calculate_seconds(timesheet_id, cb?) {
		if(timesheet_id) {
		   	this.get_timesheet_entries(timesheet_id, (entries, count)=>{
		   		var diff = 0;
		   		for (var i = 0; i < entries.length; ++i) {
		   			var start_date = new Date(entries[i].start_date);
		   			var end_date = new Date();
		   			if(entries[i].end_date) end_date = new Date(entries[i].end_date);
		   			diff=diff+Number(Number(end_date)-Number(start_date));
		   		}
				var sec = Number(diff / 1000);
				var hours = Math.floor(Number(sec / 3600));
				var minutes = Math.floor(Number(sec / 60));
				var seconds = Math.floor((sec % 60));
				if(cb)cb(hours,minutes,seconds);
		   	});
		} else {
			if(cb)cb(0);
			return 0;
		}
	}
	calculate_entries(entries,cb?) {
		var diff = 0;
		var start_date = new Date(entries.start_date);
		var end_date = new Date();
		if(entries.end_date) end_date = new Date(entries.end_date);
		diff=diff+Number(Number(end_date)-Number(start_date));
		var sec = Number(diff / 1000);
		var hours = Math.floor(Number(sec / 3600));
		var minutes = Math.floor(Number(sec / 60));
		var seconds = Math.floor((sec % 60));
		if(cb)cb(hours,minutes,seconds);
	}
	get_timesheet_entries(timesheet_id?,cb?) {
		var qq = 'TimesheetEntry';
		if (timesheet_id) qq='TimesheetEntry WHERE timesheet_id = '+timesheet_id;
		this.sql.fetch(qq,(e)=>{
			if(cb)cb(e,e.length);
		});
	}
	kill_timer(ac,cb?) {
		this.sql.set('TimesheetEntry','timesheet_entry_id',ac,'timer_active',false,(id)=>{
			var now=new Date(Date.now()).toISOString();
			this.sql.set('TimesheetEntry','timesheet_entry_id',ac,'end_date',now,(id)=>{
				this.sql.set('TimesheetEntry','timesheet_entry_id',ac,'end_time',now,(id)=>{
					if(cb)cb();
				});
			});
		});
	}
	set_timer(a) {
		a.timer='';
		a.timer=a.timer+''+(a.days?a.days+':':'');
		a.timer=a.timer+''+(a.hours>0?a.hours:a.days>0?'00':'0')+':';
		a.timer=a.timer+''+(a.minutes>0?(a.minutes<10)?'0'+a.minutes:a.minutes:'00')+':';
		a.timer=a.timer+''+(a.seconds>0?(a.seconds<10)?'0'+a.seconds:a.seconds:'00');
		return a;
	}
	seconds_between_two_dates( start, end ) {

	}
}