export class NewChequeEntry {
	constructor(
		public cheque_entry_id?: number,
		public cheque_id?: number,
		public payment_id?: number,
		public title?: string,
		public number?: string,
		public description?: string,
		public notes?: string,
		public amount?: string,
		public mode?: string,
		public fiat?: string,
		public currency?: string,
		public address_ids?: any,
		public alwaysEnabled?: boolean,
		public enabled?: boolean,
		public addresses?: any,
		public date?: any,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_ChequeEntry = '\
	CREATE TABLE IF NOT EXISTS "ChequeEntry" (\
		cheque_entry_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		cheque_id INTEGER DEFAULT 0,\
		payment_id INTEGER DEFAULT 0,\
		title TEXT,\
		number TEXT,\
		description TEXT,\
		notes TEXT,\
		amount TEXT,\
		mode TEXT,\
		fiat TEXT,\
		currency TEXT,\
		address_ids TEXT,\
		addresses TEXT,\
		alwaysEnabled INTEGER DEFAULT 0,\
		enabled INTEGER DEFAULT 0,\
		date TIMESTAMP DEFAULT NULL,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';