import { NewInvoice } from '../../models/invoice';
import { SQLService } from '../../services/sql.service';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import * as converter from 'number-to-words';
@Component({
  selector: 'ng-generate-invoice',
  templateUrl: './generate-invoices.component.html',
  styleUrls: ['./generate-invoices.component.styl']
})
export class GenerateInvoiceComponent implements OnInit {
  constructor(
    private document: DocumentViewer,
    public sql: SQLService,
    private activatedRoute: ActivatedRoute
  ) { }
  @Input('invoice_id') invoice_id: any;
  @ViewChild('pdfViewerAutoLoad') pdfViewerAutoLoad;
  invoice= new NewInvoice();
  pdf_data: any;
  settings = {
    company_title: this.sql.get_setting('company_title'),
    company_description: this.sql.get_setting('company_description')
  } 
  document_settings = {
    author: '"Cheques" version '+this.sql.version+' '+this.sql.date_man,
    title: this.sql.word.Generated_Invoice,
    subject: this.sql.word.PDF_Subject,
    keywords: this.sql.word.PDF_Keywords
  }
  generatePdf(p?) {
    // var zeroPad = (num, places) => String(num).padStart(places, '0');
    // this.invoice.number = zeroPad(this.invoice_id, 7);
    // var pdf = {
    //   content: [
    //     {
    //     columns: [
    //         { width: '*', text: '' },
    //         {
    //           width: 'auto',
    //       table: {
    //         widths: [ 'auto' ],
    //         body: [
    //           [
    //             {
    //               style: 'ChequeBox',
    //               layout: 'noBorders',
    //               table: {
    //                 widths: [ 470 ],
    //                 body: [
    //                   [
    //                     {
    //                       columns: [
    //                         {
    //                           width: 395,
    //                           margin: [ 12, 11, 0, 0 ],
    //                           text: { fontSize: 10, bold: true, text: this.settings.company_title },
    //                         },
    //                         {
    //                           width: 'auto',
    //                           margin: [ 2, 10, 0, 0 ],
    //                           text: { fontSize: 11, text: this.invoice.invoice_id }
    //                         }
    //                       ]
    //                     },
    //                   ],
    //                   [
    //                     {
    //                       layout: 'noBorders',
    //                       table: {
    //                         widths: [290,23,'auto'],
    //                         body: [
    //                           [
    //                             { fontSize: 10, text: '' },
    //                             { margin: [ 0, 4, 0, 0 ], fontSize: 9, text: 'DATE' },
    //                             { margin: [ 0, 1, 0, 0 ], fontSize: 12, text: this.invoice.date }
    //                           ],
    //                         ]
    //                       }
    //                     },
    //                   ],
    //                   [
    //                     {
    //                       margin: [ 0, 12, 0, 0 ],
    //                       columns: [
    //                         {
    //                           width: 300,
    //                           margin: [ 2, 2, 0, 0 ],
    //                           text: { fontSize: 14, text: this.invoice.title },
    //                         },
    //                         {
    //                           width: 50,
    //                           text: { fontSize: 12, text: this.invoice.amount }
    //                         }
    //                       ]
    //                     },
    //                   ],
    //                   [
    //                     {
    //                       margin: [ 13, 0, 0, 0 ],
    //                       columns: [
    //                         {
    //                           width: 320,
    //                           margin: [ 2, 2, 0, 0 ],
    //                           text: { fontSize: 11, text: this.invoice.amount },
    //                         }
    //                       ]
    //                     },
    //                   ],
    //                   [
    //                     {
    //                       layout: 'noBorders',
    //                       margin: [ 13, 0, 0, 0 ],
    //                       table: {
    //                         widths: [50,100,100,12],
    //                         body: [
    //                           [
    //                             { fontSize: 8, text: 'MEMO' },
    //                             { fontSize: 10, text: '' },
    //                             { fontSize: 10, text: '' },
    //                             { fontSize: 5, text: 'MP' }
    //                           ],
    //                         ]
    //                       }
    //                     },
    //                   ]
    //                 ]
    //               }
    //             }
    //           ]
    //         ]
    //       }
    //     },
    //     { width: '*', text: '' },
    //     ],
    //     },
    //   ],
    //   styles: {
    //     ChequeBox: {
    //       margin: [0, 5, 0, 15],
    //       fillColor: '#fff1da'
    //     }
    //   },
    // };
    // var fonts = {
    // };
    // pdfMake.vfs = pdfFonts.pdfMake.vfs;
    // if ( p ) pdfMake.createPdf(pdf, null, fonts).print();
    // if (!p ) pdfMake.createPdf(pdf, null, fonts).getBlob((blob) => {
    //   this.document.viewDocument(blob, 'application/pdf', {})
    //   this.pdfViewerAutoLoad.pdfSrc = blob;
    //   this.pdfViewerAutoLoad.refresh();
    // });
  }
  print(id?) {                                                                                                                                                                                                                                                                                                                                                      /*#3;#3;#71;*/

  }
  printed(id?) {                                                                                                                                                                                                                                                                                                                                                      /*#1322;*/

  }
  ngOnInit() {
    this.invoice_id = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.invoice_id) this.generatePdf();
  }
}