import { environment } from '../../../../environments/environment';
import { SQLService } from '../../../services/sql.service';
import { Component, OnInit, Input, EventEmitter,ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController, NavParams } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import * as Languages from './languages/index';
@Component({
	selector: 'app-eula',
	templateUrl: './eula.component.html',
	styleUrls: ['./eula.component.styl']
})
export class EULAComponent implements OnInit {
	constructor(
		private router: Router,
		public sql: SQLService,
		private route: ActivatedRoute,
		private navCtrl: ModalController,
		private params: NavParams,
		private current_platform: Platform,
		private location: Location
	) {
		if(this.current_platform.is('ios')){this.platform='apple'}
		if(this.current_platform.is('android')){this.platform='google'}
    	this.force = params.get('force');
		this.router.events.subscribe((eve) => {
			//if(eve instanceof NavigationEnd) this.get_purchase();
		});
	}
	force=true;
	contents='';
	platform:string;
	ngOnInit() {
		this.contents=Languages[this.sql.Language.replace('-','').toLowerCase()].translation;
	}
	end(agreement=false) {
		if(!agreement&&this.force)alert(this.sql.word.Terms_Required_Word);
		if(agreement||this.force==false)this.navCtrl.dismiss(agreement);
	}
}