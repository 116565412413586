export class NewTimesheet {
	constructor(
		public timesheet_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public employee_id?: number,
		public payee_id?: number,
		public last_saved?: any,
		public uuid?: string,
		public start_date?: any,
		public start_time?: any,
		public end_date?: any,
		public end_time?: any,
		public amount?: number,
		public name?: string,
		public description?: string,
		public currency?: string,
		public time?: number,
		public rate?: number,
		public employee_ids?: any,
		public payee_ids?: any,
		public completed?: boolean,
		public employees?: any,
		public payees?: any,
		public jobs?: any,
		public days?: number,
		public hours?: number,
		public minutes?: number,
		public seconds?: number,
		public timer?: any,
		public last_start_time?: any,
		public active_timer?: number,
		public timer_active?: number,
		public enabled?: boolean,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Timesheets = '\
	CREATE TABLE IF NOT EXISTS "Timesheets" (\
		timesheet_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		employee_id INTEGER DEFAULT 0,\
		payee_id INTEGER DEFAULT 0,\
		last_saved TEXT,\
		uuid TEXT,\
		start_date TIMESTAMP,\
		start_time TIMESTAMP,\
		end_date TIMESTAMP,\
		end_time TIMESTAMP,\
		amount INTEGER DEFAULT 0,\
		name TEXT,\
		description TEXT,\
		currency TEXT,\
		time TEXT,\
		employee_ids TEXT,\
		payee_ids TEXT,\
		rate VARCHAR(100),\
		completed INTEGER DEFAULT 0,\
		employees TEXT,\
		payees TEXT,\
		jobs TEXT,\
		days INTEGER,\
		hours INTEGER,\
		minutes INTEGER,\
		seconds INTEGER,\
		timer TEXT,\
		last_start_time TEXT,\
		active_timer INTEGER DEFAULT 0,\
		timer_active INTEGER DEFAULT 0,\
		enabled INTEGER DEFAULT 0,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';