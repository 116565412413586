export class NewKey {
	constructor(
		public key_id?: number,
		public admin_id?: number,
		public moderator_id?: number,
		public employee_id?: number,
		public keys?: string,
		public type?: string,
		public ab?: string,
		public iv?: string,
		public qr_image?: string,
		public uses?: number,
		public enabled?: boolean,
		public modified?: any,
		public created?: any
	){}
}
export const Create_Keys = '\
	CREATE TABLE IF NOT EXISTS "Keys" (\
		key_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER,\
		moderator_id INTEGER,\
		employee_id INTEGER,\
		keys TEXT,\
		type TEXT,\
		ab TEXT,\
		iv TEXT,\
		qr_image TEXT,\
		uses INTEGER DEFAULT 0,\
		enabled INTEGER DEFAULT 0,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';