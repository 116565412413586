import { SQLService } from '../../services/sql.service';
import { PopoverController, AlertController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'ng-cheques-dashboard-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.styl']
})
export class PopoverComponent implements OnInit {
  constructor(
    public sql: SQLService,
    public alertController: AlertController,
    public popover: PopoverController,
    private router: Router) {
  }
  _locked:boolean;
  list:string;
  dismissPopover(e) {
    if (this.popover) this.popover.dismiss(e);
  }
  import() {
    this.router.navigate(['folder','Import','New']);
    this.dismissPopover('import');
  }
  import_xls() {
    this.router.navigate(['folder','Import','New','xls']);
    this.dismissPopover('import_xls');
  }
  import_csv() {
    this.router.navigate(['folder','Import','New','csv']);
    this.dismissPopover('import_csv');
  }
  import_json() {
    this.router.navigate(['folder','Import','New','csv']);
    this.dismissPopover('import_csv');
  }
  import_sql() {
    this.router.navigate(['folder','Import','New','sql']);
    this.dismissPopover('import_sql');
  }
  import_uri() {
    this.router.navigate(['folder','Import','New','uri']);
    this.dismissPopover('import_uri');
  }
  administration() {
    this.router.navigate(['folder','Administrators']);
    this.dismissPopover('administration');
  }
  privacy() {
    this.router.navigate(['folder','Privacy']);
    this.dismissPopover('privacy');
  }
  settings() {
    this.router.navigate(['folder','Settings']);
    this.dismissPopover('settings');
  }
  logout() {
    if(this.sql.model=='web') {
      // document.location.pathname='/api/login';
      this.dismissPopover('logout');
      this.sql.logout(()=>{ })
    } else if(this.sql.model=='electron') {
      this.dismissPopover('logout');
      // this.sql.logout(()=>{
      //   this.dismissPopover('logout');
      // })
    } else {
      this.router.navigate(['folder','Splash']);
      this.dismissPopover('logout');
    }
  } 
  login()      { this.dismissPopover('login');     }
  signup()     { this.dismissPopover('signup');    }
  customize()  { this.dismissPopover('customize'); }
  purchase()   { this.dismissPopover('purchase');  }
  public lockdown() {
    this.sql.locked = true;
    this.sql.save_setting('lockdown',"1");
    this.dismissPopover('lockdown');
    setTimeout(()=>{this._locked=this.sql.locked;},321);
  }
 public unlock(cb?) {
    this.sql.get_setting('pin_enabled',(s)=>{
      if(!s || !s.option || s.option!='1') {
        this.sql.locked = false;
        this.sql.save_setting('lockdown',"0");
        this.dismissPopover('unlock');
      } else {
        this.sql.get_setting('pin',(s)=>{
          if(!s){
            this.sql.locked = false;
            this.sql.save_setting('lockdown',"0");
            this.dismissPopover('unlock');
            return;
          } else {
            var old_pin = (s.option)?s.option:false;
              if(old_pin){
                 let pin_conf = this.alertController.create({
                  header:this.sql.word.Pin_Change_Header,
                  subHeader:this.sql.word.PIN_Enter_Message,
                  inputs: [
                    {name: 'pin_conf', type: 'password', attributes:{placeholder: this.sql.word.PIN_Code, maxlength:4,inputmode:"numeric"}}
                  ],
                  buttons: [
                    { text: this.sql.word.Cancel, role: 'cancel', cssClass: 'secondary', handler: () => { 
                        if(this.sql.verbose)console.log('PIN Confirmation Cancelled.');
                      }
                    },
                    { text: this.sql.word.Confirm, handler: (data) => {
                        if ( old_pin == data.pin_conf ) {
                          this.sql.locked = false;
                          this.sql.save_setting('lockdown',"0");
                          this.dismissPopover('unlock');
                          setTimeout(()=>{this._locked=this.sql.locked;},321);
                        } else { 
                          cb(false);
                        }  
                      }
                    }
                  ]
                }).then(res=>{
                  res.present();
                })
              } else {
                this.sql.locked = false;
                this.sql.save_setting('lockdown',"0");
                this.dismissPopover('unlock');
              }
          }
        });
      }
    });
  }
  setup() {
    this.router.navigate(['folder','Intro']);
    this.dismissPopover('intro');
  }
  ngOnInit() {
    this.sql.has_security();
    this._locked = this.sql.locked;
  }
}