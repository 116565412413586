import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'name'
})
export class NamePipe implements PipeTransform {
  transform(value: any, args?: any): any {
	  if ( args && args!=null && args!='') {
			let values = [];
			for (var i = value.length - 1; i >= 0; i--) {
				if ( value[i].title) {
					var regex = RegExp(value[i].title+'*');
					if( regex.test(args) ) values.push(value[i]);
				}
				if ( value[i].name) {
					var regex = RegExp(value[i].name+'*');
					if( regex.test(args) ) values.push(value[i]);
				}
				if ( value[i].first_name) {
					var regex = RegExp(value[i].first_name+'*');
					if( regex.test(args) ) values.push(value[i]);
				}
				if ( value[i].last_name && !regex) {
					var regex = RegExp(value[i].last_name+'*');
					if( regex.test(args) ) values.push(value[i]);
				}
			}
			return values;
		} else {
			return value;
		}
  }
}
