import { environment } from '../../../../environments/environment';
import { SQLService } from '../../../services/sql.service';
import { NewCheque } from '../../../models/cheque';
import { AllSCSS } from '../../templates_html';
import { fade } from '../../../app.animations';
import { PaperSizes } from '../../../../environments/papersizes';
import { Component, Directive, Input, OnInit, AfterViewInit, OnChanges, OnDestroy, ElementRef, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { Gesture, GestureController, LoadingController, Platform } from '@ionic/angular';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import * as Languages from '../../../../environments/languages/index';
import * as Hammer from 'hammerjs';
declare var html2canvas: any;
@Component({
  animations: [fade],
  selector: 'ng2-html-viewer',
  templateUrl: './ng-html-viewer.component.html',
  styleUrls: ['./ng-html-viewer.component.styl']
})
export class HTMLViewerComponent implements AfterViewInit,OnChanges,OnDestroy {
  @ViewChild('print_container') print_container: ElementRef;
  @ViewChild('preview') preview: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('micr_numbers') micr_number_ref: ElementRef;
  @ViewChild('page') page: ElementRef;
  @Output('docugard') docugard = new EventEmitter();
  @Output('image') exporter = new EventEmitter();
  @Output('html') html = new EventEmitter();
  @Output('load_trigger') load_trigger = new EventEmitter();
  @Output('cheque_html') cheque_html = new EventEmitter();
  @Input('destroy') destroying = false;
  @Input('language') language;
  @Input('page_size') page_size;
  @Input('page_width') page_width;
  @Input('page_height') page_height;
  @Input('printer_page_size') printer_page_size;
  @Input('printer_page_width') printer_page_width;
  @Input('printer_page_height') printer_page_height;
  @Input('preview_top_page_padding') preview_top_page_padding = 7;
  @Input('preview_right_page_padding') preview_right_page_padding = 6;
  @Input('preview_bottom_page_padding') preview_bottom_page_padding = 25;
  @Input('preview_left_page_padding') preview_left_page_padding = 6;
  @Input('subtract_margins') subtract_margins = true;
  @Input('fullscreen') fullscreen = false;
  @Input('show_cheque') show_cheque = true;
  @Input('show_address') show_address = true;
  @Input('layers') layers = true;
  @Input('gradients') gradients = true;
  @Input('previewing') previewing = true;
  @Input('monochrome') monochrome = false;
  @Input('RenderMICR') RenderMICR = false; 
  @Input('ImageMICR') ImageMICR = true; 
  @Input('voidcheck') voidcheck = false;
  @Input('rotate') rotate = false;
  @Input('print') _p='';
  @Input('width') width = 793;
  @Input('canvasWidth') canvasWidth = 793;
  @Input('height') height = 1122;
  @Input('canvasHeight') canvasHeight = 1122;
  @Input('quality') quality = 1;
  @Input('scale') scale = 1;
  @Input('color') color: any;
  @Input('font') font: any;
  @Input('show') show: any;
  @Input('secret') secret = '';
  @Input('micr_top') micr_top: any;
  @Input('micr_right') micr_right: any;
  @Input('micr_bottom') micr_bottom: any;
  @Input('micr_left') micr_left: any;
  @Input('page_top') page_top: string;
  @Input('page_right') page_right: string;
  @Input('page_bottom') page_bottom: string;
  @Input('page_left') page_left: string;
  @Input('table_top') table_top: string;
  @Input('table_right') table_right: string;
  @Input('table_bottom') table_bottom: string;
  @Input('table_left') table_left: string;
  @Input('address_top') address_top: string;
  @Input('address_right') address_right: string;
  @Input('address_bottom') address_bottom: string;
  @Input('address_left') address_left: string;
  @Input('micr') micr: boolean;
  @Input('numbers') numbers: boolean;
  @Input('date') date: boolean;
  @Input('borders') borders: any;
  @Input('address') address: any;
  @Input('print_scale') print_scale = 0.9;
  @Input('designer') designer: boolean;
  @Input('fontsize') fontsize: number;
  @Input('bold') bold: boolean;
  @Input('fiat') fiat: boolean;
  @Input('italic') italic: boolean;
  @Input('cheque_text_color') cheque_text_color: string;
  @Input('background_position') background_position: string;
  @Input('cheque_background_image_repeat') cheque_background_image_repeat: boolean;
  @Input('cheque_background_image') cheque_background_image: string;
  @Input('cheque_logo_image') cheque_logo_image: string;
  @Input('cheque_header_image') cheque_header_image: string;
  @Input('cheque_bank_image') cheque_bank_image: string;
  @Input('doc_color') doc_color: string;
  @Input('template') template: string;
  @Input('fold_line') fold_line: boolean;
  @Input('center_view') centering_view: boolean;
  @Input('document') document: any;
  @Input('lines') lines = [];
  @Input('design') design: any;
  @Input('data') cheque = new NewCheque();
  translations=this.sql.word;
  constructor(
    private router: Router,
    public sql: SQLService,
    private platform: Platform,
    private loadingCtrl: LoadingController,
    private screenOrientation: ScreenOrientation,
    private changeDetectorRef: ChangeDetectorRef,
    private gestureCtrl: GestureController
  ) { 
    // this.router.events.subscribe((eve) => {
    //   if(eve instanceof NavigationEnd) {
    //     this.center_view(); 
    //   }
    // });
    this.screenOrientation.onChange().subscribe(()=>{
      this._auto_centered=false;
      this.center_view();
    });
  }

  _rendered_numbers:boolean;
  _auto_centered:boolean;
  emitted:boolean;
  _int_1:any;
  _int_2:any;
  _int_3:any;
  _int_4:any;
  _int_5:any;
  load_timer:any;
  container: any;
  imgWidth: any;
  imgHeight: any;
  viewportWidth: any;
  lastScale: any;
  viewportHeight: any;
  curWidth: any;
  curHeight: any;
  pinchCenter: any;
  x: number;
  y: number;
  lastX: number;
  lastY: number;
  pinchCenterOffset: any;
  MIN_SCALE = 0.45;
  MAX_SCALE = 4;
  paper = 0;
  hammer: any;
  no_load:boolean;
  done:boolean;
  rendering=true;
  rendering_micr=true;
  micr_dataimg:string;
  loaded:boolean;
  allow_saving=false;
  page_opacity=0;
  render_checker:any;
  dataimg:any;
  html_canvas:string;
  render_image = false;
  show_render = false;
  print_canvas_on = true;
  page_loaded = false;
  android = false;
  options = {
    cacheBust: true,
    skipAutoScale: false,
    width:this.width?this.width:null,
    height:this.height?this.height:null,
    canvasWidth:this.canvasWidth?this.canvasWidth:null,
    canvasHeight:this.canvasHeight?this.canvasHeight:null,
    quality:this.quality?this.quality:null,
  }
  render(cb?) { 
    this.print_canvas_on=true;
    this.rendering=true;
    if(!this.render_image) {
      return this.post_render();
    } else if(html2canvas) html2canvas(this.canvas.nativeElement,{ 
      logging: this.sql.more_verbose,
      imageTimeout: 555,
      scale: 1,
      backgroundColor: '#FFFFFF',
      // width: this.canvasWidth,
      // height: this.canvasHeight,
      // windowWidth: this.printer_page_width,
      // windowHeight: this.printer_page_height
    }).then((b)=>{
      if(this.sql.save_in_file_format=='png')  this.dataimg = b.toDataURL('image/png');
      if(this.sql.save_in_file_format=='jpeg'||this.sql.save_in_file_format=='jpg') this.dataimg = b.toDataURL('image/jpeg', this.quality);
      this.exporter.emit(this.dataimg);
      if(this.sql.more_verbose)console.log('Rendering Image Now in Format',this.sql.save_in_file_format,this.dataimg);
      if(!this.address && this.cheque)this.save_cheque_image(this.dataimg);
      if(this.address)this.save_address_image(this.dataimg);
      this.done=true;
      return this.post_render();
    });
  }
  async post_render(){
    this.html_canvas='';
    if(this.designer||this.fullscreen)this.print_canvas_on=false;
    if(this.template&&this.template.indexOf('DocuGard')>=0) this.docugard.emit(1);
    var a = await this.capture_canvas();
    return a;
  }
  render_numbers(f=false, i=0) {
    if(this.designer) {
      if(this.sql.more_verbose)console.log('Design Mode Enabled', 'MICR Not Rendering');
      return;
    } else if(this._rendered_numbers&&!f) {
      if(this.sql.more_verbose)console.log('MICR already rendered? None required?');
      return;
    } else {
      if(this.sql.verbose)console.log('Rendering Numbers');
      if(!this.micr_number_ref) {
        if(this.sql.more_verbose)console.log('Error pinning MICR font box.');
        if(i<6)setTimeout( () => { this.render_numbers(f,i++); }, 630);
      } else if(!this.cheque) {
        if(this.sql.more_verbose)console.log('Error Cheque data.');
        if(i<4)setTimeout( () => { this.render_numbers(f,i++); }, 630);
      } else if(!this.cheque.identifier) {
        if(this.sql.more_verbose)console.log('No Cheque Identifier.',this.cheque);
        if(i<4)setTimeout( () => { this.render_numbers(f,i++); }, 630);
      } else {
        var w = 2000; var h = 100;
        if(this.sql.verbose)console.log('Rendering MICR Numbers');
        if(html2canvas) html2canvas(this.micr_number_ref.nativeElement,
          {
            logging: this.sql.more_verbose,
            imageTimeout: 555,
            width: w,
            height: h,
            windowWidth: w,
            windowHeight: h,
            backgroundColor: '#FFFFFF',
            scale: 1
        }).then((MIRCRenderSuccess)=>{
          if(this.sql.save_in_file_format=='png')  this.micr_dataimg = MIRCRenderSuccess.toDataURL('image/png');
          if(this.sql.save_in_file_format=='jpeg'||this.sql.save_in_file_format=='jpg') this.micr_dataimg=MIRCRenderSuccess.toDataURL('image/jpeg',1);
          if(this.micr_dataimg){
            if(this.sql.more_verbose){
              console.log('Rendered MICR Now in Format',this.sql.save_in_file_format,this.micr_dataimg);
              console.log('Cheque Identifier Image conversion done.');
            }
            this.save_micr(this.micr_dataimg,(r)=>{
              this._rendered_numbers=true;
              this.changeDetectorRef.detectChanges();
              if(this.template&&this.template.indexOf('DocuGard')>=0) this.docugard.emit(1);
            });
          }
        });
      }
    }
  }
  center_view(i=0) {
    if(!this.previewing)return;
    if((!this.print_container||!this.print_container.nativeElement)||(!this.page||!this.page.nativeElement)||this.no_load){
      if(i<10)setTimeout(()=>{this.center_view(i+1)},500);return;
    }
    if(this.scale==0)this.scale=0.45;
    var elem_width = this.page.nativeElement.offsetWidth;
    var elem_height = this.page.nativeElement.offsetHeight; 
    var page_height = this.preview.nativeElement.offsetHeight; 
    if(this.scale!=1){
      this.print_container.nativeElement.scrollTop =Number((elem_height-(elem_height*this.scale))/2+10);
      this.print_container.nativeElement.scrollLeft=Number((elem_width-(elem_width*this.scale))/2+10);
      this.page_opacity=1;
    } else {
      this.print_container.nativeElement.scrollTop =0;
      this.print_container.nativeElement.scrollLeft=0;
      this.page_opacity=1;
    }
    this._auto_centered=true;
    var i=0;
  }
  showingLoader:boolean;
  ionloading:any;
  async open(i=0,cb=null,l=0) {
    if(!this.showingLoader&&!this.sql.showingLogin){
      this.ionloading = await this.loadingCtrl.create({
          message:  this.sql.word.Loading__,
          duration: 8000,
      });
      this.ionloading.present();
      this.showingLoader=true;
    }
    if(l)return;
    if(this.print_container&&this.print_container.nativeElement){
      var w = this.print_container.nativeElement.offsetWidth;
      if(w>0){
        this.no_load=false;
        this.resize_screen();
        this.hammer_loader();
        this.paper_sizes();
        this.center_view();
        this.loaded=true;
        this.page_loaded=true;
        this.load_trigger.emit(w);
        if(this.showingLoader && !this.designer){
          this.ionloading.dismiss();
          this.showingLoader=false;
        }
        if(cb)cb();
      } else if(i<40) {
        this.load_timer=setTimeout(()=>{
          this.open(i+1,cb,l);
        },123);
      }
    } else {
      if(i<40) {
        this.load_timer=setTimeout(()=>{
          this.open(i+1,cb,l);
        },123);
      }
    }
  }
  resize_screen() {
    if( !this.designer && !this.platform.is('tablet') ) {
      if( this.screenOrientation.type == "any" ) this.scale=0.45;
      if( this.screenOrientation.type == "landscape" ) this.scale=0.9;
      if( this.screenOrientation.type == "landscape-primary" ) this.scale=0.9;
      if( this.screenOrientation.type == "landscape-secondary" ) this.scale=0.9;
      if( this.screenOrientation.type == "portrait" ) this.scale=0.45;
      if( this.screenOrientation.type == "portrait-primary" ) this.scale=0.45;
      if( this.screenOrientation.type == "portrait-secondary" ) this.scale=0.45;
      this.changeDetectorRef.detectChanges();
    }
  }
  hammer_loader() {  
    if(!this.previewing)return;
    this.hammer = new Hammer(this.page.nativeElement, {
      touchAction: "auto",
      domEvents: true
    });
    this.hammer.get('pinch').set({ enable : true });
    this.hammer.on('pinch', function (e) {
      if (e.scale/2 < this.MIN_SCALE) {
        this.scale = this.MIN_SCALE;
      } else if (e.scale/2 > this.MAX_SCALE) {
        this.scale = this.MAX_SCALE;
      } else {
        this.scale = Number(e.scale/2);
      }
    });
  }
  async capture_canvas() {
    if(this.canvas && this.canvas.nativeElement) {
      this.html_canvas = (this.sql.show_printed_credit?'<!-- '+this.sql.word.CHEQUE_PRINT_CREDIT+' -->':'')+AllSCSS+''+this.canvas.nativeElement.innerHTML;
      if(!this.emitted) {
        this.emitted=true;
        this.html.emit(this.html_canvas);
        this.cheque_html.emit({
          html:this.html_canvas,
          cheque: this.cheque,
          cheque_id: this.cheque.cheque_id,
        });
      }
      return this.html_canvas;
    }
  }
  async print_canvas(i=0) {
    if(this.canvas && this.canvas.nativeElement) {
      await this.capture_canvas();
      this.sql.Print(this.html_canvas, (PrintResult) => {
        if(PrintResult)this.sql.PrintSuccess();
        if(this.sql.verbose)console.log('Print Result:', PrintResult);
      },this.paper,this.cheque.bank_account_holder);
    } else {
      this.sql.err('No HTML / Canvas Elements Were Found! This could be a routing error, try printing from another page. This could be a database error, is your DB full or overloade?')
    } 
  }
  print_image() {
    if(!this.dataimg) this.render();
    if(this.dataimg) {
      if(this.sql.verbose) console.log('print_image()','this.dataimg');
      this.sql.Print(this.dataimg, (PrintResult) => {
        if(PrintResult)this.sql.PrintSuccess();
        if(this.sql.verbose) console.log('Print Result:', PrintResult);
      },this.paper,this.cheque.bank_account_holder);
    } else {
      this.sql.err('No Image Data Was Found! This could be a render error, try reloading page. This could be a database error, Try another way to load Cheque page.')
    }
  }
  async print_now() {
    if(!this.html_canvas)await this.capture_canvas();
    if(this.html_canvas){
      this.sql.Print(this.html_canvas);
    } else if(this.dataimg){
      this.sql.Print(this.dataimg);
    } else if (this.cheque.cheque_id){
      this.sql.print_cheque(this.cheque.cheque_id);
    }
  }
  save_cheque_image(ChequeImageData) {
    if(!this.allow_saving)return false;
    if(!ChequeImageData||!this.cheque.cheque_id)
      this.sql.err('Failure Saving Image.');
    if(ChequeImageData && this.cheque.cheque_id){
      this.cheque.image = ChequeImageData;
      if(this.sql.verbose)console.log('Saving Cheque Image',this.cheque.cheque_id);
      this.sql.set('Cheques','cheque_id',this.cheque.cheque_id,'image',this.cheque.image,(ChequeImageSaved)=>{
        if (ChequeImageSaved) {
          if(this.sql.verbose) console.log('Cheque Image Data Saved to Database', this.cheque.cheque_id, ChequeImageSaved);
          this.filesave_image((FilePath) => {
            this.cheque.image_path = FilePath;
            if(this.sql.verbose)console.log('Cheque Image File Saved to System', FilePath);
          });
        }
      });
    }
  }
  save_address_image(AddressImageData) {
    if(!this.allow_saving)return false;
    if(!AddressImageData||!this.address.address_id)
      this.sql.err('Failure Saving Image.');
    if(AddressImageData && this.address.address_id){
      this.address.image = AddressImageData;
      if(this.sql.verbose)console.log('Saving Address Image',this.address.address_id);
      this.sql.set('Addresses','address_id',this.address.address_id,'image',this.address.image,(AddressImageSaved)=>{
        if (AddressImageSaved) {
          if(this.sql.verbose)console.log('Address Image Data Saved to Database', this.address.address_id, AddressImageSaved);
          this.filesave_image((FilePath) => {
            this.address.image_path = FilePath;
            if(this.sql.verbose)console.log('Address Image File Saved to System', FilePath);
          });
        }
      });
    }
  }
  save_html(ChequeHTMLData,b?) {
    this.cheque.html = ChequeHTMLData;
    if(this.sql.verbose)console.log('save_html');
    console.log('SAVING HTML IS TOO HEAVY FOR THIS DEVICE');
    return false;
    if(!ChequeHTMLData||!this.cheque.cheque_id&&this.sql.verbose)this.sql.err('Failure Saving HTML Data.');
    if(ChequeHTMLData && this.cheque.cheque_id){
      if(this.sql.verbose)console.log('Saving Cheque HTML',this.cheque.cheque_id);
      this.sql.set('Cheques','cheque_id',this.cheque.cheque_id,'html',btoa(this.cheque.html),(ChequeHTMLSaved)=>{
        if (ChequeHTMLSaved) {
          if(this.sql.verbose)console.log('Cheque HTML Data Saved to Database', this.cheque.cheque_id, ChequeHTMLSaved);
          if(b)b(ChequeHTMLSaved);
        }
      });
    }
  }
  save_micr(MICRData,b?) {
    this.cheque.micr_image = MICRData;
    if(this.sql.verbose)console.log('save_micr');
    if(!MICRData||!this.cheque.cheque_id&&this.sql.verbose)this.sql.err('Failure Saving HTML Data.');
    if(MICRData && this.cheque.cheque_id){
      if(this.sql.verbose)console.log('Saving Cheque MICR Data Img',this.cheque.cheque_id);
      this.sql.set('Cheques','cheque_id',this.cheque.cheque_id,'micr_image',this.cheque.micr_image,(ChequeMICRSaved)=>{
        if (ChequeMICRSaved) {
          if(this.sql.verbose)console.log('Cheque MICR Data Img Saved to Database', this.cheque.cheque_id, ChequeMICRSaved);
          if(b)b(ChequeMICRSaved);
        }
      });
    }
  }
  filesave_image(b?) {
    this.sql.save_cheque_image(this.cheque.image,this.sql.word.Cheque+this.cheque.cheque_id,(filepath)=>{ 
      this.cheque.image_path = filepath;
      this.sql.set('Cheques','cheque_id',this.cheque.cheque_id,'image_path',this.cheque.image_path,(cid)=>{
        if (!cid) {
          if(b)b(false);
          return false;
        }
        if(this.sql.verbose) {
          console.log('Saved Cheque',this.cheque.cheque_id,'Image Path',this.cheque.image_path);
          console.log('Completed Filesave Image:', filepath);  
        }
        if(b)b(filepath);
        return filepath;
      });
    });
  }
  filesave_address(b?) {
    this.sql.save_address_image(this.address.image,this.sql.word.Address+this.address.address_id,(filepath)=>{ 
      this.address.image_path = filepath;
      this.sql.set('Addresses','address_id',this.address.address_id,'image_path',this.address.image_path,(cid)=>{
        if (!cid) {
          if(b)b(false);
          return false;
        }
        if(this.sql.verbose) {
          console.log('Saved Address',this.address.address_id,'Image Path',this.address.image_path);
          console.log('Completed Filesave Image:', filepath);  
        }
        if(b)b(filepath);
        return filepath;
      });
    });
  }
  paper_sizes() {
    if(this.page_size) {
      for( let i = 0; i < PaperSizes.length; i++ ) {
        if( PaperSizes[i].name == this.page_size ) {
          if(this.subtract_margins) {
            var h = Number(Number(this.preview_top_page_padding)+Number(this.preview_bottom_page_padding));
            var w = Number(Number(this.preview_right_page_padding)+Number(this.preview_left_page_padding));
            this.printer_page_width = Number(Number(PaperSizes[i].width.split('mm')[0])-w)+'mm';
            this.printer_page_height = Number(Number(PaperSizes[i].height.split('mm')[0])-h)+'mm';
          } else {
            this.printer_page_width = PaperSizes[i].width;
            this.printer_page_height = PaperSizes[i].height;
          }
          this.printer_page_size = PaperSizes[i].name;
          this.paper=i;
        }
      }
    }
  }
  ngOnChanges(item?) {
    if(!this.designer) {
      // if(item.cheque)this.render_numbers();  
      if(this._p=='canvas')this.print_canvas();
      if(this._p=='image')this.print_image();
      if(this._p=='now')this.print_now();
      if(this._p=='regen'||this._p=='refresh'){
        this.changeDetectorRef.detectChanges();
        this.render();
      }
      if(this._p!=''){this._p='';}
      if(item.destroying)if(this.destroying){
        this.page_opacity=0;this.ngOnDestroy();
      }
    }
    if(item.destroying){
      if(this.destroying){
        this.page_opacity=0;
      } else {
        this.page_opacity=1;
      }
    }
    if(item.centering_view)if(this.centering_view)this.center_view();
    if(item.scale||item.design)this.center_view();
    if(item.page_size)this.paper_sizes();
    if(item.ImageMICR&&this.RenderMICR)this.render_numbers();
    // if(item.RenderMICR&&this.RenderMICR)this.render_numbers();
    if(item.ImageMICR||item.voidcheck)this.changeDetectorRef.detectChanges();
    if(item.language){
      if(this.sql.model=='web') {
        this.sql.load_web_language(this.language,(str)=>{this.translations=str;});
      } else {
        this.translations=this.language?Languages[this.language]:this.sql.word;
      }
    }
  }
  ngAfterViewInit() {
    if(this.platform.is("android"))this.android=true;
    if(this.sql.model!='web'){
      this.open(0,()=>{
        this.page_loaded=true;
      });
    } else {
      this.open(0,null,1)
    }
    this._int_3=setTimeout(()=>{
      if(!this._auto_centered)this.center_view();
      if(this.RenderMICR){
        if(!this._rendered_numbers && !this.address) {
          this.render_numbers();
        }
      }
    },1000);
    // this._int_2=setTimeout(()=>{
    //   this.center_view();
    // },100);
  }
  ngOnDestroy() {
    this.page_opacity=0;
    this.RenderMICR=false;
    this.ImageMICR=false;
    this.layers=false;
    this.gradients=false;
    this.numbers=false;
    this.date=false;
    this.micr=false;
    this.voidcheck=false;
    this.design=false;
    this.designer=false;
    this.monochrome=false;
    this.borders=false;
    this.color=false;
    this.font=false;
    this.fold_line=false;
    this.bold=false;
    this.italic=false;
    this.micr_top=false;
    this.micr_right=false;
    this.micr_bottom=false;
    this.micr_left=false;
    this.page_top='';
    this.page_right='';
    this.page_bottom='';
    this.page_left='';
    this.table_top='';
    this.table_right='';
    this.table_bottom='';
    this.table_left='';
    this.address_top='';
    this.address_right='';
    this.address_bottom='';
    this.address_left='';
    this.secret='';
    this.done=false;
    this._rendered_numbers=false;
    this._auto_centered=false;
    this.rendering=false;
    this.loaded=false;
    this.dataimg=null;
    this.template='';
    this.address=null;
    this.document=null;
    this.html_canvas='';
    this.design=null;
    this.cheque= new NewCheque();
    
    // clearInterval(this._int_1);
    // clearTimeout(this._int_2);
    clearTimeout(this.load_timer);
    clearTimeout(this._int_3);
  }  
}