import { environment } from '../../../../environments/environment';
import { SQLService } from '../../../services/sql.service';
import { NewDesignObjects } from '../../../models/designobjects';
import { NewCheque } from '../../../models/cheque';
import { NewDesign } from '../../../models/design';
import { SelectChequeDesignsPage } from '../select-design/select-design.page';
import { SelectPaperPage } from '../paper-picker/paper-picker.component';
import { Location } from '@angular/common';
import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LoadingController,ModalController, NavParams } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { Platform } from '@ionic/angular';
import { opacity } from '../../../app.animations';
@Component({
  selector: 'ng-cheques-designer',
  animations:[opacity],
  templateUrl: './cheque-designer.component.html',
  styleUrls: ['./cheque-designer.component.styl']
})
export class ChequeDesignerComponent implements OnInit {
  constructor(
    public sql: SQLService,
    private router: Router,
    private params: NavParams,
    private loadingController: LoadingController,
    private platform:Platform,
    private navCtrl: ModalController,
    private location: Location,
    private route: ActivatedRoute, 
  ) {
    this.cheque_id = params.get('cheque_id');
    this.design_id = params.get('design_id');
    this.design_pattern = params.get('design_pattern');
    this.is_tablet=this.platform.is('tablet');
  }
  _c = -1;
  img = '';
  currency = null;
  title:string;
  borders = true;
  fold_line:boolean;
  show_notice=false;
  preview_notice:string;
  fontsize = 0;
  bold: boolean;
  italic: boolean;
  pro: boolean;
  docugard: boolean;
  cheque_background_image_repeat=false;
  background_position='';
  cheque_background_image='';
  cheque_logo_image='';
  cheque_header_image='';
  cheque_bank_image='';
  micr_top:string;
  micr_right:string;
  micr_bottom:string;
  micr_left:string;
  page_top:string;
  page_right:string;
  page_bottom:string;
  page_left:string;
  table_top:string;
  table_right:string;
  table_bottom:string;
  table_left:string;
  address_top:string;
  address_right:string;
  address_bottom:string;
  address_left:string;
  micr = true;
  numbers = true;
  date = true;
  voidcheck = false;
  cheque_text_color:any;
  rotate: boolean;
  gradients: boolean = true;
  monochrome: boolean;
  printing = ''; 
  secret = ''; 
  RenderMICR = false; 
  ImageMICR = true; 
  layers = true; 
  is_tablet = false; 
  slug = 'A4';
  page_size = 'A4';
  page_width = '210mm';
  page_height = '297mm';
  cheque = new NewCheque();
  new_design = new NewDesign();
  @Input('design') design = new NewDesign();
  designobjects = new NewDesignObjects();
  currencies = [];
  @Input('scale') scale = 1;
  @Input('cheque_id') cheque_id: any;
  @Input('design_id') design_id: any;
  @Input('design_pattern') design_pattern: string;
  slugify = str =>
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');
  save() {
    if(this.design.design_id) this.design_id=this.design.design_id;
    this.design.design_id=this.design_id;
    this.design.design_pattern=this.design_pattern;
    this.design.template=this.design_pattern;
    this.design.title=this.design.title;
    this.design.font=this.design.font;
    this.design.fold_line=this.fold_line?1:0;
    this.design.fontsize=this.fontsize?this.fontsize:0;
    this.design.bold=this.bold?1:0;
    this.design.italic=this.italic?1:0;
    this.design.micr_top = this.micr_top;
    this.design.micr_right = this.micr_right;
    this.design.micr_bottom = this.micr_bottom;
    this.design.micr_left = this.micr_left;
    this.design.cheque_background_image_repeat=this.cheque_background_image_repeat?1:0;
    this.design.background_position=this.background_position;
    this.design.cheque_background_image=this.cheque_background_image;
    this.design.cheque_logo_image=this.cheque_logo_image;
    this.design.cheque_header_image=this.cheque_header_image;
    this.design.cheque_bank_image=this.cheque_bank_image;
    this.design.page_top=this.page_top;
    this.design.page_right=this.page_right;
    this.design.page_bottom=this.page_bottom;
    this.design.page_left=this.page_left;
    this.design.table_top=this.table_top;
    this.design.table_right=this.table_right;
    this.design.table_bottom=this.table_bottom;
    this.design.table_left=this.table_left;
    this.design.address_top=this.address_top;
    this.design.address_right=this.address_right;
    this.design.address_bottom=this.address_bottom;
    this.design.address_left=this.address_left;
    this.design.scale=this.scale;
    this.design.borders=this.borders?1:0;
    this.design.numbers=this.numbers?1:0;
    this.design.voidcheck=this.voidcheck?1:0;
    this.design.micr=this.micr?1:0;
    this.design.date=this.date;
    this.design.rotate=this.rotate;
    this.design.RenderMICR=this.RenderMICR?1:0;
    this.design.ImageMICR=this.ImageMICR?1:0;
    this.design.layers=this.layers?1:0;
    this.design.printing=this.printing;
    this.design.gradients=this.gradients;
    this.design.page_size=this.page_size;
    this.design.page_width=this.page_width;
    this.design.page_height=this.page_height;
    this.design.monochrome=this.design.monochrome;
    this.design.cheque_text_color=this.cheque_text_color;
    this.design.doc_color=this.design.background_colour;
    this.design.color=this.design.background_colour;
    this.design.secret=this.secret;
    if(this.design.design_id&&this.design_id!=[]){
        this.sql.updateObj("Designs",this.design,(resp)=>{
        if(resp){
          if(this.cheque_id) {
            this.sql.set('Cheques','cheque_id',this.cheque_id,'design',this.design.title,()=>{
              this.sql.set('Cheques','cheque_id',this.cheque_id,'design_id',this.design.design_id,()=>{
                this.sql.set('Cheques','cheque_id',this.cheque_id,'design_pattern',this.design.design_pattern,()=>{
                  this.sql.set('Cheques','cheque_id',this.cheque_id,'design_background_colour',this.design.background_colour,()=>{
                    var path =['folder','Print','Cheque',this.cheque_id,'Design',this.design.design_id];
                    this.router.navigate(path);
                  });
                });
              });
            });
          }else{
            this.router.navigate(['folder','ChequeDesigns']);
          }
        }
      },true);
    } else {
      console.error('No Design ID specified.');
    }
  }
  change_notice() {
    this.show_notice=true;
    setTimeout( () => {this.show_notice=false;},1500);
  }
  async print() {
    this.printing = 'refresh';
    const loading = await this.loadingController.create({
      message: this.sql.word.Loading,
      duration: 3000
    });
    await loading.present();
    this.sql.hapticsImpactLight();
    setTimeout(()=>{
      this.printing = 'regen';
      this.sql.hapticsImpactHeavy();
    },1000);
    setTimeout(()=>{
      this.printing = 'now';
      this.sql.hapticsImpactHeavy();
    },3000);
    setTimeout(()=>{this.printing='';},3200);
  }
  print_now() {
    this.printing = 'now';
    setTimeout(()=>{this.printing='';},200);
  }
  zoom() {
    if(this.scale==0.45){
      this.scale=1;
    }else{
      this.scale=0.45;
    }
  }
  delete(c,i?) {
    if(confirm(this.sql.word.Design_Delete_Confirmation)) {
      this.navCtrl.dismiss();
    } else { i.closeSlidingItems(); }
  }
  // reset_default(cba?) {
  //   this.sql.set_all("Designs","default_design",false,(d)=>{
  //     if(d)if(cba)cba(d);
  //   });
  // }
  get_default_design() {
    this.sql.get('Designs',(e)=>{
      for(let d of e)if(d.default_design=='true')this.design=d;
      if(!this.design['show_micr'])this.design['show_micr'] = true;
      if(!this.design['show_cheque_number'])this.design['show_cheque_number'] = true;
      if(!this.design['show_cheque_date'])this.design['show_cheque_date'] = true;
    });
  }
  set_color(e) {
    if(this.sql.more_verbose)console.log('Color selected',e);
    this.design.background_colour=e;
    this.design.doc_color=e;
    this.design.color=e;
    this.design.monochrome=false;
  }
  set_cheque_text_color(e) { 
    if(this.sql.more_verbose)console.log('Text color selected',e);
    this.cheque_text_color=e;
  }
  set_borders(e) {
    this.borders=e;
    /*
    this.preview_notice=this.sql.word.Borders;
    this.change_notice();
    */
  }
  set_fold_line(e) {
    this.fold_line=e;
    /*
    this.preview_notice=this.sql.word.Guides;
    this.change_notice();
    */
  }
  set_micr(e) {
    this.micr=e;
    /*
    this.preview_notice=this.sql.word.MICR;
    this.change_notice();
    */
  }
  set_numbers(e) {
    this.numbers=e;
    /*
    this.preview_notice=this.sql.word.Numbers;
    this.change_notice();
    */
  }
  set_date(e) {
    this.date=e;
    /*
    this.preview_notice=this.sql.word.Date;
    this.change_notice();
    */
  }
  set_layers(e) {
    this.layers=e;
    /*
    this.preview_notice=this.sql.word.Hide_Layer;
    this.change_notice();
    */
  }
  set_slug(e) {
    this.slug=e;
    /*
    this.preview_notice=this.sql.word.Paper_Size+': '+e;
    this.change_notice();
    */
  }
  set_rotate(e) {
    this.rotate=e;
    /*
    this.preview_notice=this.sql.word.Hide_Layer;
    this.change_notice();
    */
  }
  set_gradients(e) {
    this.gradients=e; 
    /*
    this.preview_notice=this.sql.word.Gradients;
    this.change_notice();
    */
  }
  set_page_size(e) {
    this.page_size=e; 
    /*
    this.preview_notice=this.sql.word.Paper_Size+': '+e;
    this.change_notice();
    */
  }
  set_page_width(e) {
    this.page_width=e; 
    /*
    this.preview_notice=this.sql.word.Paper_Width+': '+e;
    this.change_notice();
    */
  }
  set_page_height(e) {
    this.page_height=e; 
    /*
    this.preview_notice=this.sql.word.Paper_Height+': '+e;
    this.change_notice();
    */
  }
  set_voidcheck(e) {
    this.voidcheck=e; 
    /*
    this.preview_notice=this.sql.word.Void_Cheque;
    this.change_notice();
    */
  }
  set_RenderMICR(e) {
    this.RenderMICR=e;
    /*
    this.preview_notice=this.sql.word.MICR;
    this.change_notice();
    */
  }
  set_ImageMICR(e) {
    this.ImageMICR=e;
    /*
    this.preview_notice=this.sql.word.MICR;
    this.change_notice();
    */
  }
  set_Secret(e) {
    this.secret=e;
    /*
    this.preview_notice=this.sql.word.Secret;
    this.change_notice();
    */
  }
  set_fontsize(e) {
    this.fontsize=e; 
    /*
    this.preview_notice=this.sql.word.Fonts+': '+e;
    this.change_notice();
    */
  }
  set_fontweight(e) {
    this.bold=e; 
    /*
    this.preview_notice=this.sql.word.Bold;
    this.change_notice();
    */
  }
  set_background_position(e) { this.background_position=e;/*this.preview_notice=this.sql.word.Background; this.change_notice(); */}
  set_cheque_background_image_repeat(e) { 
    /*
      this.preview_notice=this.sql.word.Background;
      this.change_notice()
    */
    this.cheque_background_image_repeat=e; 
  }
  set_cheque_background_image(e) {
    /*
      this.preview_notice=this.sql.word.Background;
      this.change_notice()
    */
    this.cheque_background_image=e; 
    this.design.monochrome=false; 
    this.monochrome=false;
  }
  set_cheque_logo_image(e) {
    this.cheque_logo_image=e;
    /*
      this.preview_notice=this.sql.word.Logo_Image; 
      this.change_notice();
    */
  }
  set_cheque_header_image(e) {
    this.cheque_header_image=e;
    /*
      this.preview_notice=this.sql.word.Header_Image; 
      this.change_notice();
    */
  }
  set_cheque_bank_image(e) {
    this.cheque_bank_image=e;
    /*
      this.preview_notice=this.sql.word.Bank_Image; this.
      change_notice(); 
    */
  }
  set_fontitalic(e) { 
    this.italic=e;
    /*
      this.preview_notice=this.sql.word.Italic; this.
      change_notice(); 
    */
  }
  set_micr_top(e) { 
    this.micr_top=e;
    if(e!='auto') {
      this.preview_notice=this.sql.word.MICR+' '+this.sql.word.Top_Margin+': '+e; 
      this.change_notice();
    }
  }
  set_micr_right(e) { 
    this.micr_right=e;
    if(e!='auto') {
      this.preview_notice=this.sql.word.MICR+' '+this.sql.word.Right_Margin+': '+e; 
      this.change_notice();
    }
  }
  set_micr_bottom(e) { 
    this.micr_bottom=e;
    if(e!='auto') {
      this.preview_notice=this.sql.word.MICR+' '+this.sql.word.Bottom_Margin+': '+e; 
      this.change_notice();
    }
  }
  set_micr_left(e) { 
    this.micr_left=e;
    if(e!='auto') {
      this.preview_notice=this.sql.word.MICR+' '+this.sql.word.Left_Margin+': '+e; 
      this.change_notice();
    }
  }
  set_page_top(e) { 
    this.page_top=e;
    if(e!='auto') {
      this.preview_notice=this.sql.word.Top_Margin+': '+e; 
      this.change_notice();
    }
  }
  set_page_right(e) { 
    this.page_right=e;
    if(e!='auto') {
      this.preview_notice=this.sql.word.Right_Margin+': '+e; 
      this.change_notice();
    }
  }
  set_page_bottom(e) { 
    this.page_bottom=e;
    if(e!='auto') {
      this.preview_notice=this.sql.word.Bottom_Margin+': '+e; 
      this.change_notice();
    }
  }
  set_page_left(e) { 
    this.page_left=e;
    if(e!='auto') {
      this.preview_notice=this.sql.word.Left_Margin+': '+e; 
      this.change_notice();
    }
  }
  set_table_top(e) { 
    this.table_top=e;
    if(e!='auto') {
      this.preview_notice=this.sql.word.Table+' '+this.sql.word.Top_Margin+': '+e; 
      this.change_notice();
    }
  }
  set_table_right(e) { 
    this.table_right=e;
    if(e!='auto') {
      this.preview_notice=this.sql.word.Table+' '+this.sql.word.Right_Margin+': '+e; 
      this.change_notice();
    }
  }
  set_table_bottom(e) { 
    this.table_bottom=e;
    if(e!='auto') {
      this.preview_notice=this.sql.word.Table+' '+this.sql.word.Bottom_Margin+': '+e; 
      this.change_notice();
    }
  }
  set_table_left(e) { 
    this.table_left=e;
    if(e!='auto') {
      this.preview_notice=this.sql.word.Table+' '+this.sql.word.Left_Margin+': '+e; 
      this.change_notice();
    }
  }
  set_address_top(e) { 
    this.address_top=e;
    if(e!='auto') {
      this.preview_notice=this.sql.word.Address+' '+this.sql.word.Top_Margin+': '+e; 
      this.change_notice();
    }
  }
  set_address_right(e) { 
    this.address_right=e;
    if(e!='auto') {
      this.preview_notice=this.sql.word.Address+' '+this.sql.word.Right_Margin+': '+e; 
      this.change_notice();
    }
  }
  set_address_bottom(e) { 
    this.address_bottom=e;
    if(e!='auto') {
      this.preview_notice=this.sql.word.Address+' '+this.sql.word.Bottom_Margin+': '+e; 
      this.change_notice();
    }
  }
  set_address_left(e) { 
    this.address_left=e;
    if(e!='auto') {
      this.preview_notice=this.sql.word.Address+' '+this.sql.word.Left_Margin+': '+e; 
      this.change_notice();
    }
  }
  designer_canvas($event) {
    if(!$event)return;
    console.log($event);
  }
  font(font) {
    if(!font)return;
    this.design.font = font;
  }
  end() {
    this.location.back();
  }
  get_cheque_design() {
    var ida = this.route.snapshot.paramMap.get('cheque_id');
    if(!ida) return;
    if(ida=='New'){this.select_design();} else {
    this.cheque_id=ida;
    this.sql.view('Cheques','cheque_id',this.cheque_id,(e)=>{if(e){
      var f=Object.entries(e[0]);var n=new NewCheque();
      for(var t of f)n[t[0]]=t[1]; 
        this.cheque = n;
        this.design_pattern=this.cheque.design_pattern;
        this.design.design_pattern=this.cheque.design_pattern;
        this.sql.view('Designs','design_pattern','"'+this.design_pattern+'"',(e)=>{if(e){
          var f=Object.entries(e[0]);var o=new NewDesign();
          for(var t of f)o[t[0]]=t[1];
          this.design = o;
          this.design_id=this.design.design_id;
          this.make_design(o);
        }});
    }});}
  }
  set_design(designid) {
    if(designid){
      this.sql.view('Designs','design_id',designid,(e)=>{if(e){
        var f=Object.entries(e[0]);var o=new NewDesign();
        for(var t of f)o[t[0]]=t[1];
        this.design = o;
        this.make_design(this.design);
        this.design_pattern=this.design.design_pattern;
        this.design_id=this.design.design_id;
        this.docugard=this.design.docugard;
        this.pro=this.design.pro;
      }});
    }
  }
  make_design(design){
    if(design&&design.design_pattern)this.design_pattern = design.design_pattern;
    if(design&&design.font)this.design.font = design.font;
    if(design&&design.fold_line)this.fold_line = design.fold_line;
    if(design&&design.fontsize)this.fontsize = design.fontsize;
    if(design&&design.bold)this.bold = design.bold;
    if(design&&design.micr_top)this.micr_top = design.micr_top;
    if(design&&design.micr_right)this.micr_right = design.micr_right;
    if(design&&design.micr_bottom)this.micr_bottom = design.micr_bottom;
    if(design&&design.micr_left)this.micr_left = design.micr_left;
    if(design&&design.page_top)this.page_top = design.page_top;
    if(design&&design.page_right)this.page_right = design.page_right;
    if(design&&design.page_bottom)this.page_bottom = design.page_bottom;
    if(design&&design.page_left)this.page_left = design.page_left;
    if(design&&design.table_top)this.table_top = design.table_top;
    if(design&&design.table_right)this.table_right = design.table_right;
    if(design&&design.table_bottom)this.table_bottom = design.table_bottom;
    if(design&&design.table_left)this.table_left = design.table_left;
    if(design&&design.address_top)this.address_top = design.address_top;
    if(design&&design.address_right)this.address_right = design.address_right;
    if(design&&design.address_bottom)this.address_bottom = design.address_bottom;
    if(design&&design.address_left)this.address_left = design.address_left;
    if(design&&design.background_position)this.background_position = design.background_position;
    if(design&&design.cheque_background_image_repeat)this.cheque_background_image_repeat = design.cheque_background_image_repeat;
    if(design&&design.cheque_background_image)this.cheque_background_image = design.cheque_background_image;
    if(design&&design.cheque_logo_image)this.cheque_logo_image = design.cheque_logo_image;
    if(design&&design.cheque_header_image)this.cheque_header_image = design.cheque_header_image;
    if(design&&design.cheque_bank_image)this.cheque_bank_image = design.cheque_bank_image;
    if(design&&design.italic)this.italic = design.italic;
    if(design&&design.secret)this.secret = design.secret;
    if(design&&design.scale)this.scale = design.scale;
    if(design&&design.borders)this.borders = design.borders;
    if(design&&design.numbers)this.numbers = design.numbers;
    this.voidcheck = design.voidcheck=='1'||design.voidcheck==1?true:false;
    if(design&&design.micr)this.micr = design.micr;
    if(design&&design.date)this.date = design.date;
    if(design&&design.rotate)this.rotate = design.rotate;
    if(design&&design.RenderMICR)this.RenderMICR = design.RenderMICR;
    if(design&&design.ImageMICR)this.ImageMICR = design.ImageMICR;
    if(design&&design.layers)this.layers = design.layers;
    if(design&&design.printing)this.printing = design.printing;
    if(design&&design.gradients)this.gradients = design.gradients;
    if(design&&design.page_size)this.page_size = design.page_size;
    if(design&&design.page_width)this.page_width = design.page_width;
    if(design&&design.page_height)this.page_height = design.page_height;
    if(design&&design.monochrome)this.design.monochrome = design.monochrome;
    if(design&&design.cheque_text_color)this.cheque_text_color = design.cheque_text_color;
    if(design&&design.background_colour)this.design.color = design.color;
    if(design&&design.background_colour)this.design.background_colour = design.background_colour;
  }
  set_monochrome(e){
    this.design.monochrome=true;
  }
  get_design() {
    var ida = this.route.snapshot.paramMap.get('design_id');
    if(ida) this.design_id=ida;
    if(this.design_id) ida=this.design_id;
    if(!ida) return;
    this.sql.view('Designs','design_id',ida,(e)=>{
      var f=Object.entries(e[0]);var o=new NewDesign();
      for(var t of f)o[t[0]]=t[1];
      this.design = o;
      this.design_pattern=o.design_pattern;
      this.design_id = ida;
      this.make_design(o);
      // this.sql.view('DesignObjects','design_pattern',this.design.design_pattern,(e)=>{
      //   var f=Object.entries(e[0]);var p=new NewDesignObjects();
      //   for(var t of f)p[t[0]]=t[1];
      //   this.designobjects = p;
      // });
    });
  }
  get_design_pattern() {
    var ida = this.route.snapshot.paramMap.get('design_pattern');
    if(!ida) var ida = this.design_pattern;
    if(!ida) return;
    this.sql.view('Designs','design_pattern',ida,(e)=>{
      var f=Object.entries(e[0]);var o=new NewDesign();
      for(var t of f)o[t[0]]=t[1];
      this.design = o;
      this.make_design(o);
      // this.sql.view('DesignObjects','design_pattern',this.design.design_pattern,(e)=>{
      //   var f=Object.entries(e[0]);var p=new NewDesignObjects();
      //   for(var t of f)p[t[0]]=t[1];
      //   this.designobjects = p;
      // });
    });
  }
  async select_design() {
    const m = await this.navCtrl.create({
      component: SelectChequeDesignsPage,
      componentProps: {
        design_pattern:this.design.design_pattern
      }
    });
    await m.present();
    let a = await m.onDidDismiss();
    if (a.data) this.set_design(a.data);
  }
  ngOnInit() {
    this.currencies = Object.keys(environment.currencies);
    // this.presentLoading();
    this.get_design_pattern();
    this.get_cheque_design();
    this.get_design();
  }
  async presentLoading() {
    const loading = await this.loadingController.create({
      cssClass: 'designer-loading',
      message: this.sql.word.Loading,
      duration: 821
    });
    await loading.present();
    const { role, data } = await loading.onDidDismiss();
  }
}