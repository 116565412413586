import { SelectChequeDesignsPage } from '../designs/select-design/select-design.page';
import { SelectEmployeePage } from '../../employees/select-employee/select-employee.page';
import { SelectBankPage } from '../../settings/bank-accounts/select-bank/select-bank.page';
import { PrintAddressComponent } from '../../addresses/print-address/print-address.component';
import { SelectAddressPage } from '../../addresses/select-address/select-address.page';
import { ChequeEntriesComponent } from '../cheque-entries/cheque-entries.component';
import { SelectPayeePage } from '../../payees/select-payee/select-payee.page';
import { SignatureComponent } from '../../settings/signature-pad/signature-pad.component';
import { ViewSignatureComponent } from '../../settings/view-signature/view-signature.component';
import { CoinService } from '../../services/coin.service';
import { environment } from '../../../environments/environment';
import { Currencies } from '../../../environments/currencies';
import { NewDesign } from '../../models/design';
import { NewCheque } from '../../models/cheque';
import { NewChequeEntry } from '../../models/chequeentries';
import { SQLService } from '../../services/sql.service';
import { Component, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { PurchaseComponent } from '../../dashboard/purchase/purchase.component';
import { CalendarModal, CalendarModalOptions, DayConfig, CalendarResult } from 'ion2-calendar';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import * as converter from 'number-to-words';
@Component({
  selector: 'ng-cheques-new-cheque',
  templateUrl: './new-cheque.component.html',
  styleUrls: ['./new-cheque.component.styl']
})
export class NewChequePage implements AfterViewInit {
  constructor(
    public sql: SQLService,
    private route: ActivatedRoute,
    private coin: CoinService,
    private router: Router,
    private location: Location,
    public modalController: ModalController
  ) {
    // this.router.events.subscribe((eve) => {
    //   if(eve instanceof NavigationEnd)this.open();
    // });
  }
  _c=0;
  banks = [];
  cheques = [];
  employees = [];
  payees = [];
  designs = [];
  printing = false;
  bank_description = null; 
  cheque = new NewCheque();
  currencies = environment.currencies;
  Currencies = Object.keys(environment.currencies);
  Currency = {};
  selected_password: boolean;
  selected_currency: string;
  decimal_places = '0.01';
  count_cheque_entries=-1;
  async add_employee() {
    this.add_temp();
    const m = await this.modalController.create({component: SelectEmployeePage, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1){
        this.delete_employee();
        this.sql.hapticsImpactLight();
      } else {
        this._c=1;
        var employeeid = a.data;
        if(employeeid){
          this.delete_payee();
          this.sql.view('Employees','employee_id',employeeid,(a)=>{
            if(a){
              this.select_employee(a[0]);
            }
          });
        }
        this.sql.hapticsImpactMedium();
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_employee(employee) {
    this.router.navigate(['folder','Employee', employee.employee_id]); 
  }
  delete_employee() {
    this._c=1;
    this.cheque.employee_id = -1;
    this.cheque.employees = -1;
    // this.sql.set( 'Cheques', 'cheque_id', this.cheque.cheque_id, 'employees', null);
  }
  async add_address() {
    this.add_temp();
    const m = await this.modalController.create({component: SelectAddressPage, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1){
        this.delete_address();
        this.sql.hapticsImpactLight();
      } else {
        this._c=1;
        var addressid = a.data;
        if(addressid){this.sql.view('Addresses','address_id',addressid,(a)=>{if(a)this.select_address(a[0]);});}
        this.sql.hapticsImpactMedium();
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_address(address) {
    this.router.navigate(['folder','Address', address.address_id]); 
  }
  delete_cheque() {
    if(!this.cheque.cheque_id){
      this.end();
    } else if(confirm(this.sql.word.Cheque_Delete_Confirmation)) {
      this.sql.delete('Cheques','cheque_id',this.cheque.cheque_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  delete_address() {
    this._c=1;
    this.cheque.address_id = -1;
    this.cheque.addresses = -1;
    this.cheque.address_name = ' ';
    this.cheque.address = ' ';
    this.cheque.address_2 = ' '; 
    this.cheque.city = ' ';
    this.cheque.province = ' ';
    this.cheque.country = ' ';
    this.cheque.postal = ' ';
    // this.sql.set( 'Cheques', 'cheque_id', this.cheque.cheque_id, 'addresses', null);
  }
  async add_bank() {
    this.add_temp();
    const m = await this.modalController.create({
      component: SelectBankPage, 
      componentProps: {
        cheque_id: this.cheque.cheque_id
      }
    });
    await m.present();
    let a = await m.onDidDismiss();
    if (a.data) {
      if(a.data==-1){
        this.delete_bank();
        this.sql.hapticsImpactLight();
      } else {
        this._c=1;
        var bankid = a.data;
        if(bankid){
          this.sql.view('BankAccounts','bank_id',bankid,(a)=>{
            if(a)this.select_bank(a[0]);
          });
        } else {
          this.sql.remove_temporary('page');
        }
        this.sql.hapticsImpactMedium();
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_bank(bank) {
    this.router.navigate(['folder','Account', bank.bank_id]); 
  }
  delete_bank() {
    this._c=1;
    this.cheque.banks = -1;
    this.cheque.signature = null;
    this.cheque.printed_id = null;
    this.cheque.bank_account_holder = null;
    this.cheque.bank_image = null;
    this.cheque.bank_name = null;
    this.cheque.bank_address = null;
    this.cheque.bank_address_2 = null;
    this.cheque.bank_city = null;
    this.cheque.bank_postal = null;
    this.cheque.bank_country = null;
    this.cheque.cheque_number = null;
    this.cheque.transit_number = null;
    this.cheque.institution_number = null;
    this.cheque.designation_number = null;
    this.cheque.account_number = null;
    this.bank_description = null;
    // this.sql.set( 'Cheques', 'cheque_id', this.cheque.cheque_id, 'banks', null);
  }
  async add_payee() {
    this.add_temp();
    const m = await this.modalController.create({component: SelectPayeePage, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1){
        this.delete_payee();
      } else {
        var payeeid = a.data;
        if(payeeid){
          this.delete_employee();
          this.sql.view('Payees','payee_id',payeeid,(a)=>{
            if(a){
              this.select_payee(a[0]);
            }
          });
        }
        this.sql.hapticsImpactMedium();  
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_payee(payee) {
    this.router.navigate(['folder','Payee', payee.address_id]); 
  }
  delete_payee() {
    this._c=1;
    this.cheque.payees = -1;
    this.cheque.payee_id = -1;
    this.cheque.payee_title = null;
    // this.sql.set( 'Cheques', 'cheque_id', this.cheque.cheque_id, 'payees', null);
  }
  async add_design() {
    this.add_temp();
      const m = await this.modalController.create({
        component: SelectChequeDesignsPage, 
        componentProps: { 
          design_pattern: this.cheque.design_id
        }
    });
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      if (a.data==-1 ) {
        this.delete_design();
      } else {
        var designid = a.data;
        if(designid){
          this.sql.view('Designs','design_id',designid,(a)=>{
            if(a)this.select_design(a[0]);
          });
        }
        this.sql.hapticsImpactMedium();
      } 
      this.sql.remove_temporary('page');
    }
  }
  edit_design(design) {
    this.router.navigate(['folder','Design', design.design_id]); 
  }
  delete_design() {
    this._c=1;
    this.cheque.design = null;
    this.cheque.design_id = -1;
    this.cheque.design_pattern = null;
    this.cheque.design_background_colour = null;
    // this.get_default_design();
    this.sql.set( 'Cheques', 'cheque_id', this.cheque.cheque_id, 'design', '');
    this.sql.set( 'Cheques', 'cheque_id', this.cheque.cheque_id, 'design_id', -1);
    this.sql.set( 'Cheques', 'cheque_id', this.cheque.cheque_id, 'design_pattern', '');
    this.sql.set( 'Cheques', 'cheque_id', this.cheque.cheque_id, 'design_background_colour', '');
  }
  async add_entry() {
    if(!this.cheque.cheque_id){
      this.autosave((id)=>{
        this.cheque.cheque_id=id;
        this.sql.set_temporary('page', {location:'Cheque',id:id});
        this.entry_edit_window();
      });
    } else {
      this.entry_edit_window();
     this.sql.set_temporary('page', {location:'Cheque',id:this.cheque.cheque_id});
    }
  }
  async entry_edit_window() {
    const m = await this.modalController.create({component: ChequeEntriesComponent, componentProps: {
      id: this.cheque.cheque_id
    }});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      console.log('entry',a.data)
      if(a.data.id==-1){
        this.delete_entry();
        this.sql.hapticsImpactLight();
      } else {
        this._c=1;
        var entryid = a.data.id;
        var total = a.data.total;
        var count = a.data.count;
        this.count_cheque_entries = count?count:-1;
        if(total > 0 && total!=this.cheque.amount){
          // if(this.sql.word.Cheque_Update_Calculation){
          //   if(confirm(this.sql.word.Cheque_Update_Calculation+' '+total)) {
          //    this.cheque.amount=this.sql.amount(total,null,this.cheque.currency?this.cheque.currency:this.sql.Currency);
          //   }
          // } else {
            var n=this.sql.amount(total,null,this.cheque.currency?this.cheque.currency:this.sql.Currency);
            if(n)this.cheque.amount=n;
          // }
        }
        if(entryid){
          this.delete_entry();
          this.sql.view('ChequeEntry','entry_id',entryid,(a)=>{
            if(a)this.select_entry(a[0]);
          });
        }
        this.sql.hapticsImpactMedium();
      }
      this.sql.remove_temporary('page');
    }
  }

  edit_entry(entry) {
    this.router.navigate(['folder','ChequeEntry', entry.cheque_entry_id]); 
  }
  delete_entry() {
    this._c=1;
    this.cheque.entries = [];
  }
  async openCalendar() {
    var options:CalendarModalOptions={
      title:this.sql.word.Cheque_Date,
      defaultDate:this.cheque.date?this.cheque.date:null
    };
    var myCalendar = await this.modalController.create({component:CalendarModal,componentProps:{options}});
        myCalendar.present();
    var event: any = await myCalendar.onDidDismiss();
    var date: CalendarResult=event.data;
    this.cheque.date=date.string;
  }
  select_address(address) {
    if (!address) return;
    this.cheque.addresses = address.address_id;
    this.cheque.address_id = address.address_id;
    if(address.name) this.cheque.address_name = address.name;
    if(address.address) this.cheque.address = address.address;
    if(address.address_2) this.cheque.address_2 = address.address_2;
    if(address.city) this.cheque.city = address.city;
    if(address.province) this.cheque.province = address.province;
    if(address.country) this.cheque.country = address.country;
    if(address.postal) this.cheque.postal = address.postal;
  }
  select_payee(payee) {
    if (!payee) return;
    this.cheque.payees = payee.payee_id;
    this.cheque.payee_id = payee.payee_id;
    if(payee.name) this.cheque.name = payee.name;
    if(payee.title) this.cheque.payee_title = payee.title;
    if(payee.address.address) this.cheque.address_name = payee.address.name;
    if(payee.address.address) this.cheque.address = payee.address.address;
    if(payee.address.address_2) this.cheque.address_2 = payee.address.address_2;
    if(payee.address.city) this.cheque.city = payee.address.city;
    if(payee.address.country) this.cheque.country = payee.address.country;
    if(payee.address.postal) this.cheque.postal = payee.address.postal;
    if(payee.printed_memo) this.cheque.printed_memo = payee.printed_memo;
  }
  select_entry(entry) {
    if (!entry) return;
    var e = [];
    if(this.cheque.entry_ids)this.cheque.entries = this.cheque.entry_ids.split('');
    this.cheque.entry_ids = entry.entry_id;
    this.recount_cheque_entries();
  }
  select_employee(employee) {
    if (!employee) return;
    this.cheque.employees = employee.employee_id;
    this.cheque.employee_name = employee.full_name;
    this.cheque.name = employee.full_name;
    this.cheque.address = employee.address.address;
    this.cheque.address_2 = employee.address.address_2;
    this.cheque.address_name = employee.address.name;
    this.cheque.city = employee.address.city;
    this.cheque.country = employee.address.country;
    this.cheque.postal = employee.address.postal;
  }
  select_bank(bank) {
    if (!bank) return;
    this.cheque.signature = bank.signature;
    this.cheque.printed_id = bank.cheque_id?bank.cheque_id:this.cheque.cheque_id;
    this.cheque.banks = bank.bank_id;
    this.cheque.number = (Number(bank.cheque_starting_id)>Number(this.cheque.number)||!this.cheque.number)?bank.cheque_starting_id:this.cheque.number;
    this.cheque.bank_account_holder = bank.account_holder;
    this.cheque.bank_image = bank.image;
    this.cheque.bank_name = bank.name;
    this.cheque.bank_address = bank.address;
    this.cheque.bank_address_2 = bank.address_2;
    this.cheque.bank_city =  bank.city;
    this.cheque.bank_postal = bank.postal;
    this.cheque.bank_country = bank.country;
    this.cheque.cheque_number = bank.cheque_number;
    this.cheque.transit_number = bank.transit_number;
    this.cheque.institution_number = bank.institution_number;
    this.cheque.designation_number = bank.designation_number;
    this.cheque.account_number = bank.account_number;
    this.bank_description = bank.description;
  }
  select_design(design) {
    if (!design) return;
    if(this.sql.more_verbose)console.log('Design Selected',design);
    this.cheque.design = design.title;
    this.cheque.design_id = design.design_id;
    this.cheque.design_pattern = design.design_pattern;
    this.cheque.design_background_colour = design.background_colour;
  }
  set_key(event){
    console.log(this.cheque.key, this.cheque.data);
    if(event.target.value) {
      this._c=1;
      this.cheque.key=event.target.value;
    } else {
      this.cheque.key='';
      if(this.cheque.key==''&&this.cheque.data=='')this.generate();
    }
  }
  set_data(event){
    console.log(this.cheque.key, this.cheque.data);
    if(event.target.value) {
      this._c=1;
      this.cheque.data=event.target.value;
    } else {
      this.cheque.data='';
      if(this.cheque.key==''&&this.cheque.data=='')this.generate();
    }
  }
  generate() {
    this.coin.generate(this.currencies[this.cheque.currency].unit,(address)=>{
      if(address) {
        if(!address.public && !address.private) {
          this.cheque.key=null;
          this.cheque.data=null;
        }
        if(address.public)this.cheque.key=address.public;
        if(address.private)this.cheque.data=address.private;
      }
    });
  }
  add_temp() {
    if (this.cheque.cheque_id) {
      this.sql.set_temporary('page', {location:'Cheque',id:this.cheque.cheque_id});
    } else {
      this.save(true,'no',(id)=>{
        if(!id)return;
        this.cheque.cheque_id=id;
        this.sql.set_temporary('page', {location:'Cheque',id:id});
      });
    }
  }
  end(id?) {
    this.sql.get_temporary('page',(page)=>{
      if (this._c>0) {
        if (confirm(this.sql.word.Discard_Confirmation)) {
          setTimeout( () => { this.cheque = new NewCheque(); }, 500);
          this.router.navigate(['folder','Cheques']);
        }
      } else {
        if(page) {
          this.router.navigate(['folder',page.location,page.id,'Cheque',id]);
          setTimeout( () => { this.sql.remove_temporary('page'); }, 600);
        } else {
          this.router.navigate(['folder','Cheques']);
        }
      }
    });
  }
  printer() {
    if(this.sql.verbose)console.log('Calling the printer ID:'+this.sql.DEFAULT_PRINTER+' to print this image document.')
    this.sql.print_document('#'+this.cheque.cheque_id,this.cheque.image,this.sql.DEFAULT_PRINTER,'cheque');
    this.printing=false;
  }
  print() {
    this.save(true,'yes');
  }
  view(id=this.cheque.cheque_id) {
    let p = ['folder','Print','Cheque',this.cheque.cheque_id,'Print'];
    console.log('view','path',p);
    // setTimeout( ()=> { this.router.navigate(p); }, 500);
    this.router.navigate(p);
    this.sql.hapticsImpactLight();
  }
  get_properties() {
    var payeeid=this.route.snapshot.paramMap.get('payeeid');if(payeeid)this.cheque.payees=payeeid;
    var employeeid=this.route.snapshot.paramMap.get('employeeid');if(employeeid)this.cheque.employees=employeeid;
    if(payeeid){this.sql.view('Payees','payee_id',payeeid,(a)=>{if(a)this.select_payee(a[0]);});}
    if(employeeid){this.sql.view('Employees','employee_id',employeeid,(a)=>{if(a)this.select_employee(a[0]);});}
  }
  get_cheque() {
    var ida = this.route.snapshot.paramMap.get("id");
    var amount=this.route.snapshot.paramMap.get('amount'); 
    var currency=this.route.snapshot.paramMap.get('currency');
    this.cheque.entries = [];
    if(!ida) {
      if(currency)this.cheque.currency=currency;
      if(amount)this.cheque.amount=this.sql.amount(amount,null,this.cheque.currency?this.cheque.currency:this.sql.Currency);
      this.get_default_design();
      this.get_properties();
      this.get_default_bank_account();
      this.set_currency(this.sql.Currency);
      this.cheque.date = new Date(Date.now()).toISOString();
      return;
    } else if (ida=='New') {
      this.get_properties();
      this.get_default_design();
    } else {
      this.sql.view('Cheques','cheque_id',ida,(e)=>{
        if(!e||!e[0])return;
        var f=Object.entries(e[0]); let n = new NewCheque();
        for(var t of f)n[t[0]]=t[1];
        var bankid=this.route.snapshot.paramMap.get('bankid');if(bankid)n.banks=bankid;
        var payeeid=this.route.snapshot.paramMap.get('payeeid');if(payeeid)n.payees=payeeid;
        var addressid=this.route.snapshot.paramMap.get('addressid');if(addressid)n.addresses=addressid;
        var employeeid=this.route.snapshot.paramMap.get('employeeid');if(employeeid)n.employees=employeeid;
        var signatureid=this.route.snapshot.paramMap.get('signatureid');if(signatureid)n.signature=signatureid;
        if(!n.number)n.number=n.cheque_id;
        if(currency)n.currency=currency;
        if(amount)n.amount=this.sql.amount(amount,null,n.currency?n.currency:this.sql.Currency);
        if(this.sql.max_cheque_amount>0&&n.amount>this.sql.max_cheque_amount)n.amount=this.sql.max_cheque_amount;
        if(bankid){this.sql.view('BankAccounts','bank_id',bankid,(a)=>{if(a)this.select_bank(a[0]);});}
        if(payeeid){this.sql.view('Payees','payee_id',payeeid,(a)=>{if(a)this.select_payee(a[0]);});}
        if(addressid){this.sql.view('Addresses','address_id',addressid,(a)=>{if(a)this.select_address(a[0]);});}
        if(employeeid){this.sql.view('Employees','employee_id',employeeid,(a)=>{if(a)this.select_employee(a[0]);});}
        this.cheque = n;
        this.get_cheque_entries();
        if(this.cheque.design_id){
          this.sql.query('SELECT design_id FROM Cheques WHERE cheque_id = '+this.cheque.cheque_id,(did)=>{
            this.sql.view('Designs','design_id',did.design_id,(a)=>{
              if(a)this.select_design(a[0]);
              if(!a)this.get_default_design();
            });
          });
        }
        this.selected_currency=this.cheque.currency;
        this.set_decimal_places();
      });
    }
  }
  get_cheque_entries(ida=this.cheque.cheque_id) {
    this.sql.get('ChequeEntry WHERE cheque_id = '+ida,(a)=>{
      this.cheque.entries = a;
      this.count_cheque_entries=a?a.length:0;
      this.add_single_entry();
    });
  }
  recount_cheque_entries() {
    if(this.cheque.cheque_id)this.sql.get('ChequeEntry WHERE cheque_id = '+this.cheque.cheque_id,(a)=>{
      this.count_cheque_entries=a?a.length:0;
    });
  } 
  add_single_entry(cb?, title?, amount?) {
    if(this.cheque.entries){
      if(this.cheque.entries[0]&&this.cheque.entries[0].cheque_entry_id) {
        this.cheque.entries[0].amount=amount?amount:this.cheque.amount>0?this.cheque.amount:0;
        if(cb)cb(this.cheque.entries[0].cheque_entry_id);
      } else { 
        if(cb)cb(0);
      }
    } else if(title||amount) {
      var ChequeEntry = new NewChequeEntry();
      ChequeEntry.title = title?title:this.cheque.bank_account_holder?this.cheque.bank_account_holder:this.sql.word.Total;
      ChequeEntry.amount = amount?amount:this.cheque.amount>0?this.cheque.amount:0;
      ChequeEntry.cheque_id = this.cheque.cheque_id;
      this.sql.add("ChequeEntry",ChequeEntry,(id)=>{
        if(!this.cheque.entries)this.cheque.entries=[];
        this.cheque.entries.push(ChequeEntry);
        this.recount_cheque_entries();
        if(cb)cb(id);
      });
    } else { 
      if(cb)cb(0);
    }
  }
  get_designs(a?) {
    this.sql.get('Designs',(e)=>{
      if(e)this.designs=e;
      if(this.sql.more_verbose)console.log('designs',this.designs)
      if(a)a(e);
    });
  }
  get_default_design() {
    var set = 0;
    for(var d of this.designs) {
      if(d.default_design=='true') {
        set=1;
        this.select_design(d);
      }
    }
    if(set==0)this.select_design(this.designs[0]);
  }
  get_default_bank_account() {
    this.sql.get_default_account((default_account)=>{
      this.sql.view('BankAccounts','bank_id',default_account,(account)=>{
        if(account)this.select_bank(account[0]);
      });
    });
  }
  async preview_signature(sig) {
    const m=await this.modalController.create({
      component:ViewSignatureComponent,
      componentProps:{id:sig}
    });
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data=='new') {
      this.add_signature();
    } else if(a.data=='delete')  {
      this.delete_signature();
    }
  }
  async add_signature() {
    const m = await this.modalController.create({
      component: SignatureComponent, 
      componentProps: {}
    });
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data=='delete')  {
      this.delete_signature();
    } else if (a.data&&a.data.signature_id) {
      this._c=1;
      this.cheque.signature = a.data.signature_id;
    }
  }
  delete_signature() {
    this._c=1;
    this.cheque.signature=-1;
    this.cheque.signature_image = '';
    this.cheque.signature_name='';
    this.cheque.signature_description='';
    this.cheque.signature_fill_color=false;
  }
  amount(event) {
    if(!event.target.value||event.target.value==0||isNaN(event.target.value)){
      if(isNaN(event.target.value)){
        var am = event.target.value.split('.');
        this.cheque.amount = am[0]+'.'+am[1];
      } else {
        this.cheque.amount = '0.00';
      }
    } else {
      var amount=this.sql.amount(event.target.value, null, this.cheque.currency);
      if(this.sql.max_cheque_amount>0&&amount>this.sql.max_cheque_amount) {
        alert(this.sql.word.Max_Amount_Message+' '+this.sql.CurrencySymbol+this.sql.max_cheque_amount+' '+this.sql.Currency);
        this.cheque.amount = this.sql.max_cheque_amount;
        if(this.sql.Trial)this.sql.show_purhchase_modal();
      } else {
        this.cheque.amount = amount;
        this.add_single_entry();
      }
    }
  }
  cheque_number_add(){
    this._c=1;
    this.cheque.number=Number(Number(this.cheque.number)+1);
    if((!this.cheque.number)||isNaN(this.cheque.number))this.cheque.number=1;
  }
  cheque_number_subtract(){
    this._c=1;
    if(this.cheque.number>1){
      this.cheque.number=Number(Number(this.cheque.number)-1);
    } else {
      this.cheque.number=1;
    }
    if((!this.cheque.number)||isNaN(this.cheque.number))this.cheque.number=1;
  }
  mail() {
    this.router.navigate(['folder','Print','Address',this.cheque.addresses]);
  }
  async popup_mail() {
    this.sql.hapticsImpactLight();
    const m = await this.modalController.create({component: PrintAddressComponent, componentProps: {
      address_id: this.cheque.addresses,
      modal:true
    }});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      this.sql.hapticsImpactLight();
    }
  }
  async purchase() {
    const m = await this.modalController.create({component: PurchaseComponent, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
  }
  autosave(cb?) {
    if(this._c>0&&(this.cheque.amount||this.cheque.name||this.cheque.name!='')){
      this.save(false,false,cb);
    }
  }
  save(end=true,print?,cb?) {
    if(this.sql.verbose)console.log('cheque.design',this.cheque.design);
    this.cheque.admin_id = this.sql.admin_id;
    if(!this.cheque.cheque_id) {
      this.add_cheque(end, print, cb);
      return;
    } else {
      this.sql.view('Cheques','cheque_id',this.cheque.cheque_id,(e)=>{
        if(!e) {
          this.add_cheque(end, print, cb);
        } else {
          this.update_cheque(end, print, cb);
        }
      });
    }
  }
  add_cheque(end=true, print='', cb?) {
    this.sql.add("Cheques",this.cheque,(insertId)=>{
      this._c=0;
      this.cheque.cheque_id=insertId;
      this.add_single_entry((main_entry_id)=>{
        if(this.sql.verbose)console.log("Add Cheques: ",insertId);
        if(insertId!=false&&insertId>0) {
          if (print=='yes') {
            this.view(insertId);
            if(cb)cb(insertId);
            // return true;
          } else if(print=='no') {
            if(cb)cb(insertId);
            // return true;
          } else {
            if(end)this.end(insertId);
            if(cb)cb(insertId);
            // return true;
          }
        } else {
          if(cb)cb(false);
          // return false;
        }
      });
    });
  }
  update_cheque(end=true, print='', cb?) {
    if(this.sql.verbose)console.log("Save Cheques: ",this.cheque);
    this.sql.updateObj("Cheques",this.cheque,(insertId)=>{
      if(insertId){
        if(this.sql.more_verbose)console.log("UpdateObj Cheques: ",insertId, this.cheque);
        this._c=0;
        if (print=='yes') {
          if(this.sql.more_verbose)console.log('Cheque Object Sending to Print Preview', this.cheque)
          this.view();
          if(cb)cb(insertId);
          return true;
        } else if (print=='no') {
          if(cb)cb(insertId);
          return true;
        } else { 
          if(end&&!cb)this.end(insertId);
          if(cb)cb(insertId);
          return true;
        }
      }
    });
  }
  change_currency(c) {
    if(!c||this.cheque.currency==c){
      //
    } else if (!this.cheque.amount) {
      this.set_currency(c);
    } else {
      this.sql.exchange_currency(this.cheque.amount,this.cheque.currency,c,(amount)=>{
        this.cheque.amount=amount;
        this.set_currency(c);
      });  
    }
  }
  set_currency(c) {
    this.cheque.currency=c;
    if(!this.Currency[this.cheque.currency].fiat) {
      this.cheque.key='';
      this.cheque.data='';
      this.cheque.fiat='0';
      this.generate();
    } else {
      this.cheque.fiat='1';
    }
    this.set_decimal_places();
  }
  set_decimal_places() {
    this.decimal_places = '0.';
    var d = this.Currency[this.cheque.currency].decimal_places?this.Currency[this.cheque.currency].decimal_places:2;
    for (var i = 0; i < d; ++i) {
      this.decimal_places=this.decimal_places+'0';
      if(i==d)this.decimal_places=this.decimal_places+'1';
    }
  }
  reset() {
    this.cheque = new NewCheque();
    this._c = 0;
  }
  open() {
    this.Currency = Currencies;
    this.get_designs(()=>{
      this.get_default_design();
    });
    this.get_cheque();
  }
  ngAfterViewInit() { 
    this.reset();
    this.open();
  }
  ngAfterContentInit() {
  }
  ngOnDestroy() {
    this._c=0;
    this.banks = [];
    this.cheques = [];
    this.employees = [];
    this.payees = [];
    this.printing = false;
    this.bank_description = null; 
    this.cheque = new NewCheque();
  }
}