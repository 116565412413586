import { HttpClient, HttpParams, HttpHeaders, HttpClientModule, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AES256 } from '@awesome-cordova-plugins/aes-256/ngx';
import { Injectable,Inject,NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Plugins } from '@capacitor/core';
import { ElectronService } from 'ngx-electron';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Haptics, ImpactStyle, NotificationType } from '@capacitor/haptics';
import { Filesystem, Directory, FilesystemDirectory, ReaddirOptions, MkdirOptions, Encoding } from '@capacitor/filesystem';
import { IonicNativePlugin, cordova, checkAvailability, getPromise } from '@ionic-native/core';
import { Downloader, DownloadRequest } from '@ionic-native/downloader/ngx';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { SQLite, SQLiteObject, SQLiteDatabaseConfig } from '@ionic-native/sqlite/ngx';
import { Geolocation } from '@capacitor/geolocation';
import { SQLitePorter } from '@ionic-native/sqlite-porter/ngx';
import { Globalization } from '@ionic-native/globalization/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { Broadcaster } from '@awesome-cordova-plugins/broadcaster/ngx';
import { fonts } from '../cheque/designs/font-picker/fonts';
import { Platform,LoadingController} from '@ionic/angular';
import Holidays from 'date-holidays';
import { Printer, PrintOptions, HeaderFooterLabelOptions, FontOptions } from '../../../pdfmake/printer/ngx';
import { SignInComponent } from '../dashboard/sign-in/sign-in.component';
import { SignUpComponent } from '../dashboard/sign-up/sign-up.component';
import { PurchaseComponent } from '../dashboard/purchase/purchase.component';
import { environment } from '../../environments/environment';
import { Currencies } from '../../environments/currencies';
import { PaperSizes } from '../../environments/papersizes';
import { EncodingKeys, DecodingKeys, Licences } from "../../environments/keys";
import { NewPrintJob } from "../models/printjobs";
import { NewDesign } from "../models/design";
import { AppInterceptor } from "../app.interceptors";
import * as Templates from '../cheque/templates/index';
import * as SQLModels from "../models";
import * as Languages from '../../environments/languages/index';
import * as cptable from 'xlsx/dist/cpexcel.full.mjs';
import * as CryptoJS from 'crypto-js';
import { Base64 } from "@ionic-native/base64";
import { read, readFile, writeFileXLSX, utils, set_cptable } from "xlsx";
import { InAppPurchase2, IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { AlertController, ToastController, ModalController } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
set_cptable(cptable);
@Injectable()
export class SQLService {
	constructor(
		public zone: NgZone,
		private file:File,
		private sqlite:SQLite,
		private fileOpener:FileOpener,
		private angular_http:HttpClient,
		private fileChooser:FileChooser,
		private globalization:Globalization,
		private router:Router,
		private aes256:AES256,
		private imagePicker:ImagePicker,
		private ionic_http:HTTP,
		private printer:Printer,
		private platform:Platform,
		private downloader:Downloader,
		private broadcaster:Broadcaster,
		private filePicker:IOSFilePicker,
		private sqlitePorter:SQLitePorter,
		private httpClient:HttpClientModule,
		private emailComposer:EmailComposer,
		private uniqueDeviceID:UniqueDeviceID,
		private toastController: ToastController,
		private modalController: ModalController,
		private alertController: AlertController,
    	private loadingCtrl: LoadingController,
		private cookieService: CookieService,
		private interceptor:AppInterceptor,
		private store: InAppPurchase2,
		private app_controller: ElectronService
	) { this.Today = new Date();
		if(this.verbose)console.log('app controller', this.app_controller);
		if(this.platform.is('capacitor')) {
			this.emailComposer.addAlias('gmail','com.google.android.gm');
			this.emailComposer.addAlias('outlook','com.microsoft.android.outlook');
												this.mobile = true;			
			if(this.platform.is('ios')) 		this.model='ios';
			if(this.platform.is('android')) 	this.model='android';
		}else if(this.platform.is('electron')){
			if(this.platform.is('electron')) 	this.model='electron';
		} else { 								this.model='web'; 				  }
	}
	public DEFAULT_PRINTER_OPTIONS: PrintOptions;
	private db: SQLiteObject;
	private db2: SQLiteObject;
	private _c: boolean;
	private _init: boolean;
	private line: boolean;
	private elec: boolean;
	private initiated: boolean;
	private printer_picker_is_open: boolean;
	public pin: boolean;
	public UserRole: boolean;
	public dbsetup: boolean;
	public ocr_running: boolean;
	public showingPurchase: boolean;
	public showingLogin: boolean;
	public show_menu: boolean;
	public licence_key: boolean;
	public DEFAULT_PRINTER: number 			= -1;
	public ShowSpinner: boolean  			= false;
	public enable_sounds: boolean  			= false;
	public Autobilling_Subs: boolean  		= false;
	public geo_monitoring: boolean  		= false;
	public getting_geo_pos: boolean  		= false;
	public customize_dashboard: boolean 	= false;
	public widget_dashboard: boolean  		= false;
	public Trial: boolean 					= false;
	public PrintOuts: boolean 				= false;
	public Subscription: boolean 			= false;
	public Unlimited: boolean 				= false;
	public mobile 							= false;
	public _ftb: boolean  					= false;
	public locked: boolean  				= false;
	public setup_complete: boolean 			= false;
	public printing_paused: boolean  		= false;
	public show_lock_screen: boolean 		= false;
	public printing: boolean 				= false;
	public menuRendered: boolean 			= false;
	public dashboard_loaded: boolean 		= false;
	public ID: any 							= false;
	public Next_Payroll_ID:  				number;
	public logged_in: boolean  				= true;
	public account: boolean  				= true;
	public protection_mechanisms: boolean 	= true;
	public show_prints_chip: boolean 		= true;
	public co: boolean  					= true;
	public emailHTML: boolean 				= true;
	public TempDemoWebDB: boolean 			= true;
	public AvailablePrints: number 			= 0;
	public DEFAULT_INCOME: number 			= 0;
	public Purchased 						= 0;
	public printing_page:number 			= 0;
	public printing_total_pages:number 		= 0;
	public admin_id: number  				= 1;
	public selected_company: number  		= 1;
	public enable_calendar: number 			= 1;//this.epl.enable_calendar?0:1;
	public cheque_starting_id_blank: number = 1;
	public Places  							= 2;
	public minimum_geo_distance: number  	= 10;
	public maxtime: number  				= 2100;
	public maxmum_geo_distance: number 		= 4023;
	public licence_name: 	 				string;
	public secureKey: 	 					string;
	public BACKUP_AUTO:  					string;
	public BACKUP_PROVIDER:  				string;
	public BACKUP_TYPE:  					string;
	public BACKUP_FTP:  					string;
	public Company_Name:  					string;
	public Company_Logo:  					string;
	public Next_Payroll_Title:  			string;
	public AuthorizationToken: string 		= '';
	public TempToken: string 				= 'temporary_database_token';
	public alpha:string  					= "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	public MediaType: string  				= 'HMTL';
	public loading_text:string  			= 'Loading...';
	public emailApp:any 					= 'mailto'; // mailto / gmail / outlook
	public save_in_file_format: string  	= 'jpg';
	public model:string						= 'ios';
	public numo:string 						= "0123456789";
	public Next_Payroll_Date:  				any;
	public geo_timer: 						any;
	public ocr_progress: 					any;
	public Holidays:  						any;
	public TrialNotification:  				any;
	public Today:  							any;
	public loading_box: 					any;
	private _log  							= [];
	private tables 							= [];
	private _logger 						= [];
	public SucessfulPrints 					= [];
	public UnsucessfulPrints 				= [];
	public appPages 						= [];
	public userSettings 					= [];
	public adminSettings 					= [];
	public depositSettings 					= [];
	public appSettings 						= [];
	public accountSettings 					= [];
	public Currencies 						= [];
	public Locale: string 					= environment.locale;
	public Language: string 				= environment.language;
	public word 							= Languages[environment.language]?Languages[environment.language]:{};
	public production: boolean 				= environment.production;
	public procurement: string 				= environment.procurement;
	public licenced: boolean 				= environment.licenced;
	public Modifications 					= environment.required_modifications;
	public epl 								= environment.places[environment.locale].title?environment.places[environment.locale]:environment.places['default'];
	public Title: string 					= this.epl.title;
	public State_Province: string 			= this.epl.state_province;
	public Country: string 					= this.epl.country;
	public CountryName: string 				= this.epl.country_name; 
	public City: string 					= this.epl.city;
	public Currency: string 				= Currencies[this.epl.currency].symbol?Currencies[this.epl.currency].symbol:"USD";
	public CurrencyWords: string 			= Currencies[this.epl.currency].name?Currencies[this.epl.currency].name:"US Dollars";
	public CurrencyValue: number 			= Currencies[this.epl.currency].value?Currencies[this.epl.currency].value:1;
	public CurrencySymbol: string 			= Currencies[this.epl.currency].icon?Currencies[this.epl.currency].icon:"$";
	public CurrencyUnit: string 			= Currencies[this.epl.currency].unit?Currencies[this.epl.currency].unit:"Dollar";
	public subTitle: string 				= this.epl.subtitle;
	public metric: boolean 					= this.epl.metric;
	public geofence: boolean 				= this.epl.geofence;
	public pin_protection: boolean 			= this.epl.pin;
	public pass_protection: boolean 		= this.epl.password;
	public biometric_protection: boolean 	= this.epl.biometrics;
	public bank_number_protection: boolean 	= this.epl.bank_number_protection;
	public smart_printjobs: boolean 		= this.epl.print;
	public pho: boolean 					= this.epl.phone;
	public paypal_payouts: boolean 			= this.epl.paypal; 
	public designer: boolean 				= this.epl.cheque_designer;
	public designs_editable: boolean 		= this.epl.cheque_designs_editable;
	public no_empty_pages: boolean 			= this.epl.no_empty_pages;
	public deposit_enabled: boolean 		= this.epl.deposits;
	public zeropad_number: boolean 			= this.epl.zeropad_number;
	public autogen_cheques: boolean 		= this.epl.autogen_cheques;
	public auto_refresh: boolean 			= this.epl.auto_refresh;
	public enable_ultimate: boolean 		= this.epl.ultimate_buy; 
	public enable_buy: boolean 				= this.epl.see_buy;
	public multiple_companies: boolean		= this.epl.companies;
	public enable_bank_limits: boolean 		= this.epl.enable_limit_settings;
	public _off: boolean 					= this.epl._off?this.epl._off:false;
	public _on: number 						= this.epl._on?this.epl._on:0; 
	public timing: number 					= this.epl.enable_time_picker?0:1;
	public show_printed_credit: number 		= this.epl.show_printed_credit?1:0;
	public monthly_features: number 		= this.epl.monthly_features?this.epl.monthly_features:0;
	public _co: number 						= this.epl._on?this.epl.co:0;
	public msrp: number 					= this.epl.software_licence_msrp?this.epl.software_licence_msrp:0;
	public mailed_black_msrp: number 		= this.epl.mailed_black_msrp?this.epl.mailed_black_msrp:0;
	public mailed_color_msrp: number 		= this.epl.mailed_color_msrp?this.epl.mailed_color_msrp:0;
	public show_docu_title: number 	 		= this.epl.show_docu_title?this.epl.show_docu_title:0;
	public modify_docu_title: boolean 	 	= this.epl.modify_docu_title?true:false;
	public enable_secondly: boolean  		= this.epl.secondly?this.epl.secondly:false;
	public enable_minutely: boolean  		= this.epl.minutely?this.epl.minutely:false;
	public ocr: boolean 					= this.epl.ocr?true:false;
	public account_keys: boolean 			= this.epl.account_keys;
	public skype: boolean  					= this.epl.skype;
	public fax: boolean 					= this.epl.fax;
	public stripe 							= this.epl.credit_cards;
	public bitcoin 							= this.epl.bitcoins;
	public stylish 							= this.epl.stylish;
	public Banks 							= this.epl.banks;
	public TrialPrints 						= this.epl.trial_prints;
	public EnableInsurance 					= this.epl.insurance;d
	public EnableInvoices 					= this.epl.invoices;
	public InsurePayees 					= this.epl.insurance_payees;
	public max_cheque_amount  				= this.epl.max_cheque_amount;
	public max_payment_amount  				= this.epl.max_payment_amount;
	public max_payroll_amount  				= this.epl.max_payroll_amount;
	public AdminEnabled:boolean 			= this.epl.administrators;
	public ModeratorsEnabled:boolean 		= this.epl.moderators;
	public Trial_Bank_Accounts:number 		= this.epl.trial_accounts?this.epl.trial_accounts:-1;
	public Trial_Employees:number 			= this.epl.trial_employees?this.epl.trial_employees:-1;
	public Trial_Payrolls:number 			= this.epl.trial_payrolls?this.epl.trial_payrolls:-1;
	public Trial_Payments:number 			= this.epl.trial_payments?this.epl.trial_payments:-1;
	public Trial_Payees:number 				= this.epl.trial_payees?this.epl.trial_payees:-1;
	public Trial_Salaries:number 			= this.epl.trial_salaries?this.epl.trial_salaries:-1;
	public Trial_Schedules:number 			= this.epl.trial_schedules?this.epl.trial_schedules:-1;
	public Trial_Time_sheets:number 		= this.epl.trial_time_sheets?this.epl.trial_time_sheets:-1;
	public Trial_Addresses:number 			= this.epl.trial_addresses?this.epl.trial_addresses:-1;
	public trial_max_amount:number 			= this.epl.trial_max_amount?this.epl.trial_max_amount:-1;
	public DataFolder: string 				= this.word.Upgrade_Title;
	public LOGGING:boolean  				= (environment.logging)?true:false;	
	public on: boolean  					= (this._on>0)?false:true;
	public enable_override: boolean 		= environment.buy_override;
	public price 							= environment.prices;
	public more_verbose 					= environment.more_verbose;
	public Alphabet  						= environment.alpha;
	public version  						= environment.version;
	public verbose: boolean  				= environment.verbose;
	public date_man 						= environment.date;
	public Currency_Rates  					= environment.currencies;
	public products = [
		{id:"chequeslicence",months:"1",cheques:"-1",mode:"subscription",price:this.price[this.CountryName].chequeslicence,currency:this.price[this.CountryName].currency,alias:this.word.Subscription,type:this.store.PAID_SUBSCRIPTION},
		{id:"chequeslicence_2mo",months:"2",cheques:"-1",mode:"subscription",price:this.price[this.CountryName].chequeslicence_2mo,currency:this.price[this.CountryName].currency,alias:this.word.Two_Month_Subscription,type:this.store.PAID_SUBSCRIPTION},
		{id:"chequeslicence_3mo",months:"3",cheques:"-1",mode:"subscription",price:this.price[this.CountryName].chequeslicence_3mo,currency:this.price[this.CountryName].currency,alias:this.word.Three_Month_Subscription,type:this.store.PAID_SUBSCRIPTION},
		{id:"chequeslicence_6mo",months:"6",cheques:"-1",mode:"subscription",price:this.price[this.CountryName].chequeslicence_6mo,currency:this.price[this.CountryName].currency,alias:this.word.Six_Month_Subscription,type:this.store.PAID_SUBSCRIPTION},
		{id:"chequeslicence_12mo",months:"12",cheques:"-1",mode:"subscription",price:this.price[this.CountryName].chequeslicence_12mo,currency:this.price[this.CountryName].currency,alias:this.word.Twelve_Month_Subscription,type:this.store.PAID_SUBSCRIPTION},
		{id:"cheques_znvr9f",months:"-1",cheques:"-1",mode:"premium",price:this.price[this.CountryName].cheques_znvr9f,currency:this.price[this.CountryName].currency,alias:this.word.Cheques_Unlimited,type:this.store.NON_CONSUMABLE},
		{id:"cheques_print_1",months:"-1",cheques:"1",mode:"print",price:this.price[this.CountryName].cheques_print_1,currency:this.price[this.CountryName].currency,alias:this.word.Single_Print,type:this.store.CONSUMABLE},
		{id:"cheques_print_2",months:"-1",cheques:"2",mode:"print",price:this.price[this.CountryName].cheques_print_2,currency:this.price[this.CountryName].currency,alias:this.word.Two_Prints,type:this.store.CONSUMABLE},
		{id:"cheques_print_5",months:"-1",cheques:"5",mode:"print",price:this.price[this.CountryName].cheques_print_5,currency:this.price[this.CountryName].currency,alias:this.word.Five_Prints,type:this.store.CONSUMABLE},
		{id:"cheques_print_10",months:"-1",cheques:"10",mode:"print",price:this.price[this.CountryName].cheques_print_10,currency:this.price[this.CountryName].currency,alias:this.word.Ten_Prints,type:this.store.CONSUMABLE},
		{id:"cheques_print_15",months:"-1",cheques:"15",mode:"print",price:this.price[this.CountryName].cheques_print_15,currency:this.price[this.CountryName].currency,alias:this.word.Fifteen_Prints,type:this.store.CONSUMABLE},
		{id:"cheques_print_20",months:"-1",cheques:"20",mode:"print",price:this.price[this.CountryName].cheques_print_20,currency:this.price[this.CountryName].currency,alias:this.word.Twenty_Prints,type:this.store.CONSUMABLE},
		{id:"cheques_print_30",months:"-1",cheques:"30",mode:"print",price:this.price[this.CountryName].cheques_print_30,currency:this.price[this.CountryName].currency,alias:this.word.Thirty_Prints,type:this.store.CONSUMABLE},
		// {id:"cheques_mailed_black_white",mode:"real_mail",price:this.price[this.CountryName].cheques_mailed_black_white,currency:this.price[this.CountryName].currency,alias:this.word.Mailed_Cheque,type:this.store.CONSUMABLE},
		// {id:"cheques_znvr9f_color",mode:"real_mail",price:this.price[this.CountryName].cheques_znvr9f_color,currency:this.price[this.CountryName].currency,alias:this.word.Mailed_Cheque_Color,type:this.store.CONSUMABLE}
	];
	renderMenu(str=this.word) {
		this.appPages = [
			{ title: this.Title, next_cycle: this.Next_Payroll_Date, url: null, dispaly:true },
			{ title: str.Dashboard, url: '/folder/Dashboard', icon: 'file-tray-full', dispaly:true },
			{ 
				title: (this.printing_paused?str.Print_Queue:str.Printing)+' '+'('+this.printing_page+' '+str.of+' '+this.printing_total_pages+')',
				icon:'print',
				color:'secondary',
				icon_color:'light',
				url:'/folder/PrintJobs',
				spinner:!this.printing_paused,
				dispaly:this.printing
			},
			{ title: str.Cheques,  		url: '/folder/Cheques', 			icon: 'mail', 			dispaly:true},
			{ title: str.Employees, 	url: '/folder/Employees', 			icon: 'people-circle', 	dispaly:true},
			{ title: str.Jobs,  		url: '/folder/Jobs', 				icon: 'receipt', 		dispaly:false},
			{ title: str.Payees, 		url: '/folder/Payees', 				icon: 'people', 		dispaly:true},
			{ title: str.Payments, 		url: '/folder/Payments', 			icon: 'send', 			dispaly:true},
			{ title: str.Payroll, 		url: '/folder/Payroll', 			icon: 'albums', 		dispaly:true},
			{ title: str.Salaries, 		url: '/folder/Salaries', 			icon: 'book', 			dispaly:true},
			{ title: str.Schedules, 	url: '/folder/Schedules', 			icon: 'calendar', 		dispaly:true},
			{ title: str.Timesheets,	url: '/folder/Timesheets', 			icon: 'stopwatch', 		dispaly:true},
			{ title: str.Invoices, 		url: '/folder/Invoices', 			icon: 'briefcase', 		dispaly:this.EnableInvoices},
			{ title: str.Absences, 		url: '/folder/Leaves', 				icon: 'nutrition', 		dispaly:true},
			{ title: str.Vacations, 	url: '/folder/Vacations', 			icon: 'sunny', 			dispaly:true},
			{ title: str.Holidays, 		url: '/folder/Holidays', 			icon: 'balloon', 		dispaly:false},
			{ title: str.Bonuses,		url: '/folder/Bonuses', 			icon: 'add', 			dispaly:false},
			{ title: str.Deductions,	url: '/folder/Deductions', 			icon: 'calculator', 	dispaly:true},
			{ title: str.Insurance,		url: '/folder/Insurance', 			icon: 'id-card', 		dispaly:this.EnableInsurance},
			{ title: str.Tax_Deductions,url: '/folder/Taxes', 				icon: 'pie-chart', 		dispaly:true},
			{ title: str.Address, 		url: '/folder/Addresses', 			icon: 'bookmarks', 		dispaly:true},
			{ title: str.Bank_Accounts, url: '/folder/Accounts', 			icon: 'wallet', 		dispaly:true},
			{ title: str.Archive, 		url: '/folder/Archive', 			icon: 'archive', 		dispaly:false}
		];
		this.userSettings = [
			{ title: str.New_Timer, 	url: '/Timer/', 					icon: 'time',  			display: true },
			{ title: str.New_Timesheet, url: '/Timesheet/Submit', 			icon: 'time',  			display: true }
		];
		this.adminSettings = [
			{ title: str.Admin, 		url: '/folder/Administrators', 		icon: 'person',  		display: this.AdminEnabled },
			{ title: str.Moderators, 	url: '/folder/Moderators', 			icon: 'people',  		display: this.ModeratorsEnabled },
		];
		this.depositSettings = [
			{ title: str.Deposit, 		url: '/folder/Deposits', 			icon: 'wallet',  		display: true },
			{ title: str.CreditCardComponent, url: '/folder/Deposit/Card', 	icon: 'card',  			display: true },
			{ title: str.PaypalComponent,url: '/folder/Deposit/PayPal', 	icon: 'logo-paypal',	display: true },
			{ title: str.InteracComponent,url: '/folder/Deposit/Interac', 	icon: 'globe', 		 	display: true },
			{ title: str.BitcoinComponent,url: '/folder/Deposit/BitCoin', 	icon: 'logo-bitcoin',  	display: true }
		];
		this.appSettings = [
			{ title: str.Designs, 		function: null, url: '/folder/ChequeDesigns',  		icon: 'color-palette',  display: true }, //this.designer
			{ title: str.Settings, 		function: null, url: '/folder/Settings',  			icon: 'cog',  			display: true },
		];
		this.accountSettings = [
			{ title: str.Sign_Up, 		function: 'Signup', url: '/folder/Dashboard/Signup',icon: 'person-add', 	display: (this.model=='web'&&!this.logged_in) },
			{ title: str.Login, 		function: 'Login',  url: '/folder/Dashboard/Login', icon: 'power', 			display: (this.model=='web'&&!this.logged_in) },
			{ title: str.Logout, 		function: 'Logout', url: '/folder/'+(this.model=='web'?'Dashboard/Logout':'Splash'),icon: 'power',display: (this.protection_mechanisms||(this.model=='web'&&this.logged_in)) }
		]
		this.menuRendered = true;
	}
	async show_purhchase_modal() {
		if(this.TrialNotification)this.TrialNotification.dismiss();
		if(this.showingPurchase)return;
		this.showingPurchase = true;
		const m = await this.modalController.create({component: PurchaseComponent, componentProps: {}});
		await m.present();
		var a = await m.onDidDismiss();
		this.showingPurchase = false;
	}
	async show_login_modal() {
		this.showingLogin = true;
		const m = await this.modalController.create({component: SignInComponent, componentProps: {}});
		await m.present();
		var a = await m.onDidDismiss();
		if(a.data=='signup'){
			this.show_signup_modal();
		} else {
			this.showingLogin = false;
		}
	}
	async show_signup_modal() {
		this.showingLogin = true;
		const m = await this.modalController.create({component: SignUpComponent, componentProps: {}});
		await m.present();
		var a = await m.onDidDismiss();
		if(a.data=='signin'){
			this.show_login_modal();
		} else {
			this.showingLogin = false;
		}
	}
	async trial_toast() {
		if(this.showingPurchase)return;
		this.TrialNotification = await this.toastController.create({
			header: this.word.Trial_Complete,
			message: this.word.Please_Upgrade,
			position: 'bottom',
			buttons: [
				{
					side: 'start',
					icon: 'star',
					text: ' '+this.word.Purchase,
					handler: () => {
						this.show_purhchase_modal();
					}
				}, {
					text: this.word.Done,
					role: 'cancel',
					handler: () => {
						if(this.verbose)console.log('Cancel clicked');
					}
				}
			]
		});
		await this.TrialNotification.present();
	}
	setup_temp_token() {
		var t = this.getCookie('Temporary');
		if(t){ this.TempToken = t;
		} else {
			this.TempToken = environment.appname+'_client_'+Math.random()*100000000;
			this.cookie_service('Temporary', this.TempToken, 13000);
		}
	}
	trigger_trial_notification() {
		if(this.Trial&&this.AvailablePrints==0)this.trial_toast();
	}
	create_order(product_id, callback?) {
		var has_this_product = 0;
		this.b_get("Orders",(D)=>{
			for( let order of D ) {
				if(order.type!="print" && order.product==product_id) {
					has_this_product=order.order_id;	
				} 
			}
			if(has_this_product>0) {
				if(this.verbose)console.log('activate_order',has_this_product);
				this.activate_order(has_this_product,(active)=>{
					if(this.verbose)console.log('active',active);
					this.setup_orders();
					if (callback)callback(active);
				});
			} else {
				for(let product of this.products) {
					if ( product.id == product_id ) {
						if(this.verbose)console.log('Creating product', product)
						var now = new Date();
						var now_exp = new Date();
						var exp = null;
						if(product.mode=='subscription') {
							exp = new Date(now_exp.setMonth(now_exp.getMonth()+Number(product.months)));
						}
						var data = {
							type: product.mode,
							product: product.id,
							price: product.price,
							currency: product.currency,
							cheques: Number(product.cheques),
							months: Number(product.months),
							shipped: 0,
							delivered: 0,
							active: 1,
							expiry: exp?exp.getTime():null,
							modified: now.getTime(),
							created: now.getTime()
						}
						var values = "", keys = "", i = 0, l = 0;
						Object.entries(data).forEach(([key, value]) => { if(value) l++; });	
						Object.entries(data).forEach(([key, value]) => { if(value) {
							keys=keys+key+(i==l-1?"":",");
							values=values+'"'+value+'"'+(i==l-1?"":",");
							i=i+1;
						}});
						var sql="INSERT OR REPLACE INTO Orders ("+keys+") VALUES("+values+");";
						if(this.more_verbose)console.log('INSERT SQL',sql);
						this.b_query(sql,(item)=>{
							if (!item) return;
							if (this.verbose)console.log('INSERTED INTO Orders','ID',item);
							this.setup_orders(callback(item));
						});
					}
				}
			}	
		});
	} 
	setup_orders(cb?) {
		var orders = [];
		this.Trial = true;
		this.PrintOuts = false;
		this.Unlimited = false;
		this.Subscription = false;
		if(this.more_verbose)console.log('setup orders');
		this.AvailablePrints = this.TrialPrints;
		this.count_prints((prints)=>{
			if(!prints)prints=0;
			if(this.verbose)console.log('setup_orders','count prints',prints);
			this.AvailablePrints = this.TrialPrints-prints>0?this.TrialPrints-prints:0;
			if(this.more_verbose)console.log('Count prints',prints,'Available Prints',this.AvailablePrints);
			this.b_get("Orders",(D)=>{
				const now = new Date();
				if(this.more_verbose)console.log('setup_orders','Orders',D);
				if (!D) {
					this.Trial = true;
					this.PrintOuts = false;
					this.Subscription = false;
					this.Unlimited = false;
					if(this.more_verbose)console.log('No Orders Yet'); 
					// alert(this.word.PrinterError);
					if(cb)cb([]);
				} else {
					orders=D;
					if(this.verbose)console.log('Orders',orders);
					if(orders.length > 0) {
						for(var i = 0; i < orders.length; i++) {
							if(orders[i].type=='subscription') {
								this.recheck_order(orders[i],(order_check)=>{
									if(!order_check && (this.Autobilling_Subs==false || (this.Autobilling_Subs==true&&orders[i].active=="1")) ){
										this.modify_order(orders[i].order_id,false,()=>{
											this.post_order_check(orders,i,()=>{
												this.post_order_count();
												if(i==orders.length-1&&cb)cb();
											});
										});
									} else {
										this.post_order_check(orders,i,()=>{
											this.post_order_count();
											if(i==orders.length-1&&cb)cb();
										});
									}
								});
							} else {
								this.post_order_check(orders,i,()=>{
									this.post_order_count();
									if(i==orders.length-1&&cb)cb();
								});
							}
						}
					} else {
						this.AvailablePrints = this.TrialPrints-prints>0?this.TrialPrints-prints:0;
						this.Trial = true;
						if(cb)cb([]);
					}
				}
			});
		});
	}
	post_order_count() {
		if(this.Trial==true) {
			this.max_cheque_amount = this.trial_max_amount;
			this.max_payment_amount = this.trial_max_amount;
			this.max_payroll_amount = this.trial_max_amount;
		} else {
			this.max_cheque_amount = this.epl.max_cheque_amount;
			this.max_payment_amount = this.epl.max_payment_amount;
			this.max_payroll_amount = this.epl.max_payroll_amount;
		}
	}
	create(cb?) {
		if(this._on&&this._on>0)setTimeout(()=>{this.on=true;},Number(this._on*100));
		if(this.platform.is('capacitor')) {
			if(this.verbose)console.log('CAPACITOR MODE', 'SQLITE ENABLED');
			this.sqlite.create(environment.db2).then((internal: SQLiteObject) => {
				if (this.more_verbose) console.log('SQL Connected');
				this.db2 = internal;
				this.internal_database_init((result)=>{
					this.setup_default_company(()=>{
						var c:SQLiteDatabaseConfig={
							name:this.selected_company+environment.db.name,
							location:environment.db.location,
							key:environment.db.key
						}
						this.sqlite.create(c).then((database: SQLiteObject)=>{
							this.db = database; 
							if(this.more_verbose)console.log('SQL On-Device Created ',this.db);
							if(!this.dbsetup){
								this.setup(true,(a)=>{
									this.dbsetup=a;
									if(cb)cb(true);
								});
							} else {
								if(cb)cb(true);
							}
						}).catch(e=>{console.error(e)});
					});
				});
			}).catch(e=>{console.error(e)});
		} else if (this.platform.is('electron')) {
			if(this.verbose)console.log('ELECTRON MODE', 'SQLITE PASSTHROUGH ENABLED');
			this.elec = true;
			this.internal_database_init((result)=>{
				if(!this.dbsetup){
					this.setup(true,(a)=>{
						this.dbsetup=a;
						if(this.verbose)console.log("Electron Create Software",'setup');
						this.electron_app_ready();
						this.setup_orders(()=>{
							if(this.verbose)console.log("Electron Create Software",'setup_orders');
							this.setup_default_company(()=>{
								if(this.verbose)console.log("Electron Create Software",'setup_default_company');
								if(cb)cb(true);
							});
						});
					});
				} else {
					if(cb)cb(true);
				}
			});
		} else {
			this.line = true;
			if(this.verbose)console.log('WEB/CORE MODE', 'DB DISABLED', 'REVERTING TO ONLINE DATABASE ONLY.');
			if(!environment.com){console.error('WEB Core could not be conatacted. Check environment \'com\' variables.'); return;}
			if(this.more_verbose)console.log('SQL Line DB Setup');
			this.setup_temp_token();
			this.load_web_language(this.Language,(strings)=>{
				if(strings){
					this.loading_text=strings.LoadingDots;
					this.word=strings;
				}
				this.internal_database_init((result)=>{
					this.setup_orders(()=>{
						if(!this.dbsetup) {
							this.setup(true,(a)=>{
								this.setup_default_company(()=>{
									this.dbsetup=a;
									if(this.verbose)console.log('setup complete', a);
									if(cb)cb(true);
								});
							});
						} else {
							if(cb)cb(true);
						}
					});
				});
			});
		}
		this.remove_temporary('page');
	}
	internal_database_init(cb?) { 
		var e = SQLModels.AdminModels.length;
		if(this.verbose)console.log("Database setup starting. Adding "+e+" models.");
		for (var i = 0; i<e; i++) { 
			this.b_query(SQLModels['Create_'+SQLModels.AdminModels[i]]);
			if(i+1==e) {
				if(cb)cb(true);
				return true;
			}
		}
	}
	load_web_language(lang=this.Language,cb?){
		this.http_get(environment.com+'/strings/?lang='+lang,{},false,(l)=>{
			if(cb)cb(l);
		});
	}
	load_web_locale(locale=this.Locale,cb?){
		if(this.model == 'web') {
			this.http_get(environment.com+'/locale/?zone='+locale,{},false,(l)=>{
				if(cb)cb(l);
			});
		} else {
			if(cb)cb(false);
		}
	}
	// load_web_licence types: disclaimer / eula / privacy-policy / refunds / terms-of-service / terms-of-use
	load_web_licence(type='terms-of-service',lang=this.Language,cb?){
		this.http_get(environment.com+'/licence/?type='+type+'&lang='+lang,{},false,(l)=>{
			if(cb)cb(l.result);
		});
	}
	load_bank_img(name='',cb?){
		this.http_get(environment.com+'/logo/?type='+name,{},false,(l)=>{
			if(cb)cb(l.result);
		});
	}
	load_background(name='',cb?,high_res?){
		this.http_get(environment.com+'/'+(high_res?'background':'background_low_rez')+'/?type='+name,
			{},{'Authorization':this.AuthorizationToken+'++$'+'X-Temporary'+'=>'+this.TempToken},(l)=>{
				if(cb)cb(l.result);
		});
	}
	get_company_logo(id,cb?) {
		this.fetchOne('Companies where company_id = '+id,(c)=>{
			if(c&&c.image)this.Company_Logo = c.image;
			if(cb)cb(c&&c.image?c.image:false);
		})
	}
	setup_default_company(callback?) {
		this.b_get_setting('selected_company',(t)=>{
			if(this.verbose)console.log('selected company',t);
			if(t.option){
				this.selected_company = t.option;
				this.get_company_logo(this.selected_company);
			}
			this.create_or_find_company((r)=>{
				if(callback)callback(r);
				return r;
			});
		});
	}
	create_or_find_company(callback?) {
			this.count_companies((count) => {
			if(count>0) {
				this.set_default_company(this.selected_company,(company_id)=>{
					if(callback)callback(company_id);
					return company_id;
				})
			} else {
				var now = new Date();
				var data = {
					admin_id: 0,
					mod_id: 0,
					name: this.word.Upgrade_Title,
					image: null,
					description: this.word.The_Default_Company,
					enabled: true,
					date: now.getTime(),
					modified: now.getTime(),
					created: now.getTime()
				}
				var values = "", keys = "", i = 0, l = 0;
				Object.entries(data).forEach(([key, value]) => { if(value) l++; });	
				Object.entries(data).forEach(([key, value]) => { if(value) {
					keys=keys+key+(i==l-1?"":",");
					values=values+'"'+value+'"'+(i==l-1?"":",");
					i=i+1;
				}});
				var sql="INSERT OR REPLACE INTO Companies ("+keys+") VALUES("+values+");";
				if(this.more_verbose)console.log('INSERT SQL',sql);
				this.b_query(sql,(item)=>{
					if (!item) return;
					if (this.verbose)console.log('INSERTED INTO Companies','ID',item);
					this.set_default_company(item);
					if (callback)callback(item);
					return item;
				});
			}  
		});
	}
	set_default_company(item_id,cb?) {
		this.selected_company=item_id;
		if(this.verbose)console.log('selected_company',this.selected_company);
		this.b_save_setting('selected_company',item_id);
		this.b_view('Companies','company_id',item_id,(e)=>{
			if(e&&e[0]){
			var f = Object.entries(e[0]);
				for( let t of f) if(t[0]=='name')this.save_title(t[1]);
				if(cb)cb(item_id);
			}
		});
	}
	change_default_company(item_id,cb?) {
		this.setup_complete=false;
		this.set_default_company(item_id,()=>{
			this.dbsetup=false;
			this.create(cb);
		});
	}
	post_order_check(orders, i, cb) {
		if(this.more_verbose)console.log('post_order_check',orders);
		if(orders[i]) {
			if (orders[i].type=='print' && !this.Subscription && !this.Unlimited) {
				this.Trial = false;
				this.PrintOuts = true;
				this.Subscription = false;
				this.Unlimited = false;
				this.Purchased = 0;
				this.AvailablePrints = Number(Number(this.AvailablePrints)+Number(orders[i].cheques));
			} else if (orders[i].type == 'subscription' && !this.Unlimited) {
				this.Trial = false;
				this.PrintOuts = false;
				this.Subscription = true;
				this.Unlimited = false;
				this.Purchased = 1;
				this.AvailablePrints = Number(orders[i].cheques);
			} else if (orders[i].type=='premium') {
				this.Trial = false;
				this.PrintOuts = false;
				this.Subscription = false;
				this.Unlimited = true;
				this.modify_docu_title=true;
				this.Purchased = 1;
				this.AvailablePrints = -1;
			}
			if(i==orders.length-1){
				this.AvailablePrints = Number(this.AvailablePrints);
				if(cb)cb(orders);
			}
			if(this.more_verbose)console.log('Trial',this.Trial)
			if(this.more_verbose)console.log('PrintOuts',this.PrintOuts)
			if(this.more_verbose)console.log('Subscription',this.Subscription)
			if(this.more_verbose)console.log('Unlimited',this.Unlimited)
			if(this.more_verbose)console.log('Purchased',this.Purchased)
			if(this.more_verbose)console.log('AvailablePrints',this.AvailablePrints)
		} else {
			this.AvailablePrints = Number(this.AvailablePrints);
			if(cb)cb(orders);
		}
	}
	recheck_order(order, cb?) { 
		if(order.created<order.expiry){
			var now = new Date();
			var exp_ = new Date();
			let exp = new Date(exp_.setMonth(exp_.getMonth()+Number(order.months)));
			if(now.getTime() < exp.getTime()) {
				if(cb)cb(true);
				return true;
			} else { 
				if(cb)cb(false);
				return false;
			}
		} else { 
			if(cb)cb(false);
			return false;
		}
	}
	modify_order(order_id, state, cb?) {
		if(state==true) {
			this.activate_order(order_id, cb);
		} else {
			this.cancel_order(order_id, cb);
		}
	}
	get_purchases(callback) {
		this.b_get("Orders",(r)=>{callback(r);});
	}
	activate_order(order_id, callback?) {
		var now = new Date();
		var time = now.getTime();
		this.b_query("UPDATE Orders SET 'active' = '1', modified = '"+time+"' WHERE order_id = '"+order_id+"';",(item)=>{
			if(this.verbose)console.log('UPDATED Orders','ACTIVATE',order_id);
			if (callback)callback(item);
		});
	}
	deliver_cheque_order(callback?) {
		this.b_get("Orders WHERE 'type' = 'print' LIMIT 1",(val)=>{
			if(val){
				val=val[0];
				val.delivered=val.delivered?val.delivered+1:1;
				if(this.verbose)console.log('deliver_cheque_order',val,val.delivered);
				if(val.delivered >= val.cheques) {
					this.cancel_order(val.order_id, callback);
				} else {
					this.b_query("UPDATE Orders SET 'delivered' = "+val.delivered+" WHERE order_id = "+val.order_id+";",(item)=>{
						if (this.verbose)console.log('UPDATED Orders','delivered',item);
						if (callback)callback(item);
					});	
				}	
			}
		});
	}
	has_ordered(product, callback?) {
		this.b_get("Orders WHERE 'product' = '"+product+"'",(val)=>{
			if(val){
				val=val[0];
				if(val) {
					if (callback)callback(product);
				} else {
					if (callback)callback(false);
				}
			}
		});
	}
	cancel_order(order_id, callback?) {
		var now = new Date();
		var time = now.getTime();
		var orders = "UPDATE Orders SET 'active' = '0', modified = '"+time+"' WHERE order_id = "+order_id+";";
		this.b_query(orders,(item)=>{
			if (item) {
				if(this.verbose)console.log('UPDATED Orders','CANCELLED',item);
				if (callback)callback(item);
			}
		});
	}
	auto_remove_orders(callback?) {
		this.b_get("Orders",(orders)=>{
			if(orders.length==0&&callback)callback();
			for(var i = 0; i < orders.length; i++) { 
				if(orders[i].active=='0' || this.Autobilling_Subs==false ){
					this.recheck_order(orders[i],(order_check)=>{
						if(order_check==false){
							this.b_query("DELETE FROM Orders WHERE order_id = "+orders[i].order_id,(item)=>{
								if (item&&this.verbose)console.log('REMOVED Orders','Deleted', item);
								if (callback && i == orders.length-1)callback();
							});
						} else if (callback && i == orders.length-1)callback();
					});
				} else if (callback && i == orders.length-1)callback();
			}
		});
	}
	setup(init=true,cb?) {
		if(this.verbose)console.log("Cheques","FIRST TIME SETUP");
		if(init)this.init_settings();
		this.database_setup(cb);
		if(this.production){ // PRODUCT IN PRODUCTION USE WARNINGS:
			console.log("%c"+this.word.WARNING,"color: red; text-shadow: 1px 1px 1px black; font-size: 20px;");
			console.log("%c"+this.word.Remember,"color: transparent; text-shadow: 1px 1px 6px black; font-size: 12px;");
			console.error("%c "+this.word.Console_Warning,"font-weight:bold;font-size:16px;font-family:'Arial sans';text-shadow:2px 2px 0px rgba(0,0,0,0.3)");
			console.error("%c "+this.word.Console_Warning_2,"font-weight:bold;font-size:16px;font-family:'Arial sans';text-shadow:2px 2px 0px rgba(0,0,0,0.3)");
		}
	}
	init_settings() {
		if(this.model != 'web') this.renderMenu(this.word);
		if (this._init) return;
		if (this.more_verbose) console.log('Starting in verbose mode. Extra logging enabled.');
		this.reset_locale();
		this.auto_remove_orders(()=>{
			this.setup_orders();
		});
		this.save_title(environment.places[environment.locale].title);
		this.get_setting('log',(t)=>{
			this.LOGGING = t.option;
			if(!this.LOGGING)this.get_setting('hostname',(u)=>{
				if(u.option)environment.com=u.option;
			});
		});
		if('geolocation' in navigator) { 
			this.geofence=this.epl.geofence?true:false;
		} else {
			this.geofence=false;
		}
		this.get_default_printer();
		this.get_setting('locale',(locale)=>{
			if (locale&&locale.option) {
				this.Locale = locale.option ? locale.option : this.Locale;
				this.Language = environment.places[locale.option].language;
				this.Country=environment.places[locale.option].country;
				this.State_Province=environment.places[locale.option].state_province;
				this.City= environment.places[locale.option].city;
				this.Currency=environment.places[locale.option].currency;
				this.Title=environment.places[locale.option].title=='Cheques'?this.word.Cheques:environment.places[locale.option].title;
				if(this.model == 'web') { 
					this.load_web_language(this.Language,(str)=>{
						if(str)this.word=str;
						this.renderMenu(str);
					});
				} else {
					this.word = Languages[this.Language];
				}
			} else {
				this.reset_locale();
			}
		});
		this.get_setting('default_language',(t)=>{
			if(t&&t.option) {
				var o = t.option;
				this.Language = o;
				if(this.model == 'web') { 
					this.load_web_language(this.Language,(str)=>{
						if(str)this.word=str;
						this.renderMenu(str);
						this.insert_default_designs(str,()=>{
						},true);
						this.Title = str.Upgrade_Title;
					}); 
				} else { 
					this.word = Languages[this.Language]; 
					this.renderMenu(this.word);
					this.insert_default_designs(this.word,()=>{
					},true);
					this.Title = this.word.Upgrade_Title;
				}
				
			} else {
				this.renderMenu(this.word);
			}
		});
		this.get_setting('site_title',(t)=>{
			if(t.option){
				this.Title = t.option;
				this.Company_Name = t.option;
			}
		});
		this.get_setting('default_currency',(t)=>{
			if(t.option)this.Currency = t.option;
		});
		this.get_setting('show_docu_title',(t)=>{
			if(t.option)this.show_docu_title = t.option;
		});
		this.get_setting('setup_complete',(t)=>{
			if(t.option){
				this.setup_complete = true;
			} else {
				this.setup_complete = false;
			}
		});
		this.get_setting('default_income',(t)=>{this.DEFAULT_INCOME = t.option;});
		var today = new Date();
		var year = today.getFullYear();
		let hd = new Holidays(this.Country, this.State_Province, this.City);
		hd.setLanguages(this.Language);
		this.Holidays = hd.getHolidays(year);
		this.maxtime = Number(year)+100;
		this._init = true;
	}
	database_setup(cb?) { 
		if(this.initiated){console.log("Database already initalized.");return;}
		var e = SQLModels.Models.length;
		if(this.verbose)console.log("Database setup starting. Adding "+e+" models.");
		for (var i = 0; i<e; i++) {
			var sql = SQLModels['Create_'+SQLModels.Models[i]];
			this.query(sql);
			if(i+1==e) {
				this.count("Designs",(count)=>{
					if(!count||count==0)this.insert_default_designs();
				});
				if(this.more_verbose)console.log('Database setup success.');
				this.post_database_setup((res)=>{
					if(cb)cb(res);
					return res;
				});
			}
		}
	}
	post_database_setup(cb?) {
		this.sphincs((done)=>{
			if(this.more_verbose)console.log('SQL Setup Complete!',done);
			this.save_setting('selected_font',fonts[0].family);
			this.update_next_payroll();
			if(cb)cb(true);
			return true;
		});
	}
	sphincs(cb?) {
		this.get_setting('Version',(item) => {
			if(!item||!item.option){
				this.save_setting('Version',this.version);
				if(cb)cb(203);
			}else if(item.option<environment.version){
				var e = SQLModels.Models.length;
				var CheckQuery = [];
				var QueryKeys = []; 
				var Command = [];
				var Table = [];
				var Types = [];
				var Line = [];
				for(var i = 0; i<e; i++) {
					var s = SQLModels['Create_'+SQLModels.Models[i]];
					var j = ['CREATE TABLE IF NOT EXISTS "','" (',',',' ',');'];
					s=s.split(j[0]);
					s=s[1].split(j[1]);
					var name=s[0].split(j[2])[0];
					var k=s[1].split(j[2]);
					Table[i] = name;
					for(var v=0;v<k.length;v++){
						var key=k[v].split(j[3])[0].substr(2);
						if(!QueryKeys[i])QueryKeys[i]=[];
						QueryKeys[i].push(key);
						var split = s[1].split(',');
						Line[i]=[];
						for(var o=0;o<split.length;o++){
							var a = split[o].trim();
								a = a.replace(");","");
							Line[i].push(a);
						}
					}
					CheckQuery[i]='PRAGMA table_info('+name+');';
				}
				this.buld_queries(CheckQuery,QueryKeys,Line,Table,(a)=>{
					for (var i=0;i<a.length;i++){
						this.query(a[i],(result)=>{
							if(this.verbose)console.log(result);
						});
					}
				});
			} else {
				if(this.verbose)console.log('SQL Lite Version Set');
				this.version_out();
				if(cb)cb(202);
			}
		});
	}
	new_keys = [];
	buld_queries(queries, keys, command, table, cb?, i=0) {
		if(i==0)this.new_keys=[];
		var commands=[];
		var command_types=[];
		for(var j=0;j<command[i].length;j++){
			var d=command[i][j].split(' ');
			commands.push(d[0]);
			var g = '';
			for (var f=1; f<d.length;f++) {
				if(d[f])g = g+' '+d[f];
			}
			command_types.push(g.trim());
		}
		this.db.executeSql(queries[i],[]).then((r)=>{
			var items = []; var names = []; var types = []; var new_names = []; var new_types = [];
			for(var a = 0; a < r.rows.length; a++)items.push(r.rows.item(a));
			for(var b = 0; b < items.length; b++){new_names.push(items[b]['name']);}
			var newkeys=keys[i].filter(x=>!new_names.includes(x));
			if(newkeys.length>0){
				var cmd_types =[];
				for (var h = 0; h < newkeys.length; h++){
					newkeys[h]=newkeys[h]+' '+command_types[commands.indexOf(newkeys[h])];
					this.new_keys.push("ALTER TABLE "+table[i]+" ADD COLUMN "+newkeys[h]+";");
				}
			}
			if(i<queries.length-1){i=i+1;
				this.buld_queries(queries, keys, command, table, cb, i);
			}
			if(i==queries.length-1){
				cb(this.new_keys);
			}
		}).catch((e) =>{ console.log(e); });
	}
	version_out() {
		if(this.verbose){
			console.log('Cheques', 'V'+this.version, this.Locale, this.Language, this.Country, this.State_Province, this.City);
		}
	}
	update_next_payroll() {
		this.fetchOne('Payrolls',(cb) => {
			if(cb){
				this.Next_Payroll_Date=cb.date;
				this.Next_Payroll_Title=cb.title;
				this.Next_Payroll_ID=cb.payroll_id;
			}
		});
	}
	localize(cb?) {
		if(this.platform.is('capacitor')) {
			this.globalization.getLocaleName().then((res)=>{ 
				this.Locale=res.value; 
				if(this.verbose)console.log('Locale automatically detected:',res.value);
				if(cb)cb(this.Locale);
			}).catch((e)=>{
				if(navigator.language){
					if(this.verbose)console.log('Navigator locale used:',navigator.language);
					if(cb)cb(this.Locale);
					this.Locale=navigator.language;
				} else {
					if(this.verbose)console.log('Environment locale used:',navigator.language);
					if(cb)cb(this.Locale);
					this.Locale=environment.locale; 
				}
				if(this.more_verbose)console.log('ERROR: globalization -> getLocaleName():',e,'Default Selected:',this.Locale);
			});
		} else {
			this.get_locale((l)=>{
				if(l.locale){
					this.Locale=l.locale;
					if(cb)cb(this.Locale);
				} else if (navigator.language) {
					this.Locale=navigator.language;
					if(cb)cb(this.Locale);
				} else {
					this.Locale=environment.locale; 
					if(cb)cb(this.Locale);
				}
			})
		}
	}
	async electron_app_ready() {
		if(this.more_verbose)console.log("Electron App Ready");
		await this.app_controller.ipcRenderer.send('appReady',{db:true});
	}
	random(l=1) {
		return this.random_number(l);
	}
	random_number(l=1) { 
		var x=''; 
		for (var i = 0; i < l; i++) {
			x += this.numo.charAt(Math.floor(Math.random() * this.numo.length)); 
		}
		return x; 
	}
	click(e?) {
		if (this.locked)this.hapticsImpactLight();
		if (this.LOGGING&&this.more_verbose) console.log('LOGGED','click(e?)',e);
		var l=this._log.length?this._log.length:0;
		if(e)this._log[l]=e;
		this.on=true;
	}
	phone(tel,key?) {
		if(key==8)return;
		var num = tel.replace(/\D/g,''); 
		if(num.length<=10) {
		return '(' + num.substring(0,3) + ') ' + num.substring(3,6) + '-' + num.substring(6,10); 
		} else { 
		return '+' +num.substring(0,1) + ' (' + num.substring(1,4) + ') ' + num.substring(4,7) + '-' + num.substring(7,num.length);
		}
		var a; for( let u = 0; u < 3; u++ ) for( let h = 24; h > 0; h-- ) for( let g = 4; g > 0; g-- ) a.push(100-(g*h+u));
	}
	amount(num, key?, currency?) {
		if (!num) return;
		if (key=="Backspace")return;
		var places = currency?this.Currency_Rates[currency].decimal_places:this.Places;
		return Number(num).toFixed(places);
	}
	percentage(p) {
		return p.toFixed(2); // (
	}
	getCookie(name) {
        let ca: Array<string> = document.cookie.split(';');
        let caLen: number = ca.length;
        let cookieName = `${name}=`;
        let c: string;
        for (let i: number = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }
    cookie_service(title, cookie, exp=13000) {
    	if(exp) this.cookieService.set(title, cookie, {expires: exp});
    	if(!exp)this.cookieService.set(title, cookie);
    }
	remove_temporary(key) {
		localStorage.removeItem(key);
	}
	set_temporary(key, value, ttl=13000) {
		const now = new Date();
		const item = {
			value: value,
			expiry: now.getTime() + ttl,
		}
		localStorage.setItem(key, JSON.stringify(item));
	}
	get_temporary(key,c?,ex?) {
		const itemStr = localStorage.getItem(key);
		if (!itemStr) {
			if(c)c(false);
			return null;
		}
		const item = JSON.parse(itemStr);
		const now = new Date();
		if (now.getTime() > item.expiry) {
			localStorage.removeItem(key);
			if(c)c(false);
			return null;
		}
		if(c)c(item.value);
		return (ex)?item.expiry:item.value;
	}
	async device_unique_identifier(cb?) {
		if(this.ID&&cb){
			cb(this.ID);
		} else {
			if(this.platform.is('capacitor')) {
				this.uniqueDeviceID.get().then((uuid: any) => {
					if(this.more_verbose) console.log(uuid);
					this.ID=uuid;
					if(cb)cb(uuid);
				}).catch((error: any)=>{
					if(this.verbose) console.error('DEVICE ID ERROR',error);
					var now = Math.round(Date.now()/1000);
					this.ID=false;
					if(cb)cb(false);
				});
			} else if (this.platform.is('electron')){
				this.app_controller.ipcRenderer.on('uuid',(event,info)=>{this.zone.run(()=>{
					this.ID=info;
					if(cb)cb(info);
				})});
				await this.app_controller.ipcRenderer.send('uuid');
			} else {
				this.get_temporary('authorization',(ls)=>{
					this.AuthorizationToken=ls?ls:this.cookieService.get('authorization');
					if(this.AuthorizationToken==''||this.AuthorizationToken=='500') {
						this.logged_in = false;
						if(cb)cb(false);
					} else if (this.AuthorizationToken) {
						this.logged_in = true;
						if(cb)cb(this.AuthorizationToken);
					} 
				},1234);
			}
		}
	}
	authorize_admin(id,password) {
		let pwd = btoa(password);
		this.query("SELECT admin_id FROM Administrators WHERE username = "+id+" AND password = "+pwd+';',(admin)=>{
			if(this.verbose) console.log('authorize_admin: ',admin);
			if (admin) {
				var auth_key = '';
				var now = Math.round(Date.now()/1000);
				// this.authorize(admin,0,now,0,1,auth_key);
			}
		});
	}
	authorize_moderator(id,password) {
		let pwd = btoa(password);
		this.query("SELECT mod_id FROM Moderators WHERE username = "+id+" AND password = "+pwd+';',(mod)=>{
			if(this.verbose) console.log('authorize_moderator: ',mod);
			if (mod) {
				var auth_key = '';
				var now = Math.round(Date.now()/1000);
				// this.authorize(admin,0,now,0,1,auth_key)
			}
		});
	}
	authorize_employee(id,password) {
		let pwd = btoa(password);
		this.query("SELECT employee_id FROM Employees WHERE username = "+id+" AND password = "+pwd+';',(employee)=>{
			if(this.verbose) console.log('authorize_employee: ',employee);
			if(employee) {
				var auth_key = '';
				var now = Math.round(Date.now()/1000);
				// this.authorize(admin,0,now,0,1,auth_key);
			}
		});
	}
	get_role(cb) {
		if(cb) cb(this.UserRole);
	}
	http_upload(uri, formData: FormData, header?, callback?) {
		this.http_post(uri, formData, header, callback);
	}
	http_post(uri, params, header?, callback?) {
		if(!header)header={'Authorization':this.AuthorizationToken+'++$'+'X-Temporary'+'=>'+this.TempToken};
		if(this.platform.is('capacitor')) {
			this.ionic_http.post(uri,params,header).then((r)=>{
				if(callback) callback(r);
				return true;
			}).catch(e=>{
				if(this.verbose) console.error('HTTP POST Error ',e);
				if(callback) callback(false);
			});
		} else if (this.platform.is('electron')) {
			//-- NOT PACKAGED IN THIS VERSION --
			// var u = new URL(uri);
			// var request = net.request({ // ELECTRON MODE
			// 	method: 'POST',
			// 	protocol: u.protocol,
			// 	hostname: u.hostname,
			// 	port: u.protocol=='https:'?443:80,
			// 	path: u.pathname
			// })
			// request.on('response', (response) => {
			// 	response.on('data', (chunk) => {
			// 		if(callback) callback(chunk)
			// 		console.log(`HTTP Request: ${chunk}`)
			// 		return true;
			// 	});
			// });
			// request.on('error', (error) => {
			// 	console.error(`${JSON.stringify(error)}`);
			// 	return false;
			// });
			// request.setHeader('Content-Type', 'application/json');
			// request.end();
		} else {
			var headers = {'Authorization':this.AuthorizationToken+'++$'+'X-Temporary'+'=>'+this.TempToken};
			this.angular_http.post<any>(uri,params,{headers}).subscribe((r)=>{
				if(callback)callback(r);
				return true;
			});
		}
	}
	http_get(uri, params, header?, callback?) {
		if(!header)header={'Authorization':this.AuthorizationToken+'++$'+'X-Temporary'+'=>'+this.TempToken};
		if(this.platform.is('capacitor')) {
			this.ionic_http.get(uri,params,header).then((r)=>{ // DEVICE MODE
				if(callback) callback(r);
				return true;
			}).catch(e=>{
				if(this.verbose) console.error('HTTP GET Error ',e);
				if(callback) callback(false);
				return false;
			});
		} else if (this.platform.is('electron')) {
			//-- NOT PACKAGED IN THIS VERSION --
			// var u = new URL(uri);
			// var request = net.request({ // ELECTRON MODE
			// 	method: 'GET',
			// 	protocol: u.protocol,
			// 	hostname: u.hostname,
			// 	port: u.protocol=='https:'?443:80,
			// 	path: u.pathname
			// })
			// request.on('response', (response) => {
			// 	response.on('data', (chunk) => {
			// 		if(callback) callback(chunk)
			// 		console.log(`HTTP Request: ${chunk}`)
			// 		return true;
			// 	});
			// });
			// request.on('error', (error) => {
			// 	console.error(`${JSON.stringify(error)}`);
			// 	return false;
			// });
			// request.setHeader('Content-Type', 'application/json');
			// request.end();
		} else {
			this.angular_http.get<any>(uri).subscribe((r)=>{
				if(r)callback(r);
				return true;
			});
		}
	}
	line_create(q,callback?) {
		if(!environment.com)return;
		this.http_post(environment.com+'/create/', { que:q },
			{ 'Authorization':this.AuthorizationToken },(l)=>{
				if(l.error) {
					if(this.verbose)console.error((this.more_verbose)?l:l.error);
					if(callback)callback(false);
				} else {
					if(this.verbose)console.log('SQL Line Query Return ',l);
					if(callback)callback(l);
				}
		});
	}
	line_b_create(q,callback?) {
		if(!environment.com)return;
		this.http_post(environment.com+'/create_2/', { que:q },
			{ 'Authorization':this.AuthorizationToken },(l)=>{
				if(l.error) {
					if(this.verbose)console.error((this.more_verbose)?l:l.error);
					if(callback)callback(false);
				} else {
					if(this.verbose)console.log('SQL Line Query Return ',l);
					if(callback)callback(l);
				}
		});
	}
	line_query(q,callback?) {
		if(!environment.com)return;
		if(this.more_verbose)console.log('SQL Line Query ',q);
		this.http_post(environment.com+'/query/', { que:q },
			{ 'Authorization':this.AuthorizationToken },(r)=>{
			if(r.error) {
				if(this.verbose)console.error('Line Query Error',r,q);
				if(callback)callback(false);
			} else {
				if (r.result) {
					if (r.result.insertId&&callback) callback(r.result.insertId);
					var rows  = r.result.rows;
					var count = rows?rows.length:0;
					if (count > 0) {
						if (this.more_verbose) console.log('line query returns',count,rows);
						if    (callback) callback(rows);
					} else if (callback) callback([]);
				} else {
					if(this.more_verbose)console.error(r.error);
					if(callback)callback(false);
				}
			}
		});
	}
	line_b_query(q,callback?) {
		if(!environment.com)return;
		if(this.more_verbose)console.log('SQL Line Query ',q);
		this.http_post(environment.com+'/product/', { que:q },
			{ 'Authorization':this.AuthorizationToken },(r)=>{
				if(r.error) {
					if(this.verbose)console.error('Line2 Query Error',r,q);
					if(callback)callback(false);
				} else {
					if (r.result) {
						if (r.result.insertId&&callback) callback(r.result.insertId);
						var rows  = r.result.rows;
						var count = rows?rows.length:0;
						if (count > 0) {
							if (this.more_verbose) console.log('line query returns',count,rows);
							if    (callback) callback(rows);
						} else if (callback) callback([]);
					} else {
						if(this.more_verbose)console.error(r.error);
						if(callback)callback(false);
					}
				}
		});
	}
	line_count(Table,callback?,a=1) {
		let q = "SELECT * FROM "+Table+';';
		if (this.more_verbose) console.log('SQL Line Count ',q);
		if (!environment.com) return;
		this.http_post(environment.com+'/query/', { que:q }, 
			{'Authorization':this.AuthorizationToken},(r)=>{
				var count = 0;
				if( r && r.result && r.result.rows ) {
					count = r.result.rows.length;
				}
				if(r.error) {
					if (this.verbose) console.error('Line count error',r,q);
					if (callback) callback(0);
				} else {
					callback(count?count:0);
				}
			});
	}
	line_get(Table,callback?,a=1) {
		let q = "SELECT * FROM "+Table+';';
		if (this.more_verbose) console.log('SQL Line Query ',q);
		if(!environment.com)return;
		this.http_post(environment.com+'/query/', {  que:q }, 
			{'Authorization':this.AuthorizationToken},(r)=>{
				if(r.error) {
					if(this.more_verbose)console.error(r);
					if(callback)callback([]);
				} else {
					if (r.result) {
						if (r.result.insertId&&callback) callback(r.result.insertId);
						var rows  = r.result.rows;
						var count = rows?rows.length:0;
						if (count > 0) {
							if (this.more_verbose) console.log('line query returns',count,rows);
							if    (callback) callback(rows);
						} else if (callback) callback([]);
					} else {
						if(this.more_verbose)console.error(r.error);
						if(callback)callback([]);
					}
				}
		});
	}
	line_b_get(Table,callback?,a=1) {
		let q = "SELECT * FROM "+Table+';';
		if (this.more_verbose) console.log('SQL Line Query ',q);
		if(!environment.com)return;
		this.http_post(environment.com+'/product/', {  que:q }, 
			{'Authorization':this.AuthorizationToken},(r)=>{
				if(r.error) {
					if(this.verbose)console.error(r);
					if(callback)callback([]);
				} else {
					if (r.result) {
						if (r.result.insertId&&callback) callback(r.result.insertId);
						var rows  = r.result.rows;
						var count = rows?rows.length:0;
						if (count > 0) {
							if (this.more_verbose) console.log('product query returns',count,rows);
							if    (callback) callback(rows);
						} else if (callback) callback([]);
					} else {
						if(this.more_verbose)console.error(r.error);
						if(callback)callback([]);
					}
				}
		});
	}
	query(q,callback?,create?) {
		if (this.elec) {
			this.electron_query(q,(r)=>{
				if(this.verbose)console.log('Electron result',r)
				if (r.insertId && callback) callback(r.insertId);
				var count = r.rows?r.rows.length:0;
				var item = [];
				if(count>0) {
					item = r.rows;
					if(this.more_verbose)console.log('Electron Row',item)
					// for (var i = 0; i < 1; i++) {
					// 	console.log('Electron Row',item)
					// 	if(this.more_verbose) console.log('SQL Electron Result ', item);
					// }
				} else {
					if(callback) callback(item);	
				}
			});
		} else if (this.line) {
			this[create?'line_create':'line_query'](  q  , callback );
		} else {
			if(!this.db) {
				console.warn('Could not query.', 'No database connected.');
				return;
			} else {
				if(this.more_verbose)console.log('SQL Query ',q);
				this.db.executeSql(q,[]).then((r)=>{
					if (r.insertId && callback) callback(r.insertId);
					var count = r.rows?r.rows.length:0;
					for (var i =0; i<1; i++) {
						var item=r.rows.item(i);
						if(this.more_verbose)console.log('SQL Result ', item);
					}
					if(callback)callback(item);
				}).catch((e)=>{
					console.error('SQL query Error ',q,e);
					if(callback)callback(false);
				});
			}
		}
	}
	b_query(q,callback?,create?) {
		if(this.elec){
			this.electron_query(q,(r)=>{
				if(this.verbose)console.log('Electron result',r)
				if (r.insertId && callback) callback(r.insertId);
				var count = r.rows?r.rows.length:0;
				var item = [];
				if(count>0) {
					item = r.rows;
					if(this.more_verbose)console.log('Electron Row',item)
					// for (var i = 0; i < 1; i++) {
					// 	console.log('Electron Row',item)
					// 	if(this.more_verbose) console.log('SQL Electron Result ', item);
					// }
				} else {
					if(callback) callback(item);	
				}
			},true);
		} else if(this.line){
			this[create?'line_b_create':'line_b_query'](  q  , callback );
		} else {
			if(!this.db2)return;
			if(this.more_verbose)console.log('SQL Query ',q);
			this.db2.executeSql(q, []).then((r)=>{
				if(this.more_verbose)console.log('b_query',r);
				if (r.insertId && callback) callback(r.insertId);
				var count = r.rows?r.rows.length:0;
				for (var i = 0; i < 1; i++) {
					var item = r.rows.item(i);
					if(this.more_verbose) console.log('SQL Result ', item);
				}
				if(callback) callback(item);
				return true;
			}).catch((e) => {
				console.error('SQL query Error ',q,e);
				if(callback) callback([]);
				return false;
			});
		}
	}
	add( table, data, callback? ) {
		if(!table||!data)return false;
		var values = "", keys = "", i = 0, l = 0;
		Object.entries(data).forEach(([key, value]) => { if(value) l++; });	
		Object.entries(data).forEach(([key, value]) => { if(value) {
			keys=keys+key+(i==l-1?"":",");
			values=values+'"'+value+'"'+(i==l-1?"":",");
			i=i+1;
		}});
		var sql="INSERT OR REPLACE INTO "+table+" ("+keys+") VALUES("+values+");";
		if(this.more_verbose)console.log('INSERT SQL',sql);
		this.query(sql,(item)=>{
			if (this.verbose)console.log('INSERTED INTO',table,'ID',item);
			if (callback)callback(item);
		});
	}
	b_add( table, data, callback? ) {
		if(!table||!data)return false;
		var values = "", keys = "", i = 0, l = 0;
		Object.entries(data).forEach(([key, value]) => { if(value) l++; });	
		Object.entries(data).forEach(([key, value]) => { if(value) {
			keys=keys+key+(i==l-1?"":",");
			values=values+'"'+value+'"'+(i==l-1?"":",");
			i=i+1;
		}});
		var sql="INSERT OR REPLACE INTO "+table+" ("+keys+") VALUES("+values+");";
		if(this.more_verbose)console.log('INSERT SQL',sql);
		this.b_query(sql,(item)=>{
			if (this.verbose)console.log('INSERTED INTO',table,'ID',item);
			if (callback)callback(item);
		});
	}
	addMany( table, items, callback? ) {
		if(!table||!items)return false;
		var values = "", val = "", keys = "", i = 0, l = 0;
		for (var j = 0; j < items.length; ++j) {
			val = "";
			Object.entries(items[j]).forEach(([key, value]) => { if(value) l++; });	
			Object.entries(items[j]).forEach(([key, value]) => {
				if(value) {
					if(j==0)keys=keys+key+(i==l-1?"":",");
					val=val+'"'+value+'"'+(i==l-1?"":",");
					i=i+1;
				}
			});
			values = values+'('+val+')';
			if(items.length-1!=j) values=values+','; 
		}
		var sql = "INSERT OR REPLACE INTO "+table+" ("+keys+") VALUES "+values+";";
		if (this.more_verbose) console.log('INSERT SQL',sql);
		this.query(sql,(item)=>{
			if (this.verbose)console.log('INSERTED INTO',table,'ID',item);
			if (callback)callback(item);
		});
	}
	b_addMany( table, items, callback? ) {
		if(!table||!items)return false;
		var values = "", val = "", keys = "", i = 0, l = 0;
		for (var j = 0; j < items.length; ++j) {
			val = "";
			Object.entries(items[j]).forEach(([key, value]) => { if(value) l++; });	
			Object.entries(items[j]).forEach(([key, value]) => {
				if(value) {
					if(j==0)keys=keys+key+(i==l-1?"":",");
					val=val+'"'+value+'"'+(i==l-1?"":",");
					i=i+1;
				}
			});
			values = values+'('+val+')';
			if(items.length-1!=j) values=values+','; 
		}
		var sql = "INSERT OR REPLACE INTO "+table+" ("+keys+") VALUES "+values+";";
		if (this.more_verbose) console.log('INSERT SQL',sql);
		this.b_query(sql,(item)=>{
			if (this.verbose)console.log('INSERTED INTO',table,'ID',item);
			if (callback)callback(item);
		});
	}
	update( Table, Data, cb?) {
		var Keys = "";
		for(var i=0; i < Data.length; i++) {
			Keys = Keys + Data[i].key()+" "+Data[i]+", ";
		}
		return this.query("UPDATE "+Table+" SET "+Keys+';', (id)=>{if(cb)cb(id);});
	}
	b_update( Table, Data, cb?) {
		var Keys = "";
		for(var i=0; i < Data.length; i++) {
			Keys = Keys + Data[i].key()+" "+Data[i]+", ";
		}
		return this.b_query("UPDATE "+Table+" SET "+Keys+';', (id)=>{if(cb)cb(id);});
	}
	updateObj( Table, Data, callback?,force?) {
		if(!Table||!Data)return false;
		var values = "", keys = "", i = 0, l = 0, Model="", Model_ID: any;
		Object.entries(Data).forEach(([key, value])=>{if(value||force){l++;}});
		Object.entries(Data).forEach(([key, value])=>{
			if(value||force) {
				if(force)if(value==null||value==false||!value)value=0;
				if(i==0){
					Model=key;
					Model_ID=value;
				} else { 
					values=values+key+' = "'+value+'"'+(i==l-1?"":", ");
				}
				i=i+1;
			}
		});
		var sql = "UPDATE "+Table+" SET "+values+" WHERE "+Model+" = "+Model_ID+';';
		// if(this.verbose) console.log('UPDATE SQL',sql);
		this.query(sql,(item)=>{
			if (callback)callback(item);
		});
		// var Keys = "";
		// for(var i=0; i < Data.length; i++) {
		// 	Keys = Keys + Data[i].key()+" "+Data[i]+", ";
		// }
		// return this.query("UPDATE "+Table+" SET "+Keys, (id)=>{if(cb)cb(id);});
	}
	set( Table, Model, ID, Key, Value, cb?) {
		if(!ID){console.error('MISSING',Table,'ID');return;}
		var r = "UPDATE "+Table+" SET "+Key+" = '"+Value+"' WHERE "+Model+" = "+ID+';';
		return this.query(r, (id)=>{if(cb)cb(id);});
	}
	b_set( Table, Model, ID, Key, Value, cb?) {
		if(!ID){console.error('MISSING',Table,'ID');return;}
		var r = "UPDATE "+Table+" SET "+Key+" = '"+Value+"' WHERE "+Model+" = "+ID+';';
		return this.b_query(r, (id)=>{if(cb)cb(id);});
	}
	set_all( Table, Key, Value, cb?) {
		return this.query("UPDATE "+Table+" SET "+Key+" = '"+Value+"';", (id)=>{if(cb)cb(id);});
	}
	count(Table,cb?,a=1) {
		if(this.elec){
			this.electron_count(Table,cb,a)
		} else if (this.line) {
			this.line_count(Table,cb,a);
		} else if (!this.db) {
			if(a>10)return false;
			if (this.more_verbose) console.log('SQL waiting to connect... Attempt '+a+' to reconnect.');
			setTimeout(()=>{this.count(Table,cb,a+1);return;},600);
		} else {
			let q = "SELECT * FROM "+Table+';';
			if (this.more_verbose) console.log('SQL Count ',q);
			this.db.executeSql(q,[]).then((r)=>{
				if (this.more_verbose) console.log('Count ',r);
				if (this.more_verbose) console.log('SQL '+Table+' Count ', r.rows.length);
				if (cb) cb(r.rows.length);
				return r.rows.length;
			}).catch((e) => {
				if (this.more_verbose) console.error('SQL count Error ',q,e);
			});
		}
	}
	remove(Table) {
		var sql = this.query("DELETE FROM "+Table+';');
		if (this.verbose) console.log(sql);
		return sql;
	}
	delete(Table,Model,ID, cb?) {
		var sql = this.query("DELETE FROM "+Table+" WHERE "+Model+" = "+ID+';');
		if (this.verbose) console.log(sql);
		if (cb)cb(sql);
		return sql;
	}
	b_delete(Table,Model,ID, cb?) {
		var sql = this.b_query("DELETE FROM "+Table+" WHERE "+Model+" = "+ID+';');
		if (this.verbose) console.log(sql);
		if (cb)cb(sql);
		return sql;
	}
	get(Table,cb?,a=1):any {
		if(this.elec){
			this.electron_get(Table,cb,a)
		} else if (this.line) {
			this.line_get(Table,cb,a);
		} else if (!this.db) {
			if(a>10)return false;
			if (this.more_verbose) console.log('SQL waiting to connect... Attempt '+a+' to reconnect.');
			setTimeout(()=>{this.get(Table,cb,a+1);return;},600);
		} else {
			let q = "SELECT * FROM "+Table+';';
			if (this.more_verbose) console.log('SQL Query ', q);
			this.db.executeSql(q, []).then((r) => {
				var items = [];
				for (var i = 0; i < r.rows.length; i++) {
					var item = r.rows.item(i);
					if (this.more_verbose) console.log('SQL Result ', item);
					items.push(item);
				}
				if (this.more_verbose) console.log('SQL Results ', items);
				if (cb) cb(items);
				return items;
			}).catch((e) => {
				console.error('SQL get Error ',q,e);
			});
		}
	}
	b_get(Table,cb?,a=1):any { 
		if(this.elec){
			this.electron_get(Table,cb,a)
		} else if (this.line) {
			this.line_b_get(Table,cb,a);
		} else if (!this.db2) {
			if(a>10)return false;
			if (this.more_verbose) console.log('SQLB waiting to connect... Attempt '+a+' to reconnect.');
			setTimeout(()=>{this.get(Table,cb,a+1);return;},600);
		} else {
			let q = "SELECT * FROM "+Table+';';
			if (this.more_verbose) console.log('SQLB Query ', q);
			this.db2.executeSql(q, []).then((r) => {
				var items = [];
				for (var i = 0; i < r.rows.length; i++) {
					var item = r.rows.item(i);
					if (this.more_verbose) console.log('SQL Result ', item);
					items.push(item);
				}
				if (this.more_verbose) console.log('SQL Results ', items);
				if (cb) cb(items);
				return items;
			}).catch((e) => {
				console.error('SQL get Error ',q,e);
			});
		}
	}
	fetchMany(tbl,item,data,cb){
		if(!data || !tbl || !item) {
			if(cb)cb(false);
			return false;
		} else {
			let q = "SELECT DISTINCT STUFF((\
				SELECT ',' + "+data+"\
				FROM "+tbl+" T\
				WHERE T."+item+" = "+item+"\
				ORDER BY T."+item+"\
				FOR XML PATH('')\
			),1,1,'') AS datalist FROM "+tbl+" GROUP BY "+item+';';
			if(this.verbose)console.log('SQL Query ', q);
			this.query(q,(result)=>{
				if(cb)cb(result);
				return result;
			});
		}
	}
	fetch(tbl,cb){this.get(tbl+' ORDER BY created DESC',(r)=>{if(cb)cb(r);});}
	b_fetch(tbl,cb){this.b_get(tbl+' ORDER BY created DESC',(r)=>{if(cb)cb(r);});}
	fetchOne(tbl,cb){this.get(tbl+' ORDER BY created DESC LIMIT 1',(r)=>{if(cb)cb(r[0]);});}
	b_fetchOne(tbl,cb){this.b_get(tbl+' ORDER BY created DESC LIMIT 1',(r)=>{if(cb)cb(r[0]);});}
	view(tbl,mdl,id,cb){if(id==-1){cb(false);}this.get(tbl+" WHERE "+mdl+" = "+id,(r)=>{if(cb)cb(r);});}
	b_view(tbl,mdl,id,cb){if(id==-1){cb(false);}this.b_get(tbl+" WHERE "+mdl+" = "+id,(r)=>{if(cb)cb(r);});}
	encode(word){if(!word||word==null||word=='')return '';
		var w='';
		for (var i = 0; i < word.length; ++i) w=w+''+EncodingKeys[word[i]].a+'||';
		var a = btoa(w);
		return a?a:'';
	}
	base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
	unencode(word){if(!word||word==null||word=='')return '';
		if(!this.base64regex.test(word)) return word;
		var decoded = atob(word);
		var split = decoded.split('||'), w = '';
		for( let s of split ){
			var d = s.replace('||','');
			d = d.replace(/p/g,'q').replace(/t/g,'i').replace(/s9/g,'a!').replace(/rh/g,'Ren').replace(/rh/g,'Ren').replace(/hc/g,'Rh').replace(/C/g,'Cana').replace(/#\)/g,'->|*|');
			if(DecodingKeys[d])w=w+''+DecodingKeys[d].a;
		}
		return w?w:'';
	}
	async electron_query(q,cb?,b?) {
		var qn = this.random_data();
		this.app_controller.ipcRenderer.on('query'+qn, (event, query) => {this.zone.run(() => {
			query = JSON.parse(query);
			if(this.verbose)console.log('Electron Query',qn,' In: ', query);
			if(cb)cb(query);
			return query;
		})});
		await this.app_controller.ipcRenderer.send('query',{query:q,name:qn,b:b});
		if(this.verbose)console.log('Electron '+(b?'B':'')+' Query Out: ', {query:q,name:qn,b:b});
	}
	async electron_count(Table,cb?,a=1,b?) {
		var q = "SELECT * FROM "+Table+';';
		var qn = this.random_data();
		if(!environment.com)return;
		this.app_controller.ipcRenderer.on('count'+qn, (event, count) => {this.zone.run(() => {
			if(!count)count=0
			if (cb) cb(count);
			return count;
		})});
		await this.app_controller.ipcRenderer.send('count',{query:q,name:qn,b:b});
		if(this.verbose)console.log('Electron '+(b?'B':'')+' Count Out: ', {query:q,name:qn,b:b});
	}
	async electron_get(Table,cb?,a=1,b?) {
		var q = "SELECT * FROM "+Table+';';
		var qn = this.random_data();
		if (this.verbose) console.log('SQL Electron Query ',q);
		this.app_controller.ipcRenderer.on('query'+qn, (event, query) => {this.zone.run(() => {
			query = JSON.parse(query);
			if(this.verbose)console.log('Electron',qn,' Query', query);
			if(cb)cb(query);
			return query;
		})});
		await this.app_controller.ipcRenderer.send('query',{query:q,name:qn,b:b});
		if(this.verbose)console.log('Electron '+(b?'B':'')+' Get Out: ', {query:q,name:qn,b:b});
	}
	random_data(l=10){return Array.from(Array(l), () => Math.floor(Math.random() * 36).toString(36)).join('');}
	async read_file(filePath,cb?) {
		const readFile = await Filesystem.readFile({
			path: filePath
		});
		if(cb)cb(readFile);
		return readFile;
	}
	/**
	/* FILE PICKERS
	/* Open a device based file selector.
	**/
	async select_file(b?) {
		if(this.platform.is('capacitor')) {
			if(this.platform.is('ios')) {
				this.filePicker.pickFile().then((filePath) =>{
					this.read_file(filePath,(readFile)=>{
						var uri = `data:image/jpeg;base64,${readFile}`;
						if(this.verbose) console.log('FILE SELECTED',uri);
						if(b)b(uri);
						return uri;
					});
				}).catch(noFiles => {return false;});
			} else {
				this.fileChooser.open().then((filePath) => {
					this.read_file(filePath,(readFile)=>{
						var uri = `data:image/jpeg;base64,${readFile}`;
						if(this.verbose) console.log('FILE SELECTED',uri);
						if(b)b(uri);
						return uri;
					});
				}).catch((e) => {
					if(this.verbose) console.error(e);
				});
			}
		} else if (this.platform.is('electron')) {
			console.error('No Electron File Selector Installed!!!');
			if(b)b(false);
			return false;
		} else {
			console.error('No Web File Selector Installed!!!');
			if(b)b(false);
			return false;
		}
	}
	select_folder(b?) {
		this.filePicker.pickFile('.folder').then((uri) =>{
			if(this.verbose) console.log('FOLDER SELECTED',uri);
			if(b)b(uri);
			return uri;
		}).catch((e) => { if(this.verbose) console.error('Error selecing folder.',e); });
	}
	select_image(b?,width?,height?,quality?) {
		if(this.platform.is('capacitor')) {
			var options = {maximumImagesCount:1,outputType:1,width:width,height:height,quality:quality}
			this.imagePicker.getPictures(options).then((results) => {
			  if(b)b(results); 
			}, (err) => { });
		} else if (this.platform.is('electron')) {  
			console.error('No Electron File Selector Installed!!!');
			if(b)b(false); 
			return false;
		} else {
			console.error('No Web File Selector Installed!!!');
			if(b)b(false);
			return false;
		}
	}
	select_images(b?,n=10,width?,height?,quality?) { 
		if(this.platform.is('capacitor')) {
			var options = {maximumImagesCount:n,outputType:1,width:width,height:height,quality:quality}
			this.imagePicker.getPictures(options).then((results) => {
			  if(b)b(results);
			}, (err) => { });
		} else if (this.platform.is('electron')) {
			console.error('No Electron File Selector Installed!!!');
			if(b)b(false);
			return false;
		} else {
			console.error('No Web File Selector Installed!!!');
			if(b)b(false);
			return false;
		}
	}
	async view_files(path=this.file.dataDirectory,dir='.') {
		if(!path||!dir)return;
		await this.platform.ready();
		this.file.listDir(path,dir).then(existingFiles=>{
			if(this.verbose) console.log('LIST DIRECTORY',existingFiles);
			return existingFiles;
		}).catch(noFiles=>{return []; });
	}
	async save_file(data,filename,type) {
		const result = await Filesystem.writeFile({
			path: filename,
			data: btoa(data),
			directory: FilesystemDirectory.Documents
		  });
		this.fileOpener.showOpenWithDialog(result.uri, type)
	}
	async create_file(text: string,filename: string,path:any=this.file.dataDirectory,cb?) {
		if(!text||!filename)return;
		await this.platform.ready();
		this.file.writeFile(path, filename, text, {replace:false}).then((fileWritten)=>{
			if(this.verbose)console.log('FILE '+(false?'REPLACED':'WRITTEN'),filename, this.file.dataDirectory+filename);
			if(cb)cb(this.file.dataDirectory+filename);
			return this.file.dataDirectory+filename;
		}).catch((noFileWritten)=>{
			if(cb)cb(false);
			return false;
		});
	}
	async copy_file(file?,destfile?,path=this.file.dataDirectory,destpath=this.file.dataDirectory) {
		if(!file||!destfile)return;
		await this.platform.ready();
		this.file.checkFile(path, file).then(existingFileDoesExist=>{
			this.file.checkFile(destpath,destfile).then(newFileToReplace=>{
				if(destfile=prompt('Do you want to rename or replace this file?',destfile)) {
					this.file.copyFile(path, file, destpath, destfile).then(fileCopied=>{
						if(this.verbose) console.log('FILE COPIED',path, file, destpath, destfile);
						return [destpath, destfile];
					}).catch(noNewFile=>{return false;});
				}
			}).catch(readyToWrite=>{
				this.file.copyFile(path, file, destpath, destfile).then(fileCopied=>{
					if(this.verbose) console.log('FILE COPIED',path, file, destpath, destfile);
					return [destpath, destfile];
				}).catch(noNewFile=>{return false;});
			});
		}).catch(noExistingFile=>{return false;});
	}
	async write_file(text: string,filename: string,repl?: boolean,b?) {
		await this.platform.ready();
		this.file.writeFile(this.file.dataDirectory, filename, text, {replace:repl}).then(fileWritten=>{
			if(this.verbose)console.log('FILE '+(repl?'REPLACED':'WRITTEN'),filename, this.file.dataDirectory+filename);
			if(b)b(this.file.dataDirectory+filename);
			return this.file.dataDirectory+filename;
		}).catch(noFileWritten=>{
			if(b)b(false);
			return false;
		});
	}
	setup_ftp_backup(f?,t?,p?) {
		this.BACKUP_AUTO=f?f:false;
		this.BACKUP_PROVIDER=(t?"S":"")+"FTP";
		this.BACKUP_TYPE=f?"FTP":null;
		this.BACKUP_FTP=!p?"0":"1";
		this.save_setting('BACKUP_FTP',		 this.BACKUP_AUTO);
		this.save_setting('BACKUP_TYPE',	 this.BACKUP_PROVIDER);
		this.save_setting('BACKUP_PROVIDER', this.BACKUP_TYPE);
		this.save_setting('BACKUP_AUTO',	 this.BACKUP_FTP);
	}
	run_ftp_backup(ftp) {
		var URI = (ftp.secure?'sftp':'ftp')+ftp.host+(ftp.port?ftp.host:'');
		// this.ftp.connect(URI,ftp.user,ftp.pass).then((res:any)=>{
		// 		if(this.verbose) console.log('FTP LOGIN', res);
		// }).catch((error: any) => {console.error(error)});
		console.error('FTP is not yet implemented!');
	}
	setup_country(c?,s?,i?) {
		if(c)this.Country=c;
		if(s)this.State_Province=s;
		if(i)this.City=i;
		if(c)this.save_setting('Country',c);
		if(s)this.save_setting('State_Province',s);
		if(i)this.save_setting('City',i);
	}
	setup_language(lang,save=true,cb?) {
		this.Language = lang;
		if(this.model=='web'){
			this.load_web_language(this.Language,(l)=>{
				if(l)this.word=l;
				this.setup_language_complete(save,cb);	
			});
		} else {
			this.word = Languages[this.Language];
			this.setup_language_complete(save,cb);
		}
	}
	setup_language_complete(save=true,cb?) {
		this.renderMenu(Languages[this.Language]);
		if (save) this.save_setting('default_language',this.Language);
		this.insert_default_designs(this.word,()=>{
			this.Title = this.word.Upgrade_Title;
			if(this.verbose)console.log('Set Language:',this.Language);
			if(cb)cb();
		},true);
	}
	save_title(t) {
		if(!t) return;
		this.Title=t;
		this.save_setting('site_title',t);
	}
	save_currency(t) {
		if(!t) return;
		this.Currency=t;
		this.save_setting('default_currency',t);
	}
	save_setting(title,opt,cb?) {
		if(!opt)return;
		this.query('DELETE FROM Settings WHERE admin_id = '+this.admin_id+' AND title = "'+title+'";',(D)=>{
			this.query('INSERT OR REPLACE INTO Settings (admin_id, title, option) VALUES("'+this.admin_id+'","'+title+'","'+opt+'");',(r)=>{
				if(cb)cb(r);
			});
		});
	}
	b_save_setting(title,opt,cb?) {
		if(!opt)return;
		this.b_query('DELETE FROM Settings WHERE admin_id = '+this.admin_id+' AND title = "'+title+'";',(D)=>{
			this.query('INSERT OR REPLACE INTO Settings (admin_id, title, option) VALUES("'+this.admin_id+'","'+title+'","'+opt+'");',(r)=>{
				if(cb)cb(r);
			});
		});
	}
	get_setting(title,cb?) {
		return this.get('Settings WHERE title = "'+title+'" ORDER BY created DESC LIMIT 1;', (a) => {
			let b = a[0]?a[0]:false;
			if(cb) cb(b);
			return b;
		});
	}
	b_get_setting(title,cb?) {
		return this.b_get('Settings WHERE title = "'+title+'" ORDER BY created DESC LIMIT 1;', (a) => {
			let b = a[0]?a[0]:false;
			if(cb) cb(b);
			return b;
		});
	}
	search_and_destroy(model, variable,cb?){
		for (var i = 0; i < SQLModels.Models.length; ++i) {
			let keys = Object.keys(SQLModels['New_'+SQLModels.Models[i]]);
			for( let key of keys ) {
				if(this.verbose)console.log(key, keys);
			}
		}
	}
	add_schedule(type, id, sched, cb?) {
		var t = (type=='employee')?'Employees':'Payees';
		this.view( t, type+'_id', id, (result)=>{
			var j = [];
			if(result.schedule_ids) {
				var s = result.schedule_ids.split(',');
				s = s.split(',');
				s.push(sched);
				j = s.join(',');	
			} else {j=[sched]}
			this.set( type.toUpperCase()+'s', type+'_id', id, 'schedule_ids', j, (ret)=>{
				if(this.verbose)console.log('Schedule added', ret);
				if(cb)cb(ret);
			});
		});
	}
	get_default_printer() {
		this.get_setting('default_printer',(t)=>{
			if(t.option) {
				this.DEFAULT_PRINTER = t.option;
				if(this.verbose)console.log('Default printer was found:',this.DEFAULT_PRINTER);
			} else {
				this.fetchOne("Printers",(one)=>{
					if(!one)return;
					this.DEFAULT_PRINTER = one.printer_id;
					if(this.verbose)console.log('Default printer auto-selected:',this.DEFAULT_PRINTER);
				});
			}
			this.setup_printer_options();
		});
	}
	get_default_account(cb?) {
		this.get_setting('default_account',(t)=>{
			if(t.option) {
				cb(t.option);
			} else {
				this.fetchOne("BankAccounts",(one)=>{
					if(!one)return;
					cb(one.bank_id);
				});
			}
		});
	}
	ftp_import(url) {
		if(this.verbose)console.log('FTP IMPORT',url);
	}
	has_security(cb?) {
		var secure = false;
		this.protection_mechanisms=false;
		this.get_setting('face_enabled',(a)=>{
			if(a.option=="1")secure=true;
			this.get_setting('password_enabled',(b)=>{
				if(b.option=="1")secure=true;
				this.get_setting('pin_enabled',(c)=>{
					if(c.option=="1")secure=true;
					this.get_setting('geo_enabled',(d)=>{
						if(d.option=="1"){
							secure=true;
							this.geo_monitoring=true;
						}
						this.protection_mechanisms=secure;
						if(cb)cb(secure);
					});
				});
			});
		});
	}
	has_pin(cb){
		this.get_setting('pin_enabled',(si)=>{ 
			this.pin=si.option=='1'?true:false;
			cb(this.pin); 
		}); 
	}
	get_pin(cb){this.get_setting('pin',cb);}
	check_pin(input, cb?) {
		var pin = '';
		this.get_setting('pin',(s)=>{
		  if(!s){cb(true);return;}
		  pin=(s.option)?s.option:false;
		  if(this.verbose)console.log('Chekcing PIN',input);
		  if(input==pin) {
			if(this.verbose)console.log('PIN true');
			cb(true);
			return true;
		  } else {
			if(this.verbose)console.log('PIN false');
			cb(false);
			return false;
		  }
		});
	}	
	check_password(input, cb?) {
		var password = '';
		this.get_setting('password',(s)=>{
			if(!s){cb(true);return;}
			if(s.option){
				password=s.option;
				if(input==password) {
					if(this.verbose)console.log('Password checked: true');
					cb(200);
					return 200;
				} else {
					if(this.verbose)console.log('Password checked: false');
					cb(500);
					return 500;
				}
			} else {
				if(this.verbose)console.error('Password not found');
				cb(400);
				return 400;
			}
		});
	}
	check_geo(cb?) {
		var enabled, distance, location;
		this.get_setting('geo_enabled',(g)=>{
			if(this.more_verbose)console.log('Geo Enabled',g);
			enabled=(g&&g.option)?g.option:false;
			if(enabled) {
				this.get_setting('geo_distance',(d)=>{
					if(this.more_verbose)console.log('Geo Distance',d);
					distance=(d&&d.option)?d.option:false;
					this.get_setting('geo_location',(l)=>{
						if(this.more_verbose)console.log('Geo Location',l);
						if(l){
							location=(l&&l.option)?l.option:false;
							location=location.split(',');
							this.getCurrentPosition((position)=>{
								if(!position){ 
									if(this.verbose)console.error('GEOLOCATION ERROR');
									cb(500);
									return;
								} else if (position=='denied') {
									if(this.verbose)console.error('GEOLOCATION PERMISSIONS MISS');
									cb(600);
									return;
								}
								if(this.more_verbose)console.log('Current position Accuracy:', position.coords.accuracy);
								if(this.more_verbose)console.log('Current position Latitude:', position.coords.latitude);
								if(this.more_verbose)console.log('Current position Longitude:', position.coords.longitude);
								if(this.more_verbose)console.log('Current position Altitude:', position.coords.altitude);
								if(this.more_verbose)console.log('Current position Altitude Accuracy:', position.coords.altitudeAccuracy);
								if(this.more_verbose)console.log('Current position Timestamp:', position.timestamp);
								if(this.more_verbose)console.log(location[0],position.coords.latitude,location[1],position.coords.longitude);
								var live_distance = this.geodistance(location[0],position.coords.latitude,location[1],position.coords.longitude);
								if(this.more_verbose)console.log('Live distance:', live_distance, 'Measurement distance:', distance);
								if(distance<this.minimum_geo_distance)distance=this.minimum_geo_distance;
								if(distance>=live_distance) {
									if(this.verbose)console.log('GEOLOCATION ACCESS GRANTED','Distance:',distance+'m');
									this.geo_monitoring=true;
									this.geo_monitor();
									cb(200);
								} else {
									console.warn('GEOLOCATION DENIED','Distance:',distance+'m','Your distance:',live_distance+'m');
									cb(500);
								}
							},distance<1000?true:false);
						} else {
							cb(400);
						}
					});
				});
			} else {
				cb(400);
			}
		});
	}
	geo_monitor() {
		var enabled, distance, location;
		if(this.geo_timer)clearInterval(this.geo_timer);
		if(this.geo_monitoring) {
			this.get_setting('geo_enabled',(g)=>{
				if(!g)return;
				enabled=(g.option)?g.option:false;
				if(enabled) {
					this.get_setting('geo_distance',(d)=>{
						if(!d)return;
						distance=(d.option)?d.option:false;
						this.get_setting('geo_location',(l)=>{
							if(!l)return;
							location=(l.option)?l.option:false;
							location=location.split(',');
							this.getting_geo_pos=true;
							this.getCurrentPosition((position)=>{
								if(!position){this.geo_monitoring=false;return;}
								var live_distance = this.geodistance(location[0],position.coords.latitude,location[1],position.coords.longitude);
								if(this.more_verbose)console.log('Distance Measurement Monitor:', distance, live_distance);
								if(distance<this.minimum_geo_distance)distance=this.minimum_geo_distance;
								if(distance>=live_distance) {
									if(this.geo_monitoring){
										if(this.verbose)console.log('GEOLOCATION ACCESS GRANTED','Distance:',distance+'m','Your distance:',live_distance+'m');
										this.geo_timer=setTimeout(()=>{this.geo_monitor()},10000);
									}
								} else {
									console.warn('GEOLOCATION DENIED','Distance:',distance+'m','Your distance:',live_distance+'m');
									this.router.navigate(['folder','Splash']);
									this.geo_timer=setTimeout(()=>{this.geo_monitor()},10000);
								}
								setTimeout(()=>{ this.getting_geo_pos=false; }, 1000);
							},distance<1000?true:false);
						});
					});
				}
			});
		}
	}
	geodistance(lat1,lat2,lon1,lon2){
		lon1 = lon1 * Math.PI / 180;
		lon2 = lon2 * Math.PI / 180;
		lat1 = lat1 * Math.PI / 180;
		lat2 = lat2 * Math.PI / 180;
		let dlon = lon2 - lon1;
		let dlat = lat2 - lat1;
		let a = Math.pow(Math.sin(dlat / 2), 2)
				 + Math.cos(lat1) * Math.cos(lat2)
				 * Math.pow(Math.sin(dlon / 2),2);
			   
		let c = 2 * Math.asin(Math.sqrt(a));
		// Radius of earth in kilometers. Use 3956 for miles
		var unit = 'k';
		let r = (unit=='m')?3956:6371;
		return( (c*r)*1000 );
	}//geeksforgeeks.org
	async getCurrentPosition(cb,hi_acc=false){
		if(this.platform.is('capacitor')){
			var permissions = await Geolocation.checkPermissions();
			if(permissions.location=="denied") {
				cb("denied");
			} else {
				var coordinates = await Geolocation.getCurrentPosition({
					enableHighAccuracy:hi_acc,
					maximumAge: 25000
				});
	  			cb(coordinates);
			}
		} else {
			navigator.geolocation.getCurrentPosition(cb);
		}
	}
	getGeoPermissions() {
		Geolocation.requestPermissions();
	}
	change_pin(oldpin,newpin,cb?) { 
		this.get_setting('pin', (old) =>{
			if ( oldpin && newpin && old.option && old.option==oldpin && newpin.length>=4 ) {
				this.query("INSERT OR REPLACE INTO Settings (admin_id,title,option) VALUES("+this.admin_id+",'pin',"+newpin+");", (r) =>{
					if(cb) cb(true);
					return true;
				}); 
			} else { 
				if(cb) cb(true);
				return false; 
			}
		});
	}	
	export():void {
		this.sqlitePorter.exportDbToSql(this.db._objectInstance).then((result) => {
			var date = this.Today.getFullYear()+'-'+(this.Today.getMonth()+1)+'-'+this.Today.getDate();
			var name = environment.appname+'_'+date+'.sql';
			if(this.verbose) console.log('EXPORT SQL COMPLETE',date,result);
			this.save_file(unescape(encodeURIComponent( result )),name,'application/x-sql');
		}).catch((e) => {
			console.error(e);
		});
	}
	export_to_server() {
		this.sqlitePorter.exportDbToSql(this.db._objectInstance).then((result) => {
			var date = this.Today.getFullYear()+'-'+(this.Today.getMonth()+1)+'-'+this.Today.getDate();
			if(this.verbose)console.log('EXPORT TO SERVER',date,result);
			this.sync(date,result);
			return true;
		}).catch((e) => {
			console.error(e);
		});
	}
	download():void {
		this.sqlitePorter.exportDbToJson(this.db._objectInstance)
		.then((result) => {
			var dated = this.Today.getFullYear()+'-'+(this.Today.getMonth()+1)+'-'+this.Today.getDate();
			var named = environment.appname+'_'+dated+'.json';
			if(this.verbose) console.log('EXPORT JSON COMPLETE',dated,result);
			this.save_file(result,named,'application/json');
		}).catch((e) => {
			console.error(e);
		});
	}
	import() {
		var importing ="";
		this.sqlitePorter.importSqlToDb(this.db._objectInstance, importing)
		.then((result) => {
			if(this.verbose)console.log('Import',result);
		}).catch((e) => {
			console.error(e);
		});
	}
	import_json(path?) {
		var importing ="";
		this.sqlitePorter.importJsonToDb(this.db._objectInstance, importing)
		.then((result)=>{
			if(this.verbose)console.log('Import JSON',result);
		}).catch((e)=>{
			console.error(e);
		});
	}
	import_excel(path?) {
		// excel to json
		var importing ="";
		this.sqlitePorter.importJsonToDb(this.db._objectInstance, importing)
		.then((result)=>{
			if(this.verbose)console.log('Import EXCEL',result);
		}).catch((e)=>{
			console.error(e);
		});
	}
	import_sql(path?) {
		var importing ="";
		this.sqlitePorter.importSqlToDb(this.db._objectInstance, importing)
		.then((result)=>{
			if(this.verbose)console.log('Import SQL',result);
		}).catch((e)=>{
			console.error(e);
		});
	}
	import_csv(path?) {
		var importing ="";
		this.sqlitePorter.importSqlToDb(this.db._objectInstance, importing)
		.then((result)=>{
			if(this.verbose)console.log('Import CSV',result);
		}).catch((e)=>{
			console.error(e);
		});
	}
	import_ftp() {
		var importing ="", filetype="";
		if(filetype=='json')this.import_json(importing);
		if(filetype=='xlxs')this.import_excel(importing);
		if(filetype=='csv')this.import_csv(importing);
	}
	import_uri(uri?) {
		var importing ="", filetype="";
		if(filetype=='json')this.import_json(importing);
		if(filetype=='xlxs')this.import_excel(importing);
		if(filetype=='csv')this.import_csv(importing);
	}
	import_mysql() {
		var importing ="";
		this.sqlitePorter.importSqlToDb(this.db._objectInstance,importing).then((result)=>{
			if(this.verbose) console.log(result);
		}).catch((e)=>{
			console.error(e);
		});
	}
	import_icloud(path='',key?) {
		var importing = '';
		// var importing = this.fileEncryption.decrypt(path,key?key:environment.secretKey);
		this.sqlitePorter.importJsonToDb(this.db._objectInstance,importing).then((result)=>{
			if(this.verbose) console.log(result);
		}).catch((e)=>{
			console.error(e);
		});
	}
	import_googledrive(path='',key?) {
		var importing = '';
		// var importing = this.fileEncryption.decrypt(path,key?key:environment.secretKey);
		this.sqlitePorter.importJsonToDb(this.db._objectInstance,importing).then((result)=>{
			if(this.verbose) console.log(result);
		}).catch((e)=>{
			console.error(e);
		});
	}
	import_onedrive(path='',key?) {
		var importing = '';
		// var importing = this.fileEncryption.decrypt(path,key?key:environment.secretKey);
		this.sqlitePorter.importJsonToDb(this.db._objectInstance,importing).then((result)=>{
			if(this.verbose) console.log(result);
		}).catch((e)=>{
			console.error(e);
		});
	}
	import_encrpyted(file='',key?) {
		if(!key)return;
		var importing = '';
		// var importing = this.fileEncryption.decrypt(file,key?key:environment.secretKey);
		this.sqlitePorter.importJsonToDb(this.db._objectInstance,importing).then((result)=>{
			if(this.verbose) console.log(result);
		}).catch((e)=>{
			console.error(e);
		});
	}


	// import { read, writeFileXLSX, set_cptable } from "xlsx";
	// import * as cptable from 'xlsx/dist/cpexcel.full.mjs';

	getBase64(file) {
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			return reader.result;
		};
		reader.onerror = function (error) {
			console.error('Base64 File Conversion: ', error);
		};
	}
	handle_loaded_xls(readerEvt) {
		var binaryString = readerEvt.target.result;
		if(this.more_verbose)console.log(btoa(binaryString));
	}
	process_import_xls(data, cb?) {
		const ab: ArrayBuffer = data;
		var workbook = read(ab);
		if(this.more_verbose)console.log('worksheet',worksheet)
		var first_sheet_name = workbook.SheetNames[0];
		var worksheet = workbook.Sheets[first_sheet_name];
		cb(utils.sheet_to_json(worksheet,{header:1}));
	}
	process_import_json(data, cb?)  {
		if(this.more_verbose)console.log('process_import_json',data);
	}
	process_import_sql(data, cb?)  {
		if(this.more_verbose)console.log('process_import_sql',data);
	}

	ExcelDateToJSDate(date) {
		return new Date(Math.round((date - 25569)*86400*1000));
	}
	email(to,su,bod,att?,H=true) {
		if(att&&att.typeof!=Array)att=[att];
		this.emailComposer.open({ 
			to: to,
			/// cc: '',
			// bcc: [], 
			attachments:att?att:false,
			subject:su,
			body:bod,
			isHtml:H
		});
	}
	reset_locale() {
		this.localize((locale) => {
			this.Locale = locale ? locale : this.Locale;
			this.load_web_locale(this.Locale,(loc)=>{
				var ae = loc ? loc : this.epl;
				this.Language = ae.language[0].toUpperCase() + ae.language.substr(1).toLowerCase();
				this.Title = ae.title;
				this.State_Province = ae.state_province;
				this.Country = ae.country;
				this.CountryName = ae.country_name; 
				this.City = ae.city;
				this.Currency = Currencies[ae.currency].symbol;
				this.CurrencyWords = Currencies[ae.currency].name;
				this.CurrencyValue = Currencies[ae.currency].value;
				this.CurrencySymbol = Currencies[ae.currency].icon;
				this.CurrencyUnit = Currencies[ae.currency].unit;
				this.subTitle = ae.subtitle;
				this.metric = ae.metric;
				this.geofence = ae.geofence;
				this.pin_protection = ae.pin;
				this.pass_protection = ae.password;
				this.biometric_protection = ae.biometrics;
				this.bank_number_protection = ae.bank_number_protection;
				this.smart_printjobs = ae.print;
				this.pho = ae.phone;
				this.paypal_payouts = ae.paypal; 
				this.designer = ae.cheque_designer;
				this.designs_editable = ae.cheque_designs_editable;
				this.no_empty_pages = ae.no_empty_pages;
				this.deposit_enabled = ae.deposits;
				this.zeropad_number = ae.zeropad_number;
				this.autogen_cheques = ae.autogen_cheques;
				this.auto_refresh = ae.auto_refresh;
				this.enable_ultimate = ae.ultimate_buy; 
				this.enable_buy = ae.see_buy;
				this.multiple_companies = ae.companies;
				this._off = ae._off?ae._off:false;
				this._on = ae._on?ae._on:0; 
				this.enable_calendar = 1;//ae.enable_calendar?0:1;
				this.timing = ae.enable_time_picker?0:1;
				this.show_printed_credit = ae.show_printed_credit?1:0;
				this.monthly_features = ae.monthly_features?ae.monthly_features:0;
				this._co = ae._on?ae.co:0;
				this.msrp = ae.software_licence_msrp?ae.software_licence_msrp:0;
				this.mailed_black_msrp = ae.mailed_black_msrp?ae.mailed_black_msrp:0;
				this.mailed_color_msrp = ae.mailed_color_msrp?ae.mailed_color_msrp:0;
				this.show_docu_title = ae.show_docu_title?ae.show_docu_title:0;
				this.enable_secondly = ae.secondly?ae.secondly:false;
				this.enable_minutely = ae.minutely?ae.minutely:false;
				this.ocr = ae.ocr?true:false;
				this.account_keys = ae.account_keys;
				this.fax = ae.fax;
				this.stripe = ae.credit_cards;
				this.bitcoin = ae.bitcoins;
				this.stylish = ae.stylish;
				this.Banks = ae.banks;
				this.TrialPrints = ae.trial_prints;
				this.EnableInsurance = ae.insurance;
				this.EnableInvoices = ae.invoices;
				this.InsurePayees = ae.insurance_payees;
				this.max_cheque_amount = ae.max_cheque_amount;
				this.max_payment_amount = ae.max_payment_amount;
				this.max_payroll_amount = ae.max_payroll_amount;
				this.AdminEnabled = ae.administrators;
				this.ModeratorsEnabled = ae.moderators;
				this.Trial_Bank_Accounts = ae.trial_accounts?ae.trial_accounts:-1;
				this.Trial_Employees = ae.trial_employees?ae.trial_employees:-1;
				this.Trial_Payrolls = ae.trial_payrolls?ae.trial_payrolls:-1;
				this.Trial_Payments = ae.trial_payments?ae.trial_payments:-1;
				this.Trial_Payees = ae.trial_payees?ae.trial_payees:-1;
				this.Trial_Salaries = ae.trial_salaries?ae.trial_salaries:-1;
				this.Trial_Schedules = ae.trial_schedules?ae.trial_schedules:-1;
				this.Trial_Time_sheets = ae.trial_time_sheets?ae.trial_time_sheets:-1;
				this.Trial_Addresses = ae.trial_addresses?ae.trial_addresses:-1;
				this.trial_max_amount = ae.trial_max_amount?ae.trial_max_amount:-1;
				this.products = [
					{id:"chequeslicence",months:"1",cheques:"-1",mode:"subscription",price:this.price[this.CountryName].chequeslicence,currency:this.price[this.CountryName].currency,alias:this.word.Subscription,type:this.store.PAID_SUBSCRIPTION},
					{id:"chequeslicence_2mo",months:"2",cheques:"-1",mode:"subscription",price:this.price[this.CountryName].chequeslicence_2mo,currency:this.price[this.CountryName].currency,alias:this.word.Two_Month_Subscription,type:this.store.PAID_SUBSCRIPTION},
					{id:"chequeslicence_3mo",months:"3",cheques:"-1",mode:"subscription",price:this.price[this.CountryName].chequeslicence_3mo,currency:this.price[this.CountryName].currency,alias:this.word.Three_Month_Subscription,type:this.store.PAID_SUBSCRIPTION},
					{id:"chequeslicence_6mo",months:"6",cheques:"-1",mode:"subscription",price:this.price[this.CountryName].chequeslicence_6mo,currency:this.price[this.CountryName].currency,alias:this.word.Six_Month_Subscription,type:this.store.PAID_SUBSCRIPTION},
					{id:"chequeslicence_12mo",months:"12",cheques:"-1",mode:"subscription",price:this.price[this.CountryName].chequeslicence_12mo,currency:this.price[this.CountryName].currency,alias:this.word.Twelve_Month_Subscription,type:this.store.PAID_SUBSCRIPTION},
					{id:"cheques_znvr9f",months:"-1",cheques:"-1",mode:"premium",price:this.price[this.CountryName].cheques_znvr9f,currency:this.price[this.CountryName].currency,alias:this.word.Cheques_Unlimited,type:this.store.NON_CONSUMABLE},
					{id:"cheques_print_1",months:"-1",cheques:"1",mode:"print",price:this.price[this.CountryName].cheques_print_1,currency:this.price[this.CountryName].currency,alias:this.word.Single_Print,type:this.store.CONSUMABLE},
					{id:"cheques_print_2",months:"-1",cheques:"2",mode:"print",price:this.price[this.CountryName].cheques_print_2,currency:this.price[this.CountryName].currency,alias:this.word.Two_Prints,type:this.store.CONSUMABLE},
					{id:"cheques_print_5",months:"-1",cheques:"5",mode:"print",price:this.price[this.CountryName].cheques_print_5,currency:this.price[this.CountryName].currency,alias:this.word.Five_Prints,type:this.store.CONSUMABLE},
					{id:"cheques_print_10",months:"-1",cheques:"10",mode:"print",price:this.price[this.CountryName].cheques_print_10,currency:this.price[this.CountryName].currency,alias:this.word.Ten_Prints,type:this.store.CONSUMABLE},
					{id:"cheques_print_15",months:"-1",cheques:"15",mode:"print",price:this.price[this.CountryName].cheques_print_15,currency:this.price[this.CountryName].currency,alias:this.word.Fifteen_Prints,type:this.store.CONSUMABLE},
					{id:"cheques_print_20",months:"-1",cheques:"20",mode:"print",price:this.price[this.CountryName].cheques_print_20,currency:this.price[this.CountryName].currency,alias:this.word.Twenty_Prints,type:this.store.CONSUMABLE},
					{id:"cheques_print_30",months:"-1",cheques:"30",mode:"print",price:this.price[this.CountryName].cheques_print_30,currency:this.price[this.CountryName].currency,alias:this.word.Thirty_Prints,type:this.store.CONSUMABLE},
					// {id:"cheques_mailed_black_white",mode:"real_mail",price:this.price[this.CountryName].cheques_mailed_black_white,currency:this.price[this.CountryName].currency,alias:this.word.Mailed_Cheque,type:this.store.CONSUMABLE},
					// {id:"cheques_znvr9f_color",mode:"real_mail",price:this.price[this.CountryName].cheques_znvr9f_color,currency:this.price[this.CountryName].currency,alias:this.word.Mailed_Cheque_Color,type:this.store.CONSUMABLE}
				];
				this.renderMenu(this.word);
			});
		});
	}
	reset_settings() {
		this.ID=environment.appname;
		this.Alphabet=environment.alpha; 
		this.LOGGING = (environment.logging)?true:false;
		this.production = environment.production;
		this.procurement = environment.procurement;
		this.Modifications = environment.required_modifications;
		this.selected_company = 1;
		this.reset_locale();
	}
	reset(force=false,cb?,prompt=this.word.DB_Erase_Prompt) { let q ='';
		if (confirm(prompt)||force) {
			if (this.verbose) console.log('RESET & DROP THE ENTIRE DATABASE OF TABLES BECAUSE THE USER HAS RESET THE DATABASE.');
			for (var i = 0; i<SQLModels.Models.length; i++) { 
				this.query('DROP TABLE IF EXISTS "'+SQLModels.Models[i]+'";'); 
				if(i+1==SQLModels.Models.length)this.setup(true,(a)=>{if(cb)cb(a);});
			}
		} else {return 0;}
	}
	reset_database2(force=false,cb?) {
		if (confirm(this.word.DB_Erase_Prompt_2)||force) {
			this.Trial = true;
			this.PrintOuts = false;
			this.Unlimited = false;
			this.Purchased = 0;
			this.Subscription = false;
			this.AvailablePrints=this.TrialPrints;
			if (this.verbose) console.log('RESET & DROP THE ENTIRE BACKEND DATABASE OF ORDERS AND TABLES IN DEV MODE.');
			var e = SQLModels.AdminModels.length;
			for (var i = 0; i<e; i++) { 
				this.b_query('DROP TABLE IF EXISTS "'+SQLModels.AdminModels[i]+'";'); 
				if(i+1==e) {
					this.internal_database_init((result)=>{
						this.setup_orders(cb);
					});
				}
			}
		} else {return 0;}
	}
	zeroPad=(num, places) => String(num).padStart(places, '0');
	insert_default_designs(str=this.word,cb?,strings_only=false) {
		var new_designs = [];
		for (var i = 0; i < Templates['Templates'].length; i++) {
			var design = Templates['Templates'][i];
			if(!strings_only)new_designs.push({
				design_id: Templates[design+'_Properties'].id,
				title: str[Templates[design+'_Properties'].title].replace(/'/g, "\\'"),
				default_design: Templates[design+'_Properties'].default?'1':'0',
				design_pattern: design,
				editable: this.designs_editable?'true':'false',
				borders: Templates[design+'_Properties'].borders?'1':'0',
				description: str[Templates[design+'_Properties'].description],
				preview: Templates[design+'_Properties'].preview,
				pro: (Templates[design+'_Properties'].pro===true)?'1':'0',
				background_colour: Templates[design+'_Properties'].background,
				monochrome: Templates[design+'_Properties'].monochrome?'true':'0',
				font: Templates[design+'_Properties'].font?Templates[design+'_Properties'].font:'Helvetica',
				top: Templates[design+'_Properties'].top?Templates[design+'_Properties'].top:'0mm',
				bottom: Templates[design+'_Properties'].bottom?Templates[design+'_Properties'].bottom:'0mm',
				docugard: Templates[design+'_Properties'].docugard?'true':'false',
				doc_color: Templates[design+'_Properties'].doc_color?Templates[design+'_Properties'].doc_color:'green',
				show_cheque_number: Templates[design+'_Properties'].show_cheque_number?'true':'false',
				show_cheque_date: Templates[design+'_Properties'].show_cheque_date?'true':'false',
				show_micr: Templates[design+'_Properties'].show_micr?'true':'false',
				enabled: Templates[design+'_Properties'].enabled?'true':'false'
			});
			if(strings_only){ 
				this.set( 'Designs', 'design_id', Templates[design+'_Properties'].id, 'title', str[Templates[design+'_Properties'].title]);
				this.set( 'Designs', 'design_id', Templates[design+'_Properties'].id, 'description', str[Templates[design+'_Properties'].description]);
				if(i==Templates['Templates'].length-1&&cb)cb();
			}
		}
		if(!strings_only)this.addMany('Designs', new_designs,cb);
	}
	async setup_geofence(cb?) {
		this.getCurrentPosition((position)=>{
			if(!position){
				console.error('GEOLOCATION ERROR'); 
				return;
			}
			if(this.verbose)console.log('Current position Accuracy:', position.coords.accuracy);
			if(this.verbose)console.log('Current position Latitude:', position.coords.latitude);
			if(this.verbose)console.log('Current position Longitude:', position.coords.longitude);
			if(this.verbose)console.log('Current position Altitude:', position.coords.altitude);
			if(this.verbose)console.log('Current position Altitude Accuracy:', position.coords.altitudeAccuracy);
			if(this.verbose)console.log('Current position Timestamp:', position.timestamp);
			var coordinates = position.coords.latitude+','+position.coords.longitude;
			this.save_setting('geo_location',coordinates);
			if(cb)cb(coordinates);
		});
	}
	generate_model(m?) {
		if(!m) {
			for( let m of SQLModels.Models ) {
				return this.query("INSERT OR REPLACE INTO "+m+" (admin_id) VALUES("+this.admin_id+");");
			}
		} else {
			return this.query("INSERT OR REPLACE INTO "+m+" (admin_id) VALUES("+this.admin_id+");");
		}
	}
	able_to_print(cb, show_modal=true) {
		this.setup_orders(()=>{
			var a = this.AvailablePrints+this.Purchased;
			if(this.Unlimited || this.Subscription || a<0) {
				cb(true);
			} else if(a<1) {
				if(show_modal){
					this.show_purhchase_modal();
					this.hapticsImpactMedium();
				}
				cb(false);
			} else if(a>0) {
				cb(true);
			} else if(this.Trial) {
				if(show_modal){
					this.show_purhchase_modal(); 
					this.hapticsImpactMedium();
				}
				cb(false);
			} else {
				cb(true);
			}
		});
	}
	post_print(cb?) {
		this.record_print(()=>{
			if(this.AvailablePrints>0 && this.Trial){
				this.deliver_cheque_order();
				this.AvailablePrints=this.AvailablePrints-1;
				this.alert_remaining_prints();
			} else if(this.AvailablePrints==0 && this.Trial) {
				this.remove_cheque_orders(cb);
				this.alert_remaining_prints();
			}
		});
	}
	remove_cheque_orders(callback?) {
		var now = new Date();
		var time = now.getTime();
		this.b_query("DELETE FROM Orders WHERE cheques > 0;",(item)=>{
			if(item){
				if (this.verbose)console.log('REMOVED CHEQUE Orders');
				if (callback)callback(item);
			}
		});
	}
	alert_remaining_prints() {
		if(this.model!='web'){
			var remaining=this.remaining_prints(true);
			if(remaining>0)if(confirm(this.remaining_prints()+' '+this.word.Purchase_Additional_Cheques)){this.show_purhchase_modal();}
			if(remaining==0)if(confirm(this.word.No_Prints_Purchased_Word)){this.show_purhchase_modal();}
		} else {
			this.show_purhchase_modal();
		}
	}
	remaining_prints(n?) {
		if(!n)return Number(this.AvailablePrints+this.Purchased)+' '+(Number(this.AvailablePrints+this.Purchased)==1?this.word.Printable_Cheque_Word:this.word.Printable_Cheques_Word);
		if(n)return Number(this.AvailablePrints+this.Purchased);
	}
	count_prints(callback?) {
		this.b_get("Commands WHERE 'command' = 'print_cheque'",(r)=>{
			if(callback)callback(r?r.length:0);
		});
	}
	count_companies(callback?) {
		this.b_get("Companies",(r)=>{
			if(callback)callback(r?r.length:0);
		});
	}
	record_print(callback?) {
		const now = new Date();
		var data = {
			command: 'print_cheque',
			completed: true,
			applied: now.getTime(),
			modified: now.getTime(),
			created: now.getTime()
		}
		var values = "", keys = "", i = 0, l = 0;
		Object.entries(data).forEach(([key, value]) => { if(value) l++; });	
		Object.entries(data).forEach(([key, value]) => { if(value) {
			keys=keys+key+(i==l-1?"":",");
			values=values+'"'+value+'"'+(i==l-1?"":",");
			i=i+1;
		}});
		var sql="INSERT OR REPLACE INTO Commands ("+keys+") VALUES("+values+");";
		if(this.more_verbose)console.log('INSERT SQL',sql);
		this.b_query(sql,(item)=>{
			if (!item) return;
			if (this.verbose)console.log('INSERTED INTO Commands','ID',item);
			if (callback)callback(item);
		});
	}
	Print(PrintImage,cb?,paper_size_i = 0,window_title=this.word.Cheque){this.able_to_print((a)=>{if(!a)return;
		if(this.platform.is('capacitor')){
			var Paper_Properties = {
				width: PaperSizes[paper_size_i].width,
				height: PaperSizes[paper_size_i].height,
				name: PaperSizes[paper_size_i].name,
				length: PaperSizes[paper_size_i].height,
			}
			var PrinterOptions:PrintOptions = {
				maxHeight:PaperSizes[paper_size_i].height,
				maxWidth:PaperSizes[paper_size_i].width,
				paper:Paper_Properties,
				photo: false,
				monochrome: false,
				margin:false
			}
			this.printer.print(PrintImage,PrinterOptions).then((PrinterResult)=>{
				if(PrinterResult){
					if(this.verbose)console.log('PrinterResult',this.word.PrinterSuccess);
					if(this.more_verbose)alert(this.word.PrinterSuccess);
					this.post_print();
				}
				if(cb)cb(PrinterResult);
			},(PrinterError)=>{
				if(this.verbose)console.error(this.word.PrinterError, PrinterError);
				alert(this.word.PrinterError);
				if(cb)cb(false);
			});
		} else if (this.platform.is('electron')) {
			this.electron_print(PrintImage,()=>{
				this.post_print();
			})
		} else {
			var pagewidth  = PaperSizes[paper_size_i].width?Number(PaperSizes[paper_size_i].width.split('mm')[0]):0;
			var pageheight = PaperSizes[paper_size_i].height?Number(PaperSizes[paper_size_i].height.split('mm')[0]):0;
			var width  = pagewidth?Math.ceil(Number(pagewidth)*3.7795275591):768;width=width<700?700:width;
			var height = pageheight?Math.ceil(Number(pageheight)*3.7795275591):960;height=height<450?450:height;
			var Print_Preview = window.open('','','left=0,top=0,width='+width+',height='+height+',toolbar=0,scrollbars=0,status=0');
			if(Print_Preview){
				Print_Preview.document.write(PrintImage);
				Print_Preview.document.title=window_title;
				Print_Preview.document.close();
				Print_Preview.focus();
				Print_Preview.print();
				this.post_print(); // Print_Preview.close();
			}
		}
	});}
	async electron_print(htmldocument,cb) {
		if(this.verbose)console.log('ELECTRON_PRINT Event');
		this.app_controller.ipcRenderer.on('print_complete',(event, query)=>{this.zone.run(()=>{cb()})});
		await this.app_controller.ipcRenderer.send('print',htmldocument);
		await this.app_controller.ipcRenderer.send('readyToPrintHTML');
	}
	setup_printer_options(p?,cb?) {
		var paper_size_i = 0;
		var margin = {
			top: (p&&p.margin_top)?p.margin_top:'0mm',
			right: (p&&p.margin_right)?p.margin_right:'0mm',
			bottom: (p&&p.margin_bottom)?p.margin_bottom:'0mm',
			left: (p&&p.margin_left)?p.margin_left:'0mm'
		}, header_font: FontOptions = {
			name: (p&&p.header_label_font)?p.header_label_font:'Arial',
			size: (p&&p.header_font_size)?p.header_font_size:11,
			italic: (p&&p.header_label_font_italic)?p.header_label_font_italic:false,
			bold: (p&&p.header_label_font_bold)?p.header_label_font_bold:false,
			align: (p&&p.header_label_font_align)?p.header_label_font_align:'left',
			color: (p&&p.header_label_font_color)?p.header_label_font_color:'black',
		}, header_options: HeaderFooterLabelOptions = {
			showPageIndex: (p&&p.header_show_index)?p.header_show_index:false,
			text: (p&&p.header_label_text)?p.header_label_text:'',
			font: header_font,
			top: (p&&p.header_label_top)?p.header_label_top:'0mm',
			right: (p&&p.header_label_right)?p.header_label_right:'0mm',
			left: (p&&p.header_label_left)?p.header_label_left:'0mm',
			bottom: (p&&p.header_label_bottom)?p.header_label_bottom:'0mm',
		}, header = {
			label:  header_options,
			labels: (p&&p.header_label_text)?p.header_label_text:[''],
			height: (p&&p.header_height)?p.header_height:null,
		}, footer_font: FontOptions = {
			name:   (p&&p.footer_label_font)?p.footer_label_font:null,
			size:   (p&&p.footer_font_size)?p.footer_font_size:null,
			italic: (p&&p.footer_label_font_italic)?p.footer_label_font_italic:null,
			bold:   (p&&p.footer_label_font_bold)?p.footer_label_font_bold:null,
			align:  (p&&p.footer_label_font_align)?p.footer_label_font_align:null,
			color:  (p&&p.footer_label_font_color)?p.footer_label_font_color:null,
		}, footer_options:  HeaderFooterLabelOptions = {
			showPageIndex: (p&&p.footer_show_index)?p.footer_show_index:null,
			text:   (p&&p.footer_label_text)?p.footer_label_text:null,
			font:   footer_font,
			top:    (p&&p.footer_label_top)?p.footer_label_top:null,
			right:  (p&&p.footer_label_top)?p.footer_label_top:null,
			left:   (p&&p.footer_label_top)?p.footer_label_top:null,
			bottom: (p&&p.footer_label_top)?p.footer_label_top:null,
		}, footer = {
			label:  footer_options,
			labels: (p&&p.footer_label_text)?p.footer_label_text:[''],
			height: (p&&p.footer_height)?p.footer_height:'0mm',
		}, No_Options_Set: HeaderFooterLabelOptions = {
			showPageIndex: false,
			text: '',
			font: header_font,
			top: '0mm',
			right:'0mm',
			left:'0mm',
			bottom:'0mm'
		}, No_Frame_Set = {
			label: No_Options_Set?No_Options_Set:null,
			labels: [''],
			height:'0mm',
		}, No_Margins = {
			top: '0mm',
			right: '0mm',
			bottom: '0mm',
			left: '0mm',
		}, Paper_Properties = {
			width: PaperSizes[paper_size_i].width,
			height: PaperSizes[paper_size_i].height,
			name: PaperSizes[paper_size_i].name,
			length: PaperSizes[paper_size_i].height,
		}, UI_Properties = {
			hideNumberOfCopies: 'No',
			hidePaperFormat: 'No',
			top: 0,
			left: 0,
			height: 0,
			width: 0,
		}, No_Font: FontOptions = {
			name: 'Arial', 
			size: 11, 
			italic: false, 
			bold: false,
			align: 'left',
			color: 'black',
		}
		var PrinterOptions:PrintOptions = {
			printer: 	(p&&p.printer)  		?p.printer:'',
			name: 		(p&&p.title)  			?p.title:'Document',
			photo: 		(p&&p.photo) 			?p.photo:false,
			monochrome: (p&&p.monochrome)		?p.monochrome:false,
			autoFit: 	(p&&p.autoFit) 			?p.autoFit:false,
			orientation:(p&&p.orientation)		?p.orientation:'portrait',
			duplex: 	(p&&p.duplex)			?p.duplex:false,
			pageCount: 	(p&&p.page_limit)		?p.page_limit:1,
			copies: 	(p&&p.copies)			?p.copies:1,
			maxHeight: 	(p&&p.maxHeight)		?p.maxHeight:PaperSizes[paper_size_i].height,
			maxWidth: 	(p&&p.maxWidth)			?p.maxWidth:PaperSizes[paper_size_i].width,
			paper: 		(p&&p.paper) 			?p.paper:Paper_Properties,
			margin: 	(p&&p.margin_enabled) 	?margin:false,
			ui: 		(p&&p.ui) 				?p.ui:UI_Properties,
			font: 		(p&&p.font) 	 		?p.font:No_Font,
			footer: 	(p&&p.footer_enabled) 	?footer:No_Frame_Set,
			header: 	(p&&p.header_enabled) 	?header:No_Frame_Set,
		}
		if(!p)this.DEFAULT_PRINTER_OPTIONS=PrinterOptions;
		if(cb)cb(PrinterOptions);
		return PrinterOptions;
	}
	print_cheque(cheque_id, copies=1, b?) {
		if(!cheque_id) { console.error('No Cheque ID sent to Print', 'No Result'); return; }
		this.fetchOne('Cheques WHERE cheque_id = '+cheque_id,(cheque_data)=>{
			if (this.verbose) console.log('Attemping to print cheque:', cheque_data.written_amount);
			this.print_document(cheque_data.title, cheque_data.image, copies, 'cheque', this.DEFAULT_PRINTER, (PrintResult)=>{
				if(b)b(PrintResult);
				return PrintResult;
			});
		});
	}
	print_options(printer_id=this.DEFAULT_PRINTER,cb?) {
		if(printer_id<1) {
			if(cb)cb(this.DEFAULT_PRINTER_OPTIONS);
			return this.DEFAULT_PRINTER_OPTIONS;
		}
		var p = SQLModels.Models['Printers'];
		this.view('Printers','printer_id',printer_id,(e)=>{
			var f = Object.entries(e[0]);var n=[];
			for(let t of f)n[t[0]]=t[1];p=n;
			this.setup_printer_options(p,(opts)=>{
				if(cb)cb(opts);
				return opts;
			});
		});
	}
	print_document(Title, PrintDocument, Copies=1, Type='cheque', PrinterID=this.DEFAULT_PRINTER, cb?) {
		this.print_options(PrinterID,(PrinterOptions)=>{
			PrinterOptions.name=Title?Title:this.word.Upgrade_Title;
			var PrintJob = new NewPrintJob();
				PrintJob.admin_id = this.admin_id;
				PrintJob.printer_id = PrinterID?PrinterID:0;
				PrintJob.printer = PrinterOptions?PrinterOptions.printer:''; // Printer Network URL
				PrintJob.printer_options = PrinterOptions?btoa(PrinterOptions):'';
				PrintJob.image = PrintDocument;
				PrintJob.name = Title?Title:this.word.Upgrade_Title;
				PrintJob.status ='New';
				PrintJob.copies = Copies;
				PrintJob.cheque = Type=='cheque'?true:false;
				PrintJob.invoice = Type=='invoice'?true:false;
				PrintJob.printed = false;
				PrintJob.enabled = true;
			if(PrintJob)this.add('PrintJobs',PrintJob,(printjob_id) => { 
				if(!printjob_id)return;
				if(this.verbose)console.log(this.word.Printing_,printjob_id);
				if(this.printing_paused){
					alert(this.word.Prints_Paused);
					if(this.verbose)console.log(this.word.Prints_Paused);
				} else { 
					if(this.more_verbose)console.log('A print job has been added.', 'Printing Job');
					this.print_job(printjob_id,cb); 
				}
			});
		}); 
	}
	printer_picker(cb?) {
		this.printer_picker_is_open = true;
		if(this.verbose)console.log('Printer picker opening...');
		this.printer.pick().then((ChosenPrinter)=>{
			this.printer_picker_is_open = false;
			if(this.verbose)console.log('Picker picker selected:',ChosenPrinter);
			if(cb)cb(ChosenPrinter);
			return ChosenPrinter;
		},(NoPriner) => {
			this.printer_picker_is_open = false;
			if(cb)cb(false);
			if(this.verbose)console.log('No Printer Was Selected', 'Pick printer error:', NoPriner);
			return false;
		});
	}
	print_job(job_id,cb?) {
		if(!job_id){console.error('Print Job Not Found! Identitiy not defined.'); return;}
		this.view('PrintJobs','printjob_id',job_id,(PrintJob) => {
			var job = PrintJob;
			if(this.more_verbose)console.log(this.word.Printing_, job.printer_id);
			this.printer.isAvailable().then((PrinterAvailable)=>{
				if(this.verbose)console.log('Printer Available', job);
				if(this.more_verbose)console.log(this.word.Print_Cheques,PrinterAvailable);
				// if(!job.image){console.error('Printer Error', 'No Image was Found to Print');return;}
				if(!job.printer){
					this.printer_picker((ChosenPrinter)=>{
						if(!ChosenPrinter){
							console.error('Selector Error', 'No Printer Was Selected To Print');return;
						} else {
							job.printer=ChosenPrinter; 
						}
						if(!job.printer_options) {
							job.printer_options=this.DEFAULT_PRINTER_OPTIONS;
							job.printer_options.printer=ChosenPrinter;
						} else {
							let o:any = atob(job.printer_options)
							job.printer_options=o;
							job.printer_options.printer=ChosenPrinter;
						}
						this.set('PrintJobs','printjob_id', job_id, 'printer', ChosenPrinter,(PrinterSet)=>{
							this.set('PrintJobs','printjob_id', job_id, 'printer_options', btoa(job.printer_options),(PrintOptions)=>{
								if(PrintOptions)this.print_job_run(job_id,cb);
							});
						});	
					});
				} else {
					if(!job.printer_options) {
						job.printer_options=this.DEFAULT_PRINTER_OPTIONS;
					} else {
						let o:any = atob(job.printer_options)
						job.printer_options=o;
					}
					this.set('PrintJobs','printjob_id', job_id, 'printer_options', btoa(job.printer_options),(PrintOptions)=>{
						if(PrintOptions)this.print_job_run(job_id,cb);
					});
				}
			},(PrinterUnavailable)=>{
				if(this.verbose)console.error(this.word.PrintingUnavailable,PrinterUnavailable);
				alert(this.word.PrintingUnavailable);
			});
		});
	}
	print_job_run(job_id,cb?) {this.able_to_print((a)=>{if(!a)return;
		if(!job_id){console.error('Print Job Not Found! Identitiy not defined.'); return;}
		this.view('PrintJobs','printjob_id',job_id,(job) => {
			job=job[0];
			if(this.verbose) 		console.log('Running Print Job:',job);
			if(!job.image) 			console.warn('Required parameter is missing:','job{ image: string }',job.image);
			if(!job.printer) 		console.warn('Required parameter is missing:','job{ printer: string }',job.printer);
			if(!job.printer_options)console.warn('Required parameter is missing:','job{ printer_options: Object }',job.printer_options);
			if( job.image && job.printer && job.printer_options){
				if(this.verbose) 	console.log('New Job',job.printer);
				const now = new Date();
				var options: PrintOptions = this.DEFAULT_PRINTER_OPTIONS;
				options.printer = job.printer;
				if(this.verbose)console.log('Starting  	New  	Print 		Job!',
							'job.Printer',					job.printer,
							'options.Printer',				options.printer);

				this.printing=true;
				this.printing_paused=true;
				this.printing_total_pages=this.printing_total_pages+1;
				this.printing_page=0;

				/** PRINT RUN IMAGE **/
				this.printer.print(job.image, options).then((PrinterResult)=>{

					if(PrinterResult) {
						this.post_print();
						if(this.more_verbose)alert(this.word.PrinterSuccess);
						if(this.verbose)console.log(this.word.PrinterSuccess, 'ID #'+job.printjob_id, PrinterResult);
						if(this.verbose)console.log('Print was sent to printer.',
							'Printer:',					options.printer,
							'Printer Result:',			PrinterResult,
							'Current Page',				this.printing_page,
							'Total Pages',				this.printing_total_pages,
							'Date:',					now.getTime());

						this.printing_paused=false;
						this.printing=true;

						if( this.printing_page   		< this.printing_total_pages) {
							this.printing_page			= this.printing_page + 1;
							this.printing_total_pages 	= this.printing_total_pages - 1;
						} else {
							setTimeout(()=>{
								this.printing_page=0;
								this.printing_total_pages=0;
							},500);
						}

						job.status 		= 'sent';
						job.printed 	= true;
						job.modified 	= now.getTime();

						this.update( "Jobs", job, (JobDone)=>{
							if(cb)cb(PrinterResult); 
						});

					} else {

						this.printing=false; this.printing_paused=true;
						if(this.verbose)console.error(this.word.PrinterError);
						alert(this.word.PrinterError);if(cb)cb(false);

					}

				}, (PrinterError)=>{
					this.printing=false; this.printing_paused=true;
					if(this.verbose)console.error(this.word.PrinterError,PrinterError);
					alert(this.word.PrinterError);if(cb)cb(false);
				});

			} else {
				if(this.verbose){
					console.error('Error starting the print 	job.',
						'Are required arguments missing?','job',job,
						'Required: image', 						job.image, 
						'Required: printer', 					job.printer, 
						'Required: printer_options', 			job.printer_options.printer
					);
				}
			}
		})
	})}
	print(Job,Options,cb?) {this.able_to_print((a)=>{if(!a)return;
		if(this.verbose)console.log(this.word.Printing_);
		if(this.more_verbose)console.log('Print Document (with device selection window)',Job);
		this.printer.isAvailable().then((PrinterAvailable)=>{
			if(this.verbose)console.log(this.word.Printers,PrinterAvailable);
			this.printer.print(Job,Options).then((PrinterResult)=>{
					if(this.verbose)console.log(this.word.PrinterSuccess,PrinterResult);
					if(this.verbose)alert(this.word.PrinterSuccess);
					this.post_print();
					if(cb)cb(PrinterResult);
				},(PrinterError)=>{
					if(this.verbose)console.log(this.word.PrinterError,PrinterError);
					alert(this.word.PrinterError);
					if(cb)cb(false);
				}
			);
		},(PrinterUnavailable)=>{
			if(this.verbose)console.log(this.word.PrintingUnavailable,PrinterUnavailable);
			alert(this.word.PrintingUnavailable);
		});
	})}
	print_many_documents(Documents=[], cb?) {this.able_to_print((a)=>{if(!a)return;
		if (this.Trial) {alert(this.word.Upgrade_Required); return;}
		if (!Documents) {alert(this.word.No_Documents_Specified); return;}
		if(this.printing_paused) {
			if(this.verbose)console.log(this.word.Printing_Paused);
			if(this.more_verbose)console.error('Please enable printing to print this document.');
			return false;
		} else {
			this.get('Printers',(Printers)=>{
				this.SucessfulPrints=[];
				this.UnsucessfulPrints=[];
				var l = Object.entries(Printers[0]),n=[];
				for( let t of l )n[t[0]]=t[1];Printers=n;
				if (!Printers) {alert(this.word.No_Printers_Added); return;}
				for( let Doc of Documents ) {
					for( let Printer of Printers ) {
						this.printer.isAvailable().then((PrinterAvailable)=>{
							if(this.verbose)console.log(this.word.Print_Cheques,PrinterAvailable);
							this.print_options(Printer.printer_id,(PrinterOptions)=>{
								if(this.verbose){
									console.log('I will '+this.word.Show_Advanced_Printer_Options+': ',PrinterOptions);
									console.log(this.word.Printing, 'ID #'+Doc.printjob_id);
								}
								if(Doc.name)PrinterOptions.name=Doc.name;
								if(this.verbose)console.log(Doc.image,PrinterOptions);
								return;
								this.printer.print(Doc.image,PrinterOptions).then((PrinterResult)=>{
									if(PrinterResult)this.printing=true;
									if(this.more_verbose)console.log('Printer result.',PrinterResult);
									this.post_print();
									this.SucessfulPrints.push(PrinterResult);
								}, (PrinterError)=>{
									if(this.verbose)console.error(this.word.Printing,PrinterError);
									this.UnsucessfulPrints.push(PrinterError);
								});
							});
						},(PrinterUnavailable)=>{
							if(this.verbose)console.error(this.word.PrintingUnavailable,PrinterUnavailable);
						});
					}
				}
				if(this.verbose){
					console.log(this.word.Unsuccessful_Prints,this.UnsucessfulPrints);
					alert(this.word.Printing_Complete+this.word.There_Were+this.SucessfulPrints+this.word.Successful_Prints+this.UnsucessfulPrints+this.word.Unsuccessful_Prints);
				}
				if(cb)cb(this.SucessfulPrints,this.UnsucessfulPrints);
			});
		}
	});}
	print_many_cheques(cheques,cb?) {
		this.query("SELECT * Cheques WHERE status = 'ready' AND printed = 0;",(cheques)=>{
			
		});
	}
	test_printer(p) {
		if (this.verbose)console.log('TEST PRINT',p);
	}
	// FILESYSTEM FUNCTIONS
	save_cheque_image(image_data, title?, b?) {
		this.checkFilesystemPermissions((SystemAllowsPermissions)=>{
			if (SystemAllowsPermissions) {
				this.saveCheque(image_data, title, (img) => {
					if (this.verbose) console.log('Saved Image: ',img);
					if(b)b(img);
					return img;
				});
			}
		});
	}
	save_address_image(image_data, title?, b?) {
		this.checkFilesystemPermissions((SystemAllowsPermissions)=>{
			if (SystemAllowsPermissions) {
				this.saveAddress(image_data, title, (img) => {
					if (this.verbose) console.log('Saved Address Image: ',img);
					if(b)b(img);
					return img;
				});
			}
		});
	}
	async checkFilesystemPermissions(b?) {
		await Filesystem.checkPermissions().then((PermissionsGranted) => {
			if(PermissionsGranted) {
				if(b)b(PermissionsGranted);
				return PermissionsGranted;	
			} else {
				if(this.verbose)console.error('Filesystem Permissions were not Granted. Please reset permissions in settings.');
				if(b)b(false);
				return false;	
			}
		}).catch((err)=>{
			this.requestFilesystemPermissions(b);
		})
	}
	async requestFilesystemPermissions(b?) {
		await Filesystem.requestPermissions().then((PermissionsGranted) => {
			if(PermissionsGranted){
				if(this.verbose)console.log('Permissions Granted',PermissionsGranted)
				if(b)b(PermissionsGranted);
				return PermissionsGranted;
			}
		}).catch((err)=>{
			if(this.verbose)console.error('Filesystem Permissions were not Granted.',err);
			if(b)b(false);
			return false;
		})
	}
	async createDefaultDirectories() {
		let cheques_ops:ReaddirOptions = {path: this.word.Cheques,directory: Directory.Documents};
		let invoices_ops:ReaddirOptions = {path: this.word.Invoices,directory: Directory.Documents};
		let DefaultChequeDirectory = await Filesystem.readdir(cheques_ops);
		let DefaultInvoiceDirectory = await Filesystem.readdir(invoices_ops);
		if(!DefaultChequeDirectory)  await this.createChequesDirectory();
		if(!DefaultInvoiceDirectory)  await this.createInvoiceDirectory();
		return true;
	}
	async createChequesDirectory(b?) {
		let opts:MkdirOptions = {
			path:this.word.Cheques,
			directory: Directory.Documents
		}
		let CreatedDirectory = await Filesystem.mkdir(opts);
		if(b)b(CreatedDirectory);
		return CreatedDirectory;
	}
	async createInvoiceDirectory(b?) {
		let opts:MkdirOptions = {
			path:this.word.Invoices,
			directory: Directory.Documents
		}
		let CreatedDirectory = await Filesystem.mkdir(opts);
		if(b)b(CreatedDirectory);
		return CreatedDirectory;
	}
	async saveInvoice(base64Data, title = this.word.Cheque, b?) {
		this.file.writeFile(this.file.documentsDirectory, title+'.'+this.save_in_file_format, base64Data, { replace: true });
		if(this.verbose)console.log('Wrote A New Invoice',title+'.'+this.save_in_file_format);
		if(b)b(this.file.documentsDirectory+'/'+title+'.'+this.save_in_file_format);
	}
	async saveCheque(base64Data, title = this.word.Cheque, b?) {
		this.file.writeFile(this.file.documentsDirectory, title+'.'+this.save_in_file_format, base64Data, { replace: true });
		if(this.verbose)console.log('Wrote A New Cheque',title+'.'+this.save_in_file_format);
		if(b)b(this.file.documentsDirectory+'/'+title+'.'+this.save_in_file_format);
	}
	async saveAddress(base64Data, title = this.word.Cheque, b?) {
		this.file.writeFile(this.file.documentsDirectory, title+'.'+this.save_in_file_format, base64Data, { replace: true });
		if(this.verbose)console.log('Wrote A New Address',title+'.'+this.save_in_file_format);
		if(b)b(this.file.documentsDirectory+'/'+title+'.'+this.save_in_file_format);
	}

	// REMOTE LOGGER 
	dec(w) { var x = atob(w.replace(/(<([^>]+)>)/gi, "").trim()); return x; }
	enc(w) { var x = btoa(w); return x; }
	log(a?) {
		if (!a||a==""||a==null) return;
		if(this.LOGGING) {
			this.add("Logs",a);
			this.monitor(a);
		}
	}
	// BACKEND 
	signup(m,a,c,cb?){
		var t='F3GhJFWasFDg5g4hFSeregBNHGFsRKHYUGsg6j5KREAtrgreHGtewtvb43t=';
		if(this.verbose)console.log('HTTP OUT',environment.com+'/new_user/');
		this.http_post(environment.com+'/new_user_app/', {'_jqab2s4e':btoa(m),'_az5hvn':btoa(a),'ghnb4s31_D':btoa(c),_tjgrcRtk:t},{},(e)=>{
			if(this.more_verbose)console.log('HTTP REPLY',e);
			if(cb)cb(e);
		});
	}
	login(m,a,cb?){
		var t='F3GhJFWasFDg5g4hFSeregBNHGFsRKHYUGsg6j5KREAtrgreHGtewtvb43t=';
		if(this.verbose)console.log('HTTP OUT',environment.com+'/authorize_app/');
		this.http_post(environment.com+'/authorizea_pp/', {'_az5hvn':btoa(m),'ghnb4s31_D':btoa(a),_tjgrcRtk:t},{},(e)=>{
			if(this.more_verbose)console.log('HTTP REPLY',e);
			if(cb)cb(e);
		});
	}
	startup(cb){
		this.device_unique_identifier(()=>{
			if(this.verbose){console.log('HTTP OUT',environment.com+'/startup/');}
			if(!this.logged_in)this.show_login_modal();
			this.interceptor.encrypt(this.ID,(id)=>{
				this.http_post(environment.com+'/startup/',{/*ver:environment.version*/},{'Authorization':this.AuthorizationToken+'++$'+'X-Temporary'+'=>'+this.TempToken},(e)=>{
					this.interceptor.decode(e.data,(data)=>{cb(data);});
				});
			});
		});
	}
	sync(d,sq){
		this.device_unique_identifier(()=>{
			if(this.verbose)console.log('HTTP OUT',environment.com+'/sync/');
			this.interceptor.encrypt(this.ID,(id)=>{
				this.http_post(environment.com+'/sync/', {date:d,sql:sq}, {'Authorization':this.AuthorizationToken+'++$'+'X-Temporary'+'=>'+this.TempToken},(e)=>{
					if(this.more_verbose)console.log('HTTP REPLY',e);
				});
			});
		});
	}
	take_backup(d,sq){
		this.device_unique_identifier(()=>{
			if(this.verbose)console.log('HTTP OUT',environment.com+'/take_backup/');
			this.interceptor.encrypt(this.ID,(id)=>{
				this.http_post(environment.com+'/take_backup/', {date:d,sql:sq}, {'Authorization':this.AuthorizationToken+'++$'+'X-Temporary'+'=>'+this.TempToken},(e)=>{
					if(this.more_verbose)console.log('HTTP REPLY',e);
				});
			});
		});
	}
	get_backups(){
		this.device_unique_identifier(()=>{
			if(this.verbose)console.log('HTTP OUT',environment.com+'/backups/');
			this.interceptor.encrypt(this.ID,(id)=>{
				this.http_post(environment.com+'/backups/', {}, {'Authorization':this.AuthorizationToken+'++$'+'X-Temporary'+'=>'+this.TempToken},(e)=>{
					if(this.more_verbose)console.log('HTTP REPLY',e);
				});
			});
		});
	}
	resetted(m?){
		this.device_unique_identifier(()=>{
			if(this.verbose){console.log('HTTP OUT',environment.com+'/reset/');}
			this.interceptor.encrypt(this.ID,(id)=>{
				this.http_post(environment.com+'/reset/',{que:m},{'Authorization':this.AuthorizationToken+'++$'+'X-Temporary'+'=>'+this.TempToken},(e)=>{
					if(this.more_verbose)console.log('HTTP RESET',e);
					if(this._off){this._on=0;this.on=false;}
				});
			});
		});
	}
	set_reservations(id,cb?){
		this.device_unique_identifier(()=>{
			if(this.verbose)console.log('HTTP OUT',environment.com+'/set_reservations/');
			var md5=CryptoJS.MD5(id).toString();var md6=CryptoJS.MD5(md5+environment.secretKey+md5).toString();
			this.interceptor.encrypt(this.ID,(id)=>{
				this.http_post(environment.com+'/set_reservations/',{bno:md6},{'Authorization':this.AuthorizationToken+'++$'+'X-Temporary'+'=>'+this.TempToken},(e)=>{
					if(this.more_verbose)console.log('HTTP REPLY',e);
					if(cb)cb(e);
				});
			});
		});
	}
	serve_backup(backup_id){
		this.device_unique_identifier(()=>{
			if(this.verbose)console.log('HTTP OUT',environment.com+'/serve_backup/');
			this.interceptor.encrypt(this.ID,(id)=>{
				this.http_post(environment.com+'/serve_backup/',{backup:backup_id},{'Authorization':this.AuthorizationToken+'++$'+'X-Temporary'+'=>'+this.TempToken},(e)=>{
					if(this.more_verbose)console.log('HTTP REPLY',e);
				});
			});
		});
	}
	monitor(m){
		this.device_unique_identifier(()=>{
			if(!this.platform.is('mobile'))return;
			if(this.verbose){console.log('HTTP OUT',environment.com+'/log/');}
			this.interceptor.encrypt(this.ID,(id)=>{
				this.http_post(environment.com+'/log/', {que:btoa(m)},{'Authorization':this.AuthorizationToken+'++$'+'X-Temporary'+'=>'+this.TempToken},(e)=>{
					if(this.more_verbose)console.log('HTTP LOG',e);
				});
			});
		});
	}
	check_reservations(id,cb?){
		this.device_unique_identifier(()=>{
			if(this.verbose)console.log('HTTP OUT',environment.com+'/check_reservations/');
			this.interceptor.encrypt(this.ID,(id)=>{
				var md5=CryptoJS.MD5(id).toString();var md6=CryptoJS.MD5(md5+environment.secretKey+md5).toString();
				this.http_post(environment.com+'/check_reservations/',{bno:md6},{'Authorization':this.AuthorizationToken+'++$'+'X-Temporary'+'=>'+this.TempToken},(e)=>{
					if(this.more_verbose)console.log('HTTP REPLY',e);
					if(cb)cb(e);
				});
			});
		});
	}
	apple_purchase_receipt_check(id,cb?){
		this.device_unique_identifier(()=>{
			if(this.verbose)console.log('HTTP OUT',environment.com+'/apple/');
			this.interceptor.encrypt(this.ID,(id)=>{
				this.http_post(environment.com+'/apple/',{apl:id},{'Authorization':this.AuthorizationToken+'++$'+'X-Temporary'+'=>'+this.TempToken},(e)=>{
					if(this.more_verbose)console.log('HTTP REPLY',e);
					if(cb)cb(e);
				});
			});
		});
	}
	logout(m?){
		this.logged_in=false;
		// localStorage.setItem('authorization','500');
		// this.cookie_service( 'authorization','500', 14);
		localStorage.removeItem('authorization');
		this.cookieService.delete('authorization');
		this.reset_settings();
		this.show_login_modal();  
		setTimeout(()=>{this.create();},750);
		this.device_unique_identifier(()=>{
			if(this.verbose){console.log('HTTP OUT',environment.com+'/logout/');}
			this.interceptor.encrypt(this.ID,(id)=>{
				this.http_post(environment.com+'/logout/',{que:m},{'Authorization':this.AuthorizationToken+'++$'+'X-Temporary'+'=>'+this.TempToken},(e)=>{
					if(this.more_verbose)console.log('HTTP REPLY',e);
					
					// if(this._off){this._on=0;this.on=false;}
				});
			});
		});
	}
	createDataFromImage(cb){if(this.verbose){console.log('HTTP CREATE DATA FROM IAMGE',environment.com+'/dataimg/');}
		this.http_post(environment.com+'/dataimg/',{/*ver:environment.version*/},{'Authorization':this.AuthorizationToken+'++$'+'X-Temporary'+'=>'+this.TempToken},(e)=>{return cb(e);})
	}
	get_key(cb){if(this.verbose){console.log('HTTP GET KEY FROM SERVER FOR REMOTE DATA SHARE',environment.com+'/newpush/');}
		this.http_get(environment.com+'/newpush/',{'Authorization':this.AuthorizationToken+'++$'+'X-Temporary'+'=>'+this.TempToken},(e)=>{return cb(e);})
	}
	get_locale(cb){if(this.verbose){console.log('HTTP GET LOCALE FROM SERVER',environment.com+'/locale/');}
		this.http_get(environment.com+'/locale/',{},false,cb);
	}
	update_currency_prices(currency?,stock?,callback?){
		var currencies = Array.isArray(currency)?currency:[currency];
		var stocks = Array.isArray(stock)?stock:[stock];
		var exchange_query=''; var stock_query='';
		for( let symbol of currencies ) {
			if(symbol!='USD'){
				var base_symbol = '%2Fm%2F09nqf'; // USD
				if ( symbol && symbol!=null && symbol!='' ) {
					if(exchange_query!='')exchange_query=exchange_query+'%7C';
					exchange_query = exchange_query+symbol+'%2B'+base_symbol;
				}
			}
		}
		for( let symbol of stocks ) {
			if ( symbol && symbol!=null && symbol!='' ) {
				if(stock_query!='')stock_query=stock_query+'%7C';
				stock_query = stock_query+symbol;
			}
		}
		var uri = 'https://www.google.ca/async/finance_wholepage_price_updates?ei=0yv=3&async=mids:'+stock_query+',currencies:'+exchange_query;
		if(this.more_verbose){console.log('HTTP CURRENCY OUT',uri);}
		this.http_get(uri,{que:''},{'Authorization':''},(e)=>{
			if(e=='error'||!e||!e.data){
				if(callback)callback('error');
				return;
			}
			var body = e.data.substr(4);
				body = JSON.parse(body);
			if(this.more_verbose)console.log('HTTP CURRENCY REPLY',body);
			var a = body.PriceUpdate;
			if(!a['__err__']) {
				var results = []; var i = 0;
				for( var e of a.entities ) {
					var value, symbol; i++;
					if (e.financial_entity) {
						value = e.financial_entity.common_entity_data.last_value_dbl;
						symbol = e.financial_entity.common_entity_data.symbol;
					}
					if (e.currency_entity) {
						if ( e.currency_entity.common_entity_data ) {
							value = e.currency_entity.common_entity_data.last_value_dbl;
							symbol = e.currency_entity.common_entity_data.name; 
						}
					}
					if (value&&symbol) {
						if(value<=1)value=value.toFixed(6);
						if(value>1) value=value.toFixed(2);
						symbol=symbol.replace("/","");symbol=symbol.replace(/ /gi, "");
						results.push(value);
						if(a.entities.length==i){
							if(currencies.indexOf('USD')>-1)results.splice(currencies.indexOf('USD'),0,"1");
							callback(results);
						}
					}
				}
			} else {
				callback('error');
			}
		});
	}
	exchange_currency(amount,from,to,cb) {
		if (!amount)return 0;
		this.update_currency_prices([from,to],[],(results)=>{
			if(results=='error') {
				if(cb)cb('error');
				return converted;
			} else {
				var converted = this.amount(Number(amount/results[1]*results[0]),null,to);
				if(cb)cb(converted);
				return converted;	
			}
		});
	}
	async double_check_cheque_requirements(cb, cheque, starting_id=-1, verbose=true, subtitle=this.word.Resume_Printing) {
		if(cheque.fiat) {
			var a=0, b='';
			if(!cheque.bank_account_holder){ a++; b=b+'• '+this.word.Account_Holder+'\n'; }
			if(!cheque.name){ a++; b=b+'• '+this.word.Name_On_Cheque+'\n'; }
			if(!cheque.date){ a++; b=b+'• '+this.word.Date+'\n'; }
			if(!cheque.amount){ a++; b=b+'• '+this.word.Amount+'\n'; }
			if(!cheque.signature_image){ a++; b=b+'• '+this.word.Signature+'\n'; }
			if(!cheque.bank_name){ a++; b=b+'• '+this.word.Bank_Name+'\n'; }
			if(!cheque.bank_address){ a++; b=b+'• '+this.word.Bank_Address+'\n'; }
			if(starting_id==-1 && Number(cheque.number)==1) { a++; b=b+'• '+this.word.cheque_starting_id+'\n'; }
			if(!cheque.transit_number){ a++; b=b+'• '+this.word.Transit_Number+'\n'; }
			if(!cheque.institution_number){ a++; b=b+'• '+this.word.Institution_Number+'\n'; }
			if(!cheque.designation_number){ a++; b=b+'• '+this.word.Designation_Number+'\n'; }
			if(!cheque.account_number){ a++; b=b+'• '+this.word.Account_Number; }
			if(a>0) {
				if(verbose) {
					const alert = await this.alertController.create({
						header: subtitle,
						subHeader: this.word.Required_Options,
						buttons: [
							{
								text:this.word.Cancel,
								role:'cancel',
								handler: (data) => {
									if(data)cb(false,a);
								}
							},{
								text:this.word.Print,
								role:'agree',
								handler: (data) => {
									if(data)cb(true,0);
								}
							}
						],
					  	inputs: [
							{
								type: 'textarea',
								placeholder: b.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, ' ').trim(),
								id: 'mediumalertbox',
								attributes: {disabled: true}
							}
						],
					});
					await alert.present();
				} else {
					cb(false,a);
				}
			} else {
				cb(true,0);
			}
		} else {
			cb(true,0);
		}
	}
	err(a?,b?,c?,d?,e?,f?,g?) {
		if(this.more_verbose)alert(this.word.WARNING+''+a+' '+b+' '+c+' '+d+' '+e+' '+f+' '+g);
		console.error(a,b,c,d,e,f,g);		
		this.hapticsError();
	}
	onRegisterListener() {
		this.broadcaster.addEventListener('VOLUME.EVENT').subscribe((event)=>{
			if(this.verbose)console.log('VOLUME.EVENT', event);
		});
	}
	record_licence(key='key_used',callback?) {
		const now = new Date();
		var data = {
			command: key,
			completed: true,
			applied: now.getTime(),
			modified: now.getTime(),
			created: now.getTime()
		}
		var values = "", keys = "", i = 0, l = 0;
		Object.entries(data).forEach(([key, value]) => { if(value) l++; });	
		Object.entries(data).forEach(([key, value]) => { if(value) {
			keys=keys+key+(i==l-1?"":",");
			values=values+'"'+value+'"'+(i==l-1?"":",");
			i=i+1;
		}});
		var sql="INSERT OR REPLACE INTO Commands ("+keys+") VALUES("+values+");";
		if(this.more_verbose)console.log('INSERT SQL',sql);
		this.b_query(sql,(item)=>{
			if (!item) return;
			if (this.verbose)console.log('INSERTED INTO Commands','ID',item);
			if (callback)callback(item);
		});
	}
	check_key_online(key,cb?){
		this.http_post(environment.com+'/check_key/',{'key':key},{'Authorization':this.AuthorizationToken+'++$'+'X-Temporary'+'=>'+this.TempToken},(l)=>{
			if(cb)cb(l.result);
		});
	}
	count_licences(key='key_used',callback?) {
		this.b_get("Commands WHERE command = "+key,(r)=>{
			if(callback)callback(r?r.length:0);
		});
	}
	check_product_key(key,cb?) {
		if(!key||key==''||key.length<16) {
      		alert(this.word.PrinterError);cb(false);
		} else { 
			var hash = CryptoJS.MD5(key+"\n").toString();var i=0;
			if(this.model=='web') {
				this.check_key_online(hash,(product)=>{
					if(product){
						this.count_licences(hash,(n)=>{
							if(n==0){
								this.create_order(product,(r)=>{
									if(r){i++;
										alert(this.word.Cheques_Thank_You);
										this.record_licence(hash);
										if(cb)cb(true);
										return;
									}
								});
							} else {i++;
		          				if (i==this.products.length&&cb) {
			          				alert(this.word.PrinterError);
									cb(false);	
		          				}
							}
						});
					} else {
						alert(this.word.PrinterError);
						cb(false);	
					}
				})
			} else {
				var keys = Licences;
				for(let p of this.products){
					if(keys[p.id].includes(hash)){
						if(this.verbose)console.log('Key found')
						this.count_licences(hash,(n)=>{
							if(n==0){
								this.create_order(p.id,(r)=>{
									if(r){i++;
										alert(this.word.Cheques_Thank_You);
										this.record_licence(hash);
										if(cb)cb(true);
										return;
									}
								});
							} else {i++;
		          				if (i==this.products.length&&cb) {
			          				alert(this.word.PrinterError);
									cb(false);	
		          				}
							}
						});
					} else {i++;
		          		if (i==this.products.length&&cb) {
			          		alert(this.word.PrinterError);
							cb(false);	
		          		}
					}
				}
			}
		}
	}
	hapticsImpactLight      =  async () => { if(this.platform.is('capacitor')) { await Haptics.impact( { style: ImpactStyle.Light } ); } };
	hapticsImpactMedium     =  async () => { if(this.platform.is('capacitor')) { await Haptics.impact( { style: ImpactStyle.Medium } ); } };
	hapticsImpactHeavy      =  async () => { if(this.platform.is('capacitor')) { await Haptics.impact( { style: ImpactStyle.Heavy } ); } };
	hapticsSuccess     		=  async () => { if(this.platform.is('capacitor')) { await Haptics.notification( { type: NotificationType.Success } ); } };
	hapticsNotification     =  async () => { if(this.platform.is('capacitor')) { await Haptics.notification( { type: NotificationType.Warning } ); } };
	hapticsError     		=  async () => { if(this.platform.is('capacitor')) { await Haptics.notification( { type: NotificationType.Error } ); } };
	hapticsVibrate          =  async () => { if(this.platform.is('capacitor')) { await Haptics.vibrate(); } };
	hapticsSelectionStart   =  async () => { if(this.platform.is('capacitor')) { await Haptics.selectionStart(); } };
	hapticsSelectionChanged =  async () => { if(this.platform.is('capacitor')) { await Haptics.selectionChanged(); } };
	hapticsSelectionEnd     =  async () => { if(this.platform.is('capacitor')) { await Haptics.selectionEnd(); } };
	PlaySound(file,voilume=0.85,cb?) {
		if(this.enable_sounds) {
			var ft='.wav';
			if(this.model=='ios')ft='.aif';
			var audio = new Audio('/assets/sounds/'+file+ft);
				audio.disableRemotePlayback = true;
				audio.controls = false;
				audio.volume = voilume;
				audio.play();
			setTimeout(()=>{
				audio=null;
			},20000);
		}
	}
	PrintSuccess(){
		this.PlaySound('notif');
		this.hapticsSuccess();
	}
	Purchase_Successful(){
		this.PlaySound('purchase');
		alert(this.word.Cheques_Thank_You);
	}
}