export class NewCheque {
	constructor(
		public cheque_id?: number,
		public printed_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public employee_id?: number,
		public address_id?: number,
		public payee_id?: number,
		public payroll_id?: number,
		public payment_id?: number,
		public design_id?: number,
		public entry_ids?: string,
		public status?: number,
		public image?: string,
		public image_path?: string,
		public html?: string,
		public printed?: boolean,
		public mailed?: boolean,
		public applied?: boolean,
		public posted?: boolean,
		public fiat?: string,
		public name?: string,
		public printed_memo?: string,
		public taxes?: string,
		public schedules?: string,
		public vacations?: string,
		public deductions?: string,
		public notes?: string,
		public employees?: any,
		public payees?: any,
		public banks?: any,
		public addresses?: any,
		public address?: string,
		public address_2?: string,
		public address_name?: string,
		public entries?: any,
		public city?: string,
		public province?: string,
		public country?: string,
		public postal?: string,
		public payee_title?: string,
		public bank_name?: string,
		public bank_account_holder?: string,
		public bank_address?: string,
		public bank_address_2?: string,
		public bank_address_3?: string,
		public bank_city?: string,
		public bank_province?: string,
		public bank_country?: string,
		public bank_postal?: string,
		public bank_image?: string,
		public employee_name?: string,
		public cheque_logo_image?: string,
		public company_address_id?: any,
		public company_name?: string,
		public company_city?: string,
		public company_address?: string,
		public company_country?: string,
		public company_province?: string,
		public company_address_2?: string,
		public company_postal_code?: string,
		public design?: any,
		public design_background_colour?: string,
		public design_pattern?: string,
		public currency?: string,
		public currency_name?: string,
		public currency_symbol?: string,
		public key_currency?: string,
		public written_amount?: string,
		public amount?: any,
		public number?: any,
		public cheque_number?: string,
		public transit_number?: string,
		public institution_number?: string,
		public designation_number?: string,
		public account_number?: string,
		public identifier?: any,
		public fob?: any,
		public media_type?: any,
		public signature?: any,
		public signature_image?: any,
		public signature_name?: any,
		public signature_description?: any,
		public signature_fill_color?: any,
		public number_fill_color?: any,
		public micr_image?: any,
		public printed_date?: any,
		public key?: string,
		public data?: string,
		public date?: any,
		public date_y?: any,
		public date_yy?: any,
		public date_yyy?: any,
		public date_yyyy?: any,
		public date_m?: any,
		public date_mm?: any,
		public date_d?: any,
		public date_dd?: any,
		public modified?: any,
		public created?: any
	){}
}
export const Create_Cheques = '\
	CREATE TABLE IF NOT EXISTS "Cheques" (\
		cheque_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		printed_id INTEGER DEFAULT 402,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		employee_id INTEGER DEFAULT 0,\
		address_id INTEGER DEFAULT 0,\
		payee_id INTEGER DEFAULT 0,\
		payroll_id INTEGER DEFAULT 0,\
		payment_id INTEGER DEFAULT 0,\
		design_id INTEGER DEFAULT 0,\
		company_address_id INTEGER DEFAULT 0,\
		status INTEGER DEFAULT 0,\
		printed INTEGER DEFAULT 0,\
		mailed INTEGER DEFAULT 0,\
		applied  INTEGER DEFAULT 0,\
		posted  INTEGER DEFAULT 0,\
		entry_ids  TEXT,\
		entries  TEXT,\
		fiat  TEXT,\
		name TEXT,\
		printed_memo TEXT,\
		taxes TEXT,\
		schedules TEXT,\
		vacations TEXT,\
		deductions TEXT,\
		notes TEXT,\
		employees TEXT,\
		payees TEXT,\
		banks TEXT,\
		addresses TEXT,\
		address TEXT,\
		address_2 TEXT,\
		address_name TEXT,\
		city TEXT,\
		province TEXT,\
		country TEXT,\
		postal TEXT,\
		payee_title TEXT,\
		account_holder TEXT,\
		bank_name TEXT,\
		bank_account_holder TEXT,\
		bank_address TEXT,\
		bank_address_2 TEXT,\
		bank_address_3 TEXT,\
		bank_city TEXT,\
		bank_province TEXT,\
		bank_country TEXT,\
		bank_postal TEXT,\
		bank_image TEXT,\
		employee_name TEXT,\
		cheque_logo_image TEXT,\
		company_name TEXT,\
		company_city TEXT,\
		company_address TEXT,\
		company_country TEXT,\
		company_province TEXT,\
		company_address_2 TEXT,\
		company_postal_code TEXT,\
		design TEXT,\
		design_pattern TEXT,\
		design_background_colour TEXT,\
		image TEXT,\
		image_path TEXT,\
		html TEXT,\
		currency TEXT,\
		key_currency TEXT,\
		currency_name TEXT,\
		currency_symbol TEXT,\
		written_amount TEXT,\
		amount TEXT,\
		number TEXT,\
		cheque_number TEXT,\
		transit_number TEXT,\
		institution_number TEXT,\
		designation_number TEXT,\
		account_number TEXT,\
		identifier TEXT,\
		fob TEXT,\
		media_type TEXT,\
		signature TEXT,\
		signature_image TEXT,\
		signature_name TEXT,\
		signature_description TEXT,\
		signature_fill_color TEXT,\
		number_fill_color TEXT,\
		micr_image TEXT,\
		printed_date TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		date TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		key TEXT,\
		data TEXT,\
		date_y TEXT,\
		date_yy TEXT,\
		date_yyy TEXT,\
		date_yyyy TEXT,\
		date_m TEXT,\
		date_mm TEXT,\
		date_d TEXT,\
		date_dd TEXT,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';