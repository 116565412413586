import { SQLService } from '../../services/sql.service';
import { Flip } from '../../app.animations';
import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController, NavParams } from '@ionic/angular';
@Component({
  selector: 'app-select-employee',
  templateUrl: './select-employee.page.html',
  styleUrls: ['./select-employee.page.styl'],
  animations: [ Flip ]
})
export class SelectEmployeePage implements OnInit {
  constructor(
    private router: Router,
    public sql: SQLService,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    private params: NavParams,
    private location: Location
  ) {
    var s = params.get('selected');
    if(s)this.selected = s.split(',');
    this.multiple = params.get('select_multiple');
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_employees();
    });
  }
  count = -1;
  output = new EventEmitter();
  employees =[];
  selected =[];
  multiple: boolean;
  @Input('set_table') table:string;
  @Input('set_model') model:string;
  @Input('set_model_id') id:string;
  ngOnInit() {
    this.get_employees();
    this.id = this.route.snapshot.paramMap.get("id");
    this.table = this.route.snapshot.paramMap.get("table");
    this.model = this.route.snapshot.paramMap.get("model");
  }
  get_employees(event?) {
    this.sql.fetch('Employees',(e)=>{
      this.employees=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  select(e) {
    if(this.multiple){
      this.toggle_item(e);
    } else {
      this.navCtrl.dismiss(e.employee_id);
    }
  }
  done_multiple() {
    this.navCtrl.dismiss(this.selected.join(','));
  }
  toggle_item(item) {
    if( this.selected.includes(item.employee_id) ) {
      this.deselect_item(item);
    } else {
      this.select_item(item);
    }
  }
  select_none() {this.navCtrl.dismiss(-1);}
  select_item(item) {
    console.log(item.employee_id);
    this.selected.push(item.employee_id);
  }
  deselect_item(item) {
    var new_selection=[];
    for(var i = 0; i < this.selected.length; ++i){
      if(this.selected[i]!=item.employee_id){
        new_selection.push(this.selected[i]);
      }
    }
    this.selected = new_selection;
  }
  select_all() {
    var new_selection=[];
    for (var i = 0; i < this.employees.length; ++i) {
      new_selection.push(this.employees[i].employee_id);
    }
    this.selected=new_selection;
  }
  deselect_all() {
   this.selected = []; 
  }
  add() {
    this.sql.count('Employees',(c)=>{
      if(this.sql.Trial && this.sql.Trial_Employees!=-1 && c>=this.sql.Trial_Employees) {
        this.sql.show_purhchase_modal();
      } else {
        this.router.navigate(['folder','Employee','New']);
      }
    });
    this.navCtrl.dismiss();
  }
  off() {
    // this.location.back();
    if(this.multiple){
      this.done_multiple();
    } else {
      this.navCtrl.dismiss();
    }
  }
}