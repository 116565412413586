import { environment } from '../../../../environments/environment';
import { SQLService } from '../../../services/sql.service';
import { Component, OnInit, Input, EventEmitter,ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Platform } from '@ionic/angular';
import { ModalController, NavParams } from '@ionic/angular';
import * as Languages from './languages/index';
@Component({
	selector: 'app-terms-of-use',
	templateUrl: './terms-of-use.component.html',
	styleUrls: ['./terms-of-use.component.styl']
})
export class TermsOfUseComponent implements OnInit {
	constructor(
		private router: Router,
		public sql: SQLService,
		private route: ActivatedRoute,
		private navCtrl: ModalController,
    	private params: NavParams,
		private current_platform: Platform,
		private location: Location
	) {
    	this.force = params.get('force');
		this.router.events.subscribe((eve) => {
			//if(eve instanceof NavigationEnd) this.get_purchase();
		});
	}
	force=true;
	contents='';
	ngOnInit() {
		this.contents=Languages[this.sql.Language.replace('-','').toLowerCase()].translation;
	}
	end(agreement=false) {
		if(!agreement&&this.force)alert(this.sql.word.Terms_Required_Word);
		if(agreement||this.force==false)this.navCtrl.dismiss(agreement);
	}
}