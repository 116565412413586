import { SQLService } from '../../../../services/sql.service';
import { Flip } from '../../../../app.animations';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-select-printjob',
  templateUrl: './select-printjob.page.html',
  styleUrls: ['./select-printjob.page.styl'],
  animations: [ Flip ]
})
export class SelectPrintJobsPage implements OnInit {
  constructor(
    public sql: SQLService,
    private navCtrl: ModalController,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_jobs();
    });
  }
  count = -1; 
  jobs = [];
  search:string;
  filter(e) { this.search=e.target.value; }
  get_jobs(event?) {
    this.sql.fetch('PrintJobs',(e)=>{
      this.jobs=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  pause_resume() {
    this.sql.printing_paused = this.sql.printing_paused ? false : true;
  }
  edit_job(print_job, item?) {
    this.router.navigate(['folder','Printer','Job',print_job.job_id]);
  }
  delete_job(print_job, item) {
    if(confirm(this.sql.word.Discard_Confirmation)) {
      this.sql.delete('PrintJobs','printer_id',print_job.job_id);
      this.get_jobs();
    } else { item.closeSlidingItems(); }
  }
  off() {
    this.navCtrl.dismiss();
  }
  ngOnInit() {
    this.get_jobs();
  }
}