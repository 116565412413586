import { NewPayment } from '../../models/payment';
import { SQLService } from '../../services/sql.service';
import { PayrollService } from '../../services/payroll.service';
import { environment } from '../../../environments/environment';
import { SelectBankPage } from '../../settings/bank-accounts/select-bank/select-bank.page';
import { SelectAddressPage } from '../../addresses/select-address/select-address.page';
import { SelectEmployeePage } from '../../employees/select-employee/select-employee.page';
import { SelectPayeePage } from '../../payees/select-payee/select-payee.page';
import { SendPayPalPage } from '../paypal-payment/paypal-payment.page';
import { SelectPaypalPaymentsComponent } from '../../settings/paypal-payments/select-paypal-payments/select-paypal-payments.component';
import { ModalController, Platform } from '@ionic/angular';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'ng-cheques-new-payment',
  templateUrl: './new-payment.component.html',
  styleUrls: ['./new-payment.component.styl']
})
export class NewPaymentPage implements OnInit, OnDestroy {
  currencies = Object.keys(environment.currencies);
  paypal_currencies = [];
  employee = [];
  _c=0;
  payment = new NewPayment();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sql: SQLService,
    private payroll: PayrollService,
    private location: Location,
    public modalController: ModalController,
    public platform: Platform
  ) { 
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.open();
    });
  }
  change_payment_type(e) {
    if(e.target.value=='paypal'&&(this.sql.Trial||!this.sql.paypal_payouts)) {
      alert(this.sql.word.PayPal_Payouts_Word);
      this.sql.show_purhchase_modal();
      setTimeout(()=>{this.payment.type='cheque';},500);
    } else {
      this.payment.type=e.target.value;
    }
  }
  async add_employee() {
    const m = await this.modalController.create({component: SelectEmployeePage, componentProps: {select_multiple: false}});
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data){
      this._c=1;
      if(a.data==-1){
        this.delete_employee();
        this.sql.hapticsImpactLight();
      } else {
        this.payment.employee_ids = a.data;

        this.get_employees();
        this.delete_payee();
        this.sql.hapticsImpactMedium();
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_employee(employee) {
    this.router.navigate(['folder','Employee', employee.employee_id]); 
  }
  delete_employee() {
    this.payment.employee_ids = null;
    this.payment.employees = null;

    // this.sql.set('Payment','payment_id',this.payment.payment_id,'employees',null);
  }
  get_employees(cb?) {
    if(!this.payment.employee_ids){if(cb)cb();return;}
    if(typeof this.payment.employee_ids === 'string'){
      this.payment.employee_ids=this.payment.employee_ids.split(',');
    } else if (typeof this.payment.employee_ids === 'number') {
      this.payment.employee_ids=[this.payment.employee_ids];
    }
    this.payment.employees=[];
    for(let a of this.payment.employee_ids) {
      this.sql.view('Employees','employee_id',a,(data)=>{
        for(let d of data)this.payment.employees.push(d);
        this.payment.email = this.payment.employees[0].email;
        if(cb)cb();
      });
    }
  }
  async add_payee() {
    const m = await this.modalController.create({component: SelectPayeePage, componentProps: {select_multiple: false}});
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data) {
      this._c=1;
      if(a.data==-1) {
        this.delete_payee();
        this.sql.hapticsImpactLight();
      } else {
        this.payment.payee_ids = a.data;

        this.get_payees();
        this.delete_employee();
        this.sql.hapticsImpactMedium();
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_payee(payee) {
    this.router.navigate(['folder','Payee', payee.payee_id]); 
  }
  delete_payee() {
    this.payment.payee_ids = null;
    this.payment.payees = null;

    // this.sql.set('Payment','payment_id',this.payment.payment_id,'payees',null);
  }
  get_payees(cb?) {
    if(!this.payment.payee_ids){if(cb)cb();return;}
    if(typeof this.payment.payee_ids === 'string'){
      this.payment.payee_ids=this.payment.payee_ids.split(',');
    } else if (typeof this.payment.payee_ids === 'number') {
      this.payment.payee_ids=[this.payment.payee_ids];
    }
    this.payment.payees=[];
    for(let a of this.payment.payee_ids) {
      this.sql.view('Payees','payee_id',a,(data)=>{
        for(let d of data)this.payment.payees.push(d);
        this.payment.email = this.payment.payees[0].email;
        if(cb)cb();
      });
    }
  }
  async add_address() {
    const m = await this.modalController.create({component: SelectAddressPage, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data){
      this._c=1;
      if(a.data==-1){
        this.delete_address();
        this.sql.hapticsImpactLight();
      } else {
        this.payment.address_ids = [a.data];

        this.get_address();
        this.sql.hapticsImpactMedium();
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_address(a?) {
    this.router.navigate(["folder","Address",a.address_id]);
  }
  delete_address(j?) {
    this.payment.addresses = null;
    this.payment.address_ids = null;

    // this.sql.set('Payment','payment_id',this.payment.payment_id,'addresses',null);
  }
  get_address(cb?) {
    if(!this.payment.address_ids){if(cb)cb();return;}
    if(typeof this.payment.address_ids === 'string') {
      this.payment.address_ids=this.payment.address_ids.split(',');
    } else if (typeof this.payment.address_ids === 'number') {
      this.payment.address_ids=[this.payment.address_ids];
    }
    this.payment.addresses=[];
    for(let a of this.payment.address_ids) {
      this.sql.view('Addresses','address_id',a,(data)=>{
        for(let d of data)this.payment.addresses.push(d);
        if(cb)cb();
      });
    }
  }
  async add_paypal() {
    const m = await this.modalController.create({
      component: SelectPaypalPaymentsComponent, 
      componentProps: {select_multiple: false}
    });
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1){
        this.delete_paypal();
        this.sql.hapticsImpactLight();
      } else {
        this.payment.paypal_ids = a.data;
        this._c=1;

        this.get_paypals();
        this.sql.hapticsImpactMedium();
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_paypal(paypal) {
    this.router.navigate(['folder','Settings', 'PayPal', paypal.paypal_id]); 
  }
  delete_paypal() {
    // this.sql.set( 'Payrolls', 'payroll_id', this.payment.payroll_id, 'banks', null);
    this.payment.paypals=null;
    this.payment.paypal_ids=null;

  }
  get_paypals(cb?) {
    if(!this.payment.paypal_ids){if(cb)cb();return;}
    if(typeof this.payment.paypal_ids === 'string'){
      this.payment.paypal_ids=this.payment.paypal_ids.split(',');
    } else if (typeof this.payment.paypal_ids === 'number') {
      this.payment.paypal_ids=[this.payment.paypal_ids];
    }
    this.payment.paypals=[];
    for(let a of this.payment.paypal_ids) {
      this.sql.view('PayPals','paypal_id',a,(data)=>{
        for(let d of data)this.payment.paypals.push(d);
        if(cb)cb();
      });
    }
  }
  async add_bank_account() {
    if (this.payment.payment_id) {
      this.sql.set_temporary('page',{location:'Payment',id:this.payment.payment_id});
    } else {
      this.save(false,(id) =>{
        if(!id)return;
        this.payment.payment_id=id;
        this.sql.set_temporary('page',{location:'Payment',id:id});
      });
    }
    const m = await this.modalController.create({component: SelectBankPage, componentProps: {}});
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data) {
      this._c=1;
      if(a.data==-1) {
        this.delete_bank_account();
        this.sql.hapticsImpactLight();
      } else {
        this.payment.bank_ids = a.data;

        this.get_bank_account();
        this.sql.hapticsImpactMedium();
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_bank_account(bank) {
    this.router.navigate(['folder','Account', bank.bank_id]); 
  }
  delete_bank_account() {
    this.payment.bank_ids = null;
    this.payment.banks = null;

    // this.sql.set('Payment','payment_id',this.payment.payment_id,'banks',null);
  }
  get_bank_account(cb?) {
    if(!this.payment.bank_ids){if(cb)cb();return;}
    if(typeof this.payment.bank_ids === 'string'){
      this.payment.bank_ids=this.payment.bank_ids.split(',');
    } else if (typeof this.payment.bank_ids === 'number') {
      this.payment.bank_ids=[this.payment.bank_ids];
    }
    this.payment.banks=[];
    for(let a of this.payment.bank_ids) {
      this.sql.view('BankAccounts','bank_id',a,(data)=>{
        for(let d of data)this.payment.banks.push(d);
        if(cb)cb();
      });
    }
  }
  end() {
    if (this._c>0) {
      if (confirm(this.sql.word.Discard_Confirmation)) {
        this.payment = new NewPayment();
        this.router.navigate(['folder','Payments']);
      }
    } else {
      this.router.navigate(['folder','Payments']);
    }
  }
  print() {
    this.save(false,(PaymentID)=>{
      if(!PaymentID)return;
      this.payment.payment_id=PaymentID;
      if(this.sql.verbose)console.log('generate_cheque', this.payment);
      this.payroll.generate_cheque(this.payment,(Cheque)=>{
        if(this.sql.verbose)console.log('New Cheque from Payment', Cheque);
        this.payment = new NewPayment();
        this._c=0;  
        this.router.navigate(["folder","Print","Cheque",Cheque.cheque_id]);
      });
    });
  }
  async send_paypal() {
    if((this.sql.Trial||!this.sql.paypal_payouts)) {
      alert(this.sql.word.PayPal_Payouts_Word);
      this.sql.show_purhchase_modal();
    } else {
      const m = await this.modalController.create({component: SendPayPalPage, componentProps: {payment_id: this.payment.payment_id}});
      await m.present();
      var a = await m.onDidDismiss();
      if(a.data){
        this._c=1;
        if(a.data==-1){
          this.sql.hapticsImpactLight();
        } else if(a.data==1) {
          this.payment.paid = true;
          this.save(false,(id) => {
            if(this.sql.verbose) {
              console.log('Payment ID',id,'Sent and Saved');
            }
          });
        }
        this.sql.remove_temporary('page');
      }
    }
  }
  toggle_paid(e){ 
    this._c=1;
    this.payment.paid=e;
  }
  get_properties() {
    for (var i of ['employee','payee']) {
      var a = this.route.snapshot.paramMap.get(i+'id');
      if (a) this.payment[i+'_ids']=a;
    }
    this.get_paypals(()=>{
      this.get_bank_account(()=>{
        this.get_employees(() =>{
          this.get_address(() =>{
            this.get_payees();
          });
        });
      });
    });
  }
  amount(event) {
    if(!event.target.value||event.target.value==0||isNaN(event.target.value)){
      if(isNaN(event.target.value)){
        var am = event.target.value.split('.');
        this.payment.amount = am[0]+'.'+am[1];
      } else {
        this.payment.amount = '0.00';
      }

    } else {
      var amount=this.sql.amount(event.target.value, null, this.payment.currency);
      if(this.sql.max_cheque_amount>0&&amount>this.sql.max_cheque_amount) {
        alert(this.sql.word.Max_Amount_Message+' '+this.sql.CurrencySymbol+this.sql.max_cheque_amount+' '+this.sql.Currency);
        this.payment.amount = this.sql.max_cheque_amount;

      } else {
        this.payment.amount = amount;

      }
    }
  }
  get_payment() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida){ 
      this.get_properties();
      return; 
    } else if (ida == 'New') {
      this.get_properties();
    } else {
      this.sql.view('Payments','payment_id',ida,(e)=>{
        if(e[0]){
          var f=Object.entries(e[0]);let n = new NewPayment();
          for(let t of f)n[t[0]]=t[1];this.payment=n;this._c=0;
          this.get_properties();
        }
      });
    }
  }
  save(end=true,cb?) {
    if(this._c>0){
      this.sql.add("Payments",this.payment,(insertId)=>{
        if(!insertId){
          return;
        } else {
          if(!cb && end){
            this.payment = new NewPayment();
            this._c=0;  
            this.end();
          } else if (cb) {
            cb(insertId);
          }
        }
      });
    }
  }
  delete_payment() {
    if(!this.payment.payment_id){
      this.end();
    } else if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('Payments','payment_id',this.payment.payment_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  open() {
    this.payment.date = new Date(Date.now()).toISOString();
    this.payment.enabled = true;
    this.payment.auto_send = true;
    this.payment.type = 'cheque';
    this.payment.currency=this.sql.Currency;
    this.get_payment();
  }
  ngOnInit() {
    this.paypal_currencies=[];
    for(var [key, value] of Object.entries(environment.currencies))if(value.paypal)this.paypal_currencies.push(value);
    // this.open();
  }
  ngOnDestroy() { this._c>0; }
}