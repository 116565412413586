import { NewCompanies } from '../../../models/companies';
import { SelectAddressPage } from '../../../addresses/select-address/select-address.page';
import { environment } from '../../../../environments/environment';
import { SQLService } from '../../../services/sql.service';
import { ImagePreviewComponent } from '../../../cheque/designs/image-preview/image-preview.component';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { Location } from '@angular/common';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
@Component({
  selector: 'app-new-company',
  templateUrl: './new-company.component.html',
  styleUrls: ['./new-company.component.styl'],
})
export class NewCompanyComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer:DomSanitizer,
    public sql: SQLService,
    private location: Location,
    public modalController: ModalController
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) {
      }
    });
  }
  @ViewChild('ImageCanvas') ImageCanvas:ElementRef;
  _c=0;
  company_image: SafeResourceUrl;
  company = new NewCompanies();
  currencies:any;
  end() {
    if (this._c>0) {
      if(confirm(this.sql.word.Discard_Confirmation)) {
        this.company = new NewCompanies();
        this.router.navigate(['folder','Companies']);
      }
    } else {
      this.router.navigate(['folder','Companies']);
    }
  }
  delete_company() {
    if(!this.company.company_id){
      this.end();
    }else {
      if(this.company.company_id==1){
        alert(this.sql.word.Cant_Delete_Default_Company_Word);
      } else {
        this.sql.reset(false,()=>{
          this.sql.b_delete('Companies','company_id',this.company.company_id,()=>{
            this.sql.change_default_company(1);
            this._c=0;
            this.end();
          });
          // setTimeout(()=>{this.sql.flush(company.company_id);},3000);  
        },this.sql.word.Delete_Company_Confirmation);
      }
    }
  }
  save_default() {
    this.save(false,(company_id)=>{
      if(!this.company.company_id)this.company.company_id=company_id;
      this.sql.change_default_company(this.company.company_id,()=>{
        this.company = new NewCompanies();
        this._c=0;
        this.router.navigate(['folder','Companies']);
      });
    });  
  }
  save(end=true,cb?) {
    if(end&&!this.company.name||this.company.name=="") {
      alert(this.sql.word.Enter_A_Company_Name);
    } else {
      this.sql.b_add("Companies",this.company,(a)=>{
        if(!a)return;
        if(end) {
          this.company = new NewCompanies();
          this._c=0;
          this.end();
        } if(cb)cb(a);
      });  
    }
  }
  async add_address() {
    const m = await this.modalController.create({component: SelectAddressPage, componentProps: {}});
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data){
      if(a.data==-1){
        this.delete_address();
        this.sql.hapticsImpactLight();
      } else {
        this._c=1;
        this.company.address_id = a.data;
        this.sql.view('Addresses','address_id',a.data,(a)=>{this.company.addresses=a;});
        this.sql.hapticsImpactMedium();
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_address(address) {
    this.router.navigate(['folder','Address', address.address_id]); 
  }
  delete_address() {
    this.company.addresses = false;
    this.company.address_id = null;
    // this.sql.set( 'Employees', 'company_id', this.company.company_id, 'addresses', null);
  }
  async preview() {
    const m = await this.modalController.create({
      component: ImagePreviewComponent,
      componentProps: {trash:true,src:this.company_image,title:this.sql.word.Company_Logo}
    });
    await m.present();
    let a = await m.onDidDismiss();console.log(a);
    if(a.data==1){
      this.company.image='';
      this.company_image=false;
    }
  }
  image_lib() {
    this.sql.select_image((file)=>{
      console.log('file',file)
      if (file.length==0) {
        this.company.image='';
        this.company_image=false;
      } else {
        this.company.image='data:image/png;base64, '+file;
        this.company_image=this.sanitizer.bypassSecurityTrustResourceUrl(this.company.image);
      }
    },500,500,87);
  }
  trash() {

  }
  save_as_default() {
    this.save(false,(id)=>{
      this.sql.change_default_company(id,()=>{
        this.company = new NewCompanies();
        this._c=0;
        this.end();
      });
    });
  }
  update() { 
    this.sql.b_update("Companies",this.company);
  }
  get_companies() {
    let ida = this.route.snapshot.paramMap.get("id");
    if (!ida) return;
    this.sql.b_view('Companies','company_id',ida,(e)=>{
      var f = Object.entries(e[0]); let n=new NewCompanies();
      for(let t of f) n[t[0]]=t[1]; 
      if(n.address_id&&n.address_id>0)this.sql.view('Addresses','address_id',n.address_id,(a)=>{n.addresses=a;});
      this.company = n;
      if(this.company.image)this.company_image=this.sanitizer.bypassSecurityTrustResourceUrl(this.company.image);
    });
  }
  open() {
    this.company.enabled = true;
    this.get_companies();
  }
  ngOnInit() {
    this.open();
  }
}
