import { Component, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { SQLService } from '../../../services/sql.service';
@Component({
  selector: 'app-three-cheques',
  templateUrl: './three-cheques.component.html',
  styleUrls: ['./three-cheques.component.scss'],
})
export class ThreeChequesComponent implements AfterViewInit {
  constructor(private sql: SQLService) { }
  @Output('loaded') loaded = new EventEmitter<boolean>();
  @Input('RenderMICR') RenderMICR = false;
  @Input('ImageMICR') ImageMICR = true;
  @Input('show') show = 'table';
  @Input('cheque') cheque: any;
  @Input('design') design: any;
  @Input('designer') designer: any;
  @Input('fold_line') fold_line: boolean;
  @Input('voidcheck') voidcheck: boolean;
  @Input('monochrome') monochrome: boolean;
  @Input('borders') borders: boolean;
  @Input('rotate') rotate: boolean;
  @Input('gradients') gradients: boolean;
  @Input('font') font: any;
  @Input('fiat') fiat: boolean;
  @Input('color') color: any;
  @Input('micr') micr: any;
  @Input('micr_top') micr_top: string;
  @Input('micr_right') micr_right: string;
  @Input('micr_bottom') micr_bottom: string;
  @Input('micr_left') micr_left: string;
  @Input('secret') secret = '';
  @Input('template') template: string;
  @Input('layers') layers: any;
  @Input('translations') translations = this.sql.word;
  @Input('fontsize') fontsize: boolean;
  @Input('bold') bold: boolean;
  @Input('italic') italic: boolean;
  @Input('scale') scale =0.9;
  @Input('margin_bottom') bottom = '1cm';
  @Input('margin_top') top = '0cm';
  @Input('show_cheque_number') show_cheque_number: boolean = true;
  @Input('show_cheque_date') show_cheque_date: boolean = true;
  @Input('show_micr') show_micr: boolean = true;
  @Input('cheque_background_image_repeat') cheque_background_image_repeat = false;
  @Input('background_position') background_position: string;
  @Input('cheque_text_color') cheque_text_color: string;
  @Input('cheque_background_image') cheque_background_image: any;
  @Input('cheque_logo_image') cheque_logo_image: any;
  @Input('cheque_header_image') cheque_header_image: any;
  @Input('cheque_bank_image') cheque_bank_image: any;
  images = [];
  loaded_component() {
    this.images.push(1);
    if(this.images.length==3) this.loaded.emit(true);
  }
  ngAfterViewInit() {
  }
}