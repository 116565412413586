import { SQLService } from '../../services/sql.service';
import { Flip } from '../../app.animations';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-select-signature',
  templateUrl: './select-signature.page.html',
  styleUrls: ['./select-signature.page.styl'],
  animations: [ Flip ]
})
export class SelectSignaturePage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    public sql: SQLService,
    private location: Location
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_signatures();
    });
  }
  signatures = [];
  output = new EventEmitter();
  @Input('set_table') table:string;
  @Input('set_model') model:string;
  @Input('set_model_id') id:string;
  ngOnInit() {
    this.get_signatures();
    this.id = this.route.snapshot.paramMap.get("id");
    this.table = this.route.snapshot.paramMap.get("table");
    this.model = this.route.snapshot.paramMap.get("model");
  }
  get_signatures(event?) {
    this.sql.fetch('Signatures',(e)=>{
      this.signatures=e;
      setTimeout(()=>{if (event) event.target.complete();},1234);
    });
  }
  select(e) { 
    if ( this.id ) {
      let a = 'signature';
      this.sql.query('UPDATE '+this.table+' '+( this.id?'WHERE '+this.model+' = '+this.id:'' )+' SET '+a+'_id = '+e[a+'_id']);
      this.location.back();
    } else {
      this.navCtrl.dismiss(e);
    }
  }
  add() {
    this.router.navigate(['folder','Signature','New']);
    this.navCtrl.dismiss();
  }
  off() {
    this.navCtrl.dismiss();
  }
}