import { SQLService } from '../../services/sql.service';
import { PopoverController, AlertController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'ng-cheques-accounts-popover',
  templateUrl: './popover-accounts.component.html',
  styleUrls: ['./popover-accounts.component.styl']
})
export class PopoverAccountsComponent implements OnInit {
  constructor(
    public sql: SQLService,
    public alertController: AlertController,
    public popover: PopoverController,
    private router: Router) {
  }
  account:boolean;
  comp_:boolean;
  _locked:boolean;
  count:number;
  companies = [];
  dismissPopover(e) {
    if(this.popover)this.popover.dismiss();
  }
  view_companies() {
    this.comp_=true;
  }
  get_companies(event?) {
    this.sql.b_fetch('Companies',(e)=>{
      this.companies=e;
      this.count=e.length;
      setTimeout(()=>{
        if(event)event.target.complete();
      },1234);
    });
  }
  add_company(e?) {
    this.router.navigate(['folder','Company','New']);
    this.dismissPopover(e);
  }
  manage_company(e?) {
    this.router.navigate(['folder','Companies']);
    this.dismissPopover(e);
  }
  signup(e?) {
    this.dismissPopover(e);
  }
  login(e?) {
    this.dismissPopover(e);
  }
  logout(e?) {
    this.sql.create();
    this.sql.startup((m)=>{
      
    });
    this.dismissPopover(e);
  }
  select_default_company(e,company) {
    this.sql.change_default_company(company.company_id,()=>{
      this.get_companies();
      this.sql.dashboard_loaded=false;
      this.dismissPopover(e);
    });
  }
  ngOnInit() {
    this.get_companies();
    this.sql.has_security();
    this._locked=this.sql.locked;
    this.account=this.sql.account;
  }
}