export class NewPhoneCall {
	constructor(
		public call_id?: number,
		public admin_id?: number,
		public employee_id?: number,
		public mod_id?: number,
		public name?: string,
		public to?: string,
		public from?: string,
		public date?: any,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_PhoneCalls = '\
	CREATE TABLE IF NOT EXISTS "PhoneCalls" (\
		call_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		employee_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		name TEXT,\
		receiver TEXT,\
		sender TEXT,\
		date TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';