import { SQLService } from '../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { InvoiceMenuComponent } from './invoice-menu/invoice-menu.component';
@Component({
  selector: 'ng-cheques-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.styl']
})
export class InvoicesComponent implements OnInit {
  constructor(
    private router: Router,
    public popoverController: PopoverController,
    public sql: SQLService
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_invoices();
    });
  }
  count=-1;
  search:string;
  invoices=[];
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:InvoiceMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='delete')this.delete(item, false);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  get_invoices(event?) {
    this.sql.fetch('Invoices',(e)=>{
      if(e)this.invoices=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  edit(invoice, item?) {
    this.router.navigate(['folder','Invoice',invoice.invoice_id]);
    if(item)item.closeSlidingItems();
  }
  delete(invoice, item?) {
    if(confirm(this.sql.word.Delete_Invoice_Confirmation)) {
      this.sql.delete('Invoices','invoice_id',invoice.invoice_id);
      this.get_invoices();
    } else { if(item)item.closeSlidingItems(); }
  }
  add() {
    this.router.navigate(['folder','Invoice','New']);
  }
  end() {
    this.router.navigate(['folder','Dashboard']);
  }
  filter(e) {
    this.search=e.target.value;
  }
  ngOnInit() {
    this.get_invoices();
  }
}