import { Component, OnInit, OnChanges, Input, ViewChild, HostListener,Output,EventEmitter } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { SQLService } from '../../../../services/sql.service';
import { page,fade,fade_in,display } from '../../../../app.animations';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { MenuController } from '@ionic/angular';
@Component({
  selector: 'app-intro-page-6',
  templateUrl: './6-sixth.page.html',
  styleUrls: ['./6-sixth.page.styl'],
})
export class IntroSixthPageComponent implements OnInit, OnChanges {
  ngOnChanges(obj){
  }
  @Input('save') _save:boolean
  @Output('complete') complete = new EventEmitter();
  @ViewChild('intro_slider') slider: IonSlides;
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
  }
  constructor(
    public menu: MenuController,
    public sql: SQLService,
    public router: Router) { 
  }
  ngOnInit() {}
}