import { SQLService } from '../../services/sql.service';
import { Flip } from '../../app.animations';
import { NewSignature } from '../../models/signature';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-cheques-view-signature',
  templateUrl: './view-signature.component.html',
  styleUrls: ['./view-signature.component.styl'],
  animations: [ Flip ]
})
export class ViewSignatureComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    public sql: SQLService,
    private location: Location
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_signatures();
    });
  }
  id;
  _c=0;
  count = -1;
  signature=new NewSignature();
  ngOnInit() {
    this.get_signatures();
  }
  end() {
    this.navCtrl.dismiss();
    this.signature = new NewSignature();
  }
  delete() {
    this.navCtrl.dismiss('delete');
  }
  save() {
    this.sql.add("Signatures",this.signature, (id) =>{
      this.signature.signature_id = id;
      this.navCtrl.dismiss(this.signature);
      this.signature = new NewSignature();
      this._c=0;
    });
  }
  clear() { this.navCtrl.dismiss('new'); }
  get_signatures() {
    if(!this.id) return;
    this.sql.view('Signatures','signature_id',this.id,(e)=>{
      if(e[0]){
        var f=Object.entries(e[0]);let n=new NewSignature();
        for(let t of f)n[t[0]]=t[1];this.signature=n;
      }
    });
  }
  off() {
    this.navCtrl.dismiss();
  }
}