import { SelectEmployeePage } from '../../employees/select-employee/select-employee.page';
import { environment } from '../../../environments/environment';
import { SQLService } from '../../services/sql.service';
import { NewSalary } from '../../models/salary';
import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';

@Component({
  selector: 'ng-cheques-new-salary',
  templateUrl: './new-salary.component.html',
  styleUrls: ['./new-salary.component.styl']
})
export class NewSalaryPage implements OnInit {
  constructor(
    private location: Location,
    public modalController: ModalController,
    private route: ActivatedRoute,
    private router: Router,
    public sql: SQLService
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.open();
    });
  }
  @Input('live_save') live_save = false;
  @Input('ShowHeader') ShowHeader = true;
  currencies = Object.keys(environment.currencies);
  salary = new NewSalary(0);
  _c=0;
  save(end=true, cb?) {
    this.sql.add("Salaries",this.salary,(insertId)=>{
      if(this.live_save){
        this.salary.salary_id=insertId;
        this._c=0;
      } else {
        if(!insertId)return
        if(end){
          this.salary = new NewSalary(0);
          this._c=0;
          if(!cb)this.end(insertId);
          if(cb)cb(insertId);    
        }
      }
    });
  }
  lv() {
    if(this.live_save){
      this.save(false);
    }
  }
  delete_salary() {
    if(!this.salary.salary_id){
      this.end();
    } else if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('Salaries','salary_id',this.salary.salary_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  end(id?) {
    this.sql.get_temporary('page',(page)=>{
      if (this._c>0) {
        if (confirm(this.sql.word.Discard_Confirmation)) {
          this.salary = new NewSalary(0);
          this.router.navigate(['folder','Salaries']);
        }
      } else {
        if(page) {
          this.router.navigate(['folder',page.location,page.id,'Salary',id]);
          setTimeout( () => { this.sql.remove_temporary('page'); }, 600);
        } else {
          this.router.navigate(['folder','Salaries']);
        }
      }
    });
  }
  set_temp() {
    if (this.salary.salary_id) {
      this.sql.set_temporary('page',{location:'Salary',id:this.salary.salary_id});
    } else {
      this.save(true,(id) =>{
        if(!id)return;
        this.salary.salary_id=id;
        this.sql.set_temporary('page',{location:'Salary',id:id});
      });
    }
  }
  async add_employee() {
    const m = await this.modalController.create({component: SelectEmployeePage, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data){
      if(a.data==-1){
        this.delete_employee();
        this.sql.hapticsImpactLight();
      } else {
        this._c=1;
        this.salary.employees=a.data;
        this.sql.hapticsImpactMedium();
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_employee(employee) {
    this.router.navigate(['folder','Employee', employee.employee_id]); 
  }
  delete_employee() {
    this.salary.employees=null;
    // this.sql.set( 'Salaries', 'salary_id', this.salary.salary_id, 'employees', null);
  }
  get_salary() {
    var ida = this.route.snapshot.paramMap.get("id");
    if(!ida) return;
    this.sql.view('Salaries','salary_id',ida,(e)=>{
      var f=Object.entries(e[0]); let n = new NewSalary(0);
      for(let t of f)n[t[0]]=t[1]; 
      this.salary=n;
      this.salary.amount=this.sql.amount(this.salary.amount,null,this.salary.currency);
    });
  }
  open() {
    this.salary.currency=this.sql.Currency;
    if(!this.salary.frequency)this.salary.frequency='hour';
    this.salary.enabled=true;
    this.get_salary();
  }
  ngOnInit() {
    // this.open();
  }
}