/**
 * Universal Paper Sizes [PrintMediaSize Enum]
 * @Microsoft Windows.Graphics.Printing
 * https://docs.microsoft.com/en-us/uwp/api/windows.graphics.printing.printmediasize?view=winrt-22000
 * 
 * The width, height are measured in mm
 * 
**/
export const PaperSizes = [
	{slug:"A4", name:"Default (A4)", id:"Default", value:0,width: '203mm', height: '254mm',rotate:false},
	{slug:"A0",name:"A0",id:"IsoA0",value:1,width:'841mm',height:'1189mm',rotate:false},
	{slug:"A1",name:"A1",id:"IsoA1",value:2, width: '594mm', height:'841mm',rotate:false},
	{slug:"A2",name:"A2",id:"IsoA2",value:3, width: '420mm', height:'594mm',rotate:false},
	{slug:"A3",name:"A3",id:"IsoA3",value:4, width: '297mm', height:'420mm',rotate:false},
	{slug:"A3",name:"A3 (Extra)",id:"IsoA3Extra",value:5, width: '297mm', height:'420mm',rotate:false},
	{slug:"A3",name:"A3 Plus (Other Metric)",id:"OtherMetricA3Plus",value:6, width: '330mm', height:'483mm',rotate:false},
	{slug:"A4",name:"A4",id:"IsoA4",value:7, width:'210mm', height:'279mm',rotate:false},
	{slug:"A4",name:"A4 (Extra)",id:"IsoA4Extra",value:8, width:'210mm', height:'270mm',rotate:false},
	{slug:"A4",name:"A4 Plus (Other Metric)",id:"OtherMetricA4Plus",value:9, width:'210mm', height:'278mm',rotate:false},
	{slug:"A5",name:"A5",id:"IsoA5",value:10, width: '148mm', height: '210mm',rotate:false},
	{slug:"A5",name:"A5 (Extra)",id:"IsoA5Extra",value:11, width: '148mm', height: '210mm',rotate:false},
	{slug:"A6",name:"A6",id:"IsoA6",value:12, width: '105mm', height: '148mm',rotate:false},
	{slug:"A7",name:"A7",id:"IsoA7",value:13, width: '74mm', height: '105mm',rotate:false},
	{slug:"A8",name:"A8",id:"IsoA8",value:14, width: '52mm', height: '74mm',rotate:false},
	{slug:"A9",name:"A9",id:"IsoA9",value:15, width: '37mm', height: '52mm',rotate:false},
	{slug:"A10",name:"A10",id:"IsoA10",value:16, width: '26mm', height:'37mm',rotate:false},
	{slug:"A3+",name:"A3+ Envelope",id:"IsoA3PlusEnvelope",value:17, width: '350mm', height:'475mm',rotate:false},
	{slug:"A4",name:"A4 Envelope",id:"IsoA4Envelope",value:18, width: '216mm', height:'278mm',rotate:false},
	{slug:"As",name:"Architecture A Sheet",id:"NorthAmericaArchitectureASheet",value:19, width: '229mm', height: '305mm',rotate:false},
	{slug:"Bs",name:"Architecture B Sheet",id:"NorthAmericaArchitectureBSheet",value:20, width: '305mm', height: '457mm',rotate:false},
	{slug:"Cs",name:"Architecture C Sheet",id:"NorthAmericaArchitectureCSheet",value:21, width: '457mm', height: '610mm',rotate:false},
	{slug:"Ds",name:"Architecture D Sheet",id:"NorthAmericaArchitectureDSheet",value:22, width: '610mm', height: '914mm',rotate:false},
	{slug:"Es",name:"Architecture E Sheet",id:"NorthAmericaArchitectureESheet",value:23, width: '914mm', height: '1219mm',rotate:false},
	{slug:"E1",name:"Architecture E1 Sheet",id:"NorthAmericaArchitectureE1Sheet",value:24, width: '762mm', height: '1067mm',rotate:false},
	{slug:"6¼",name:"Number 6-1/4 Commercial",id:"NorthAmericaNumber614CommercialEnvelope",value:25, width: '152mm', height: '89mm',rotate:true},
	{slug:"6¾",name:"Number 6-3/4",id:"NorthAmericaNumber634Envelope",value:26, width: '165mm', height: '92mm',rotate:true},
	{slug:"8⅝",name:"Number 8-5/8",id:"NorthAmericaNumber858Envelope",value:27, width: '219mm', height: '92mm',rotate:true},
	{slug:"7",name:"Number 7",id:"NorthAmericaNumber7Envelope",value:28, width: '171mm', height: '95mm',rotate:true},
	{slug:"9",name:"Number 9 Envelope",id:"NorthAmericaNumber9Envelope",value:29, width: '225mm', height: '98mm',rotate:true},
	{slug:"10",name:"Number 10 Envelope",id:"NorthAmericaNumber10Envelope",value:30, width: '241mm', height: '105mm',rotate:true},
	{slug:"11",name:"Number 11 Envelope",id:"NorthAmericaNumber11Envelope",value:31, width: '263mm', height: '114mm',rotate:true},
	{slug:"12",name:"Number 12 Envelope",id:"NorthAmericaNumber12Envelope",value:32, width: '280mm', height: '120mm',rotate:true},
	{slug:"14",name:"Number 14 Envelope",id:"NorthAmericaNumber14Envelope",value:33, width: '292mm', height: '127mm',rotate:true},
	{slug:"16",name:"Number 16 Envelope",id:"NorthAmericaNumber16Envelope",value:34, width: '305mm', height: '152mm',rotate:true},
	{slug:"BC",name:"Business Card",id:"BusinessCard",value:35, width: '85mm', height: '55mm',rotate:false},
	{slug:"B0",name:"B0",id:"IsoB0",value:36, width: '1000mm', height:'1414mm',rotate:false},
	{slug:"B1",name:"B1",id:"IsoB1",value:37, width: '707mm', height:'1000mm',rotate:false},
	{slug:"B2",name:"B2",id:"IsoB2",value:38, width: '500mm', height:'707mm',rotate:false},
	{slug:"B3",name:"B3",id:"IsoB3",value:39, width: '353mm', height:'500mm',rotate:false},
	{slug:"B4",name:"B4",id:"IsoB4",value:40, width: '250mm', height:'353mm',rotate:true},
	{slug:"B5",name:"B5",id:"IsoB5Extra",value:41, width: '176mm', height: '250mm',rotate:false},
	{slug:"B6",name:"B6",id:"IsoB6",value:42, width: '125mm', height: '176mm',rotate:false},
	{slug:"B7",name:"B7",id:"IsoB7",value:43, width: '88mm', height: '125mm',rotate:false},
	{slug:"B8",name:"B8",id:"IsoB8",value:44, width: '62mm', height: '88mm',rotate:false},
	{slug:"B9",name:"B9",id:"IsoB9",value:45, width: '44mm', height: '62mm',rotate:false},
	{slug:"B10",name:"B10",id:"IsoB10",value:46, width: '31mm', height:'44mm',rotate:false},
	{slug:"B4",name:"B4 Envelope",id:"IsoB4Envelope",value:47, width: '250mm', height:'353mm',rotate:false},
	{slug:"B4",name:"B4 Gusset Envelope",id:"IsoB4GussetEnvelope",value:48, width: '250mm', height:'352mm',rotate:false},
	{slug:"B5",name:"B5 Envelope",id:"IsoB5Envelope",value:49, width: '176mm', height: '250mm',rotate:false},
	{slug:"B6",name:"B6 Envelope",id:"IsoB6Envelope",value:50, width: '125mm', height: '176mm',rotate:false},
	{slug:"CC",name:"Credit Card",id:"CreditCard",value:51, width: '85.60mm', height: '53.98mm',rotate:false},
	{slug:"Cs",name:"C Sheet",id:"NorthAmericaCSheet",value:52, width: '432mm', height: '559mm',rotate:false},
	{slug:"C0",name:"C0",id:"IsoC0",value:53, width: '176mm', height: '250mm',rotate:false},
	{slug:"C1",name:"C1",id:"IsoC1",value:54, width: '648mm', height: '917mm',rotate:false},
	{slug:"C2",name:"C2",id:"IsoC2",value:55, width: '458mm', height: '648mm',rotate:false},
	{slug:"C3",name:"C3",id:"IsoC3",value:56, width: '324mm', height: '458mm',rotate:false},
	{slug:"C3",name:"C3 Envelope",id:"IsoC3Envelope",value:57, width: '324mm', height: '458mm',rotate:false},
	{slug:"C3",name:"C3 Envelope (Rotated)",id:"IsoC3Envelope",value:570, width: '458mm', height: '324mm',rotate:false},
	{slug:"C3",name:"C3 Gusset Envelope",id:"IsoC3GussetEnvelope",value:58, width: '324mm', height: '450mm',rotate:false},
	{slug:"C3",name:"C3 Gusset Envelope (Rotated)",id:"IsoC3GussetEnvelope",value:580, width: '450mm', height: '324mm',rotate:false},
	{slug:"C3",name:"C3+ Envelope",id:"IsoC3PlusEnvelope",value:59, width: '330mm', height: '430mm',rotate:false},
	{slug:"C3",name:"C3+ Envelope (Rotated)",id:"IsoC3PlusEnvelope",value:590, width: '430mm', height: '330mm',rotate:false},
	{slug:"C4",name:"C4",id:"IsoC4",value:60, width: '229mm', height: '324mm',rotate:false},
	{slug:"C4",name:"C4 Envelope",id:"IsoC4Envelope",value:61, width: '229mm', height: '324mm',rotate:false},
	{slug:"C4",name:"C4+ Envelope",id:"IsoC4PlusEnvelope",value:62, width: '240mm', height: '330mm',rotate:false},
	{slug:"C5",name:"C5",id:"IsoC5",value:63, width: '162mm', height: '229mm',rotate:false},
	{slug:"C5",name:"C5 Envelope",id:"IsoC5Envelope",value:64, width: '162mm', height: '229mm',rotate:false},
	{slug:"C5",name:"C5+ Envelope",id:"IsoC5PlusEnvelope",value:65, width: '162mm', height: '235mm',rotate:false},
	{slug:"C5",name:"C5++ Envelope",id:"IsoC5PlusPlusEnvelope",value:66, width: '162mm', height: '238mm',rotate:false},
	{slug:"C5",name:"C5 Gusset Envelope",id:"IsoC5GussetEnvelope",value:67,width:'229mm',height:'162mm',rotate:false},
	{slug:"C6",name:"C6",id:"IsoC6",value:68, width: '114mm', height: '114mm',rotate:false},
	{slug:"C6",name:"C6 Envelope",id:"IsoC6Envelope",value:69, width: '114mm', height: '162mm',rotate:false},
	{slug:"C6",name:"C6 Envelope (Rotated)",id:"IsoC6EnvelopeRotated",value:690, width: '162mm', height: '114mm',rotate:false},
	{slug:"C7",name:"C7",id:"IsoC7",value:70, width: '81mm', height: '114mm',rotate:false},
	{slug:"C8",name:"C8",id:"IsoC8",value:71, width: '57mm', height: '81mm',rotate:false},
	{slug:"C9",name:"C9",id:"IsoC9",value:72, width: '40mm', height: '57mm',rotate:false},
	{slug:"C10",name:"C10",id:"IsoC10",value:73, width: '28mm', height: '40mm',rotate:false},
	{slug:"Ch",name:"Chou 3 Envelope",id:"JapanChou3Envelope",value:74, height:'235mm', width:'120mm' ,rotate:false},
	{slug:"Ch",name:"Chou 3 Envelope (Rotated)",id:"JapanChou3Envelope",value:740, width:'120mm', height:'235mm',rotate:false},
	{slug:"Ch",name:"Chou 4 Envelope",id:"JapanChou4Envelope",value:75, height:'205mm', width:'90mm' ,rotate:false},
	{slug:"Ch",name:"Chou 4 Envelope (Rotated)",id:"JapanChou4Envelope",value:750, width:'90mm', height:'205mm',rotate:false},
	{slug:"D",name:"D Sheet",id:"NorthAmericaDSheet",value:76, width:'559mm', height: '864mm',rotate:false},
	{slug:"DL",name:"DL Envelope",id:"IsoDLEnvelope",value:77, width: '220mm', height: '110mm',rotate:false},
	{slug:"DL",name:"DL+ Envelope",id:"IsoDLPlusEnvelope",value:78, width: '229mm', height: '114mm',rotate:false},
	{slug:"DL",name:"DL++ Envelope",id:"IsoDLPlusPlusEnvelope",value:79, width: '235mm', height: '114mm',rotate:false},
	{slug:"Es",name:"E Sheet",id:"NorthAmericaESheet",value:80, width:'914mm', height:'1219mm',rotate:false},
	{slug:"Ex",name:"Executive",id:"NorthAmericaExecutive",value:81, width: '178mm', height: '267mm',rotate:false},
	{slug:"Fo",name:"Folio (Other Metric)",id:"OtherMetricFolio",value:82, width: '216mm', height: '330mm',rotate:false},
	{slug:"Gl",name:"German Legal Fanfold",id:"NorthAmericaGermanLegalFanfold",value:83, width:'216mm', height:'330mm',rotate:false},
	{slug:"Gr",name:"German Standard Fanfold",id:"NorthAmericaGermanStandardFanfold",value:84,width:'1030mm',height:'1456mm',rotate:false},
	{slug:"H",name:"Hagaki Postcard",id:"JapanHagakiPostcard",value:85, width:'148mm', height:'100mm',rotate:false},
	{slug:"Hd",name:"Hagaki Postcard (Double)",id:"JapanDoubleHagakiPostcard",value:86, width:'200mm',height:'148mm',rotate:false},
	{slug:"Hq",name:"Hagaki Postcard (Quadruple)",id:"JapanQuadrupleHagakiPostcard",value:87, width:'400mm',height:'296mm',rotate:false},
	{slug:"Iv",name:"Invite Envelope (Metric)",id:"OtherMetricInviteEnvelope",value:88,rotate:false},
	{slug:"It",name:"Italian Envelope (Metric)",id:"OtherMetricItalianEnvelope",value:89,rotate:false},
	{slug:"LP",name:"Japan LPhoto",id:"JapanLPhoto",value:90, width:'45mm', height:'45mm',rotate:false},
	{slug:"2L",name:"Japan 2L Photo",id:"Japan2LPhoto",value:91, width:'127mm', height:'178mm',rotate:false},
	{slug:"B0",name:"JIS B0",id:"JisB0",value:92, height:'1456mm', width:'1030mm',rotate:false},
	{slug:"B1",name:"JIS B1",id:"JisB1",value:93, height:'1030mm', width:'728mm',rotate:false},
	{slug:"B2",name:"JIS B2",id:"JisB2",value:94, height:'728mm', width:'515mm',rotate:false},
	{slug:"B3",name:"JIS B3",id:"JisB3",value:95, height:'515mm', width:'364mm',rotate:false},
	{slug:"B4",name:"JIS B4",id:"JisB4",value:96, height:'364mm', width:'257mm',rotate:false},
	{slug:"B5",name:"JIS B5",id:"JisB5",value:97, height:'257mm', width:'182mm',rotate:false},
	{slug:"B6",name:"JIS B6",id:"JisB6",value:98, height:'182mm', width:'128mm',rotate:false},
	{slug:"B7",name:"JIS B7",id:"JisB7",value:99, height:'128mm', width:'91mm',rotate:false},
	{slug:"B8",name:"JIS B8",id:"JisB8",value:100, height:'91mm', width:'64mm',rotate:false},
	{slug:"B9",name:"JIS B9",id:"JisB9",value:101, height:'64mm', width:'45mm',rotate:false},
	{slug:"B10",name:"JIS B10",id:"JisB10",value:102, height:'32mm', width:'45mm',rotate:false},
	{slug:"K2",name:"Kaku 2 Envelope",id:"JapanKaku2Envelope",value:103, width:'240mm', height:'332mm',rotate:false},
	{slug:"K3",name:"Kaku 3 Envelope",id:"JapanKaku3Envelope",value:104, width:'216mm', height:'277mm',rotate:false},
	{slug:"L",name:"Legal",id:"NorthAmericaLegal",value:105, width:'216mm', height:'330mm',rotate:false},
	{slug:"Lx",name:"Legal Extra",id:"NorthAmericaLegalExtra",value:106, width:'216mm', height:'356mm',rotate:false},
	{slug:"Le",name:"Letter",id:"NorthAmericaLetter",value:107,width: '203mm', height: '254mm',rotate:false},
	{slug:"Lex",name:"Letter Extra",id:"NorthAmericaLetterExtra",value:108,width: '216mm', height: '279mm',rotate:false},
	{slug:"Le+",name:"Letter Plus",id:"NorthAmericaLetterPlus",value:109,rotate:false},
	{slug:"Mo",name:"Monarch Envelope",id:"NorthAmericaMonarchEnvelope",value:110,width:'190mm',height:'98mm',rotate:false},
	{slug:"No",name:"Note",id:"NorthAmericaNote",value:111,rotate:false},
	{slug:"Pe",name:"Personal Envelope",id:"NorthAmericaPersonalEnvelope",value:112,rotate:false},
	{slug:"1",name:"PRC 1 Envelope",id:"Prc1Envelope",value:113, height: '102mm', width: '165mm',rotate:false},
	{slug:"2",name:"PRC 2 Envelope",id:"Prc2Envelope",value:114, height: '102mm', width: '176mm',rotate:false},
	{slug:"3",name:"PRC 3 Envelope",id:"Prc3Envelope",value:115, height: '125mm', width: '176mm',rotate:false},
	{slug:"4",name:"PRC 4 Envelope",id:"Prc4Envelope",value:116, height: '110mm', width: '208mm',rotate:false},
	{slug:"5",name:"PRC 5 Envelope",id:"Prc5Envelope",value:117, height: '110mm', width: '220mm',rotate:false},
	{slug:"6",name:"PRC 6 Envelope",id:"Prc6Envelope",value:118, height: '120mm', width: '230mm',rotate:false},
	{slug:"7",name:"PRC 7 Envelope",id:"Prc7Envelope",value:119, height: '160mm', width: '230mm',rotate:false},
	{slug:"8",name:"PRC 8 Envelope",id:"Prc8Envelope",value:120, height: '120mm', width: '309mm',rotate:false},
	{slug:"9",name:"PRC 9 Envelope",id:"Prc9Envelope",value:121, height: '229mm', width: '324mm',rotate:false},
	{slug:"10",name:"PRC 10 Envelope",id:"Prc10Envelope",value:122, height: '324mm', width: '458mm',rotate:false},
	{slug:"16K",name:"PRC 16K",id:"Prc16K",value:123, width: '215mm', height: '146mm',rotate:false},
	{slug:"32K",name:"PRC 32K",id:"Prc32K",value:124, width: '151mm', height: '97mm',rotate:false},
	{slug:"32K",name:"PRC 32K Big",id:"Prc32KBig",value:125, width: '151mm', height: '97mm',rotate:false},
	{slug:"Q",name:"Quarto",id:"NorthAmericaQuarto",value:126, width: '215mm', height: '275mm',rotate:false},
	{slug:"04\"",name:"Roll 04\"",id:"Roll04Inch",value:127, height: "215mm", width: "102mm",rotate:false},
	{slug:"06\"",name:"Roll 06\"",id:"Roll06Inch",value:128, height: "215mm", width: "152mm",rotate:false},
	{slug:"08\"",name:"Roll 08\"",id:"Roll08Inch",value:129, height: "215mm", width: "203mm",rotate:false},
	{slug:"12\"",name:"Roll 12\"",id:"Roll12Inch",value:130, height: "215mm", width: "305mm",rotate:false},
	{slug:"15\"",name:"Roll 15\"",id:"Roll15Inch",value:131, height: "215mm", width: "381mm",rotate:false},
	{slug:"18\"",name:"Roll 18\"",id:"Roll18Inch",value:132, height: "215mm", width: "457mm",rotate:false},
	{slug:"22\"",name:"Roll 22\"",id:"Roll22Inch",value:133, height: "215mm", width: "559mm",rotate:false},
	{slug:"24\"",name:"Roll 24\"",id:"Roll24Inch",value:134, height: "215mm", width: "610mm",rotate:false},
	{slug:"30\"",name:"Roll 30\"",id:"Roll30Inch",value:135, height: "215mm", width: "762mm",rotate:false},
	{slug:"36\"",name:"Roll 36\"",id:"Roll36Inch",value:136, height: "215mm", width: "914mm",rotate:false},
	{slug:"54\"",name:"Roll 54\"",id:"Roll54Inch",value:137, height: "215mm", width: "1372mm",rotate:false},
	{slug:"St",name:"Statement",id:"NorthAmericaStatement",value:138,width:'140mm',height:'216mm',rotate:false},
	{slug:"sA",name:"Super A",id:"NorthAmericaSuperA",value:139,rotate:false},
	{slug:"sB",name:"Super B",id:"NorthAmericaSuperB",value:140,rotate:false},
	{slug:"S3",name:"SRA 3",id:"IsoSRA3",value:141, width:'320mm', height:'450mm',rotate:false},
	{slug:"Ta",name:"Tabloid",id:"NorthAmericaTabloid",value:142, width:'279mm', height:'432mm',rotate:false},
	{slug:"Tx",name:"Tabloid Extra",id:"NorthAmericaTabloidExtra",value:143, width:'305mm', height:'457.mm',rotate:false},
	{slug:"Y1",name:"You 1 Envelope",id:"JapanYou1Envelope",value:144, width:'120mm', height:'176mm',rotate:false},
	{slug:"Y2",name:"You 2 Envelope",id:"JapanYou2Envelope",value:145, width:'114mm', height:'162mm',rotate:false},
	{slug:"Y3",name:"You 3 Envelope",id:"JapanYou3Envelope",value:146, width:'98mm', height:'148mm',rotate:false},
	{slug:"Y4",name:"You 4 Envelope",id:"JapanYou4Envelope",value:147, width:'105mm', height:'235mm',rotate:false},
	{slug:"Y6",name:"You 6 Envelope",id:"JapanYou6Envelope",value:148, width:'98mm', height:'190mm',rotate:false},
	{slug:"Y7",name:"You 7 Envelope",id:"JapanYou7Envelope",value:149, width:'92mm', height:'165mm',rotate:false},
	{slug:"4\"x6\"",name:"4\" x 6\"",id:"NorthAmerica4x6",value:150, width:'152mm', height:'102mm',rotate:false},
	{slug:"4\"x8\"",name:"4\" x 8\"",id:"NorthAmerica4x8",value:151, width:'203mm', height:'102mm',rotate:false},
	{slug:"5\"x7\"",name:"5\" x 7\"",id:"NorthAmerica5x7",value:152, width:'178mm', height:'127mm',rotate:false},
	{slug:"8\"x10\"",name:"8\" x 10\"",id:"NorthAmerica8x10",value:153, width:'254mm', height:'203mm',rotate:false},
	{slug:"9\"x11\"",name:"9\" x 11\"",id:"NorthAmerica9x11",value:154, width:'279mm', height:'229mm',rotate:false},
	{slug:"10\"x11\"",name:"10\" x 11\"",id:"NorthAmerica10x11",value:155, width:'279mm', height:'254mm',rotate:false},
	{slug:"10\"x12\"",name:"10\" x 12\"",id:"NorthAmerica10x12",value:156, width:'305mm', height:'254mm',rotate:false},
	{slug:"10\"x14\"",name:"10\" x 14\"",id:"NorthAmerica10x14",value:157, width:'356mm', height:'254mm',rotate:false},
	{slug:"11\"x17\"",name:"11\" x 17\"",id:"NorthAmerica11x17",value:158, width:'432mm', height:'279mm',rotate:false},
	{slug:"14\"x17\"",name:"14\" x 17\"",id:"NorthAmerica14x17",value:159, width:'432mm', height:'279mm',rotate:false},
	{slug:"100mm",name:"100mm Square",id:"IsoSquare100mm",value:160, width: '100mm', height: '100mm',rotate:false},
	{slug:"111mm",name:"111mm Square",id:"IsoSquare111mm",value:161, width: '111mm', height: '111mm',rotate:false},
	{slug:"120mm",name:"120mm Square",id:"IsoSquare120mm",value:162, width: '120mm', height: '120mm',rotate:false},
	{slug:"125mm",name:"125mm Square",id:"IsoSquare125mm",value:163, width: '125mm', height: '125mm',rotate:false},
	{slug:"130mm",name:"130mm Square",id:"IsoSquare130mm",value:164, width: '130mm', height: '130mm',rotate:false},
	{slug:"140mm",name:"140mm Square",id:"IsoSquare140mm",value:165, width: '140mm', height: '140mm',rotate:false},
	{slug:"146mm",name:"146mm Square",id:"IsoSquare146mm",value:166, width: '146mm', height: '146mm',rotate:false},
	{slug:"155mm",name:"155mm Square",id:"IsoSquare155mm",value:167, width: '155mm', height: '155mm',rotate:false},
	{slug:"160mm",name:"160mm Square",id:"IsoSquare160mm",value:168, width: '160mm', height: '160mm',rotate:false},
	{slug:"165mm",name:"165mm Square",id:"IsoSquare165mm",value:169, width: '165mm', height: '165mm',rotate:false},
	{slug:"170mm",name:"170mm Square",id:"IsoSquare170mm",value:170, width: '170mm', height: '170mm',rotate:false},
	{slug:"180mm",name:"180mm Square",id:"IsoSquare180mm",value:171, width: '180mm', height: '180mm',rotate:false},
	{slug:"190mm",name:"190mm Square",id:"IsoSquare190mm",value:172, width: '190mm', height: '190mm',rotate:false},
	{slug:"195mm",name:"195mm Square",id:"IsoSquare195mm",value:173, width: '195mm', height: '195mm',rotate:false},
	{slug:"200mm",name:"200mm Square",id:"IsoSquare200mm",value:174, width: '200mm', height: '200mm',rotate:false},
	{slug:"205mm",name:"205mm Square",id:"IsoSquare205mm",value:175, width: '205mm', height: '205mm',rotate:false},
	{slug:"220mm",name:"220mm Square",id:"IsoSquare220mm",value:176, width: '220mm', height: '220mm',rotate:false},
	{slug:"240mm",name:"240mm Square",id:"IsoSquare240mm",value:177, width: '240mm', height: '240mm',rotate:false},
	{slug:"270mm",name:"270mm Square",id:"IsoSquare270mm",value:178, width: '270mm', height: '270mm',rotate:false},
	{slug:"300mm",name:"300mm Square",id:"IsoSquare300mm",value:178, width: '300mm', height: '300mm',rotate:false},
	{slug:"330mm",name:"330mm Square",id:"IsoSquare330mm",value:179, width: '330mm', height: '330mm',rotate:false},
	{slug:"340mm",name:"340mm Square",id:"IsoSquare340mm",value:180, width: '340mm', height: '340mm',rotate:false}
];