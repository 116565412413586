import { Component, OnInit } from '@angular/core';
import { SQLService } from '../../services/sql.service';
import { DatePipe } from '@angular/common';
import { NewTimesheetEntry } from '../../models/timesheetentries';
import { TimesheetService } from '../../services/timesheet.service';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-timesheet-entries',
  templateUrl: './timesheet-entries.component.html',
  styleUrls: ['./timesheet-entries.component.scss'],
})
export class TimesheetEntriesComponent implements OnInit {
  add_timer_with_a_page = false
  timesheet_entries = [];
  search:string;
  count = -1;
  one_second_timer: any;
  ida: any;
  constructor(
    public datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private ts: TimesheetService,
    public sql: SQLService
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) {
        this.get_timesheet_entries();
      }
    });
  }
  filter(e) { this.search=e.target.value; }
  get_timesheet_entries(event?) {
    var qq = 'TimesheetEntry';
    if (this.ida) qq='TimesheetEntry WHERE timesheet_id = '+this.ida;
    this.sql.fetch(qq,(e)=>{
      this.calc_sec(e,(d)=>{
        this.timesheet_entries = d;
        this.second_check();
      });
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  calc_sec(z,cb) {var i=0, d=[];
    for(let e of z) { i++;
      this.ts.calculate_entries(e,(h,m,s)=>{
        e['seconds']=s; e['minutes']=m; e['hours']=h;
        e=this.ts.set_timer(e);
        d.push(e);
        if(i==z.length)cb(d);
      });  
    }
  }
  end() {
    this.router.navigate(['folder','Timesheet',this.ida]);
  }
  add() { 
    if(this.add_timer_with_a_page) {
      this.router.navigate(['folder','Timesheet','NewEntry']); 
    } else {
      var entry = new NewTimesheetEntry();
      entry.start_date=new Date(Date.now()).toISOString();
      entry.start_time=new Date(Date.now()).toISOString();  
      entry.timesheet_id=this.ida;
      this.sql.add("TimesheetEntry",entry,(id)=>{
        this.get_timesheet_entries();
      });
    }
  }
  create() {

  }
  second_check() {
    if(!this.one_second_timer) {
      this.one_second_timer = setInterval(()=>{
        for (var i = 0; i < this.timesheet_entries.length; ++i) {
          if( !this.timesheet_entries[i].end_date && !this.timesheet_entries[i].end_time ) {
            this.timesheet_entries[i].seconds=this.timesheet_entries[i].seconds+1
            if(this.timesheet_entries[i].seconds>59){
              this.timesheet_entries[i].seconds=0
              this.timesheet_entries[i].minutes=this.timesheet_entries[i].minutes+1
            }
            if(this.timesheet_entries[i].minutes>59){
              this.timesheet_entries[i].minutes=0
              this.timesheet_entries[i].hours=this.timesheet_entries[i].hours+1
            }
            if(this.timesheet_entries[i].hours>23){
              this.timesheet_entries[i].hours=0
              this.timesheet_entries[i].days=this.timesheet_entries[i].days+1
            }
            this.timesheet_entries[i]=this.ts.set_timer(this.timesheet_entries[i]);
          }
        }
      },1000);
    }
  }
  edit(ts, item) {
    this.router.navigate(['folder','Timesheet','Entry',ts.timesheet_entry_id,this.ida]);
    if(item)item.close(); 
  }
  delete(ts, item) {
    if(confirm(this.sql.word.Delete_Timesheet_Confirmation)) {
      this.sql.delete('TimesheetEntry','timesheet_entry_id',ts.timesheet_entry_id);
      this.get_timesheet_entries();
    } else {if(item)item.close();}
  }
  ngOnInit() {
    this.ida = this.route.snapshot.paramMap.get("id");
  }
}