export class NewSettings {
	constructor(
		public log?: any,
		public title?: string,
		public default_language?: string,
		public default_print_language?: string,
		public default_currency?: string,
		public default_income?: string,
		public default_printer?: string,
		public default_design?: string,
		public default_bank_account?: string,
		public hostname?: string,
		public key?: string,
		public serial_key?: string,
		public pin_enabled?: any,
		public pin?: string,
		public password_enabled?: any,
		public password?: string,
		public face_enabled?: any,
		public face?: any,
		public company_address?: any,
		public company_address_id?: any,
		public company_address_name?: any,
		public bank_number_protection_enabled?: any,
		public site_title?: any,
		public geo_location?: any,
		public geo_enabled?: any,
		public geo_distance?: any,
		public geo?: any
	) {}
}
export class NewDashboard {
	constructor(
		public order?: any,
		public slider_enabled?: boolean,
		public display_method?: any
	) {}
}
export class Setting {
	constructor(
		public setting_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public title?: string,
		public option?: string,
		public modified?: any,
		public created?: any
	) {}
}
export class PlaceHolders {
	constructor(
		public pin?: any,
		public pass?: any,
	) {}
}
export const Create_Settings = '\
	CREATE TABLE IF NOT EXISTS "Settings" (\
		setting_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		title TEXT,\
		option TEXT,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';