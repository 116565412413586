import { SQLService } from '../../services/sql.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { Gesture, GestureController } from '@ionic/angular';
@Component({
  selector: 'ng-cheques-print-cheques',
  templateUrl: './print-cheques.component.html',
  styleUrls: ['./print-cheques.component.scss'],
})
export class PrintChequesComponent implements OnInit {
  constructor(
    private router: Router,
    public sql: SQLService,
    private gestureCtrl: GestureController
  ) { 
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_cheques();
    });
  }
  sc=[];
  count=-1;
  search='';
  cheques=[];
  selected_cheques=[];
  print(c,i?) {
    this.router.navigate(['Print','Cheque',c.cheque_id]);
  }
  view(c,i?) {
    this.router.navigate(['View','Cheque',c.cheque_id]);
  }
  add() {
    this.router.navigate(['folder','Cheque','New']);
  }
  filter(e) {
    this.search=e.target.value;
  }
  get_cheques(event?) {
    this.sql.fetch('Cheques',(e)=>{
      this.cheques=e; 
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  print_cheques(c,i?) {
    this.sql.print_many_documents(this.selected_cheques, (result) => {
      //if(result);
    });
  }
  edit_cheque(c,i?) {
    this.router.navigate(['folder','Cheque',c.cheque_id]);
  }
  delete_cheque(c,i?) {
    if(confirm(this.sql.word.Cheque_Delete_Confirmation)) {
      this.sql.delete('Cheques','cheque_id',c.cheque_id);
      this.get_cheques();
    } else { i.closeSlidingItems(); }
  }
  paid_cheque(c?,i?) {
    if(confirm(this.sql.word.Cheque_Pay_Confirmation)) {
      this.sql.set('Cheques','cheque_id',c.cheque_id,'paid',true);
      this.get_cheques();
    } else { i.closeSlidingItems(); }
  }
  ngOnInit() {
    this.get_cheques();
  }
}