import { Currencies } from './currencies';
import { Languages } from './languages/index';
import { Prices } from './prices';
import { Locales } from './locales';
export const environment = {
  appname: "cheques-znvr9f",
  title: "Cheques",
  author: "Liam Hogan <liamhogan7@gmail.com>",
  author_email: "chequesapp@outlook.com",
  author_phone: "+1 343-803-5853",
  author_website: "http://cheques.janglehost.com",
  date: 'Sep. 2th, 2023',
  version: '2.005',
  procurement: "com.cheques.app",
  base: 'localhost', 
  verbose: false,
  more_verbose: false,
  production: true,
  encode: true,
  logging: true,
  licenced: false,
  buy_override: false,
  inital_pin_prompt: false,
  assets: "https://www.chequesapp.com/assets",
  com: "https://www.chequesapp.com/api",
  aesKey: "DaGbKaPdSgVkYp3s6v9ydBqEcHpMbQeT",
  secretKey: "AA6bvucWTUVSsZG%)S@sz55atWWxzNmp*utjTq2G9m4QYSLTm7u^K$Fj39JUbGwT!2WvY735QI8@I$wG3LJKBHjQxqVK)4!cIq*mn^Vj&Y#ezA)e%WV+gBMgQdF6AMuj",
  db:  {name: "-cPnypDaDT",location: "default",key: "7Sg5hkJGgsRnWn39fqVKUxAStfnG4V9j"},
  db2: {name: "45rBWx5epV",location: "default",key: "XRaBzftKNQK2TGkrRjyffCkSVExrJkuh"},
  paypal: "https://api-m.paypal.com",
  merchantIdentifier:'merchant.com.janglehost.cheques',
  paypal_production_key: "-", 
  paypal_sandbox_key: "-",
  paypal_client_id: "Af_H3ft1tcTtLWxy6o4h1B3yYYU4cq_xA8vUt1-7oWVLw_j-jIn7k7NkhqmJa1-JJhe69dS-eRe4TbK4",
  stripe_key:"",
  btc_address:"1QKQCHtvqrPbtyvYpQomUZXC85HNWCaSLM",
  required_modifications:[],
  lines: [],
  locale: 'en-US',
  places: Locales,
  prices: Prices,
  language: 'En',
  currency: 'USD',
  currencies: Currencies,
  alpha: ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"]
};
import 'zone.js/dist/zone-error';
