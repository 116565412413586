/**
 * @cheques
 * Cheque SCSS Design.
 */
import DesignerCSS from './designer.scss';
import DocugardCSS from './docugard.scss';
import Fonts from './fonts.scss';
import Post from './post.scss';
import Table from './table.scss';
import PrintCSS from './print.scss';
import ChequeTableCSS from './cheque-table/cheque-table.component.scss';
import PostedChequeCSS from './posted-cheque/posted-cheque.component.scss';
import SingleChequeCSS from './single-cheque/single-cheque.component.scss';
import ThreeChequesCSS from './three-cheques/three-cheques.component.scss';
import ChequeYellowComponentCSS from './cheque-yellow/cheque-yellow.component.scss'
import ChequeRedComponentCSS from './cheque-red/cheque-red.component.scss';
import ChequeGreenComponentCSS from './cheque-green/cheque-green.component.scss';
import ChequeGoldenComponentCSS from './cheque-golden/cheque-golden.component.scss';
import CashiersChequeComponentCSS from './cashiers-cheque/cashiers-cheque.component.scss';
import ChequeAquaCheckersComponentCSS from './cheque-aqua-checkers/cheque-aqua-checkers.component.scss';
import ChequeBlueCheckersComponentCSS from './cheque-blue-checkers/cheque-blue-checkers.component.scss';
import ChequeGoldenCheckersComponentCSS from './cheque-golden-checkers/cheque-golden-checkers.component.scss';
import ChequeGoldenWeaveComponentCSS from './cheque-golden-weave/cheque-golden-weave.component.scss';
import ChequeGreenSunComponentCSS from './cheque-green-sun/cheque-green-sun.component.scss';
import ChequeGreenWaveComponentCSS from './cheque-green-wave/cheque-green-wave.component.scss';
import ChequeStatusofLibertyComponentCSS from './cheque-statue-of-liberty/cheque-statue-of-liberty.component.scss';
import DocugardMailerTopCSS from './docugard-mailer-top/docugard-mailer-top.component.scss';
import DocugardMailerMiddleCSS from './docugard-mailer-middle/docugard-mailer-middle.component.scss';
import DocugardMailerBottomCSS from './docugard-mailer-bottom/docugard-mailer-bottom.component.scss';
import DocugardTableBottomCSS from './docugard-table-bottom/docugard-table-bottom.component.scss';
import DocugardTableMiddleCSS from './docugard-table-middle/docugard-table-middle.component.scss';
import DocugardTableTopCSS from './docugard-table-top/docugard-table-top.component.scss';
import DocugardChequeBottomCSS from './docugard-cheque-bottom/docugard-cheque-bottom.component.scss';
import DocugardChequeMiddleCSS from './docugard-cheque-middle/docugard-cheque-middle.component.scss';
import DocugardChequeTopCSS from './docugard-cheque-top/docugard-cheque-top.component.scss';
import DocugardThreeChequesCSS from './docugard-three-cheques/docugard-three-cheques.component.scss';
export const AllSCSS=
	'<style>'+
		Fonts+
		PrintCSS+
		DesignerCSS+
		DocugardCSS+
		ChequeTableCSS+
		PostedChequeCSS+
		SingleChequeCSS+
		ThreeChequesCSS+
		ChequeYellowComponentCSS+
		ChequeRedComponentCSS+
		ChequeGreenComponentCSS+
		ChequeGoldenComponentCSS+
		CashiersChequeComponentCSS+
		ChequeAquaCheckersComponentCSS+
		ChequeBlueCheckersComponentCSS+
		ChequeGoldenCheckersComponentCSS+
		ChequeGoldenWeaveComponentCSS+
		ChequeGreenSunComponentCSS+
		ChequeGreenWaveComponentCSS+
		ChequeStatusofLibertyComponentCSS+
		DocugardMailerTopCSS+
		DocugardMailerMiddleCSS+
		DocugardMailerBottomCSS+
		DocugardTableBottomCSS+
		DocugardTableMiddleCSS+
		DocugardTableTopCSS+
		DocugardChequeBottomCSS+
		DocugardChequeMiddleCSS+
		DocugardChequeTopCSS+
		DocugardThreeChequesCSS+
		Post+
		Table+
	'</style>';
export const DocugardColors=[
	'black',
	'green',
	'gold',
	'blue',
	'brown',
	'purple',
	'pink',
	'red',
]