import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bitcoin',
  templateUrl: './bitcoin.component.html',
  styleUrls: ['./bitcoin.component.scss'],
})
export class BitcoinComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
