  import { SQLService } from '../../../services/sql.service';
import { PaymentsService } from '../../../services/payments.service';
import { ColorPickerComponent } from '../color-picker/color-picker.component';
import { PurchaseComponent } from '../../../dashboard/purchase/purchase.component';
import { ChequeDesignerComponent } from '../cheque-designer/cheque-designer.component';
import { DocugardColors } from '../../templates_html';
import { Flip } from '../../../app.animations';
import { NewBonus } from '../../../models/bonus';
import { NewDesign } from '../../../models/design';
import { PaperSizes } from '../../../../environments/papersizes';
import { Component, OnInit, EventEmitter,Input,ViewChild } from '@angular/core';
import { Router,NavigationEnd,ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController, NavParams, IonContent } from '@ionic/angular';
@Component({
  selector: 'app-page-margins',
  templateUrl: './page-margins.page.html',
  styleUrls: ['./page-margins.page.styl'],
  animations: [ Flip ]
})
export class PageMarginsPage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    private payments: PaymentsService,
    private sql: SQLService,
    private params: NavParams,
    private location: Location
  ){
    this.router.events.subscribe((eve)=>{
      if(eve instanceof NavigationEnd)this.get_default();
    });
    this.page_margin.top = params.get('top');
    this.page_margin.bottom = params.get('bottom');
    this.page_margin.left = params.get('left');
    this.page_margin.right = params.get('right');
    this.page_margin.micr_bottom = params.get('micr_bottom');
    this.page_margin.micr_left = params.get('micr_left');
    this.page_margin.micr_right = params.get('micr_right');
    this.page_margin.micr_top = params.get('micr_top');
    this.page_margin.table_bottom = params.get('table_bottom');
    this.page_margin.table_left = params.get('table_left');
    this.page_margin.table_right = params.get('table_right');
    this.page_margin.table_top = params.get('table_top');
    this.page_margin.address_bottom = params.get('address_bottom');
    this.page_margin.address_left = params.get('address_left');
    this.page_margin.address_right = params.get('address_right');
    this.page_margin.address_top = params.get('address_top');
  }
  @ViewChild(IonContent) content: IonContent;
  scrollToTop() {this.content.scrollToTop(400);}
  filter(e) { this.search=e.target.value; }
  count = -1;
  papers = [];
  search: string;
  page_margin={
    top: null,
    bottom: null,
    left: null,
    right: null,
    micr_bottom: null,
    micr_left: null,
    micr_right: null,
    micr_top: null,
    table_bottom: null,
    table_left: null,
    table_right: null,
    table_top: null,
    address_bottom: null,
    address_left: null,
    address_right: null,
    address_top: null
  };
  output = new EventEmitter();
  select(name) {
  }
  ngOnInit() {
  }
  get_default() {

  }
  done() {
    this.navCtrl.dismiss(this.page_margin?this.page_margin:false);
  }
  end() {
    this.navCtrl.dismiss(false);
  }
}