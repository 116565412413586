import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Router,ActivatedRoute,NavigationEnd} from '@angular/router';
import { SQLService } from '../../../services/sql.service';
@Component({
  selector: 'app-list-deposits',
  templateUrl: './list-deposits.component.html',
  styleUrls: ['./list-deposits.component.scss'],
})
export class ListDepositsComponent implements OnInit {
  constructor(
    private modalController:ModalController,
    private router:Router,
    private route:ActivatedRoute,
    private location:Location,
    private sql:SQLService) {
      this.router.events.subscribe((eve) => {
        if(eve instanceof NavigationEnd) this.open();
      });
    }
  count = -1; 
  deposits = [];
  search:string;
  filter(e) { this.search=e.target.value; }
  get_deposits(event?){
    this.sql.fetch('Deposits',(e)=>{
      console.log(e);
      this.deposits=e;this.count=e.length;
      setTimeout(()=>{if (event) event.target.complete();},1234);
    });
  }
  delete(d, i) {
    if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('Deposits','deposit_id',d.deposit_id);
      this.get_deposits();
    } else { i.closeSlidingItems(); }
  }
  edit(d,i?) {this.router.navigate(['folder','Deposit',d.deposit_id]);i.closeSlidingItems();}
  go(d) {this.router.navigate(['folder','Deposit',d.deposit_id]);}
  add() {this.router.navigate(['folder','Deposits','New']);}
  end() {this.router.navigate(['folder','Dashboard']);}
  open() {this.get_deposits();}
  ngOnInit() {this.open();}
}
