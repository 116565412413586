export class NewFTPDrive {
	constructor(
		public user?: string,
		public pass?: string,
		public host?: string,
		public port?: number,
		public path?: string,
		public auto?: boolean,
		public secure?: boolean,
		public modified?: any,
		public created?: any
	) {}
}