import { SQLService } from '../../services/sql.service';
import { Flip } from '../../app.animations';
import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController, NavParams } from '@ionic/angular';
@Component({
  selector: 'app-select-cheque',
  templateUrl: './select-cheque.page.html',
  styleUrls: ['./select-cheque.page.styl'],
  animations: [ Flip ]
})
export class SelectChequePage implements OnInit {
  constructor(
    private router: Router,
    public sql: SQLService,
    private params: NavParams,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    private location: Location
  ) {
    var s = params.get('selected');
    if(s)this.selected = s.split(',');
    this.multiple = params.get('select_multiple');
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_cheques();
    });
  }
  count = -1;
  cheques =[];
  selected =[];
  multiple:boolean;
  verifiedName: boolean;
  output = new EventEmitter();
  @Input('set_table') table:string;
  @Input('set_model') model:string;
  @Input('set_model_id') id:string;
  ngOnInit() {
    this.get_cheques();
    this.id = this.route.snapshot.paramMap.get("id");
    this.table = this.route.snapshot.paramMap.get("table");
    this.model = this.route.snapshot.paramMap.get("model");
  }
  get_cheques(event?) {
    
    this.sql.fetch('Cheques',(e)=>{
      this.cheques=e;
      console.log('cheques',this.cheques)
      this.count=e.length;
      setTimeout(()=>{ if (event) event.target.complete(); },1234);
    });
  }
  select_none() {this.navCtrl.dismiss(-1);}
  select(e) { 
    if (this.id) {
      let a = 'cheque';
      this.sql.query('UPDATE '+this.table+' '+( this.id?'WHERE '+this.model+' = '+this.id:'' )+' SET '+a+'_id = '+e[a+'_id']);
      this.location.back();
    }
    this.navCtrl.dismiss(e);
  }
  add() {
    this.router.navigate(['folder','Cheque','New']);
    this.navCtrl.dismiss();
  }
  off() {
    this.navCtrl.dismiss();
  }
}