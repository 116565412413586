import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FingerprintAIO, FingerprintOptions } from '@ionic-native/fingerprint-aio/ngx';
import { SQLService } from '../../../services/sql.service';

@Component({
  selector: 'app-biometrics',
  templateUrl: './biometrics.component.html',
  styleUrls: ['./biometrics.component.styl'],
})
export class BiometricsComponent implements OnInit, OnDestroy {

  constructor(
    private sql: SQLService,
    private faio: FingerprintAIO
  ) { }
  status = 'loading';
  bio_result: any;
  @Output('login') _login = new EventEmitter();
  ngOnDestroy() {
    status = 'loading';
  }
  ngOnInit() {
    status = 'loading';
    this.biometrics((result)=>{ this._login.emit((result==200)?true:false); });
  }
  biometrics(cb?){
    status = 'loading';
    var opts:FingerprintOptions = {
      title: this.sql.Title,
      disableBackup:true,
      fallbackButtonTitle: this.sql.word.Use_PIN,
      cancelButtonTitle: this.sql.word.Cancel,
      description: this.sql.word.Biometric_Security
    }
    this.faio.show(opts)
      .then((result: any) => {
        if(result=="Success"){
          this.bio_result=200;
          this.status='ok'
          setTimeout(()=>{
            this._login.emit(true); 
          },300)
          if(cb)cb(true);
        } else {
          this.bio_result=500;
          this.status='error'
          if(cb)cb(false);
        }
        setTimeout(()=>{this.bio_result=false;},5000);
      }).catch((error: any) => {
        console.warn(error);
        this.bio_result=500;
        this.status='error';
        setTimeout(()=>{this.bio_result=false;},5000);
        if(cb)cb(false);
      });
  }
}