import { SQLService } from '../services/sql.service';
import { TimesheetService } from '../services/timesheet.service';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router,NavigationEnd } from '@angular/router';
import { PopoverController} from '@ionic/angular';
import { NewTimesheetEntry } from '../models/timesheetentries';
import { TimesheetMenuComponent } from './timesheet-menu/timesheet-menu.component';
@Component({
  selector: 'ng-cheques-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.styl']
})
export class TimesheetComponent implements OnInit {
  show_start_date=[];
  show_end_date=[];
  timesheets=[];
  selected=[];
  search:string;
  mode='timer';
  count = -1;
  act = 0;
  one_second_timer: any;
  multiple: boolean;
  multiple_running: boolean;
  constructor(
    public datePipe: DatePipe,
    private router: Router,
    private ts: TimesheetService,
    public popoverController: PopoverController,
    public sql: SQLService
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) {
          this.get_timesheets();
      }
    });
  }
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:TimesheetMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled, timer_active: item.timer_active},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='enable')this.enable(item);
      if(data=='disable')this.disable(item);
      if(data=='play')this.start_timer(item, false);
      if(data=='pause')this.stop_timer(item, false);
      if(data=='delete')this.delete(item, false);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  timesheet_done() {
    this.selected=[];
    this.multiple = false;
  }
  timesheet_edit() {
    this.multiple = true;
  }
  filter(e) { this.search=e.target.value; }
  add() {
    this.sql.count('Timesheets',(c)=>{
      if(this.sql.Trial && this.sql.Trial_Time_sheets!=-1 && c>=this.sql.Trial_Time_sheets) {
        this.sql.show_purhchase_modal();
      } else {this.router.navigate(['folder','Timesheet','New']);}
    });
  }
  end() {this.router.navigate(['folder','Dashboard']);}
  get_timesheets(event?, cb?) {
    this.sql.fetch('Timesheets',(e)=>{
      this.count=e.length;
      this.calc_sec(e,(d)=>{
        if(d)this.timesheets = d;
        this.second_check();
        if(cb)cb(d);
      });
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  calc_sec(z,cb) {var i=0, d=[];
    for(let e of z) { i++;
      this.ts.calculate_seconds(e.timesheet_id,(h,m,s)=>{
        e['seconds']=s; e['minutes']=m; e['hours']=h;
        e=this.ts.set_timer(e);
        d.push(e);
        if(i==z.length)cb(d);
      });  
    }
  }
  second_check() {
    if(!this.one_second_timer) {
      this.one_second_timer = setInterval(()=>{
        for (var i = 0; i < this.timesheets.length; ++i) {
          if(this.timesheets[i].timer_active==1) {
            this.timesheets[i].seconds=this.timesheets[i].seconds+1
            if(this.timesheets[i].seconds>59){
              this.timesheets[i].seconds=0
              this.timesheets[i].minutes=this.timesheets[i].minutes+1
            }
            if(this.timesheets[i].minutes>59){
              this.timesheets[i].minutes=0
              this.timesheets[i].seconds=0
              this.timesheets[i].hours=this.timesheets[i].hours+1
            }
            if(this.timesheets[i].hours>23){
              this.timesheets[i].hours=0
              this.timesheets[i].minutes=0
              this.timesheets[i].seconds=0
              this.timesheets[i].days=this.timesheets[i].days+1
            }
            this.timesheets[i]=this.ts.set_timer(this.timesheets[i]);
          }
        }
      },1000);
    }
  }
  employee(ts, item?) {
    this.router.navigate(['folder','Employee',ts.employee.employee_id]);
    if(item)item.close();
  }
  edit(ts, item?) {
    if(this.multiple){
      this.toggle_item(ts);
    } else {
      this.router.navigate(['folder','Timesheet',ts.timesheet_id]);
    }
    if(item)item.closeSlidingItems();
  }
  delete(ts, item?) { 
    if(confirm(this.sql.word.Delete_Timesheet_Confirmation)) {
      this.sql.delete('Timesheets','timesheet_id',ts.timesheet_id);
      this.get_timesheets();
    } else {
      if(item)item.close();
    }
  }
  toggle_multiple() {
    if(!this.selected || this.selected.length==0){
      alert(this.sql.word.Select_Multiple_Timesheets_Prompt);
    } else {
      if(this.multiple_running) {
        this.stop_multiple();  
        this.multiple_running=false;
      } else {
        this.start_multiple();
        this.multiple_running=true;
      }
    }
  }
  start_multiple() {
    for (let s of this.timesheets) {
      if (this.selected.includes(s.timesheet_id)) {
        this.start_timer(s);
      }
    }
  }
  stop_multiple() {
    for (let s of this.timesheets) {
      if (this.selected.includes(s.timesheet_id)) {
        this.stop_timer(s);
      }
    }
  }
  done_multiple() {
    this.multiple = false;
  }
  toggle_item(item) {
    if( this.selected.includes(item.timesheet_id) ) {
      this.deselect_item(item);
    } else {
      this.select_item(item);
    }
  }
  select_item(item) {
    this.act = 0;
    for (var i = 0; i < this.timesheets.length; ++i) {
      if(this.timesheets[i].timer_active==1)this.act++;
    }
    if(item.timer_active==1){
      if (this.act>this.timesheets.length/2) {
        this.multiple_running=true;
      }
    }
    this.selected.push(item.timesheet_id);
  }
  deselect_item(item) {
    var new_selection=[];
    for(var i = 0; i < this.selected.length; ++i){
      if(this.selected[i]!=item.timesheet_id){
        new_selection.push(this.selected[i]);
      }
    }
    this.selected = new_selection;
  }
  select_all() {
    var new_selection=[]; this.act=0;
    for (var i = 0; i < this.timesheets.length; ++i) {
      if(this.timesheets[i].timer_active==1)this.act++;
      new_selection.push(this.timesheets[i].timesheet_id);
    }
    if(this.act>this.timesheets.length/2){
      this.multiple_running=true;
    } else {
      this.multiple_running=false;
    }
    this.selected=new_selection;
  }
  deselect_all() {
   this.selected = []; 
  }
  delete_selected() {
     if(!this.selected || this.selected.length==0){
      alert(this.sql.word.Select_Multiple_Timesheets_Prompt);
    } else {
      if(confirm(this.sql.word.Timesheet_Multiple_Delete_Confirmation)) {
        var i = 0;
        for(let c of this.selected) {
          this.sql.delete('Timesheets','timesheet_id',c,(Done)=>{i++;
            if(i==this.selected.length) {
              this.get_timesheets(null,()=>{
                this.selected = [];  
              });
            }
          });
        }
        
      }
    }
  }
  enable(timesheet){
    this.sql.set('Timesheets','timesheet_id',timesheet.timesheet_id,'enabled',1,(res)=>{
      timesheet.enabled = true;
    });
  }
  disable(timesheet){
    this.sql.set('Timesheets','timesheet_id',timesheet.timesheet_id,'enabled',0,(res)=>{
      timesheet.enabled = false;
    });
  }
  start_timer(ts, item?) {
    ts.timer_active=1;
    var now = new Date(Date.now()).toISOString();
    if (!ts.start_date) this.sql.set( "Timesheets", "timesheet_id", ts.timesheet_id, "start_date", now);
    if (!ts.start_time) this.sql.set( "Timesheets", "timesheet_id", ts.timesheet_id, "start_time", now);
    if (!ts.name) this.sql.set( "Timesheets", "timesheet_id", ts.timesheet_id, "name", this.sql.word.Untitled_Entry);
    this.sql.set( "Timesheets", "timesheet_id", ts.timesheet_id, "timer_active", 1);
    this.new_timer(ts);
    if(item)item.close(); 
  }
  new_timer(timesheet) {
    var entry = new NewTimesheetEntry();
      entry.start_date=new Date(Date.now()).toISOString();
      entry.start_time=new Date(Date.now()).toISOString();  
      entry.timesheet_id=timesheet.timesheet_id;
      this.sql.add("TimesheetEntry",entry,(id)=>{if(!id)return;
        timesheet.active_timer=id;
        // this.save(()=>{
          console.log('this.timesheet.active_timer',timesheet.active_timer)
          // this.get_entries();
        // });
      });
  }
  stop_timer(ts, item?) {
    var d = new Date(Date.now()).toISOString();
    ts.end_date = d;
    ts.end_time = d;
    ts.timer_active=0;
    var now = new Date(Date.now()).toISOString();
    this.ts.kill_timer(ts.active_timer,()=>{
      this.sql.set( "Timesheets", "timesheet_id", ts.timesheet_id, "end_date", now);
      this.sql.set( "Timesheets", "timesheet_id", ts.timesheet_id, "end_time", now);
      if (!ts.timer_active) this.sql.set( "Timesheets", "timesheet_id", ts.timesheet_id, "name", this.sql.word.Untitled_Entry);
      this.sql.set( "Timesheets", "timesheet_id", ts.timesheet_id, "timer", ts.timer);
      this.sql.set( "Timesheets", "timesheet_id", ts.timesheet_id, "timer_active", -1);
    });
    if(item)item.close(); 
  }
  ngOnInit() {
    this.timesheets=[];   
    this.get_timesheets();
  }
  ngOnDestroy() {
    this.timesheets=[];
    clearInterval(this.one_second_timer);
  }
}