import { SQLService } from '../../services/sql.service';
import { PopoverController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-expenses-menu',
  templateUrl: './expenses-menu.component.html',
  styleUrls: ['./expenses-menu.component.scss'],
})
export class ExpensesMenuComponent implements OnInit {
  constructor(
    public sql: SQLService,
    public popover: PopoverController,
    private router: Router) {
  }
  list:string;
  @Input('enabled') enabled:boolean;
  dismissPopover(e) {
    if (this.popover) this.popover.dismiss(e);
  }
  delete() {
    this.dismissPopover('delete');
  }
  disable() {
    this.dismissPopover('disable');
  }
  enable() {
    this.dismissPopover('enable');
  }
  ngOnInit() {
  }
}
