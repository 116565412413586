import * as converter from 'number-to-words';
import * as ChequeTemplates from '../../../templates';
import pdfFonts from '../../../../../../pdfmake/build/fonts';
import { environment } from '../../../../../environments/environment';
import { SQLService } from '../../../../services/sql.service';
import { NewDesign } from '../../../../models/design';
import { Component, OnInit, Input, ViewChild } from '@angular/core';import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'cheque-properties',
  templateUrl: './cheque-properties.component.html',
  styleUrls: ['./cheque-properties.component.styl']
})
export class ChequePropertiesComponent implements OnInit {
  constructor(
    public sql: SQLService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
  ) { }
  @Input('design') design: any;


  ngOnInit() {
    console.log(this.design);
  }
}