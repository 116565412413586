import { SQLService } from '../../../../../services/sql.service';
import { PopoverController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'ng-cheques-position-popover',
  templateUrl: './position-menu.component.html',
  styleUrls: ['./position-menu.component.styl']
})
export class PositionMenuComponent implements OnInit {
  constructor(
    public sql: SQLService,
    public popover: PopoverController,
    private router: Router) {
  }
  type: any;
  vert: any;
  horz: any;
  dismissPopover(e) {
    if (this.popover) this.popover.dismiss({
      type:this.type, 
      vert:this.vert, 
      horz:this.horz
    });
  }
  ngOnInit() {
  }
}