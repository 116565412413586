import { Component, ElementRef,ViewChild,OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SQLService } from '../../../services/sql.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.styl'],
})
export class PhoneComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private sql: SQLService,
    private location: Location,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.open();
    });
  }
  number='';
  end() {this.location.back()}
  contacts() {

  }
  open() {
    var n=this.route.snapshot.paramMap.get("nu");;
    if(n)this.number=n;
  }
  ngOnInit() {
    if(!this.sql.phone)this.end();
    this.open();
  }
}
