export class NewCommand {
	constructor(
		public command_id?: number,
		public device_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public command?: string,
		public completed?: boolean,
		public applied?: any,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Commands = '\
	CREATE TABLE IF NOT EXISTS "Commands" (\
		command_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		device_id INTEGER DEFAULT 0,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		command TEXT,\
		completed INTEGER DEFAULT 0,\
		applied TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';