import { NewAdministrator } from '../../../models/administrator';
import { environment } from '../../../../environments/environment';
import { SelectAddressPage } from '../../../addresses/select-address/select-address.page';
import { SelectEmployeePage } from '../../../employees/select-employee/select-employee.page';
import { SQLService } from '../../../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
@Component({
  selector: 'ng-cheques-new-administrator',
  templateUrl: './new-administrator.component.html',
  styleUrls: ['./new-administrator.component.styl']
})
export class NewAdministratorComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public sql: SQLService,
    private location: Location,
    public modalController: ModalController
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.open();
    });
  }
  _c=0;
  currencies:any;
  admin = new NewAdministrator();
  async add_address() {
    const m = await this.modalController.create({component: SelectAddressPage, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1) {
        this.delete_address();
      } else {
        this.admin.address_ids = a.data;
        this.sql.view('Addresses','address_id',a.data,(a)=>{this.admin.addresses=a;});
        this._c=1;
        this.sql.hapticsImpactLight();
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_address(address) {
    this.router.navigate(['folder','Address', address.address_id]); 
  }
  delete_address() {
    this.admin.addresses = null;
    // this.sql.set( 'Administrators', 'admin_id', this.admin.admin_id, 'addresses', null);
  }
  async add_employee() {
    const m = await this.modalController.create({component: SelectEmployeePage, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1) {
        this.admin.employees = null;
      } else {
        this.admin.employees = a.data;
        this._c=1;
        this.sql.hapticsImpactLight();
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_employee(employee) {
    this.router.navigate(['folder','Employee', employee.employee_id]); 
  }
  delete_employee() {
    this.admin.employees =  null;
    this.sql.query('UPDATE Administrators WHERE admin_id = '+this.admin.admin_id+' SET employees = NULL');
  }
  end() {
    if (this._c>0) {
      if(confirm(this.sql.word.Discard_Confirmation)) {
        this.admin = new NewAdministrator();
        this.router.navigate(['folder','Administrators']);
      }
    } else { 
      this.router.navigate(['folder','Administrators']);
    }
  }
  delete_admin() {
    if(!this.admin.admin_id){
      this.end();
    } else if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('Administrators','admin_id',this.admin.admin_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  save(end=true) {
    this.sql.add("Administrators",this.admin,(insertId)=>{
      if(!insertId)return
      if(end){ 
        this.admin = new NewAdministrator();
        this._c=0;
        this.end();
      }
    });
  }
  update() { this.sql.update("Administrators",this.admin); }
  get_administrator() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida) return;
    this.sql.view('Administrators','admin_id',ida,(e)=>{
      var f = Object.entries(e[0]); let n=new NewAdministrator();
      for( let t of f) n[t[0] ]=t[1]; this.admin = n;
    });
  }
  open() {
    this.currencies = Object.keys(environment.currencies);
    this.admin.currency = this.sql.Currency;
    this.admin.enabled = true;
    this.get_administrator();
  }
  ngOnInit() {
    this.open();
  }
}