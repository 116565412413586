import { SQLService } from '../../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { PopoverController} from '@ionic/angular';
import { HolidayMenuComponent } from './holiday-menu/holiday-menu.component';
@Component({
  selector: 'ng-cheques-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.styl']
})
export class HolidaysComponent implements OnInit {
  constructor(
    public sql: SQLService,
    public popoverController: PopoverController,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_holidays();
    });
  }
  holidays = [];
  count = -1;
  search:string;
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:HolidayMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='disable')this.disable(item);
      if(data=='enable')this.enable(item);
      if(data=='delete')this.delete_holiday(item);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  
  filter(e) { this.search=e.target.value; }

  end() {
    this.router.navigate(['folder','Dashboard']);
  }
  add() {
    this.router.navigate(['folder','Holidays','New']);
  }
  disable(holiday,i?){
    this.sql.set('Holidays','holiday_id',holiday.holiday_id,'enabled',0,(res)=>{
      holiday.enabled = false;
      if(i)i.closeSlidingItems();
    });
  }
  enable(holiday,i?){
    this.sql.set('Holidays','holiday_id',holiday.holiday_id,'enabled',1,(res)=>{
      holiday.enabled = true;
      if(i)i.closeSlidingItems();
    });
  }
  get_holidays(event?) {
    this.sql.fetch('Holidays',(e)=>{
      this.holidays=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  edit_holiday(holiday, item?) {
    this.router.navigate(['folder','Holiday',holiday.holiday_id]);
  }
  delete_holiday(holiday, item?) { 
    if(confirm(this.sql.word.Discard_Confirmation)) {
      this.sql.delete('Holidays','holiday_id',holiday.employee_id);
      this.get_holidays();
    } else { 
      if(item)item.closeSlidingItems(); 
    }
  }

  ngOnInit() {
    this.get_holidays();
  }

}
