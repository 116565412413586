import { Component, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { SQLService } from '../../../services/sql.service';
import * as Currencies from '../../../../environments/currencies';
@Component({
  selector: 'app-cheque-table',
  templateUrl: './cheque-table.component.html',
  styleUrls: ['./cheque-table.component.scss'],
})
export class ChequeTableComponent implements AfterViewInit {
  constructor(private sql: SQLService) {
    this.currencies = Currencies;
  }
  @Output('loaded') loaded = new EventEmitter<boolean>();
  @Input('RenderMICR') RenderMICR = false;
  @Input('ImageMICR') ImageMICR = true;
  @Input('show') show: any;
  @Input('cheque') cheque: any;
  @Input('design') design: any;
  @Input('designer') designer: any;
  @Input('rotate') rotate: boolean;
  @Input('borders') borders: boolean;
  @Input('voidcheck') voidcheck: boolean;
  @Input('font') font: any;
  @Input('fiat') fiat: boolean;
  @Input('color') color: any;
  @Input('micr') micr: any;
  @Input('micr_top') micr_top: string;
  @Input('micr_right') micr_right: string;
  @Input('micr_bottom') micr_bottom: string;
  @Input('micr_left') micr_left: string;
  @Input('table_top') table_top: string;
  @Input('table_right') table_right: string;
  @Input('table_bottom') table_bottom: string;
  @Input('table_left') table_left: string;
  @Input('secret') secret = '';
  @Input('scale') scale =0.9;
  @Input('margin_bottom') bottom = '0mm';
  @Input('margin_top') top = '0mm';
  @Input('fontsize') fontsize: boolean;
  @Input('gradients') gradients: boolean;
  @Input('layers') layers: any;
  @Input('translations') translations = this.sql.word;
  @Input('bold') bold: boolean;
  @Input('italic') italic: boolean;
  @Input('fold_line') fold_line: boolean;
  @Input('template') template: string;
  @Input('monochrome') monochrome: boolean;
  @Input('show_cheque_number') show_cheque_number: boolean = true;
  @Input('show_cheque_date') show_cheque_date: boolean = true;
  @Input('show_micr') show_micr: boolean = true;
  @Input('cheque_background_image_repeat') cheque_background_image_repeat = false;
  @Input('background_position') background_position: string;
  @Input('cheque_text_color') cheque_text_color: string;
  @Input('cheque_background_image') cheque_background_image: any;
  @Input('cheque_logo_image') cheque_logo_image: any;
  @Input('cheque_header_image') cheque_header_image: any;
  @Input('cheque_bank_image') cheque_bank_image: any;
  @Input('border') border = false;
  @Input('table_style') table_style = 'hard';
  @Input('font') font_family: string;
  @Input('docugard') docugard: boolean;
  @Input('lines') lines = [];
  currencies: any;
  ngAfterViewInit() {
    this.design['public_image']['top']='29mm';
    this.design['public_image']['right']='6.5mm';
    this.design['public_image']['bottom']='auto';
    this.design['public_image']['left']='auto';
    this.design['public_image']['width']='18mm';
    this.design['key_image']['top']='40mm';
    this.design['key_image']['right']='auto';
    this.design['key_image']['bottom']='auto';
    this.design['key_image']['left']='6.5mm';
    this.design['key_image']['width']='15mm';
    this.design['currency_name']['top']='42.7mm';
    this.design['currency_name']['right']='auto';
    this.design['currency_name']['bottom']='auto';
    this.design['currency_name']['left']='23mm';
    this.design['address_data']['top']='46mm';
    this.design['address_data']['left']='23mm';
  }
  loaderer() {  
    this.loaded.emit(true);  
  }
}