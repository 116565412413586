import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-postage-menu',
  templateUrl: './postage-menu.component.html',
  styleUrls: ['./postage-menu.component.scss'],
})
export class PostageMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
