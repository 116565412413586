export class NewSchedule {
	constructor(
		public enabled?: boolean,
		public schedule_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public employee_id?: number,
		public payee_id?: number,
		public title?: string,
		public description?: string,
		public employees?: any,
		public employee_ids?: any,
		public payees?: any,
		public payee_ids?: any,
		public payment_form?: any,
		public start_date?: any,
		public start_time?: any,
		public end_date?: any,
		public end_time?: any,
		public address?: any,
		public printed_memo?: string,
		public payment_frequency?: string,
		public holiday_enabled?: boolean,
		public addresses?: any,
		public date?: any,
		public repeat?: any,
		public name?: string,
		public amount?: any,
		public always_enabled?: boolean,
		public currency?: string,
		public modified?: any,
		public created?: any
	){}
}
export const Create_Schedules = '\
	CREATE TABLE IF NOT EXISTS "Schedules" (\
		schedule_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER,\
		mod_id INTEGER,\
		employee_id INTEGER,\
		payee_id INTEGER,\
		title TEXT,\
		description TEXT,\
		employees TEXT,\
		employee_ids TEXT,\
		payees TEXT,\
		payee_ids TEXT,\
		payment_form TEXT,\
		start_date TIMESTAMP,\
		start_time TIMESTAMP,\
		end_date TIMESTAMP,\
		end_time TIMESTAMP,\
		address TEXT,\
		printed_memo TEXT,\
		payment_frequency TEXT,\
		addresses TEXT,\
		date TEXT,\
		repeat TEXT,\
		name TEXT,\
		amount TEXT,\
		holiday_enabled INTEGER DEFAULT 1,\
		always_enabled INTEGER DEFAULT 0,\
		currency TEXT,\
		enabled INTEGER,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';