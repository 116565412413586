import { SQLService } from '../../../../services/sql.service';
import { NewPayroll } from '../../../../models/payroll';
import { SelectDatePage } from '../../../../settings/select-date/select-date.component';
import { SelectEmployeePage } from '../../../../employees/select-employee/select-employee.page';
import { SelectBankPage } from '../../../../settings/bank-accounts/select-bank/select-bank.page';
import { SelectPayeePage } from '../../../../payees/select-payee/select-payee.page';
import { SelectJobPage } from '../../../../jobs/select-job/select-job.page';
import { environment } from '../../../../../environments/environment';
import { Flip } from '../../../../app.animations';
import { CalendarModal, CalendarModalOptions, DayConfig, CalendarResult } from 'ion2-calendar';
import { Component, OnInit, OnChanges, Input, ViewChild, HostListener,Output,EventEmitter } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { Location } from '@angular/common';
@Component({
  selector: 'app-intro-page-9',
  templateUrl: './9-ninth.page.html',
  styleUrls: ['./9-ninth.page.styl'], 
})
export class IntroNinthPageComponent implements OnInit, OnChanges {
  ngOnChanges(obj){

  }
  @Input('saver') _save:boolean
  @Output('complete') complete = new EventEmitter();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public sql: SQLService,
    public modalController: ModalController,
    private location: Location,
    public platform: Platform
  ) { 
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.open();
    });
  }
  payroll = new NewPayroll('week','both',true);
  currencies = Object.keys(environment.currencies);
  sort_by_alpha = false;
  filter_paid = false;
  sort_by_money = false;
  _c=0;
  async add_employee() {
    const m = await this.modalController.create({component: SelectEmployeePage, componentProps: {}});
    await m.present();
    var a = await m.onDidDismiss();
    this.payroll.employees = a.data;
    if(a.data)this._c=1;
  }
  edit_employee(employee) {
    this.router.navigate(['folder','Employee', employee.employee_id]); 
  }
  delete_employee() {
    this.payroll.employees = null;
    this.sql.query('UPDATE Payroll WHERE payroll_id = '+this.payroll.payroll_id+' SET employees = NULL');
  }
  async add_payee() {
    const m = await this.modalController.create({component: SelectPayeePage, componentProps: {}});
    await m.present();
    var a = await m.onDidDismiss();
    this.payroll.payees = a.data;
    if(a.data)this._c=1;
  }
  edit_payee(payee) {
    this.router.navigate(['folder','Payee', payee.payee_id]); 
  }
  delete_payee() {
    this.payroll.payees = null;
    this.sql.query('UPDATE Payroll WHERE payroll_id = '+this.payroll.payroll_id+' SET payees = NULL');
  }
  async add_job() {
    const m = await this.modalController.create({component: SelectJobPage, componentProps: {}});
    await m.present();
    var a = await m.onDidDismiss();
    this.payroll.jobs = a.data;
    if(a.data)this._c=1;
  }
  edit_job(job) {
    this.router.navigate(['folder','Job', job.job_id]); 
  }
  delete_job() {
    this.payroll.jobs = null;
    this.sql.query('UPDATE Payroll WHERE payroll_id = '+this.payroll.payroll_id+' SET jobs = NULL');
  }
  async add_bank_account() {
    const m = await this.modalController.create({component: SelectBankPage, componentProps: {}});
    await m.present();
    var a = await m.onDidDismiss();
    this.payroll.banks = a.data;
  }
  edit_bank_account(bank) {
    this.router.navigate(['folder','Account', bank.bank_id]); 
  }
  delete_bank_account() {
    this.sql.query('UPDATE Payroll WHERE payroll_id = '+this.payroll.payroll_id+' SET banks = NULL');
    this.payroll.banks = null;
  }
  async openCalendar(end?) {
    const options:CalendarModalOptions={title:end?this.sql.word.End_Date:this.sql.word.Start_Date};
    const myCalendar = await this.modalController.create({
      component: CalendarModal,
      componentProps: { options }
    });
    myCalendar.present();
    const event: any = await myCalendar.onDidDismiss();
    const date: CalendarResult = event.data;
    if(end) {
      this.payroll.end_date=date.dateObj.toISOString();
    } else {
      this.payroll.start_date=date.dateObj.toISOString();
      if(!this.payroll.end_date) {
        this.payroll.end_date=date.dateObj.toISOString();
      }
    }
    console.log(this.payroll.end_date);
    console.log(this.payroll.start_date);
  }
  save() {
    this.sql.add("Payrolls",this.payroll);
    this.payroll = new NewPayroll('fortnite','both',true);
    this._c=0; 
    this.end();
  }
  update() { this.sql.update("Payrolls",this.payroll); }
  end() {
    if (this._c>0) {
      if(confirm(this.sql.word.Discard_Confirmation)) {
        this.payroll = new NewPayroll('fortnite','both',true);
        this.location.back();
      }
    } else { this.location.back(); }
  }
  get_payment() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida) return;
    this.sql.view('Payrolls','payroll_id',ida,(e)=>{
      var f=Object.entries(e[0]); let n = new NewPayroll('fortnite','both',true);
      for(let t of f)n[t[0]]=t[1]; this.payroll =n;
    });
  }
  open() {
    this.payroll.currency=this.sql.Currency;
    this.payroll.start_date=new Date(Date.now()).toISOString();
    this.payroll.frequency='fortnite';
    this.payroll.indefinite_payroll_enabled=true;
    this.payroll.payroll_limit=0;
    this.payroll.payment_limit=0;
    this.payroll.total_limit=0;
    this.get_payment();
  }
  ngOnInit() {
   this.open();
  }
}