import { main } from './app.animations';
import { environment } from '../environments/environment';
import { SelectPrintJobsPage } from './settings/printers/jobs/select-printjob/select-printjob.page';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MenuController, PopoverController, LoadingController} from '@ionic/angular';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { SQLService } from './services/sql.service';
import { CoinService } from './services/coin.service';
import { PaymentsService } from './services/payments.service';
import { PayrollService } from './services/payroll.service';
import { Platform, ModalController } from '@ionic/angular';
import { PopoverAccountsComponent } from './dashboard/popover-accounts/popover-accounts.component';
import { SettingsComponent } from './settings/settings.component';
import { PopoverComponent } from './dashboard/popover/popover.component';
@Component({
  selector: 'app-root',
  animations: [main],
  providers:[PopoverComponent,SettingsComponent],
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  inter: any;
  startup:string;
  loading:boolean;
  show_startup = false;
  show_spinner = false;
  public show_menu = false;
  public ShowUserSettings = false;
  public ShowAdminSettings = false;
  public ShowDepositSettings = true;
  public identifier = 'folder';
  public appPages = [];
  public userSettings = [];
  public depositSettings = [];
  public adminSettings = [];
  public appSettings = [];
  constructor( 
    public sql: SQLService,
    public coin: CoinService,
    public payment: PaymentsService,
    private platform: Platform,
    private loadingCtrl: LoadingController,
    public popover: PopoverController,
    public modalController: ModalController, 
    private popoverComponent: PopoverComponent,
    private settingsComponent: SettingsComponent,
    private router: Router,
    private payroll: PayrollService,
    private route: ActivatedRoute,
    private location: Location,
  ) {
    router.events.subscribe((val) => {
      var p = this.location.path();
      if(this.platform.is('capacitor')) {
        this.show_menu = ( p == '/folder/Splash' )?false:true;
      } else {
        if(this.sql.model=='web') {
          this.show_menu = true;
        } else {
          this.show_menu = true;
        }
      }
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }
  async present_accounts_menu(ev: any) {
    ev.preventDefault();
    if(this.sql.Unlimited||this.sql.Subscription) {
      if(this.sql.multiple_companies){
        var menu = await this.popover.create({component: PopoverAccountsComponent, cssClass: 'accounts-popover',event: ev,translucent: true});
        await menu.present();
        var role = await menu.onDidDismiss();
        // if(role.data=='customize')this.customize_dashboard();
      }
    } else {
      alert(this.sql.word.Multiple_Companies_Word);
      this.sql.show_purhchase_modal();
    }
  }
  _func(f){
    switch (f) {
      case "Signup":
        this.sql.show_signup_modal();  
      break;
      case "Login":
        this.sql.show_login_modal();  
      break;
      case "Logout":
          this.sql.logout();
      break;
    }
  }
  dashboard(){this.router.navigate(['folder','Dashboard']);}
  ngOnDestroy(){clearInterval(this.inter);}
  async ngOnInit(){
    this.sql.has_security((enabled)=>{
      this.sql.show_lock_screen = enabled;
      if(this.sql.show_lock_screen && !this.sql.logged_in){
        this.router.navigate(['folder','Splash']);
      }
    });
    this.identifier = this.route.snapshot.paramMap.get("folder");
    this.sql.create();
    this.ShowDepositSettings=this.sql.deposit_enabled;
    this.sql.get_role((UserRole)=>{
      if(UserRole=='admin')this.ShowAdminSettings=true;
      if(UserRole=='employee')this.ShowUserSettings=true;
    });
    if(this.sql.locked)this.dashboard();
    this.sql.startup((m)=>{
      if(m.message!=''){
        this.br(m.message);
        if(m.milliseconds==-1){
          this.startup=m.message;
          this.show_startup=true;
        }else if(m.milliseconds>0){
          this.startup=m.message;
          this.show_startup=true;
          setTimeout(()=>{this.show_startup=false;},m.milliseconds);
        }
      }
    });
  }
  br(st) {
    if     (st=='#1#CHEQUES#14')this.sql.EnableInsurance=true;                    // enable insurance features
    else if(st=='#1#CHEQUES#14B#1')this.sql.InsurePayees=true;                // enable insurance features
    else if(st=='#1#CHEQUES#14B#0')this.sql.InsurePayees=false;               // enable insurance features
    else if(st=='#1#CHEQUES#13')this.sql.EnableInsurance=false;               // disable insurance features
    else if(st=='#1#CHEQUES#7')this.sql.AdminEnabled=true;                    // enable administrator features
    else if(st=='#1#CHEQUES#6')this.sql.AdminEnabled=false;                   // disable administrator features
    else if(st=='#1#CHEQUES#9')this.sql.ModeratorsEnabled=true;               // enable moderator features
    else if(st=='#1#CHEQUES#8')this.sql.ModeratorsEnabled=false;              // disable moderator features
    else if(st=='#1#CHEQUES#0B')this.sql.reset_database2(false);              // reset purchases [prompt]
    else if(st=='#1#CHEQUES#0B#F')this.sql.reset_database2(true);             // reset purchases [force]
    else if(st=='#1#CHEQUES#00')this.settingsComponent.reset_database(false); // reset app       [prompt]
    // else if(st=='#1#CHEQUES#00#F')this.settingsComponent.reset_database(true);  // reset app       [force]
    else if(st=='#1#CHEQUES#83')this.sql.skype=false;                         // disable skype addresses
    else if(st=='#1#CHEQUES#82')this.sql.skype=true;                          // enable  skype addresses
    else if(st=='#1#CHEQUES#81')this.sql.geofence=true;                       // enable  geofence
    else if(st=='#1#CHEQUES#80')this.sql.geofence=false;                      // disable geofence
    else if(st=='#1#CHEQUES#79')this.sql.pin_protection=true;                 // enable  pin_protection
    else if(st=='#1#CHEQUES#78')this.sql.pin_protection=false;                // disable pin_protection
    else if(st=='#1#CHEQUES#77')this.sql.pass_protection=true;                // enable  pass_protection
    else if(st=='#1#CHEQUES#76')this.sql.pass_protection=false;               // disable pass_protection
    else if(st=='#1#CHEQUES#75')this.sql.biometric_protection=true;           // enable  biometric_protection
    else if(st=='#1#CHEQUES#74')this.sql.biometric_protection=false;          // disable biometric_protection
    else if(st=='#1#CHEQUES#73')this.sql.bank_number_protection=true;         // enable  bank_number_protection
    else if(st=='#1#CHEQUES#72')this.sql.bank_number_protection=false;        // disable bank_number_protection
    else if(st=='#1#CHEQUES#71')this.sql.smart_printjobs=true;                // enable  smart_printjobs
    else if(st=='#1#CHEQUES#70')this.sql.smart_printjobs=false;               // disable smart_printjobs 
    else if(st=='#1#CHEQUES#69')this.sql.pho=true;                            // enable  phone
    else if(st=='#1#CHEQUES#68')this.sql.pho=false;                           // disable phone
    else if(st=='#1#CHEQUES#657')this.sql.enable_ultimate=true;               // enable  enable_ultimate
    else if(st=='#1#CHEQUES#656')this.sql.enable_ultimate=false;              // disable enable_ultimate
    else if(st=='#1#CHEQUES#65')this.sql.enable_buy=true;                     // enable  enable_buy
    else if(st=='#1#CHEQUES#64')this.sql.enable_buy=false;                    // disable enable_buy
    else if(st=='#1#CHEQUES#63')this.sql.multiple_companies=true;             // enable  multiple_companies
    else if(st=='#1#CHEQUES#62')this.sql.multiple_companies=false;            // disable multiple_companies
    else if(st=='#1#CHEQUES#61')this.sql.show_printed_credit=1;               // enable  show_printed_credit
    else if(st=='#1#CHEQUES#60')this.sql.show_printed_credit=0;               // disable show_printed_credit
    else if(st=='#1#CHEQUES#60')this.sql.enable_secondly=true;                // enable  enable_secondly
    else if(st=='#1#CHEQUES#59')this.sql.enable_secondly=false;               // disable enable_secondly
    else if(st=='#1#CHEQUES#58')this.sql.enable_minutely=true;                // enable  enable_minutely
    else if(st=='#1#CHEQUES#57')this.sql.enable_minutely=false;               // disable enable_minutely
    else if(st=='#1#CHEQUES#56')this.sql.fax=true;                            // enable  fax
    else if(st=='#1#CHEQUES#55')this.sql.fax=false;                           // disable fax
    else if(st=='#1#CHEQUES#54')this.sql.stripe=true;                         // enable  stripe
    else if(st=='#1#CHEQUES#53')this.sql.stripe=false;                        // disable stripe
    else if(st=='#1#CHEQUES#52')this.sql.bitcoin=true;                        // enable  bitcoin
    else if(st=='#1#CHEQUES#51')this.sql.bitcoin=false;                       // disable bitcoin
    else if(st=='#1#CHEQUES#50')this.sql.EnableInvoices=true;                 // enable  EnableInvoices
    else if(st=='#1#CHEQUES#49')this.sql.EnableInvoices=false;                // disable EnableInvoices
    else if(st=='#1#CHEQUES#48')this.sql.InsurePayees=true;                   // enable  InsurePayees
    else if(st=='#1#CHEQUES#47')this.sql.InsurePayees=false;                  // disable InsurePayees
    else if(st=='#1#CHEQUES#46')this.sql.max_cheque_amount=true;              // enable  max_cheque_amount
    else if(st=='#1#CHEQUES#45')this.sql.max_cheque_amount=false;             // disable max_cheque_amount
    else if(st=='#1#CHEQUES#44')this.sql.max_payment_amount=true;             // enable  max_payment_amount
    else if(st=='#1#CHEQUES#43')this.sql.max_payment_amount=false;            // disable max_payment_amount
    else if(st=='#1#CHEQUES#42')this.sql.max_payroll_amount=true;             // enable  max_payroll_amount
    else if(st=='#1#CHEQUES#41')this.sql.max_payroll_amount=false;            // disable max_payroll_amount
    else if(st=='#1#CHEQUES#40')this.sql.enable_override=true;                // enable  enable_override
    else if(st=='#1#CHEQUES#39')this.sql.enable_override=false;               // disable enable_override
    else if(st=='#1#CHEQUES#38')this.sql.more_verbose=true;                   // enable  more_verbose
    else if(st=='#1#CHEQUES#37')this.sql.more_verbose=false;                  // disable more_verbose
    else if(st=='#1#CHEQUES#36')this.sql.verbose=true;                        // enable  verbose
    else if(st=='#1#CHEQUES#35')this.sql.verbose=false;                       // disable verbose
    else if(st=='#1#CHEQUES#91#0')this.sql.show_docu_title=0;                 // set show_docu_title
    else if(st=='#1#CHEQUES#91#1')this.sql.show_docu_title=1;                 // set show_docu_title
    else if(st=='#1#CHEQUES#91#2')this.sql.show_docu_title=2;                 // set show_docu_title
    else if(st=='#1#CHEQUES#91#3')this.sql.show_docu_title=3;                 // set show_docu_title
    else if(st=='#1#CHEQUES#91#4')this.sql.show_docu_title=4;                 // set show_docu_title
    else if(st=='#1#CHEQUES#91#5')this.sql.show_docu_title=5;                 // set show_docu_title
    else if(st=='#1#CHEQUES#91#6')this.sql.show_docu_title=6;                 // set show_docu_title
    else if(st=='#1#CHEQUES#91#7')this.sql.show_docu_title=7;                 // set show_docu_title
    else if(st=='#1#CHEQUES#91#8')this.sql.show_docu_title=8;                 // set show_docu_title
    else if(st=='#1#CHEQUES#94#ON')this.sql.on=true;                          // enable
    else if(st=='#1#CHEQUES#94#OFF')this.sql.on=false;                        // disable
    else if(st=='#1#CHEQUES#94#LOCK')this.popoverComponent.lockdown();        // lockdown app
    else if(st=='#1#CHEQUES#94#UNLOCK')this.popoverComponent.unlock();        // unlock lockdown app
    else if(st=='#1#CHEQUES#94#LOGOUT')this.popoverComponent.logout();        // logout of app
    else if(st=='#1#CHEQUES#94#SETUP')this.popoverComponent.setup();          // open setup
    else if(st=='#1#CHEQUES#94#TRIAL'){this.sql.Trial=true;this.sql.Unlimited=false;this.sql.Subscription=false;}
    else if(st=='#1#CHEQUES#95')this.sql.enable_bank_limits=true;             // enable  limit settings
    else if(st=='#1#CHEQUES#96')this.sql.enable_bank_limits=false;            // disable limit settings
    else if(st=='#1#CHEQUES#97')this.sql.AvailablePrints=0;                   // print limit settings
    else if(st=='#1#CHEQUES#98')this.sql.AvailablePrints=-1;                  // disable print limit settings
    else if(st=='#1#CHEQUES#99'){this.sql.Trial=false;this.sql.Unlimited=false;this.sql.Subscription=true;}
    else if(st=='#1#CHEQUES#100'){this.sql.Trial=false;this.sql.Unlimited=true;this.sql.Subscription=false;}
    else if(st.contains('#1#CHEQUES#101')){
      var a = 0;st.split('#1#CHEQUES#101#');
      this.sql.max_cheque_amount=a[1];
    } else if(st.contains('#1#CHEQUES#102')){
      var a = 0;st.split('#1#CHEQUES#102#');
      this.sql.max_payment_amount=a[1];
    } else if(st.contains('#1#CHEQUES#103')){
      var a = 0;st.split('#1#CHEQUES#103#');
      this.sql.max_payroll_amount=a[1];
    } else if(st=='#1#CHEQUES#104'){
      this.sql.post_order_count();
      this.sql.enable_bank_limits=false;
    }else if(st=='#21#7'){this.sql.AvailablePrints=this.sql.AvailablePrints+1;}
  }
}