import { SQLService } from '../../services/sql.service';
import { Flip } from '../../app.animations';
import { environment } from '../../../environments/environment';
import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController,NavParams } from '@ionic/angular';
import { NewPayment } from '../../models/payment';
@Component({
  selector: 'app-send-paypal',
  templateUrl: './paypal-payment.page.html',
  styleUrls: ['./paypal-payment.page.styl'],
  animations: [ Flip ]
})
export class SendPayPalPage implements OnInit {
  constructor(
    private router: Router,
    public sql: SQLService,
    private params: NavParams,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    private location: Location
  ) {
    this.payment_id = params.get('payment_id');
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) { }
    });
  }
  payment = new NewPayment();
  currencies = Object.keys(environment.currencies);
  paypal_currencies = [];
  payment_id: number;
  sending: boolean;
  sent: boolean;
  send() {
    this.sent=false;
    if(confirm(this.sql.word.Send_PayPal_Confirmation)) {
      this.sending=true;
      setTimeout(()=>{this.sending=false;this.sent=true;},2000);
    }
  }
  add() {
    
  }
  off() {
    this.navCtrl.dismiss(this.sent);
  }
  get_payment() {
    this.sql.view('Payments','payment_id',this.payment_id,(e)=>{
      if(e[0]){
        var f=Object.entries(e[0]);let n = new NewPayment();
        for(let t of f)n[t[0]]=t[1];this.payment=n;
      }
    });
  }
  ngOnInit() {
    this.paypal_currencies=[];
    for(var [key, value] of Object.entries(environment.currencies))if(value.paypal)this.paypal_currencies.push(value);
    this.get_payment();
  }
}