import * as ChequeTemplates from '../templates';
import * as converter from 'number-to-words';
import { NewCheque } from '../../models/cheque';
import { SQLService } from '../../services/sql.service';
import { CoinService } from '../../services/coin.service';
import { NewDesignObjects } from '../../models/designobjects';
import { Currencies } from '../../../environments/currencies';
import { environment } from '../../../environments/environment';
import { PurchaseComponent } from '../../dashboard/purchase/purchase.component';
import { PrintAddressComponent } from '../../addresses/print-address/print-address.component';
import { SelectChequeDesignsPage } from '../designs/select-design/select-design.page';
import { NumberToWord, kananda, english, telagu, hindi, marathi } from "multilingual-number-to-words";
import { Component, OnChanges, SimpleChanges, AfterViewInit, ElementRef, ChangeDetectorRef, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DatePipe,CurrencyPipe } from '@angular/common';
import { ModalController, LoadingController, AlertController, Platform } from '@ionic/angular';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
@Component({
	selector: 'ng-generate-cheque',
	templateUrl: './generate-cheque.component.html',
	styleUrls: ['./generate-cheque.component.styl']
})
export class GenerateChequeComponent implements AfterViewInit, OnChanges, OnDestroy {
	constructor(
		private sql: SQLService,
		private coin: CoinService,
		private router: Router,
		private platform:Platform,
		private emailer: EmailComposer,
		private alertController: AlertController,
		private modalController: ModalController,
		private screenOrientation: ScreenOrientation,
		private loadingController: LoadingController,
		private changeDetector: ChangeDetectorRef,
		private activatedRoute: ActivatedRoute
	) {
		this.currencies = Currencies;
		this.router.events.subscribe((eve) => {
			if(eve instanceof NavigationEnd){
				this.open();
				this.zoom_adj=false;
				this.adj_zoom();
			}
		});
		this.screenOrientation.onChange().subscribe(()=>{
			this.adjust_zoom();
		});// toggle pinch zoom with is_tablet boolean
		this.is_tablet=(this.platform.is('tablet')||this.sql.model=='web')?true:false;
	}
	@ViewChild('htmlViewerAutoLoad') 		public htmlViewerAutoLoad: ElementRef<HTMLInputElement>;
	@Input('micr') micr:boolean  			= true;
  	@Input('layers') layers:boolean  		= true;
  	@Input('gradients') gradients:boolean  	= true;
  	@Input('numbers') numbers:boolean  		= true;
  	@Input('date') date:boolean  			= true;
  	@Input('ImageMICR') ImageMICR:boolean  	= true;
	@Input('RenderMICR') RenderMICR:boolean = false;
	@Input('fold_line') fold_line:boolean 	= false;
	@Input('download') _download:boolean 	= false;
	@Input('designer') _designer:boolean  	= false;
	@Input('fullscreen') fullscreen:boolean = false;
	@Input('rotate') rotate:boolean  		= false;
	@Input('monochrome') monochrome:boolean = false;
	@Input('borders') borders:boolean  		= false;
	@Input('destroying') destroying:boolean = false;
	@Input('voidcheck') voidcheck:boolean  	= false;
	@Input('previewing') previewing:boolean = true;
	@Input('micr_top') micr_top: string;
	@Input('micr_right') micr_right: string;
	@Input('micr_bottom') micr_bottom: string;
	@Input('micr_left') micr_left: string;
	@Input('page_top') page_top: string;
	@Input('page_right') page_right: string;
	@Input('page_bottom') page_bottom: string;
	@Input('page_left') page_left: string;
	@Input('table_top') table_top: string;
	@Input('table_right') table_right: string;
	@Input('table_bottom') table_bottom: string;
	@Input('table_left') table_left: string;
	@Input('address_top') address_top: string;
	@Input('address_right') address_right: string;
	@Input('address_bottom') address_bottom: string;
	@Input('address_left') address_left: string;
	@Input('docugard') docugard:boolean;
	@Input('cheque') cheque_data: any;
	@Input('template') template: any;
	@Input('cheque_id') cheque_id: any;
	@Input('scale') scale = 1;//0.45;
	@Input('print_scale') print_scale:number;
	@Input('design_id') design_id: any;
	@Input('fontsize') fontsize=0;
	@Input('bold') bold: boolean;
	@Input('italic') italic: boolean;
	@Input('cheque_text_color') cheque_text_color: boolean;
	@Input('background_position') background_position: string;
	@Input('cheque_background_image_repeat') cheque_background_image_repeat: boolean;
	@Input('cheque_background_image') cheque_background_image: string;
	@Input('cheque_logo_image') cheque_logo_image: string;
	@Input('cheque_header_image') cheque_header_image: string;
	@Input('cheque_bank_image') cheque_bank_image: string;
	@Input('font') document_font:string;
	@Input('color') background_colour:string;
	@Input('paper') paper:string;
	@Input('printing') printing:string;
	@Input('doc_color') doc_color:string;
	@Input('print_language') print_language:string;
	@Input('lines') lines = [];
	@Input('secret') secret = '';
	@Input('page_size') page_size = 'A4';
	@Input('page_width') page_width = '210mm';
	@Input('page_height') page_height = '297mm';
	@Output('cheque_html') cheque_html_obj = new EventEmitter();
	@Output('html') html = new EventEmitter();
	@Output('image') image = new EventEmitter();
	closing=false;
	zoom_adj=false;
	center_view=false;
	is_tablet=false;
	starting_id=-1;
	// TODO: Fetch each Design key as an object from the database. 
	// Use this data to place the item on the canvas with absolute positining. 
	get_design_objects(
		pattern=this.cheque.design_pattern, 
		cheque_id=this.cheque.cheque_id,cb?){
	}
	public design_default = {
		"paper": 				 			null,
		"bottom": 				 			null,
		"top": 					 			null,
		"doc_color": 			 			null,
		"multiline_written_amount_chars":  	0,
		"fontsize": 			 			0,
		"monochrome": 			 			false,
		"borders": 				 			false,
		"voidcheck": 			 			false,
		"print_scale": 			 			false,
		"docugard": 			 			false,
		"height": 				 			false,
		"background_filters": 	 			false,
		"cheque_background_image_repeat":  	false,
		"background_position":  			false,
		"bold": 				 			false,
		"italic": 				 			false,
		"cheque_text_color":	 			false,
		"micr_top": 			 			false,
		"micr_right": 			 			false,
		"micr_bottom": 			 			false,
		"micr_left": 			 			false,
		"page_top": 			 			false,
		"page_right": 			 			false,
		"page_bottom": 			 			false,
		"page_left": 			 			false,
		"table_top": 			 			false,
		"table_right": 			 			false,
		"table_bottom": 		 			false,
		"table_left": 			 			false,
		"address_top": 			 			false,
		"address_right": 		 			false,
		"address_bottom": 		 			false,
		"address_left": 		 			false,
		"fold_line": 		 				false,
		"show_cheque_number": 	 			true,
		"show_cheque_date": 	 			true,
		"show_micr": 			 			true,
		"secret": 							'',
		"cheque_background_image": 			'',
		"cheque_logo_image": 				'',
		"cheque_header_image": 				'',
		"cheque_bank_image": 				'',
		"key_image": 						{zindex:false,width:false,top:false,right:false,bottom:false,left:false,QRerrorCorrection:'M',QRCodeMargin:'4',QRcolorDark:'#000000',QRcolorLight:'#ffffff'},
		"public_image": 					{width:false,top:false,right:false,bottom:false,left:false,QRerrorCorrection:'M',QRCodeMargin:'4',QRcolorDark:'#000000',QRcolorLight:'#ffffff'},
		"currency_name": 					{width:false,top:false,right:false,bottom:false,left:false,class:false},
		"address_data": 					{width:false,top:false,right:false,bottom:false,left:false,class:false},
		"bank_account_holder": 				{width:false,top:false,right:false,bottom:false,left:false},
		"cheque_header_image_properties":  	{width:false,top:false,right:false,bottom:false,left:false},
		"cheque_logo_image_properties":  	{width:false,top:false,right:false,bottom:false,left:false},
		"printed_id": 			 			{width:false,top:false,right:false,bottom:false,left:false},
		"date": 				 			{width:false,alternative:false,alternative_2:false,individual:false,top:false,right:false,bottom:false,left:false},
		"payment_name": 		 			{width:false,top:false,right:false,bottom:false,left:false},
		"date_label": 			 			{width:false,display:true,top:false,right:false,bottom:false,left:false},
		"payline": 				 			{width:false,prefix:true,borders:true,top:false,right:false,bottom:false,left:false},
		"amounts": 				 			{width:false,fontsize:false,symbol:true,top:false,right:false,bottom:false,left:false},
		"amount_number": 		 			{width:false,borders:true,background:true,top:false,left:false,right:false},
		"written_amount": 		 			{width:false,height:false,index:true,top:false,right:false,bottom:false,left:false},
		"written_amount_line":   			{width:false,overflow:false,height:"23px",borders:true,left:false,right:false,top:false,bottom:false},
		"written_amount_line2":  			{width:false,left:false,right:false,top:false,bottom:false},
		"memo":					 			{width:false,bordercolor:false,top:false,right:false,bottom:false,left:false,label:true,border:true,class:false},
		"bank_image": 			 			{lineheight:false,fontsize:false,width:false,top:false,right:false,bottom:false,left:false,enabled:true},
		"bank_name": 			 			{textalign:false,width:false,top:false,right:false,bottom:false,left:false},
		"bank_address": 		 			{grouping:false,textalign:false,width:false,top:false,right:false,bottom:false,left:false},
		"bank_address_2": 		 			{grouping:false,textalign:false,width:false,top:false,right:false,bottom:false,left:false},
		"bank_address_3": 		 			{grouping:false,textalign:false,width:false,top:false,right:false,bottom:false,left:false},
		"bank": 				 			{width:false,top:false,right:false,bottom:false,left:false},
		"signature": 			 			{image:{top:false,right:false,bottom:false,left:false},
											 info:{top:false,right:false,bottom:false,left:false},
											 desc:{top:false,right:false,bottom:false,left:false},
											 width:false,container_width:false,top:false,right:false,bottom:false,left:false,label:true,border:true},
		"micr": 				 			{width:false,top:false,right:false,bottom:false,left:false,background:false},
		"credit": 				 			{width:false,top:false,right:false,bottom:false,left:false},
		"mp": 					 			{width:false,top:false,right:false,bottom:false,left:false}
	};
	public design = this.design_default;
	datepipe: DatePipe = new DatePipe(this.sql.Locale);
	currencypipe: CurrencyPipe = new CurrencyPipe(this.sql.Locale);
	MediaType 		    = this.sql.MediaType;
	cheque 			    = new NewCheque();
	designobjects 	    = new NewDesignObjects();
	showingRender 	    = true;
	emailer_available   = false;
	email_message:  	string;
	default_design:  	string;
	filename:  			string;
	bank_sig: 			string;
	in_design: 			boolean;
	off: 				boolean;
	currencies: 		any;
	_print_held 	 	= 0;
	platform_width 	 	= 0;
	cheque_size:		any;
	orientation:		any;
	settings 		 	= {
		company_title: this.sql.get_setting('company_title'),
		company_description: this.sql.get_setting('company_description')
	}
	document_settings={
		author: this.sql.Title+' v.'+this.sql.version+' d:'+this.sql.date_man,
		title: this.sql.word.Generated_Cheque,
		subject: this.sql.word.PDF_Subject,
		keywords: this.sql.word.PDF_Keywords
	}
	generate(print_now=false)  { 
		if(this.off)return;
		if(!this.cheque){this.sql.err('No cheque data provided. PDF exit.',this.cheque);return;}
		if(this.sql.verbose)console.log('Generate Cheque',this.cheque);
		if(this.cheque.employees)this.select_employee(this.cheque.employees);
		if(this.cheque.banks)this.select_bank(this.cheque.banks);
		if(this.cheque.address_id)this.select_address(this.cheque.address_id);
		if(this.cheque.payees)this.select_payee(this.cheque.payees);
		if(!this.cheque.design_pattern)this.cheque.design_pattern=this.default_design;
		this.get_written_amount();
		if(this.currencies[this.cheque.currency]&&!this.currencies[this.cheque.currency].fiat){
			if((!this.cheque.key && !this.cheque.data) || this.cheque.currency != this.cheque.key_currency){
				this.coin.generate(this.currencies[this.cheque.currency].unit,(address)=>{
					if(address) {
						if(!address.public && !address.private) {
							this.cheque.key=null;
							this.cheque.data=null;
							this.sql.set('Cheques','cheque_id',this.cheque.cheque_id,'key',null);
							this.sql.set('Cheques','cheque_id',this.cheque.cheque_id,'data',null);
						}
						if(address.public){
							this.cheque.key = address.public;
							this.sql.set('Cheques','cheque_id',this.cheque.cheque_id,'key',this.cheque.key);
						}
						if(address.private) {
							this.cheque.data = address.private;
							this.sql.set('Cheques','cheque_id',this.cheque.cheque_id,'data',this.cheque.data);
						}
						this.sql.set('Cheques','cheque_id',this.cheque.cheque_id,'key_currency',this.cheque.currency);
					}
					this.generateDoc(print_now);
				});
			} else {
				this.generateDoc(print_now);
			}
		} else {
			this.generateDoc(print_now);
		}
	}
	generateDoc(print_now=false) {
		if(this.off)return;
		this.select_company_settings((c)=>{
			this.get_signature((cs)=>{
				this.template 							= this.cheque.design_pattern;
				this.MediaType 							= this.cheque.media_type ? this.cheque.media_type : this.sql.MediaType;
				this.filename 							= 'Cheque'+(this.cheque_id?'_'+this.cheque_id:'')+(this.cheque.date?'_'+this.cheque.date:'');
				// this.document_settings.title 		= this.cheque.name ? this.cheque.name : 'Generated Check #'+this.cheque_id;
				this.cheque.bank_image 					= this.cheque.bank_image  		  ? this.cheque.bank_image : null;
				this.cheque.number  					= (!this.cheque.number)  		  ? this.cheque_id : this.cheque.number;
				this.cheque.fiat  						= this.cheque.currency		      ? Currencies[this.cheque.currency].fiat : true;
				this.cheque.currency  					= this.cheque.currency		      ? this.cheque.currency 		  : this.sql.Currency;
				this.cheque.currency_name  				= this.cheque.currency		      ? Currencies[this.cheque.currency].name : this.sql.CurrencyWords;
				this.cheque.currency_symbol  			= this.cheque.currency		      ? Currencies[this.cheque.currency].icon : this.sql.CurrencySymbol;
				this.cheque.cheque_number  				= this.cheque.cheque_number  	  ? this.cheque.cheque_number 	  : '';
				this.cheque.bank_account_holder  		= this.cheque.bank_account_holder ? this.cheque.bank_account_holder : this.settings.company_title;
				this.cheque.transit_number  			= this.cheque.transit_number 	  ? this.sql.unencode(this.cheque.transit_number) : '';
				this.cheque.institution_number 			= this.cheque.institution_number  ? this.sql.unencode(this.cheque.institution_number) : '';
				this.cheque.designation_number 			= this.cheque.designation_number  ? this.sql.unencode(this.cheque.designation_number) : '';
				this.cheque.account_number 				= this.cheque.account_number 	  ? this.sql.unencode(this.cheque.account_number) : '';
				this.cheque.bank_address 				= this.cheque.bank_address  	  ? this.cheque.bank_address 	  : '';
				this.cheque.bank_address_2 				= this.cheque.bank_address_2  	  ? this.cheque.bank_address_2 	  : '';
				this.cheque.fob  						= this.sql.word.CHEQUE_PRINT_AMOUNT_INDEX+' '+(this.cheque.currency?this.cheque.currency:this.sql.CurrencyWords).toLocaleUpperCase(this.sql.Locale);
				this.cheque.signature_fill_color 		= this.cheque.signature_fill_color ? this.cheque.signature_fill_color : '#fff';
				this.cheque.number_fill_color  			= this.cheque.number_fill_color    ? this.cheque.number_fill_color    : '#fff';
				this.cheque.cheque_logo_image  			= this.cheque.cheque_logo_image    ? this.cheque.cheque_logo_image    : null;
				this.cheque.identifier 					= '';
				if(this.cheque.number)
					this.cheque.identifier ='C'+this.sql.zeroPad(Number(this.cheque.number)+Number(this.sql.zeropad_number), 3)+'C ';
				if(this.cheque.transit_number)
					this.cheque.identifier+='A'+this.cheque.transit_number;
				if(this.cheque.institution_number)
					this.cheque.identifier+='D'+this.cheque.institution_number;
				if(this.cheque.designation_number)
					this.cheque.identifier+='A'+this.cheque.designation_number;
				if(this.cheque.account_number)
					this.cheque.identifier+='D'+this.cheque.account_number.substr(0, this.cheque.account_number.length-1);
				if(this.cheque.account_number[this.cheque.account_number.length-1])
					this.cheque.identifier+='D'+this.cheque.account_number[this.cheque.account_number.length-1]+'C';
				// this.cheque.identifier 					= 'C000C A000D00000A000D00000DC'; // CLASSIC MICR NUMBERING
				this.get_amount_lines(()=>{
					this.printing='refresh'; this.destroying=false;
					if(this.sql.verbose)console.log('Generating', this.cheque.address,this.cheque.address_2,this.cheque);
					setTimeout(()=>{this.printing='';},200);
				});
			}); 
		});
	}
	get_written_amount(){
		if(this.cheque.amount) {
			var c = this.cheque.currency ? this.cheque.currency : environment.places[this.sql.Locale].currency;
			var NO_DOLLARS 				= this.sql.word.NO_DOLLARS;
			var ONE_DOLLAR 				= this.sql.word.ONE_DOLLAR;
			var DOLLARS 				= this.sql.word.DOLLARS;
			var AND 					= this.sql.word.AND;
			var AND_NO_CENTS 			= this.sql.word.AND_NO_CENTS;
			var DOLLARS_AND_ONE_CENT 	= this.sql.word.DOLLARS_AND_ONE_CENT;
			var CENTS 					= this.sql.word.CENTS;
			var ex=this.cheque.amount.split('.');
			var a=Number(ex[0]);var b=ex[1];
			if (Currencies[c] && Currencies[c]['unit'] == "Dollar") {
				if(a<1) this.cheque.written_amount        = NO_DOLLARS;
				if(a==1)this.cheque.written_amount        = ONE_DOLLAR;
				if(a>1) this.cheque.written_amount        = converter.toWords(a).toUpperCase();
				if(a>1) this.cheque.written_amount       += DOLLARS;
				if(a&&b)this.cheque.written_amount       += AND;
				if(b<1) this.cheque.written_amount       += AND_NO_CENTS;
				if(b==1)this.cheque.written_amount       += DOLLARS_AND_ONE_CENT;
				if(b>1) this.cheque.written_amount       += converter.toWords(b).toUpperCase();
				if(b>1 && a>1)this.cheque.written_amount += CENTS;
			} else {
				switch(environment.places[environment.locale].language_name) {
					case "English":
						var n = new NumberToWord(english);
					break;
					case "Kannada":
						var n = new NumberToWord(kananda);
					break;
					case "Telugu":
						var n = new NumberToWord(telagu);
					break;
					case "Hindi":
						var n = new NumberToWord(hindi);
					break;
					case "Marathi":
						var n = new NumberToWord(marathi);
					break;
				}
				var unit = Currencies[c]['unit'];
				var units = Currencies[c]['units'];
				var subunit = Currencies[c]['subunit'];
				var subunits = Currencies[c]['subunits'];
				var NO_ = this.sql.word.ZERO_;//NO_;
				var ONE_ = this.sql.word.ONE_;
				var AND_NO_ = this.sql.word.AND_NO_;
				var _AND_ONE_ = this.sql.word._AND_ONE_;
				if(a<1) this.cheque.written_amount        	  = NO_+unit;
				if(a==1)this.cheque.written_amount        	  = ONE_+unit;
				if(a>1) this.cheque.written_amount        	  = n.getWord(a, "MillionAndBillion", "Currency").toUpperCase();
				if(a>1) this.cheque.written_amount       	 += units;
				if(a&&b)this.cheque.written_amount       	 += AND;
				if(b<1) this.cheque.written_amount       	 += AND_NO_+subunits;
				if(b==1)this.cheque.written_amount       	 += units+_AND_ONE_+subunit;
				if(c=='BTC'){
					if(b>1) this.cheque.written_amount   	 += AND+' '+n.getWord(Number('0.'+b)*100000000, "MillionAndBillion", "Currency").toUpperCase() +' '+Currencies[c]['micounits'].toUpperCase();
				} else {
					if(b>1) this.cheque.written_amount   	 += n.getWord(b, "MillionAndBillion", "Currency").toUpperCase();
					if(b>1 && a>1)this.cheque.written_amount += subunits;
				}
			}
		}
	}
	get_amount_lines(cb?) {
		if(this.cheque_id){
			this.lines = [];
			this.sql.get('ChequeEntry WHERE cheque_id = '+this.cheque_id,(b)=>{
				if(b)this.lines=b;
				if(cb)cb(this.lines);
			});
		}
	}
	async select_design() {
		this.sql.hapticsImpactLight();
		if(this.in_design)return;
		this.in_design=true;
		if(this.sql.designer){
			this.router.navigate([
				'folder', 
				'ChequeDesigner',
				this.cheque.cheque_id
			]); 
			this.in_design=false;
		}else{
			const DesignModal = await this.modalController.create({
				component:SelectChequeDesignsPage, 
				componentProps:{
					cheque_id:this.cheque.cheque_id,
					design_pattern:this.cheque.design_pattern,
					docugard:this.docugard
				}
			});
			await DesignModal.present();
			let a = await DesignModal.onDidDismiss();
			if(a.data){
				this.sql.hapticsImpactLight();
				this.showingRender=false;
				var design = a.data;
				if(design){
					var d ={
						cheque_id:this.cheque.cheque_id,
						design_id:design.design_id,
						design_pattern:design.design_pattern,
						design_background_colour:design.background_colour
					};
					this.rotate = a.data.rotate;
					// this.monochrome = a.data.monochrome;
					this.update_design(a.data);
					this.sql.updateObj("Cheques",d);
					this.showingRender=true;
				}
			}
			this.in_design=false;
		}
	}
	update_design(design) {
		if(!design) {console.error('Error loading cheque design in cheque generator.');return;}
		console.log('Update Design called:', design);
		this.template  						= design&&design.design_pattern;
		this.cheque.design_pattern  		= design&&design.design_pattern;
		this.borders  						= design&&design.borders?true:false;
		this.monochrome  					= design&&design.monochrome?true:false;
		if(design.name)this.paper  			= design&&design.paper;
		if(design.name)this.page_size 		= design&&design.name;
		if(design.width)this.page_width 	= design&&design.width;
		if(design.height)this.page_height 	= design&&design.height;
		this.voidcheck  					= design&&design.voidcheck?true:false;
		this.background_colour  			= design&&design.background_colour;
		this.doc_color  					= design&&design.doc_color;
		this.docugard  	 					= design&&design.docugard;
		this.design['docugard']  	 		= design&&design.docugard;
		this.design['doc_color']  	 		= design&&design.doc_color;
		this.document_font  				= design&&design.font;
		this.design['font']  				= design&&design.font;
		this.fontsize  						= design&&design.fontsize;
		this.bold  							= design&&design.bold;
		this.italic  						= design&&design.italic;
		this.fold_line  					= design&&design.fold_line?true:false;
		this.cheque_text_color  			= design&&design.cheque_text_color;
		this.background_position  			= design&&design.background_position;
		this.cheque_background_image_repeat = design&&design.cheque_background_image_repeat;
		this.cheque_background_image  		= design&&design.cheque_background_image;
		this.cheque_logo_image  			= design&&design.cheque_logo_image;
		this.cheque_header_image  			= design&&design.cheque_header_image;
		this.cheque_bank_image  			= design&&design.cheque_bank_image;
		this.secret  						= design&&design.secret;
		this.micr_top 						= design&&design.micr_top;
		this.micr_right 					= design&&design.micr_right;
		this.micr_bottom 					= design&&design.micr_bottom;
		this.micr_left 						= design&&design.micr_left;
		this.page_top 						= design&&design.page_top;
		this.page_right 					= design&&design.page_right;
		this.page_bottom 					= design&&design.page_bottom;
		this.page_left 						= design&&design.page_left;
		this.table_top 						= design&&design.table_top;
		this.table_right 					= design&&design.table_right;
		this.table_bottom 					= design&&design.table_bottom;
		this.table_left 					= design&&design.table_left;
		this.address_top 					= design&&design.address_top;
		this.address_right 					= design&&design.address_right;
		this.address_bottom 				= design&&design.address_bottom;
		this.address_left 					= design&&design.address_left;
		// this.design['top']  				= design&&design.top;
		// this.design['bottom']  			= design&&design.bottom;
		this.design['show_ch/eque_number']  = design&&design.show_cheque_number?true:false;
		this.design['show_cheque_date']   	= design&&design.show_cheque_date?true:false;
		this.design['show_micr']   			= design&&design.show_micr?true:false;
		// this.design['print_scale']   	= design&&design.print_scale?design.print_scale:false;
		this.print_scale   					= design&&design.print_scale?design.print_scale:false;
	}
	get_design_sql(pattern,cb) {
		// this.design = [];
		this.cheque.design = [];
		this.sql.view('DesignObjects','design_pattern',pattern,(e)=>{
			if(e){
				var f=Object.entries(e[0]);var p=new NewDesignObjects();
				for(var t of f)p[t[0]]=t[1];
				// this.design = p;
				this.cheque.design = p;
				if(cb)cb(p);
				return p;
			} else {
				if(cb)cb(false);
			}
		});
	}
	design_background(val) {
		this.background_colour=val;
		this.design['background']=val;
	}
	design_font(val) {
		this.document_font =val;
		if(this.design)this.design['font']=val;
	}
	cheque_html(html?) {
		if(!html) return;
		this.cheque_html_obj.emit(html);
		if(this.sql.more_verbose)console.log('HTML Exported',html);
	}
	exported_html(html?) {
		if(!html) return;
		this.html.emit(html);
		if(this.sql.more_verbose)console.log('HTML Exported',html);
	}
	exported_image(event?) {
		this.cheque.image = event;
		this.image.emit(event);
		if(!event) return;
		if(this.sql.more_verbose)console.log('Image Exported',event);
	}
	end() {this.destroying=true;this.sql.hapticsImpactLight();this.router.navigate(['folder','Cheques']);}
	designer(id=this.cheque.cheque_id) {this.destroying=true;this.sql.hapticsImpactLight();this.router.navigate(['folder','Cheque','Designer',id]);}
	edit(id=this.cheque.cheque_id) {this.destroying=true;this.sql.hapticsImpactLight();this.router.navigate(['folder','Cheque',id]);}
	printes(c,i?) {this.sql.hapticsImpactLight();this.router.navigate(['folder','Print','Cheque',c.cheque_id,'Print']);}
	print() {
		this.sql.double_check_cheque_requirements((_p)=>{
			if(_p){
				this.printing = 'canvas';
				this.sql.hapticsImpactHeavy();
				setTimeout(()=>{this.printing='';},200);
			}
		},this.cheque,this.starting_id);
	}
	print_now() {
		this.sql.double_check_cheque_requirements((_p)=>{
			if(_p){
				this.printing = 'now';
				setTimeout(()=>{this.printing='';},200);
			}
		},this.cheque,this.starting_id);
	}
	print_img() {
		this.sql.double_check_cheque_requirements((_p)=>{
			if(_p){
				this.filesave_image((heque_image)=>{
					if(this.sql.more_verbose)console.log('image_path',this.cheque.image_path);
					this.sql.Print(this.cheque.image_path);
					this.sql.hapticsImpactLight();
				});
			}
		},this.cheque,this.starting_id);
	}
	load_design(design,cb?) {
		this.design_id=design.design_id;
		this.cheque.design=design.design_name;
		this.cheque.design_id=design.design_id;
		this.cheque.design_pattern=design.design_pattern;
		if(design.ImageMICR)this.ImageMICR = design.ImageMICR=='0'?false:true;
		if(design.RenderMICR)this.RenderMICR = design.RenderMICR=='0'?false:true;
		if(design.background_colour)this.background_colour = design.background_colour 
		if(design.bold)this.bold = design.bold=='0'?false:true;
		if(design.borders)this.borders = design.borders=='0'?false:true;
		if(design.font)this.document_font = design.font 
		if(design.fontsize)this.fontsize = design.fontsize 
		if(design.fold_line)this.fold_line = design.fold_line=='0'?false:true;
		if(design.gradients)this.gradients = design.gradients=='0'?false:true;
		// if(design.color)this.doc_color = design.color&&design.color!=''?design.color:false;
		if(design.background_colour)this.doc_color = design.background_colour&&design.background_colour!=''?design.background_colour:false;
		if(design.doc_color)this.doc_color = design.doc_color&&design.doc_color!=''?design.doc_color:false;
		if(design.cheque_text_color)this.cheque_text_color = design.cheque_text_color&&design.cheque_text_color!=''?design.cheque_text_color:false;
		if(design.background_position)this.background_position = design.background_position;
		if(design.cheque_background_image_repeat)this.cheque_background_image_repeat = design.cheque_background_image_repeat;
		if(design.cheque_background_image)this.cheque_background_image = design.cheque_background_image;
		if(design.cheque_logo_image)this.cheque_logo_image = design.cheque_logo_image;
		if(design.cheque_header_image)this.cheque_header_image = design.cheque_header_image;
		if(design.cheque_bank_image)this.cheque_bank_image = design.cheque_bank_image;
		if(design.italic)this.italic = design.italic=='0'?false:true;
		if(design.secret)this.secret = design.secret==''?'':design.secret;
		if(design.layers)this.layers = design.layers=='0'?false:true;
		if(design.micr)this.micr = design.micr=='0'?false:true;
		if(design.micr_top)this.micr_top = design.micr_top&&design.micr_top!='auto'&&design.micr_top!='0'?design.micr_top:false;
		if(design.micr_right)this.micr_right = design.micr_right&&design.micr_right!='auto'&&design.micr_right!='0'?design.micr_right:false;
		if(design.micr_bottom)this.micr_bottom = design.micr_bottom&&design.micr_bottom!='auto'&&design.micr_bottom!='0'?design.micr_bottom:false;
		if(design.micr_left)this.micr_left = design.micr_left&&design.micr_left!='auto'&&design.micr_left!='0'?design.micr_left:false;
		if(design.page_top)this.page_top = design.page_top&&design.page_top!='auto'&&design.page_top!='0'?design.page_top:false;
		if(design.page_right)this.page_right = design.page_right&&design.page_right!='auto'&&design.page_right!='0'?design.page_right:false;
		if(design.page_bottom)this.page_bottom = design.page_bottom&&design.page_bottom!='auto'&&design.page_bottom!='0'?design.page_bottom:false;
		if(design.page_left)this.page_left = design.page_left&&design.page_left!='auto'&&design.page_left!='0'?design.page_left:false;
		if(design.table_top)this.table_top = design.table_top&&design.table_top!='auto'&&design.table_top!='0'?design.table_top:false;
		if(design.table_right)this.table_right = design.table_right&&design.table_right!='auto'&&design.table_right!='0'?design.table_right:false;
		if(design.table_bottom)this.table_bottom = design.table_bottom&&design.table_bottom!='auto'&&design.table_bottom!='0'?design.table_bottom:false;
		if(design.table_left)this.table_left = design.table_left&&design.table_left!='auto'&&design.table_left!='0'?design.table_left:false;
		if(design.address_top)this.address_top = design.address_top&&design.address_top!='auto'&&design.address_top!='0'?design.address_top:false;
		if(design.address_right)this.address_right = design.address_right&&design.address_right!='auto'&&design.address_right!='0'?design.address_right:false;
		if(design.address_bottom)this.address_bottom = design.address_bottom&&design.address_bottom!='auto'&&design.address_bottom!='0'?design.address_bottom:false;
		if(design.address_left)this.address_left = design.address_left&&design.address_left!='auto'&&design.address_left!='0'?design.address_left:false;
		if(design.monochrome)this.monochrome = design.monochrome=='0'?false:true;
		if(design.numbers)this.numbers = design.numbers=='0'?false:true;
		if(design.page_height)this.page_height = design.page_height 
		if(design.page_size)this.page_size = design.page_size 
		if(design.page_width)this.page_width = design.page_width 
		if(design.paper)this.paper = design.paper 
		if(design.print_scale)this.print_scale = design.print_scale 
		if(design.rotate)this.rotate = design.rotate 
		if(design.scale)this.scale = design.scale 
		if(design.show_cheque_date)this.date = design.show_cheque_date=='0'?false:true;
		if(design.show_cheque_number)this.numbers = design.show_cheque_number=='0'?false:true;
		if(design.template)this.template = design.template 
		if(design.voidcheck)this.voidcheck = design.voidcheck=='0'?false:true 
		if(this.document_font)this.design_font(this.document_font);
		if(cb)cb();
	}
	async filesave_image(b?) {
		this.sql.save_cheque_image(this.cheque.image,this.sql.word.Cheque+this.cheque.cheque_id,(filepath)=>{ 
			this.cheque.image_path = filepath;
			this.sql.set('Cheques','cheque_id',this.cheque.cheque_id,'image_path',this.cheque.image_path,(cid)=>{
				if (cid) {
					if(this.sql.verbose){
						if(this.sql.verbose)console.log('Saved Cheque',this.cheque.cheque_id,'Image Path',this.cheque.image_path);
						if(this.sql.verbose)console.log('Completed Filesave Image:', filepath);	
					}
					if(b)b(filepath);
					return filepath;
				} else {
					if(b)b(false);
					return false;
				}
			});
			
		});
	}
	async send_in_email(){
		if(!this.cheque.image_path) await this.filesave_image();
		if(this.cheque.name) this.email_message = '<h1>'+this.cheque.name+'</h1>';
		this.email_message = '<h4>'+this.sql.word.Cheque+'</h4>';
		this.email_message = this.email_message + '<p>';
		if(this.cheque.amount) this.email_message = '<b>'+this.cheque.currency_symbol+this.cheque.amount+'</b> '+this.cheque.currency+'<br />';
		if(this.cheque.printed_memo) 	this.email_message = this.email_message + 'Memo: '+this.cheque.printed_memo+'<br />';
		if(this.cheque.date) 			this.email_message = this.email_message + 'Date: '+this.cheque.date+'<br />';
		if(this.cheque.company_name) 	this.email_message = this.email_message + '<br />'+this.cheque.company_name+'<br />';
		this.email_message = this.email_message + '</p>';
		if(this.sql.show_printed_credit) this.email_message = this.email_message + '<br /><br /><a href="'+environment.com+'" target="_blank">'+this.sql.word.CHEQUE_PRINT_CREDIT+'</a>';
		const email:any  = {
			attachments: [this.cheque.image_path],
			subject: this.sql.word.Cheque+' #'+this.cheque.number?this.cheque.number:this.cheque.cheque_id,
			body: this.email_message,
			isHtml: this.sql.emailHTML,
			app: this.sql.emailApp
		}
		if(this.sql.verbose)console.log('Emailer Viewer', email);
		this.emailer.open(email);
		this.sql.hapticsImpactLight();
	}
	mail() {
    	this.router.navigate(['folder','Print','Address',this.cheque.addresses]);
  	}
	async popup_mail() {
		this.sql.hapticsImpactLight();
		const m = await this.modalController.create({component: PrintAddressComponent, componentProps: {
			address_id: this.cheque.addresses,
			modal:true
		}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {
			this.sql.hapticsImpactLight();
		}
	}
	async purchase() {
		setTimeout(() => { this.sql.hapticsImpactHeavy(); },440);
		const m = await this.modalController.create({component: PurchaseComponent, componentProps: {}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {
			this.sql.hapticsImpactLight();
		}
	}
	get_design(b?) {
		if(this.cheque.design_id||this.design_id){
			if(this.design_id)this.cheque.design_id=Number(this.design_id);
			this.sql.view('Designs','design_id',this.cheque.design_id,(d)=>{
				if(!d)return;
				d=d[0]
				if(d){
					if(d.design_id>0) {
						this.load_design(d,b);
					} else {
						this.get_default_design((d) =>{ this.load_design(d,b); });
					}
				}
			});
		} else {
		    this.get_default_design((d) =>{ this.load_design(d,b); });
		}
	}
	get_default_design(b?) {
		this.sql.get('Designs',(e)=>{
			for(let d of e)if(d.default_design=='true'){
				this.default_design=d.design_pattern;
				if(b)b(d);
			}
		});
	}
	get_details() {
		if(!this.cheque_id) {
			var n =this.activatedRoute.snapshot.paramMap.get('id');
			var d =this.activatedRoute.snapshot.paramMap.get('did');	
		}
		if(!this.design_id)this.design_id=(d)?d:false;
		if(n)this.cheque_id=n;
		if(this.cheque_id){
			this.sql.view('Cheques','cheque_id',this.cheque_id,(e)=>{
				if(e&&e[0]){
					var f=Object.entries(e[0]);var n=new NewCheque();
					for(let t of f)n[t[0]]=t[1];
					this.cheque = n;
					this.design_id=this.cheque.design_id;
					if(this.sql.more_verbose)console.log(this.sql.word.Cheque,this.cheque);
					this.get_design(()=>{this.generate()});
					///this.generate();
				}
			});
		}
	}
	select_employee(employee) {
		if (!employee||employee==-1) { } else {
			this.sql.fetchOne("Employees WHERE employee_id = "+employee,(e)=>{
				this.cheque.employees = e.employee_id;
				this.cheque.name = e.full_name;
				this.select_address(e.address_id);
			});
		}
	}
	select_bank(bank) {
		if (!bank||bank==-1) { } else {
			this.sql.fetchOne("BankAccounts WHERE bank_id = "+bank,(b)=>{
				if(this.sql.more_verbose)console.log("Bank Accounts", b);
				this.cheque.banks = b.bank_id;
				this.cheque.bank_image = b.image;
				this.cheque.bank_account_holder = b.account_holder;
				this.cheque.bank_name = b.name;
				this.cheque.bank_address = b.address;
				this.cheque.bank_address_2 = b.address_2;
				this.cheque.bank_city =  b.city;
				this.cheque.bank_province = b.province;
				this.cheque.bank_country = b.country;
				this.cheque.bank_postal = b.postal_code;
				this.cheque.cheque_number = b.cheque_number;
				this.cheque.transit_number = b.transit_number;
				this.cheque.institution_number = b.institution_number;
				this.cheque.designation_number = b.designation_number;
				this.cheque.account_number = b.account_number;
				this.cheque.number = this.sql.zeroPad(Number(b.cheque_starting_id)+Number((this.cheque.number?this.cheque.number:this.cheque_id)-1), 3);
				this.cheque.bank_address_3 = (this.cheque.bank_city?this.cheque.bank_city+',':'')
					 + (this.cheque.bank_country?this.cheque.bank_country+' ':'')
					 + (this.cheque.bank_postal?this.cheque.bank_postal:'');
				this.bank_sig = b.signature;
				this.starting_id = b.cheque_starting_id?b.cheque_starting_id:-1;
			});
		}
	}
	get_signature(cb?) {
		if(this.cheque.signature>0){
			this.sql.fetchOne("Signatures WHERE signature_id = "+this.cheque.signature,(s)=>{
				if (this.sql.more_verbose)console.log('Signature Image',s);
				this.cheque.signature_name = s.name;
				this.cheque.signature_image = s.image;
				this.cheque.signature_description = s.description;
				if(cb)cb(1);
			});
		} else if (this.bank_sig) {
			this.sql.fetchOne("Signatures WHERE signature_id = "+this.bank_sig,(s)=>{
				if (this.sql.more_verbose)console.log('Signature Image',s);
				this.cheque.signature_name = s.name;
				this.cheque.signature_image = s.image;
				this.cheque.signature_description = s.description;
				if(cb)cb(1);
			});
		} else {
			this.cheque.signature_name = '';
			this.cheque.signature_image = '';
			this.cheque.signature_description = '';
			if(cb)cb(1);
		}
	}
	select_address(address) {
		if (!address||address==-1) {} else {
			this.sql.fetchOne("Addresses WHERE address_id = "+address,(a)=>{
				if(a){
					if(this.sql.more_verbose)console.log('Address ',a);
					if(a.name)this.cheque.address_name = a.name;
					if(a.address)this.cheque.address = a.address;
					if(a.address_2)this.cheque.address_2 = a.address_2;
					if(a.city)this.cheque.city =  a.city;
					if(a.city)this.cheque.province  = a.city;
					if(a.country)this.cheque.country = a.country;
					if(a.postal)this.cheque.postal = a.postal;
				}
			});
		}
	}
	select_payee(payee) {
		if (!payee||payee==-1) {} else {
			this.sql.fetchOne("Payees WHERE payee_id = "+payee,(p)=>{
				if(this.sql.more_verbose)console.log('Payee ',p);
			});
		} 
	}
	select_company_settings(c?) {
		this.sql.get_setting('company_address_id',(id)=>{
			if (!id.option) {if(c)c();} else {
				this.cheque.company_address_id = id.option;
				this.sql.fetchOne("Addresses WHERE address_id = "+id.option,(address)=>{
					this.cheque.company_name = address.name.toUpperCase();
					this.cheque.company_city = address.city;
					this.cheque.company_address = address.address;
					this.cheque.company_address_2 = address.address_2;
					this.cheque.company_country = address.country;
					this.cheque.company_province = address.province;
					this.cheque.company_postal_code = address.postal_code;
					this.sql.get_setting('company_title',(name)=>{
						if(name.option)this.cheque.company_name = name.option;
						if(c)c();
					});
				});
			}
		});
	}
	open() { 
	  	if(!this.print_language)this.sql.get_setting('default_print_language',(s)=>{
	      if(!s){
	        this.print_language=this.sql.Language;
	      } else {
		    this.print_language=s.option?s.option:this.sql.Language;
	      }
	    });
		if(this.off)return;
		this.design=this.design_default;
		this.get_details();
	}
	zero_margins() {
		this.design["bottom"] = '0mm';
		this.design["top"] = '0mm';
	}
  	zoom() {
  		if(this.is_tablet)return;
    	if(this.scale==0.45) {
      		this.scale=1;
    	} else {
      		this.scale=0.45;
    	}
        this.changeDetector.detectChanges();
  	}	
  	ngAfterViewInit() {
		var n =this.activatedRoute.snapshot.paramMap.get('id');
		this.design=this.design_default;
  		this.closing=false;
  		this.off=false;
		if( this.platform.is('capacitor') ) {
			this.adj_zoom();
			this.emailer.isAvailable().then(this.sql.emailApp,(available: boolean) => { 
				this.emailer_available=available; 
			});
		} else if (this.sql.model=='web'){
			this.open();
		}
  	}
  	adj_zoom() {
  		if(this.is_tablet)return;
  		if(this.zoom_adj)return;
		// if(this.platform.is('ios')){
			this.zoom();
			setTimeout(()=>{
				this.zoom_adj=true;
				this.center_view=true;
				setTimeout(()=>{this.center_view=false;},500);
			},3);
		// }
  	}
	ngOnChanges(propName) {
    	if(propName.template){
    		this.design=this.design_default;
    		this.off=false;
    		this.adjust_zoom();
    	}
		if(propName.design&&!this.closing){
			// this.get_design();
			this.adjust_zoom();
		}
		if(propName.document_font)this.design_font(this.document_font);
	}
	ngOnDestroy() {
		this.destroying=true;
		this.closing=true;
		if(!this.designer&&!this.fullscreen)this.presentLoading();
		this.off=true;
		this.cheque = new NewCheque();
		this.design=null;
		this.design_id=false;
		this.template='';
		this.document_settings=null;
		this.background_colour='';
		this.cheque_background_image='';
		this.cheque_logo_image='';
		this.cheque_header_image='';
		this.cheque_bank_image='';
	}
	chequeLoaded(w) {
		this.cheque_size = w;
		this.adjust_zoom();
	}
	adjust_zoom() {
  		if(this.is_tablet)return;
		this.platform_width = this.platform.width();
		if(this.platform_width <= this.cheque_size) {
			this.scale=0.45;
		} else {
			this.scale=1;
		}
		this.center_view=true;
		setTimeout(()=>{this.center_view=false;},500);
        this.changeDetector.detectChanges();
	}
	async presentLoading() {
		// const loading = await this.loadingController.create({
		//   cssClass: 'designer-loading',
		//   message: this.sql.word.Loading,
		//   duration: 821
		// });
		// await loading.present();
		// const { role, data } = await loading.onDidDismiss();
	}
	delete_cheque() {
	    if(!this.cheque.cheque_id){
	      this.end();
	    } else if(confirm(this.sql.word.Cheque_Delete_Confirmation)) {
	      this.sql.delete('Cheques','cheque_id',this.cheque.cheque_id,()=>{
	        this.end();
	      });
	    }
	}
}