import { Component, AfterViewInit, Input } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { SQLService } from '../../../services/sql.service';
import { Router,NavigationEnd } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
})
export class ImagePreviewComponent implements AfterViewInit {
  constructor(
    public sql: SQLService,
    private navCtrl: ModalController,
    private sanitizer:DomSanitizer,
    private params: NavParams,
    private router: Router) {
    this.bin = params.get('trash');
    this.title = params.get('title');
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(params.get('src'));
  }
  @Input('trash') bin:string;
  @Input('title') title:string;
  @Input('src') src: SafeResourceUrl;
  ngAfterViewInit() {
  }
  trash() {
    this.sql.hapticsImpactHeavy();
    this.navCtrl.dismiss(1);
  }
  end() {
    this.sql.hapticsImpactLight();
    this.navCtrl.dismiss();
  }
}