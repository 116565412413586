import { SQLService } from '../../../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Router ,NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController, NavParams } from '@ionic/angular';
@Component({
  selector: 'app-select-fax',
  templateUrl: './select-fax.page.html',
  styleUrls: ['./select-fax.page.scss'],
})
export class SelectFaxPage implements OnInit {
 constructor(
    public sql: SQLService,
    private navCtrl: ModalController,
    private location: Location,
    private params: NavParams,
    private router: Router) {
    var s = params.get('selected');
    if(s)this.selected = s.split(',');
    this.multiple = params.get('select_multiple');
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_faxes();
    });
  }
  count = -1;
  faxes = [];
  selected = [];
  search: string;
  multiple: boolean;
  filter(e) { this.search=e.target.value; }
  get_faxes(event?) {
    this.sql.fetch('Faxes',(e)=>{
      this.faxes=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  add() {
    this.router.navigate(['folder','Fax','New']);
    this.navCtrl.dismiss();
  }
  select_none() {this.navCtrl.dismiss(-1);}
  select(fax) { 
    this.navCtrl.dismiss(fax);
  }
  off() {
    this.navCtrl.dismiss();
  }
  ngOnInit() {
    if(!this.sql.account_keys)this.off();
    this.get_faxes();
  }
}
