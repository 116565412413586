import { environment } from '../../environments/environment';
import { SQLService } from '../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { PopoverController} from '@ionic/angular';
import { IncomeMenuComponent } from './income-menu/income-menu.component';
@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.scss'],
})
export class IncomeComponent implements OnInit {
  search:string;
  incomes=[];
  count = -1;
  constructor(
    private router: Router,
    public popoverController: PopoverController,
    public sql: SQLService
  ) { 
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_incomes();
    });
  }
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:IncomeMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='enable')this.enable(item);
      if(data=='disable')this.disable(item);
      if(data=='delete')this.delete_income(item);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  filter(e) {
    this.search=e.target.value;
  }
  get_incomes(event?) {
    this.sql.fetch('Incomes',(e)=>{
      if(e)this.incomes=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  edit_income(a?,i?) {
    this.router.navigate(['folder','Income',a.income_id]);
    if(i)i.closeSlidingItems();
  }
  delete_income(a?,i?) {
    if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('Incomes','income_id',a.income_id);
      this.get_incomes();
    } else { if(i)i.closeSlidingItems(); }
  }
  enable(income,i?){
    this.sql.set('Incomes','income_id',income.income_id,'enabled',1,(res)=>{
      income.enabled = true;
      if(i)i.closeSlidingItems();
    });
  }
  disable(income,i?){
    this.sql.set('Incomes','income_id',income.income_id,'enabled',0,(res)=>{
      income.enabled = false;
      if(i)i.closeSlidingItems();
    });
  }
  add() {this.router.navigate(['folder','Income','New']);}
  end() {this.router.navigate(['folder','Dashboard']);}
  ngOnInit() {
    this.get_incomes();
  }
}
