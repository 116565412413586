export class NewDeposits {
	constructor(
		public deposit_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public title?: string,
		public description?: string,
		public amount?: string,
		public currency?: string,
		public balance?: string,
		public balance_available?: string,
		public total_amount?: string,
		public total_input?: string,
		public total_output?: string,
		public total_payments?: number,
		public enabled?: boolean,
		public last_used?: any,
		public modified?: any,
		public created?: any
	){}
}
export const Create_Deposits = '\
	CREATE TABLE IF NOT EXISTS "Deposits" (\
		deposit_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		title TEXT,\
		description TEXT,\
		amount TEXT,\
		currency TEXT,\
		balance TEXT,\
		balance_available TEXT,\
		total_amount TEXT,\
		total_input TEXT,\
		total_output TEXT,\
		total_payments INTEGER DEFAULT 0,\
		enabled INTEGER DEFAULT 0,\
		last_used TIMESTAMP,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';