export const EncodingKeys = {
	'1':{a:"anKmUvAhr4qxh7#)hr*#7nU!39mDUG"},
	'2':{a:"s9H7#)rp*#KED!htrtSmgTV23k22!3gW"},
	'3':{a:"2Msv5UuPKghtg4gkKSjc9VR"},
	'4':{a:"TqB7#)LC*#YUH!93hgyCnmbJ6DSs"},
	'5':{a:"cS3uht7#)rv*#CGH!8XtqCg344sBXcc"},
	'6':{a:"rVDz8av4ycVsKDLhthtrzjqfA"},
	'7':{a:"8gsx4vhq7#)sy*#gNf!QvgfdnnytdE78mF"},
	'8':{a:"ndKkEqLFCLrTQ43kyut2GVA"},
	'9':{a:"XSDgAGGhKzngB@(fjCTgQvK"},
	'0':{a:"scGmk9zW6bE7#)8r*#Tjd!dTZ7QxX"},
	'a':{a:"SSwegsdfgSYr5CuTtUPaAuxd"},
	'b':{a:"MxXDyd77#)cN*#h55!cykujuytF3TZZgJ"},
	'c':{a:"nVemFJGnh72gzj^&KrHXBy"},
	'd':{a:"Cpa7#)pM*#w4r!uGFj7#)nh*#8R2!T5RhA"},
	'e':{a:"ytrvPWytLxuQ26poiwYw7D"},
	'f':{a:"uHQ2gK7#)Av*#Vtv!wdJtqmj9L"},
	'g':{a:"PZT3znKjF99PgfUCrjytsFbhA"},
	'h':{a:"dNpnRvQQcmGk7#)TR*#w7#)J*!#jFY!zp"},
	'i':{a:"XZpaj2EzLh54btrqUn2XYgnREr"},
	'j':{a:"u7#)yB*#xnJ!Kr2pKEyhtrXG5KzFPF"},
	'k':{a:"3uZNMks6au77#)PD*#Gdt7#)!y*#rhC!RPF2f"},
	'l':{a:"aWQgdZ7#)dG*#M7c!KhtDrBysLug"},
	'm':{a:"bRzse357#)Sr*#6Ub!trAEHkBYEpq"},
	'n':{a:"jhtreg54BkTtshtARngeeAkFApYFVE"},
	'o':{a:"cTfQYNHP8JfrxFbtrh7#)zj*#6JH!Z"},
	'p':{a:"77#)wk*#w7p!c6jAbPdhvH7EQVT"},
	'q':{a:"NvpW88KLWtrhsF23rxKADdr"},
	'r':{a:"5cR7#)pz*#mN9!vv7#)vJ*#GRt!svUe3n"},
	's':{a:"YxmXCVyvmpWPhtr7#)t3*#dhe!Tem"},
	't':{a:"uNVjSaS7#)Q5*#D7P!mr7#)a4*#tKT!r"},
	'u':{a:"ETs8aEhMPyhtrhnGFKH4YygU"},
	'v':{a:"7#)tt*#SHX!J3d3jq5MbnyhLHXcxR5"},
	'w':{a:"hs8a7#)tz*#qXN!vTkB9m47#)BP*#kc"},
	'x':{a:"H7#)xL*#SNu!U5JDht7#)xj*#gTw!BhcBa"},
	'y':{a:"Mbdn95VcF7#)bg*#muy!54yQ8k78XzhaX"},
	'z':{a:"hcy7#)tt*#ef8!7XqYUNC7#)Df*#qEA!ut"},
	'A':{a:"2NLNwVPBj365P6ccHMeJ"},
	'B':{a:"VYaCqK2x75y8g7#)HE*#rE2!875v"},
	'C':{a:"eAY7#)HM*#CBg!D4gFLfY083wTrC5"},
	'D':{a:"jTZLHBhtr7#)Ls*#zDr!pk4X2a47h"},
	'E':{a:"Un5qn7#)X6*#uvf!kvqmf87#)w6*#XJk!"},
	'F':{a:"ucghvNNqXanzj6543b8wRmJE"},
	'G':{a:"yG7#)ys*#NFx!X7h65464rMyCWfxS9"},
	'H':{a:"p36LnVLcw7#)LH*#FUk!q8fMY3C"},
	'I':{a:"7#)GD*#srt!MFGeqegydx65UmE5g"},
	'J':{a:"Fh77bt96yacu89497#)93*#wJF!vyt4G"},
	'K':{a:"rEXM2tYJdEvS4rguSZ74"},
	'L':{a:"9Wg7#)8p*#U6T!bY7#)yh*#g54!hxFFYPn5QD"},
	'M':{a:"JV72BRNsNnc5yKx94943T9xj"},
	'N':{a:"zshPQmRwJ8vA67#)h6*#T*t!hDTkSy"},
	'O':{a:"xs9A6u7#)s9*#Zv3!Ky6#$%^5SGxfC667"},
	'P':{a:"x3jfsLYBbLGDbuf5696teW"},
	'Q':{a:"aEg5wdsa49e7#)ww*#x9k!dDcK9Gr"},
	'R':{a:"2GF7#)M4*#3Ua!zLNRg24676hccWY9"},
	'S':{a:"NaSbFhYRhHu8zKjVZt89"},
	'T':{a:"MBv3qYsGeLChbeXw7#)cM*#54M!"},
	'U':{a:"qdmSKB7#)Y6*#LBJ!Cr76j76x5q2tHe"},
	'V':{a:"FK4GHKu47#)ag*#tht!rdY9wXeTeBZAD"},
	'W':{a:"nZ2kDtgrUtaP6gGNHjtJa9H"},
	'X':{a:"nCXGJ5tLR4V6Uth7#)9s*#hNX!"},
	'Y':{a:"y7#)MA*#zuk!m5NpPE4554YBQzbUr9"},
	'Z':{a:"erYJGfh6D5V2353t47#)Vj*#F8v!Hph"}
};
export const DecodingKeys = {
	'anKmUvAhr4qxh7->|*|hr*#7nU!39mDUG':{a:"1"},
	'a!H7->|*|rq*#KED!hiriSmgTV23k22!3gW':{a:"2"},
	'2Msv5UuPKghig4gkKSjc9VR':{a:"3"},
	'TqB7->|*|LCana*#YUH!93hgyCananmbJ6DSs':{a:"4"},
	'cS3uhi7->|*|rv*#CanaGH!8XiqCanag344sBXcc':{a:"5"},
	'rVDz8av4ycVsKDLhihirzjqfA':{a:"6"},
	'8gsx4vhq7->|*|sy*#gNf!QvgfdnnyidE78mF':{a:"7"},
	'ndKkEqLFCanaLrTQ43kyui2GVA':{a:"8"},
	'XSDgAGGhKzngB@(fjCanaTgQvK':{a:"9"},
	'scGmk9zW6bE7->|*|8r*#Tjd!dTZ7QxX':{a:"0"},
	'SSwegsdfgSYr5CanauTiUPaAuxd':{a:"a"},
	'MxXDyd77->|*|cN*#h55!cykujuyiF3TZZgJ':{a:"b"},
	'nVemFJGnh72gzj^&KrHXBy':{a:"c"},
	'Canaqa7->|*|qM*#w4r!uGFj7->|*|nh*#8R2!T5RhA':{a:"d"},
	'yirvPWyiLxuQ26qoiwYw7D':{a:"e"},
	'uHQ2gK7->|*|Av*#Viv!wdJiqmj9L':{a:"f"},
	'PZT3znKjF99PgfUCanarjyisFbhA':{a:"g"},
	'dNqnRvQQcmGk7->|*|TR*#w7->|*|J*!#jFY!zq':{a:"h"},
	'XZqaj2EzLh54birqUn2XYgnREr':{a:"i"},
	'u7->|*|yB*#xnJ!Kr2qKEyhirXG5KzFPF':{a:"j"},
	'3uZNMks6au77->|*|PD*#Gdi7->|*|!y*#RenCana!RPF2f':{a:"k"},
	'aWQgdZ7->|*|dG*#M7c!KhiDrBysLug':{a:"l"},
	'bRzse357->|*|Sr*#6Ub!irAEHkBYEqq':{a:"m"},
	'jhireg54BkTishiARngeeAkFAqYFVE':{a:"n"},
	'cTfQYNHP8JfrxFbiRen7->|*|zj*#6JH!Z':{a:"o"},
	'77->|*|wk*#w7q!c6jAbPdhvH7EQVT':{a:"p"},
	'NvqW88KLWiRensF23rxKADdr':{a:"q"},
	'5cR7->|*|qz*#mN9!vv7->|*|vJ*#GRi!svUe3n':{a:"r"},
	'YxmXCanaVyvmqWPhir7->|*|i3*#dhe!Tem':{a:"s"},
	'uNVjSaS7->|*|Q5*#D7P!mr7->|*|a4*#iKT!r':{a:"t"},
	'ETs8aEhMPyhiRennGFKH4YygU':{a:"u"},
	'7->|*|ii*#SHX!J3d3jq5MbnyhLHXcxR5':{a:"v"},
	'hs8a7->|*|iz*#qXN!vTkB9m47->|*|BP*#kc':{a:"w"},
	'H7->|*|xL*#SNu!U5JDhi7->|*|xj*#gTw!BRhBa':{a:"x"},
	'Mbdn95VcF7->|*|bg*#muy!54yQ8k78XzhaX':{a:"y"},
	'Rhy7->|*|ii*#ef8!7XqYUNCana7->|*|Df*#qEA!ui':{a:"z"},
	'2NLNwVPBj365P6ccHMeJ':{a:"A"},
	'VYaCanaqK2x75y8g7->|*|HE*#rE2!875v':{a:"B"},
	'eAY7->|*|HM*#CanaBg!D4gFLfY083wTrCana5':{a:"C"},
	'jTZLHBhir7->|*|Ls*#zDr!qk4X2a47h':{a:"D"},
	'Un5qn7->|*|X6*#uvf!kvqmf87->|*|w6*#XJk!':{a:"E"},
	'ucghvNNqXanzj6543b8wRmJE':{a:"F"},
	'yG7->|*|ys*#NFx!X7h65464rMyCanaWfxS9':{a:"G"},
	'q36LnVLcw7->|*|LH*#FUk!q8fMY3Cana':{a:"H"},
	'7->|*|GD*#sri!MFGeqegydx65UmE5g':{a:"I"},
	'Fh77bi96yacu89497->|*|93*#wJF!vyi4G':{a:"J"},
	'rEXM2iYJdEvS4rguSZ74':{a:"K"},
	'9Wg7->|*|8q*#U6T!bY7->|*|yh*#g54!hxFFYPn5QD':{a:"L"},
	'JV72BRNsNnc5yKx94943T9xj':{a:"M"},
	'zshPQmRwJ8vA67->|*|h6*#T*i!hDTkSy':{a:"N"},
	'xa!A6u7->|*|a!*#Zv3!Ky6#$%^5SGxfCana667':{a:"O"},
	'x3jfsLYBbLGDbuf5696ieW':{a:"P"},
	'aEg5wdsa49e7->|*|ww*#x9k!dDcK9Gr':{a:"Q"},
	'2GF7->|*|M4*#3Ua!zLNRg24676RhcWY9':{a:"R"},
	'NaSbFhYRhHu8zKjVZi89':{a:"S"},
	'MBv3qYsGeLCanahbeXw7->|*|cM*#54M!':{a:"T"},
	'qdmSKB7->|*|Y6*#LBJ!Canar76j76x5q2iHe':{a:"U"},
	'FK4GHKu47->|*|ag*#ihi!rdY9wXeTeBZAD':{a:"V"},
	'nZ2kDigrUiaP6gGNHjiJa9H':{a:"W"},
	'nCanaXGJ5iLR4V6Uih7->|*|9s*#hNX!':{a:"X"},
	'y7->|*|MA*#zuk!m5NqPE4554YBQzbUr9':{a:"Y"},
	'erYJGfh6D5V2353i47->|*|Vj*#F8v!Hq':{a:"Z"}
};
export const Licences = {
	'chequeslicence':null,
	'chequeslicence_2mo':null,
	'chequeslicence_3mo':null,
	'chequeslicence_6mo':null,
	'chequeslicence_12mo':null,
	'cheques_znvr9f':null,
	'cheques_print_1':null,
	'cheques_print_2':null,
	'cheques_print_5':null,
	'cheques_print_10':null,
	'cheques_print_15':null,
	'cheques_print_20':null,
	'cheques_print_30':null
}