export class NewEmployee {
	constructor(
		public enabled?: boolean,
		public employee_id?: any,
		public address_id?: any,
		public salary_id?: any,
		public deduction_id?: any,
		public admin_id?: any,
		public tax_id?: any,
		public job_id?: any,
		public mod_id?: any,
		public username?: string,
		public password?: string,
		public name?: string,
		public first_name?: string,
		public last_name?: string,
		public full_name?: string,
		public position?: string,
		public birthday?: any,
		public email?: string,
		public phone?: string,
		public fax?: string,
		public skype?: string,
		public employed?: boolean,
		public jobs?: any,
		public hourly_rate?: number,
		public currency?: string,
		public cycles?: number,
		public total?: number,
		public deductions?: any,
		public schedule_ids?: any,
		public schedules?: any,
		public timesheets?: any,
		public first_cheque?: string,
		public yearly_totals?: string,
		public salary_ids?: any,
		public salaries?: any,
		public taxes?: any,
		public addresses?: any,
		public last_last_cheque?: string,
		public bank_number?: string,
		public transit_number?: string,
		public bank?: string,
		public description?: string,
		public email_updates?: boolean,
		public verified?: boolean,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Employees = '\
	CREATE TABLE IF NOT EXISTS "Employees" (\
		employee_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		tax_id INTEGER,\
		address_id INTEGER,\
		salary_id INTEGER,\
		job_id INTEGER,\
		deduction_id INTEGER,\
		username TEXT,\
		password TEXT,\
		name TEXT,\
		first_name TEXT,\
		last_name TEXT,\
		full_name TEXT,\
		position TEXT,\
		birthday TEXT,\
		email TEXT,\
		phone TEXT,\
		fax TEXT,\
		skype TEXT,\
		employed INTEGER DEFAULT 0,\
		enabled INTEGER DEFAULT 0,\
		hourly_rate VARCHAR(100),\
		currency VARCHAR(10),\
		cycles INTEGER DEFAULT 0,\
		total VARCHAR(1000),\
		jobs TEXT,\
		taxes TEXT,\
		salary_ids TEXT,\
		salaries TEXT,\
		deductions TEXT,\
		schedule_ids TEXT,\
		schedules TEXT,\
		addresses TEXT,\
		timesheets TEXT,\
		first_cheque TEXT,\
		yearly_totals TEXT,\
		last_last_cheque TEXT,\
		bank_number TEXT,\
		transit_number TEXT,\
		bank TEXT,\
		description TEXT,\
		email_updates INTEGER DEFAULT 0,\
		verified INTEGER DEFAULT 0,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';