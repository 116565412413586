export class NewAuthorization {
	constructor(
		public authorizations_id: number,
		public admin_id: number,
		public mod_id: number,
		public employee_id: number,
		public session_start: any,
		public session_end: any,
		public active: boolean,
		public auth_key: string,
		public modified: any,
		public created: any
	){}
}
export const Create_Authorizations = '\
	CREATE TABLE IF NOT EXISTS "Authorizations" (\
		authorizations_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		employee_id INTEGER,\
		session_start INTEGER DEFAULT 0,\
		session_end INTEGER DEFAULT 0,\
		active INTEGER DEFAULT 0,\
		auth_key TEXT,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';