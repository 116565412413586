import { Component, OnInit, OnDestroy } from '@angular/core';
import { SQLService } from '../services/sql.service';
import { PayrollService } from '../services/payroll.service';
import { Router, NavigationEnd } from '@angular/router';
import { PopoverController} from '@ionic/angular';
import { ModalController,NavParams } from '@ionic/angular';
import { PaymentsMenuComponent } from './payment-menu/payment-menu.component'
@Component({
  selector: 'ng-cheques-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.styl']
})
export class PaymentsComponent implements OnInit, OnDestroy {
  _;
  showInfo = [];
  payments = [];
  showList = [];
  count = -1;
  search: string;
  selected =[];
  multiple: boolean;
  verifiedName: boolean;
  constructor(
    private router: Router,
    private params: NavParams,
    private payroll: PayrollService,
    public popoverController: PopoverController,
    private navCtrl: ModalController,
    public sql: SQLService
  ) {
    this.multiple = params.get('select_multiple');
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_payments();
    });
  }
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:PaymentsMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='enable')this.enable(item);
      if(data=='disable')this.disable(item);
      if(data=='delete')this.delete(item, false);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  filter(e) { this.search=e.target.value; }
  add() {
    this.sql.count('Payments',(c)=>{
      if(this.sql.Trial && this.sql.Trial_Payments!=-1 && c>=this.sql.Trial_Payments) {
        this.sql.show_purhchase_modal();
      } else {this.router.navigate(['folder','Payment', 'New']);}
    });
  }
  print_selected() {
    //this.selected;
  }
  pay_selected() {
    //this.selected;
  }
  print_done() {
    this.multiple = false;
    this.selected=[];
  }
  print_edit() {
    this.multiple = true;
  }
  print_doc(payment,item?) {
    this.payroll.generate_cheque(payment,(Cheque)=>{
      this.router.navigate(["folder","Print","Cheque",Cheque.cheque_id]);
    });
    if(item)item.closeSlidingItems();
  }
  select(e) {
    if(this.multiple){
      this.toggle_item(e);
    } else {
      this.navCtrl.dismiss(e);
    }
  }
  import_multiple() {
    this.multiple=false;
    localStorage.setItem('import_type','Payments');
    this.router.navigate(['folder','Import','New']);
  }
  done_multiple() {
    this.multiple = false;
  }
  toggle_item(item) {
    if( this.selected.includes(item.payment_id) ) {
      this.deselect_item(item);
    } else {
      this.select_item(item);
    }
  }
  select_item(item) {
    this.selected.push(item.payment_id);
  }
  deselect_item(item) {
    var new_selection=[];
    for(var i = 0; i < this.selected.length; ++i){
      if(this.selected[i]!=item.payment_id){
        new_selection.push(this.selected[i]);
      }
    }
    this.selected = new_selection;
  }
  select_all() {
    var new_selection=[];
    for (var i = 0; i < this.payments.length; ++i) {
      new_selection.push(this.payments[i].payment_id);
    }
    this.selected=new_selection;
  }
  deselect_all() {
   this.selected = []; 
  }
  delete_selected() {
     if(!this.selected || this.selected.length==0){
      alert(this.sql.word.Select_Multiple_Payments_Prompt);
    } else {
      if(confirm(this.sql.word.Paymemt_Multiple_Delete_Confirmation)) {
        var i = 0;
        for(let c of this.selected) {
          this.sql.delete('Payments','payment_id',c,(Done)=>{i++;
            if(i==this.selected.length) {
              this.get_payments(null,()=>{
                this.selected = [];  
              });
            }
          });
        }
        
      }
    }
  }
  enable(payment){
    this.sql.set('Payments','payment_id',payment.payment_id,'enabled',1,(res)=>{
      payment.enabled = true;
    });
  }
  disable(payment){
    this.sql.set('Payments','payment_id',payment.payment_id,'enabled',0,(res)=>{
      payment.enabled = false;
    });
  }
  end() {this.router.navigate(['folder','Dashboard']);}
  get_payments(event?, cb?) {
    this.sql.fetch('Payments',(e)=>{
      if(e)this.payments=e;
      this.count=e.length;
      if(cb)cb();
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  edit(payment, item?) {
    if(this.multiple){
      this.toggle_item(payment);
    } else {
      this.router.navigate(['folder','Payment',payment.payment_id]);
    }
    if(item)item.closeSlidingItems();
  }
  paid(payment, item?) {
    if(payment.type=='cheque'){
      if(confirm(this.sql.word.Payment_Pay_Confirmation)) {
        this.sql.set( "Payments", 'payment_id', payment.payment_id, 'paid', true, (w) => {
          payment.paid = true;
          if (item) item.closeSlidingItems();
        });
      } else if (item) { item.closeSlidingItems(); }
    } else if(payment.type=='paypal') {
      if(confirm(this.sql.word.Payment_Pay_Confirmation_PayPal)) {
        this.sql.set( "Payments", 'payment_id', payment.payment_id, 'paid', true, (w) => {
          payment.paid = true;
          if (item) item.closeSlidingItems();
        });
      } else if(item) { item.closeSlidingItems(); }
    }
  }
  unpaid(payment, item?) {
    if(confirm(this.sql.word.Save_Payment_As_Unpaid)) {
      this.sql.set( "Payments", 'payment_id', payment.payment_id, 'paid', false, (w) => {
        payment.paid = false;
        if (item) item.closeSlidingItems();
      });
    } else if (item) { item.closeSlidingItems(); }
  }
  print(payment, item?) {
    this.payroll.generate_cheque(payment.cheque_id, (cheque) => {
      this.router.navigate(["Print","Cheque",cheque.cheque_id]);
      if(item)item.closeSlidingItems();
    })
  }
  paypal_pay(payment, item?) {
    if(confirm(this.sql.word.Send_PayPal_Confirmation)) {
      console.log('paypal_pay');
      if (item) item.closeSlidingItems();
    }
  }
  delete(payment, item?) { 
    if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('Payments','payment_id',payment.payment_id);
      this.get_payments();
    } else if (item) { item.closeSlidingItems(); }
  }
  ngOnInit() {
    this.get_payments();
  }
  ngOnDestroy(){
    this.multiple=false;
  }
}