import { environment } from '../../../../environments/environment';
import { SQLService } from '../../../services/sql.service';
import { Component, OnInit, Input, EventEmitter,ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
@Component({
	selector: 'app-purchase-complete',
	templateUrl: './purchase-complete.component.html',
	styleUrls: ['./purchase-complete.component.styl']
})
export class PurchaseCompleteComponent implements OnInit {
	constructor(
		private router: Router,
		public sql: SQLService,
		private route: ActivatedRoute,
		private navCtrl: ModalController,
		private current_platform: Platform,
		private location: Location
	) {
		this.router.events.subscribe((eve) => {
			//if(eve instanceof NavigationEnd) this.get_purchase();
		});
	}
	ngOnInit() {
	}
	off() {
		this.navCtrl.dismiss();
	}
}