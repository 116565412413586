import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { SQLService } from '../../services/sql.service';
import * as converter from 'number-to-words';
@Component({
  selector: 'ng-view-cheque',
  template: `
  	<ng-generate-cheque></ng-generate-cheque>
  `
})
export class ViewChequeComponent implements AfterViewInit {
	constructor(
		private activatedRoute: ActivatedRoute,
    public sql: SQLService
	) { }
	ngAfterViewInit() {

	}
}