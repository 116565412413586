import { SQLService } from '../../services/sql.service';
import { Flip } from '../../app.animations';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController, NavParams } from '@ionic/angular';
@Component({
  selector: 'ng-cheques-select-leave',
  templateUrl: './select-leave.page.html',
  styleUrls: ['./select-leave.page.styl'],
  animations: [ Flip ]
})
export class SelectLeavesPage implements OnInit {
  constructor(
    private router: Router,
    public sql: SQLService,
    private params: NavParams,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    private location: Location
  ) {
    var s = params.get('selected');
    if(s)this.selected = s.split(',');
    this.multiple = params.get('select_multiple');
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_leaves();
    });
  }
  count = -1;
  leaves = [];
  selected = [];
  multiple: boolean;
  output = new EventEmitter();
  @Input('set_table') table:string;
  @Input('set_model') model:string;
  @Input('set_model_id') id:string;
  ngOnInit() {
    this.get_leaves();
    this.id = this.route.snapshot.paramMap.get("id");
    this.table = this.route.snapshot.paramMap.get("table");
    this.model = this.route.snapshot.paramMap.get("model");
  }
  get_leaves(event?) {
    this.sql.fetch('Leaves',(e)=>{
      this.leaves=e;
      this.count=e.length;
      setTimeout(()=>{if (event) event.target.complete();},1234);
    });
  }
  select_none() {this.navCtrl.dismiss(-1);}
  select(e) {
    if (this.id) {
      let a = 'leave';
      this.sql.query('UPDATE '+this.table+' '+( this.id?'WHERE '+this.model+' = '+this.id:'' )+' SET '+a+'_id = '+e[a+'_id']);
      this.location.back();
    }
    this.navCtrl.dismiss(e);
  }
  add() {
    this.router.navigate(['folder','Leave','New']);
    this.navCtrl.dismiss();
  }
  off() {
    this.navCtrl.dismiss();
  }
}