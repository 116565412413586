export class NewSalary {
	constructor(
		public salary_id?: number,
		public title?: string,
		public frequency?: string,
		public description?: string,
		public amount?: string,
		public currency?: string,
		public employees?: any,
		public enabled?: boolean,
		public admin_id?: number,
		public mod_id?: number,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Salaries = '\
	CREATE TABLE IF NOT EXISTS "Salaries" (\
		salary_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		title TEXT,\
		description TEXT,\
		frequency VARCHAR(100),\
		amount INTEGER DEFAULT 0,\
		currency VARCHAR(10),\
		employees TEXT,\
		enabled INTEGER DEFAULT 0,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';