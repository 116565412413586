import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NewAddress } from '../../models/address';
import { SQLService } from '../../services/sql.service';
import { ModalController, Platform } from '@ionic/angular';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { PurchaseComponent } from '../../dashboard/purchase/purchase.component';
@Component({
  selector: 'ng-cheques-new-address',
  templateUrl: './new-address.component.html',
  styleUrls: ['./new-address.component.styl']
})
export class NewAddressPage {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public modalController: ModalController,
    public sql: SQLService
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd)this.open()
    });
  }
  address = new NewAddress();
  _c=0;
  mail() {
    this.purchase(); 
  }
  print() {
    if(this._c>0) {
      this.sql.add('Addresses',this.address,(address_id)=>{
        if(address_id){
          this.address.address_id = address_id;
          this.router.navigate(['folder','Print','Address',this.address.address_id]);      
        }
      });
    } else {
      if(this.address.address_id){
        this.router.navigate(['folder','Print','Address',this.address.address_id]);
      } else {
        alert(this.sql.word.PrinterError);
      }
    }
  }
  save(end=true) {
    this.sql.add('Addresses',this.address,(insertId)=>{
      if(!insertId) return;
      if(end){
        this._c=0;
        this.address = new NewAddress();
        this.end(insertId);
      }
    });
  }
  delete_address() {
    if(!this.address.address_id){
      this.end();
    } else if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('Addresses','address_id',this.address.address_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  end(id?) {
    this.sql.get_temporary('page',(page)=>{
      if (this._c>0) {
        if (confirm(this.sql.word.Discard_Confirmation)) {
          setTimeout( () => { this.address = new NewAddress(); }, 500);
          this.router.navigate(['folder','Address']);
        }
      } else {
        if(page) {
          this.router.navigate(['folder',page.location,page.id,'Address',id]);
          setTimeout( () => { this.sql.remove_temporary('page'); }, 600);
        } else {
          this.router.navigate(['folder','Address']);
        }
      }
    });
  }
  get_addresses() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida) return;
    this.sql.view('Addresses','address_id',ida,(e)=>{
      var f=Object.entries(e[0]); let n = new NewAddress();
      for(let t of f)n[t[0]]=t[1];
      this.address = n;
    });
  }
  async purchase() {
    const m = await this.modalController.create({
      component: PurchaseComponent, 
      componentProps: {}
    });
    await m.present();
    let a = await m.onDidDismiss();
  }
  open() {
    this.address.enabled=true;
    this.get_addresses();
  }
}