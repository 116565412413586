import { SQLService } from '../../services/sql.service';
import { NewDashboard } from '../../models/settings';
import { Flip } from '../../app.animations';
import { Component, ViewChild, OnInit, Input, EventEmitter } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
import { IonReorderGroup } from '@ionic/angular';
import { ItemReorderEventDetail } from '@ionic/core';
@Component({
  selector: 'app-dashboard-customize',
  templateUrl: './customize.page.html',
  styleUrls: ['./customize.page.scss'],
  animations: [ Flip ]
})
export class CustomizePage implements OnInit {
  constructor(
    private router: Router,
    public sql: SQLService,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    private location: Location
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_components();
    });
  }
  output = new EventEmitter();
  dashboard = new NewDashboard();
  count = -1;
  Reordering=true;
  ShowEditButton=false;
  show_slider_items=false;
  main_components = [
    {'title':this.sql.word.Cheques},
    {'title':this.sql.word.Employees},
    {'title':this.sql.word.Payroll},
    {'title':this.sql.word.Timesheets}
  ];
  smaller_components = [
    {'title':this.sql.word.Payees},
    {'title':this.sql.word.Invoices},
    {'title':this.sql.word.Jobs},
    {'title':this.sql.word.Holiday},
    {'title':this.sql.word.Salaries},
    {'title':this.sql.word.Payments},
    {'title':this.sql.word.Schedules},
    {'title':this.sql.word.Vacations}
  ];

  enabled_widgets = [
    {'title':this.sql.word.Cheques},
    {'title':this.sql.word.Employees},
    {'title':this.sql.word.Payroll},
    {'title':this.sql.word.Timesheets}
  ];
  disabled_widgets = [
    {'title':this.sql.word.Payees},
    {'title':this.sql.word.Invoices},
    {'title':this.sql.word.Jobs},
    {'title':this.sql.word.Holiday},
    {'title':this.sql.word.Salaries},
    {'title':this.sql.word.Payments},
    {'title':this.sql.word.Schedules},
    {'title':this.sql.word.Vacations}
  ];

  ngOnInit() {
    this.get_components();
  }
  @ViewChild(IonReorderGroup) reorderGroup: IonReorderGroup;
  doReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    console.log('Dragged from index', ev.detail.from, 'to', ev.detail.to);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. This method can also be called directly
    // by the reorder group
    ev.detail.complete();
    console.log('enabled_widgets',this.enabled_widgets);
    console.log('disabled_widgets',this.disabled_widgets);
  }
  get_components(event?) {
    if(!this.dashboard.display_method)this.dashboard.display_method='list';
    this.count=0;
  }
  save_components() {

  }
  off() {
    this.save_components();
    this.navCtrl.dismiss(1);
  }
}