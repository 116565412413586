import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SQLService } from '../../../services/sql.service';
@Component({
  selector: 'app-posted-cheque',
  templateUrl: './posted-cheque.component.html',
  styleUrls: ['./posted-cheque.component.scss'],
})
export class PostedChequeComponent implements OnInit {
  constructor(
    private sql: SQLService) { 
  }
  @Output('loaded') loaded = new EventEmitter<boolean>();
  @Input('RenderMICR') RenderMICR = false;
  @Input('ImageMICR') ImageMICR = true;
  @Input('show') show = 'table';
  @Input('cheque') cheque: any;
  @Input('design') design: any;
  @Input('rotate') rotate: boolean;
  @Input('monochrome') monochrome: boolean;
  @Input('fold_line') fold_line: boolean;
  @Input('borders') borders: boolean;
  @Input('designer') designer: any;
  @Input('gradients') gradients: boolean;
  @Input('voidcheck') voidcheck: boolean;
  @Input('font') font: any;
  @Input('fiat') fiat: boolean;
  @Input('color') color: any;
  @Input('scale') scale=0.9;
  @Input('micr') micr: any;
  @Input('micr_top') micr_top: string;
  @Input('micr_right') micr_right: string;
  @Input('micr_bottom') micr_bottom: string;
  @Input('micr_left') micr_left: string;
  @Input('secret') secret = '';
  @Input('lines') lines: any;
  @Input('template') template: string;
  @Input('layers') layers: any;
  @Input('translations') translations = this.sql.word;
  @Input('address') data: any;
  @Input('fontsize') fontsize: boolean;
  @Input('bold') bold: boolean;
  @Input('italic') italic: boolean;
  @Input('margin_bottom') bottom = '0.0cm';
  @Input('margin_top') top = '0.0cm';
  @Input('show_cheque_number') show_cheque_number: boolean = true;
  @Input('show_cheque_date') show_cheque_date: boolean = true;
  @Input('show_micr') show_micr: boolean = true;
  @Input('cheque_background_image_repeat') cheque_background_image_repeat = false;
  @Input('background_position') background_position: string;
  @Input('cheque_text_color') cheque_text_color: string;
  @Input('cheque_background_image') cheque_background_image: any;
  @Input('cheque_logo_image') cheque_logo_image: any;
  @Input('cheque_header_image') cheque_header_image: any;
  @Input('cheque_bank_image') cheque_bank_image: any;
  @Input('show_cheque') show_cheque: boolean = true;
  @Input('show_address') show_address: boolean = true;
  @Input("address_top") address_top: string;
  @Input("address_right") address_right: string;
  @Input("address_bottom") address_bottom: string;
  @Input("address_left") address_left: string;
  design_company_name_top = "auto";
  design_company_name_right = "auto";
  design_company_name_bottom = "89mm";
  design_company_name_left = "20mm";
  design_company_address_top = "auto";
  design_company_address_right = "auto";
  design_company_address_bottom = "85mm";
  design_company_address_left = "20mm";
  design_company_address_2_top = "auto";
  design_company_address_2_right = "auto";
  design_company_address_2_bottom = "81mm";
  design_company_address_2_left = "20mm";
  design_company_city_top = "auto";
  design_company_city_right = "auto";
  design_company_city_bottom = "77mm";
  design_company_city_left = "20mm";
  design_company_country_top = "auto";
  design_company_country_right = "auto";
  design_company_country_bottom = "73mm";
  design_company_country_left = "20mm";
  design_postmark_img_top = "auto";
  design_postmark_img_right = "5mm";
  design_postmark_img_bottom = "73mm";
  design_postmark_img_left = "auto";
  design_postmark_qr_top = "18mm";
  design_postmark_qr_right = "50mm";
  design_postmark_qr_bottom = "auto";
  design_postmark_qr_left = "auto";
  design_postmark_id_top = "30mm";
  design_postmark_id_right = "auto";
  design_postmark_id_bottom = "auto";
  design_postmark_id_left = "30mm";
  design_address_name_top = "auto";
  design_address_name_right = "auto";
  design_address_name_bottom = "40mm";
  design_address_name_left = "20mm";
  design_address_top = "auto";
  design_address_right = "auto";
  design_address_bottom = "36mm";
  design_address_left = "20mm";
  design_address_line_2_top = "auto";
  design_address_line_2_right = "auto";
  design_address_line_2_bottom = "32mm";
  design_address_line_2_left = "20mm";
  design_address_line_3_top = "auto";
  design_address_line_3_right = "auto";
  design_address_line_3_bottom = "28mm";
  design_address_line_3_left = "20mm";
  design_address_line_4_top = "auto";
  design_address_line_4_right = "auto";
  design_address_line_4_bottom = "24mm";
  design_address_line_4_left = "20mm";
  ngOnInit() {
    if(!this.show_cheque)this.loaded.emit(true);
  }
  loaded_component() {
    //this.images.push(1);
    //if(this.images.length==2) 
    this.loaded.emit(true);
  }
  generateQR(d) {
    // this.qr.encode(this.qr.Encode.TEXT_TYPE,d).then((data) => {
    //   console.log('QR Data', data);
    //   this.design.postmark_qr=data;
    // }).catch(err => {console.error(err);});
  }
  clicker(e, element) {
    console.log('mousedown', e, element);
  }
  noper(e, element) {
    console.log('mouseup', e, element);
  }
  selected=[];
  images = [];
  deselect_all() {
    this.selected = [];
  }
}
 