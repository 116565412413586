import { SQLService } from '../../../../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { Location } from '@angular/common';
import { PreviewImportFieldsPage } from './preview-field/preview-field.component'
import { ToastController } from '@ionic/angular';
@Component({
  selector: 'ng-cheques-preview-import',
  templateUrl: './preview-import.component.html',
  styleUrls: ['./preview-import.component.styl']
})
export class PreviewImportComponent implements OnInit {
  constructor(
    public location: Location,
    public sql: SQLService,
    private toast: ToastController,
    private navCtrl: ModalController,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      // if(eve instanceof NavigationEnd) 
    });
  }
  import_settings:any;
  import_type:any;
  spreadsheet_import_meta:any;
  spreadsheet_import:any;
  spreadsheet:any;
  imports = [];
  count = -1;
  search:string;
  filter(e) {this.search=e.target.value;}
  add() {this.router.navigate(['folder','Imports','New']);}
  backtoimport() {
    this.location.back();
  }
  ngOnInit() {
    this.import_settings = JSON.parse(localStorage.getItem('import_settings'));
    this.import_type = localStorage.getItem('import_type');
    this.spreadsheet_import_meta = JSON.parse(localStorage.getItem('spreadsheet_import_meta'));
    this.spreadsheet = JSON.parse(localStorage.getItem('spreadsheet_import'));

    this.run_translations((imports)=>{
      this.imports = imports;
        
    })
  }
  save() {
    var ii = 0;
    for(let t of this.translations){
      if(t.type==this.import_type) {
        for(let i of this.imports) {
          this.sql.add(t.table,i,()=>{ii++;
            if(ii==this.imports.length){
              this.doneImport(this.imports.length,this.import_type);
            }
          });
        }
      }
    }
  }
  async preview(item){
    console.log(item);
    const m = await this.navCtrl.create({component: PreviewImportFieldsPage, componentProps: {
      data: item
    }});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data){
    }
  }
  run_translations(cb?) {
    var j=0,k=0,l=0,m=[];
    for(let s of this.import_settings){k=0;
      for(let t of this.translations) {
        if(t.type==this.import_type){j=0;
          for(let e of t.items) {
            if(e.title==s[0]){
              var h_i=this.spreadsheet[0].indexOf(s[1]);
              for(var i = 1; i < this.spreadsheet.length; i++){
                if(!m[i-1])m[i-1]={};
                m[i-1][e.model]=this.spreadsheet[i][h_i];
                if(cb&&this.spreadsheet.length-1==i &&l==this.import_settings.length-1){
                  cb(m);
                }
             }
            }j++;
          }
        }k++;
      }l++;
    }
  }
  async doneImport(count,type) {
    this.router.navigate(['folder',type])
    const toast = await this.toast.create({
      message: this.sql.word.Successfully_Imported+' '+count+' '+this.sql.word[type],
      duration: 2000
    });
    toast.present();
  }

  // translations
  // 

  translations = [
    {
      type:'Addresses',
      table:'Addresses',
      items:[
        {title:'Name', model: 'name'},
        {title:'Address', model: 'address'},
        {title:'Address_2', model: 'address_2'},
        {title:'City', model: 'city'},
        {title:'State', model: 'province'},
        {title:'Postal', model: 'postal_code'},
        {title:'Country', model: 'country'} 
      ]
    },
    {
      type:'Administrators',
      table:'Administrators',
      items:[
        {title:'Username', model: 'username'},
        {title:'Full_Name', model: 'full_name'},
        {title:'Date_of_Birth', model: 'birthday'},
        {title:'Email', model: 'email'},
        {title:'Phone', model: 'phone'},
        {title:'Employee', model: 'employees'},
        {title:'Address', model: 'addresses'},
        {title:'Payment_Limit', model: 'payment_amount_limit'},
        {title:'Total_Limit', model: 'total_limit'},
        {title:'Currency', model: 'currency'},
        {title:'Description', model: 'description'}
      ]
    },
    {
      type:'Bank_Accounts',
      table:'BankAccounts',
      items:[
        {title:'Bank_Name', model:'name'},
        {title:'Description', model:'description'},
        {title:'Bank_Address', model:'address_line_one'},
        {title:'Unit', model:'address_line_two'},
        {title:'City', model:'city'},
        {title:'State', model:'province'},
        {title:'Country', model:'country'},
        {title:'ZIP', model:'postal_code'},
        {title:'Bank_Logo', model:'image'},
        {title:'Account_Holder', model:'account_holder'},
        {title:'Starting_Cheque_ID', model:'cheque_starting_id'},
        {title:'Transit_Number', model:'transit_number'},
        {title:'Institution_Number', model:'institution_number'},
        {title:'Designation_Number', model:'designation_number'},
        {title:'Account_Number', model:'account_number'},
        {title:'Currency', model:'currency'},
        {title:'Digital_Signature', model:'signature'},
        {title:'Payroll_Limit', model:'payment_payroll_limit'},
        {title:'Payment_Limit', model:'payment_amount_limit'},
        {title:'Total_Limit', model:'total_limit'}
      ]
    },
    {
      type:'Cheques',
      table:'Cheques',
      items:[
        {title:'Name', model:'name'},
        {title:'Amount', model:'amount'},
        {title:'Currency', model:'currency'},
        {title:'Date', model:'date'},
        {title:'Memo', model:'printed_memo'},
        {title:'Bank_Account', model:'banks'},
        {title:'Digital_Signature', model:'signature_image'},
        {title:'Address', model:'address'},
        {title:'Address_2', model:'address_2'},
        {title:'Address_Name', model:'address_name'},
        {title:'Payee', model:'payees'},
        {title:'Employee', model:'employees'},
        {title:'Description', model:'notes'},
        {title:'Design', model:'design'}
      ]
    },
    {
      type:'Deductions',
      table:'Deductions',
      items:[
        {title:'Title', model:'title'},
        {title:'Description', model:'description'},
        {title:'Amount', model:'amount'},
        {title:'Currency', model:'currency'},
        {title:'Frequency', model:'frequency'},
        {title:'Perpetually_Enabled', model:'always_enabled'},
        {title:'Start_Date', model:'start_date'},
        {title:'End_Date', model:'end_date'}
      ]
    },
    {
      type:'Employees',
      table:'Employees',
      items:[
        {title:'Full_Name', model:'full_name'},
        {title:'Email', model:'email'},
        {title:'Phone', model:'phone'},
        {title:'Fax', model:'fax'},
        {title:'Description', model:'description'},
        {title:'Address', model:'addresses'},
        {title:'Salary', model:'salaries'},
        {title:'Currency', model:'currency'},
      ]
    },
    {
      type:'Invoices',
      table:'Invoices',
      items:[
        {title:'Title', model:'title'},
        {title:'Address', model:'addresses'},
        {title:'Description', model:'description'},
        {title:'Amount', model:'amount'},
        {title:'Currency', model:'currency'},
        {title:'Due_Date', model:'date'},
      ]
    },
    {
      type:'Jobs',
      table:'Jobs',
      items:[
        {title:'Title', model:'title'},
        {title:'Description', model:'description'},
        {title:'Employees', model:'employees'},
        {title:'Salary', model:'salary_ids'},
        {title:'Start_Date', model:'start_date'},
        {title:'Start_Time', model:'start_time'},
        {title:'End_Date', model:'end_date'},
        {title:'End_Time', model:'end_time'},
      ]
    },
    {
      type:'Leaves_Of_Absence',
      table:'Leaves',
      items:[
        {title:'Leave_Name',model:'title'},
        {title:'Description',model:'description'},
        {title:'Employee',model:'employees'},
        {title:'Payee',model:'payees'},
        {title:'Paid_Leave',model:'paid'},
        {title:'Bonus_Percentage',model:'overtime_percentage'},
        {title:'Fixed_Amount',model:'overtime_amount'},
        {title:'Currency',model:'currency'},
        {title:'Start_Date',model:'start_date'},
        {title:'End_Date',model:'end_date'},
      ]
    },
    {
      type:'Moderators',
      table:'Moderators',
      items:[
      {title:'Username',model:'username'},
      {title:'First_Name',model:'first_name'},
      {title:'Last_Name',model:'last_name'},
      {title:'Date_of_Birth',model:'birthday'},
      {title:'Email',model:'email'},
      {title:'Phone',model:'phone'},
      {title:'Employee',model:'employees'},
      {title:'Address',model:'addresses'},
      {title:'Payment_Limit',model:'payment_amount_limit'},
      {title:'Total_Limit',model:'total_limit'},
      {title:'Currency',model:'currency'},
      {title:'Description',model:'description'},
      {title:'Allow_Moderation',model:'moderation'},
      {title:'Create_Paycheques',model:'create'},
      {title:'Edit_Paycheques_and_Data',model:'edit'},
      {title:'Destroy_Data_and_Paycheques',model:'destroy'},
      {title:'Day_Start_Time',model:'day_start_time'},
      {title:'Day_End_Time',model:'day_end_time'},
      ]
    },
    {
      type:'Payees',
      table:'Payees',
      items:[
        {title:'Payee_Identifier',model:'title'},
        {title:'Payee_Name',model:'name'},
        {title:'Description',model:'description'},
        {title:'Currency',model:'currency'},
        {title:'Payment_Memo',model:'printed_memo'},
        {title:'Address',model:'addresses'},
        {title:'Salary',model:'salaries'}
      ]
    },
    {
      type:'Payments',
      table:'Payments',
      items:[
        {title:'Title',model:'title'},
        {title:'Description',model:'description'},
        {title:'Name',model:'fullname'},
        {title:'Employee',model:'employees'},
        {title:'Payee',model:'payees'},
        {title:'Address',model:'addresses'},
        {title:'Amount',model:'amount'},
        {title:'Currency',model:'currency'},
        {title:'Bank_Account',model:'banks'},
        {title:'Memo',model:'printed_memo'},
        {title:'Date',model:'date'},
        {title:'Time',model:'time'},
        {title:'Automatically_Process',model:'auto_send'},
        {title:'Mark_as_Paid',model:'paid'},
      ]
    },
    {
      type:'Payrolls',
      table:'Payrolls',
      items:[
        {title:'Title', model:'title'},
        {title:'Description', model:'description'},
        {title:'Tabulate_Payments', model:'time_method'},
        {title:'Bank_Account', model:'banks'},
        {title:'Currency', model:'currency'},
        {title:'Employees', model:'employees'},
        {title:'Payees', model:'payees'},
        {title:'Schedules', model:'schedules'},
        {title:'Memo', model:'printed_memo'},
        {title:'Frequency', model:'frequency'},
        {title:'Repeat_Indefinitely', model:'indefinite_payroll_enabled'},
        {title:'Start_Date', model:'start_date'},
        {title:'Start_Time', model:'start_time'},
        {title:'End_Date', model:'end_date'},
        {title:'End_Time', model:'end_time'},
      ]
    },
    {
      type:'Salaries',
      table:'Salaries',
      items:[
        {title:'Salary_Name',model:"title"},
        {title:'Description',model:"description"},
        {title:'Salary_Amount',model:"amount"},
        {title:'Currency',model:"currency"},
        {title:'Frequency',model:"frequency"},
      ]
    },
    {
      type:'Timesheets',
      table:'Timesheets',
      items:[
        {title:'Title',model:'name'},
        {title:'Description',model:'description'},
        {title:'Employee',model:'employees'},
        {title:'Payee',model:'payees'},
        {title:'Start_Date',model:'start_date'},
        {title:'Start_Time',model:'start_time'},
        {title:'End_Date',model:'end_date'},
        {title:'End_Time',model:'end_time'},
      ]
    },
    {
      type:'Taxes',
      table:'Taxes',
      items:[
        {title:'Title',model:"title"},
        {title:'Description',model:"description"},
        {title:'Amount',model:"amount"},
        {title:'Currency',model:"currency"},
        {title:'Frequency',model:"frequency"},
        {title:'Start_Date',model:"start_date"},
        {title:'Perpetually_Enabled',model:"always_enabled"},
        {title:'End_Date',model:"end_date"}
      ]
    },
    {
      type:'Vacations',
      table:'Vacations',
      items:[
        {title:'Vacation_Name',model:"name"},
        {title:'Description',model:"description"},
        {title:'Employees',model:"employees"},
        {title:'Payees',model:"payees"},
        {title:'Paid_Vacation',model:"paid"},
        {title:'Bonus_Percentage',model:"overtime_percentage"},
        {title:'Fixed_Amount',model:"overtime_amount"},
        {title:'Currency',model:"currency"},
        {title:'Repeat',model:"repeat"},
        {title:'Indefinite_Leave',model:"always_enabled"},
        {title:'Start_Date',model:"start_date"},
        {title:'End_Date',model:"end_date"},
      ]
    }
  ]

}