import { SQLService } from '../../services/sql.service';
import { NewDeduction } from '../../models/deduction';
import { environment } from '../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
@Component({
  selector: 'ng-cheques-new-deduction',
  templateUrl: './new-deduction.component.html',
  styleUrls: ['./new-deduction.component.styl']
})
export class NewDeductionPage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public sql: SQLService
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd)this.open();
    });
  }
  deduction = new NewDeduction();
  currencies = Object.keys(environment.currencies);
  _c = 0;
  end() {
    if (this._c > 0) {
      if (confirm(this.sql.word.Delete_Confirmation)) {
        this.deduction = new NewDeduction();
         this.router.navigate(['folder','Deductions']);
      }
    } else {
       this.router.navigate(['folder','Deductions']);
    }
  }
  save(end=true) {
    this.sql.add('Deductions',this.deduction,(insertId)=>{
      if(!insertId) return;
      if(end){
        this.deduction = new NewDeduction();
        this._c=0;
        this.end();
      }
    });
  }
  delete_deduction() {
    if(!this.deduction.deduction_id){
      this.end();
    } else if(confirm(this.sql.word.Delete_Deduction_Confirmation)) {
      this.sql.delete('Deductions','deduction_id',this.deduction.deduction_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  amount(event) {
    if(!event.target.value||event.target.value==0){
      this.deduction.amount='0.00';
    } else {
      this.deduction.amount=this.sql.amount(event.target.value,null,this.deduction.currency);
    }
  }
  get_deduction() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida) return;
    this.sql.view('Deductions','deduction_id',ida,(e)=>{
      var f=Object.entries(e[0]);let n=new NewDeduction();
      for(let t of f)n[t[0]]=t[1];
      this.deduction=n;
      console.log(this.deduction);
    });
  }
  open() {
    var d = new Date(Date.now()).toISOString();
    this.deduction.start_date = d;
    var date = new Date();
    var start_time = date.setHours(9,0,0);
    this.deduction.start_time = new Date(start_time).toISOString();
    var date = new Date();
    var end_time = date.setHours(17,0,0);
    this.deduction.end_time = new Date(end_time).toISOString();
    this.deduction.enabled=true;
    this.deduction.always_enabled=true;
    this.deduction.frequency='month';
    this.deduction.paytype='percentage';
    this.deduction.currency=this.sql.Currency;
    this.get_deduction();
  }
  ngOnInit() {
    // this.open();
  }
}