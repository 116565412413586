import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SQLService } from '../../services/sql.service';
import { Router } from '@angular/router';
import { MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
@Component({
  selector: 'ng-cheques-splash-screen',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.styl']
})
export class SplashComponent implements OnInit, AfterViewInit, OnDestroy{
  constructor(
    public menu: MenuController,
    public sql: SQLService,
    public plt: Platform,
    public router: Router) { 
    
  } 
  languages: any;
  currencies: any;
  timer:any;
  pin: string;
  url: string;
  prod:boolean;
  mobile:boolean;
  new = 'loading';
  mode: string;
  error=false;
  loading=false;
  load_intro=true;
  _vuri=false;
  welcome_word:string;
  showSubtitle = true;
  check_geo=false;
  check_biometrics=false;
  check_password=false;
  check_pin=false;
  check_login=false;
  pin_enabled:boolean;
  geolocation_enabled:boolean;
  biometrics_enabled:boolean;
  password_enabled:boolean;
  login_enabled:boolean;
  get_enabled_settings() {
    var secure = 0;
    this.sql.get_setting('face_enabled',(face)=>{
      if(face.option=="1"){
        secure=1;
        this.biometrics_enabled=true;
      }
      this.sql.get_setting('password_enabled',(pass_enabled)=>{
        this.sql.get_setting('password',(pass)=>{
          if(pass_enabled.option=="1"&&pass.option){
            secure=1;
            this.password_enabled=true;
          }
          this.sql.get_setting('pin_enabled',(pin_enabled)=>{
            this.sql.get_setting('pin',(pin)=>{
              if(pin_enabled.option=="1"&&pin.option){
                secure=1;
                this.pin_enabled=true;
              }
              this.sql.get_setting('geo_enabled',(geo)=>{
                if(geo.option=="1"){
                  secure=1;
                  this.geolocation_enabled=true;
                } else if(secure==0) {
                  this.logged_in();
                }
                this.begin_experience();
              });
            });
          });
        });
      });
    });
  }
  begin_experience() {
    if(!this.login_enabled&&!this.pin_enabled&&!this.biometrics_enabled&&!this.password_enabled&&!this.geolocation_enabled){
      return;
    }
    if(this.login_enabled&&!this.check_login){
      this.mode='login';
    } else if(this.pin_enabled&&!this.check_pin){
      this.mode='pin';
    } else if(this.biometrics_enabled&&!this.check_biometrics){
      this.mode='biometrics';
    } else if(this.password_enabled&&!this.check_password){
      this.mode='password';
    } else if(this.geolocation_enabled&&!this.check_geo){
      this.mode='geolocation';
    } else {
      this.logged_in();
    }
  }
  _subtitle(e?) {this.showSubtitle=false;}
  geo_return(v){
    this.check_geo = v;
    if(v)this.begin_experience();
  }
  biometric_return(v){
    this.check_biometrics = v;
    if(v)this.begin_experience();
  }
  password_return(v){
    this.check_password = v;
    if(v)this.begin_experience();
  }
  pin_return(v){
    this.check_pin = v;
    if(v)this.begin_experience();
  }
  login_return(v){
    this.check_login = v;
    if(v)this.begin_experience();
  }
  logged_in() {
    this.sql.logged_in=true;
    this.router.navigate(['folder','Dashboard']);
  }
  ngOnDestroy() {
    this.showSubtitle = true;
  }
  ngAfterViewInit() {
    this.menu.close();
    if (this.plt.is('mobile')){
      this.mobile=true;
      this.mode='login';
    }
    this.get_enabled_settings();
  }
  async ngOnInit() {
    this.showSubtitle = true;
    this.prod = environment.production;
    this.menu.close();
    this.sql.remove_temporary('LoggedIn');
    this.sql.get_setting('welcome_word',(s)=>{this.welcome_word=(s.option)?s.option:false;});
    this.sql.get_setting('view_splash_uri',(s)=>{this._vuri=(s.option)?s.option:false;});
    this.currencies = Object.keys(environment.currencies);
  }
}
  // login() {console.log('LOGIN ATTEMPT',this.pin);
  //   this.loading=true;
  //   this.sql.check_pin(this.pin,(loggedIn)=>{
  //     if(this.sql.verbose)console.log('LOGIN ATTEMPT ',loggedIn);
  //     this.window_model.pin=null;
  //     if(loggedIn){
  //       if(this.sql.verbose)console.log('LOGIN SUCCESS');
  //       this.a=-200;
  //       this.sql.set_temporary('LoggedIn','true', 1800000);
  //       this.router.navigate(['folder','Dashboard','Menu']);
  //     }
  //   });
  // }