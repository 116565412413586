import { SQLService } from '../../services/sql.service';
import { PayrollService } from '../../services/payroll.service';
import { NewPayroll } from '../../models/payroll';
import { SelectDeductionPage } from '../../deductions/select-deduction/select-deduction.page';
import { SelectTaxPage } from '../../taxes/select-tax/select-tax.page';
import { SelectDatePage } from '../../settings/select-date/select-date.component';
import { SelectEmployeePage } from '../../employees/select-employee/select-employee.page';
import { SelectBankPage } from '../../settings/bank-accounts/select-bank/select-bank.page';
import { SelectPayeePage } from '../../payees/select-payee/select-payee.page';
import { SelectSchedulePage } from '../../schedule/select-schedule/select-schedule.page';
import { SelectJobPage } from '../../jobs/select-job/select-job.page';
import { SelectPaypalPaymentsComponent } from '../../settings/paypal-payments/select-paypal-payments/select-paypal-payments.component';
import { environment } from '../../../environments/environment';
import { Flip } from '../../app.animations';
import { CalendarModal, CalendarModalOptions, DayConfig, CalendarResult } from 'ion2-calendar';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { Location } from '@angular/common';
@Component({
  selector: 'ng-cheques-new-payroll',
  templateUrl: './new-payroll.component.html',
  styleUrls: ['./new-payroll.component.styl'],
  animations: [ Flip ]
})
export class NewPayrollPage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public sql: SQLService,
    public pay: PayrollService,
    public modalController: ModalController,
    private location: Location,
    public platform: Platform
  ) { 
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.open();
    });
  }
  payroll = new NewPayroll('fortnite','both',true);
  currencies = Object.keys(environment.currencies);
  paypal_currencies = [];
  sort_by_alpha = false;
  filter_paid = false;
  sort_by_money = false;
  default_currency: any;
  _c=0;
  async add_schedule() {
    this.set_temp();
    const m = await this.modalController.create({
      component: SelectSchedulePage, 
      componentProps: {select_multiple: true}
    });
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data){
      if(a.data==-1){
        this.delete_schedule();
        this.sql.hapticsImpactLight();  
      } else {
        this.payroll.schedule_ids=a.data;
        this.get_schedules();
        this._c=1;
        this.sql.hapticsImpactMedium();
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_schedule(schedule) {
    this.router.navigate(['folder','Schedule', schedule.schedule_id]); 
  }
  delete_schedule() {
    this.payroll.schedules = null;
    this.payroll.schedule_ids=null;
    // this.sql.set( 'Payrolls', 'payroll_id', this.payroll.payroll_id, 'schedules', null);
  }
  get_schedules() {
    if(!this.payroll.schedule_ids)return;
    if(typeof this.payroll.schedule_ids === 'string'){
      this.payroll.schedule_ids=this.payroll.schedule_ids.split(',');
    } else if (typeof this.payroll.schedule_ids === 'number') {
      this.payroll.schedule_ids=[this.payroll.schedule_ids];
    }
    this.payroll.schedules=[];
    for(let a of this.payroll.schedule_ids) {
      this.sql.view('Schedules','schedule_id',a,(data)=>{
        for(let d of data)this.payroll.schedules.push(d);
      });
    }
  }
  async add_job() {
    this.set_temp();
    const m = await this.modalController.create({
      component: SelectJobPage, 
      componentProps: {select_multiple: true}
    });
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data){
      if(a.data==-1){
        this.delete_job();
        this.sql.hapticsImpactLight();
      } else {
        this.payroll.job_ids=a.data;
        this.get_jobs();
        this._c=1;
        this.sql.hapticsImpactMedium();
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_job(job) {
    this.router.navigate(['folder','Job', job.job_id]); 
  }
  delete_job() {
    this.payroll.jobs = null;
    this.payroll.job_ids=null;
    // this.sql.set( 'Payrolls', 'payroll_id', this.payroll.payroll_id, 'jobs', null);
  }
  get_jobs() {
    if(!this.payroll.job_ids)return;
    if(typeof this.payroll.job_ids === 'string'){
      this.payroll.job_ids=this.payroll.job_ids.split(',');
    } else if (typeof this.payroll.job_ids === 'number') {
      this.payroll.job_ids=[this.payroll.job_ids];
    }
    this.payroll.jobs=[];
    for(let a of this.payroll.job_ids) {
      this.sql.view('Jobs','job_id',a,(data)=>{
        for(let d of data)if(true)this.payroll.jobs.push(d);
      });
    }
  }
  async add_employee() {
    this.set_temp();
    const m = await this.modalController.create({
      component: SelectEmployeePage, 
      componentProps: {select_multiple: true}
    });
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data){
      if(a.data==-1){
        this.delete_employee();
        this.sql.hapticsImpactLight();
      } else {
        this.payroll.employee_ids = a.data;
        this.get_employees();
        this._c=1;
        this.sql.hapticsImpactMedium();
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_employee(employee) {
    this.router.navigate(['folder','Employee', employee.employee_id]); 
  }
  delete_employee() {
    this.payroll.employees = null;
    this.payroll.employee_ids=null;
    // this.sql.set( 'Payrolls', 'payroll_id', this.payroll.payroll_id, 'employees', null);
  }
  get_employees() {
    if(!this.payroll.employee_ids)return;
    if(typeof this.payroll.employee_ids === 'string'){
      this.payroll.employee_ids=this.payroll.employee_ids.split(',');
    } else if (typeof this.payroll.employee_ids === 'number') {
      this.payroll.employee_ids=[this.payroll.employee_ids];
    }
    this.payroll.employees=[];
    for(let a of this.payroll.employee_ids) {
      this.sql.view('Employees','employee_id',a,(data)=>{
        for(let d of data)this.payroll.employees.push(d);
      });
    }
  }
  async add_payee() {
    this.set_temp();
    const m = await this.modalController.create({
      component: SelectPayeePage, 
      componentProps: {select_multiple: true}
    });
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1){
        this.delete_payee();
        this.sql.hapticsImpactLight();  
      } else {
        this.payroll.payee_ids = a.data;
        this.get_payees();
        this._c=1;
        this.sql.hapticsImpactMedium();  
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_payee(payee) {
    this.router.navigate(['folder','Payee', payee.payee_id]); 
  }
  delete_payee() {
    this.payroll.payees   =null;
    this.payroll.payee_ids=null;
    // this.sql.set( 'Payrolls', 'payroll_id', this.payroll.payroll_id, 'payees', null);
  }
  get_payees() {
    if(!this.payroll.payee_ids)return;
    if(typeof this.payroll.payee_ids === 'string'){
      this.payroll.payee_ids=this.payroll.payee_ids.split(',');
    } else if (typeof this.payroll.payee_ids === 'number') {
      this.payroll.payee_ids=[this.payroll.payee_ids];
    }
    this.payroll.payees=[];
    for(let a of this.payroll.payee_ids) {
      this.sql.view('Payees','payee_id',a,(data)=>{
        for(let d of data)this.payroll.payees.push(d);
      });
    }
  }
  async add_bank_account() {
    this.set_temp();
    const m = await this.modalController.create({
      component: SelectBankPage, 
      componentProps: {select_multiple: false}
    });
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1){
        this.delete_bank_account();
        this.sql.hapticsImpactLight();
      } else {
        this.payroll.bank_ids = a.data;
        this._c=1;
        this.get_bank_account();
        this.sql.hapticsImpactMedium();
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_bank_account(bank) {
    this.router.navigate(['folder','Account', bank.bank_id]); 
  }
  delete_bank_account() {
    // this.sql.set( 'Payrolls', 'payroll_id', this.payroll.payroll_id, 'banks', null);
    this.payroll.banks=null;
    this.payroll.bank_ids=null;
  }
  get_bank_account() {
    if(!this.payroll.bank_ids)return;
    if(typeof this.payroll.bank_ids === 'string'){
      this.payroll.bank_ids=this.payroll.bank_ids.split(',');
    } else if (typeof this.payroll.bank_ids === 'number') {
      this.payroll.bank_ids=[this.payroll.bank_ids];
    }
    this.payroll.banks=[];
    for(let a of this.payroll.bank_ids) {
      this.sql.view('BankAccounts','bank_id',a,(data)=>{
        for(let d of data)this.payroll.banks.push(d);
      });
    }
  }
  
  async add_deductions() {
    this.set_temp();
    const m = await this.modalController.create({
      component: SelectDeductionPage, 
      componentProps: {select_multiple: true}
    });
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1){
        this.delete_deductions();
        this.sql.hapticsImpactLight();
      } else {
        this.payroll.deduction_ids = a.data;
        this._c=1;
        this.get_deductions();
        this.sql.hapticsImpactMedium();
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_deductions(deduction) {
    this.router.navigate(['folder','Deduction', deduction.deduction_id]); 
  }
  delete_deductions() {
    this.payroll.deductions = null;
    this.payroll.deduction_ids=null;
  }
  get_deductions() {
    if(!this.payroll.deduction_ids)return;
    if(typeof this.payroll.deduction_ids === 'string'){
      this.payroll.deduction_ids=this.payroll.deduction_ids.split(',');
    } else if (typeof this.payroll.deduction_ids === 'number') {
      this.payroll.deduction_ids=[this.payroll.deduction_ids];
    }
    this.payroll.deductions=[];
    for(let a of this.payroll.deduction_ids) {
      this.sql.view('Deductions','deduction_id',a,(data)=>{
        for(let d of data)this.payroll.deductions.push(d);
      });
    }
  }

  async add_paypal() {
    this.set_temp();
    const m = await this.modalController.create({
      component: SelectPaypalPaymentsComponent, 
      componentProps: {select_multiple: false}
    });
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1){
        this.delete_paypal();
        this.sql.hapticsImpactLight();
      } else {
        this.payroll.paypal_ids = a.data;
        this._c=1;
        this.get_paypals();
        this.sql.hapticsImpactMedium();
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_paypal(paypal) {
    this.router.navigate(['folder','Settings', 'PayPal', paypal.paypal_id]); 
  }
  delete_paypal() {
    // this.sql.set( 'Payrolls', 'payroll_id', this.payroll.payroll_id, 'banks', null);
    this.payroll.paypals=null;
    this.payroll.paypal_ids=null;
  }
  get_paypals() {
    if(!this.payroll.paypal_ids)return;
    if(typeof this.payroll.paypal_ids === 'string'){
      this.payroll.paypal_ids=this.payroll.paypal_ids.split(',');
    } else if (typeof this.payroll.paypal_ids === 'number') {
      this.payroll.paypal_ids=[this.payroll.paypal_ids];
    }
    this.payroll.paypals=[];
    for(let a of this.payroll.paypal_ids) {
      this.sql.view('PayPals','paypal_id',a,(data)=>{
        for(let d of data)this.payroll.paypals.push(d);
      });
    }
  }
  async add_taxes() {
    this.set_temp();
    const m = await this.modalController.create({
      component: SelectTaxPage, 
      componentProps: {select_multiple: true}
    });
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1){
        this.delete_taxes();
        this.sql.hapticsImpactLight();
      } else {
        this.payroll.tax_ids = a.data;
        this._c=1;
        this.get_taxes();
        this.sql.hapticsImpactMedium();
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_taxes(tax) {
    this.router.navigate(['folder','Tax', tax.tax_id]); 
  }
  delete_taxes() {
    this.payroll.taxes = null;
    this.payroll.tax_ids=null;
  }
  get_taxes() {
    if(!this.payroll.tax_ids)return;
    if(typeof this.payroll.tax_ids === 'string'){
      this.payroll.tax_ids=this.payroll.tax_ids.split(',');
    } else if (typeof this.payroll.tax_ids === 'number') {
      this.payroll.tax_ids=[this.payroll.tax_ids];
    }
    this.payroll.taxes=[];
    for(let a of this.payroll.tax_ids) {
      this.sql.view('Taxes','tax_id',a,(data)=>{
        for(let d of data)this.payroll.taxes.push(d);
      });
    }
  }
  delete_payroll() {
    if(!this.payroll.payroll_id){
      this.end();
    } else if(confirm(this.sql.word.Delete_Payroll_Confirmation)) {
      this.sql.delete('Payrolls','payroll_id',this.payroll.payroll_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  save(end=true,cb?) {
    for(let s of ['job','employee','payee','bank','schedule'])
      if(this.payroll[s+'_ids']&&typeof this.payroll[s+'_ids']=='object')
        this.payroll[s+'_ids']=this.payroll[s+'_ids'].join(',');
    this.sql.add("Payrolls",this.payroll,(insertId)=>{
      if(!insertId)return
      this._c=0;
      if(!cb && end){
        this.end();
        this.payroll = new NewPayroll('fortnite','both',true);
      }
      if(cb)cb(insertId);
    });
  }
  async openCalendar(end?,from?,to?) {
    if(!from){
      from = new Date();
      from.setFullYear(from.getFullYear()-10);
    }
    if(!to)to=0
    const options:CalendarModalOptions={
      from:from,
      to: to,
      title:end?this.sql.word.End_Date:this.sql.word.Start_Date
    };
    const myCalendar = await this.modalController.create({ 
      component: CalendarModal,
      componentProps: { options }
    });
    myCalendar.present();
    const event: any = await myCalendar.onDidDismiss();
    const date: CalendarResult=event.data;
    if(end) {
      this.payroll.end_date=date.dateObj.toISOString();
    } else {
      this.payroll.start_date=date.dateObj.toISOString();
      if(!this.payroll.end_date) {
        this.payroll.end_date=date.dateObj.toISOString();
      }
    }
    this.sql.remove_temporary('page');
  }
  amount(event) {
    if(!event.target.value||event.target.value==0){
      this.payroll.amount='0.00';
    } else {
      this.payroll.amount=this.sql.amount(event.target.value,null,this.payroll.currency); 
    }
  }
  update() { 
    this.sql.update("Payrolls",this.payroll); 
  }
  end() {
    if (this._c>0) {
      if(confirm(this.sql.word.Discard_Confirmation)) {
        this.payroll = new NewPayroll('fortnite','both',true);
        this.router.navigate(['folder','Payrolls']);
      }
    } else { 
      this.router.navigate(['folder','Payrolls']);
    }
  }
  get_properties(){
    this.get_paypals();
    this.get_bank_account();
    this.get_employees();
    this.get_payees();
    this.get_schedules();
    this.get_deductions();
    this.get_taxes();
  }
  get_payment() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida){
      return;
    } else if(ida=='New'){
      this.get_properties();
    } else {
      this.sql.view('Payrolls','payroll_id',ida,(e)=>{
        if(!e||!e[0]) return;
        var f=Object.entries(e[0]); let n=new NewPayroll('fortnite','both',true);
        for(let t of f)n[t[0]]=t[1];this.payroll=n;
        var r=[];
        for (var i of ['bank','employee','payee','job']) {
          var a = this.route.snapshot.paramMap.get(i+'id');
          if (a)this.payroll[i+'_ids']=a;
        }
        this.get_properties();
        // this.get_jobs();
      });
    }
  }
  set_temp() {
    if (this.payroll.payroll_id) {
      this.sql.set_temporary('page',{location:'Payroll',id:this.payroll.payroll_id});
    } else {
      this.save(false,(id) =>{
        if(!id)return;
        this.payroll.payroll_id=id;
        this.sql.set_temporary('page',{location:'Payroll',id:id});
      });
    }
  }
  run(p) {
    this.save(false,(payroll_id) => {
      this.router.navigate(['folder','Payroll',payroll_id,'Preview']);
    });
  }
  open() {
    this.payroll.currency=this.sql.Currency
    this.payroll.start_date=new Date(Date.now()).toISOString();
    this.payroll.indefinite_payroll_enabled=true;
    this.payroll.payroll_limit=0;
    this.payroll.payment_limit=0;
    this.payroll.total_limit=0;
    this.payroll.payment_method='cheque';
    this.get_payment();
  }
  ngOnInit() {
    this.paypal_currencies=[];
    for(var [key, value] of Object.entries(environment.currencies))if(value.paypal)this.paypal_currencies.push(value);
    this.default_currency = this.sql.Currency;
  }
}