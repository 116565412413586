import { SQLService } from '../../../services/sql.service';
import { PopoverController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'ng-cheques-fax-menu',
  templateUrl: './fax-menu.component.html',
  styleUrls: ['./fax-menu.component.styl']
})
export class FaxMenuComponent implements OnInit {
  constructor(
    public sql: SQLService,
    public popover: PopoverController,
    private router: Router) {
  }
  list:string;
  dismissPopover(e) {
    if (this.popover) this.popover.dismiss(e);
  }
  delete() {
    this.dismissPopover('delete');
  }
  send() {
    this.dismissPopover('send');
  }
  ngOnInit() {
  }
}