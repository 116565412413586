import { Component, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { SQLService } from '../../../services/sql.service';
import { backgrounds, backgrounds_lowrez } from '../../../../environments/logos';
@Component({
  selector: 'app-cheque-green',
  templateUrl: './cheque-green.component.html',
  styleUrls: ['./cheque-green.component.scss'],
})
export class ChequeGreenComponent implements AfterViewInit {
  constructor(private sql: SQLService) { }
  @Output('loaded') loaded = new EventEmitter<boolean>();
  @Input('RenderMICR') RenderMICR = false;
  @Input('ImageMICR') ImageMICR = true;
  @Input('show') show = 'table';
  @Input('cheque') cheque: any;
  @Input('design') design: any;
  @Input('designer') designer: any;
  @Input('rotate') rotate: boolean;
  @Input('borders') borders: boolean;
  @Input('voidcheck') voidcheck: boolean;
  @Input('font') font: any;
  @Input('fiat') fiat: boolean;
  @Input('color') color: any;
  @Input('micr') micr: any;
  micr_top=false;
  micr_right=false;
  micr_bottom='7mm';
  micr_left='12mm';
  @Input('secret') secret = '';
  @Input('scale') scale =1;
  @Input('fold_line') fold_line: boolean;
  @Input('template') template: string;
  @Input('monochrome') monochrome: boolean;
  @Input('gradients') gradients: boolean;
  @Input('margin_bottom') bottom = '0mm';
  @Input('margin_top') top = '0mm';
  @Input('fontsize') fontsize: boolean;
  @Input('layers') layers: any;
  @Input('translations') translations = this.sql.word;
  @Input('bold') bold: boolean;
  @Input('italic') italic: boolean;
  @Input('show_cheque_number') show_cheque_number: boolean = true;
  @Input('show_cheque_date') show_cheque_date: boolean = true;
  @Input('show_micr') show_micr: boolean = true;
  @Input('cheque_background_image_repeat') cheque_background_image_repeat = false;
  @Input('background_position') background_position: string;
  @Input('cheque_text_color') cheque_text_color: string;
  @Input('cheque_background_image') cheque_background_image: any;
  @Input('cheque_logo_image') cheque_logo_image: any;
  @Input('cheque_header_image') cheque_header_image: any;
  @Input('cheque_bank_image') cheque_bank_image: any;
  @Input('doc_color') doc_color: string;
  bg_image: string;
  loaded_component() {this.loaded.emit(true);}
  ngAfterViewInit() {
    this.design["height"]='69mm';
    this.design["date"]["top"]='48mm';
    this.design["date"]["right"]='25.5mm';
    this.design["date"]["individual"]=false;
    this.design["date"]["alternative"]=false;
    this.design["date"]["alternative_2"]=false;
    this.design["date_label"]["display"]=false;
    this.design["payline"]["prefix"]=false;
    this.design["payline"]["borders"]=false;
    this.design["payline"]["top"]='125px';
    this.design["payline"]["right"]='27px';
    this.design["payline"]["bottom"]='4px';
    this.design["payline"]["left"]='35mm';
    this.design["printed_id"]["top"]='6mm';
    this.design["printed_id"]["right"]='9mm';
    this.design["payment_name"]["left"]="30px";
    this.design["payment_name"]["bottom"]="5.2px";
    this.design["bank_account_holder"]["left"]="38mm";
    this.design["bank_account_holder"]["top"]="20.8mm";
    this.design["written_amount"]["top"]='41.4mm';
    this.design["written_amount"]["right"]=false;
    this.design["written_amount"]["bottom"]='auto';
    this.design["written_amount"]["left"]='13.8mm';
    this.design["written_amount"]["width"]='127mm';
    this.design["written_amount"]["index"]=false;
    this.design["written_amount_line"]["borders"]=false;
    this.design["written_amount_line"]["overflow"]=true;
    this.design["written_amount_line"]["top"]='0px';
    this.design["written_amount_line"]["right"]='0px';
    this.design["written_amount_line"]["bottom"]='0px';
    this.design["written_amount_line"]["left"]='0px';
    this.design["written_amount_line"]["width"]='127mm';
    this.design["written_amount_line2"]["top"]='4mm';
    this.design["written_amount_line2"]["right"]='0mm';
    this.design["written_amount_line2"]["bottom"]='0mm';
    this.design["written_amount_line2"]["left"]='0mm';
    this.design["written_amount_line2"]["width"]='127mm';
    this.design["multiline_written_amount_chars"]=60;
    this.design["amounts"]["symbol"]=false;
    this.design["bank_image"]["top"]="25.4mm";
    this.design["bank_image"]["left"]="15.5mm";
    this.design["amounts"]["top"]="22.7mm";
    this.design["amounts"]["right"]="8.5mm";
    this.design["amounts"]["fontsize"]="16px";
    this.design["currency_name"]["top"]="27.4mm";
    this.design["currency_name"]["left"]="13.2mm";
    this.design["address_data"]["top"]="30.7mm";
    this.design["address_data"]["left"]="13.4mm";
    this.design["amount_number"]["borders"]=false;
    this.design["amount_number"]["background"]=false;
    this.design["amount_number"]["top"]="4.5mm";
    this.design["amount_number"]["right"]="auto";
    this.design["amount_number"]["left"]="2.4mm";
    this.design["memo"]["label"]=true;
    this.design["memo"]["border"]=false;
    this.design["memo"]["left"]='auto';
    this.design["memo"]["right"]='0mm';
    this.design["memo"]["bottom"]='6.3mm';
    this.design["memo"]["width"]='202px';
    this.design["public_image"]["top"]='4.3mm';
    this.design["public_image"]["right"]='22mm';
    this.design["public_image"]["width"]='77';
    this.design["public_image"]["zindex"]='1';
    this.design["key_image"]["width"]='77';
    this.design["key_image"]["top"]='5.7mm';
    this.design["key_image"]["left"]='9.3mm';
    this.design["key_image"]["zindex"]='1';
    this.design["signature"]["container_width"]='58mm';
    this.design["signature"]["width"]='180px';
    this.design["signature"]["border"]=false;
    this.design["signature"]["label"]=false;
    this.design["signature"]["right"]='auto';
    this.design["signature"]["bottom"]='18.1mm';
    this.design["signature"]["left"]='45mm';
    this.design["micr"]["top"]='auto';
    this.design["micr"]["right"]='auto';
    this.design["micr"]["bottom"]='7mm';
    this.design["micr"]["left"]='12mm';
    this.design["micr"]["background"]=true;
    if(this.sql.model=='web') {
      var img = "green";
      this.sql.load_background(img,(base64Img)=>{
        this.bg_image=base64Img;
      },this.sql.production);
    } else {
      this.bg_image = this.sql.production?backgrounds.green:backgrounds_lowrez.green;
    }
  }
}