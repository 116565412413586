import { SQLService } from '../../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { PopoverController} from '@ionic/angular';

import { ScanAdministratiorComponent } from './scan-administrator/scan-administrator.component';
import { NewQRAdministratiorComponent } from './new-qr-administrator/new-qr-administrator.component';
import { AdministratiorMenuComponent } from './administrator-menu/administrator-menu.component';
@Component({
  selector: 'ng-cheques-administrators',
  templateUrl: './administrators.component.html',
  styleUrls: ['./administrators.component.styl']
})
export class AdministratorsComponent implements OnInit {
  constructor(
    public sql: SQLService,
    public popoverController: PopoverController,
    private modalController: ModalController,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_administrators();
    });
  }
  count = -1;
  admins = [];
  search:string;
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:AdministratiorMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='enable')this.enable(item);
      if(data=='disable')this.disable(item);
      if(data=='delete')this.delete_administrator(item, false);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  filter(e) { this.search=e.target.value; }
  add() {this.router.navigate(['folder','Administrator','New']);}
  get_administrators(event?) {
    this.sql.fetch('Administrators',(e)=>{
      this.admins=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  edit_administrator(admin, item?) {
    console.log(admin);
    this.router.navigate(['folder','Administrator',admin.admin_id]);
  }
  delete_administrator(admin, item) {
    if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('Administrators','admin_id',admin.admin_id);
      this.get_administrators();
    } else { item.closeSlidingItems(); }
  }
  disable(admin,i?){
    this.sql.set('Administrators','admin_id',admin.admin_id,'enabled',0,(res)=>{
      admin.enabled = false;
      if(i)i.closeSlidingItems();
    });
  }
  enable(admin,i?){
    this.sql.set('Administrators','admin_id',admin.admin_id,'enabled',1,(res)=>{
      admin.enabled = true;
      if(i)i.closeSlidingItems();
    });
  }
  async scan() {
    const m = await this.modalController.create({component: ScanAdministratiorComponent, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data){
      if(a.data=='add_qr')this.add_qr();
      return;
    }
  }
  async add_qr() {
    const m = await this.modalController.create({component: NewQRAdministratiorComponent, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data){
      if(a.data=='scan')this.scan();
      return;
    }
  }
  ngOnInit() {
    this.get_administrators();
  }
}