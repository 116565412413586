import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'pro'
})
export class ProPipe implements PipeTransform {
  transform(value: any, args?: any): any {
			let values = [];
			for (var i = 0; i < value.length; i++) {
				if(value[i].pro) values.push(value[i]);
			}
			return values;
  }
}
