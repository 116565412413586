export class NewSignature {
	constructor(
		public signature_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public name?: string,
		public description?: string,
		public date?: any,
		public image?: any,
		public enabled?: boolean,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Signatures = '\
	CREATE TABLE IF NOT EXISTS "Signatures" (\
		signature_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		name TEXT,\
		description TEXT,\
		date TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		image TEXT,\
		enabled INTEGER DEFAULT 0,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';