import { Component, Input, ElementRef,ViewChild,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SQLService } from '../../../../services/sql.service';
// import SIPml from 'ecmascript-webrtc-sipml';
@Component({
  selector: 'call',
  templateUrl: './call.component.html',
  styleUrls: ['./call.component.styl'],
})
export class CallComponent implements OnInit {
  constructor(
    private router: Router,
    private sql: SQLService,
  ) {
    this.ringtone = document.getElementById("ringtone");
    this.audioRemote = document.getElementById("audio_remote");
    this.ringbacktone = document.getElementById("ringbacktone");
  }

  @Input('number') number = '';
  @Input('calling') calling = false;

  realm='sip.janglehost.com';
  host='sip:401@sip.janglehost.com';
  username = '401';
  password='2c3a0cdbbb70f63b7b5e592320cd8466';
  socket='wss://sip.janglehost.com:8089/ws';
  port ='5060';

  verbose = true;
  p=0;
  bal=0;
  price=0;
  _p:string;
  ringtone:any;
  ringbacktone:any;
  sipStack:any;
  audioRemote:any;
  oConfigCall:any;
  oSipSessionRegister:any;
  session:any;
  service: any;
  debug=false;
  default_type='call-audio';

  Answer() { this.session.accept(this.oConfigCall); };

  Call(number, type=this.default_type) { // type = ['call-audio', 'call-audiovideo', 'call-screenshare']
    this.session = this.sipStack.newSession(type,this.oConfigCall);
    this.session.call(number);
  }

  Dial() { if(this.number=='') return;
    this.session=this.sipStack.newSession(this.default_type,this.oConfigCall);
    this.session.call(this.number);
  };

  Hangup() { this.session.hangup(); };

  Reject() { this.session.reject(); };
  
  SIP_Register() {
    // try {
    //   // enable notifications if not already done
    //   // if (window.webkitNotifications && window.webkitNotifications.checkPermission() != 0) {
    //   //     window.webkitNotifications.requestPermission();
    //   // }
    //   // Notification.requestPermission();
    //   // save credentials
    //   //saveCredentials();
    //   SIPml.setDebugLevel((this.debug) ? "error" : "info");
    //   this.sipStack = new SIPml.Stack({
    //     realm: this.realm,
    //     impi: this.username,
    //     impu: this.host,
    //     password: this.password,
    //     websocket_proxy_url: this.socket,
    //     outbound_proxy_url: 'udp://'+this.realm+':'+this.port,
    //     events_listener: { events: '*', listener: this.SIP_Event_Listener }
    //   });
    //   if (this.sipStack.start()!=0)console.error('SIP STACK STARTUP FAILURE.');
    // } catch (e) {console.error("SIP STARTUP",e);}
  }
  SIP_Event_Listener = (e) => {
    if(e.type == 'started'){ if(this.verbose)console.log('SIP NOW LISTENING','REGISTERED',e);
      this.oSipSessionRegister = this.sipStack.newSession('register', {
          expires: 200,
          events_listener: { events: '*', listener: this.onsipeventsession },
          sip_caps: [
                { name: '+g.oma.sip-im', value: null },
                //{ name: '+sip.ice' }, // rfc5768: FIXME doesn't work with Polycom TelePresence
                { name: '+audio', value: null },
                { name: 'language', value: '\"en,fr\"' }
          ]
      });
      this.oSipSessionRegister.register();
      if(this.calling)this.Dial();
    } else if(e.type == 'i_new_message'){ if(this.verbose)console.log('NEW SIP/SMS MESSAGE',e);
      this.session = e.newSession; 
      console.log('SMS-content = ' + e.getContentString() + ' and SMS-content-type = ' + e.getContentType());
    } else if(e.type == 'i_new_call'){ if(this.verbose)console.log('INCOMING TELEPHONE CALL','--- PHONE RINGING ---','CALL INFO:',e);
      this.session = e.newSession;
    }
  }
  Transfer() {
    if (this.session) {
      var s_destination = prompt('Enter destination number', '');
      if (this.session.transfer(s_destination) != 0) {
          console.log('<i>Call transfer failed</i>');
          return;
      }
      console.log('<i>Transfering the call...</i>');
    }
  }
  onsipeventsession(e){
    if(this.verbose) console.log('SIP EVENT',e);
  }
  reset() {  this.p=0;this._p='';this.number=''; }
  ngOnInit() {
     this.SIP_Register();
  }
  SIP_Unregister(){
    if(this.sipStack) this.sipStack.stop();
  }
}