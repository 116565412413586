import { SQLService } from '../../services/sql.service';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-keys',
  templateUrl: './keys.component.html',
  styleUrls: ['./keys.component.scss'],
})
export class KeysComponent implements OnInit {
  constructor(
    public sql: SQLService,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) {
        if(!this.sql.account_keys)this.end();
        this.get_keys();
      }
    });
  }
  scan(g?) {


  }
  keys=[];
  count:number
  search:string;
  get_keys(event?) { 
    this.sql.fetch('Keys',(e)=>{
      this.keys=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  edit_key(key, item?) {
    this.router.navigate(['folder','Moderator',key.key_id]);
  }
  delete_key(key, item) {
    if(confirm(this.sql.word.Discard_Confirmation)) {
      this.sql.delete('Key','key_id',key.key_id);
      this.get_keys();
    } else { item.closeSlidingItems(); }
  }
  add() {

  }  
  end() {this.router.navigate(['folder','Dashboard'])};
  ngOnInit() {
    this.get_keys();

  }


}
