export class NewPrintJob {
	constructor(
		public printjob_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public printer_id?: number,
		public name?: string,
		public printer?: string,
		public printer_options?: string,
		public order?: string,
		public status?: string,
		public image?: any,
		public pages?: number,
		public copies?: number,
		public cheque?: any,
		public invoice?: any,
		public printed?: boolean,
		public enabled?: boolean,
		public modified?: any,
		public created?: any
	){}
}
export const Create_PrintJobs = '\
	CREATE TABLE IF NOT EXISTS "PrintJobs" (\
		printjob_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER,\
		mod_id INTEGER,\
		printer_id INTEGER,\
		name STRING,\
		printer STRING,\
		printer_options STRING,\
		printer_order INTEGER DEFAULT 0,\
		status STRING,\
		image STRING,\
		pages STRING,\
		copies STRING,\
		cheque STRING,\
		invoice STRING,\
		printed INTEGER DEFAULT 0,\
		enabled INTEGER DEFAULT 0,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';