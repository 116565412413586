import { SQLService } from '../../services/sql.service';
import { Flip } from '../../app.animations';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-select-address',
  templateUrl: './select-address.page.html',
  styleUrls: ['./select-address.page.styl'],
  animations: [ Flip ]
})
export class SelectAddressPage implements OnInit {
  constructor(
    private router: Router,
    public sql: SQLService,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    private location: Location
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_addresses();
    });
  }
  count=0;
  addresses = [];
  output = new EventEmitter();
  get_addresses(event?) {
    this.sql.fetch('Addresses',(e)=>{
      this.addresses=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  select(e) {
    this.navCtrl.dismiss(e.address_id);
  }
  add() {
    this.sql.count('Addresses',(c)=>{
      if(this.sql.Trial && this.sql.Trial_Addresses!=-1 && c>=this.sql.Trial_Addresses) {
        this.sql.show_purhchase_modal();
      } else {this.router.navigate(['folder','Address','New']);}
    });
    this.navCtrl.dismiss();
  }
  select_none() {this.navCtrl.dismiss(-1);}
  off() {
    this.navCtrl.dismiss();
  }
  ngOnInit() {
    this.get_addresses();
  }
}