import { SQLService } from '../../services/sql.service';
import { Flip } from '../../app.animations';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController, NavParams } from '@ionic/angular';
@Component({
  selector: 'app-select-income',
  templateUrl: './select-income.page.html',
  styleUrls: ['./select-income.page.scss'],
})
export class SelectIncomeComponent implements OnInit {
  constructor(
    private router: Router,
    public sql: SQLService,
    private params: NavParams,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    private location: Location
  ){
    var s = params.get('selected');
    if(s)this.selected = s.split(',');
    this.multiple=params.get('select_multiple');
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd)this.get_incomes();
    });
  }
  count=0;
  incomes = [];
  selected =[];
  multiple:boolean;
  output = new EventEmitter();
  ngOnInit() {
    this.get_incomes();
  }
  get_incomes(event?) {
    this.sql.fetch('Incomes',(e)=>{
      this.incomes=e;
      this.count=e.length;
      setTimeout(()=>{if(event)event.target.complete();},1234);
    });
  }
  select(e) {
    if(this.multiple){
      this.toggle_item(e);
    } else {
      this.navCtrl.dismiss(e.employee_id);
    }
  }
  done_multiple() {
    this.navCtrl.dismiss(this.selected.join(','));
  }
  toggle_item(item) {
    if( this.selected.includes(item.employee_id) ) {
      this.deselect_item(item);
    } else {
      this.select_item(item);
    }
  }
  select_item(item) {
    this.selected.push(item.employee_id);
  }
  deselect_item(item) {
    var new_selection=[];
    for(var i = 0; i < this.selected.length; ++i){
      if(this.selected[i]!=item.employee_id){
        new_selection.push(this.selected[i]);
      }
    }
    this.selected = new_selection;
  }
  select_all() {
    var new_selection=[];
    for (var i = 0; i < this.selected.length; ++i) {
      new_selection.push(this.selected[i].employee_id);
    }
    this.selected=new_selection;
  }
  deselect_all() {
    this.selected = []; 
  }
  add() {
    this.router.navigate(['folder','Income','New']);
    this.navCtrl.dismiss();
  }
  off() {
    // this.location.back();
    if(this.multiple){
      this.done_multiple();
    } else {
      this.navCtrl.dismiss();
    }
  }
}