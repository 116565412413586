export class NewPayment {
	constructor(
		public payment_id?: number,
		public admin_id?: number,
		public payroll_id?: number,
		public mod_id?: number,
		public cheque_ids?: any,
		public employee_ids?: any,
		public payee_ids?: any,
		public address_ids?: any,
		public bank_ids?: any,
		public deduction_ids?: string,
		public leave_ids?: string,
		public schedule_ids?: number,
		public timesheet_ids?: string,
		public paypal_ids?: any,
		public vacation_ids?: string,
		public tax_ids?: string,
		public amount?: any,
		public currency?: string,
		public paid?: boolean,
		public fullname?: string,
		public type?: string,
		public title?: string,
		public email?: string,
		public employees?: any,
		public addresses?: any,
		public paypals?: any,
		public payees?: any,
		public banks?: any,
		public date?: any,
		public time?: any,
		public entries?: any,
		public description?: string,
		public printed_memo?: string,
		public auto_send?: boolean,
		public enabled?: boolean,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Payments = '\
	CREATE TABLE IF NOT EXISTS "Payments" (\
		payment_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		payroll_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		cheque_ids TEXT,\
		employee_ids TEXT,\
		payee_ids TEXT,\
		address_ids TEXT,\
		bank_ids TEXT,\
		deduction_ids TEXT,\
		leave_ids TEXT,\
		schedule_ids TEXT,\
		timesheet_ids TEXT,\
		paypal_ids TEXT,\
		vacation_ids TEXT,\
		tax_ids TEXT,\
		amount VARCHAR(100),\
		currency VARCHAR(10),\
		paid INTEGER DEFAULT 0,\
		fullname TEXT,\
		type TEXT DEFAULT "cheque",\
		title TEXT,\
		email TEXT,\
		employees TEXT,\
		addresses TEXT,\
		paypals TEXT,\
		payees TEXT,\
		banks TEXT,\
		date TEXT,\
		time TEXT,\
		entries TEXT,\
		description TEXT,\
		printed_memo TEXT,\
		auto_send INTEGER DEFAULT 0,\
		enabled INTEGER DEFAULT 0,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';