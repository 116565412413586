import { Component, OnInit } from '@angular/core';
import { NewTimesheetEntry } from '../../../models/timesheetentries';
import { ModalController, Platform } from '@ionic/angular';
import { SQLService } from '../../../services/sql.service';
import { TimesheetService } from '../../../services/timesheet.service';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-new-timesheet-entry',
  templateUrl: './new-timesheet-entry.component.html',
  styleUrls: ['./new-timesheet-entry.component.styl'],
})
export class NewTimesheetEntryComponent implements OnInit {
  constructor(
    private router: Router,
    private sql: SQLService,
    private ts: TimesheetService,
    private route: ActivatedRoute,
    private modalController: ModalController
  ) {
    this.router.events.subscribe((e) => {
      if(e instanceof NavigationEnd) this.open();
    });
  }
  _c=0;
  timesheetentry = new NewTimesheetEntry();
  not_updated = true;
  get_timesheet_entries() {
    this.timesheetentry = new NewTimesheetEntry();
    var ida = this.route.snapshot.paramMap.get("id");
    if (!ida) {
      return;
    } else {
      this.sql.view('TimesheetEntry','timesheet_entry_id',ida,(e)=>{
        var f=Object.entries(e[0]); let n = new NewTimesheetEntry();
        for(let t of f)n[t[0]]=t[1];
        // n.start_date=new Date(Date.now()).toISOString();
        // n.start_time=new Date(Date.now()).toISOString();
        // n.end_date=new Date(Date.now()).toISOString();
        this.timesheetentry=n;
        console.log(this.timesheetentry);
      });
    }
  }
  set_timer() {

  }
  open() {
    this.get_timesheet_entries();
  }
  save(e=true) {
    if(!this.timesheetentry.timesheet_entry_id) {
      this.sql.add("TimesheetEntry",this.timesheetentry,(id)=>{
        this.not_updated=false;
        this.timesheetentry.timesheet_id=id;
        this._c=0;
        if(e)this.end();
      });
    } else {
      this.sql.updateObj("TimesheetEntry",this.timesheetentry,(insertId)=>{
        setTimeout(()=>{
          insertId = this.timesheetentry.timesheet_id;
          this._c=0;
          if(e)this.end();
        },500);
      });
    }
  }
  end() {
    var ida = this.route.snapshot.paramMap.get("id");
    var idb = this.route.snapshot.paramMap.get("sd");
    this.router.navigate(['folder','Timesheet','Entries',idb]);
  }
  ngOnInit() {
  }
}