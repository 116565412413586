import { SQLService } from '../../../../../services/sql.service';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router,NavigationEnd,ActivatedRoute } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { Location } from '@angular/common';
@Component({
  selector: 'app-preview-import-fields',
  templateUrl: './preview-field.component.html',
  styleUrls: ['./preview-field.component.styl']
})
export class PreviewImportFieldsPage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private params: NavParams,
    public sql: SQLService,
    private navCtrl: ModalController
  ) {
    this.data = this.params.get('data');
    this.router.events.subscribe((eve) => {
    });
  }
  data: any;
  ngOnInit() {
    this.data = this.params.get('data');
  }
  off() {
    this.navCtrl.dismiss();
  }
}