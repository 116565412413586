import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { SQLService } from '../../../services/sql.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.styl'],
})
export class LoginComponent implements OnInit, OnDestroy {
  login = new Login();
  status = 'ready';
  constructor( private sql: SQLService) { }
  @Output('login') _login = new EventEmitter();
  ngOnDestroy() {
    this.status = 'ready';
  }
  ngOnInit() {
    this.status = 'ready';
  }
  login_now() {
    //this.status = 'loading';
    this.sql.login(this.login.email,this.login.pass,(result)=>{
      if(result==200){
        this.status = 'ok';
        this._login.emit(1);
      } else if (result==500) {
        this.status = 'error';
      }
    });
  }
  sign_up() {
    this.sql.signup(this.login.email,this.login.pass,(result)=>{
      if(result==200){
        this.status = 'ok';
        this._login.emit(1);
      } else if (result==500) {
        this.status = 'error';
      }
    });
  }
}
export class Login {
  constructor(
    public email?: string,
    public pass?: string,
  ) {}
}