import { SQLService } from './sql.service';
import { NewCheque } from '../models/cheque';
import { NewPayment } from '../models/payment';
import { environment } from '../../environments/environment';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { Globalization } from '@ionic-native/globalization/ngx';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { ApplePay } from '@ionic-native/apple-pay';
import { InAppPurchase2, IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import * as converter from 'number-to-words';
@Injectable()
export class PaymentsService {
	constructor(
	private http: HttpClient,
	private platform: Platform,
	private store: InAppPurchase2,
	public sql: SQLService ) {
		platform.ready().then(()=>{this.Setup();});
	}
	Setup() {
		if(this.platform.is('capacitor')) {
			// if(this.sql.verbose) this.store.verbosity = this.store.DEBUG;
			// this.store.ready(() => {\
			// this.sql.more_verbose=true;
			// this.sql.verbose=true;
				for(let p of this.sql.products){
					var type=this.store.PAID_SUBSCRIPTION;
					if(p.mode=="subscription")type=this.store.PAID_SUBSCRIPTION;
					if(p.mode=="premium")type=this.store.NON_CONSUMABLE;
					if(p.mode=="print")type=this.store.CONSUMABLE;
					this.store.register({ id: this.flip(p.id), type: type });
					this.store.when(this.flip(p.id))
						.registered((product: IAPProduct)=>{
							if(this.sql.more_verbose)console.log('In-App_Purchase: Registered', product);
						}).approved((product: IAPProduct)=>{
							if(this.sql.verbose)console.log('In-App_Purchase: Approved', product);
							product.verify()
							this.sql.has_ordered(product, (purchase_id)=>{
								var id = (purchase_id&&product.type!='print') ? purchase_id : product.id;
								this.product_sold(id);
							});
						}).verified((product: IAPProduct)=>{
							if(this.sql.verbose)console.log('In-App_Purchase: Verified', product);
							product.finish()
							this.sql.has_ordered(product, (order_id)=>{
								if(order_id)this.sql.modify_order(order_id, true, (result)=>{
									product.finish();
								});
							});
						}).unverified((product: IAPProduct)=>{
							if(this.sql.more_verbose)console.log('In-App_Purchase: Receipt verification failed.', product);
							this.sql.has_ordered(this.flipback(product), (order_id)=>{
								if(order_id)this.sql.modify_order(order_id, false, (result)=>{
									product.finish();
								});
							});
						}).updated((product: IAPProduct)=>{
							if(this.sql.more_verbose)console.log('In-App_Purchase: Updated', product);
							if (product.owned) {
								// Begin subscription
								this.sql.has_ordered(this.flipback(product), (order_id)=>{
									if(order_id)this.sql.modify_order(order_id, true, (result)=>{
										product.finish();
									});
								});
							} else {
								// Remove subscription
								this.sql.has_ordered(this.flipback(product), (order_id)=>{
									if(order_id)this.sql.modify_order(order_id, false, (result)=>{
										product.finish();
									});
								});
							}
						}).expired((product: IAPProduct)=>{
							if(this.sql.more_verbose)console.log('Updated', product);
								this.sql.has_ordered(this.flipback(product), (order_id)=>{
									if(order_id)this.sql.modify_order(order_id, false, (result)=>{
										product.finish();
									});
								});
						}).owned((product: IAPProduct)=>{
							if(this.sql.more_verbose)console.log('Owned', product);
							this.sql.has_ordered(this.flipback(product), (order_id)=>{
								if(order_id){
									this.sql.modify_order(order_id, true, (result)=>{
										product.finish();
									});
								} else {this.product_sold(order_id);}
							});
						}).downloaded((product: IAPProduct)=>{
							if(this.sql.more_verbose)console.log('Downloaded product', product);
						}).cancelled((product: IAPProduct)=>{
							if(this.sql.more_verbose)console.log('Cancelled', product);
							this.sql.has_ordered(this.flipback(product), (order_id)=>{
								if(order_id)this.sql.modify_order(order_id, false, (result)=>{
									product.finish();
								});
							});
						}).refunded((product: IAPProduct)=>{
							if(this.sql.more_verbose)console.log('Refunded', product);
							var v = "DELETE FROM Orders WHERE product = '"+product+"'";
							this.sql.b_query(v,(item)=>{
								this.sql.setup_orders(()=>{
									product.finish();
								});
							});
						});
				}
				this.store.error((err)=>{
					if(this.sql.more_verbose)console.error('In-App Purchase Store Error',err);
				});
				this.store.ready(()=>{
					if(this.sql.more_verbose)console.log('Store is ready');
				});
				this.store.refresh();
				this.ready = true;
			// });
		} else if (this.platform.is('electron')) {

		} else {

		}
	}
	Updated(product) {
		if(this.sql.verbose)console.log('Store: Product Updated', product);
		var state = true; // true: activated, false: deactivated
		this.sql.has_ordered(product, (order_id)=>{
			if(order_id)this.sql.modify_order(order_id, state, (result)=>{
				product.finish();
			});
		});
		product.finish();
	}
	Owned(product) {
		if(this.sql.verbose)console.log('Store: Product Owned', product);
		this.sql.has_ordered(product, (order_id)=>{
			if(order_id)this.sql.activate_order(order_id, (result)=>{
				product.finish();
			});
		});
	}
	Approved(product) {
		if(this.sql.verbose)console.log('Store: Product Approved', product);
		this.product_sold(product);
		product.finish();
	}
	ready = false;
	public verbose = environment.verbose;
	public Currency_Rates = environment.currencies;
	public Places = 2;
	new_paypal_payout(paypal_api,payments) {
		var payment_items = [];
		for(let payment_details of payments){
			payment_items.push({
				"recipient_type": payment_details.type,
				"amount": {
					"value": payment_details.amount,
					"currency": payment_details.currency
				},
				"note": paypal_api.payment_note,
				"sender_item_id": payment_details.payment_id,
				"receiver": payment_details.email,
				"alternate_notification_method": {
					"phone": {
						"country_code": payment_details.phone_country_code,
						"national_number": payment_details.phone_number
					}
				},
				"notification_language": payment_details.language?payment_details.language:'en-US'
			});
		}
		let payment = {
			"sender_batch_header": {
				"sender_batch_id": this.sql.selected_company+this.sql.admin_id+'PP'+paypal_api.paypal_id,
				"email_subject": paypal_api.email_subject,
				"email_message": paypal_api.email_message
			},
			"items":payment_items
		};
		if(this.sql.verbose)console.log('HTTP NEW PAYPAL PAYOUT',environment.paypal+'/v1/payments/payouts');
		this.sql.http_post(environment.paypal+'/v1/payments/payouts/',payment,{
			"Content-Type":"application/json",
			"Authorization":null
		},(e)=>{
			if(this.sql.more_verbose)console.log('HTTP PAYPAL NEW PAYPAL PAYOUT',e);
		});
	}
	show_paypal_payouts(payout_batch_id){
		this.sql.http_get(environment.paypal+'v1/payments/payouts/'+payout_batch_id+'/',{},{
			"Content-Type":"application/json",
			"Authorization":null
		},(e)=>{
			if(this.sql.verbose)console.log('HTTP PAYPAL SHOW PAYOUTS',e);
		});
	}
	show_payout(item_id){
		this.sql.http_post(environment.paypal+'v1/payments/payouts-item/'+item_id+'/',{},{
			"Content-Type":"application/json",
			"Authorization":null
		},(e)=>{
			if(this.sql.more_verbose)console.log('HTTP PAYPAL SHOW PAYOUT',e);
		});
	}
	cancel_payout(id){
		this.sql.http_post(environment.paypal+'v1/payments/payouts-item/'+id+'/cancel/',{},{
			"Content-Type":"application/json",
			"Authorization":null
		},(e)=>{
			if(this.sql.more_verbose)console.log('HTTP PAYPAL CANCEL PAYOUT',e);
		});
	}
	generate_batch_payout(paypal_id) {
		var payout = '', total = 0, payments =[], i = 0, currency = '', epoch = Math.floor(Date.now() /1000), name = '', paypal;
		this.sql.view('PayPals','paypal_id',paypal_id,(e)=>{if(e)paypal=e;});
		for(let p of payments) {
			payout = payout+'PAYOUT,'+p.email+','+p.amount+','+p.currency+','+p.payment_id+','+p.payment_note+'\n';
			total  = total+p.amount; i++;
		}
		payout = 'PAYOUT_SUMMARY,'+total+','+currency+','+i+','+paypal.email_subject+','+paypal.email_message+'\n'+payout;
		var file = 'pp_payouts_'+epoch+'_'+name+'.csv';
		var data = new Blob([String.fromCharCode(0xFEFF), payout], {type: 'text/csv;charset=UTF-8'});
		var url = URL.createObjectURL(data);
		if(this.sql.more_verbose)console.log('Batch Payout',file, url);
	}
	purchase(Product,Callback?) {
		if(this.platform.is('capacitor')) {
			if(this.sql.verbose)console.log('Purchase Product: ',Product);
			var result = this.store.order(this.flip(Product.id));//.then((result)=>{
			if(this.sql.verbose)console.log('Google Purchase: ',result);
			this.sql.has_ordered(Product.id, (purchase_id)=>{
				var id = (purchase_id&&Product.type!='print') ? purchase_id : Product.id;
				this.product_sold(id, (purchase)=>{
					if(this.sql.verbose)console.log('Google purchase complete',result,purchase_id,purchase,Product);
					if(Callback)Callback(purchase?true:false);
				});
			});

			// }).error((err)=>{
			// 	console.error('Purchase Error: ',err);
			// 	this.sql.hapticsError();
			// 	if(err)alert(err);
			// 	if(Callback)Callback(false);	
			// });
		} else if (this.platform.is('electron')) {
			console.error('Purchases not setup for platform: Electron.');
		} else {
			console.error('Purchases not setup for platform: Web.');
		}
	}
	restore() {
		this.store.refresh();
	}
	override(product, cb?) {
		this.product_sold(product,(p)=>{
			if(cb)cb(p);
		});
	}
	product_sold(product_id, cb?) {
		this.sql.create_order(product_id,(p)=>{
			if(cb)cb(p);
		});
	}
	subscription_sold(product?) {
		if(this.sql.verbose)console.log('subscription sold ',product);
	}
	mail_black_sold(product?) {
		if(this.sql.verbose)console.log('Mail black sold ',product);
	}
	mail_color_sold(product?) {
		if(this.sql.verbose)console.log('Mail color sold ',product);
	}
	apple_purchase(selected,cb?) {
		if(this.sql.verbose)console.log('Apple Purchase', selected);
		const order:any = {
			items: [{label: this.flip_name(selected.id), amount: selected.price}],
			supportedNetworks: ['visa', 'masterCard', 'discover'],
			merchantCapabilities: ['3ds', 'debit', 'credit'],          
			merchantIdentifier: environment.merchantIdentifier,
			currencyCode: selected.currency,
			countryCode: this.sql.Country,
			billingAddressRequirement: 'none',
			shippingAddressRequirement: 'none',
			shippingType: 'service'
		};
		this.sql.hapticsImpactHeavy();
		ApplePay.makePaymentRequest(order).then((PaymentResponse) => {
			if(this.sql.verbose)console.log('Payment Response',PaymentResponse);
			if(this.sql.verbose)console.log('Payment Data',PaymentResponse.paymentData);
			this.sql.apple_purchase_receipt_check(PaymentResponse.paymentData,(ReceiptCheck)=>{
				if(ReceiptCheck.valid==true) {
					if(this.sql.verbose)console.log('Apple Purchase','Recepit Check Valid');
					ApplePay.completeLastTransaction('success').then((TransactionResponse)=>{
						if(this.sql.verbose)console.log('Apple Pay', TransactionResponse);
						this.sql.has_ordered(selected.id, (purchase_id)=>{
							var id = (purchase_id&&selected.type!='print') ? purchase_id : selected.id;
							this.product_sold(id,(purchase)=>{
								this.sql.hapticsSuccess();
								if(this.sql.verbose)console.log('Apple purchase complete',TransactionResponse,purchase_id,purchase,selected);
								if(cb)cb(purchase?true:false);
							});
						});
					});
				} else {
					ApplePay.completeLastTransaction('failure');
					if(this.sql.verbose)console.log('Apple Pay Receipt Error', ReceiptCheck);
					if(cb)cb(false);
				}
			})
		}).catch((message) => {
			console.error('Payment Error',message);
			this.sql.hapticsError();
			if(cb)cb(false);
		});
	}
	flip(p){
		var pid=p;
		if(this.platform.is("android")){
			if(pid=='chequeslicence_2mo')pid='chequeslicence2mo';
			if(pid=='chequeslicence_3mo')pid='chequeslicence3mo';
			if(pid=='chequeslicence_6mo')pid='chequeslicence6mo';
			if(pid=='chequeslicence_12mo')pid='chequeslicence12mo';
		}
		return pid;
	}
	flipback(p){
		var pid=p;
		if(this.platform.is("android")){
			if(pid=='chequeslicence2mo')pid='chequeslicence_2mo';
			if(pid=='chequeslicence3mo')pid='chequeslicence_3mo';
			if(pid=='chequeslicence6mo')pid='chequeslicence_6mo';
			if(pid=='chequeslicence12mo')pid='chequeslicence_12mo';
		}
		return pid;
	}
	flip_name(id) {
      if(id=='cheques_print_1') return this.sql.word.Single_Print;
      if(id=='cheques_print_2') return this.sql.word.Two_Prints;
      if(id=='cheques_print_5') return this.sql.word.Five_Prints;
      if(id=='cheques_print_10') return this.sql.word.Ten_Prints;
      if(id=='cheques_print_15') return this.sql.word.Fifteen_Prints;
      if(id=='cheques_print_20') return this.sql.word.Twenty_Prints;
      if(id=='cheques_print_30') return this.sql.word.Thirty_Prints;
      if(id=='cheques_znvr9f') return this.sql.word.Cheques_Unlimited;
      if(id=='chequeslicence') return this.sql.word.Subscription;
      if(id=='chequeslicence_2mo') return this.sql.word.Two_Month_Subscription;
      if(id=='chequeslicence_3mo') return this.sql.word.Three_Month_Subscription;
      if(id=='chequeslicence_6mo') return this.sql.word.Six_Month_Subscription;
      if(id=='chequeslicence_12mo') return this.sql.word.Twelve_Month_Subscription;
	}
}