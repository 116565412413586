import { SQLService } from '../../services/sql.service';
import { NewSchedule } from '../../models/schedule';
import { SelectAddressPage } from '../../addresses/select-address/select-address.page';
import { SelectEmployeePage } from '../../employees/select-employee/select-employee.page';
import { SelectPayeePage } from '../../payees/select-payee/select-payee.page';
import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'ng-cheques-new-schedule',
  templateUrl: './new-schedule.component.html',
  styleUrls: ['./new-schedule.component.styl']
})
export class NewSchedulePage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public sql: SQLService,
    public modalController: ModalController
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.open();
    });
  } 
  @Input('live_save') live_save = false;
  @Input('ShowHeader') ShowHeader = true;
  currencies = Object.keys(environment.currencies);
  schedule = new NewSchedule(true);
  _c=0;
  async add_address() {
    const m = await this.modalController.create({
      component: SelectAddressPage, 
      componentProps: {}
    });
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1) {
        this.sql.hapticsImpactLight();
        this.delete_address();
      } else {
        this.schedule.addresses = a.data;
        this.sql.view('Addresses','address_id',a.data,(a)=>{this.schedule.addresses=a;});;
        this.sql.hapticsImpactMedium();
        this._c=1;  
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_address(address) {
    this.router.navigate(['folder','Address', address.address_id]); 
  }
  delete_address() {
    this.schedule.addresses = null;
    // this.sql.set( 'Schedules', 'schedule_id', this.schedule.schedule_id, 'addresses', null);
  }
  async add_employee() {
    const m = await this.modalController.create({
      component: SelectEmployeePage, 
      componentProps: {select_multiple: true}
    });
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data){
      if(a.data==-1){
        this.delete_employee();
        this.sql.hapticsImpactLight();
      } else {
        this.schedule.employee_ids = a.data;
        this.get_employees();
        this._c=1;
        this.sql.hapticsImpactMedium();
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_employee(employee) {
    this.router.navigate(['folder','Employee', employee.employee_id]); 
  }
  delete_employee() {
    this.schedule.employee_ids = null;
    this.schedule.employees = null;
    // this.sql.set( 'Schedules', 'schedule_id', this.schedule.schedule_id, 'employee_ids', null);
  }
  get_employees() {
    if(!this.schedule.employee_ids)return;
    if(typeof this.schedule.employee_ids === 'string'){
      this.schedule.employee_ids=this.schedule.employee_ids.split(',');
    } else if (typeof this.schedule.employee_ids === 'number') {
      this.schedule.employee_ids=[this.schedule.employee_ids];
    }
    this.schedule.employees=[];
    for(let a of this.schedule.employee_ids) {
      this.sql.view('Employees','employee_id',a,(data)=>{
        for(let d of data)this.schedule.employees.push(d);
      });
    }
  }
  async add_payee() {
    const m = await this.modalController.create({
      component: SelectPayeePage, 
      componentProps: {select_multiple: true}
    });
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1){
        this.delete_payee();
        this.sql.hapticsImpactLight();
      } else {
        this.schedule.payee_ids = a.data;
        this.get_payees();
        this._c=1;
        this.sql.hapticsImpactMedium();  
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_payee(payee) {
    this.router.navigate(['folder','Payee', payee.payee_id]); 
  }
  delete_payee() {
    this.schedule.payee_ids = null;
    this.schedule.payees = null;
    // this.sql.set( 'Schedules', 'schedule_id', this.schedule.schedule_id, 'payee_ids', null);
  }
  get_payees() {
    if(!this.schedule.payee_ids)return;
    if(typeof this.schedule.payee_ids === 'string'){
      this.schedule.payee_ids=this.schedule.payee_ids.split(',');
    } else if (typeof this.schedule.payee_ids === 'number') {
      this.schedule.payee_ids=[this.schedule.payee_ids];
    }
    this.schedule.payees=[];
    for(let a of this.schedule.payee_ids) {
      this.sql.view('Payees','payee_id',a,(data)=>{
        for(let d of data)this.schedule.payees.push(d);
      });
    }
  }
  set_employee(e) {
    if(e.full_name)this.schedule.name=e.full_name;
    if(e.currency)this.schedule.currency=e.currency;
  }
  delete_schedule() {
    if(!this.schedule.schedule_id){
      this.end();
    } else if(confirm(this.sql.word.Delete_Schedule_Confirmation)) {
      this.sql.delete('Schedules','schedule_id',this.schedule.schedule_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  save(end?, cb?) {
    if(this.schedule.start_time>this.schedule.end_time||this.schedule.start_date>this.schedule.end_date) {
      alert(this.sql.word.Schedule_Timing_Alert);
      return;
    }
    this.sql.add("Schedules",this.schedule, (insertId) => {
      if(!insertId)return
      this._c=0;
      if(!cb && end){
        this.end();
        this.schedule = new NewSchedule(true);
      }
      if(cb) {
        this.schedule.schedule_id = insertId;
        cb(insertId);
      }
      // if(this.schedule.employee_ids) {
      //   var ids = this.schedule.employee_ids.split(',');
      //   for(let employee_id of ids) {
      //     this.sql.add_schedule('employee',employee_id,this.schedule.schedule_id);
      //   }
      // }
      // if(this.schedule.payee_ids) {
      //   var ids = this.schedule.payee_ids.split(',');
      //   for(let payee_id of ids) {
      //     this.sql.add_schedule('payee',payee_id,this.schedule.schedule_id);
      //   }
      // }
    });
  }
  lv() {
    if(this.live_save) {
      this.save();
    }
  }
  amount(event) {
    if(!event.target.value||event.target.value==0){
      this.schedule.amount='0.00';
    } else {
      this.schedule.amount=this.sql.amount(event.target.value,null,this.schedule.currency);
    }
  }
  end() {
    if (this._c==1) {
      if (confirm(this.sql.word.Discard_Confirmation)) {
        this.schedule = new NewSchedule(true);
        this.router.navigate(['folder','Schedules']);
      }
    } else {
      this.router.navigate(['folder','Schedules']);
    }
  }
  log(l){
    console.log(l);
  }
  get_properties() {
    var payeeid=this.route.snapshot.paramMap.get('payeeid');
    if(payeeid)this.schedule.payee_ids=payeeid;
    var employeeid=this.route.snapshot.paramMap.get('employeeid');
    if(employeeid)this.schedule.employee_ids=employeeid;
    this.get_employees();
    this.get_payees();
  }
  get_schedule() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida){} else if(ida=='New') {
      this.get_properties();
    } else {
      this.sql.view('Schedules','schedule_id',ida,(e)=>{
        var f=Object.entries(e[0]); let n = new NewSchedule();
        for(let t of f)n[t[0]]=t[1];
        this.schedule = n;
        this.get_properties();
      });
    }
  }
  open() {
    var d = new Date(Date.now()).toISOString();
    this.schedule.start_date = d;
    var date = new Date();
    var start_time = date.setHours(9,0,0);
    this.schedule.start_time = new Date(start_time).toISOString();
    var date = new Date();
    var end_time = date.setHours(17,0,0);
    this.schedule.end_time = new Date(end_time).toISOString();
    this.schedule.payment_form='salary';
    this.schedule.repeat='weekdays';
    this.schedule.holiday_enabled=true;
    this.schedule.always_enabled=true;
    this.schedule.enabled=true;
    this.get_schedule();
  }
  ngOnInit() { 
    //this.open();
  }
}