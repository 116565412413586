import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SQLService } from '../../../services/sql.service';
import pdfFonts from '../../../../../pdfmake/build/fonts';
import { Router,NavigationEnd } from '@angular/router';
import { fonts } from './fonts';
@Component({
  selector: 'font-picker',
  templateUrl: './font-picker.component.html',
  styleUrls: ['./font-picker.component.scss'],
})
export class FontPickerComponent implements OnInit {
  constructor(
    public sql: SQLService,
    private navCtrl: ModalController,
    private router: Router) {
    this.router.events.subscribe((eve) => {
    });
  }
  fonts = fonts;
  search: string;
  selected: any;
  font = new Fonts();
  ngOnInit() {
    this.sql.get_setting('selected_font',(s)=>{
      this.selected=s.option?s.option:fonts[0].family;
    });
  }
  select(f) {
    this.selected=f.family;
    this.sql.save_setting('selected_font',this.selected);
  }
  end() {
    this.sql.hapticsImpactLight();
    this.navCtrl.dismiss(this.selected);
  }
  filter(e) {
    this.search=e.target.value;
  }
}
export class Fonts {
   constructor(
     public name?: string,
     public family?: string
   ) {}
}