import { SQLService } from '../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { PopoverController} from '@ionic/angular';
import { Router,NavigationEnd } from '@angular/router';
import { TaxMenuComponent } from './tax-menu/tax-menu.component';
@Component({
  selector: 'ng-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.styl']
})
export class TaxesComponent implements OnInit {
  constructor(
    private router: Router,
    public popoverController: PopoverController,
    public sql: SQLService
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_taxes();
    });
  }
  taxes = [];
  count = -1;
  search: string;
  add() {this.router.navigate(['folder','Tax', 'New']);}
  end() {this.router.navigate(['folder','Dashboard']);}
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:TaxMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='enable')this.enable(item);
      if(data=='disable')this.disable(item);
      if(data=='delete')this.delete(item, false);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  filter(e) {
    this.search=e.target.value;
  }
  get_taxes(event?) {
    this.sql.fetch('Taxes',(e)=>{
      if(e)this.taxes=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  edit(a?,i?) {
    this.router.navigate(['folder','Tax',a.tax_id]);
  }
  enable_disable(tax,i){
    if(tax.enabled){
      this.disable(tax,i);
    } else {
      this.enable(tax,i);
    }
    if(i)i.closeSlidingItems();
  }
  disable(tax,i?){
    this.sql.set('Taxes','tax_id',tax.tax_id,'enabled',0,(res)=>{
      tax.enabled = false;
      if(i)i.closeSlidingItems();
    });
  }
  enable(tax,i?){
    this.sql.set('Taxes','tax_id',tax.tax_id,'enabled',1,(res)=>{
      tax.enabled = true;
      if(i)i.closeSlidingItems();
    });
  }
  delete(a?,i?) {
    if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('Taxes','tax_id',a.tax_id);
      this.get_taxes();
    } else { i.closeSlidingItems(); }
  }
  ngOnInit() {
    //this.get_taxes();
  }
}