import { environment } from '../../../../environments/environment';
import { SQLService } from '../../../services/sql.service';
import { Component, OnInit, Input, EventEmitter,ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController, NavParams } from '@ionic/angular';
@Component({
	selector: 'app-bitcoin-purchase',
	templateUrl: './bitcoin.component.html',
	styleUrls: ['./bitcoin.component.scss']
})
export class BitCoinPurchaseComponent implements OnInit {
	constructor(
		private router: Router,
		public sql: SQLService,
		private route: ActivatedRoute,
		private params: NavParams,
		private navCtrl: ModalController,
		private location: Location
	) {
		this.router.events.subscribe((eve) => {
			// if(eve instanceof NavigationEnd) this.get_purchase();
			this.id = this.params.get('id');
			this.name = this.params.get('name');
			this.price = this.params.get('price');
		});
	}
	btc_address: string;
	id: string;
	name: string;
	price: any;
	payment: any;
	ngOnInit() {
		this.payment = this.params.get('data');
		this.btc_address = environment.btc_address;
	}
	off() {
		this.navCtrl.dismiss();
	}
}