export const Prices = {
	"Afghanistan": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Albania": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Algeria": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Angola": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Anguilla": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Antigua and Barbuda": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Argentina": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Armenia": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Australia": {
		"currency":"AUD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.49",
		"chequeslicence_3mo":"1.99",
		"chequeslicence_6mo":"2.99",
		"chequeslicence_12mo":"4.99",
		"cheques_print_1":"0.30",
		"cheques_print_2":"0.50",
		"cheques_print_5":"0.75",
		"cheques_print_10":"1.00",
		"cheques_print_15":"1.50",
		"cheques_print_20":"2.00",
		"cheques_print_30":"3.00",
		"cheques_znvr9f":"79.99",
		"cheques_mailed_black_white":"3.00",
		"cheques_znvr9f_color":"5.00"
	},
	"Austria": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"Azerbaijan": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Bahamas": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Bahrain": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Barbados": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Belarus": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Belgium": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"Belize": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Benin": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Bermuda": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Bhutan": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Bolivia": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Bosnia and Herzegovina": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"Botswana": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Brazil": {
		"currency":"BRL",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.45",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"4.99",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.89",
		"cheques_print_2":"1.39",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"8.00",
		"cheques_print_20":"10.00",
		"cheques_print_30":"12.00",
		"cheques_znvr9f":"99.99",
		"cheques_mailed_black_white":"17.45",
		"cheques_znvr9f_color":"34.50"
	},
	"British Virgin Islands": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Brunei": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Bulgaria": {
		"currency":"BGN",
		"chequeslicence":"1.49",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"4.99",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.50",
		"cheques_print_2":"0.75",
		"cheques_print_5":"1.50",
		"cheques_print_10":"2.00",
		"cheques_print_15":"2.50",
		"cheques_print_20":"2.90",
		"cheques_print_30":"3.50",
		"cheques_znvr9f":"89.99",
		"cheques_mailed_black_white":"5.00",
		"cheques_znvr9f_color":"10.00"
	},
	"Burkina Faso": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Cambodia": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Cameroon": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Canada": {
		"currency":"CAD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"4.99",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.30",
		"cheques_print_2":"0.45",
		"cheques_print_5":"0.50",
		"cheques_print_10":"1.00",
		"cheques_print_15":"2.50",
		"cheques_print_20":"2.00",
		"cheques_print_30":"3.00",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Cape Verde": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Cayman Islands": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Chad": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Chile": {
		"currency":"CLP",
		"chequeslicence":"999",
		"chequeslicence_2mo":"1950",
		"chequeslicence_3mo":"3249",
		"chequeslicence_6mo":"4982",
		"chequeslicence_12mo":"9987",
		"cheques_print_1":"250",
		"cheques_print_2":"300",
		"cheques_print_5":"400",
		"cheques_print_10":"500",
		"cheques_print_15":"600",
		"cheques_print_20":"750",
		"cheques_print_30":"950",
		"cheques_znvr9f":"749995",
		"cheques_mailed_black_white":"1000",
		"cheques_znvr9f_color":"2960"
	},
	"China mainland": {
		"currency":"CNY",
		"chequeslicence":"10",
		"chequeslicence_2mo":"50",
		"chequeslicence_3mo":"100",
		"chequeslicence_6mo":"350",
		"chequeslicence_12mo":"450",
		"cheques_print_1":"1",
		"cheques_print_2":"5",
		"cheques_print_5":"7",
		"cheques_print_10":"15",
		"cheques_print_15":"25",
		"cheques_print_20":"75",
		"cheques_print_30":"125",
		"cheques_znvr9f":"1275",
		"cheques_mailed_black_white":"18",
		"cheques_znvr9f_color":"32"
	},
	"Colombia": {
		"currency":"COP",
		"chequeslicence":"125",
		"chequeslicence_2mo":"200",
		"chequeslicence_3mo":"750",
		"chequeslicence_6mo":"1000",
		"chequeslicence_12mo":"2500",
		"cheques_print_1":"10",
		"cheques_print_2":"20",
		"cheques_print_5":"30",
		"cheques_print_10":"50",
		"cheques_print_15":"125",
		"cheques_print_20":"230",
		"cheques_print_30":"350",
		"cheques_znvr9f":"999999",
		"cheques_mailed_black_white":"13900",
		"cheques_znvr9f_color":"17599"
	},
	"Congo, Democratic Republic of the": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Congo, Republic of the": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Costa Rica": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Cote D'Ivoire": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Croatia": {
		"currency":"HRK",
		"chequeslicence":"14.95",
		"chequeslicence_2mo":"19.95",
		"chequeslicence_3mo":"29.99",
		"chequeslicence_6mo":"49.99",
		"chequeslicence_12mo":"99.99",
		"cheques_print_1":"2.00",
		"cheques_print_2":"3.00",
		"cheques_print_5":"4.00",
		"cheques_print_10":"10.00",
		"cheques_print_15":"12.00",
		"cheques_print_20":"15.00",
		"cheques_print_30":"20.00",
		"cheques_znvr9f":"249.99",
		"cheques_mailed_black_white":"24.99",
		"cheques_znvr9f_color":"29.99"
	},
	"Cyprus": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"Czech Republic": {
		"currency":"CZK",
		"chequeslicence":"149",
		"chequeslicence_2mo":"249",
		"chequeslicence_3mo":"349",
		"chequeslicence_6mo":"749",
		"chequeslicence_12mo":"999",
		"cheques_print_1":"25",
		"cheques_print_2":"49",
		"cheques_print_5":"129",
		"cheques_print_10":"249",
		"cheques_print_15":"379",
		"cheques_print_20":"499",
		"cheques_print_30":"779",
		"cheques_znvr9f":"2499",
		"cheques_mailed_black_white":"79",
		"cheques_znvr9f_color":"99"
	},
	"Denmark": {
		"currency":"DKK",
		"chequeslicence":"10",
		"chequeslicence_2mo":"20",
		"chequeslicence_3mo":"25",
		"chequeslicence_6mo":"50",
		"chequeslicence_12mo":"100",
		"cheques_print_1":"1",
		"cheques_print_2":"5",
		"cheques_print_5":"10",
		"cheques_print_10":"20",
		"cheques_print_15":"50",
		"cheques_print_20":"75",
		"cheques_print_30":"100",
		"cheques_znvr9f":"300",
		"cheques_mailed_black_white":"15",
		"cheques_znvr9f_color":"18"
	},
	"Dominica": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Dominican Republic": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Ecuador": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Egypt": {
		"currency":"EGP",
		"chequeslicence":"14.99",
		"chequeslicence_2mo":"34.99",
		"chequeslicence_3mo":"49.99",
		"chequeslicence_6mo":"79.99",
		"chequeslicence_12mo":"99.99",
		"cheques_print_1":"5.00",
		"cheques_print_2":"6.00",
		"cheques_print_5":"8.00",
		"cheques_print_10":"10.00",
		"cheques_print_15":"15.00",
		"cheques_print_20":"20.00",
		"cheques_print_30":"30.00",
		"cheques_znvr9f":"749.95",
		"cheques_mailed_black_white":"24.99",
		"cheques_znvr9f_color":"34.95"
	},
	"El Salvador": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Estonia": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"Eswatini": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Fiji": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Finland": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"France": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"Gabon": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Gambia": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Georgia": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Germany": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"Ghana": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Greece": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"Grenada": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Guatemala": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Guinea-Bissau": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Guyana": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Honduras": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Hong Kong": {
		"currency":"HKD",
		"chequeslicence":"8.99",
		"chequeslicence_2mo":"17.49",
		"chequeslicence_3mo":"49.95",
		"chequeslicence_6mo":"79.95",
		"chequeslicence_12mo":"99.99",
		"cheques_print_1":"69.99",
		"cheques_print_2":"79.95",
		"cheques_print_5":"89.79",
		"cheques_print_10":"99.89",
		"cheques_print_15":"119.99",
		"cheques_print_20":"124.49",
		"cheques_print_30":"159.79",
		"cheques_znvr9f":"8995.98",
		"cheques_mailed_black_white":"200",
		"cheques_znvr9f_color":"320"
	},
	"Hungary": {
		"currency":"HUF",
		"chequeslicence":"1299",
		"chequeslicence_2mo":"1499",
		"chequeslicence_3mo":"2999",
		"chequeslicence_6mo":"7499",
		"chequeslicence_12mo":"9999",
		"cheques_print_1":"399",
		"cheques_print_2":"799",
		"cheques_print_5":"1990",
		"cheques_print_10":"3990",
		"cheques_print_15":"6490",
		"cheques_print_20":"7990",
		"cheques_print_30":"12990",
		"cheques_znvr9f":"29997",
		"cheques_mailed_black_white":"1290",
		"cheques_znvr9f_color":"2490"
	},
	"Iceland": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"India": {
		"currency":"INR",
		"chequeslicence":"100",
		"chequeslicence_2mo":"120",
		"chequeslicence_3mo":"750",
		"chequeslicence_6mo":"1250",
		"chequeslicence_12mo":"2179",
		"cheques_print_1":"5",
		"cheques_print_2":"20",
		"cheques_print_5":"50",
		"cheques_print_10":"100",
		"cheques_print_15":"250",
		"cheques_print_20":"500",
		"cheques_print_30":"1000",
		"cheques_znvr9f":"6295",
		"cheques_mailed_black_white":"270",
		"cheques_znvr9f_color":"350"
	},
	"Indonesia": {
		"currency":"IDR",
		"chequeslicence":"969",
		"chequeslicence_2mo":"999",
		"chequeslicence_3mo":"1997",
		"chequeslicence_6mo":"3299",
		"chequeslicence_12mo":"6979",
		"cheques_print_1":"250",
		"cheques_print_2":"500",
		"cheques_print_5":"1000",
		"cheques_print_10":"1270",
		"cheques_print_15":"1570",
		"cheques_print_20":"2750",
		"cheques_print_30":"3750",
		"cheques_znvr9f":"578995",
		"cheques_mailed_black_white":"45000",
		"cheques_znvr9f_color":"75000"
	},
	"Iraq": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Ireland": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"Israel": {
		"currency":"ILS",
		"chequeslicence":"8.99",
		"chequeslicence_2mo":"9.99",
		"chequeslicence_3mo":"19.99",
		"chequeslicence_6mo":"29.99",
		"chequeslicence_12mo":"49.99",
		"cheques_print_1":"1.00",
		"cheques_print_2":"2.00",
		"cheques_print_5":"3.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"10.00",
		"cheques_print_30":"25.00",
		"cheques_znvr9f":"249.9",
		"cheques_mailed_black_white":"9.99",
		"cheques_znvr9f_color":"13.39"
	},
	"Italy": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"Jamaica": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Japan": {
		"currency":"JPY",
		"chequeslicence":"270",
		"chequeslicence_2mo":"300",
		"chequeslicence_3mo":"500",
		"chequeslicence_6mo":"750",
		"chequeslicence_12mo":"1950",
		"cheques_print_1":"70",
		"cheques_print_2":"75",
		"cheques_print_5":"85",
		"cheques_print_10":"90",
		"cheques_print_15":"100",
		"cheques_print_20":"150",
		"cheques_print_30":"200",
		"cheques_znvr9f":"8995",
		"cheques_mailed_black_white":"369",
		"cheques_znvr9f_color":"418"
	},
	"Jordan": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Kazakhstan": {
		"currency":"KZT",
		"chequeslicence":"997",
		"chequeslicence_2mo":"998",
		"chequeslicence_3mo":"1999",
		"chequeslicence_6mo":"4989",
		"chequeslicence_12mo":"9745",
		"cheques_print_1":"200",
		"cheques_print_2":"300",
		"cheques_print_5":"450",
		"cheques_print_10":"800",
		"cheques_print_15":"1000",
		"cheques_print_20":"1250",
		"cheques_print_30":"1500",
		"cheques_znvr9f":"34995",
		"cheques_mailed_black_white":"1495",
		"cheques_znvr9f_color":"1695"
	},
	"Kenya": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Korea, Republic of": {
		"currency":"KRW",
		"chequeslicence":"979",
		"chequeslicence_2mo":"1000",
		"chequeslicence_3mo":"2000",
		"chequeslicence_6mo":"3750",
		"chequeslicence_12mo":"5999",
		"cheques_print_1":"300",
		"cheques_print_2":"350",
		"cheques_print_5":"450",
		"cheques_print_10":"500",
		"cheques_print_15":"600",
		"cheques_print_20":"750",
		"cheques_print_30":"1000",
		"cheques_znvr9f":"15950",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Kosovo": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"Kuwait": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Kyrgyzstan": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Laos": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Latvia": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"Lebanon": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Liberia": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Libya": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Lithuania": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"Luxembourg": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"Macau": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Madagascar": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Malawi": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Malaysia": {
		"currency":"MYR",
		"chequeslicence":"9.99",
		"chequeslicence_2mo":"17.99",
		"chequeslicence_3mo":"32.98",
		"chequeslicence_6mo":"54.99",
		"chequeslicence_12mo":"99.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"189.95",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Maldives": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Mali": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Malta": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"Mauritania": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Mauritius": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Mexico": {
		"currency":"MXN",
		"chequeslicence":"9.29",
		"chequeslicence_2mo":"14.99",
		"chequeslicence_3mo":"29.94",
		"chequeslicence_6mo":"49.95",
		"chequeslicence_12mo":"99.99",
		"cheques_print_1":"0.99",
		"cheques_print_2":"1.99",
		"cheques_print_5":"4.99",
		"cheques_print_10":"9.99",
		"cheques_print_15":"14.99",
		"cheques_print_20":"19.99",
		"cheques_print_30":"29.99",
		"cheques_znvr9f":"349.95",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"5.99"
	},
	"Micronesia": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Moldova": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Mongolia": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Montenegro": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"Montserrat": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Morocco": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Mozambique": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Myanmar": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Namibia": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Nauru": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Nepal": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Netherlands": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"New Zealand": {
		"currency":"NZD",
		"chequeslicence":"1.89",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"4.99",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.10",
		"cheques_print_5":"3.20",
		"cheques_print_10":"3.90",
		"cheques_print_15":"4.50",
		"cheques_print_20":"4.80",
		"cheques_print_30":"4.99",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"3.00",
		"cheques_znvr9f_color":"4.00"
	},
	"Nicaragua": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Niger": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Nigeria": {
		"currency":"NGN",
		"chequeslicence":"998",
		"chequeslicence_2mo":"999",
		"chequeslicence_3mo":"1997",
		"chequeslicence_6mo":"4247",
		"chequeslicence_12mo":"8950",
		"cheques_print_1":"100",
		"cheques_print_2":"200",
		"cheques_print_5":"300",
		"cheques_print_10":"700",
		"cheques_print_15":"800",
		"cheques_print_20":"1000",
		"cheques_print_30":"1400",
		"cheques_znvr9f":"29850",
		"cheques_mailed_black_white":"2000",
		"cheques_znvr9f_color":"2375"
	},
	"North Macedonia": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Norway": {
		"currency":"NOK",
		"chequeslicence":"8.99",
		"chequeslicence_2mo":"9.99",
		"chequeslicence_3mo":"19.99",
		"chequeslicence_6mo":"35.99",
		"chequeslicence_12mo":"69.95",
		"cheques_print_1":"1.00",
		"cheques_print_2":"2.00",
		"cheques_print_5":"3.00",
		"cheques_print_10":"10.00",
		"cheques_print_15":"15.00",
		"cheques_print_20":"20.00",
		"cheques_print_30":"30.00",
		"cheques_znvr9f":"249.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"3.99"
	},
	"Oman": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Pakistan": {
		"currency":"PKR",
		"chequeslicence":"427",
		"chequeslicence_2mo":"449",
		"chequeslicence_3mo":"999",
		"chequeslicence_6mo":"2799",
		"chequeslicence_12mo":"4999",
		"cheques_print_1":"100",
		"cheques_print_2":"200",
		"cheques_print_5":"300",
		"cheques_print_10":"500",
		"cheques_print_15":"600",
		"cheques_print_20":"750",
		"cheques_print_30":"800",
		"cheques_znvr9f":"19985",
		"cheques_mailed_black_white":"500",
		"cheques_znvr9f_color":"600"
	},
	"Palau": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Panama": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Papua New Guinea": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Paraguay": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Peru": {
		"currency":"PEN",
		"chequeslicence":"9.99",
		"chequeslicence_2mo":"19.99",
		"chequeslicence_3mo":"35.99",
		"chequeslicence_6mo":"59.99",
		"chequeslicence_12mo":"79.94",
		"cheques_print_1":"10",
		"cheques_print_2":"20",
		"cheques_print_5":"50",
		"cheques_print_10":"100",
		"cheques_print_15":"150",
		"cheques_print_20":"200",
		"cheques_print_30":"300",
		"cheques_znvr9f":"249.99",
		"cheques_mailed_black_white":"149",
		"cheques_znvr9f_color":"299"
	},
	"Philippines": {
		"currency":"PHP",
		"chequeslicence":"99",
		"chequeslicence_2mo":"100",
		"chequeslicence_3mo":"300",
		"chequeslicence_6mo":"500",
		"chequeslicence_12mo":"1000",
		"cheques_print_1":"30",
		"cheques_print_2":"50",
		"cheques_print_5":"70",
		"cheques_print_10":"100",
		"cheques_print_15":"120",
		"cheques_print_20":"150",
		"cheques_print_30":"200",
		"cheques_znvr9f":"2875",
		"cheques_mailed_black_white":"13.99",
		"cheques_znvr9f_color":"17.99"
	},
	"Poland": {
		"currency":"PLN",
		"chequeslicence":"7.99",
		"chequeslicence_2mo":"9.99",
		"chequeslicence_3mo":"19.99",
		"chequeslicence_6mo":"49.99",
		"chequeslicence_12mo":"99.99",
		"cheques_print_1":"1.00",
		"cheques_print_2":"2.00",
		"cheques_print_5":"5.00",
		"cheques_print_10":"10.00",
		"cheques_print_15":"15.00",
		"cheques_print_20":"20.00",
		"cheques_print_30":"30.00",
		"cheques_znvr9f":"249.99",
		"cheques_mailed_black_white":"14.99",
		"cheques_znvr9f_color":"17.99"
	},
	"Portugal": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"Qatar": {
		"currency":"QAR",
		"chequeslicence":"8.99",
		"chequeslicence_2mo":"9.99",
		"chequeslicence_3mo":"19.99",
		"chequeslicence_6mo":"49.99",
		"chequeslicence_12mo":"99.99",
		"cheques_print_1":"1.00",
		"cheques_print_2":"2.00",
		"cheques_print_5":"5.00",
		"cheques_print_10":"10.00",
		"cheques_print_15":"15.00",
		"cheques_print_20":"20.00",
		"cheques_print_30":"30.00",
		"cheques_znvr9f":"249.99",
		"cheques_mailed_black_white":"14.99",
		"cheques_znvr9f_color":"17.99"
	},
	"Romania": {
		"currency":"RON",
		"chequeslicence":"12.99",
		"chequeslicence_2mo":"13.49",
		"chequeslicence_3mo":"36.98",
		"chequeslicence_6mo":"79.99",
		"chequeslicence_12mo":"129.99",
		"cheques_print_1":"1.00",
		"cheques_print_2":"2.00",
		"cheques_print_5":"3.00",
		"cheques_print_10":"7.00",
		"cheques_print_15":"10.00",
		"cheques_print_20":"15.00",
		"cheques_print_30":"20.00",
		"cheques_znvr9f":"249.99",
		"cheques_mailed_black_white":"279",
		"cheques_znvr9f_color":"319"
	},
	"Russia": {
		"currency":"RUB",
		"chequeslicence":"90",
		"chequeslicence_2mo":"100",
		"chequeslicence_3mo":"300",
		"chequeslicence_6mo":"500",
		"chequeslicence_12mo":"1000",
		"cheques_print_1":"10",
		"cheques_print_2":"20",
		"cheques_print_5":"25",
		"cheques_print_10":"50",
		"cheques_print_15":"60",
		"cheques_print_20":"70",
		"cheques_print_30":"75",
		"cheques_znvr9f":"3750",
		"cheques_mailed_black_white":"100",
		"cheques_znvr9f_color":"200"
	},
	"Rwanda": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"S√£o Tom√© and Pr√≠ncipe": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Saudi Arabia": {
		"currency":"SAR",
		"chequeslicence":"10.00",
		"chequeslicence_2mo":"10.95",
		"chequeslicence_3mo":"29.95",
		"chequeslicence_6mo":"48.99",
		"chequeslicence_12mo":"99.99",
		"cheques_print_1":"1.00",
		"cheques_print_2":"2.00",
		"cheques_print_5":"3.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"10.00",
		"cheques_print_20":"20.00",
		"cheques_print_30":"30.00",
		"cheques_znvr9f":"319.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"3.39"
	},
	"Senegal": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Serbia": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"Seychelles": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Sierra Leone": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Singapore": {
		"currency":"SGD",
		"chequeslicence":"1.99",
		"chequeslicence_2mo":"4.99",
		"chequeslicence_3mo":"9.98",
		"chequeslicence_6mo":"19.98",
		"chequeslicence_12mo":"49.98",
		"cheques_print_1":"1.00",
		"cheques_print_2":"2.00",
		"cheques_print_5":"5.00",
		"cheques_print_10":"10.00",
		"cheques_print_15":"15.00",
		"cheques_print_20":"20.00",
		"cheques_print_30":"30.00",
		"cheques_znvr9f":"69.99",
		"cheques_mailed_black_white":"2.99",
		"cheques_znvr9f_color":"3.49"
	},
	"Slovakia": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"Slovenia": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"Solomon Islands": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"South Africa": {
		"currency":"ZAR",
		"chequeslicence":"49.99",
		"chequeslicence_2mo":"89.99",
		"chequeslicence_3mo":"149.99",
		"chequeslicence_6mo":"199.99",
		"chequeslicence_12mo":"249.99",
		"cheques_print_1":"5.00",
		"cheques_print_2":"10.00",
		"cheques_print_5":"15.00",
		"cheques_print_10":"20.00",
		"cheques_print_15":"25.00",
		"cheques_print_20":"40.00",
		"cheques_print_30":"75.00",
		"cheques_znvr9f":"1000",
		"cheques_mailed_black_white":"3.00",
		"cheques_znvr9f_color":"5.00"
	},
	"Spain": {
		"currency":"EUR",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"1.99",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"3.98",
		"chequeslicence_12mo":"7.95",
		"cheques_print_1":"0.50",
		"cheques_print_2":"1.00",
		"cheques_print_5":"2.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"9.00",
		"cheques_print_30":"10.00",
		"cheques_znvr9f":"19.94",
		"cheques_mailed_black_white":"1.79",
		"cheques_znvr9f_color":"3.35"
	},
	"Sri Lanka": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"St. Kitts and Nevis": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"St. Lucia": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"St. Vincent and the Grenadines": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Suriname": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Sweden": {
		"currency":"SEK",
		"chequeslicence":"9",
		"chequeslicence_2mo":"12",
		"chequeslicence_3mo":"15",
		"chequeslicence_6mo":"30",
		"chequeslicence_12mo":"50",
		"cheques_print_1":"5",
		"cheques_print_2":"7",
		"cheques_print_5":"8",
		"cheques_print_10":"10",
		"cheques_print_15":"15",
		"cheques_print_20":"20",
		"cheques_print_30":"30",
		"cheques_znvr9f":"495",
		"cheques_mailed_black_white":"31",
		"cheques_znvr9f_color":"42"
	},
	"Switzerland": {
		"currency":"CHF",
		"chequeslicence":"0.79",
		"chequeslicence_2mo":"0.99",
		"chequeslicence_3mo":"1.49",
		"chequeslicence_6mo":"2.99",
		"chequeslicence_12mo":"4.99",
		"cheques_print_1":"0.25",
		"cheques_print_2":"0.75",
		"cheques_print_5":"1.00",
		"cheques_print_10":"1.25",
		"cheques_print_15":"1.50",
		"cheques_print_20":"2.00",
		"cheques_print_30":"3.00",
		"cheques_znvr9f":"29.99",
		"cheques_mailed_black_white":"1.99",
		"cheques_znvr9f_color":"2.49"
	},
	"Taiwan": {
		"currency":"TWD",
		"chequeslicence":"39",
		"chequeslicence_2mo":"49",
		"chequeslicence_3mo":"99",
		"chequeslicence_6mo":"199",
		"chequeslicence_12mo":"399",
		"cheques_print_1":"10",
		"cheques_print_2":"20",
		"cheques_print_5":"30",
		"cheques_print_10":"50",
		"cheques_print_15":"110",
		"cheques_print_20":"220",
		"cheques_print_30":"350",
		"cheques_znvr9f":"2598",
		"cheques_mailed_black_white":"100",
		"cheques_znvr9f_color":"135"
	},
	"Tajikistan": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Tanzania": {
		"currency":"TZS",
		"chequeslicence":"899",
		"chequeslicence_2mo":"1000",
		"chequeslicence_3mo":"2000",
		"chequeslicence_6mo":"3750",
		"chequeslicence_12mo":"7695",
		"cheques_print_1":"1000",
		"cheques_print_2":"1750",
		"cheques_print_5":"2000",
		"cheques_print_10":"3000",
		"cheques_print_15":"3500",
		"cheques_print_20":"4200",
		"cheques_print_30":"5000",
		"cheques_znvr9f":"28750",
		"cheques_mailed_black_white":"5000",
		"cheques_znvr9f_color":"5750"
	},
	"Thailand": {
		"currency":"THB",
		"chequeslicence":"79",
		"chequeslicence_2mo":"149",
		"chequeslicence_3mo":"295",
		"chequeslicence_6mo":"449",
		"chequeslicence_12mo":"779",
		"cheques_print_1":"6",
		"cheques_print_2":"7",
		"cheques_print_5":"9",
		"cheques_print_10":"10",
		"cheques_print_15":"25",
		"cheques_print_20":"50",
		"cheques_print_30":"100",
		"cheques_znvr9f":"1975",
		"cheques_mailed_black_white":"99",
		"cheques_znvr9f_color":"149"
	},
	"Tonga": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Trinidad and Tobago": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Tunisia": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Turkey": {
		"currency":"TRY",
		"chequeslicence":"9.89",
		"chequeslicence_2mo":"9.99",
		"chequeslicence_3mo":"24.99",
		"chequeslicence_6mo":"49.99",
		"chequeslicence_12mo":"99.99",
		"cheques_print_1":"5.00",
		"cheques_print_2":"7.00",
		"cheques_print_5":"10.00",
		"cheques_print_10":"12.00",
		"cheques_print_15":"15.00",
		"cheques_print_20":"20.00",
		"cheques_print_30":"25.00",
		"cheques_znvr9f":"239.95",
		"cheques_mailed_black_white":"50.00",
		"cheques_znvr9f_color":"75.00"
	},
	"Turkmenistan": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Turks and Caicos Islands": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Uganda": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Ukraine": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"United Arab Emirates": {
		"currency":"AED",
		"chequeslicence":"4.99",
		"chequeslicence_2mo":"5.95",
		"chequeslicence_3mo":"9.95",
		"chequeslicence_6mo":"17.49",
		"chequeslicence_12mo":"29.99",
		"cheques_print_1":"1.00",
		"cheques_print_2":"2.00",
		"cheques_print_5":"3.00",
		"cheques_print_10":"5.00",
		"cheques_print_15":"7.00",
		"cheques_print_20":"10.00",
		"cheques_print_30":"12.00",
		"cheques_znvr9f":"89.94",
		"cheques_mailed_black_white":"10.99",
		"cheques_znvr9f_color":"20.99"
	},
	"United Kingdom": {
		"currency":"GBP",
		"chequeslicence":"0.49",
		"chequeslicence_2mo":"0.99",
		"chequeslicence_3mo":"1.49",
		"chequeslicence_6mo":"2.99",
		"chequeslicence_12mo":"4.99",
		"cheques_print_1":"0.10",
		"cheques_print_2":"0.20",
		"cheques_print_5":"0.30",
		"cheques_print_10":"0.50",
		"cheques_print_15":"1.00",
		"cheques_print_20":"1.30",
		"cheques_print_30":"1.50",
		"cheques_znvr9f":"19.95",
		"cheques_mailed_black_white":"1.75",
		"cheques_znvr9f_color":"2.35"
	},
	"United States": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Uruguay": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Uzbekistan": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Vanuatu": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Venezuela": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Vietnam": {
		"currency":"VND",
		"chequeslicence":"119000",
		"chequeslicence_2mo":"229000",
		"chequeslicence_3mo":"349000",
		"chequeslicence_6mo":"669000",
		"chequeslicence_12mo":"1249000",
		"cheques_print_1":"22000",
		"cheques_print_2":"45000",
		"cheques_print_5":"109000",
		"cheques_print_10":"219000",
		"cheques_print_15":"329000",
		"cheques_print_20":"449000",
		"cheques_print_30":"699000",
		"cheques_znvr9f":"1499000",
		"cheques_mailed_black_white":"69000",
		"cheques_znvr9f_color":"129000"
	},
	"Yemen": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Zambia": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	},
	"Zimbabwe": {
		"currency":"USD",
		"chequeslicence":"0.99",
		"chequeslicence_2mo":"1.29",
		"chequeslicence_3mo":"3.99",
		"chequeslicence_6mo":"5.39",
		"chequeslicence_12mo":"9.99",
		"cheques_print_1":"0.77",
		"cheques_print_2":"0.98",
		"cheques_print_5":"2.49",
		"cheques_print_10":"2.99",
		"cheques_print_15":"4.49",
		"cheques_print_20":"6.99",
		"cheques_print_30":"8.99",
		"cheques_znvr9f":"39.95",
		"cheques_mailed_black_white":"3.99",
		"cheques_znvr9f_color":"8.49"
	}
};