export class NewBonus {
	constructor(
		public bonus_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public employee_id?: number,
		public employee_name?: string,
		public employees?: any,
		public frequency?: any,
		public amount?: number,
		public currency?: string,
		public beginning?: any,
		public ending?: any,
		public title?: string,
		public description?: string,
		public always_enabled?: boolean,
		public start_date?: any,
		public end_date?: any,
		public enabled?: boolean,
		public total_amount_rewarded?: number,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Deductions = '\
	CREATE TABLE IF NOT EXISTS "Bonuses" (\
		bonus_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		employee_id INTEGER,\
		frequency VARCHAR(100),\
		amount TEXT,\
		currency VARCHAR(10),\
		always_enabled INTEGER DEFAULT 0,\
		beginning INTEGER,\
		ending INTEGER,\
		enabled INTEGER DEFAULT 0,\
		title TEXT,\
		description TEXT,\
		total_amount_rewarded INTEGER DEFAULT 0,\
		start_date TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		end_date TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';