import { Component, Input, ElementRef, ViewChild,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SQLService } from '../../../../services/sql.service';
@Component({
  selector: 'app-dialpad',
  templateUrl: './dialpad.component.html',
  styleUrls: ['./dialpad.component.styl'],
})
export class DialpadComponent implements OnInit {
 constructor(
    private router: Router,
    private sql: SQLService,
  ) {}
  p=0;
  bal=0;
  price=0;
  _p:string;
  service: any;
  calling=false;
  @Input('number') number=''
  @ViewChild('speaker') speaker: ElementRef;
  reset() {  this.p=0;this._p='';this.number=''; }
  call() { 
    if(this.calling==true)return;
    this.calling=true;
  }
  key(e) {
    this.sql.hapticsImpactLight();
    this.p=this.p+1;
    this.number=this.number+''+e;
    if(this.p>4){
      this._p=this.sql.phone(this.number);
    } else {
      this._p=this.number;
    }
  }
  backsp() {
    if (this.p<=0) return;
    this.p=0;
    this.number='';
    this._p='';
  }
  ngOnInit() {
    
  }
}
