import { NewEmployee } from '../../models/employee';
import { Flip } from '../../app.animations';
import { SQLService } from '../../services/sql.service';
import { environment } from '../../../environments/environment';
import { SelectAddressPage } from '../../addresses/select-address/select-address.page';
import { SelectDeductionPage } from '../../deductions/select-deduction/select-deduction.page';
import { SelectJobPage } from '../../jobs/select-job/select-job.page';
import { SelectSalaryPage } from '../../salary/select-salary/select-salary.page';
import { SelectTaxPage } from '../../taxes/select-tax/select-tax.page';
import { Location } from '@angular/common';
import { Component, OnInit, OnChanges, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { ActionSheetController, ModalController, Platform } from '@ionic/angular';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { Browser } from '@capacitor/browser';
import { CalendarModal, CalendarModalOptions, DayConfig, CalendarResult } from 'ion2-calendar';
@Component({
  selector: 'ng-cheques-new-employee',
  templateUrl: './new-employee.component.html',
  styleUrls: ['./new-employee.component.styl'],
  animations: [ Flip ]
})
export class NewEmployeePage implements OnInit, OnChanges {
  constructor(
    private router: Router,
    public sql: SQLService,
    public platform: Platform,
    private actionSheetCtrl: ActionSheetController,
    private callNumber: CallNumber,
    private keyboard: Keyboard,
    private route: ActivatedRoute,
    private location: Location,
    public modalController: ModalController
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.open();
    });
  }
  @ViewChild('jobTitle') jobTitle: ElementRef;
  @Input('live_save') live_save = false;
  @Input('ShowHeader') ShowHeader = true;
  currencies = Object.keys(environment.currencies);
  employee = new NewEmployee(true);
  yournamehere = 'New Employee';
  filter_paid = false;
  sort_by_money = false;
  _c=0;
  ngOnChanges(item){
    
  }
  open(){
    this.get_employee();
  }
  description(event) {
    if( event.keycode==13 ) {
      event.preventDefault();
    }
  }
  get_employee() {
    var ida = this.route.snapshot.paramMap.get("id");
    if(!ida)return; 
    this.sql.view('Employees','employee_id',ida,(e)=>{
      var f=Object.entries(e[0]);var n=new NewEmployee(true);
      for(var t of f)n[t[0]]=t[1];
      if(n.first_name&&n.last_name)this.yournamehere=n.first_name+' '+n.last_name;
      var a=this.route.snapshot.paramMap.get('addressid');if(a)n.address_id = a;
      var a=this.route.snapshot.paramMap.get('deductionid');if(a)n.deduction_id = a;
      var a=this.route.snapshot.paramMap.get('jobid');if(a)n.job_id = a;
      var a=this.route.snapshot.paramMap.get('salaryid');if(a)n.salary_ids = a;
      var a=this.route.snapshot.paramMap.get('taxid');if(a)n.tax_id = a;
      if(n.address_id&&n.address_id>0)
        this.sql.view('Addresses','address_id',n.address_id,(a)=>{n.addresses=a;});
      if(n.deduction_id&&n.deduction_id>0)
        this.sql.view('Deductions','deduction_id',n.deduction_id,(a)=>{n.deductions=a;});
      if(n.job_id&&n.job_id>0)
        this.sql.view('Jobs','job_id',n.job_id,(a)=>{n.jobs=a;});
      if(n.salaries&&n.salary_ids>0)
        this.sql.view('Salaries','salary_id',n.salary_ids,(a)=>{
          n.salaries=a;
        });
      if(n.tax_id&&n.tax_id>0)
        this.sql.view('Taxes','tax_id',n.tax_id,(a)=>{n.taxes=a;});
      this.employee = n;
      if(!this.employee.currency)this.employee.currency=this.sql.Currency;
    });
  }
  setname(str) {}
  delete_employee() {
    if(!this.employee.employee_id){
      this.end();
    } else if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('Employees','employee_id',this.employee.employee_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  end(id?) {
    this.sql.get_temporary('page',(page)=>{
      if (this._c>0) {
        if (confirm(this.sql.word.Discard_Confirmation)) {
          this.employee = new NewEmployee(true);
          this.router.navigate(['folder','Employees']);
        }
      } else {
        if(page) {
          this.router.navigate(['folder',page.location,page.id,'Employee',id]);
          /// setTimeout( () => { this.sql.remove_temporary('page'); }, 600);
        } else {
          this.router.navigate(['folder','Employees']);
        }
      }
    });
  }
  async add_address() {
    this.set_temp();
    const m = await this.modalController.create({component: SelectAddressPage, componentProps: {}});
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data){
      if(a.data==-1){
        this.delete_address();
        this.sql.hapticsImpactLight();
      } else {
        this._c=1;
        this.employee.address_id = a.data;
        this.sql.view('Addresses','address_id',a.data,(a)=>{this.employee.addresses=a;});
        this.sql.hapticsImpactMedium();
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_address(address) {
    this.router.navigate(['folder','Address', address.address_id]); 
  }
  delete_address() {
    this.employee.addresses = false;
    this.employee.address_id = null;
    // this.sql.set( 'Employees', 'employee_id', this.employee.employee_id, 'addresses', null);
  }
  async add_taxes() {
    this.set_temp();
    const m = await this.modalController.create({component: SelectTaxPage});
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data){
      if(a.data==-1){
        this.delete_taxes();
        this.sql.hapticsImpactLight();
      } else {
        this.employee.taxes = [a.data];
        this.employee.tax_id = a.data.tax_id;
        this._c=1;
        this.sql.hapticsImpactMedium();
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_taxes(tax) {
    this.router.navigate(['folder','Tax', tax.tax_id]);
  }
  delete_taxes() {
    this.employee.taxes = null;
    this.employee.tax_id = null;
    // this.sql.set( 'Employees', 'employee_id', this.employee.employee_id, 'taxes', null);
  }
  async add_job() {
    this.set_temp();
    const m = await this.modalController.create({component: SelectJobPage});
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data){
      if(a.data==-1){
        this.delete_job();
        this.sql.hapticsImpactLight();
      } else {
        this.employee.jobs = [a.data];
        this.employee.job_id = a.data.job_id;
        this._c=1;
        this.sql.hapticsImpactMedium();  
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_job(job) {
    this.router.navigate(['folder','Job', job.job_id]);
  }
  delete_job() {
    this.employee.jobs = null;
    this.employee.job_id = null;
    // this.sql.set( 'Employees', 'employee_id', this.employee.employee_id, 'jobs', null);
  }
  get_job() {
      this.sql.view('Jobs','job_id',this.employee.job_id,(data)=>{
        this.employee.jobs = data;
      });
  }
  async add_salary() {
    this.set_temp();
    const m = await this.modalController.create({component: SelectSalaryPage});
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1){
        this.delete_salary();
        this.sql.hapticsImpactLight();
      } else {
        this.employee.salaries = [a.data];
        this.employee.salary_ids = a.data.salary_id;
        this._c=1;
        this.sql.hapticsImpactMedium();
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_salary(salary) {
    this.router.navigate(['folder','Salary', salary.salary_id]); 
  }
  delete_salary() {
    this.employee.salaries = null;
    this.employee.salary_ids = null;
    // this.sql.set( 'Employees', 'employee_id', this.employee.employee_id, 'salaries', null);
  }
  get_salary() {
    this.sql.view('Salaries','salary_ids',this.employee.salary_ids,(data)=>{
      this.employee.salaries = data;
    });
  }
  async add_deduction() {
    this.set_temp();
    const m = await this.modalController.create({component: SelectDeductionPage});
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1){
        this.delete_deduction();
        this.sql.hapticsImpactLight();
      } else {
        this.employee.deductions = [a.data];
        this.employee.deduction_id = a.data.deduction_id;
        this._c=1;
        this.sql.hapticsImpactMedium();
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_deduction(deduction) {
    this.router.navigate(['folder','Deduction', deduction.deduction_id]);
  }
  delete_deduction() {
    this.employee.deductions = null;
    this.employee.deduction_id = null;
    // this.sql.set( 'Employees', 'employee_id', this.employee.employee_id, 'deductions', null);
  }
  async open_dob_calendar() {
    var then = new Date(-2208970800);
    var now = new Date();
    var options:CalendarModalOptions={
      from: then, to: now,
      canBackwardsSelected: true,
      title:this.sql.word.Date_of_Birth,
      defaultDate:this.employee.birthday?this.employee.birthday:null
    };
    var cal = await this.modalController.create({component:CalendarModal,componentProps:{options}});
        cal.present();
    var e: any = await cal.onDidDismiss();
    var date: CalendarResult=e.data;
    this.employee.birthday=date.string;
  }
  set_temp() {
    if (this.employee.employee_id) {
      this.sql.set_temporary('page',{location:'Employee',id:this.employee.employee_id});
    } else {
      this.save((id) =>{
        if(!id)return;
        this.employee.employee_id=id;
        this.sql.set_temporary('page',{location:'Employee',id:id});
      });
    }
  }
  update() { this.sql.update("Employees",this.employee); }
  save(cb?) {
    this.employee.admin_id = this.employee.admin_id?this.employee.admin_id:1;
    this.employee.cycles = this.employee.cycles?this.employee.cycles:0;
    this.employee.total = this.employee.total?this.employee.total:0;
    if (this.employee.first_name)this.employee.name = this.employee.first_name;
    if (this.employee.last_name) this.employee.name = this.employee.name+' '+this.employee.last_name;
    this.sql.add("Employees",this.employee,(insertId)=>{
      if(!insertId) return false;
      if(this.live_save) {
        this.employee.employee_id=insertId;
      } else {
        this._c=0;
        if(!cb) {
          this.employee = new NewEmployee(true);
          this.end(insertId);
        } else {
          cb(insertId);
        }  
      }
    });
  }
  lv() {
    if(this.live_save) {
      this.save();
    }
  }
  ngOnInit() {
    // this.open();
  }
  start_schedule() {
    this.save((insertId)=>{
      this.router.navigate(['folder','Schedule','New','Employee',insertId]);
    });
  }
  start_timer() {
    this.save((insertId)=>{
      this.router.navigate(['folder','Timesheet','New','Employee',insertId]);
    });
  }
  new_payment() {
    this.save((insertId)=>{
      this.router.navigate(['folder','Payment','New','Employee',insertId]);
    });
  }
  new_cheque() {
    this.save((insertId)=>{
      this.router.navigate(['folder','Cheque','New','Employee',insertId]);
    });
  }
  async show_contact_info(item) {
    var buttons=[];
    if(!item.fax&&!item.email&&!item.phone&&!item.skype)return;
    if(item.fax)buttons.push({text: this.sql.word.Fax+' ('+item.fax+')',role: 'fax'});
    if(item.email)buttons.push({text: this.sql.word.Email+' ('+item.email+')',role: 'email'});
    if(item.phone)buttons.push({text: this.sql.word.Call+' ('+item.phone+')',role: 'phone'});
    if(item.skype)buttons.push({text: 'Skype ('+item.skype+')',role: 'skype'});
    buttons.push({text: this.sql.word.Cancel,role: 'cancel'});
    var items = {
      header: this.sql.word.Call,
      subHeader: this.employee.full_name?this.employee.full_name:this.sql.word.New_Call,
      buttons: buttons
    }
    const actionSheet = await this.actionSheetCtrl.create(items);
    await actionSheet.present();
    var result = await actionSheet.onDidDismiss();
    if(result.role=='phone')this.phonecall(item);
    if(result.role=='skype')this.skypecall(item);
    if(result.role=='email')this.email(item);
    if(result.role=='fax')this.fax(item);
  }
  email(item) { this.sql.email(item.email,'',''); }
  phonecall(item) { if(item.phone)this.callNumber.callNumber(item.phone, true); }
  async skypecall(item) { if(item.skype)await Browser.open({ url:'skype:'+item.skype+'?call' }); }
  async fax(item) { if(item.fax)await Browser.open({ url:'fax:'+item.fax }); }
}