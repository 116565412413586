import { SQLService } from '../services/sql.service';
import { fade } from '../app.animations';
import * as Currencies from '../../environments/currencies';
import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { ChequeMenuComponent } from './cheque-menu/cheque-menu.component'
import { Gesture, GestureController, ModalController, PopoverController} from '@ionic/angular';
import { GenerateMultipleChequesComponent } from './generate-cheque/multiple-cheques/multiple-cheques.component';
@Component({
  animations: [fade],
  selector: 'ng-cheques-cheque',
  templateUrl: './cheque.component.html',
  styleUrls: ['./cheque.component.styl']
})
export class ChequeComponent implements OnInit,OnDestroy {
  constructor(
    private router: Router,
    public sql: SQLService,
    public popoverController: PopoverController,
    private modalController: ModalController,
    private gestureCtrl: GestureController
  ) { 
    this.currencies = Currencies;
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_cheques();
    });
  }
  sc=[];
  search='';
  cheques=[];
  selected=[];
  selected_to_print:any;
  count = -1;
  currencies: any;
  verifiedName: boolean;
  print_multiple: boolean;
  printing_multiple: boolean;
  print(c,i?) { this.router.navigate(['folder','Print','Cheque',c.cheque_id,'Print']);}
  view(c,i?) { this.router.navigate(['folder','View','Cheque',c.cheque_id]);}
  add() { this.sql.hapticsImpactLight(); this.router.navigate(['folder','Cheque','New']);}
  filter(e) { this.search=e.target.value;}
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?,event?) {
    if(this.showing_popover)return;
    if(this.sql.model=='ios'||this.sql.model=='electron'||this.sql.model=='web') {
      this.showing_popover=true;
      if(event){event.preventDefault();
        if(this.sql.model=='electron'||this.sql.model=='web')this.lpevent=event;
      }
      this.sql.hapticsImpactMedium();
      var popover = await this.popoverController.create({
        component:ChequeMenuComponent,
        cssClass: 'popover_container',
        translucent: true,
        componentProps: {enabled: item.enabled},
        event: this.lpevent?this.lpevent:false
      });
      popover.present();
      var { data } = await popover.onWillDismiss();
      if (data && item) {
        this.sql.hapticsImpactLight();
        if(data=='delete')this.delete_cheque(item);
        if(data=='print')this.print(item);
      }
      this.showing_popover=false;
      this.lpevent=null;
    }
  }
  get_cheques(event?,cb?) {
    this.sql.fetch('Cheques',(e)=>{
      if(e)this.cheques=e;
      this.count=e.length;
      if(cb)cb(this.cheques);
      setTimeout(()=>{ 
        if (event) event.target.complete(); 
      },1234);
    });
  }
  edit_cheque(c,i?) {
    if(this.print_multiple){
      this.toggle_item(c);
    } else {
      this.router.navigate(['folder','Cheque',c.cheque_id]);
    }
  }
  end() {this.router.navigate(['folder','Dashboard']);}
  delete_cheque(c,i?) {
    if(confirm(this.sql.word.Cheque_Delete_Confirmation)) {
      this.sql.delete('Cheques','cheque_id',c.cheque_id);
      this.get_cheques();
    } else { if(i)i.closeSlidingItems(); }
  }
  paid_cheque(c?,i?) {
    if(confirm(this.sql.word.Cheque_Pay_Confirmation)) {
      this.sql.set('Cheques','cheque_id',c.cheque_id,'paid',true);
      this.get_cheques();
    } else { if(i)i.closeSlidingItems(); }
  }
  print_edit() {
    this.print_multiple = true;
  }
  async print_selected() {
    if(!this.selected||this.selected.length==0){
      this.select_all();
      setTimeout( () =>{
        this.print_selected();
      },200);
    } else {
      this.print_multiple=false;
      this.printing_multiple=true;
      this.selected_to_print=this.selected.join(',');
      this.selected=[];
      this.router.navigate(['folder','Print','Many','Checks',this.selected_to_print]);
    }
  }
  design_selected(){
    
  } 
  import_multiple() {
    this.print_multiple=false;
    localStorage.setItem('import_type','Cheques');
    this.router.navigate(['folder','Import','New']);
  }
  export_selected() {
    
  }
  delete_selected() {
     if(!this.selected || this.selected.length==0){
      alert(this.sql.word.Select_Multiple_Cheques_Prompt);
    } else {
      if(confirm(this.sql.word.Cheque_Multiple_Delete_Confirmation)) {
        var i = 0;
        for(let c of this.selected) {
          this.sql.delete('Cheques','cheque_id',c,(Done)=>{i++;
            if(i==this.selected.length) {
              this.get_cheques(null,()=>{
                this.selected = [];  
              });
            }
          });
        }
        
      }
    }
  }
  edit_selected(){

  }
  toggle_item(item) {
    if( this.selected.includes(item.cheque_id) ) {
      this.deselect_item(item);
    } else {
      this.select_item(item);
    }
  }
  select_item(item) {
    this.selected.push(item.cheque_id);
  }
  deselect_item(item) {
    var new_selection=[];
    for(var i = 0; i < this.selected.length; ++i){
      if(this.selected[i]!=item.cheque_id){
        new_selection.push(this.selected[i]);
      }
    }
    this.selected = new_selection;
  }
  select_all() {
    var new_selection=[];
    for (var i = 0; i < this.cheques.length; ++i) {
      new_selection.push(this.cheques[i].cheque_id);
    }
    this.selected=new_selection;
  }
  deselect_all() {
   this.selected = []; 
  }
  print_done() {
    this.print_multiple = false;
    this.selected = [];
  }
  ngOnInit() {
    this.get_cheques();
  }
  ngOnDestroy(){
    this.print_multiple=false;
  }
}