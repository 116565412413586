import { NewPayPal } from '../../../models/paypal';
import { environment } from '../../../../environments/environment';
import { SelectAddressPage } from '../../../addresses/select-address/select-address.page';
import { SelectEmployeePage } from '../../../employees/select-employee/select-employee.page';
import { SQLService } from '../../../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { Location } from '@angular/common';
@Component({
  selector: 'app-new-paypal-payments',
  templateUrl: './new-paypal-payments.component.html',
  styleUrls: ['./new-paypal-payments.component.scss'],
})
export class NewPaypalPaymentsComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public sql: SQLService,
    private location: Location,
    public modalController: ModalController
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) {
        this.paypals.currency = this.sql.Currency;
        this.paypals.enabled = true;
      }
    });
  }
  _c=0;
  paypals = new NewPayPal();
  currencies:any;
  end() {
    if (this._c>0) {
      if(confirm(this.sql.word.Discard_Confirmation)) {
        this.paypals = new NewPayPal();
        this.router.navigate(['folder','Settings','PayPal']);
      }
    } else { 
      this.router.navigate(['folder','Settings','PayPal']);
    }
  }
  delete_paypal() {
    if(!this.paypals.paypal_id){
      this.end();
    } else if(confirm(this.sql.word.Discard_Confirmation)) {
      this.sql.delete('PayPals','paypal_id',this.paypals.paypal_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  save(end=true) {
    if (this._c>0) {
      this.paypals.email_subject = this.paypals.email_subject?this.paypals.email_subject:this.sql.word.PayPal_Default_Subject;
      this.paypals.email_message = this.paypals.email_message?this.paypals.email_message:this.sql.word.PayPal_Default_Email_Message;
      this.paypals.payment_note = this.paypals.payment_note?this.paypals.payment_note:this.sql.word.PayPal_Default_Payment_Note;
      this.sql.add("PayPals",this.paypals,(a)=>{
        if(!a)return;
        if(end){
          this.paypals = new NewPayPal();
          this._c=0;
          this.end();
        }
      });
    }
  }
  save_paypal_change() {
    this.save(false);
  }
  update() { this.sql.update("PayPals",this.paypals); }
  get_paypals() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida) return;
    this.sql.view('PayPals','paypal_id',ida,(e)=>{
      var f = Object.entries(e[0]); let n=new NewPayPal();
      for( let t of f) n[t[0] ]=t[1]; this.paypals = n;
    });
  }
  async paypal_applications() {
    await Browser.open({ url:'https://www.paypal.com/signin?returnUri=https%3A%2F%2Fdeveloper.paypal.com%2Fdashboard%2F&intent=developer&ctxId=ul02c764aa740e4a3e91098ced08f1a7c0' });
  }
  open() {
    this.currencies=[];
    for(var [key, value] of Object.entries(environment.currencies))if(value.paypal)this.currencies.push(value);
    this.paypals.currency = this.sql.Currency;
    this.paypals.production_mode = 1;
    this.paypals.production = 1;
    this.paypals.enabled = true;
    this.paypals.email_subject = this.sql.word.PayPal_Default_Subject;
    this.paypals.email_message = this.sql.word.PayPal_Default_Email_Message;
    this.paypals.payment_note = this.sql.word.PayPal_Default_Payment_Note;
    this.get_paypals();
  }
  ngOnInit() {
    this.open();
  }
}