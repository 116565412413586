import { SQLService } from '../../../services/sql.service';
import { PaymentsService } from '../../../services/payments.service';
import { ColorPickerComponent } from '../color-picker/color-picker.component';
import { PurchaseComponent } from '../../../dashboard/purchase/purchase.component';
import { ChequeDesignerComponent } from '../cheque-designer/cheque-designer.component';
import { DocugardColors } from '../../templates_html';
import { Flip } from '../../../app.animations';
import { NewDesign } from '../../../models/design';
import { PaperSizes } from '../../../../environments/papersizes';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router,NavigationEnd,ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ImagePreviewComponent } from '../image-preview/image-preview.component'
import { ModalController, NavParams } from '@ionic/angular';
import { backgrounds } from '../../../../environments/logos';
@Component({
  selector: 'app-select-design',
  templateUrl: './select-design.page.html',
  styleUrls: ['./select-design.page.styl'],
  animations: [ Flip ]
})
export class SelectChequeDesignsPage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    private payments: PaymentsService,
    private sql: SQLService,
    private params: NavParams,
    private location: Location
  ) {
    this.selected_design = params.get('design_pattern');
    this.docugard = params.get('docugard');
    this.cheque_id = params.get('check_id');
    this.show_buttons = params.get('show_buttons');
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) {
        this.get_designs();
      }
    });
  }
  default_design=new NewDesign();
  count = -1;
  designs = [];
  papers = [];
  colors = [];
  output = new EventEmitter();
  docugard:boolean;
  viewing='customize';
  show_buttons:boolean;
  selected_design:any;
  search:string;
  cheque_id:any;
  filter(e) { this.search=e.target.value; }
  ngOnInit() {
    this.papers=PaperSizes;
    this.colors=DocugardColors;
    this.get_designs();
  }
  get_designs(event?) {
    this.sql.fetch('Designs',(e)=>{
      this.designs=e; this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  save_design(d) {
    // if(d&&d.design_pattern)this.sql.updateObj("Designs",d);
  }
  set_default(design,i?) {
    this.default_design=design;
    this.sql.set_all("Designs","default_design",false,(cb)=>{ 
      design.default_design=true;
      this.save_design(design)
    });
  }
  buy_now() {
    this.sql.show_purhchase_modal();
  }
  purchase() {
    this.end();
    this.beggar();
  }
  preview_design(design) {
    this.preview(design.preview,design.title);
  }
  async preview(img,title?) {
    const m = await this.navCtrl.create({
      component: ImagePreviewComponent,
      componentProps: {src:img,title:title}
    });
    await m.present();
    let a = await m.onDidDismiss();console.log(a);
    //if(a.data==1)
  }
  async beggar() {
    const m = await this.navCtrl.create({
      component: PurchaseComponent, 
      componentProps: {}
    });
    await m.present();
    var a = await m.onDidDismiss();
  }
  async background() {
    const m = await this.navCtrl.create({
      component: ColorPickerComponent,
      componentProps: {
        hex:this.default_design['background']
      }
    });
    await m.present();
    let a = await m.onDidDismiss();
    if (a.data) {
      console.log('background',a.data)
      this.default_design['background']=a.data;
      // this.cheque_background_color=a.data;
      // this.background_color.emit(this.cheque_background_color);
      // this.done();
    }
  }
  mod_design() {
    this.router.navigate(['folder','Designer',this.selected_design]);
  }
  async edit_design(design,i?) {
    const m = await this.navCtrl.create({
        component: ChequeDesignerComponent, componentProps: {
        cheque_id: this.cheque_id,
        design_id: design.design_id,
        design: design,
        design_pattern: design.design_pattern
      }
    });
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      console.log('Cheque Design Component Done:', a);
      design.background_colour = a.data.background_colour;
      design.font = a.data.font;
      this.select(this.default_design);
    }
  }
  contains(word,contents) {
    if(!word)return 0;
    if(contents&&contents.indexOf(word)>-1) return 1;
    return 0;
  }
  end() {
    this.sql.hapticsImpactLight();
    this.navCtrl.dismiss(false);
  }
  pro_() {
    if(this.sql.Trial) {this.sql.show_purhchase_modal();}
  }
  select_none() {
    this.sql.hapticsImpactLight();
    this.navCtrl.dismiss(-1);
  }
  select(e) {
    if(e.pro&&this.sql.Trial) {
      // this.sql.show_purhchase_modal(); 
    } else {
      this.sql.hapticsImpactLight();
      this.navCtrl.dismiss(e.design_id);
    }
  }
  delete(c,i?) {
    if(confirm(this.sql.word.Design_Delete_Confirmation)) {
      this.sql.delete('Designs','design_id',c.design_id);
      this.get_designs();
    } else { i.closeSlidingItems(); }
  }
  add() {
    this.get_designs();
    // this.router.navigate(['folder','Cheque','Design','New']);
    // this.navCtrl.dismiss();
  }
}