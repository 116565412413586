import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { SQLService } from '../../../services/sql.service';
@Component({
  selector: 'app-geolocation',
  templateUrl: './geolocation.component.html',
  styleUrls: ['./geolocation.component.styl'],
})
export class GeolocationComponent implements OnInit {
  constructor(private sql: SQLService, private router: Router) { }
  status = 'loading';
  @Output('login') _login = new EventEmitter();
  check() {
    if(this.status=='denied'){
      this.get_geo();
      this.status ='loading';
      return; 
    }
    this.sql.check_geo((result)=>{
      if(result==400) {
        this.status = 'loading';
        this._login.emit(true);
      } else if (result==200) {
        this.status ='ok';
        this._login.emit(true);
      } else if (result==500) {
        this.status ='error';
      } else if (result==600) {
        this.status ='denied';
      }
    });
  }
  get_geo() {
    this.sql.getGeoPermissions();
  }
  ngOnInit() {
    this.status = 'loading';
    this.check();
  } 
}