/**
 * @cheques
 * @sql
 * Cheque designs.
 */
export {DocuGard_Mailer_Top, DocuGard_Mailer_Top_Properties} from './docugard_mailer_top';
export {DocuGard_Mailer_Middle, DocuGard_Mailer_Middle_Properties} from './docugard_mailer_middle';
export {DocuGard_Mailer_Bottom, DocuGard_Mailer_Bottom_Properties} from './docugard_mailer_bottom';
export {DocuGard_Table_Top, DocuGard_Table_Top_Properties} from './docugard_table_top';
export {DocuGard_Table_Middle, DocuGard_Table_Middle_Properties} from './docugard_table_middle';
export {DocuGard_Table_Bottom, DocuGard_Table_Bottom_Properties} from './docugard_table_bottom';
export {DocuGard_Cheque_Top, DocuGard_Cheque_Top_Properties} from './docugard_cheque_top';
export {DocuGard_Cheque_Middle, DocuGard_Cheque_Middle_Properties} from './docugard_cheque_middle';
export {DocuGard_Cheque_Bottom, DocuGard_Cheque_Bottom_Properties} from './docugard_cheque_bottom';
export {DocuGard_Three_Cheques, DocuGard_Three_Cheques_Properties} from './docugard_three_cheques';
export {Cheque_Golden,Cheque_Golden_Properties} from './cheque_golden';
export {Cheque_Green,Cheque_Green_Properties} from './cheque_green';
export {Cheque_Red,Cheque_Red_Properties} from './cheque_red';
export {Cheque_Yellow,Cheque_Yellow_Properties} from './cheque_yellow';
export {SingleCheque, SingleCheque_Properties} from './single_cheque';
export {Cheque_Table, Cheque_Table_Properties} from './cheque_table';
export {Posted_Cheque, Posted_Cheque_Properties} from './posted_cheque';
export {Three_Cheques, Three_Cheques_Properties} from './3_cheques';
export {Cashiers_Check, Cashiers_Check_Properties }  from './cashiers_cheque';
export {Cheque_Aqua_Checkers, Cheque_Aqua_Checkers_Properties } from './cheque_aqua_checkers';
export {Cheque_Blue_Checkers, Cheque_Blue_Checkers_Properties } from './cheque_blue_checkers';
export {Cheque_Golden_Checkers, Cheque_Golden_Checkers_Properties } from './cheque_golden_checkers';
export {Cheque_Golden_Weave, Cheque_Golden_Weave_Properties } from './cheque_golden_weave';
export {Cheque_Green_Sun, Cheque_Green_Sun_Properties } from './cheque_green_sun';
export {Cheque_Green_Wave, Cheque_Green_Wave_Properties } from './cheque_green_wave';
export {Cheque_Statue_of_Liberty, Cheque_Statue_of_Liberty_Properties } from './cheque_statue_of_liberty';
export {Fonts } from './fonts';
export const Templates = [
    "Posted_Cheque",
    "Cheque_Table",
    "DocuGard_Mailer_Top",
    "DocuGard_Table_Top",
    "DocuGard_Cheque_Top",
    "Cashiers_Check",
    "Cheque_Blue_Checkers",
    "Cheque_Aqua_Checkers",
    "Cheque_Green_Sun",
    "Cheque_Green_Wave",
    "Cheque_Statue_of_Liberty",
    "Cheque_Golden_Checkers",
    "Cheque_Golden_Weave",
    "Cheque_Golden",
    "Cheque_Green",
    "Cheque_Red",
    "Cheque_Yellow",
    "SingleCheque",
    "Three_Cheques",
    "DocuGard_Three_Cheques"
];