import { SQLService } from '../../services/sql.service';
import { NewLeave } from '../../models/leave';
import { environment } from '../../../environments/environment';
import { SelectEmployeePage } from '../../employees/select-employee/select-employee.page';
import { SelectPayeePage } from '../../payees/select-payee/select-payee.page';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { Location } from '@angular/common';
@Component({
  selector: 'ng-cheques-new-leave',
  templateUrl: './new-leave.component.html',
  styleUrls: ['./new-leave.component.styl']
})
export class NewLeavePage implements OnInit {
  _c = 0;
  currencies = Object.keys(environment.currencies);
  leave = new NewLeave();
  constructor(
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    public modalController: ModalController,
    public sql: SQLService
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.open();
    });
  }
  async add_employee() {
    const m = await this.modalController.create({
      component: SelectEmployeePage, 
      componentProps: {select_multiple: true}
    });
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data){
      if(a.data==-1){
        this.delete_employee();
        this.sql.hapticsImpactLight();
      } else {
        this.leave.employee_id = a.data;
        this.leave.employee_ids = [a.data];
        this.get_employees();
        this._c=1;
        this.sql.hapticsImpactMedium();
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_employee(employee) {
    this.router.navigate(['folder','Employee', employee.employee_id]); 
  }
  delete_employee() {
    this.leave.employee_ids = null;
    this.leave.employees = null;
    // this.sql.set('leave','leave_id',this.leave.leave_id,'employees',null);
  }
  delete_leave() {
    if(!this.leave.leave_id){
      this.end();
    } else if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('Leaves','leave_id',this.leave.leave_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  get_employees() {
    if(!this.leave.employee_ids)return;
    if(typeof this.leave.employee_ids === 'string'){
      this.leave.employee_ids=this.leave.employee_ids.split(',');
    } else if (typeof this.leave.employee_ids === 'number') {
      this.leave.employee_ids=[this.leave.employee_ids];
    }
    this.leave.employees=[];
    for(let a of this.leave.employee_ids) {
      this.sql.view('Employees','employee_id',a,(data)=>{
        for(let d of data)this.leave.employees.push(d);
      });
    }
  }
  async add_payee() {
    const m = await this.modalController.create({
      component: SelectPayeePage, 
      componentProps: {select_multiple: true}
    });
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1) {
        this.delete_payee();
        this.sql.hapticsImpactLight();
      } else {
        this.leave.payee_ids = a.data;
        this.leave.payee_id = [a.data];
        this.get_payees();
        this._c=1;
        this.sql.hapticsImpactMedium();
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_payee(payee) {
    this.router.navigate(['folder','Payee', payee.payee_id]); 
  }
  delete_payee() {
    this.leave.payee_ids = null;
    this.leave.payees = null;
    // this.sql.set('Payment','payment_id',this.payment.payment_id,'payees',null);
  }
  get_payees() {
    if(!this.leave.payee_ids)return;
    if(typeof this.leave.payee_ids === 'string'){
      this.leave.payee_ids=this.leave.payee_ids.split(',');
    } else if (typeof this.leave.payee_ids === 'number') {
      this.leave.payee_ids=[this.leave.payee_ids];
    }
    this.leave.payees=[];
    for(let a of this.leave.payee_ids) {
      this.sql.view('Payees','payee_id',a,(data)=>{
        for(let d of data)this.leave.payees.push(d);
      });
    }
  }
  end(id?) {
    if (this._c>0) {
      if (confirm(this.sql.word.Discard_Confirmation)) {
        this.leave = new NewLeave();
        this.router.navigate(['folder','Leaves']);
      }
    } else {
      this.router.navigate(['folder','Leaves']);
    }
  }
  set_temp() {
    if (this.leave.leave_id) {
      this.sql.set_temporary('page',{location:'Leave',id:this.leave.leave_id}); 
    } else { 
      this.save(false,(id) =>{
        if(!id)return;
        this.leave.leave_id=id;
        this.sql.set_temporary('page',{location:'Leave',id:id});
      });
    }
  }
  get_leaves_of_absense() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida) return;
    this.sql.view('Leaves','leave_id',ida,(e)=>{
      var f=Object.entries(e[0]); let n = new NewLeave();
      for(let t of f)n[t[0]]=t[1];
      this.leave = n;
      this.get_employees();
      this.get_payees();
    });
  }
  save(end=true,cb?) {
    this.sql.add("Leaves",this.leave,(insertID)=>{
      if(!insertID)return
      if(end) {
        this.leave = new NewLeave();
        this._c=0;
        this.end(insertID);
      }
    });
  }
  open() {
    var d = new Date();
    this.leave.start_date = d;
    var date = new Date();
    var start_time = date.setHours(9,0,0);
    this.leave.start_time = new Date(start_time).toISOString();
    var date = new Date();
    var end_time = date.setHours(17,0,0);
    this.leave.end_time = new Date(end_time).toISOString();
    this.leave.start_date = new Date(Date.now()).toISOString();
    this.leave.enabled=true;
    this.leave.paytype='percentage';
    this.leave.currency=this.sql.Currency;
    this.get_leaves_of_absense();
  }
  ngOnInit() {
    // this.open();
  }
} 