import { SQLService } from '../../../services/sql.service';
import { Flip } from '../../../app.animations';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController, NavParams } from '@ionic/angular';
@Component({
  selector: 'app-select-bank',
  templateUrl: './select-bank.page.html',
  styleUrls: ['./select-bank.page.styl'],
  animations: [ Flip ]
})
export class SelectBankPage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    private params: NavParams,
    public sql: SQLService,
    private location: Location
  ) {
    var s = params.get('selected');
    if(s)this.selected = s.split(',');
    this.multiple = params.get('select_multiple');
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_banks();
    });
  }
  count = -1;
  banks = [];
  output = new EventEmitter();
  selected = [];
  multiple: boolean;
  ngOnInit() {
    this.get_banks();
  }
  get_banks(event?) {
    this.sql.fetch('BankAccounts',(e)=>{
      this.banks=e;
      this.count=e.length;
      setTimeout(()=>{if (event) event.target.complete();},1234);
    });
  }
  select(e) {
    if(this.multiple){
      this.toggle_item(e);
    } else {
      this.navCtrl.dismiss(e.bank_id);
    }
  }
  done_multiple() {
    this.navCtrl.dismiss(this.selected.join(','));
  }
  toggle_item(item) {
    if( this.selected.includes(item.bank_id) ) {
      this.deselect_item(item);
    } else {
      this.select_item(item);
    }
  }
  select_none() {this.navCtrl.dismiss(-1);}
  select_item(item) {
    console.log(item.bank_id);
    this.selected.push(item.bank_id);
  }
  deselect_item(item) {
    var new_selection=[];
    for(var i = 0; i < this.selected.length; ++i){
      if(this.selected[i]!=item.bank_id){
        new_selection.push(this.selected[i]);
      }
    }
    this.selected = new_selection;
  }
  select_all() {
    var new_selection=[];
    for (var i = 0; i < this.banks.length; ++i) {
      new_selection.push(this.banks[i].bank_id);
    }
    this.selected=new_selection;
  }
  deselect_all() {
   this.selected = []; 
  }
  add() {
    this.sql.count('BankAccounts',(c)=>{
      if(this.sql.Trial && this.sql.Trial_Bank_Accounts!=-1 && c>=this.sql.Trial_Bank_Accounts) {
        this.sql.show_purhchase_modal();
      } else {
        this.router.navigate(['folder','Account','New']);
      }
    });
    this.navCtrl.dismiss();
  }
  edit(bank, item?) {
    this.router.navigate(['folder','Account',bank.bank_id]);
    this.navCtrl.dismiss();
  }
  delete(bank, item) { 
    if(confirm(this.sql.word.Discard_Bank_Confirmation)) {
      this.sql.delete('BankAccounts','bank_id',bank.bank_id);
      this.get_banks();
    } else { item.closeSlidingItems(); }
  }
  off() {
    // this.location.back();
    if(this.multiple){
      this.done_multiple();
     } else {
      this.navCtrl.dismiss();
     }
  }
}