import { Component, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { SQLService } from '../../../services/sql.service';
import { backgrounds, backgrounds_lowrez } from '../../../../environments/logos';
@Component({
  selector: 'app-cheque-golden-checkers',
  templateUrl: './cheque-golden-checkers.component.html',
  styleUrls: ['./cheque-golden-checkers.component.scss'],
})
export class ChequeGoldenCheckersComponent implements AfterViewInit {
  constructor(private sql: SQLService) { }
  @Output('loaded') loaded = new EventEmitter<boolean>();
  @Input('RenderMICR') RenderMICR = false;
  @Input('ImageMICR') ImageMICR = true;
  @Input('show') show = 'table';
  @Input('cheque') cheque: any;
  @Input('design') design: any;
  @Input('designer') designer: any;
  @Input('rotate') rotate: boolean;
  @Input('borders') borders: boolean;
  @Input('voidcheck') voidcheck: boolean;
  @Input('font') font: any;
  @Input('fiat') fiat: boolean;
  @Input('color') color: any;
  @Input('micr') micr: any;
  micr_top=false;
  micr_right=false;
  micr_bottom='7mm';
  micr_left='9mm';
  @Input('secret') secret = '';
  @Input('scale') scale =1;
  @Input('fold_line') fold_line: boolean;
  @Input('template') template: string;
  @Input('monochrome') monochrome: boolean;
  @Input('gradients') gradients: boolean;
  @Input('margin_bottom') bottom = '0mm';
  @Input('margin_top') top = '0mm';
  @Input('fontsize') fontsize: boolean;
  @Input('layers') layers: any;
  @Input('translations') translations = this.sql.word;
  @Input('bold') bold: boolean;
  @Input('italic') italic: boolean;
  @Input('show_cheque_number') show_cheque_number: boolean = true;
  @Input('show_cheque_date') show_cheque_date: boolean = true;
  @Input('show_micr') show_micr: boolean = true;
  @Input('cheque_background_image_repeat') cheque_background_image_repeat = false;
  @Input('background_position') background_position: string;
  @Input('cheque_text_color') cheque_text_color: string;
  @Input('cheque_background_image') cheque_background_image: any;
  @Input('cheque_logo_image') cheque_logo_image: any;
  @Input('cheque_header_image') cheque_header_image: any;
  @Input('cheque_bank_image') cheque_bank_image: any;
  @Input('doc_color') doc_color: string = 'gold';
  bg_image: string;
  bank_bottom:boolean;
  loaded_component() {this.loaded.emit(true);}
  ngAfterViewInit() {
    this.design["height"]='69mm';
    this.design["date"]["top"]='16mm';
    this.design["date"]["right"]='39mm';
    this.design["date"]["alternative"]=true;
    this.design["date_label"]["display"]=false;
    this.design["payline"]["prefix"]=false;
    this.design["payline"]["borders"]=false;
    this.design["payline"]["left"]='132px';
    this.design["payline"]["right"]='27px';
    this.design["payline"]["bottom"]='4px';
    this.design["payline"]["top"]='23mm';
    this.design["printed_id"]["top"]='27.3px';
    this.design["printed_id"]["right"]='45px';
    this.design["payment_name"]["left"]="-5px";
    this.design["payment_name"]["bottom"]="0px";
    this.design["written_amount"]["top"]='34.3mm';
    this.design["written_amount"]["right"]='30px';
    this.design["written_amount"]["bottom"]='39mm';
    this.design["written_amount"]["left"]='11.5mm';
    this.design["written_amount_line"]["width"]='550px';
    this.design["written_amount"]["index"]=false;
    this.design["written_amount_line"]["borders"]=false;
    this.design["written_amount_line"]["overflow"]=true;
    this.design["written_amount_line"]["top"]='0px';
    this.design["written_amount_line"]["right"]='0px';
    this.design["written_amount_line"]["bottom"]='0px';
    this.design["written_amount_line"]["left"]='0px';
    this.design["multiline_written_amount_chars"]=49;
    this.design["written_amount_line2"]["top"]='4.4mm'
    this.design["written_amount_line2"]["right"]='0mm'
    this.design["written_amount_line2"]["bottom"]='0mm'
    this.design["written_amount_line2"]["left"]='0mm'
    this.design["amount_number"]["top"]="1.1mm";
    this.design["amount_number"]["right"]="auto";
    this.design["amount_number"]["left"]="-16.7mm";
    this.design["amount_number"]["borders"]=false;
    this.design["amount_number"]["background"]=false;
    this.design["amounts"]["symbol"]=false;
    this.design['bank_image']['top']=this.bank_bottom?'39.6mm':'12.7mm';
    this.design['bank_image']['left']='10mm';
    this.design['bank_name']['top']=this.bank_bottom?'39.6mm':'12.3mm';
    this.design['bank_name']['left']='23mm';
    this.design['bank_address']['top']=this.bank_bottom?'42.5mm':'15.5mm';
    this.design['bank_address']['left']='23mm';
    this.design['bank_address_2']['top']=this.bank_bottom?'44.9mm':'18.1mm';
    this.design['bank_address_2']['left']='23mm';
    this.design['bank_address_3']['top']=this.bank_bottom?'47.3mm':'20.7mm';
    this.design['bank_address_3']['left']='23mm'; 
    this.design["memo"]["label"]=false;
    this.design["memo"]["border"]=false;
    this.design["memo"]["left"]='14.8mm';
    this.design["memo"]["bottom"]='13.9mm';
    this.design["public_image"]["top"]='32.5mm';
    this.design["public_image"]["left"]='128.8mm';
    this.design["public_image"]["width"]='77';
    this.design["public_image"]["zindex"]='1';
    this.design['public_image']['QRcolorDark']="#543b25";
    this.design['public_image']['QRcolorLight']="#efeeec";
    this.design['public_image']['QRCodeMargin']="1";
    this.design["key_image"]["width"]='77';
    this.design["key_image"]["left"]='11.5mm';
    this.design["key_image"]["top"]='7mm';
    this.design["key_image"]["zindex"]='1';
    this.design['key_image']['QRcolorDark']="#543b25";
    this.design['key_image']['QRcolorLight']="#efeeec";
    this.design['key_image']['QRCodeMargin']="1";
    this.design["currency_name"]["top"]='13.9mm';
    this.design["currency_name"]["left"]='31.5mm';
    this.design["currency_name"]["width"]='53mm';
    this.design["address_data"]["top"]='17.5mm';
    this.design["address_data"]["right"]='75mm';
    this.design["address_data"]["bottom"]='auto';
    this.design["address_data"]["left"]='31.5mm';
    this.design["address_data"]["width"]='113mm';
    this.design["signature"]["container_width"]='46mm';
    this.design["signature"]["width"]='180px';
    this.design["signature"]["border"]=false;
    this.design["signature"]["label"]=false;
    this.design["signature"]["right"]='auto';
    this.design["signature"]["bottom"]='16.3mm';
    this.design["signature"]["left"]='99.5mm';
    // this.design["signature"]["info"]["top"]='0mm';
    this.design["signature"]["info"]["left"]='3.6mm';
    // this.design["signature"]["desc"]["top"]='0mm';
    this.design["signature"]["desc"]["right"]='4.8mm';
    this.design["micr"]["top"]='auto';
    this.design["micr"]["right"]='auto';
    this.design["micr"]["bottom"]='7mm';
    this.design["micr"]["left"]='9mm';
    this.design["micr"]["background"]=true;
    if(this.sql.model=='web') {
      var img = "goldencheckers";
      this.sql.load_background(img,(base64Img)=>{
        this.bg_image=base64Img;
      },this.sql.production);
    } else {
      this.bg_image = this.sql.production?backgrounds.goldencheckers:backgrounds_lowrez.goldencheckers;
    }
}
}