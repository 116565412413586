export const fonts = [
    {"name":"Arial", "family":"Arial"},
    {"name":"Verdana", "family":"Verdana"},
    {"name":"Helvetica", "family":"Helvetica"},
    {"name":"Tahoma", "family":"Tahoma"},
    {"name":"Trebuchet", "family":"Trebuchet"},
    {"name":"Times New Roman", "family":"Times New Roman"},
    {"name":"Georgia", "family":"Georgia"},
    {"name":"Garamond", "family":"Garamond"},
    {"name":"Courier New", "family":"Courier New"},
    {"name":"Brush Script MT", "family":"Brush Script MT"}
]