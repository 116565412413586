import { SQLService } from '../../services/sql.service';
import { PrinterMenuComponent } from './printer-menu/printer-menu.component';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { PopoverController} from '@ionic/angular';
import { Router,NavigationEnd } from '@angular/router';
@Component({
  selector: 'ng-cheques-printers',
  templateUrl: './printers.component.html',
  styleUrls: ['./printers.component.scss']
})
export class PrintersComponent implements OnInit {
  constructor(
    public location: Location,
    public sql: SQLService,
    public popoverController: PopoverController,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_printers();
    });
  }
  count = -1; 
  printers = [];
  search:string;
  default_printer:string;
  filter(e) { this.search=e.target.value; }
  go(p) {this.router.navigate(['folder','Printers',p.printer_id]);}
  add() {this.router.navigate(['folder','Printers','New']);}
  end() {this.location.back()}
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:PrinterMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='test')this.test(item);
      if(data=='default')this.default(item);
      if(data=='delete')this.delete(item);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  get_printers(event?) {
    this.sql.fetch('Printers',(e)=>{
      this.printers=e;
      this.count=e.length;
      this.sql.get_setting('default_printer',(s)=>{
        if(!s) return;
        this.default_printer=s.option;
      });
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  test(printer,item?) {
    if(confirm(this.sql.word.Test_Printer_Confirmation)) {
      this.sql.test_printer(printer);
      item.closeSlidingItems();
    } else { item.closeSlidingItems(); }
  }
  edit(printer, item?) {
    this.router.navigate(['folder','Printer',printer.printer_id]);
  }
  default(printer, item?) {
    this.sql.save_setting('default_printer',printer.printer_id,(a)=>{
      this.get_printers();
    });
  }
  delete(printer, item?) {
    if(confirm(this.sql.word.Delete_Printer_Confirmation)) {
      this.sql.delete('Printers','printer_id',printer.printer_id);
      this.get_printers();
    } else { if(item)item.closeSlidingItems(); }
  }
  ngOnInit() {
    this.get_printers();
  }
}
