import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Router,NavigationEnd } from '@angular/router';
import { SQLService } from '../../../services/sql.service';
import { colours } from './swatches';
import { opacity } from '../../../app.animations';
@Component({
  selector: 'color-picker',
  animations:[opacity],
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit {
  constructor(
    public sql: SQLService,
    private navCtrl: ModalController,
    private params: NavParams,
    private router: Router) {
    this.default = params.get('default');
    this.background = params.get('hex');
    this.colour.HEX = params.get('hex');
    this.colour.mode = params.get('mode');
  }
  _c=0;
  _t: any;
  @Input('cheque') previewCheque = false;
  @Input('default') default:any = false;
  show_preview_notice=false;
  extension=false;
  picking_color=false;
  @Input('color') preview_notice = '#000';
  colours = colours;
  background = 'rgb(0,0,0)';
  colour = new Colours('hsl');
  ngOnInit() {
    this.hex_hsl(this.colour.HEX);
    this.hexToRgb(this.colour.HEX);
    if(!this.colour.mode){
      var ofmd=localStorage.getItem('ormd');
      if(ofmd)this.colour.mode=ofmd;
     }
  }
  changed_mode(e?) {this._c=1;
    this.colour.mode=e;
    localStorage.setItem('ormd',e);
  }
  set_default() {
    if(this.default){
      this.background=this.default;
      this.colour.HEX=this.default;
      this.preview_notice=this.default; 
      this.change_color_notice();
    }
  }
  change_color_notice() {
    this.show_preview_notice=true;
    setTimeout( () => {this.show_preview_notice=false;},1500);
  }
  chaged_rgb() {
    if(this.sql.verbose)console.log('Chaged_rgb',this.background);
    this.colour.R=isNaN(this.colour.R)?0:this.colour.R;
    this.colour.G=isNaN(this.colour.G)?0:this.colour.G;
    this.colour.B=isNaN(this.colour.B)?0:this.colour.B;
    this.rgb_hsv(this.colour.R,this.colour.G,this.colour.B);
    this.background = 'rgb('+this.colour.R+','+this.colour.G+','+this.colour.B+')';
    this.preview_notice = 'R:'+this.colour.R+' G:'+this.colour.G+' B:'+this.colour.B;
    this.change_color_notice();
    this.rgbToHex(this.colour.R,this.colour.G,this.colour.B);
  }
  chaged_hsl() {
    if(this.picking_color)return;
    if(this.sql.verbose)console.log('Chaged_hsl',this.background);
    this.colour.H=isNaN(this.colour.H)?0:this.colour.H;
    this.colour.S=isNaN(this.colour.S)?0:this.colour.S;
    this.colour.L=isNaN(this.colour.L)?0:this.colour.L;
    this.hsl_hex(this.colour.H,this.colour.S,this.colour.L, () => {
      this.preview_notice = this.colour.HEX;
      this.background = this.colour.HEX;
      this.hexToRgb(this.colour.HEX);
      this.change_color_notice();
    });
  }
  pick_color(c) {
    clearTimeout(this._t);
    this.picking_color=true;
    if(this.sql.verbose)console.log('Color Picked', c);
    this.colour.HEX=c.color;
    this.background=c.color;
    console.log(this.sql.Language)
    if(this.sql.Language=='En'){
      this.preview_notice=c.name;
    } else {
      this.preview_notice=c.color;
    }
    this.change_color_notice();
    this.hex_hsl(this.colour.HEX);  
    this.hexToRgb(this.colour.HEX);
    this._t=setTimeout(()=>{
      this.picking_color=false;  
    },2000);
  }
  rgb_hsv(r,g,b) { //@mjackson 
    r /= 255, g /= 255, b /= 255;
    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, v = max;
    var d = max - min;
    s = max == 0 ? 0 : d / max;
    if (max == min) {
      h = 0;
    } else {
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
      h /= 6;
    }
    this.colour.H=h*100; this.colour.S=s*100; this.colour.V=v*100;
  }
  hex_hsl(hex,cb?) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);
    r /= 255, g /= 255, b /= 255;
    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;
    if(max == min){
        h = s = 0; // achromatic
    } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch(max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }
    s = s*100;
    s = Math.round(s);
    l = l*100;
    l = Math.round(l); 
    h = Math.round(360*h);
    this.colour.H=h;
    this.colour.S=s;
    this.colour.L=l;
    if(cb)cb();
  }
  hsl_hex(h,s,l,b?) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    this.colour.HEX=`#${f(0)}${f(8)}${f(4)}`;
    if(b)b();
  }
  hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    this.colour.R= parseInt(result[1], 16),
    this.colour.G= parseInt(result[2], 16),
    this.colour.B= parseInt(result[3], 16)
  }
  rgbToHex(r, g, b) {
    this.colour.HEX=`#${this.valueToHex(r)}${this.valueToHex(g)}${this.valueToHex(b)}`;
  }
  valueToHex(c) { // @SharqaHameed
    var hex = c.toString(16);
    return hex;
  }
  done() {
    this.sql.hapticsImpactLight();
    this.navCtrl.dismiss(this.colour.HEX);
  }
  end() {this.done();}
  close() {
    this.sql.hapticsImpactLight();
    this.navCtrl.dismiss(false);
  }
}
export class Colours {
   constructor(
     public mode?: string,
     public R?: number,
     public G?: number,
     public B?: number,
     public H?: number,
     public S?: number,
     public L?: number,
     public V?: number,
     public HEX?: string,
   ) {}
}