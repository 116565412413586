import { Component, OnInit, Input, ViewChild, HostListener,Output,EventEmitter } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { SQLService } from '../../../../services/sql.service';
import { NewEmployee } from '../../../../models/employee';
import { NewSettings } from '../../../../models/settings';
import { page,fade,fade_in,display } from '../../../../app.animations';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { MenuController } from '@ionic/angular';
@Component({
  selector: 'app-intro-page-5',
  templateUrl: './5-fith.page.html',
  styleUrls: ['./5-fith.page.styl'],
})
export class IntroFithPageComponent implements OnInit {
  @Input('save') _save:boolean
  @Output('complete') complete = new EventEmitter();
  @ViewChild('intro_slider') slider: IonSlides;
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
  }
  constructor(
    public menu: MenuController,
    public sql: SQLService,
    public router: Router) { 
  }
  ngOnInit() {
  }
}