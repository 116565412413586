import { Flip } from '../app.animations';
import { SQLService } from '../services/sql.service';
import { PayrollService } from '../services/payroll.service';
import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { PopoverController} from '@ionic/angular';
import { ActionSheetController } from '@ionic/angular';
import { PayrollMenuComponent } from './payroll-menu/payroll-menu.component';
@Component({
  selector: 'ng-cheques-payroll',
  animations: [Flip],
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.styl']
})
export class PayrollComponent implements OnInit {
  constructor(
    public router: Router,
    public sql: SQLService,
    public payroll: PayrollService,
    public popoverController: PopoverController,
    public actionSheetController: ActionSheetController
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_payrolls();
    });
  }
  count = -1;
  payrolls=[];
  showInfo=[];
  showList=[];
  search:string;
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:PayrollMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='enable')this.enable(item);
      if(data=='disable')this.disable(item);
      if(data=='delete')this.delete(item, false);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  get_payrolls(event?) {
    this.sql.fetch('Payrolls',(e)=>{
      e.employees=[];
      if(e)this.payrolls=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  filter(e) {this.search=e.target.value;}
  edit(p,i?) {
    this.router.navigate(['folder','Payroll',p.payroll_id]);
    if(i)i.closeSlidingItems();
  }
  end() {this.router.navigate(['folder','Dashboard']);}
  delete(p,i?) {
    if(confirm(this.sql.word.Delete_Payroll_Confirmation)) {
      this.sql.delete('Payrolls','payroll_id',p.payroll_id);
      this.get_payrolls();
    } else { 
      if(i)i.closeSlidingItems();
    }
  }
  enable(payroll,i?){
    this.sql.set('Payrolls','payroll_id',payroll.payroll_id,'enabled',1,(res)=>{
      payroll.enabled = true;
      if(i)i.closeSlidingItems();
    });
  }
  disable(payroll,i?){
    this.sql.set('Payrolls','payroll_id',payroll.payroll_id,'enabled',0,(res)=>{
      payroll.enabled = false;
      if(i)i.closeSlidingItems();
    });
  }
  print(p,i?) {
    this.router.navigate(['folder','Payroll','Print',p.payroll_id]);
    if(i)i.closeSlidingItems();
  }
  add() {
    this.sql.count('Payrolls',(c)=>{
      if(this.sql.Trial && this.sql.Trial_Payrolls!=-1 && c>=this.sql.Trial_Payrolls) {
        this.sql.show_purhchase_modal();
      } else {this.router.navigate(['folder','Payroll','New']);}
    });
  }
  run_all() {
    this.router.navigate(['folder','Payroll','All','Preview']);
  }
  run(p) {
    this.router.navigate(['folder','Payroll',p.payroll_id,'Preview']);
  }
  ngOnInit() {
    this.get_payrolls();
  }
}