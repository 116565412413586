import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-deposit',
  templateUrl: './view-deposit.component.html',
  styleUrls: ['./view-deposit.component.scss'],
})
export class ViewDepositComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
