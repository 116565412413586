import { SQLService } from '../../../services/sql.service';
import { SelectPrintJobsPage } from '../jobs/select-printjob/select-printjob.page';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router,NavigationEnd } from '@angular/router';
import { ModalController, Platform, LoadingController } from '@ionic/angular';
@Component({
  selector: 'ng-cheques-printing-statusbar',
  templateUrl: './printing.statusbar.html',
  styleUrls: ['./printing.statusbar.scss']
})
export class PrintingStatusbar implements OnInit {
  constructor(
    public location: Location,
    public modalController: ModalController,
    public sql: SQLService,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      // if(eve instanceof NavigationEnd) this.get_printers();
    });
  }
  async show_printjobs() {
    const m = await this.modalController.create({component: SelectPrintJobsPage, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data)  {
      console.log(a);
    }
  }
  ngOnInit() {
  }
}