import { SQLService } from '../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { PopoverController} from '@ionic/angular';
import { BonusMenuComponent } from './bonus-menu/bonus-menu.component';
@Component({
  selector: 'ng-cheques-bonuses',
  templateUrl: './bonuses.component.html',
  styleUrls: ['./bonuses.component.styl']
})
export class BonusesComponent implements OnInit {
  count = -1;
  bonuses = [];
  search = '';
  constructor(
    public router: Router,
    public popoverController: PopoverController,
    public sql: SQLService) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_bonuses();
    });
  }
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:BonusMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='enable')this.enable(item);
      if(data=='disable')this.disable(item);
      if(data=='delete')this.delete_bonus(item);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  add() {this.router.navigate(['folder','Bonus','New']);}
  end() {this.router.navigate(['folder','Dashboard']);}
  filter(e) {this.search=e.target.value;}
  get_bonuses(event?) {
    this.sql.fetch('Bonuses',(e)=>{
      if(e)this.bonuses=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  edit_bonus(c?,i?) {
    this.router.navigate(['folder','Bonus',c.bonus_id]);
    if(i)i.closeSlidingItems();
  }
  delete_bonus(c?,i?) {
    if(confirm(this.sql.word.Delete_Bonus_Confirmation)) {
      this.sql.delete('Bonuses','bonus_id',c.bonus_id);
      this.get_bonuses();
    } else { i.closeSlidingItems(); }
  }
  disable(bonus,i?){
    this.sql.set('Bonuse','bonus_id',bonus.bonus_id,'enabled',0,(res)=>{
      bonus.enabled = false;
      if(i)i.closeSlidingItems();
    });
  }
  enable(bonus,i?){
    this.sql.set('Bonuse','bonus_id',bonus.bonus_id,'enabled',1,(res)=>{
      bonus.enabled = true;
      if(i)i.closeSlidingItems();
    });
  }
  ngOnInit() {
    this.get_bonuses();
  }
}