import { SelectAddressPage } from '../addresses/select-address/select-address.page';
import { Languages } from '../../environments/languages/index';
import { Locales } from '../../environments/locales';
import { environment } from '../../environments/environment';
import { NewSettings, PlaceHolders } from '../models/settings';
import { AppComponent } from '../app.component';
import { SQLService } from '../services/sql.service';
import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ActionSheetController, AlertController, ModalController, Platform } from '@ionic/angular';
import { PurchaseComponent } from '../dashboard/purchase/purchase.component';
import { FingerprintAIO, FingerprintOptions } from '@ionic-native/fingerprint-aio/ngx';
import { TermsOfServiceComponent } from '../settings/licences/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from '../settings/licences/privacy-policy/privacy-policy.component';
import { EULAComponent } from '../settings/licences/eula/eula.component';
import { TermsOfUseComponent } from '../settings/licences/terms-of-use/terms-of-use.component'
import { DisclaimerComponent } from '../settings/licences/disclaimer/disclaimer.component'
import { RefundsComponent } from '../settings/licences/refunds/refunds.component'
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { Browser } from '@capacitor/browser';
@Component({
  selector: 'ng-cheques-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.styl']
})
export class SettingsComponent implements OnInit {
  constructor(
    public alertController: AlertController,
    public router: Router,
    private faio: FingerprintAIO,
    private callNumber: CallNumber,
    private actionSheetCtrl: ActionSheetController,
    public modalController: ModalController,
    public route: ActivatedRoute,
    public platform: Platform,
    public sql: SQLService
  ) { 
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) {
        this.open()
      }
    });
  }
  uri = environment.com;
  currencies = Object.keys(environment.currencies);
  languages = Languages;
  settingsIcons = false;
  pininput = new ElementRef('pin');
  settings = new NewSettings(true);
  placeholders = new PlaceHolders();
  pass_placeholder = '••••';
  pin_placeholder = '••••';
  accounts = [];
  designs = [];
  printers = [];
  geo_loading = false;
  licence_key: boolean;
  password: string;
  default_printer: string;
  pin: string;
  pass: string;
  search: string;
  count: number;
  DesignCount: number;
  AccountCount = 0;
  PrinterCount: number;
  bio_result: any;
  old_pin: any;
  old_password: any;
  author_email: any;
  author_phone: any;
  author_website: any;
  geosettimeout: any;
  _t =false;
  async show_author() {
    var items = {
      header: this.sql.word.Upgrade_Title+' v'+this.sql.version,
      subHeader: this.sql.word.Author_Name,
      buttons: [
        {
          text: this.sql.word.Title_Intro_Word,
          role: 'visit',
        },
        {
          text: this.sql.word.Call+' ('+this.author_phone+')',
          role: 'call',
        },
        {
          text: this.sql.word.Email+' ('+this.author_email+')',
          role: 'email',
        },
        {
          text: this.sql.word.Cancel,
          role: 'cancel',
        },
      ],
    }
    const actionSheet = await this.actionSheetCtrl.create(items);
    await actionSheet.present();
    var result = await actionSheet.onDidDismiss();
    if(result.role=='call')this.call_author();
    if(result.role=='email')this.email_author();
    if(result.role=='visit')this.view_author();
  }
  pin_confirmation(a?) {
    if(!this.old_pin)return;
    let pin_conf = this.alertController.create({
      header:this.sql.word.Pin_Change_Header,
      subHeader:a?this.sql.word.PIN_Enter_Message:this.sql.word.Pin_Change_SubHeader,
      inputs: [
        {name: 'pin_conf',type: 'password', attributes:{placeholder: this.sql.word.PIN_Code, maxlength:4,inputmode:"numeric"}},
        {name: 'pin_conf_2',type: 'password', attributes:{placeholder: this.sql.word.Pin_Change_Header, maxlength:4,inputmode:"numeric"}}
      ],
      buttons: [
        { text: this.sql.word.Cancel, role: 'cancel', cssClass: 'secondary', handler: () => { 
            if(this.sql.verbose)console.log('PIN Confirmation Cancelled.'); 
            this.settings.pin='';
          } 
        }, 
        { text: this.sql.word.Confirm, handler: (data) => 
          {
            if(data.pin_conf==data.pin_conf_2) {
              if (a && data.pin_conf){
                this.old_pin = data.pin_conf;
                this.sql.save_setting('pin',data.pin_conf);
                alert(this.sql.word.Pin_Updated_Success);
                if(this.sql.verbose)console.log(this.sql.word.Pin_Updated_Success);
                this.settings.pin='';
                this.sql.save_setting('pin_enabled','1');
                this.settings.pin_enabled=true;
              } else if ( this.old_pin == data.pin_conf ) {
                this.old_pin = this.settings.pin;
                this.sql.save_setting('pin',this.settings.pin);
                alert(this.sql.word.Pin_Updated_Success);
                if(this.sql.verbose)console.log(this.sql.word.Pin_Updated_Success);
                this.settings.pin='';
              } else { 
                alert(this.sql.word.Pin_Updated_Fail);
                if(this.sql.verbose)console.log(this.sql.word.Pin_Updated_Fail);
                this.settings.pin='';
              }
            } else {
              alert(this.sql.word.Invalid_PIN);
            }
          }
        }
      ]
    }).then(res=>{
      res.present();
    })
  }
  pin_conf(cb?) {
    if(!this.old_pin)cb(true);
    let pin_conf = this.alertController.create({
      header:this.sql.word.Pin_Change_Header,
      subHeader:this.sql.word.PIN_Enter_Message,
      inputs: [
        {name: 'pin_conf',type: 'password', attributes:{placeholder: this.sql.word.PIN_Code, maxlength:4,inputmode:"numeric"}},
        {name: 'pin_conf_2',type: 'password', attributes:{placeholder: this.sql.word.Pin_Change_Header, maxlength:4,inputmode:"numeric"}}
      ],
      buttons: [
        { text: this.sql.word.Cancel, role: 'cancel', cssClass: 'secondary', handler: () => { 
            if(this.sql.verbose)console.log('PIN Configuration Cancelled.'); 
            this.settings.pin='';
          }
        },
        { text: this.sql.word.Confirm, handler: (data) => 
          {
            if(data.pin_conf==data.pin_conf_2) {
              if ( this.old_pin == data.pin_conf ) {
                alert(this.sql.word.Pin_Updated_Success);
                cb(true);
              } else { 
                alert(this.sql.word.Pin_Updated_Fail);
                cb(false);
              }  
            } else {
              alert(this.sql.word.Invalid_PIN);
              cb(false);
            }
          }
        }
      ]
    }).then(res=>{
      res.present();
    })
  }
  password_confirmation(a?) {
    let pass_conf = this.alertController.create({
      header:this.sql.word.Pass_Change_Header,
      subHeader:a?this.sql.word.Password_Enter_Message:this.sql.word.Pass_Change_SubHeader,
      inputs: [
        {name: 'pass_conf',type: 'password', attributes:{placeholder:this.sql.word.Password}},
        {name: 'pass_conf_2',type: 'password', attributes:{placeholder:this.sql.word.Pass_Change_Header}}
      ],
      buttons: [
        { 
          text: this.sql.word.Cancel, 
          role: 'cancel', 
          cssClass: 'secondary', 
          handler:()=>{
            if(this.sql.verbose)console.log('Password Confirmation Cancelled.'); 
            this.settings.password='';
          } 
        }, 
        { 
          text: this.sql.word.Confirm, handler: (data) => {
            if (data.pass_conf==data.pass_conf_2){
              if (a&&data.pass_conf){
                this.old_password = data.pass_conf;
                this.sql.save_setting('password',data.pass_conf);
                alert(this.sql.word.Pass_Updated_Success)
                if(this.sql.verbose)console.log(this.sql.word.Pass_Updated_Success);
                this.settings.password='';
                this.sql.save_setting('password_enabled','1');
                this.settings.password_enabled=true;
              } else if ( this.old_password == data.pass_conf ) {
                this.old_password = this.settings.password;
                this.sql.save_setting('password',this.settings.password,(result)=>{
                  if(result){
                    alert(this.sql.word.Pass_Updated_Success);
                    if(this.sql.verbose)console.log(this.sql.word.Pass_Updated_Success);  
                  }
                  this.settings.password='';
                });
              } else { 
                alert(this.sql.word.Pass_Updated_Fail);
                if(this.sql.verbose)console.log(this.sql.word.Pass_Updated_Fail);
                this.settings.password='';
              }
            } else {
              alert(this.sql.word.Pass_Updated_Fail);
            }
          }
        }
      ]
    }).then(res=>{
      res.present();
    })
  }
  password_conf(cb?) {
    if(!this.old_password)cb(true);
    let pass_conf = this.alertController.create({
      header:this.sql.word.Pass_Change_Header,
      subHeader:this.sql.word.Password_Enter_Message,
      inputs: [
        {name: 'pass_conf',type: 'password', attributes:{placeholder:this.sql.word.Password}},
        {name: 'pass_conf_2',type: 'password', attributes:{placeholder:this.sql.word.Pass_Change_Header}}
      ],
      buttons: [
        { 
          text: this.sql.word.Cancel, 
          role: 'cancel', 
          cssClass: 'secondary', 
          handler:()=>{
            if(this.sql.verbose)console.log('Password Configuration Cancelled.'); 
          } 
        }, 
        { 
          text: this.sql.word.Confirm, handler: (data) => {
            if (data.pass_conf == data.pass_conf_2) {
              if ( this.old_password == data.pass_conf ) {
                alert(this.sql.word.Pass_Updated_Success)
                cb(true);
              } else { 
                alert(this.sql.word.Pass_Updated_Fail)
                cb(false);
              }
            } else {
              alert(this.sql.word.Pass_Updated_Fail)
              cb(false);
            }
          }
        }
      ]
    }).then(res=>{
      res.present();
    })
  }

  change_lang(lang_code?) {
    if(!lang_code)return;
    if(this.settings.site_title=='Cheques'||this.settings.site_title==this.sql.word.Cheques){
      this.settings.site_title=null;
    }
    if(this.settings.default_print_language==this.settings.default_language)this.change_print_lang(lang_code);
    this.settings.default_language=lang_code;
    if(!this._t)return;
    this.sql.setup_language(lang_code,true,()=>{
      // this.get_default_design();
    }); 
    // for(var l of Object.values(Locales)){
    //   if(l&&l['language']&&l['language'].toLowerCase()==lang_code.toLowerCase()){
    //     this.change_currency(l['currency']);
    //   }
    // }
    if(!this.settings.site_title){
      this.settings.site_title=this.sql.word.Cheques;
    }
  }
  change_print_lang(lang_code?) {
    if(!lang_code)return;
    this.settings.default_print_language=lang_code;
    this.sql.save_setting('default_print_language',this.settings.default_print_language);
  }
  change_currency(c?) {
    if(!c)return;
    this.settings.default_currency = c;
    this.sql.save_currency(c);
  }
  change_name(n?) {
    if(!n)return;
    this.settings.site_title = n;
    this.sql.save_title(this.settings.site_title);
  }
  save_monthly_income(i?) {
    if(!i)return;
    this.settings.default_income = i;
    this.sql.save_setting('default_income',this.settings.default_income);
  }
  save_default_printer(i?,k?) {
    if(!i)return;
    this.settings.default_printer = i;
    if(!k)this.sql.save_setting('default_printer',this.settings.default_printer);
  }

  save_default_bank_account(i?) {
    if(!i)return;
    this.settings.default_design = i;
    this.sql.set_all("BankAccounts","default_account",false,(cb)=>{ 
      this.sql.query("UPDATE BankAccounts SET default_account = 'true' WHERE bank_id = "+i,(e)=>{
        this.get_default_bank_account();
      });
    });
  }
  get_default_bank_account() {
    this.sql.get('BankAccounts',(e)=>{
      this.accounts=e;
      for(let d of e)if(d.default_account=='true')this.settings.default_bank_account=d.bank_id;
      this.sql.count('BankAccounts',(c)=>{this.AccountCount=c;});
    });
  }

  save_default_design(i?) {
    if(!i)return;
    this.settings.default_design = i;
    this.sql.set_all("Designs","default_design",false,(cb)=>{ 
      this.sql.query("UPDATE Designs SET default_design = 'true' WHERE design_id = "+i,(e)=>{
        this.get_default_design();
      });
    });
  }
  get_default_design() {
    this.sql.get('Designs',(e)=>{
      this.designs=e;
      for(let d of e)if(d.default_design=='true')this.settings.default_design=d.design_id;
      this.sql.count('Designs',(c)=>{this.DesignCount=c;});
    });
  }
  fetch_printers() {
    this.sql.fetch('Printers',(e)=>{
      this.printers=e;
      this.PrinterCount=e.length;
    });
  }
  async add_address() {
    const m = await this.modalController.create({component: SelectAddressPage, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data){
      console.log('Addresses',a.data)
      this.settings.company_address_id = a.data;
      this.sql.save_setting('company_address_id',this.settings.company_address_id);
      this.sql.view('Addresses','address_id',this.settings.company_address_id,(a)=>{a=a[0];
        this.settings.company_address = a.address;
        this.settings.company_address_name = a.name;
        this.sql.save_setting('company_address',this.settings.company_address);
        this.sql.save_setting('company_address_name',this.settings.company_address_name);
      });
    }
  }
  edit_address(address) {
    this.router.navigate(['folder','Address', address.address_id]); 
  }
  delete_address() {
    this.settings.company_address = null;
    this.settings.company_address_id = null;
    this.sql.save_setting('company_address_id',this.settings.company_address_id);
    this.sql.save_setting('company_address_name',this.settings.company_address_name);
  }
  biometrics(cb?){
    var opts:FingerprintOptions = {
      title: this.sql.Title,
      disableBackup:true,
      fallbackButtonTitle: this.sql.word.Use_PIN,
      cancelButtonTitle: this.sql.word.Cancel,
      description: this.sql.word.Biometric_Security
    }
    this.faio.show(opts)
      .then((result: any) => {
        if(result=="Success"){
          this.bio_result=200;
          if(cb)cb(true);
        } else {
          this.bio_result=500;
          if(cb)cb(false);
        }
        setTimeout(()=>{this.bio_result=false;},5000);
      }).catch((error: any) => {
        console.warn(error);
        this.bio_result=500;
        setTimeout(()=>{this.bio_result=false;},5000);
        if(cb)cb(false);
      });
  }
  logout() { this.router.navigate(['folder','Splash'])}
  change_face(){

  }
  change_logging(){ this.sql.save_setting('log',(this.settings.log)?'1':'0'); }
  setup_geofence(i?){
    if(i || confirm(this.sql.word.Remove_Geofence_Prompt)){
      this.geo_loading = true;
      if(this.sql.verbose)console.log('Trying Geofence...');
      this.settings.geo_location='';
      setTimeout(()=>{
        this.sql.setup_geofence((res)=>{
          this.settings.geo_location=res;
          this.geo_loading = false;
          if(!this.settings.geo_distance)this.settings.geo_distance=this.sql.minimum_geo_distance;
          if(this.sql.verbose)console.log('Setup Geofence','Lat, Lng',res);
        });
      }, 777);
    }
  }
  email_author() {
    this.sql.email(this.author_email,'Cheques ','');
  }
  call_author() {
    this.callNumber.callNumber(this.author_phone, true);
  }
  async view_author() {
    await Browser.open({ url:this.author_website });
  }
  save_geo_distance(){
    if(this.geosettimeout)clearTimeout(this.geosettimeout);
    this.geosettimeout=setTimeout(()=>{
      this.sql.save_setting('geo_distance',this.settings.geo_distance);
    },100);
  }
  save_pin_enabled(){
    this.sql.save_setting('pin_enabled',this.settings.pin_enabled);
  }
  save_pin(){
    if(this.old_pin){
      this.pin_confirmation();
    } else {
      this.sql.save_setting('pin',this.settings.pin);
      alert(this.sql.word.Pin_Updated_Success)
      this.old_pin=this.settings.pin;
      if(this.sql.verbose)console.log(this.sql.word.Pin_Updated_Success);
      this.settings.pin='';
    }
  }
  save_pass(){
    if(this.old_password){
      this.password_confirmation();
    } else {
      this.sql.save_setting('password',this.settings.password);
      alert(this.sql.word.Pass_Updated_Success)
      this.old_password=this.settings.password;
      if(this.sql.verbose)console.log(this.sql.word.Pass_Updated_Success);
      this.settings.password='';
    }
  }
  process_pin(e,key){
    if(!this.settings.pin)this.settings.pin='';
    if (key==8) {
      e.preventDefault();
      this.placeholders.pin='';this.settings.pin='';
      return '';
    } else if (e.key&&Number(e.key)>-1) {
      this.settings.pin=(this.settings.pin?this.settings.pin:'')+e.key;
      this.placeholders.pin=this.placeholders.pin+'•';
    }
  }
  save_password_enabled(){
    this.sql.save_setting('password_enabled',(this.settings.password_enabled)?true:false);
  }
  setup_printer(){
    this.router.navigate(['folder','Printers']);
  }
  account(){this.router.navigate(['folder','Account','New']);}
  add_company(){
    if(this.sql.Trial) {
      alert(this.sql.word.Multiple_Companies_Word);
      this.sql.show_purhchase_modal();
    } else {
      this.router.navigate(['folder','Companies']);
    }
  }
  phone()  {this.router.navigate(['folder','Phone']);}
  faxes()  {this.router.navigate(['folder','Faxes']);}
  paypals(){
    if((this.sql.Trial||!this.sql.paypal_payouts)) {
      alert(this.sql.word.PayPal_Payouts_Word);
      this.sql.show_purhchase_modal();
    } else {
      this.router.navigate(['folder','Settings','PayPal']);
    }
  }
  save_new_password(){
    if(this.old_password)this.password_confirmation();
  }
  save_new_hostname(h){
    if (h=='http://') return;
    this.settings.hostname=h;
    this.sql.save_setting('hostname',this.settings.hostname);
  }
  save_new_key(k){
    this.sql.save_setting('key',this.settings.key);
  }
  process_pass(e,key) {
    if(key==8){
      e.preventDefault();
      this.placeholders.pass='';this.settings.pin='';
    }else{
      this.settings.password=this.settings.password+''+e.key;
      this.placeholders.pass=this.placeholders.pass+'•';
    }
  }
  learn_backend() {
    window.open('http://github.com/bentbot/', '_system', 'location=yes');
  }
  end() {this.router.navigate(['folder','Dashboard']);}
  // change_password(p?) {
  //   this.password_confirmation.present();
  //   this.sql.save_setting('pin',this.password);
  // }
  toggle_pin(e?) {
    if(!this.settings.pin_enabled) {
      this.pin_confirmation(1);
    } else {
      this.pin_conf((re)=>{
        if(re){
          this.sql.save_setting('pin_enabled','0');
          this.sql.save_setting('pin','');
          this.settings.pin_enabled=false;
        }
      });
    }
  }
  toggle_password_protection(e?) {
    if(!this.settings.password_enabled) {
      this.password_confirmation(1);
    } else {
      this.password_conf((re)=>{
        if(re){
          this.settings.password_enabled=false;
          this.sql.save_setting('password_enabled','0');
          this.sql.save_setting('password','');
        }
      });
    }

  }
  save_geo_protection(e?) {
    this.sql.save_setting('geo_enabled',(this.settings.geo_enabled)?'1':'0');
    if(this.settings.geo_enabled)this.setup_geofence(1);
  }
  disable_pin() {
    this.settings.pin='';
    this.settings.pin_enabled=false;
  }
  enable_pin() {
    this.settings.pin='';
    this.settings.pin_enabled=true;
  }
  toggle_facial_recognition() {
    this.biometrics((res)=>{
      if(res){
        this.settings.face_enabled=this.settings.face_enabled?false:true;
        this.sql.save_setting('face_enabled',(this.settings.face_enabled)?'1':'0');
      }
    })
  }
  import() {
    this.router.navigate(['folder','Import','New']);
  }
  export() {
    if(this.sql.Trial){
      this.sql.show_purhchase_modal();
    } else {
      this.router.navigate(['folder','Export','New']);
    }
  }
  public reset_database(force?) {
    this.pininput = new ElementRef('pin');
    this.settings = new NewSettings(true);
    this.placeholders = new PlaceHolders();
    this.pass_placeholder = '••••';
    this.pin_placeholder = '••••';
    this.old_pin=false;
    this.old_password=false;
    this.sql.reset(force,(z)=>{
      this.settings.log=environment.logging;
      this.sql.save_setting('log',this.settings.log);
      this.sql.hapticsNotification();
      setTimeout(()=>{
        this.open();
        this.sql.hapticsImpactLight();
      },765);
    });
  }
  async purchase() {
    setTimeout(() => { this.sql.hapticsImpactHeavy(); },440);
    const m = await this.modalController.create({component: PurchaseComponent, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      this.sql.hapticsImpactLight();
    }
  }
  select_default_design() {this.router.navigate(['folder','ChequeDesigns']);}
  designer() {this.router.navigate(['folder','Cheque','Design','New']);}
  add_backup() {this.router.navigate(['folder','Settings','Backup']);}
  save() {this.sql.update('Settings',this.settings);}
  filter(e) {this.search=e.target.value;}
  get_settings(event?) {
    this.sql.get_setting('key',(s)=>{if(!s)return;this.settings.key=s.option});
    this.sql.get_setting('site_title',(s)=>{if(s.option!='Cheques')this.settings.site_title=s.option});
    this.sql.get_setting('default_language',(s)=>{
      if(!s){
        this.settings.default_language=this.sql.Language;
      } else { this.settings.default_language=(s&&s.option)?s.option:this.sql.Language; }
    });
    this.sql.get_setting('default_print_language',(s)=>{
      if(!s){
        this.settings.default_print_language=this.sql.Language;
      } else { this.settings.default_print_language=s.option?s.option:this.sql.Language; }
    });
    this.sql.get_setting('default_currency',(s)=>{if(!s)return;this.settings.default_currency=s.option?s.option:this.sql.Currency});
    this.sql.get_setting('default_income',(s)=>{if(!s)return;if(Number(s.option)>0.00001)this.settings.default_income=s.option});
    this.sql.get_setting('default_bank_account',(s)=>{if(!s)return;this.settings.default_bank_account=s.option?s.option:false});
    this.sql.get_setting('default_printer',(s)=>{if(!s)return;this.save_default_printer(s.option,true);});
    this.sql.get_setting('pin_enabled',(s)=>{if(!s)return;this.settings.pin_enabled=(s.option=="1")?true:false});
    this.sql.get_setting('pin',(s)=>{
      if(!s)return;
      this.old_pin=(s.option)?s.option:false;
    });
    this.sql.get_setting('log',(s)=>{
      if(!s){
        this.settings.log=this.sql.LOGGING;
      } else { this.settings.log=(s.option=="1")?true:false; }
    });
    this.sql.get_setting('password_enabled',(s)=>{if(!s)return;this.settings.password_enabled=(s.option=="1")?true:false});
    this.sql.get_setting('password',(s)=>{
      if(!s)return;
      this.old_password=(s.option)?s.option:false;
    });
    this.sql.get_setting('geo_enabled',(s)=>{if(!s)return;this.settings.geo_enabled=(s.option=="1")?true:false});
    this.sql.get_setting('geo_distance',(s)=>{if(!s)return;this.settings.geo_distance=s.option?s.option:250});
    this.sql.get_setting('geo_location',(s)=>{if(!s)return;this.settings.geo_location=s.option});
    this.sql.get_setting('face_enabled',(s)=>{if(!s)return;this.settings.face_enabled=(s.option=="1")?true:false});
    this.sql.get_setting('company_address_id',(s)=>{
      if(!s)return;
      this.settings.company_address_id=s.option;
      if(!this.settings.company_address_id)return;
      this.sql.view('Addresses','address_id',this.settings.company_address_id,(a)=>{a=a[0];
        this.settings.company_address = a.address;
        this.settings.company_address_name = a.name;
      });
    });
    
    this.get_default_bank_account();
    this.get_default_design();
    this.fetch_printers();
    this.setup_faio();
  }
  setup_faio() {
    if(this.sql.biometric_protection) {
      this.faio.isAvailable().then((result: any)=>{
        this.sql.biometric_protection=true;
      }).catch((error: any)=>{
        this.sql.biometric_protection=false;
      });
    }
  }
  reset(c?,i?) {
    if(confirm(this.sql.word.Delete_Setting_Confirmation)) {
      this.sql.delete('Settings','option',c.option);
      this.open();
    } else { if(i)i.closeSlidingItems(); }
  }
  generate_rand_key() {
    if(!this.settings.key)this.settings.key=this.sql.random(20);
  }
  open() {
    this.settings.pin='';
    this.placeholders.pin ='';
    this.placeholders.pass='';
    this.settings.default_currency=this.sql.Currency;
    this.get_settings();
  }
  ngOnInit() {
    if(this.platform.is('electron')) {
      this.licence_key = true;
    } else {
      this.licence_key = false;
    }
    this.open();
    this.author_email=environment.author_email;
    this.author_phone=environment.author_phone;
    this.author_website=environment.author_website;
  }
  async tos() {
    const m = await this.modalController.create({component: TermsOfServiceComponent, componentProps: {force:false}});
      await m.present();
      let a = await m.onDidDismiss();
      if(a.data) {
        this.sql.hapticsImpactLight();
        console.log('Check Key', a.data);
      }
  }
  async pp() {
    const m = await this.modalController.create({component: PrivacyPolicyComponent, componentProps: {force:false}});
      await m.present();
      let a = await m.onDidDismiss();
      if(a.data) {
        this.sql.hapticsImpactLight();
        console.log('Check Key', a.data);
      }
  }
  async eula() {
    const m = await this.modalController.create({component: EULAComponent, componentProps: {force:false}});
      await m.present();
      let a = await m.onDidDismiss();
      if(a.data) {
        this.sql.hapticsImpactLight();
        console.log('Check Key', a.data);
      }
  }
  async tou() {
    const m = await this.modalController.create({component: TermsOfUseComponent, componentProps: {force:false}});
      await m.present();
      let a = await m.onDidDismiss();
      if(a.data) {
        this.sql.hapticsImpactLight();
      }
  }
  async dis() {
    const m = await this.modalController.create({component: DisclaimerComponent, componentProps: {force:false}});
      await m.present();
      let a = await m.onDidDismiss();
      if(a.data) {
        this.sql.hapticsImpactLight();
      }
  }
  async ref() {
    const m = await this.modalController.create({component: RefundsComponent, componentProps: {force:false}});
      await m.present();
      let a = await m.onDidDismiss();
      if(a.data) {
        this.sql.hapticsImpactLight();
      }
  }
}