import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Router,ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NewFax } from '../../../models/faxes';
import { SQLService } from '../../../services/sql.service';
import { SelectAddressPage } from '../../../addresses/select-address/select-address.page';
import { SelectEmployeePage } from '../../../employees/select-employee/select-employee.page';
import { SelectChequePage } from '../../../cheque/select-cheque/select-cheque.page';
@Component({
  selector: 'app-new-fax',
  templateUrl: './new-fax.component.html',
  styleUrls: ['./new-fax.component.scss'],
})
export class NewFaxComponent implements OnInit {
  fax = new NewFax();
  _c=0;
  constructor(
    private modalController:ModalController,
    private router:Router,
    private route:ActivatedRoute,
    private location:Location,
    private sql:SQLService) { }
  async add_address() {
    const m = await this.modalController.create({component: SelectAddressPage, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1) {
        this.delete_address();
      } else {
        this.fax.address_ids = a.data;
        this.sql.view('Addresses','address_id',a.data,(a)=>{this.fax.addresses=a;});
        this._c=1;
        this.sql.hapticsImpactMedium();
      }
    }
    
    this.sql.remove_temporary('page');
  }
  edit_address(address) {
    this.router.navigate(['folder','Address', address.address_id]); 
  }
  delete_address() {
    this.fax.addresses = null;
    // this.sql.set( 'Faxes', 'fax_id', this.fax.fax_id, 'addresses', null);
  }
  async add_employee() {
    const m = await this.modalController.create({component: SelectEmployeePage, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1) {
        this.delete_employee();
      } else {
        this.fax.employees = a.data;
        this._c=1;
        this.sql.hapticsImpactMedium();  
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_employee(employee) {
    this.router.navigate(['folder','Employee', employee.employee_id]); 
  }
  delete_employee() {
    this.fax.employees = null;
    // this.sql.set( 'Faxes', 'fax_id', this.fax.fax_id, 'employees', null);
  }
  async add_cheque() {
    const m = await this.modalController.create({component: SelectChequePage, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1){
        this.delete_cheque();
        this.sql.hapticsImpactLight();
      }else{
        this.fax.cheques = a.data;
        if(a.data)this._c=1;
        if(a.data)this.sql.hapticsImpactMedium();
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_cheque(employee) {
    this.router.navigate(['folder','Employee', employee.employee_id]); 
  }
  delete_cheque() {
    this.fax.cheques = null;
    // this.sql.set( 'Faxes', 'fax_id', this.fax.fax_id, 'cheques', null);
  }
  send(id) {
    this.router.navigate(['folder','Fax','Send',id]);
  }
  delete_fax() {
    if(!this.fax.fax_id){
      this.end();
    } else if(confirm(this.sql.word.Delete_Fax_Confirmation)) {
      this.sql.delete('Faxes','fax_id',this.fax.fax_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  save(end=true,send?) {
    console.log("NEW FAX",this.fax);
    this.sql.add("Faxes",this.fax,(insertId)=>{
      if(!insertId)return
      if(insertId!=false&&insertId>0){
        this.fax.fax_id=insertId;
        this.fax = new NewFax();
        this._c = 0;
        if (send) this.send(insertId);
        if (!send) this.end();
      }
    });
  }
  end() {
   if (this._c > 0) {
      if (confirm(this.sql.word.Delete_Fax_Confirmation)) {
        this.fax = new NewFax();
        this.location.back();
      }
    } else {
      this.location.back();
    }
  }
  get_fax() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida) return;
    this.sql.view('Faxes','fax_id',ida,(e)=>{
      var f = Object.entries(e[0]); let n=new NewFax();
      for( let t of f) n[t[0] ]=t[1]; this.fax = n;
    });
  }
  open() { 
    if(!this.sql.fax)this.end();
    this.fax.enabled = true;
    this.get_fax();
  }
  ngOnInit() {
    this.open();
  }
}
