import { SQLService } from '../../services/sql.service';
import { ChequeDesignerComponent } from './cheque-designer/cheque-designer.component';
import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController, Platform } from '@ionic/angular';
import { ImagePreviewComponent } from './image-preview/image-preview.component'
import { CalendarModal, CalendarModalOptions, DayConfig, CalendarResult } from 'ion2-calendar';
import { backgrounds } from '../../../environments/logos';
@Component({
  selector: 'ng-cheques-cheque-designs',
  templateUrl: './cheque-designs.component.html',
  styleUrls: ['./cheque-designs.component.styl']
})
export class ChequeDesignsComponent implements OnInit {
  constructor(
    public sql: SQLService,
    public location: Location,
    public modalController: ModalController,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.open();
    });
  }
  count = -1;
  designs = [];
  search: string;
  selected_design:any;
  show_reload = true;
  filter(e) { this.search=e.target.value; }
  end() {
    alert(this.sql.word.Default_Design_Selected);
    // this.location.back()
  }
  get_designs(event?) {
    this.sql.fetch('Designs',(e)=>{
      var n = [];
      for(let a of e){
        // a.background=backgrounds[a.name]
        n.push(a);
      }
      this.designs=n; this.count=n.length;
      console.log('designs', this.designs)
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  async preview(img,title?) {
    const m = await this.modalController.create({
      component: ImagePreviewComponent,
      componentProps: {src:img,title:title}
    });
    await m.present();
    let a = await m.onDidDismiss();console.log(a);
    if(a.data==1){
      //
    }
  }
  mod_design() {
    this.set_default(this.selected_design,null,()=>{
      if(this.selected_design)this.router.navigate(['folder','Designer',this.selected_design.design_id]);
    });
  }
  pro_() {
    if(this.sql.Trial) {this.sql.show_purhchase_modal();}
  }
  preview_design(design) {
    this.preview(design.preview,design.title);
  }
  select(design) {
    if(design.pro&&this.sql.Trial) {
      //
    } else {
      for(var j=0;j<this.count;j++)this.designs[j].default_design=false;
      this.selected_design = design;  
    }
  }
  refresh_designs() {
    if(confirm(this.sql.word.Reinsert_Default_Designs_word+' - '+this.sql.word.DB_Erase_Prompt)){
      this.sql.insert_default_designs(this.sql.word,(res)=>{
        if(res)this.get_designs();
      });
    }
  }
  add(){this.router.navigate(['folder','Cheque','New']);}
  edit(design, item?) {this.router.navigate(['folder','Design',design.design_id]);}
  set_default(design,i?,cba?) {
    this.sql.set_all("Designs","default_design",false,(cb)=>{
      for(var j=0;j<this.count;j++)this.designs[j].default_design=false;
    });
    design.default_design='true';
    this.sql.add("Designs",design,(d)=>{
      if(d)if(cba)cba(d);
    });
  }
  delete(design,item?) {
    // if(design.default_design) {
    //   alert(this.sql.word.Cant_Delete_Default_Design)
    // } else {
      if(confirm(this.sql.word.Remove_Design_Confirmation)) {
        this.sql.delete('Designs','design_id',design.design_id);
        this.get_designs();
      } else { item.closeSlidingItems(); }
    // }
  }
  get_default_design() {
    this.sql.get('Designs',(e)=>{
      this.designs=e;
      for(let d of e)if(d.default_design=='true')this.selected_design=d;
    });
  }
  save() {
    this.set_default(this.selected_design,null,()=>{
      this.end();
    });
  }
  async openCalendar() {
    const options: CalendarModalOptions={title:this.sql.word.End_Date};
    const myCalendar = await this.modalController.create({
      component: CalendarModal,
      componentProps: { options }
    });
    myCalendar.present();
    const event: any = await myCalendar.onDidDismiss();
    const date: CalendarResult = event.data;
  }
  open() {
    this.get_default_design();
    this.get_designs();
  }
  ngOnInit() {

  }
}