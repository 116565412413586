import { SQLService } from '../../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
@Component({
  selector: 'ng-cheques-imports',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.styl']
})
export class ImportsComponent implements OnInit {
  constructor(
    public sql: SQLService,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_imports();
    });
  }
  imports = [];
  count = -1;
  search:string;
  filter(e) {this.search=e.target.value;}
  add() {this.router.navigate(['folder','Imports','New']);}
  get_imports(event?) {
    this.sql.fetch('Imports',(e)=>{
      this.imports=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  edit_import(i, item?) {
    this.router.navigate(['folder','Import',i.import_id]);
  }
  delete_import(i, item) { 
    if(confirm(this.sql.word.Discard_Confirmation)) {
      this.sql.delete('Imports','import_id',i.employee_id);
      this.get_imports();
    } else { item.closeSlidingItems(); }
  }
  ngOnInit() {
    this.get_imports();
  }
}