import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { SQLService } from '../../../services/sql.service';
@Component({
  selector: 'app-pin-pad',
  templateUrl: './pin-pad.component.html',
  styleUrls: ['./pin-pad.component.styl'],
})
export class PinPadComponent implements OnInit, OnDestroy {
  pin = '';
  @Input('set_pin') set:boolean;
  @Output('subtitle') subtitle = new EventEmitter();
  @Output('login') _login = new EventEmitter();
  constructor(public sql: SQLService) { }
  a = 5; A = 0; d = 0; e = 0; f = 0; G = 0; _h = true; h = 0; I = 0;  _p = ['_','_','_','_']; p = 0; s = 0; t = false; u = 0; 
  ngOnInit() {
    this.reset_pin();
  }
  ngOnDestroy() {
    this.reset_pin();
  }
  key(e) {
    this.sql.hapticsImpactLight();
    if(this.p>=4||this.a==0) return;
    this.t=true;
    this._p[this.p]='*';
    this.pin=this.pin+''+e;
    this.p=this.p+1;
    this.subtitle.emit(1);
    if(this.p>3){
      setTimeout(()=>{
        this.login();
      }, 500);
    }
  }
  backsp() {
    if (this.p<=0) return;
    this.sql.hapticsImpactLight();
    this.p=this.p-1;
    this.pin=this.pin.substring(0,this.pin.length-1);
    this._p[this.p]='_';
    if(this.p==0)this._p=['_','_','_','_'];
  }
  reset_pin() {
    this.pin = '';
    this._p = ['_','_','_','_'];
    this.a = 5; this.A = 0; this.d = 0;
    this.e = 0; this.f = 0; this.G = 0;
    this.h = 0; this.I = 0; this.p = 0;
    this.s = 0; this.t = false;
  }
  login() {
    if(this.set) {
      this.sql.save_setting('pin',this.pin, () =>{
        this.sql.hapticsSuccess();
        this._login.emit(true);
        this.reset_pin();
      });
    } else {
      // if(this.pin=='###*')this._login.emit(true);
      this.sql.check_pin(this.pin,(result) => {
        if(result==true){
          this.sql.hapticsSuccess();
          this._login.emit(true);
          this.reset_pin();
        } else {
          this.reset_pin();
        }
      })
    }
  }
  hasNumber(n) {return /\d/.test(n);}
}
export class PIN {
  constructor(
    public url?: any,
    public pin?: any,
    public langg?: any,
    public currency?: any
  ) {}
}