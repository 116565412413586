import { SQLService } from '../../../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
@Component({
  selector: 'ng-cheques-printjobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class PrintJobsComponent implements OnInit {
  constructor(
    public sql: SQLService,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_jobs();
    });
  }
  count = -1; 
  jobs = [];
  search:string;
  filter(e) {this.search=e.target.value;}
  get_jobs(event?) {
    this.sql.fetch('PrintJobs',(e)=>{
      this.jobs=e;
      this.count=e.length;
      setTimeout(()=>{if (event) {event.target.complete();}},1234);
    });
  }
  edit_job(print_job, item?) {
    this.router.navigate(['folder','PrintJobs',print_job.job_id]);
  }
  end() {this.router.navigate(['folder','Dashboard']);}
  add() {this.router.navigate(['folder','Printer','New']);}
  delete_job(print_job, item) {
    if(confirm(this.sql.word.Discard_Confirmation)) {
      this.sql.delete('PrintJobs','printer_id',print_job.job_id);
      this.get_jobs();
    } else { item.closeSlidingItems(); }
  }
  toggle_printing() {
    this.sql.printing_paused = this.sql.printing_paused?false:true;
  }
  ngOnInit() {
    this.get_jobs();
  }
}