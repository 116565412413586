import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SQLService } from '../../../services/sql.service';
@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.styl'],
})
export class PasswordComponent implements OnInit {
  password = new Password();
  success: boolean;
  loading: boolean;
  error: boolean;
  constructor( private sql: SQLService ) { }
  @Output('login') _login = new EventEmitter();
  ngOnInit() {}
  reset_pass() {
    this.password.pass='';
  }
  login() {
    this.error = false;
    this.sql.check_password(this.password.pass,(result) => {
      if(result==200){
        this._login.emit(true);
        this.success = true;
        setTimeout(()=>{this.success=false;},1200);
        this.reset_pass();
      } else if (result==400) {
        this._login.emit(true);
        this.reset_pass();
      } else if (result==500) {
        this.error = true;
        setTimeout(()=>{this.error=false;},1200);
        this.reset_pass();
      }
    })
  }

}
export class Password {
  constructor(
    public pass?: string,
  ) {}
}