/**
 * @cheques
 * @sql
 * Starting point to export all lang vars.
 */
export const Languages = [
    {name:"Afrikaans", code:"Af"},
    {name:"Amharic", code:"Am"},
    {name:"Arabic", code:"Ar"},
    {name:"Azerbaijani", code:"Az"},
    {name:"Belarusian", code:"Be"},
    {name:"Bengali", code:"Bn"},
    {name:"Bulgarian", code:"Bg"},
    {name:"Bosnian", code:"Bs"},
    {name:"Catalan", code:"Ca"},
    {name:"Czech", code:"Cs"},
    {name:"Welsh", code:"Cy"},
    {name:"Danish", code:"Da"},
    {name:"German", code:"De"},
    {name:"Greek", code:"El"},
    {name:"English", code:"En"},
    {name:"Spanish", code:"Es"},
    {name:"Basque", code:"Eu"},
    {name:"Persian", code:"Fa"},
    {name:"Finnish", code:"Fi"},
    {name:"Filipino", code:"Tl"},
    {name:"French", code:"Fr"},
    {name:"Scots Gaelic", code:"Gd"},
    {name:"Galician", code:"Gl"},
    {name:"Gujarati", code:"Gu"},
    {name:"Hawaiian", code:"Haw"},
    {name:"Hebrew", code:"He"},
    {name:"Hindi", code:"Hi"},
    {name:"Croatian", code:"Hr"},
    {name:"Hungarian", code:"Hu"},
    {name:"Armenian", code:"Hy"},
    {name:"Indonesian", code:"Id"},
    {name:"Icelandic", code:"Is"},
    {name:"Italian", code:"It"},
    {name:"Japanese", code:"Ja"},
    {name:"Georgian", code:"Ka"},
    {name:"Kazakh", code:"Kk"},
    {name:"Khmer", code:"Km"},
    {name:"Kannada", code:"Kn"},
    {name:"Korean", code:"Ko"},
    {name:"Latin", code:"La"},
    {name:"Lithuanian", code:"Li"},
    {name:"Lao", code:"Lo"},
    {name:"Latvian", code:"Lv"},
    {name:"Maori", code:"Mi"},
    {name:"Macedonian", code:"Mk"},
    {name:"Malayalam", code:"Ml"},
    {name:"Mongolian", code:"Mn"},
    {name:"Marathi", code:"Mr"},
    {name:"Malay", code:"Ms"},
    {name:"Maltese", code:"Mt"},
    {name:"Myanmar (Burmese)", code:"My"},
    {name:"Norwegian (Bokml)", code:"Nb"},
    {name:"Nepali", code:"Ne"},
    {name:"Dutch", code:"Nl"},
    {name:"Norwegian", code:"Nn"},
    {name:"Odia (Oriya)", code:"Or"},
    {name:"Punjabi", code:"Pa"},
    {name:"Polish", code:"Pl"},
    {name:"Portuguese", code:"Pt"},
    {name:"Romanian", code:"Ro"},
    {name:"Russian", code:"Ru"},
    {name:"Sindhi", code:"Sd"},
    {name:"Slovak", code:"Sk"},
    {name:"Slovenian", code:"Sl"},
    {name:"Somali", code:"So"},
    {name:"Albanian", code:"Sq"},
    {name:"Serbian", code:"Sr"},
    {name:"Swedish", code:"Sv"},
    {name:"Swahili", code:"Sw"},
    {name:"Tamil", code:"Ta"},
    {name:"Telugu", code:"Te"},
    {name:"Tajik", code:"Tg"},
    {name:"Thai", code:"Th"},
    {name:"Turkish", code:"Tr"},
    {name:"Tatar", code:"Tt"},
    {name:"Ukrainian", code:"Uk"},
    {name:"Urdu", code:"Ur"},
    {name:"Uzbek", code:"Uz"},
    {name:"Vietnamese", code:"Vi"},
    {name:"Xhosa", code:"Xh"},
    {name:"Yiddish", code:"Yi"},
    {name:"Chinese (Simplified)", code:"ZhCn"},
    {name:"Chinese (Traditional)", code:"Zh"},
    {name:"Zulu", code:"Zu"}
];