import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { SQLService } from '../../services/sql.service';
import { NewFTPDrive } from '../../models/ftp';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'ng-cheques-ftp-settings',
  templateUrl: './ftp-settings.component.html',
  styleUrls: ['./ftp-settings.component.styl']
})
export class FTPSettingsComponent implements OnInit {
  constructor(
    private navCtrl: ModalController,
    public sql: SQLService,
    public router: Router
  ) { }
	ftp = new NewFTPDrive();
	f = 1;
	s = 0;
  auto = false;
  backup = false;
	save(backup?) {
    var URL = (this.ftp.secure?'s':'')+(this.ftp.secure?'ftp':'')+'://'+this.ftp.user+':'+this.ftp.pass+'@'+this.ftp.host+':'+this.ftp.port+'/'+this.ftp.path;
    if(backup) {
      this.sql.setup_ftp_backup(URL,this.s,this.ftp.auto);
    } else {
      this.sql.ftp_import(URL);
      this.navCtrl.dismiss(this.ftp);
    }
    this.ftp = new NewFTPDrive();
  }
  end() {
    this.navCtrl.dismiss();
  }
	ngOnInit() {
    	this.ftp.secure = false;
      this.ftp.port = 21;
	}
}