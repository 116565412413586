import { NewInvoice } from '../../models/invoice';
import { SQLService } from '../../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { SelectAddressPage } from '../../addresses/select-address/select-address.page';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController, Platform } from '@ionic/angular';
@Component({
  selector: 'ng-cheques-new-invoice',
  templateUrl: './new-invoice.component.html',
  styleUrls: ['./new-invoice.component.styl']
})
export class NewInvoicePage implements OnInit {
  constructor(
    public sql: SQLService,
    private route: ActivatedRoute,
    private modalController: ModalController,
    private location: Location,
    private router: Router
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.open();
    });
  }
  currencies = Object.keys(environment.currencies);
  invoice = new NewInvoice();
  _c=0;
  async add_address() {
    this.set_temp();
    const m = await this.modalController.create({component: SelectAddressPage, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1) {
        this.delete_address();
        this.sql.hapticsImpactLight();
      } else {
        this.invoice.address_ids = a.data;
        this.sql.view('Addresses','address_id',a.data,(a)=>{this.invoice.addresses=a;});
        this._c=1;
        this.sql.hapticsImpactMedium();  
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_address(address) {
    this.router.navigate(['folder','Address', address.address_id]); 
  }
  delete_address() {
    this.invoice.address_ids = null;
    this.invoice.addresses = null;
    // this.sql.query('UPDATE Invoices WHERE invoice_id = '+this.invoice.invoice_id+' SET addresses = NULL');
  }
  delete_invoice() {
    if(!this.invoice.invoice_id){
      this.end();
    } else if(confirm(this.sql.word.Delete_Invoice_Confirmation)) {
      this.sql.delete('Invoices','invoice_id',this.invoice.invoice_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  get_invoice() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida) return;
    this.sql.view('Invoices','invoice_id',ida,(e)=>{
      var f=Object.entries(e[0]); let n = new NewInvoice();
      for(let t of f)n[t[0]]=t[1];
      if(n.address_ids)this.sql.view('Addresses','address_id',n.address_ids,(a)=>{this.invoice.addresses=a;});
      this.invoice = n;
    });
  }
  set_temp() {
    if (this.invoice.invoice_id) {
      this.sql.set_temporary('page',{location:'Invocie',id:this.invoice.invoice_id});
    } else {
      this.save(false,(id) =>{
        if(!id)return;
        this.invoice.invoice_id=id;
        this.sql.set_temporary('page',{location:'Invocie',id:id});
      });
    }
  }
  end(id?) {
    this.sql.get_temporary('page',(page)=>{
      if (this._c>0) {
        if (confirm(this.sql.word.Discard_Confirmation)) {
          this.invoice = new NewInvoice();
          this.router.navigate(['folder','Invoices']);
        }
      } else {
        if(page) {
          this.router.navigate(['folder',page.location,page.id,'Invoice',id]);
          setTimeout( () => { this.sql.remove_temporary('page'); }, 600);
        } else {
          this.router.navigate(['folder','Invoices']);
        }
      }
    });
  }
  save(end=true,print?) {
    this.sql.add("Invoices", this.invoice,(insertId)=>{
      if(end) {
        if(!insertId)return;
        this._c=0;
        this.end();
      }
    });
  }
  open() {
    this.invoice.currency=this.sql.Currency;
    this.invoice.date = new Date(Date.now()).toISOString();
    this.get_invoice();
  }
  ngOnInit() {
    this.open();
  }
}