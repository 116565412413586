import { NewInsurance } from '../../models/insurance';
import { SQLService } from '../../services/sql.service';
import { PayrollService } from '../../services/payroll.service';
import { environment } from '../../../environments/environment';
import { SelectBankPage } from '../../settings/bank-accounts/select-bank/select-bank.page';
import { SelectAddressPage } from '../../addresses/select-address/select-address.page';
import { SelectEmployeePage } from '../../employees/select-employee/select-employee.page';
import { SelectPayeePage } from '../../payees/select-payee/select-payee.page';
import { ModalController, Platform } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Currencies } from '../../../environments/currencies';
@Component({
  selector: 'ng-cheques-new-insurance',
  templateUrl: './new-insurance.component.html',
  styleUrls: ['./new-insurance.component.styl']
})
export class NewInsurancePage implements OnInit {
  currencies = Object.keys(environment.currencies);
  employee = [];
  Currency = {};
  decimal_places: string;
  _c=0;
  insurance = new NewInsurance();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sql: SQLService,
    private payroll: PayrollService,
    private location: Location,
    public modalController: ModalController,
    public platform: Platform
  ) { 
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.open();
    });
  }
  async add_employee() {
    const m = await this.modalController.create({
      component: SelectEmployeePage, 
      componentProps: {select_multiple: true}
    });
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data){
      this._c=1;
      if(a.data==-1){
        this.delete_employee();
        this.sql.hapticsImpactLight();
      } else {
        this.insurance.employee_id = a.data;
        this.get_employees();
        this.sql.hapticsImpactMedium();
      }
      this.sql.remove_temporary('page');
    }
  }
  delete_policy() {
    if(!this.insurance.insurance_id){
      this.end();
    } else if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('Insurance','insurance_id',this.insurance.insurance_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  copay_amount(event) {
    if(!event.target.value||event.target.value==0||isNaN(event.target.value)){
      if(isNaN(event.target.value)){
        var am = event.target.value.split('.');
        this.insurance.copay_amount = am[0]+'.'+am[1];
      } else {
        this.insurance.copay_amount = '0.00';
      }
    } else {
      var amount=this.sql.amount(event.target.value, null, this.insurance.currency);
      this.insurance.copay_amount = amount;
    }
  }
  change_currency(c) {
    if(!this.insurance.copay_amount||!this.insurance.currency||!c||this.insurance.currency==c)return;
    this.sql.exchange_currency(this.insurance.copay_amount,this.insurance.currency,c,(amount)=>{
      this.insurance.copay_amount=amount;
      this.set_currency(c);
    });
  }
  set_currency(c) {
    this.insurance.currency=c;
    if(!this.Currency[this.insurance.currency].fiat) {
      this.insurance.fiat='0';
    } else {
      this.insurance.fiat='1';
    }
    this.set_decimal_places();
  }
  set_decimal_places() {
    this.decimal_places = '0.';
    for (var i = 0; i < this.Currency[this.insurance.currency].decimal_places; ++i) {
      this.decimal_places=this.decimal_places+'0';
      if(i==this.Currency[this.insurance.currency].decimal_places)this.decimal_places=this.decimal_places+'1';
    }
  }
  edit_employee(employee) {
    this.router.navigate(['folder','Employee', employee.employee_id]); 
  }
  delete_employee() {
    this.insurance.employee_id = null;
    this.insurance.employees = null;
    // this.sql.set('Payment','payment_id',this.insurance.payment_id,'employees',null);
  }
  get_employees() {
    if(!this.insurance.employee_id)return;
    this.insurance.employees=[];
    this.sql.view('Employees','employee_id',this.insurance.employee_id,(data)=>{
      for(let d of data)this.insurance.employees.push(d);
      this.insurance.name=this.insurance.employees[0].full_name;
    });
  }
  async add_payee() {
    const m = await this.modalController.create({component: SelectPayeePage, componentProps: {select_multiple: true}});
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data) {
      this._c=1;
      if(a.data==-1) {
        this.delete_payee();
        this.sql.hapticsImpactLight();
      } else {
        this.insurance.payee_id = a.data;
        this.get_payees();
        this.sql.hapticsImpactMedium();
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_payee(payee) {
    this.router.navigate(['folder','Payee', payee.payee_id]); 
  }
  delete_payee() {
    this.insurance.payee_id = null;
    this.insurance.payees = null;
    // this.sql.set('Payment','payment_id',this.insurance.payment_id,'payees',null);
  }
  get_payees() {
    if(!this.insurance.payee_id)return;
    this.insurance.payees=[];
    this.sql.view('Payees','payee_id',this.insurance.payee_id,(data)=>{
      for(let d of data)this.insurance.payees.push(d);
      this.insurance.name=this.insurance.payees[0].name;
    });
  }
  async add_address() {
    const m = await this.modalController.create({component: SelectAddressPage, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data){
      this._c=1;
      if(a.data==-1){
        this.delete_address();
        this.sql.hapticsImpactLight();
      } else {
        this.insurance.address_id = a.data;
        this.get_address();
        this.sql.hapticsImpactMedium();
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_address(a?) {this.router.navigate(["folder","Address",a.address_id]);}
  delete_address(j?) {
    this.insurance.address_id=null;
    this.insurance.addresses=[];
    // this.sql.set('Payment','payment_id',this.insurance.payment_id,'addresses',null);
  }
  get_address() {
    if(!this.insurance.address_id)return;
    this.insurance.addresses=[];
    this.sql.view('Addresses','address_id',this.insurance.address_id,(data)=>{
      for(let d of data)this.insurance.addresses.push(d);
    });
  }
  end() {
    if (this._c>0) {
      if (confirm(this.sql.word.Discard_Confirmation)) {
        this.insurance = new NewInsurance();
        this.router.navigate(['folder','Insurance']);
      }
    } else {
      this.router.navigate(['folder','Insurance']);
    }
  }
  toggle_paid(e){ 
    this._c=1;
    this.insurance.paid=e;
  }
  get_properties() {
    for (var i of ['employee','payee']) {
      var a = this.route.snapshot.paramMap.get(i+'id');
      if (a) this.insurance[i+'_id']=a;
    }
    this.get_address();
    this.get_employees();
    this.get_payees();
  }
  get_policy() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida){ this.get_properties();return; } else if (ida == 'New') {this.get_properties();} else {
      this.sql.view('Insurance','insurance_id',ida,(e)=>{
        var f=Object.entries(e[0]);let n=new NewInsurance();
        for(let t of f)n[t[0]]=t[1];this.insurance=n;this._c=0;
        this.get_properties();
      });
    }
  }
  save(end=true,cb?) {
    // if (this.insurance.employee_id) {
    //   this.insurance.fullname = this.insurance.employees[0].first_name + ' ' + this.insurance.employees[0].last_name;
    // } else if ( this.insurance.fullname ) {  
    //   this.insurance.fullname = this.insurance.fullname;
    // } else if ( this.insurance.title ) {
    //   this.insurance.fullname = this.insurance.title;
    // }
    delete(this.insurance.employees);
    delete(this.insurance.payees);
    this.sql.add("Insurance",this.insurance,(insertId)=>{
      if(!insertId){
        return
      } else {
        if(!cb && end){
          this.insurance = new NewInsurance();
          this._c=0;  
          this.end();
        } else {
          if(cb)cb(insertId);
        }
      }
    });
  }
  open() {
    this.Currency = Currencies;
    this.insurance.date = new Date(Date.now()).toISOString();
    this.insurance.enabled = true;
    this.insurance.currency=this.sql.Currency;
    this.get_policy();
  }
  ngOnInit() {
    // this.open();
  }
}