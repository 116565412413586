export class NewPostage {
	constructor(
		public postage_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public name?: string,
		public title?: string,
		public enabled?: boolean,
		public modified?: any,
		public created?: any
	) {}
}

export const Create_Postage = '\
	CREATE TABLE IF NOT EXISTS "PayPals" (\
		postage_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		name TEXT,\
		title TEXT,\
		enabled INTEGER DEFAULT 0,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';