export class NewAdministrator  {
	constructor(
		public admin_id?: number,
		public username?: string,
		public image?: string,
		public full_name?: string,
		public birthday?: string,
		public email?: string,
		public password?: string,
		public phone?: string,
		public employees?: any,
		public addresses?: any,
		public address_ids?: any,
		public currency?: string,
		public total_paid?: number,
		public moderations?: number,
		public savings?: number,
		public address_id?: number,
		public payment_amount_limit?: string,
		public total_limit?: string,
		public description?: string,
		public enabled?: boolean,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Administrators = '\
	CREATE TABLE IF NOT EXISTS "Administrators" (\
		admin_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		uuid TEXT,\
		username TEXT,\
		image TEXT,\
		full_name TEXT,\
		birthday TEXT,\
		email TEXT,\
		password TEXT,\
		phone TEXT,\
		currency TEXT,\
		address_id INTEGER DEFAULT 0,\
		total_paid INTEGER DEFAULT 0,\
		moderations INTEGER DEFAULT 0,\
		savings INTEGER DEFAULT 0,\
		employees TEXT,\
		addresses TEXT,\
		address_ids TEXT,\
		payment_payroll_limit INTEGER DEFAULT 0,\
		payment_amount_limit INTEGER DEFAULT 0,\
		total_limit INTEGER DEFAULT 0,\
		description TEXT,\
		enabled INTEGER DEFAULT 0,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';