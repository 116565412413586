
import { Component, ElementRef, Input, OnInit, ViewChild, HostListener, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { SQLService } from '../../../../services/sql.service';
import { page,fade,fade_in,display } from '../../../../app.animations';
import { NewSettings, PlaceHolders } from '../../../../models/settings';
import { AlertController } from '@ionic/angular';
import { Router,NavigationEnd} from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { MenuController } from '@ionic/angular';
@Component({
  selector: 'app-intro-page-1',
  templateUrl: './1-first.page.html',
  styleUrls: ['./1-first.page.styl'],
})
export class IntroFirstPageComponent implements OnInit {
  @Input('save') save:boolean
  @ViewChild('intro_slider') slider: IonSlides;
  @Output('complete') complete = new EventEmitter();
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
  }
  uri = environment.com;
  currencies = Object.keys(environment.currencies);
  procurement = environment.procurement;
  pininput = new ElementRef('pin');
  settings = new NewSettings(true);
  placeholders = new PlaceHolders();
  pass_placeholder = '••••';
  pin_placeholder = '••••';
  password: string;
  password_confirmation: any;
  pin_confirmation: any;
  pin: string;
  pass: string;
  search: string;
  count: number;
  constructor(
    public menu: MenuController,
    public alertController: AlertController,
    public sql: SQLService,
    public router: Router) { 
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) {
        this.open()
      }
    });
    this.pin_confirmation = this.alertController.create({
      header: 'PIN Confirmation',
      subHeader: 'Enter your old PIN number to confirm the change.',
      inputs: [{name: 'pin_conf',type: 'number'}],    
      buttons: [
        { text: this.sql.word.Cancel, role: 'cancel', cssClass: 'secondary', handler: () => { console.log('PIN Confirmation Cancelled.'); } }, 
        { text: 'Confirm', handler: (alertData) => 
          { 
            this.sql.change_pin(alertData.pin_conf, this.settings.pin, (results) => {
              if ( results ) {
                alert('PIN Changed Successfully!')
                console.log('PIN Updated Successfully!');
              } else { 
                alert('PIN Could Not Be Updated.')
                console.log('PIN Could Not Be Updated.');
              }
            });
          }
        }
      ]
    });
    this.password_confirmation = this.alertController.create({
      header: 'PIN Confirmation',
      subHeader: 'Enter your old PIN number to confirm the change.',
      inputs: [{name: 'pin_conf',type: 'number'}],    
      buttons: [
        { text: this.sql.word.Cancel, role: 'cancel', cssClass: 'secondary', handler: () => { console.log('PIN Confirmation Cancelled.'); } }, 
        { text: 'Confirm', handler: (alertData) => 
          { 
            this.sql.change_pin(alertData.pin_conf, this.settings.pin, (results) => {
              if ( results ) {
                alert('PIN Changed Successfully!')
                console.log('PIN Updated Successfully!');
              } else { 
                alert('PIN Could Not Be Updated.')
                console.log('PIN Could Not Be Updated.');
              }
            });
          }
        }
      ]
    });
  }
  save_pin(){
    if(this.settings.pin.length>4)
      this.sql.save_setting('pin',this.settings.pin);
  }
  process_pin(e,key){
    if(!this.settings.pin)this.settings.pin='';
    if (key==8) {
      e.preventDefault();
      this.placeholders.pin='';this.settings.pin='';
      return '';
    } else if (e.key&&Number(e.key)>-1) {
      this.settings.pin=(this.settings.pin?this.settings.pin:'')+e.key;
      this.placeholders.pin=this.placeholders.pin+'•';
    }
  }
  save_password_enabled(){
    this.sql.save_setting('password_enabled',this.settings.password_enabled);
  }
  change_password(p?) {
    this.password_confirmation.present();
    this.sql.save_setting('pin',this.password);
  }
  save_new_password(){
    this.sql.save_setting('password',this.settings.password);
  }
  toggle_pin() {
    if(this.settings.pin_enabled) {
      this.disable_pin();
    }else{
      this.enable_pin();
    }
  }
  disable_pin() {
    this.settings.pin='';
    this.settings.pin_enabled=false;
    this.sql.save_setting('pin_enabled','0');
  }
  enable_pin() {
    this.settings.pin='';
    this.settings.pin_enabled=true;
    this.sql.save_setting('pin_enabled','1');
  }
  toggle_password_protection() {
    if ( this.settings.password_enabled ) {
      this.disable_password_protection();
    } else {
      this.enable_password_protection();
    }
  }
  enable_password_protection() {
    this.settings.password_enabled = true;
  }
  disable_password_protection() {
    this.settings.password_enabled = false;
  }
  get_settings(event?) {
    this.sql.get_setting('pin_enabled',(s)=>{this.settings.pin_enabled=(s.option=="1")?true:false});
    this.sql.get_setting('password_enabled',(s)=>{this.settings.password_enabled=s.option});
    this.sql.get_setting('password',(s)=>{this.settings.password=s.option});
  }
  open() {
    this.settings.pin='';
    this.placeholders.pin ='';
    this.placeholders.pass='';
    this.get_settings();
  }
  ngOnInit() {this.open()}

}