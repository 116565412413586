import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { SQLService } from '../services/sql.service';
import { PopoverController} from '@ionic/angular';
import { SalaryMenuComponent } from './salary-menu/salary-menu.component';
@Component({
  selector: 'ng-cheques-salary',
  templateUrl: './salary.component.html',
  styleUrls: ['./salary.component.styl']
})
export class SalaryComponent implements OnInit {
  constructor(
    private router: Router,
    public popoverController: PopoverController,
    public sql: SQLService
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_salaries();
    });
  }
  salaries = [];
  count = -1;
  search: string;
  add() {this.router.navigate(['folder','Salary', 'New']);}
  end() {this.router.navigate(['folder','Dashboard']);}
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:SalaryMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='enable')this.enable(item);
      if(data=='disable')this.disable(item);
      if(data=='delete')this.delete(item, false);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  filter(e) {
    this.search=e.target.value;
  }
  get_salaries(event?) {
    this.sql.fetch('Salaries',(e)=>{
      if(e)this.salaries=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  edit(a?,i?) {
    this.router.navigate(['folder','Salary',a.salary_id]);
  }
  enable(salary,i?){
    this.sql.set('Salaries','salary_id',salary.salary_id,'enabled',1,(res)=>{
      salary.enabled = true;
      if(i)i.closeSlidingItems();
    });
  }
  disable(salary,i?){
    this.sql.set('Salaries','salary_id',salary.salary_id,'enabled',0,(res)=>{
      salary.enabled = false;
      if(i)i.closeSlidingItems();
    });
  }
  delete(a?,i?) {
    if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('Salaries','salary_id',a.salary_id);
      this.get_salaries();
    } else { if(i)i.closeSlidingItems(); }
  }
  ngOnInit() {
    this.get_salaries();
  }
}