export class NewPrinter {
	constructor(
		public printer_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public title?: string,
		public status?: string,
		public description?: string,
		public protocol?: string,
		public queue_name?: string,
		public queue?: number,
		public host?: string,
		public page_limit?: number,
		public pages_idle_time?: number,
		public copies?: number,
		public pages?: number,
		public cheques?: number,
		public invocies?: number,
		public enabled?: boolean,
		public advanced?: boolean,
		public monochrome?: boolean,
		public photo?: boolean,
		public autoFit?: boolean,
		public paper?: any,
		public paper_name?: any,
		public paper_width?: any,
		public paper_height?: any,
		public paper_length?: any,
		public enable_margin?: boolean,
		public margin?: boolean,
		public margin_top?: any,
		public margin_right?: any,
		public margin_bottom?: any,
		public margin_left?: any,
		public header?: any,
		public enable_header?: boolean,
		public header_label_text?: any,
		public header_label_showPageIndex?: any,
		public header_font_size?: number,
		public header_label_font_bold?: any,
		public header_label_font_italic?: any,
		public header_label_font_align?: any,
		public header_label_font_color?: any,
		public header_label_font?: any,
		public enable_footer?: boolean,
		public footer?: any,
		public footer_label_text?: any,
		public footer_label_showPageIndex?: any,
		public footer_font_size?: number,
		public footer_label_font_bold?: any,
		public footer_label_font_italic?: any,
		public footer_label_font_align?: any,
		public footer_label_font_color?: any,
		public footer_label_font?: any,
		public email?: any,
		public printer?: any,
		public orientation?: any,
		public duplex?: any,
		public modified?: any,
		public created?: any
	){}
}
export const Create_Printers = '\
	CREATE TABLE IF NOT EXISTS "Printers" (\
		printer_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER,\
		mod_id INTEGER,\
		title STRING,\
		status STRING,\
		description STRING,\
		protocol STRING,\
		queue_name STRING,\
		queue INTEGER,\
		host STRING,\
		page_limit NUMBER,\
		pages_idle_time NUMBER,\
		copies NUMBER,\
		pages NUMBER,\
		cheques NUMBER,\
		invocies NUMBER,\
		enabled INTEGER,\
		advanced INTEGER,\
		monochrome INTEGER,\
		photo INTEGER,\
		autoFit INTEGER,\
		enable_header INTEGER,\
		header_label_text STRING,\
		header_label_showPageIndex STRING,\
		header_font_size STRING,\
		header_label_font_bold STRING,\
		header_label_font_italic STRING,\
		header_label_font_align STRING,\
		enable_footer INTEGER,\
		footer_label_text STRING,\
		footer_label_showPageIndex STRING,\
		footer_font_size STRING,\
		footer_label_font_bold STRING,\
		footer_label_font_italic STRING,\
		footer_label_font_align STRING,\
		enable_margin INTEGER,\
		margin INTEGER,\
		margin_top STRING,\
		margin_right STRING,\
		margin_bottom STRING,\
		margin_left STRING,\
		paper STRING,\
		email STRING,\
		printer STRING,\
		paper_name STRING,\
		paper_width STRING,\
		paper_height STRING,\
		paper_length STRING,\
		orientation STRING,\
		duplex STRING,\
		header STRING,\
		footer STRING,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';