import { Component, OnInit } from '@angular/core';
import { SQLService } from '../../services/sql.service';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit {
  constructor(
    public sql: SQLService,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_messages();
    });
  }
  count = -1; 
  messages = [];
  search:string;
  filter(e) { this.search=e.target.value; }
  add() {
    this.router.navigate(['folder','Messages','New']);
  }
  get_messages(event?) {
    this.sql.fetch('Messages',(e)=>{
      this.messages=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  edit_message(msg, item?) {
    this.router.navigate(['folder','Message',msg.message_id]);
  }
  delete_message(msg, item) {
    if(confirm(this.sql.word.Discard_Confirmation)) {
      this.sql.delete('Messages','message_id',msg.mod_id);
      this.get_messages();
    } else { item.closeSlidingItems(); }
  }
  ngOnInit() {
    this.get_messages();
  }
}
