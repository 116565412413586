import { Component, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { SQLService } from '../../../services/sql.service';
import { backgrounds, backgrounds_lowrez } from '../../../../environments/logos';
@Component({
  selector: 'app-cheque-red',
  templateUrl: './cheque-red.component.html',
  styleUrls: ['./cheque-red.component.scss'],
})
export class ChequeRedComponent implements AfterViewInit {
  constructor(private sql: SQLService) { }
  @Output('loaded') loaded = new EventEmitter<boolean>();
  @Input('RenderMICR') RenderMICR = false;
  @Input('ImageMICR') ImageMICR = true;
  @Input('show') show = 'table';
  @Input('cheque') cheque: any;
  @Input('design') design: any;
  @Input('designer') designer: any;
  @Input('rotate') rotate: boolean;
  @Input('borders') borders: boolean;
  @Input('voidcheck') voidcheck: boolean;
  @Input('font') font: any;
  @Input('fiat') fiat: boolean;
  @Input('color') color: any;
  @Input('micr') micr: any;
  micr_top=false;
  micr_right=false;
  micr_bottom='4mm';
  micr_left='7mm';
  @Input('secret') secret = '';
  @Input('scale') scale =1;
  @Input('fold_line') fold_line: boolean;
  @Input('template') template: string;
  @Input('monochrome') monochrome: boolean;
  @Input('gradients') gradients: boolean;
  @Input('margin_bottom') bottom = '0mm';
  @Input('margin_top') top = '0mm';
  @Input('fontsize') fontsize: boolean;
  @Input('layers') layers: any;
  @Input('translations') translations = this.sql.word;
  @Input('bold') bold: boolean;
  @Input('italic') italic: boolean;
  @Input('show_cheque_number') show_cheque_number: boolean = true;
  @Input('show_cheque_date') show_cheque_date: boolean = true;
  @Input('show_micr') show_micr: boolean = true;
  @Input('cheque_background_image_repeat') cheque_background_image_repeat = false;
  @Input('background_position') background_position: string;
  @Input('cheque_text_color') cheque_text_color: string;
  @Input('cheque_background_image') cheque_background_image: any;
  @Input('cheque_logo_image') cheque_logo_image: any;
  @Input('cheque_header_image') cheque_header_image: any;
  @Input('cheque_bank_image') cheque_bank_image: any;
  @Input('doc_color') doc_color: string = 'red';
  bg_image: string;
  ngAfterViewInit() {
    this.design["date"]["top"]='12.3mm';
    this.design["date"]["right"]='17.5mm';
    // this.design["date"]["top"]='42.5px';
    // this.design["date"]["right"]='81px';
    this.design["date_label"]["display"]=false;
    this.design["payline"]["prefix"]=false;
    this.design["payline"]["borders"]=false;
    this.design["payline"]["top"]='86.5px';
    this.design["payline"]["left"]='142px';
    this.design["payline"]["right"]='27px';
    this.design["payline"]["bottom"]='4px';
    this.design["bank_account_holder"]["top"]='3.3mm';
    this.design["bank_account_holder"]["left"]='7.5mm';
    this.design["printed_id"]["top"]='7.5mm';
    this.design["printed_id"]["right"]='11.5mm';
    this.design["payment_name"]["left"]="30px";
    this.design["payment_name"]["bottom"]="5.2px";
    this.design["payment_name"]["right"]="7mm";
    this.design["bank_image"]["top"]="13.2mm";
    this.design["bank_image"]["left"]="28px";
    this.design["bank_name"]["top"]="8.7mm";
    this.design["bank_name"]["left"]="35.7mm";
    this.design["bank_address"]["top"]="13.5mm";
    this.design["bank_address"]["left"]="26mm";
    this.design["bank_address_2"]["top"]="16.5mm";
    this.design["bank_address_2"]["left"]="26mm";
    this.design["bank_address_3"]["top"]="20mm";
    this.design["bank_address_3"]["left"]="26mm";
    this.design["written_amount"]["top"]='33mm';
    this.design["written_amount"]["right"]=false;
    this.design["written_amount"]["bottom"]='auto';
    this.design["written_amount"]["left"]='30mm';
    this.design["written_amount"]["index"]=false;
    this.design["written_amount_line"]["width"]='550px';
    this.design["written_amount_line"]["borders"]=false;
    this.design["written_amount_line"]["overflow"]=true;
    this.design["written_amount_line"]["top"]='0px';
    this.design["written_amount_line"]["right"]='0px';
    this.design["written_amount_line"]["bottom"]='0px';
    this.design["written_amount_line"]["left"]='0px';
    this.design["multiline_written_amount_chars"]=62;
    this.design["written_amount_line2"]["top"]='8mm'
    this.design["written_amount_line2"]["right"]='0mm'
    this.design["written_amount_line2"]["bottom"]='0mm'
    this.design["written_amount_line2"]["left"]='-21mm'
    this.design["amounts"]["symbol"]=false;
    this.design["amount_number"]["borders"]=false;
    this.design["amount_number"]["background"]=true;
    this.design["amount_number"]["top"]="2.7mm";
    this.design["amount_number"]["right"]="auto";
    this.design["amount_number"]["left"]="-5.5mm";
    this.design["memo"]["label"]=false;
    this.design["memo"]["border"]=false;
    this.design["memo"]["left"]='11mm';
    this.design["memo"]["bottom"]='15.5mm';
    this.design["memo"]["width"]='172px';
    this.design["public_image"]["top"]='32.5mm';
    this.design["public_image"]["left"]='auto';
    this.design["public_image"]["right"]='6.1mm';
    this.design["public_image"]["width"]='77';
    this.design["public_image"]["zindex"]='1';
    this.design['public_image']['QRcolorDark']="#000000";
    this.design['public_image']['QRcolorLight']="#FFFFFF";
    this.design['public_image']['QRCodeMargin']="3";
    this.design["key_image"]["width"]='77';
    this.design["key_image"]["top"]='12.2mm';
    this.design["key_image"]["left"]='8.1mm';
    this.design["key_image"]["zindex"]='1';
    this.design['key_image']['QRcolorDark']="#000000";
    this.design['key_image']['QRcolorLight']="#FFFFFF";
    this.design['key_image']['QRCodeMargin']="1";
    this.design["currency_name"]["top"]='19.8mm';
    this.design["currency_name"]["left"]='30.6mm';
    this.design["currency_name"]["width"]='80mm';
    this.design["address_data"]["top"]='24mm';
    this.design["address_data"]["right"]='75mm';
    this.design["address_data"]["bottom"]='auto';
    this.design["address_data"]["left"]='30.5mm';
    this.design["address_data"]["width"]='80mm';
    this.design["signature"]["width"]='145px';
    this.design["signature"]["border"]=false;
    this.design["signature"]["label"]=false;
    this.design["signature"]["right"]='auto';
    this.design["signature"]["container_width"]='37.5mm';
    this.design["signature"]['bottom']='4mm'; 
    this.design["signature"]['left']='114mm';
    this.design["signature"]["image"]["top"]="auto";
    this.design["signature"]["image"]["right"]="auto";
    this.design["signature"]["image"]["bottom"]="auto";
    this.design["signature"]["image"]["left"]="auto";
    this.design["signature"]["info"]["top"]="-13.1mm";
    this.design["signature"]["info"]["left"]="0mm";
    this.design["signature"]["info"]["right"]="auto";
    this.design["signature"]["desc"]["top"]="-13.1mm";
    this.design["signature"]["desc"]["right"]="1mm";
    this.design["signature"]["desc"]["left"]="auto";
    this.design["micr"]["top"]='auto';
    this.design["micr"]["right"]='auto';
    this.design["micr"]["bottom"]='4mm';
    this.design["micr"]["left"]='7mm';
    this.design["micr"]["background"]=true;
    if(this.sql.model=='web') {
      var img = "red";
      this.sql.load_background(img,(base64Img)=>{
        this.bg_image=base64Img;
      },this.sql.production);
    } else {
      this.bg_image = this.sql.production?backgrounds.red:backgrounds_lowrez.red;
    }
}
}