import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NewIncome } from '../../models/income';
import { SQLService } from '../../services/sql.service';
import { environment } from '../../../environments/environment';
import { Currencies } from '../../../environments/currencies';
import { ModalController, Platform } from '@ionic/angular';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { PurchaseComponent } from '../../dashboard/purchase/purchase.component';
import { CalendarModal, CalendarModalOptions, DayConfig, CalendarResult } from 'ion2-calendar';
@Component({
  selector: 'app-new-income',
  templateUrl: './new-income.component.html',
  styleUrls: ['./new-income.component.scss'],
})
export class NewIncomeComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public modalController: ModalController,
    public sql: SQLService
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd)this.open()
    });
  }
  currencies = environment.currencies;
  Currencies = Object.keys(environment.currencies);
  Currency = {};
  income = new NewIncome();
  decimal_places = '0.01';
  _c=0;
  save(end=true) {
    this.sql.add('Incomes',this.income,(insertId)=>{
      if(!insertId)return;
      if(end){
        this.income = new NewIncome();
        this._c=0;
        this.end();
      }
    });
  }
  delete_income() {
    if(!this.income.income_id){
      this.end();
    } else if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('Incomes','income_id',this.income.income_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  amount(event) {
    if(!event.target.value||event.target.value==0||isNaN(event.target.value)){
      if(isNaN(event.target.value)){
        var am = event.target.value.split('.');
        this.income.amount = am[0]+'.'+am[1];
      } else {
        this.income.amount = '0.00';
      }
    } else {
      var amount=this.sql.amount(event.target.value, null, this.income.currency);
      this.income.amount = amount;
    }
  }
  change_currency(c) {
    if(!this.income.amount||!this.income.currency||!c||this.income.currency==c)return;
    this.sql.exchange_currency(this.income.amount,this.income.currency,c,(amount)=>{
      this.income.amount=amount;
      this.set_currency(c);
    });
  }
  set_currency(c) {
    this.income.currency=c;
    if(!this.Currency[this.income.currency].fiat) {
      this.income.key='';
      this.income.data='';
      this.income.fiat='0';
    } else {
      this.income.fiat='1';
    }
    this.set_decimal_places();
  }
  set_decimal_places() {
    this.decimal_places = '0.';
    for (var i = 0; i < this.Currency[this.income.currency].decimal_places; ++i) {
      this.decimal_places=this.decimal_places+'0';
      if(i==this.Currency[this.income.currency].decimal_places)this.decimal_places=this.decimal_places+'1';
    }
  }
  async openCalendar() {
    var options:CalendarModalOptions={
      title:this.sql.word.Cheque_Date,
      defaultDate:this.income.date?this.income.date:null
    };
    var myCalendar = await this.modalController.create({component:CalendarModal,componentProps:{options}});
        myCalendar.present();
    var event: any = await myCalendar.onDidDismiss();
    var date: CalendarResult=event.data;
    this.income.date=date.string;
  }
  set_key(event){
    if(event.target.value) {
      this._c=1;
      this.income.key=event.target.value;
    } else {
      this.income.key='';
    }
  }
  set_data(event){
    if(event.target.value) {
      this._c=1;
      this.income.data=event.target.value;
    } else {
      this.income.data='';
    }
  }
  end() {
    if (this._c>0) {
      if (confirm(this.sql.word.Discard_Confirmation)) {
        this.income = new NewIncome();
        this.router.navigate(['folder','Incomes']);
      }
    } else {
      this.router.navigate(['folder','Incomes']);
    }
  }
  get_income() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida) return;
    this.sql.view('Incomes','income_id',ida,(e)=>{
      var f=Object.entries(e[0]); let n = new NewIncome();
      for(let t of f)n[t[0]]=t[1];
      this.income = n;
    });
  }
  open() {
    this.get_income();
  }
  ngOnInit() {
    this.open();
  }
}
