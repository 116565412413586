export class NewDeduction {
	constructor(
		public deduction_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public employee_id?: number,
		public frequency?: any,
		public amount?: any,
		public percentage?: string,
		public currency?: string,
		public beginning?: any,
		public ending?: any,
		public title?: string,
		public paytype?: string,
		public description?: string,
		public always_enabled?: boolean,
		public start_date?: any,
		public start_time?: any,
		public end_date?: any,
		public end_time?: any,
		public enabled?: boolean,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Deductions = '\
	CREATE TABLE IF NOT EXISTS "Deductions" (\
		deduction_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		employee_id INTEGER,\
		frequency VARCHAR(100),\
		amount TEXT,\
		percentage TEXT,\
		currency VARCHAR(10),\
		always_enabled INTEGER DEFAULT 0,\
		beginning INTEGER,\
		ending INTEGER,\
		enabled INTEGER DEFAULT 0,\
		title TEXT,\
		paytype TEXT,\
		description TEXT,\
		total_amount_deducted INTEGER DEFAULT 0,\
		start_date TIMESTAMP,\
		start_time TIMESTAMP,\
		end_date TIMESTAMP,\
		end_time TIMESTAMP,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';