
import { Component, OnInit, Input, ElementRef, ViewChild, HostListener,Output,EventEmitter } from '@angular/core';
import { SignatureComponent } from '../../../../settings/signature-pad/signature-pad.component';
import { ViewSignatureComponent } from '../../../../settings/view-signature/view-signature.component'
import { NewBank } from '../../../../models/bank';
import { environment } from '../../../../../environments/environment';
import { SQLService } from '../../../../services/sql.service';
import { page,fade,fade_in,display } from '../../../../app.animations';
import { Router,ActivatedRoute,NavigationEnd } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { Location } from '@angular/common';
import { MenuController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-intro-page-3',
  templateUrl: './3-third.page.html',
  styleUrls: ['./3-third.page.styl'],
})
export class IntroThirdPageComponent {
  constructor(
    public sql: SQLService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    public modalController: ModalController
  ) {
  }
  @Input('save') _save:boolean
  ngOnInit() {
  }
}