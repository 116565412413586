import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SQLService } from '../../services/sql.service';
import { page,fade,fade_in,opacity,display,color_highlight } from '../../app.animations';
import { ModalController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { MenuController } from '@ionic/angular';
import { Location } from '@angular/common';
import { SplashScreen } from '@capacitor/splash-screen';
@Component({
  selector: 'app-intro',
  animations: [page,fade,opacity,fade_in,display,color_highlight],
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
})
export class IntroComponent implements OnInit {
  showHeader=true;
  showSlider=false;
  showSkip=false;
  loaded=false;
  page5save=false;
  page6save=false;
  page2save=false;
  page3save=false;
  page=-1;
  ending=0;
  SJS=true;
  hl=[];
  ShowEmployee=true;
  ShowSalary=true;
  ShowBank_Account=true;
  ShowSchedule=true;
  @ViewChild('Slider', { static: false }) slider: IonSlides; 
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {  
  }
  constructor(
    public menu: MenuController,
    public sql: SQLService,
    public location: Location,
    public navCtrl: ModalController,
    public router: Router) { 
    this.router.events.subscribe((val) => {
      this.page=0;
      this.ending=0;
      this.menu.close();
      this.sql.remove_temporary('LoggedIn');  
    });
  }
  save_schedule:boolean;
  ngOnInit() {
    this.sql.count('Employees',(c)=>{
      if(this.sql.Trial_Employees!=-1 && c>=this.sql.Trial_Employees)this.ShowEmployee=false;
      this.sql.count('Salaries',(c)=>{
        if(this.sql.Trial_Salaries!=-1 && c>=this.sql.Trial_Salaries)this.ShowSalary=false;
        this.sql.count('BankAccounts',(c)=>{
          if(this.sql.Trial_Bank_Accounts!=-1 && c>=this.sql.Trial_Bank_Accounts)this.ShowBank_Account=false;
          this.sql.count('Schedules',(c)=>{
            if(this.sql.Trial_Schedules!=-1 && c>=this.sql.Trial_Schedules)this.ShowSchedule=false;
          });
        });
      });
    });
    setTimeout(()=>{this.loaded=true},2000);
    this.page=0;
    this.count(); 
  }
  log(e){console.log(e);}
  unit_complete(e){
    console.log('UNIT COMPLETE',e);
  }
  slide_changed(e?){ 
    this.page=this.page+1;
    console.log('this.page',this.page)
    if(this.page>4){
      console.log('Save Schedule');
      this.page2save=true;
    } else if (this.page>3) {
      console.log('Save Bank');
      this.page3save=true;
    } else if (this.page>2){
      console.log('Save Salary');
      this.page6save=true;
    } else if(this.page>1){
      console.log('Save Employees');
      this.page5save=true;
    }
  }
  next(){this.slider.slideNext()}
  prev(){this.slider.slidePrev()}
  back(){this.location.back()}
  skip(){this.navCtrl.dismiss();this.router.navigate(['folder','Dashboard'])}
  new(){this.router.navigate(['folder','Cheque','New'])}
  end(){
    this.navCtrl.dismiss();this.router.navigate(['folder','Dashboard'])
  }
  done() {
    this.sql.setup_complete=true;
    this.sql.save_setting('setup_complete','true');
    this.end();
  }
  changed(event){console.log(event)}
  count() {
    this.sql.count('Printers',(p)=>{
      this.sql.count('BankAccounts',(b)=>{
        this.showSkip = (p>0 && b>0)?true:false;
      });
    });
  }
  ngOnDestroy() {
    this.loaded=false;
  }
}