import * as converter from 'number-to-words';
import * as ChequeTemplates from '../../../templates';
import pdfFonts from '../../../../../../pdfmake/build/fonts';
import { environment } from '../../../../../environments/environment';
import { SQLService } from '../../../../services/sql.service';
import { NewDesign } from '../../../../models/design';
import { Component, OnInit, Input, ViewChild } from '@angular/core';import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'cheque-designer-canvas',
  templateUrl: './cheque-canvas.component.html',
  styleUrls: ['./cheque-canvas.component.styl']
})
export class ChequeDesignerCanvasComponent implements OnInit {
  constructor(
    public sql: SQLService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
  ) { }
  @Input('design') design: any;
  @ViewChild('pdfViewerAutoLoad') pdfViewerAutoLoad;
  PDF_Generator() {
    // if(!this.design)console.error('No Cheque Design Pattern Found');
    // let ChequeDocument = {
    //   content: [{
    //     columns: [
    //       { width: '*', text: '' },
    //       { width: 'auto',
    //         table: {
    //           widths: [ 'auto' ],
    //           body: [this.design.design_pattern]
    //         }
    //       },
    //       { width: '*', text: '' },
    //     ],
    //   }],
    //   styles: {
    //     ChequeBox: {
    //       margin: [0, 5, 0, 15],
    //       fillColor: (this.design.design_background_colour)?this.design.design_background_colour:'#FFF'
    //     }
    //   }
    // };
    // pdfMake.vfs = pdfFonts.pdfMake.vfs;
    // pdfMake.createPdf(ChequeDocument, null, ChequeTemplates.Fonts).getBlob((blob) => {
    //   if (!blob) {alert(this.sql.word.Error_Generating_PDF);}
    //   if(environment.verbose)console.log('PDF Rendered', blob);
    //   this.pdfViewerAutoLoad.pdfSrc = blob;
    //   this.pdfViewerAutoLoad.refresh();
    // });
  }
  ngOnInit() {
    this.PDF_Generator();
    console.log(this.design);
  }
}