export class NewHoliday {
	constructor(
		public holiday_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public name?: string,
		public repeat?: string,
		public description?: string,
		public start_date?: any,
		public start_time?: any,
		public end_date?: any,
		public end_time?: any,
		public currency?: string,
		public overtime_amount?: any,
		public overtime_percentage?: any,
		public enabled?: boolean,
		public modified?: any,
		public created?: any
	){}
}
export const Create_Holidays = '\
	CREATE TABLE IF NOT EXISTS "Holidays" (\
		holiday_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		name TEXT,\
		repeat TEXT,\
		description TEXT,\
		start_date TIMESTAMP,\
		start_time TIMESTAMP,\
		end_date TIMESTAMP,\
		end_time TIMESTAMP,\
		enabled INTEGER DEFAULT 0,\
		currency TEXT,\
		overtime_amount INTEGER DEFAULT 0,\
		overtime_percentage INTEGER DEFAULT 0,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';