export class NewPayroll {
	constructor(
		public frequency?: string,
		public time_method?: string,
		public enabled?: boolean,
		public indefinite_payroll_enabled?: boolean,
		public admin_id?: number,
		public mod_id?: number,
		public payroll_id?: any,
		public employee_ids?: any,
		public payee_ids?: any,
		public deduction_ids?: any,
		public cheque_ids?: any,
		public leave_ids?: any,
		public leaves?: any,
		public job_ids?: any,
		public timesheet_ids?: any,
		public vacation_ids?: any,
		public vacations?: any,
		public tax_ids?: any,
		public bank_ids?: any,
		public timesheets?: any,
		public employees?: any,
		public payees?: any,
		public jobs?: any,
		public deductions?: any,
		public taxes?: any,
		public banks?: any,
		public payment_method?: any,
		public schedules?: any,
		public schedule_ids?: any,
		public schedule_id?: any,
		public paypals?: any,
		public paypal_ids?: any,
		public accounts_count?: number,
		public employee_count?: number,
		public payee_count?: number,
		public amount?: string,
		public last_amount?: number,
		public total?: number,
		public total_amount?: number,
		public currency?: string,
		public fullname?: string,
		public title?: string,
		public date?: any,
		public next_date?: any,
		public start_date?: any,
		public start_time?: any,
		public end_date?: any,
		public end_time?: any,
		public payroll_limit?: any,
		public payment_limit?: any,
		public total_limit?: any,
		public description?: string,
		public printed_memo?: string,
		public paid?: boolean,
		public last_run?: any,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Payrolls = '\
	CREATE TABLE IF NOT EXISTS "Payrolls" (\
		payroll_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		employee_ids TEXT,\
		payee_ids TEXT,\
		deduction_ids TEXT,\
		cheque_ids TEXT,\
		leave_ids TEXT,\
		leaves TEXT,\
		vacations TEXT,\
		job_ids TEXT,\
		timesheet_ids TEXT,\
		vacation_ids TEXT,\
		tax_ids TEXT,\
		bank_ids TEXT,\
		timesheets TEXT,\
		employees TEXT,\
		payees TEXT,\
		jobs TEXT,\
		deductions TEXT,\
		taxes TEXT,\
		banks TEXT,\
		payment_method TEXT,\
		schedules TEXT,\
		schedule_ids TEXT,\
		schedule_id TEXT,\
		paypals TEXT,\
		paypal_ids TEXT,\
		accounts_count INTEGER DEFAULT 0,\
		employee_count INTEGER DEFAULT 0,\
		payee_count INTEGER DEFAULT 0,\
		amount INTEGER DEFAULT 0,\
		last_amount TEXT,\
		total TEXT,\
		total_amount TEXT,\
		currency TEXT,\
		fullname TEXT,\
		title TEXT,\
		date TIMESTAMP,\
		next_date TIMESTAMP,\
		start_date TIMESTAMP,\
		start_time TIMESTAMP,\
		end_date TIMESTAMP,\
		end_time TIMESTAMP,\
		frequency TEXT,\
		time_method TEXT,\
		description TEXT,\
		printed_memo TEXT,\
		paid INTEGER DEFAULT 0,\
		indefinite_payroll_enabled INTEGER DEFAULT 0,\
		enabled INTEGER DEFAULT 0,\
		last_run TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';