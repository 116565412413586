import { SQLService } from '../../../services/sql.service';
import { PaymentsService } from '../../../services/payments.service';
import { ColorPickerComponent } from '../color-picker/color-picker.component';
import { PurchaseComponent } from '../../../dashboard/purchase/purchase.component';
import { ChequeDesignerComponent } from '../cheque-designer/cheque-designer.component';
import { DocugardColors } from '../../templates_html';
import { Flip } from '../../../app.animations';
import { NewDesign } from '../../../models/design';
import { PaperSizes } from '../../../../environments/papersizes';
import { Component, OnInit, EventEmitter,Input,ViewChild } from '@angular/core';
import { Router,NavigationEnd,ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController, NavParams, IonContent } from '@ionic/angular';
@Component({
  selector: 'app-paper-picker',
  templateUrl: './paper-picker.page.html',
  styleUrls: ['./paper-picker.page.styl'],
  animations: [ Flip ]
})
export class SelectPaperPage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    private payments: PaymentsService,
    private sql: SQLService,
    private params: NavParams,
    private location: Location
  ) {
    this.default_paper.name=this.params.get('name');
    this.default_paper.name_for_printer=this.params.get('printer');
    this.default_paper.width_for_printer=this.params.get('printer_width');
    this.default_paper.height_for_printer=this.params.get('printer_height');
    this.default_paper.width=this.params.get('width');
    this.default_paper.height=this.params.get('height');
    this.default_paper.rotate=this.params.get('rotate');
    this.default_paper.monochrome=this.params.get('monochrome');
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd)
        this.get_default();
    });
  }
  @ViewChild(IonContent) content: IonContent;
  scrollToTop() {this.content.scrollToTop(400);}
  filter(e) { this.search=e.target.value; }
  count = -1;
  papers = [];
  search: string;
  default_paper={slug:'',name:false,name_for_printer:false,width:'',height:'',width_for_printer:'210mm',height_for_printer:'297mm',rotate: false,monochrome: false};
  output = new EventEmitter();
  select(name) {
    this.default_paper.name=name;
    this.papers=PaperSizes;
    for(let p of this.papers)if(name==p.name){
      this.default_paper.slug=p.slug;
      this.default_paper.name=p.name;
      this.default_paper.width=p.width;
      this.default_paper.height=p.height;
      if(p.rotate){
        this.default_paper.rotate=true;
      } else {
        this.default_paper.rotate=(198<Number(p.width.substr(0,p.width.length-2)))?true:false;
      }
    }
  }
  select_printer(paper) {
    for(let p of this.papers)if(paper==p.name){
      this.default_paper.name_for_printer=p.name;
      this.default_paper.width_for_printer=p.width;
      this.default_paper.height_for_printer=p.height;
      if(p.rotate){
        this.default_paper.rotate=true;
      } else {
        this.default_paper.rotate=(198<Number(p.width.substr(0,p.width.length-2)))?true:false;
      }
    }
  }
  save_paper(paper?) {
    if(paper)console.log(paper);
  }
  ngOnInit() {
    this.papers=PaperSizes;
    this.count=this.papers.length;
    this.get_default();
  }
  get_default() {
    this.sql.get_setting('default_paper',(s)=>{for(let p of this.papers)if(s.option==p.name){
      this.default_paper.slug=p.slug;
      this.default_paper.name=p.name;
      this.default_paper.width=p.width;
      this.default_paper.height=p.height;
      if(p.rotate){
        this.default_paper.rotate=true;
      } else {
        this.default_paper.rotate=(198<Number(p.width.substr(0,p.width.length-2)))?true:false;
      }
    }});
  }
  purchase() {
    this.end();
    this.beggar();
  }
  async beggar() {
    const m = await this.navCtrl.create({
      component: PurchaseComponent, 
      componentProps: {}
    });
    await m.present();
    var a = await m.onDidDismiss();
  }
  end() {this.navCtrl.dismiss(0);}
  done() {
    this.sql.save_setting('default_paper',this.default_paper.name);
    var data = {
      rotate: this.default_paper.rotate,
      monochrome: this.default_paper.monochrome,
      slug: this.default_paper.slug,
      name: this.default_paper.name,
      width: this.default_paper.width,
      height: this.default_paper.height,
      printer: this.default_paper.name_for_printer,
      printer_width: this.default_paper.width_for_printer,
      printer_height: this.default_paper.height_for_printer
    }
    this.navCtrl.dismiss(data);
  }
}