export class NewBackup {
	constructor(
		public backup_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public type?: string,
		public user?: string,
		public pass?: string,
		public host?: string,
		public port?: string,
		public path?: string,
		public database?: any,
		public secure?: boolean,
		public auto?: boolean,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Backups = '\
	CREATE TABLE IF NOT EXISTS "Backups" (\
		backup_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		type TEXT,\
		user TEXT,\
		pass TEXT,\
		host TEXT,\
		port TEXT,\
		path TEXT,\
		secure TEXT,\
		database TEXT,\
		auto INTEGER DEFAULT 0,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';