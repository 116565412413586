import { environment } from '../../../../../environments/environment';
import { SQLService } from '../../../../services/sql.service';
import { NewImport } from '../../../../models/import';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'ng-cheques-add-export',
  templateUrl: './add-export.component.html',
  styleUrls: ['./add-export.component.styl']
})
export class AddExportComponent implements OnInit {
  constructor(
    public sql: SQLService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
  ) { }
  _c = -1;
  options = [
    'Name',
    'Amount',
    'Currency',
    'Date',
    'Printed_Memo'
  ];
  currency = null;
  newimport = new NewImport();
  currencies = Object.keys(environment.currencies);
  save(end=true) {
    this.sql.add("Imports",this.newimport);
    this.newimport = new NewImport();
    this._c=0;
    if(end)this.end();
  }
  end() {
    if (this._c>0) {
      if(confirm(this.sql.word.Discard_Confirmation)) {
        this.newimport = new NewImport();
        this.router.navigate(['folder','Imports']);
      }
    } else { 
      this.router.navigate(['folder','Imports']);
    }
  }
  get_import() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida) return;
    this.sql.view('Imports','import_id',ida,(e)=>{
      var f=Object.entries(e[0]); let n = new NewImport();
      for(let t of f)n[t[0]]=t[1];
      this.newimport = n;
    });
  }
  ngOnInit() {
    this.get_import();
  }
}