export class NewLogin {
	constructor(
		public email?: string,
		public username?: string,
		public password?: string,
		public password_conf?: string,
		public terms?: boolean,
		public modified?: any,
		public created?: any
	) {}
}