import { SQLService } from '../../../services/sql.service';
import { Flip } from '../../../app.animations';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute,NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-select-backup-type',
  templateUrl: './select-backup-type.page.html',
  styleUrls: ['./select-backup-type.page.styl'],
  animations: [ Flip ]
})
export class SelectBackupTypePage {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    public sql: SQLService,
    private location: Location
  ) {
  }
  off() {
    this.navCtrl.dismiss();
  }
}