import { BrowserModule } from '@angular/platform-browser'; 
import { trigger, state, animate, transition, style } from '@angular/animations';
export const Flip = trigger('Flip', [
    state('true', style({
        "transform": "rotateX(-180deg)"
    })),
    state('false', style({ 
        "transform": "rotateX(0deg)"
    })),
    transition('*=>*', animate('400ms ease-out'))
]);
export const turn = trigger('turn', [
    state('true', style({
        "transform": "rotate(90deg)"
    })),
    state('false', style({ 
        "transform": "rotate(0deg)"
    })),
    transition('*=>*', animate('200ms'))
]);
export const page = trigger('page', [
    state('true', style({
        'transform': 'translateX(0%)'
    })),
    state('false', style({
        'transform': 'translateX(0%)'
    })),
    state('in', style({
        'transform': 'translateX(100%)'
    })),
    state('out', style({
        'transform': 'translateX(-100%)'
    })),
    transition('*=>*', animate('300ms'))
]);
export const fade = trigger('fade', [
    state('true', style({
        "opacity": "1",
        "display": "block"
    })),
    state('false', style({ 
        "opacity": "0",
        "display": "none"
    })),
    transition('*=>*', animate('400ms ease-out'))
]); 
export const lock = trigger('lock', [
    state('true', style({
        "opacity": "0.333",
        "pointer-events": "all"
    })),
    state('false', style({ 
        "opacity": "1"
    })),
    transition('*=>*', animate('300ms ease-out'))
]);
export const color_highlight = trigger('color_highlight', [
    state('true', style({
        "opacity": "0.333",
    })),
    state('false', style({ 
        "opacity": "1"
    })),
    transition('*=>*', animate('200ms ease-out'))
]);
export const opacity = trigger('opacity', [
    state('true', style({
        "opacity": "1",
    })),
    state('false', style({ 
        "opacity": "0",
    })),
    transition('*=>*', animate('400ms ease-out'))
]); 
export const main = trigger('main', [
    state('true', style({
        "opacity": "1",
        "pointer-events":"all"
    })),
    state('false', style({ 
        "opacity": "0",
        "pointer-events":"none"
    })),
    transition('*=>*', animate('400ms ease-out'))
]);
export const scale = trigger('scale', [
    state('true', style({
        "transform": "scale(1)",
        "max-height": "200px",
        "opacity": '1'
    })),
    state('false', style({ 
        "transform": "scale(0)",
        "max-height": "0px",
        "opacity": '0.1'
    })),
    transition('*=>*', animate('400ms ease-out'))
]);
export const hide = trigger('hide', [
    state('true', style({
        "max-height": "200px",
    })),
    state('false', style({ 
        "max-height": "0px",
    })),
    transition('*=>*', animate('400ms ease-out'))
]);
export const fade_in = trigger('fade_in', [
    state('true', style({
        "opacity": "1",
        "display": "block"
    })),
    state('false', style({ 
        "opacity": "0",
        "display": "none"
    })),
    transition('*=>*', animate('400ms ease-out'), { params: { delay: 400 } })
]);
export const display = trigger('display', [
    state('true', style({"display": "block"})),
    state('false', style({ "display": "none" }))
]);