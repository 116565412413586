import { SQLService } from '../../services/sql.service';
import { NewPayee } from '../../models/payee';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { SelectAddressPage } from '../../addresses/select-address/select-address.page';
import { SelectSalaryPage } from '../../salary/select-salary/select-salary.page';
import { SelectSchedulePage } from '../../schedule/select-schedule/select-schedule.page';
import { ActionSheetController, ModalController, Platform } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { Browser } from '@capacitor/browser';
@Component({
  selector: 'ng-cheques-new-payee',
  templateUrl: './new-payee.component.html',
  styleUrls: ['./new-payee.component.styl']
})
export class NewPayeeComponent implements OnInit {
  payee = new NewPayee();
  currencies = Object.keys(environment.currencies);
  _c = 0;
  constructor(
    private router: Router,
    private actionSheetCtrl: ActionSheetController,
    private route: ActivatedRoute,
    private callNumber: CallNumber,
    public sql: SQLService,
    private location: Location,
    public modalController: ModalController
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.open();
    }); 
  }
  async add_address() {
    this.set_temp();
    const m = await this.modalController.create({component: SelectAddressPage, componentProps: {}});
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data){
      if(a.data==-1){
        this.delete_address();
        this.sql.hapticsImpactLight();
      } else {
        this._c=1;
        this.payee.address_id = a.data;
        this.sql.view('Addresses','address_id',a.data,(a)=>{this.payee.addresses=a;});
        this.sql.hapticsImpactMedium();
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_address(address) {
    this.router.navigate(['folder','Address', address.address_id]); 
  }
  delete_address() {
    this.payee.addresses = false;
    this.payee.address_id = null;
    // this.sql.set( 'Employees', 'employee_id', this.payee.employee_id, 'addresses', null);
  }
  async add_salary() {
    this.set_temp();
    const m = await this.modalController.create({component: SelectSalaryPage});
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1){
        this.delete_salary();
        this.sql.hapticsImpactLight();
      } else {
        this.payee.salary_ids = a.data.salary_id;
        this.payee.salaries = [a.data];
        this._c=1;
        this.sql.hapticsImpactMedium();  
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_salary(salary) {
    this.router.navigate(['folder','Salary', salary.salary_id]); 
  }
  delete_salary() {
    this.payee.salaries = null;
    this.payee.salary_ids = null;
    // this.sql.set( 'Employees', 'employee_id', this.payee.employee_id, 'salaries', null);
  }
  get_salary() {
    this.sql.view('Salaries','salary_ids',this.payee.salary_ids,(data)=>{
      this.payee.salaries = data;
    });
  }
  delete_payee() {
    if(!this.payee.payee_id){
      this.end();
    } else if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('Payees','payee_id',this.payee.payee_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }

  // async add_address() {
  //   this.set_temp();
  //   const m = await this.modalController.create({component: SelectAddressPage, componentProps: {}});
  //   await m.present();
  //   let a = await m.onDidDismiss();
  //   if(a.data){
  //     if(a.data==-1){
  //       this.delete_address();
  //       this.sql.hapticsImpactLight();
  //     } else {
  //       this.payee.address_ids = a.data; 
  //       this.get_address();
  //       this._c=1;
  //       this.sql.hapticsImpactMedium();
  //     }
  //     this.sql.remove_temporary('page');
  //   } else {this.sql.remove_temporary('page');}
  // }
  // edit_address(a?) {this.router.navigate(["folder","Address",a.address_id]);}
  // delete_address(j?) {
  //   this.payee.addresses = null;
  //   this.payee.address_ids = null;
  //   this.sql.set('Payees','payee_id',this.payee.payee_id,'address_ids',-1);
  // }
  // get_address() {
  //   if(typeof this.payee.address_ids === 'string'){
  //     this.payee.address_ids=this.payee.address_ids.split(',');
  //   } else if (typeof this.payee.address_ids === 'number'){
  //     this.payee.address_ids=[this.payee.address_ids];
  //   }
  //   this.payee.addresses=[];
  //   for(let a of this.payee.address_ids){
  //     this.sql.view('Addresses','address_id',a,(data)=>{
  //       for(let d of data)this.payee.addresses.push(d);
  //     });
  //   }
  // }
  // async add_salary() {
  //   this.set_temp();
  //   const m = await this.modalController.create({component: SelectSalaryPage});
  //   await m.present();
  //   var a = await m.onDidDismiss();
  //   if(a.data) {
  //     if(a.data==-1){
  //       this.delete_salary();
  //       this.sql.hapticsImpactLight();
  //     } else {
  //       this.payee.salaries = [a.data];
  //       this.payee.salary_ids = a.data.salary_id;
  //       this._c=1;
  //       this.sql.hapticsImpactMedium();  
  //     }
  //     this.sql.remove_temporary('page');
  //   } else {this.sql.remove_temporary('page');}
  // }
  // edit_salary(salary) {
  //   this.router.navigate(['folder','Salary', salary.salary_id]); 
  // }
  // delete_salary() {
  //   this.payee.salaries = null;
  //   this.payee.salary_ids = null; 
  //   this.sql.set('Payees','payee_id',this.payee.payee_id,'salary_ids',-1);
  // }
  // get_salary() {
  //   if(typeof this.payee.salary_ids === 'string'){
  //     this.payee.salary_ids=this.payee.salary_ids.split(',');
  //   } else if (typeof this.payee.salary_ids === 'number'){
  //     this.payee.salary_ids=[this.payee.salary_ids];
  //   }
  //   this.payee.salaries=[];
  //   for(let a of this.payee.salary_ids){
  //     this.sql.view('Salaries','salary_id',a,(data)=>{
  //       for(let d of data)this.payee.salaries.push(d);
  //     });
  //   }
  // }

  async add_schedule() {
    this.set_temp();
    const m = await this.modalController.create({component: SelectSchedulePage});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1) {
        this.delete_schedule();
        this.sql.hapticsImpactLight();
      } else {
        this._c=1;
        this.payee.schedules = a.data;
        this.sql.hapticsImpactMedium();
      }
      this.sql.remove_temporary('page');
    } else {this.sql.remove_temporary('page');}
  }
  edit_schedule(schedule) {
    this.router.navigate(['folder','Schedule', schedule.salary_id]); 
  }
  delete_schedule() {
    this.payee.schedules = null;
    this.sql.set('Payees','payee_id',this.payee.payee_id,'schedules',-1);
  }
  end(id?) {
    this.sql.get_temporary('page',(page)=>{
      if (this._c>0) {
        if (confirm(this.sql.word.Discard_Confirmation)) {
          this.payee = new NewPayee();
          this.router.navigate(['folder','Payees']);
        }
      } else {
        if(page) {
          this.router.navigate(['folder',page.location,page.id,'Payee',id]);
          setTimeout( () => { this.sql.remove_temporary('page'); }, 600);
        } else {
          this.router.navigate(['folder','Payees']);
        }
      }
    });
  }
  set_temp() {
    if (this.payee.payee_id) {
      this.sql.set_temporary('page',{location:'Payee',id:this.payee.payee_id});
    } else {
      this.save(false,(id) =>{
        if(!id)return;
        this.payee.payee_id=id;
        this.sql.set_temporary('page',{location:'Payee',id:id});
      });
    }
  }
  get_payee() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida) return;
    this.sql.view('Payees','payee_id',ida,(e)=>{
      if(!e) return;
      var f=Object.entries(e[0]); 
      let n = new NewPayee();
      for(let t of f)n[t[0]]=t[1];
      var a=this.route.snapshot.paramMap.get('addressid');if(a)n.address_id = a;
      var a=this.route.snapshot.paramMap.get('salaryid');if(a)n.salary_ids = a;
      if(n.address_id&&n.address_id>0)
        this.sql.view('Addresses','address_id',n.address_id,(a)=>{n.addresses=a;});
      if(n.salaries&&n.salary_ids>0)
        this.sql.view('Salaries','salary_id',n.salary_ids,(a)=>{
          n.salaries=a;
        });
      this.payee=n;
    });
  }
  save(end=true,cb?) {
    this.sql.add("Payees",this.payee,(insertId)=>{
      if(!insertId)return
      this._c=0;
      if(!cb && end) {
        this.payee = new NewPayee();
        this.end(insertId);
      } else {
        cb(insertId);
      }
    });
  }
  open() {
    this.payee.enabled = true;
    this.get_payee();
  }
  ngOnInit() {
    // this.open();
  }
  start_schedule() {
    this.save(false,(insertId)=>{
      this.router.navigate(['folder','Schedule','New','Payee',insertId]);
    });
  }
  start_timer() {
    this.save(false,(insertId)=>{
      this.router.navigate(['folder','Timesheet','New','Payee',insertId]);
    });
  }
  new_payment() {
    this.save(false,(insertId)=>{
      this.router.navigate(['folder','Payment','New','Payee',insertId]);
    });
  }
  new_cheque() {
    this.save(false,(insertId)=>{
      this.router.navigate(['folder','Cheque','New','Payee',insertId]);
    });
  }
  async show_contact_info(item) {
    var buttons=[];
    if(!item.fax&&!item.email&&!item.phone&&!item.skype)return;
    if(item.fax)buttons.push({text: this.sql.word.Fax+' ('+item.fax+')',role: 'fax'});
    if(item.email)buttons.push({text: this.sql.word.Email+' ('+item.email+')',role: 'email'});
    if(item.phone)buttons.push({text: this.sql.word.Call+' ('+item.phone+')',role: 'phone'});
    if(item.skype)buttons.push({text: 'Skype ('+item.skype+')',role: 'skype'});
    buttons.push({text: this.sql.word.Cancel,role: 'cancel'});
    var items = {
      header: this.sql.word.Call,
      subHeader: this.payee.title?this.payee.title:this.payee.name?this.payee.name:this.sql.word.New_Call,
      buttons: buttons
    }
    const actionSheet = await this.actionSheetCtrl.create(items);
    await actionSheet.present();
    var result = await actionSheet.onDidDismiss();
    if(result.role=='phone')this.phonecall(item);
    if(result.role=='skype')this.skypecall(item);
    if(result.role=='email')this.email(item);
    if(result.role=='fax')this.fax(item);
  }
  email(item) { this.sql.email(item.email,this.sql.word.Cheques,this.sql.Trial?this.sql.word.CHEQUE_PRINT_CREDIT:''); }
  phonecall(item) { if(item.phone)this.callNumber.callNumber(item.phone, true); }
  async skypecall(item) { if(item.skype)await Browser.open({ url:'skype:'+item.skype+'?call' }); }
  async fax(item) { if(item.fax)await Browser.open({ url:'fax:'+item.fax }); }
}