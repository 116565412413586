import { Component, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { SQLService } from '../../../services/sql.service';
import { backgrounds, backgrounds_lowrez } from '../../../../environments/logos';
@Component({
  selector: 'app-cheque-yellow',
  templateUrl: './cheque-yellow.component.html',
  styleUrls: ['./cheque-yellow.component.scss'],
})
export class ChequeYellowComponent implements AfterViewInit {
  constructor(private sql: SQLService) { }
  @Output('loaded') loaded = new EventEmitter<boolean>();
  @Input('RenderMICR') RenderMICR = false;
  @Input('ImageMICR') ImageMICR = true;
  @Input('show') show = 'table';
  @Input('cheque') cheque: any;
  @Input('design') design: any;
  @Input('designer') designer: any;
  @Input('rotate') rotate: boolean;
  @Input('borders') borders: boolean;
  @Input('voidcheck') voidcheck: boolean;
  @Input('font') font: any;
  @Input('fiat') fiat: boolean;
  @Input('color') color: any;
  @Input('micr') micr: any;
  micr_top=false;
  micr_right=false;
  micr_bottom='4mm';
  micr_left='7mm';
  @Input('secret') secret = '';
  @Input('scale') scale =1;
  @Input('fold_line') fold_line: boolean;
  @Input('template') template: string;
  @Input('monochrome') monochrome: boolean;
  @Input('gradients') gradients: boolean;
  @Input('margin_bottom') bottom = '0mm';
  @Input('margin_top') top = '0mm';
  @Input('fontsize') fontsize: boolean;
  @Input('layers') layers: any;
  @Input('translations') translations = this.sql.word;
  @Input('bold') bold: boolean;
  @Input('italic') italic: boolean;
  @Input('show_cheque_number') show_cheque_number: boolean = true;
  @Input('show_cheque_date') show_cheque_date: boolean = true;
  @Input('show_micr') show_micr: boolean = true;
  @Input('cheque_background_image_repeat') cheque_background_image_repeat = false;
  @Input('background_position') background_position: string;
  @Input('cheque_text_color') cheque_text_color: string;
  @Input('cheque_background_image') cheque_background_image: any;
  @Input('cheque_logo_image') cheque_logo_image: any;
  @Input('cheque_header_image') cheque_header_image: any;
  @Input('cheque_bank_image') cheque_bank_image: any;
  @Input('doc_color') doc_color: string = 'yellow';
  bg_image: string; 
  b = true;
  loaded_component() {this.loaded.emit(true);}
  ngAfterViewInit() {
    this.design["height"]='69mm';
    this.design["date"]["top"]='15.8mm';
    this.design["date"]["right"]='17.8mm';
    this.design["date_label"]["display"]=false;
    this.design["date_label"]["alternative"]=true;
    this.design["payline"]["prefix"]=false;
    this.design["payline"]["borders"]=false;
    this.design["payline"]["top"]='22.8mm';
    this.design["payline"]["left"]='132px';
    this.design["payline"]["right"]='27px';
    this.design["payline"]["bottom"]='4px';
    this.design["printed_id"]["top"]='27.3px';
    this.design["printed_id"]["right"]='45px';
    this.design["payment_name"]["left"]="34px";
    this.design["payment_name"]["bottom"]="4px";
    this.design["bank_account_holder"]["top"]="20mm";
    this.design["bank_account_holder"]["left"]="7mm";
    this.design["written_amount"]["top"]='33mm';
    this.design["written_amount"]["right"]=false;
    this.design["written_amount"]["bottom"]='30mm';
    this.design["written_amount"]["left"]='28.5mm';
    this.design["written_amount"]["index"]=false;
    this.design["written_amount_line"]["borders"]=false;
    this.design["written_amount_line"]["overflow"]=true;
    this.design["written_amount_line"]["top"]='0px';
    this.design["written_amount_line"]["right"]='0px';
    this.design["written_amount_line"]["bottom"]='0px';
    this.design["written_amount_line"]["left"]='0px';
    this.design["written_amount_line"]["width"]='100%';
    this.design["multiline_written_amount_chars"]=60;
    this.design["written_amount_line2"]["top"]='8.1mm';
    this.design["written_amount_line2"]["right"]='0mm';
    this.design["written_amount_line2"]["bottom"]='0mm';
    this.design["written_amount_line2"]["left"]='-21mm';
    this.design["bank_image"]["top"]="7.4mm";
    this.design["bank_image"]["left"]="34.7mm";
    this.design["bank_name"]["top"]="7.1mm";
    this.design["bank_name"]["left"]="46mm";
    this.design["bank_address"]["top"]="10.2mm";
    this.design["bank_address"]["left"]="46mm";
    this.design["bank_address_2"]["top"]="12.8mm";
    this.design["bank_address_2"]["left"]="46mm";
    this.design["bank_address_3"]["top"]="15.6mm";
    this.design["bank_address_3"]["left"]="46mm";
    this.design["bank"]["width"]="390px";
    this.design["amounts"]["symbol"]=false;
    this.design["amount_number"]["borders"]=false;
    this.design["amount_number"]["background"]=false;
    this.design["amount_number"]["top"]="3.3mm";
    this.design["amount_number"]["right"]="auto";
    this.design["amount_number"]["left"]="-5mm";
    this.design["memo"]["label"]=false;
    this.design["memo"]["border"]=false;
    this.design["memo"]["left"]='10mm';
    this.design["memo"]["bottom"]='15.3mm';
    this.design["public_image"]["top"]='33mm';
    this.design["public_image"]["right"]='0mm';
    this.design["public_image"]["width"]='77';
    this.design["public_image"]["zindex"]='1';
    this.design['public_image']['QRcolorDark']=this.sql.paypal_payouts?"#4d2e1c":"#000000";
    this.design['public_image']['QRcolorLight']="#d9d3b9";
    this.design['public_image']['QRCodeMargin']="4";
    this.design["key_image"]["width"]='77';
    this.design["key_image"]["left"]='7mm';
    this.design["key_image"]["top"]='4mm';
    this.design["key_image"]["zindex"]='1';
    this.design['key_image']['QRcolorDark']=this.sql.paypal_payouts?"#4d2e1c":"#000000";
    this.design['key_image']['QRcolorLight']="#d3d3bb";
    this.design['key_image']['QRCodeMargin']="2";
    this.design["currency_name"]["top"]='21.8mm';
    this.design["currency_name"]["left"]='113mm';
    this.design["address_data"]["top"]='16.7mm';
    this.design["address_data"]["left"]='29mm';
    this.design["address_data"]["width"]='80mm';
    this.design["address_data"]["class"]='yello_v1';
    this.design["signature"]["container_width"]='40.5mm';
    this.design["signature"]["width"]='180px';
    this.design["signature"]["border"]=false;
    this.design["signature"]["label"]=false;
    this.design["signature"]["right"]='auto';
    this.design["signature"]["bottom"]='16.6mm';
    this.design["signature"]["left"]='109.6mm';
    this.design["signature"]["image"]["bottom"]="-1mm";
    this.design["signature"]["image"]["left"]="8mm";
    this.design["signature"]["image"]["width"]="31mm";
    this.design["signature"]["info"]["left"]="3.7mm";
    this.design["signature"]["info"]["right"]="auto";
    this.design["signature"]["desc"]["right"]="0mm";
    this.design["signature"]["desc"]["left"]="auto";
    this.design["micr"]["top"]='auto';
    this.design["micr"]["right"]='auto';
    this.design["micr"]["bottom"]='4mm';
    this.design["micr"]["left"]='7mm';
    this.design["micr"]["background"]=true;
    if(this.sql.model=='web') {
      var img = "yellow";
      this.sql.load_background(img,(base64Img)=>{
        this.bg_image=base64Img;
      },this.sql.production);
    } else {
      this.bg_image = this.sql.production?backgrounds.yellow:backgrounds_lowrez.yellow;
    }
}
}