import { environment } from '../../../../environments/environment';
import { SQLService } from '../../../services/sql.service';
import { NewBank } from '../../../models/bank';
import { SignatureComponent } from '../../signature-pad/signature-pad.component';
import { ImagePreviewComponent } from '../../../cheque/designs/image-preview/image-preview.component';
import { ViewSignatureComponent } from '../../view-signature/view-signature.component';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
// import * as Tesseract from 'tesseract.js/dist/tesseract.min.js'
// import { createWorker } from 'tesseract.js/dist/tesseract.min.js';
@Component({
  selector: 'ng-cheques-new-bank-account',
  templateUrl: './new-bank-account.component.html',
  styleUrls: ['./new-bank-account.component.styl']
})
export class NewBankAccountComponent implements OnInit {
  constructor(
    public sql: SQLService,
    private location: Location,
    private route: ActivatedRoute,
    private sanitizer:DomSanitizer,
    private router: Router,
    public modalController: ModalController
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.open();
    });
  }
  // worker = createWorker({
  //   workerPath: '/assets/js/tesseract.js/dist/worker.min.js',
  //   langPath: '/assets/js/tesseract',
  //   cachePath: '/assets/js/tesseract',
  //   corePath: '/assets/js/tesseract.js-core/tesseract-core.wasm.js',
  //   logger: m => console.log(m)
  // })
  _c=0;
  ocr_running=false;
  hide_numbers=false;
  id: any;
  // OCRAD: any;
  ocrImage= null;
  max_size = 20971520;
  max_height = 15200;
  max_width = 25600;
  reader = new FileReader();
  allowed_types = ['image/png', 'image/jpeg'];
  bank_logos = this.sql.Banks;
  banks=[];
  bank = new NewBank(true);
  @Input('color') color_ = 'primary';
  @Input('live_save') live_save = false;
  @Input('ShowHeader') ShowHeader = true;
  @ViewChild('ocr_preview') ocrpreview: ElementRef;
  @ViewChild('Uploader') Uploader: ElementRef;
  currencies = Object.keys(environment.currencies);
  save() {
    this.resv_enc(this.bank,(encoded_bank)=>{
      if(this.bank.number_protection) {
        this.hide_numbers=true;
        this.sql.set_reservations(encoded_bank.checksum,(result)=>{
          console.log('check_reservations', result);
          if(result=='error') {
            this.hide_numbers=false;
            alert(this.sql.word.Bank_Code_Protection_Error_Word);
          } else if(result=='block') {
            alert(this.sql.word.Bank_Code_Protection_Blocked_Word);
          } else if(result=='OK') {
            // this.save_bank(encoded_bank);
          }
        });
      } else {
        this.save_bank(encoded_bank);
      }
    });
  }
  delete_bank() {
    if(!this.bank.bank_id){
      this.end();
    } else if(confirm(this.sql.word.Discard_Bank_Confirmation)) {
      this.sql.delete('BankAccounts','bank_id',this.bank.bank_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  toggle_bank_number_protection(e?) {
    if(!this.bank.number_protection&&confirm(this.sql.word.Bank_Code_Protection_Word)){
      this.bank.number_protection=true;
      this.lv();
    } else {
      this.bank.number_protection=false;
      this.lv();
    }
  }
  save_bank(bank_data){
    this.hide_numbers=true;
    this.sql.add("BankAccounts",bank_data,(r)=>{
      if(!r)return;
      if(this.live_save) {
        this.bank.bank_id=r;
      } else {
        this._c=0;
        this.bank=new NewBank(true);
        if(r)this.end(r);
      }
    });
  }   
  check_numbers() {
    console.log('transit number', this.bank.transit_number);
    console.log('institution number', this.bank.institution_number);
    console.log('designation number', this.bank.designation_number);
    console.log('account number', this.bank.account_number);
    if( this.bank.transit_number&&this.bank.institution_number&&this.bank.designation_number&&this.bank.account_number ) { 
      this.resv_enc((sum)=>{
        console.log('check_numbers', sum);
        this.sql.check_reservations(sum,(result)=>{
          if(result=='error') {
            alert(this.sql.word.Bank_Code_Protection_Set_Error_Word);
          } else {
            console.log('check_reservations', result);  
          }
        });
      });  
    }
  }
  resv_enc(b,cb?) {
    var encoded_bank = b;
    if(b.transit_number)encoded_bank.transit_number=this.sql.encode(b.transit_number);
    if(b.institution_number)encoded_bank.institution_number=this.sql.encode(b.institution_number);
    if(b.designation_number)encoded_bank.designation_number=this.sql.encode(b.designation_number);
    if(b.account_number)encoded_bank.account_number=this.sql.encode(b.account_number);
    encoded_bank.checksum='';
    encoded_bank.checksum=+encoded_bank.transit_number;
    encoded_bank.checksum=+encoded_bank.institution_number;
    encoded_bank.checksum=+encoded_bank.designation_number;
    encoded_bank.checksum=+encoded_bank.account_number;
    if(cb)cb(encoded_bank);
  }
  lv(){if(this.live_save)this.save();}
  transit(event){
    this.bank.transit_number=event.target.value;
    this.check_numbers();
  }
  institution_number(event){
    this.bank.institution_number=event.target.value;
    this.check_numbers();
  }
  designation_number(event){
    this.bank.designation_number=event.target.value;
    this.check_numbers();
  }
  account_number(event){
    this.bank.account_number=event.target.value;
    this.check_numbers();
  }
  end(id?) {
    this.sql.get_temporary('page',(page)=>{
      if (this._c>0) {
        if (confirm(this.sql.word.Delete_Confirmation)) {
          this.bank = new NewBank(true);
          this.router.navigate(['folder','Accounts']);
        }
      } else {
        if(page&&page.location&&page.id) {
          this.router.navigate(['folder',page.location,page.id]);
          setTimeout( () => { this.sql.remove_temporary('page'); }, 600);
        } else {
          this.router.navigate(['folder','Accounts']);
        }
      }
    });
  }
  set_logo(ev) {
    this._c=1;
    if(ev=='upload_logo') {
      this.Uploader.nativeElement.click();
    } else {
      if(this.sql.model=='web') {
        this.sql.load_bank_img(ev,(d)=>{
          this.bank.image = d?d:null;
        });
      } else {
        this.bank.image = ev;
      }
    }
  }
  imageuploader(val) {
    this.bank.image=window.URL.createObjectURL(val);
  } 
  fileChangeEvent(fileInput: any) {
    this.sql.createDataFromImage(fileInput.target.files[0]);
  }
  async add_signature() {
    const m = await this.modalController.create({component: SignatureComponent, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data=='delete')  {
      this.bank.signature = null;
      this.sql.hapticsImpactLight();
      this.lv()
    } else if (a.data&&a.data.signature_id) {
      this.sql.hapticsImpactMedium();
      this._c=1;
      this.bank.signature = a.data.signature_id;
      this.lv();
    }
    this.sql.remove_temporary('page');
  }
  delete_signature() {
    this.bank.signature = null;
  }
  get_bank() {
    if(!this.id)return;
    this.sql.view('BankAccounts','bank_id',this.id,(e)=>{
      var f = Object.entries(e[0]);
      let n = new NewBank(true);
      for(let t of f)n[t[0]]=t[1];
      if(n.transit_number)n.transit_number=this.sql.unencode(n.transit_number);
      if(n.institution_number)n.institution_number=this.sql.unencode(n.institution_number);
      if(n.designation_number)n.designation_number=this.sql.unencode(n.designation_number);
      if(n.account_number)n.account_number=this.sql.unencode(n.account_number);
      this.hide_numbers=false;
      this.bank = n;
      this.get_bank_images();
      console.log('bank', this.bank);
      this._c=0;
    });
  }
  bank_image:any;
  async preview() {
    const m = await this.modalController.create({
      component: ImagePreviewComponent,
      componentProps: {
        trash:true,
        src:this.bank_image,
        title:this.sql.word.bank_Logo
      }
    });
    await m.present();
    let a = await m.onDidDismiss();console.log(a);
    console.log('bank.image',a)
    if(a.data==1){
      this.bank.image='';
      this.bank_image=false;
    }
  }
  image_lib() {
    this.sql.select_image((file)=>{
      if (file.length==0) {
        this.bank.image='';
        this.bank_image=false;
      } else {
        this.bank.image='data:image/png;base64, '+file;
        this.bank_image=this.sanitizer.bypassSecurityTrustResourceUrl(this.bank.image);
      }
    },500,500,87);
  }
  ocr_cheque() {
    this.camera_ocr((cb) => {
      console.log('camera_ocr',cb);
    });
  }
  async camera_ocr(img, cb?) {
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
      correctOrientation: true,
      height: 1080,
      width: 1920,
      quality: 90
    });
    this.ocrImage=capturedPhoto.dataUrl;
    setTimeout(()=>{
      this.ocr_running = true;
      this.recognizeImage();
      // (<any>window).OCRAD(document.getElementById('ocrpreview'), {filters:['letters_only']}, (text)=>{
      //   console.log('OCR',text);
      //   this.ocr_running = false;
      // })
    },500);
  }
   async recognizeImage() {
      // await this.worker.load();
      // this.ocr_running = true;
      // await this.worker.loadLanguage('eng');
      // await this.worker.initialize('eng');
      // const { data: { text } } = await this.worker.recognize(this.ocrImage);
      // var imageText = JSON.stringify(text);
      // console.log('OCR',imageText);
      // this.ocr_running = false;
      // await this.worker.terminate();

        // Tesseract.recognize(this.selectedImage)
        // .progress(message => {
        //   if (message.status === 'recognizing text')
        //   this.ngProgress.set(message.progress);
        //   console.log('progressing....')
        // })
        // .catch(err => console.error(err))
        // .then(result => {
        //   this.imageText = result.text;
        // })
        // .finally(resultOrError => {
        //   this.ngProgress.done();
        //   console.log('finished.');
        // });
  }
  async preview_signature(sig) {
    const m=await this.modalController.create({component:ViewSignatureComponent,componentProps:{id:sig}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data=='new') {
      this.add_signature();
    } else if(a.data=='delete')  {
      this.bank.signature = null;
    }
  }
  open() {
    this.sql.count('BankAccounts',(c)=>{
      this.bank.default_account=c==0?true:false;
      this.bank.enabled = true;
      this.bank.currency = this.sql.Currency;
      this.get_bank();
    });
  }
  get_bank_images() {
    this.bank_image=this.sanitizer.bypassSecurityTrustResourceUrl(this.bank.image);
    this.sql.fetch('BankAccounts',(e)=>{
      this.banks=e;
    });
  }
  async ngOnInit() {
    this.hide_numbers=false;
    this.id = this.route.snapshot.paramMap.get("id");
    this.open();
  }
}