import { environment } from '../../../environments/environment';
import { SQLService } from '../../services/sql.service';
import { Flip,fade } from '../../app.animations';
import { Component, OnDestroy, OnInit, Input, EventEmitter,ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { TermsOfServiceComponent } from '../../settings/licences/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from '../../settings/licences/privacy-policy/privacy-policy.component';
import { EULAComponent } from '../../settings/licences/eula/eula.component';
import { TermsOfUseComponent } from '../../settings/licences/terms-of-use/terms-of-use.component';
import { DisclaimerComponent } from '../../settings/licences/disclaimer/disclaimer.component';
import { RefundsComponent } from '../../settings/licences/refunds/refunds.component';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { NewLogin } from '../../models/login'
declare var Stripe;
@Component({
	selector: 'app-sign-in',
	templateUrl: './sign-in.component.html',
	styleUrls: ['./sign-in.component.styl'],
	animations: [ Flip,fade ]
})
export class SignInComponent implements OnInit, OnDestroy {
	constructor(
		private router: Router,
		public sql: SQLService,
		private route: ActivatedRoute,
		private navCtrl: ModalController,
		private current_platform: Platform,
		private location: Location
	) {
		// this.router.events.subscribe((eve) => {
			//if(eve instanceof NavigationEnd) this.get_purchase();
		// });
	}
	login = new NewLogin();
	platform: string;
	ngOnInit() {
		
	}
	ngOnDestroy() {

	}
	sign_up() {
		this.navCtrl.dismiss('signup');
		// this.show_signup();
	}
	sign_in() {
		if(this.login.email && this.login.password) {
			this.sql.login(this.login.email, this.login.password, (res) => {
				if (this.sql.verbose) console.log('Login Complete ',res);
				if (res.result.code >= 500) {
					alert(this.sql.word.PrinterError+' '+res.result.error+' ('+res.result.code+')');
					this.sql.logged_in = false;
					console.log('Login Error ', res);
				} else if(res.result) {
					// this.sql.set_temporary('authorization', res.result, 13000);
					this.sql.cookie_service('authorization', res.result, 13000);
					this.sql.logged_in = true;
					this.sql.renderMenu();
					this.navCtrl.dismiss(true);
				}
			});
		} else {
			alert(this.sql.word.PrinterError);
		}
	}
	off() {this.navCtrl.dismiss(false);}
	close() {this.off();}
	async show_signup() {
		this.sql.showingLogin = true;
		const m = await this.navCtrl.create({component: SignUpComponent, componentProps: {force:true}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {
		  this.sql.hapticsImpactLight();
		}
	}
	async tos() {
		const m = await this.navCtrl.create({component: TermsOfServiceComponent, componentProps: {force:true}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {
		  this.sql.hapticsImpactLight();
		  console.log('Check Key', a.data);
		}
	}
	async pp() {
		const m = await this.navCtrl.create({component: PrivacyPolicyComponent, componentProps: {force:true}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {
		  this.sql.hapticsImpactLight();
		  console.log('Check Key', a.data);
		}
	}
	async eula() {
		const m = await this.navCtrl.create({component: EULAComponent, componentProps: {force:true}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {
		  this.sql.hapticsImpactLight();
		  console.log('Check Key', a.data);
		}
	}
	async tou() {
		const m = await this.navCtrl.create({component: TermsOfUseComponent, componentProps: {force:true}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {
		  this.sql.hapticsImpactLight();
		}
	}
	async dis() {
		const m = await this.navCtrl.create({component: DisclaimerComponent, componentProps: {force:true}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {
		  this.sql.hapticsImpactLight();
		}
	}
	async ref() {
		const m = await this.navCtrl.create({component: RefundsComponent, componentProps: {force:true}});
		await m.present();
		let a = await m.onDidDismiss();
		if(a.data) {
		  this.sql.hapticsImpactLight();
		}
	}
}