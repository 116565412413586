import { SQLService } from './sql.service';
import { TimesheetService } from './timesheet.service';
import { NewCheque } from '../models/cheque'; 
import { NewPayment } from '../models/payment';
import { NewPayroll } from '../models/payroll';
import { environment } from '../../environments/environment';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { HttpClient, HttpParams, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { Globalization } from '@ionic-native/globalization/ngx';
import * as converter from 'number-to-words';
import { Injectable } from '@angular/core';
import { Currencies } from '../../environments/currencies';
@Injectable()
export class PayrollService {
	constructor(
		private http: HttpClient,
		private ts: TimesheetService,
		public sql: SQLService 
	) {}
	public verbose = environment.verbose;
	expanded = false;
	expanding = true;
	public inter: any;
	more_verbose = true;
	Currencies=Currencies;
	payroll = new NewPayroll();
	SECONDS_IN_A_MINUTE= 60;
	SECONDS_IN_AN_HOUR = 3600;
	SECONDS_IN_A_DAY   = 86400;
	SECONDS_IN_A_WEEK  = 604800;
	SECONDS_IN_2_WEEKS = 1209600;
	SECONDS_IN_A_MONTH = 2764800;
	SECONDS_IN_A_YEAR  = 31536000;
	run_payroll(payment_id,save?,cb?){
		this.run(payment_id,save,(Payments)=>{
			if(this.more_verbose)console.log('Payments',Payments);
			if(save && this.payroll.payment_method=='cheque'){
				this.generate_cheques(Payments,(Cheques)=>{
					if(this.more_verbose)console.log('Generated New Cheques:',Cheques);
					if(cb)cb(Cheques);
					return Cheques;
				});
			} else if(cb){
				cb(Payments);
				return Payments;
			}
		});
	}
	run(payroll_id?,save?,cb?) {
		var now = Date.now(); var b = '', c = '';
		if(false)c=" AND date < "+now;
		var query = "Payrolls WHERE payroll_id="+payroll_id;
		this.sql.get(query,(payroll)=>{
			payroll=payroll[0];payroll.employees=[];payroll.payees=[];payroll.banks=[];payroll.timesheets=[];
			if( (payroll.payee_ids&&payroll.payee_ids.split(',').length>0)||
				(payroll.employee_ids&&payroll.employee_ids.split(',').length>0) ){
				this.expand(payroll,(p)=>{
					this.execute_payroll(p,save,cb);
				});
			} else {
				if(this.more_verbose)console.log('No Payroll Entries');
				if(cb)cb([]);
			}
		});
	}
	execute_payroll(p,save,cb) {
		this.payroll = p; var Payments = [];
		this.inter = setTimeout(()=>{
			if(this.expanding){
				this.execute_payroll(p,save,cb);
			} else {
				if(this.sql.verbose)console.log('Payroll',this.payroll);
				this.payloop(this.payroll,(Payroll, Payees)=>{
					var i=0,a=0;this.payroll=Payroll;
					for (var payee of Payees) {
						if(payee.amount>0){
							this.create_payment(payee,Payroll,save,(Payment,PaymentID)=>{
								this.create_annum_entries(payee,Payroll,(payee_entries)=>{ 
									if(payee_entries!=[])for(var e of payee_entries)payee.entries.push(e);
									this.save_entries(payee.entries,PaymentID,save,(PaymentEntries)=>{ i++;
										if(Payment){
											Payment.payment_id=PaymentID;
											Payments.push(Payment);
										}
										if(Payees.length==i){
											cb(Payments);
											return Payments;
										}
									});
								
								});

							});
						} else {
							i++;
							if(Payees.length==i){
								cb(Payments);
								return Payments;
							}
						}
					}
				});
			}
		},220);
	}
	create_annum_entries(subject,payroll,cb?) {
		if(subject.amount>0){
			var e = []; var E=0;
			var this_year = 2023;//new Date().year;
			if(subject.employee_id){E=1;}else if(subject.payee_id){E=0;}
			this.sql.get((E?"Employees":"Payees")+"WHERE "+(E?"employee_id":"payee_id")+" = "+subject[E?'employee_id':'payee_id'],(e)=>{
				if(e.yearly_totals){
					var YearlyTotal = JSON.parse(e.yearly_totals);
					var TotalThisAnnum = Number(Number(YearlyTotal[this_year])+subject.amount);
					if(payroll.currency!=e.currency)
						this.sql.exchange_currency(TotalThisAnnum,payroll.currency,e.currency,(a)=>{TotalThisAnnum=a;});
				} else {
					if(payroll.currency!=e.currency)
						this.sql.exchange_currency(TotalThisAnnum,payroll.currency,e.currency,(a)=>{TotalThisAnnum=a;});
					e.yearly_totals[this_year]=Number(subject.amount);
				}
			});
		}
	}
	save_entries(PaymentEntries, PaymentID, save, cb?) {
		if(PaymentID&&PaymentEntries){``
			var NewPaymentEntries  = [new PaymentEntries()];
			for(let e of PaymentEntries) {
				e.payment_id=PaymentID;
				NewPaymentEntries.push(e);
			}
			var j=0;
			for(let n of NewPaymentEntries) {
				this.sql.add("ChequeEntry",n,(id)=>{j++;
					NewPaymentEntries[j].cheque_entry_id=id;
					if(cb&&j==NewPaymentEntries.length)cb(NewPaymentEntries);
				});
			}
		} else {
			if(cb)cb(false);
		}
	}
	update_cheque_entries(PaymentID, ChequeID, cb) {
		this.sql.update("ChequeEntry WHERE payment_id = "+PaymentID,{'cheque_id':ChequeID},(Updated)=>{
			cb(Updated?true:false);
		});
	}
	payloop(Payroll,cb?){
		this.fetch_schedules(Payroll,(Tabulation)=>{
			if(Payroll.start_date){
				var PAYROLL_START=new Date(Payroll.start_date); 
			} else {
				var PAYROLL_START=new Date(); 
			}
			if(Payroll.end_date){
				var PAYROLL_STOP=new Date(Payroll.end_date); 
			} else {
				var PAYROLL_STOP=new Date();
			}
			var i = 0, total = 0, Payments = [];
			if(Payroll.start_time){
				PAYROLL_START.setHours(Payroll.start_time.split(':')[0],Payroll.start_time.split(':')[1],0,0);
			}else{PAYROLL_START.setHours(0,0,0,0);}
			if(Payroll.end_time){
				PAYROLL_STOP.setHours(Payroll.end_time.split(':')[0],Payroll.end_time.split(':')[1],0,0);
			}else{PAYROLL_STOP.setHours(0,0,0,0);}
			var payees = [], ii = 0; 
			for(var ep of ['employees','payees']){
				if(Payroll[ep].length>0)for (var i = 0; i < Payroll[ep].length; ++i) {
					var payee=Payroll[ep][i][0];
					this.cached=[]
					var TimeOff = {always_enabled:false,secs:0,mins:0,hrs:0,days:0,weeks:0,two_weeks:0,work_days:0,weekends:0,months:0,years:0};
					var objects = ['vacations','leaves','timesheets','schedules','taxes','deductions'];
					payee.amount=0;
					for(var ve of objects) {
						payee[ve]=[];
						if(Tabulation[ve]&&Tabulation[ve].length>0)for(var c = 0; c < Tabulation[ve].length; ++c){
							var entry = Tabulation[ve][c][0];
							if(this.verbose)console.log(ep,ve,Tabulation,entry);
							if(	(ve=='taxes') || (ve=='deductions') || (ve=='vacations') || (ve=='leaves') || (ve=='timesheets') ||
								(ep=='payees' && entry[ep] && entry[ep].filter(e => e.payee_id === payee.payee_id).length > 0) || 
								(ep=='employees' && entry[ep] && entry[ep].filter(e => e.employee_id === payee.employee_id).length > 0) ) {
								var index = payees.indexOf(payee);
								if (index<0){
									payees.push(payee);
									index = payees.indexOf(payee);
								}
								// leaves(tabulation, payee, cb)
								var NOW = new Date();
								var ENTRY_START = new Date();
								var ENTRY_STOP = new Date();
								if(Payroll.indefinite_payroll_enabled || entry.always_enabled){
									ENTRY_START=PAYROLL_START;
									ENTRY_STOP=PAYROLL_STOP;
								} else {
									if(entry.start_date) ENTRY_START=new Date(entry.start_date);
									if(entry.end_date) ENTRY_STOP=new Date(entry.end_date);
									if(PAYROLL_START) if (ENTRY_START<PAYROLL_START)ENTRY_START=PAYROLL_START;
									if(PAYROLL_STOP)  if (ENTRY_STOP>PAYROLL_STOP)ENTRY_STOP=PAYROLL_STOP;
									if(ENTRY_STOP>NOW) ENTRY_STOP=NOW;
								}
								var STOP=Number(ENTRY_STOP), START=Number(ENTRY_START);
								var START_DATE = new Date(START);
								var END_DATE = new Date(STOP);
								var START_TIME = new Date(entry.start_time);
								// if(this.verbose)console.log('entry.start_time',entry.start_time)
								var END_TIME = new Date(entry.end_time);
								// if(this.verbose)console.log('entry.end_time',entry.end_time)
								var s = 0, // Number(START_TIME.getSeconds()),
									m = Number(START_TIME.getMinutes()*this.SECONDS_IN_A_MINUTE),
									h = Number(START_TIME.getHours()*this.SECONDS_IN_AN_HOUR),
									start_seconds = Number(s+m+h);
								var s = 0, // Number(END_TIME.getSeconds()),
									m = Number(END_TIME.getMinutes()*this.SECONDS_IN_A_MINUTE),
									h = Number(END_TIME.getHours()*this.SECONDS_IN_AN_HOUR),
									end_seconds = Number(s+m+h);
									// if(this.verbose)console.log('start and end secods', end_seconds, start_seconds);
								var ADD=false,SUBTRACT=false,LABEL='';
								switch(ve) {
									case 'schedules':
										if(Payroll.time_method=='schedules'||Payroll.time_method=='both') {
											var salary=payees[index].salary[0];
											if(salary){
												var SALARY = this.frequency(salary.frequency, salary.amount);
												var TIME = 0;
												if(end_seconds>0&&start_seconds>0&&end_seconds>start_seconds) {
													TIME = Number(end_seconds-start_seconds);	
													if(this.verbose)console.log('Setting schedules time', TIME);
												} else {
													TIME = 0;
												}
												ADD=true; 
												SUBTRACT=false;
												LABEL='Schedule';
											}
										}
									break;
									case 'timesheets':
										if(Payroll.time_method=='timesheets'||Payroll.time_method=='both') {
											console.log('payees[index]',payees[index])
											var salary=payees[index].salary[0];
											if(entry){
												var TIMESHEET = entry;
												var SALARY = this.frequency(salary.frequency,salary.amount);
												var TIME = 0; 
												if(TIMESHEET.seconds>0) TIME = Number(TIME + TIMESHEET.seconds);
												if(TIMESHEET.minutes>0) TIME = Number(TIME + this.to_seconds('minute',TIMESHEET.minutes));
												if(TIMESHEET.hours>0) TIME = Number(TIME + this.to_seconds('hour',TIMESHEET.hours));
												if(TIMESHEET.days>0) TIME = Number(TIME + this.to_seconds('day',TIMESHEET.days));
												if(TIMESHEET.weeks>0) TIME = Number(TIME + this.to_seconds('week',TIMESHEET.weeks));
												ADD=true; 
												SUBTRACT=false;
												LABEL='Timesheet';
											}
										}
									break;
									case 'taxes':
									case 'deductions':
										var TimeOff = {always_enabled:false,secs:0,mins:0,hrs:0,days:0,weeks:0,two_weeks:0,work_days:0,weekends:0,months:0,years:0};
										var TIME = 0; var SALARY = 0;
										var amount = entry.paytype=='percentage'?Number(payees[index].amount*(entry.percentage/100)):entry.amount;
										if(end_seconds>0&&start_seconds>0&&end_seconds>start_seconds) {
											SALARY = this.frequency(entry.frequency,amount);
											TIME = Number(end_seconds-start_seconds);	
										} else {
											SALARY = this.frequency(entry.frequency,amount);
											TIME = 0;
										}
										ADD=false; 
										SUBTRACT=true;
										LABEL=(ve=='taxes')?'Tax':'Deduction';
									break;
									case 'leaves':
									case 'vacations':
										LABEL=(ve=='vacations')?'Vacation':'Leave';
										if(entry.always_enabled){
											TimeOff.always_enabled=true;
										} else {
											this.count_days(START_DATE, END_DATE, (secs,mins,hrs,days,weeks,two_weeks,work_days,weekends,months,years)=>{
												TimeOff = {
													always_enabled:TimeOff.always_enabled?TimeOff.always_enabled:false,
													secs:TimeOff.secs>0?TimeOff.secs+secs:secs,
													mins:TimeOff.mins>0?TimeOff.mins+mins:mins,
													hrs:TimeOff.hrs>0?TimeOff.hrs+hrs:hrs,
													days:TimeOff.days>0?TimeOff.days+days:days,
													weeks:TimeOff.weeks>0?TimeOff.weeks+weeks:weeks,
													two_weeks:TimeOff.two_weeks>0?TimeOff.two_weeks+two_weeks:two_weeks,
													work_days:TimeOff.work_days>0?TimeOff.work_days+work_days:work_days,
													weekends:TimeOff.weekends>0?TimeOff.weekends+weekends:weekends,
													months:TimeOff.months>0?TimeOff.months+months:months,
													years:TimeOff.years>0?TimeOff.years+years:years
												};
											}, true);
										}
									break;  
								}
								this.count_days(START_DATE,END_DATE,(SECONDS,MINUTES,HOURS,DAYS,WEEKS,TWO_WEEKS,WEEKDAYS,WEEKENDS,MONTHS,YEARS)=>{
									var DAYS_TO_REPEAT = 0, x=0, y=0;
									if(ve=='timesheets') {
										x=1;
										y=Number(SALARY * TIME);
									} else {
										if(!entry.repeat&&entry.frequency)entry.repeat=entry.frequency;
										if(TimeOff.secs>0)SECONDS=SECONDS-TimeOff.secs;if(TimeOff.always_enabled)SECONDS=0;
										if(TimeOff.mins>0)MINUTES=MINUTES-TimeOff.mins;if(TimeOff.always_enabled)MINUTES=0;
										if(TimeOff.hrs>0)HOURS=HOURS-TimeOff.hrs;if(TimeOff.always_enabled)HOURS=0;
										if(TimeOff.days>0)DAYS=DAYS-TimeOff.days;if(TimeOff.always_enabled)DAYS=0;
										if(TimeOff.weeks>0)WEEKS=WEEKS-TimeOff.weeks;if(TimeOff.always_enabled)WEEKS=0;
										if(TimeOff.two_weeks>0)TWO_WEEKS=TWO_WEEKS-TimeOff.two_weeks;if(TimeOff.always_enabled)TWO_WEEKS=0;
										if(TimeOff.work_days>0)WEEKDAYS=WEEKDAYS-TimeOff.work_days;if(TimeOff.always_enabled)WEEKDAYS=0;
										if(TimeOff.weekends>0)WEEKENDS=WEEKENDS-TimeOff.weekends;if(TimeOff.always_enabled)WEEKENDS=0;
										if(TimeOff.months>0)MONTHS=MONTHS-TimeOff.months;if(TimeOff.always_enabled)MONTHS=0;
										if(TimeOff.years>0)YEARS=YEARS-TimeOff.years;if(TimeOff.always_enabled)YEARS=0;
										switch (entry.repeat){
											case "second":
												x=SECONDS;
											break;
											case "minute":
												x=MINUTES;
											break;
											case "hour":
												x=HOURS;
											break;
											case "day":
												x=DAYS;
											break;
											case "weekdays":
												x=WEEKDAYS;
											break;
											case "weekends":
												x=WEEKENDS;
											break;
											case "week":
												x=WEEKS;
												TIME=this.SECONDS_IN_A_WEEK;
											break;
											case "fortnite":
												x=TWO_WEEKS;
												TIME=this.SECONDS_IN_2_WEEKS;
											break;
											case "month":
												x=MONTHS
												TIME=this.SECONDS_IN_A_MONTH;
											break;
											case "year":
												x=YEARS; 
												TIME=this.SECONDS_IN_A_YEAR;
											break;
										}
										y=Number(TIME*SALARY);
									}
									if(this.verbose)console.log(ve,ADD,SUBTRACT,x,y);
									if(!payees[index].amount)payees[index].amount=0;
									var adg = 0;
									if(ADD && !SUBTRACT && x > 0 && y > 0){adg=1;
										for(var w = 0; w < x; ++w){
											payees[index].amount = Number(payees[index].amount + y);
											total = Number(total + payees[index].amount);
										}
										if(this.verbose)console.log('PAYROLL ADDITION:',y,ve,entry);
									}
									if(SUBTRACT && !ADD && x > 0 && y > 0){adg=0;
										for(var w = 0; w < x; ++w){
											payees[index].amount = Number(payees[index].amount - y);
											total = Number(total - payees[index].amount);
										}
										if(this.verbose)console.log('PAYROLL SUBTRACTION:',y,ve,entry);
									}
									this.generate_entry(x,y,entry,Number(payees[index].amount),total,SALARY,TIME,LABEL,adg,(ChequeEntry)=>{
										if(ChequeEntry)payees[index].entries.push(ChequeEntry);
										var ssa=(Payroll.payees?Payroll.payees.length:0)+(Payroll.employees?Payroll.employees.length:0);
										var dod=0;for( let o of objects ){
											dod=dod+(Tabulation[o]?Tabulation[o].length:0);
										}
										var ds=dod*ssa;ii++;
										if(ii==ds){
											if(Payroll[ep].length-1==i&&c==Tabulation[ve].length-1){
												if(this.more_verbose)console.log('New Payees:',payees);
												cb(Payroll, payees);
												return;
											}
										}
									});
								});
							} else {
								var ssa=(Payroll.payees?Payroll.payees.length:0)+(Payroll.employees?Payroll.employees.length:0);
								var dod=0;for( let o of objects ){
									dod=dod+(Tabulation[o]?Tabulation[o].length:0);
								}
								var ds =dod*ssa;ii++;
								if(ii==ds){
									if(Payroll[ep].length-1==i&&c==Tabulation[ve].length-1){
										if(this.more_verbose)console.log('New Payees:',payees);
										cb(Payroll, payees);
										return;
									}
								}
							}
						}
					}
				}
			}
		});
	}
	generate_entry(x,y,entry,amount,total,salary,time,label,mode,cb?) {
		this.Currencies=Currencies;
		var ChequeEntry = new ChequeEntry();
			ChequeEntry.title = label+' '+time+'*'+(mode?' ':' -')+salary.amount+''+salary.currency;
			ChequeEntry.amount = amount;
			ChequeEntry.mode = mode?'add':'subtract';
			ChequeEntry.currency=salary.currency;
			ChequeEntry.fiat=this.Currencies[salary.currency].fiat?true:false;
			ChequeEntry.enabled=true;
			ChequeEntry.date = new Date(Date.now()).toISOString();
		console.log('generate_entry', x,y,entry,amount,total,label,salary,time);
		console.log('Cheque Entry', ChequeEntry);
		if(cb)cb(ChequeEntry);
	}
	expand(payroll,cb?) {
		this.expanding=true;
		var i=0;
		var models=['payee','employee','job','bank','salary','schedule','tax','deduction','vacation','leave'];
		payroll.vacations=[];payroll.leaves=[];
		var ek = [
			{single:'address',table:'Addresses',plural:'address_ids',id:'address_id'},
			{single:'deductions',table:'Deductions',plural:'deduction_ids',id:'deduction_id'},
			{single:'salary',table:'Salaries',plural:'salary_ids',id:'salary_id'},
			{single:'schedule',table:'Schedules',plural:'schedule_ids',id:'schedule_id'},
			{single:'employees',table:'Employees',plural:'employee_ids',id:'employee_id'},
			{single:'payees',table:'Payees',plural:'payee_ids',id:'payee_id'},
			{single:'taxes',table:'Taxes',plural:'tax_ids',id:'tax_id'},
			{single:'leaves',table:'Leaves',plural:'leave_ids',id:'leave_id'},
			{single:'vacations',table:'Vacations',plural:'vacation_ids',id:'vacation_id'},
			{single:'timesheet',table:'Timesheets',plural:'timesheet_ids',id:'timesheet_id'}
		];
		this.get_vacations(payroll,(p)=>{
			this.get_leaves(p,(l)=>{
				this.get_timesheets(l,(payroll)=>{
					var i =0;
					for(let d of models){
						if(payroll[d+'_ids']){
							var t = d.charAt(0).toUpperCase()+d.slice(1)+'s';
							if (t=='Banks')t='BankAccounts';if (t=='Salarys')t='Salaries';if (t=='Taxs')t='Taxes';
							var e =[];
							if( payroll[d+'_id'] && typeof payroll[d+'_id']=='string'||typeof payroll[d+'_id']=='number' ) {
								e =[payroll[d+'_ids']];
								payroll[(d=='tax')?d+'es':d+'s']=[];
							} else if ( payroll[d+'_ids'] && typeof payroll[d+'_ids']=='string'||typeof payroll[d+'_ids']=='number' ) {
								e =[];
								if (payroll[d+'_ids'].indexOf(',') > -1) {
									e=payroll[d+'_ids'].split(',');
								} else {
									e=[payroll[d+'_ids']];
								}
								payroll[(d=='tax')?d+'es':d+'s']=[];
							}
							var ii=0;
							for(let f of e) {
								this.sql.view(t,d+'_id',f,(n)=>{
									if(n) {
										var l = [];
										for(let k of n) {
											var iii = 0;
											for(let v of ek){
												k[v.single]=[];
												if(k[v.plural]&&k[v.plural].includes(',')){
													var s = k[v.plural].split(',');
													k[v.single]=[]; 
													var iiii = 0;
													for (var t of s) {
														if(t)this.sql.view(v.table,v.id,t,(p)=>{
															k[v.single].push(p[0]);
															l.push(k);
															payroll[(d=='tax')?d+'es':d+'s'].push(l);
															if(i==models.length && e.length==ii && ek.length==iii && s.length==iiii){
																this.expanding = false;
																cb(payroll);
															}
															iiii++;
															iii++;
														});
													}
												} else if(k[v.plural]) {
													this.sql.view(v.table,v.id,k[v.plural],(p)=>{
														k[v.single]=[p[0]];
														l.push(k);
														payroll[(d=='tax')?d+'es':d+'s'].push(l);
														if(i==models.length && e.length==ii && ek.length==iii){
															this.expanding = false;
															cb(payroll);
														}
														iii++;
													});
												}
											}
										}
									}
									ii++;
								});
							}
						}
						i++;
					}
				});
			});
		});
	}
	fetch_schedules(payroll,cb?) {
		var tabulation_data = {
			cheques: 	[],
			payments: 	[],
			entries: 	[],
			payees: 	[],
			employees: 	[],
			jobs: 		[],
			total: 		 0.00,
			banks: 		payroll.banks,
			time_method:payroll.time_method?payroll.time_method:'both',
			currency: 	payroll.currency?payroll.currency:this.sql.Currency
		};
		if(payroll.schedule_ids){
			if(payroll.schedule_ids.includes(',')){
				payroll.schedule_ids=payroll.schedule_ids.split(',');
			} else {
				payroll.schedule_ids=[payroll.schedule_ids];
			}
		}
		if(cb)cb(payroll);
	}
	create_payment(subject, payroll, save, cb?) {
		if( (this.sql.max_payroll_amount>0&&this.sql.max_payroll_amount<subject.amount) || (this.sql.max_payment_amount>0&&this.sql.max_payment_amount<subject.amount) ) {
			cb(false);
		} else {
			if (!payroll.currency) payroll.currency = this.sql.Currency;
			if (!payroll.date) payroll.date = new Date(Date.now()).toISOString();
			var memo = payroll.printed_memo;
			if( subject.printed_memo ) memo = subject.printed_memo;
			let payment = new NewPayment();
				payment.amount = subject.amount;
				payment.fullname = subject.full_name?subject.full_name:subject.name;
				payment.employee_ids = subject.employee_id;
				payment.payee_ids = subject.payee_id;
				payment.address_ids = (subject.address_ids)?subject.address_ids:subject.address_id;
				payment.created = new Date(Date.now()).toISOString();
				payment.currency = payroll.currency;
				payment.date = new Date(Date.now()).toISOString();
				payment.bank_ids = Number(payroll.bank_ids);
				payment.tax_ids = payroll.tax_id;
				payment.payroll_id = payroll.payroll_id;
				payment.printed_memo = memo;
				payment.type = payroll.payment_method;
				payment.enabled = true;
			if(save&&(payroll.payment_method=='payment'||payroll.payment_method=='paypal')){
				this.sql.add("Payments",payment,(PaymentID)=>{
					if(PaymentID){
						payment.payment_id=PaymentID;
						if(cb)cb(payment,PaymentID);
					}
				});
			} else { if(cb)cb(payment); }
		}
	}
	generate_cheques(ids,CallBack?) {
		var cheques =[], i = 0;
		for (var id of ids) {
			this.generate_cheque(id,(cheque)=>{
				if(cheque)cheques.push(cheque);
				i++;
				if(ids.length==i)CallBack(cheques);
			});
		}
	}
	generate_cheque(_payment,CallBack?) {
		//_payment.currency _payment.amount // TODO check/compare the currency rate
		if((!_payment) || (this.sql.max_payroll_amount>0&&this.sql.max_payroll_amount<_payment.amount) || (this.sql.max_cheque_amount>0&&this.sql.max_cheque_amount<_payment.amount)) {
			CallBack(false);
		} else {
			if(!_payment.address_ids)_payment.address_ids=-1;
			if(!_payment.bank_ids)_payment.bank_ids=-1;
			this.sql.view('Addresses','address_id',_payment.address_ids,(address)=>{
				if(!address)return;
				if(address)address = address[0];
				this.sql.view('BankAccounts','bank_id',_payment.bank_ids,(bank)=>{
					if(!bank)return;
					if(bank)bank = bank[0];
					let Cheque = new NewCheque();
					if(address){
						Cheque.addresses = address.address_id;
						Cheque.address_id = address.address_id;
						Cheque.address_name = address.name;
						Cheque.address = address.address;
						Cheque.address_2 = address.address_2;
						Cheque.city = address.city;
						Cheque.province = address.province;
						Cheque.country = address.country;
						Cheque.postal = address.postal;
					}
					if(bank){
						Cheque.signature = bank.signature;
						Cheque.printed_id = bank.cheque_id?bank.cheque_id:Cheque.cheque_id;
						Cheque.banks = bank.bank_id;
						Cheque.bank_account_holder = bank.account_holder;
						Cheque.bank_image = bank.image;
						Cheque.bank_name = bank.name;
						Cheque.bank_address = bank.address;
						Cheque.bank_address_2 = bank.address_2;
						Cheque.bank_city =  bank.city;
						Cheque.bank_postal = bank.postal;
						Cheque.bank_country = bank.country;
						Cheque.cheque_number = bank.cheque_number;
						Cheque.transit_number = bank.transit_number;
						Cheque.institution_number = bank.institution_number;
						Cheque.designation_number = bank.designation_number;
						Cheque.account_number = bank.account_number;
					}
					Cheque.admin_id = this.sql.admin_id,
					Cheque.printed = false;
					Cheque.mailed = false;
					Cheque.applied = false;
					Cheque.posted = false;
					Cheque.status = 100;
					Cheque.cheque_id = 0;
					Cheque.address_id = _payment.address_ids;
					Cheque.payment_id = _payment.payment_id;
					Cheque.banks = _payment.bank_ids;
					Cheque.currency = _payment.currency;
					Cheque.taxes = '';
					Cheque.schedules = '';
					Cheque.vacations = '';
					Cheque.deductions = '';
					Cheque.notes = '';
					if(_payment&&_payment.fullname)Cheque.name = _payment.fullname;
					if(_payment&&_payment.employee_id)Cheque.employee_id = _payment.employee_id;
					if(_payment&&_payment.payee_id)Cheque.payee_id = _payment.payee_id;
					if(_payment&&_payment.payroll_id)Cheque.payroll_id = _payment.payroll_id;
					if(_payment&&_payment.date)Cheque.date = _payment.date;
					if(_payment&&_payment.currency)Cheque.currency = _payment.currency;
					if(_payment&&_payment.amount)Cheque.amount = _payment.amount;
					if(_payment&&_payment.payment_id)Cheque.number = _payment.payment_id;
					if(_payment&&_payment.cheque_id)Cheque.cheque_number = _payment.cheque_id;
					if(_payment&&_payment.printed_memo)Cheque.printed_memo = _payment.printed_memo;
					if(_payment&&_payment.amount)Cheque.written_amount = converter.toWords(_payment.amount);
					this.sql.add("Cheques",Cheque,(ChequeID)=>{
						if(ChequeID){
							Cheque.entries = _payment.entries;
							Cheque.cheque_id=ChequeID;
							this.update_cheque_entries(_payment.payment_id, Cheque.cheque_id,(Done)=>{
								CallBack(Cheque,ChequeID);
							});
						}
					});
				})
			})
		}
	}
	frequency(frequency, amount){
		switch(frequency) {
			case 'second':
				return Number(amount);
			break;
			case 'minute':
				return Number(amount/this.SECONDS_IN_A_MINUTE);
			break;
			case 'hour':
				return Number(amount/this.SECONDS_IN_AN_HOUR);
			break;
			case 'day':
				return Number(amount/this.SECONDS_IN_A_DAY);
			break;
			case 'week':
				return Number(amount/this.SECONDS_IN_A_WEEK);
			break;
			case 'fortnite':
				return Number(amount/this.SECONDS_IN_2_WEEKS);
			break;
			case 'month':
				return Number(amount/this.SECONDS_IN_A_MONTH);
			break;
			case 'year':
				return Number(amount/this.SECONDS_IN_A_YEAR);
			break;
			default:
				return 0;
			break;
		}
	}
	to_seconds(frequency, amount){
		switch(frequency) {
			case 'second':
				return Number(amount);
			break;
			case 'minute':
				return Number(amount*this.SECONDS_IN_A_MINUTE);
			break;
			case 'hour':
				return Number(amount*this.SECONDS_IN_AN_HOUR);
			break;
			case 'day':
				return Number(amount*this.SECONDS_IN_A_DAY);
			break;
			case 'week':
				return Number(amount*this.SECONDS_IN_A_WEEK);
			break;
			case 'fortnite':
				return Number(amount*this.SECONDS_IN_2_WEEKS);
			break;
			case 'month':
				return Number(amount*this.SECONDS_IN_A_MONTH);
			break;
			case 'year':
				return Number(amount*this.SECONDS_IN_A_YEAR);
			break;
			default:
				return 0;
			break;
		}
	}
	leaves(tabulation, payee, cb) {
		var skip = false;
		for(let b of ['leaves','vacations']){
			for(let v of tabulation[b]) {v=v[0];
				for(let e of ['employee','payee']) {
					if(payee[e]){let pid = payee[e];
						// console.log(b,e,pid)
						if(v.employee_ids.split(',').includes(pid)){
							console.log(b,v);
							if(v.always_enabled){
								skip=true;
							} else {
								this.count_days(v.start_date, v.end_date, (secs,mins,hrs,days,weeks,two_weeks,work_days,weekends,months,years)=>{
									console.log(secs,mins,hrs,days,weeks,two_weeks,work_days,weekends,months,years);
								});
							}
						}
					}
				}
			}
		}
	}
	absent(payroll,tabulation,payee,cb?){
		var absent = false;
		for(let v of tabulation.vacations[0]){
			for(let e of v.employees){
				if( e.employee_id==payee.employee_id||
					e.payee_id==payee.payee_id) {
					var date = new Date();
					var end = new Date(v.end_date);
					var start = new Date(v.start_date);
					if(date>start&&date<end){absent=true;}else{absent=false;}
					if(this.more_verbose)console.log('absent',absent);
					if(cb)cb(absent);
					return absent;
				}
			}
		}
	}
	cached=[]
	count_days(startDate, endDate, cb, leave?) {
	    let secs = 0;
	    let mins = 0;
	    let hrs = 0;
	    let days = 0;
	    let weeks = 0;
	    let two_weeks = 0;
	    let work_days = 0;
	    let weekends = 0;
	    let months = 0;
	    let years = 0;
		const curDate = new Date(startDate.getTime());
	    var n = 1, month = curDate.getMonth(), year = curDate.getFullYear();
	    while (curDate <= endDate) {
	    	if(leave && this.cached.includes(curDate.getDate())) {
	    		curDate.setDate(curDate.getDate()+1);
	    	} else {
		        const dayOfWeek = curDate.getDay();
		        if(month < curDate.getMonth()){
		        	months++;
		        	month=curDate.getMonth();
		        }
		        if(year < curDate.getFullYear()){
		        	years++;
		        	year=curDate.getFullYear();
		        }
		        if(dayOfWeek !== 0 && dayOfWeek !== 6) work_days++;
		        if(dayOfWeek == 0 || dayOfWeek == 6) weekends++;
		        if(dayOfWeek == 6) {
		        	weeks++;
		        	if (n==2){
		        		two_weeks++;
		        		n=1;
		        	}
		        	n++;
		        }
		        days++;

		        if(leave)this.cached.push(curDate.getDate());
		        curDate.setDate(curDate.getDate()+1);
	    	}
	    }
	    secs = days*this.SECONDS_IN_A_DAY;
	    mins = (days*this.SECONDS_IN_A_DAY)/60;
	    hrs  = days*24;
	    cb(secs,mins,hrs,days,weeks,two_weeks,work_days,weekends,months,years);
	}
	get_timesheets(payroll, cb?) {
		var i = 0;
		var ids=[];
		var payee_ids=[];
		var employee_ids=[];
		if(payroll.employee_ids)employee_ids=payroll.employee_ids.split(',');
		if(payroll.payee_ids)payee_ids=payroll.payee_ids.split(',');
		if(payee_ids&&payee_ids.length>0)for(var ep of payee_ids){
			this.sql.fetch('Timesheets WHERE payee_ids = '+ep,(v)=>{
				if(v){
					i++;
					for(let o of v){
						var ii = 0;
						this.ts.calculate_seconds(o.timesheet_id,(h,m,s)=>{
							o['seconds']=s; o['minutes']=m; o['hours']=h;
							payroll.timesheets.push([o]);
							ids.push(o.timesheet_id);
							ii++;
							if(i==Number(employee_ids.length+payee_ids.length)&&cb&&ii==v.length){
								payroll.timesheet_ids=ids.join(',');
								cb(payroll);
							}
						});
					}
				} else {
					i++;
					if(i==Number(employee_ids.length+payee_ids.length)&&cb){
						payroll.timesheet_ids=ids.join(',');
						cb(payroll);
					}
				}
			});
		}
		if(employee_ids&&employee_ids.length>0)for(var ep of employee_ids){
			this.sql.fetch('Timesheets WHERE employee_ids = '+ep,(v)=>{
				if(v){
					i++;
					for(let o of v){
						var ii = 0;
						this.ts.calculate_seconds(o.timesheet_id,(h,m,s)=>{
							o['seconds']=s; o['minutes']=m; o['hours']=h;
							payroll.timesheets.push([o]);
							ids.push(o.timesheet_id);
							ii++;
							if(i==Number(employee_ids.length+payee_ids.length)&&cb&&ii==v.length){
								payroll.timesheet_ids=ids.join(',');
								cb(payroll);
							}
						});
					}
				} else {
					i++;
					if(i==Number(employee_ids.length+payee_ids.length)&&cb){
						payroll.timesheet_ids=ids.join(',');
						cb(payroll);
					}
				}
			});
		}
	}
	get_vacations(payroll, cb?) {
		var i = 0;
		var ids=[];
		var payee_ids=[];
		var employee_ids=[];
		if(payroll.employee_ids)employee_ids=payroll.employee_ids.split(',');
		if(payroll.payee_ids)payee_ids=payroll.payee_ids.split(',');
		if(payee_ids&&payee_ids.length>0)for(var ep of payee_ids){
			this.sql.fetch('Vacations WHERE payee_id = '+ep,(v)=>{
				if(v){
					for(let o of v){
						payroll.vacations.push(o);
						ids.push(o.vacation_id);
					}
					i++;
					if(i==Number(employee_ids.length+payee_ids.length)&&cb){
						payroll.vacation_ids=ids.join(',');
						cb(payroll);
					}
				} else {
					i++;
					if(i==Number(employee_ids.length+payee_ids.length)&&cb){
						payroll.vacation_ids=ids.join(',');
						cb(payroll);
					}
				}
			});
		}
		if(employee_ids&&employee_ids.length>0)for(var ep of employee_ids){
			this.sql.fetch('Vacations WHERE employee_id = '+ep,(v)=>{
				if(v){
					for(let o of v){
						payroll.vacations.push(o);
						ids.push(o.vacation_id);
					}
					i++;
					if(i==Number(employee_ids.length+payee_ids.length)&&cb){
						payroll.vacation_ids=ids.join(',');
						cb(payroll);
					}
				} else {
					i++;
					if(i==Number(employee_ids.length+payee_ids.length)&&cb){
						payroll.vacation_ids=ids.join(',');
						cb(payroll);
					}
				}
			});
		}
	}
	get_leaves(payroll, cb?) {
		var i = 0;
		var ids=[];
		var payee_ids=[];
		var employee_ids=[];
		if(payroll.employee_ids)employee_ids=payroll.employee_ids.split(',');
		if(payroll.payee_ids)payee_ids=payroll.payee_ids.split(',');
		if(payee_ids&&payee_ids.length>0)for(var ep of payee_ids){
			this.sql.fetch('Leaves WHERE payee_id = '+ep,(v)=>{
				if(v){
					for(let o of v){
						payroll.leaves.push([o]);
						ids.push(o.leave_id);
					}
					i++;
					if(i==Number(employee_ids.length+payee_ids.length)&&cb){
						payroll.leaves_ids=ids.join(',');
						cb(payroll);
					}
				} else {
					i++;
					if(i==Number(employee_ids.length+payee_ids.length)&&cb){
						payroll.leaves_ids=ids.join(',');
						cb(payroll);
					}
				}
			});
		}
		if(employee_ids&&employee_ids.length>0)for(var ep of employee_ids){
			this.sql.fetch('Leaves WHERE employee_id = '+ep,(v)=>{
				if(v){
					for(let o of v){
						payroll.leaves.push([o]);
						ids.push(o.leave_id);
					}
					i++;
					if(i==Number(employee_ids.length+payee_ids.length)&&cb){
						payroll.leaves_ids=ids.join(',');
						cb(payroll);
					}
				} else {
					i++;
					if(i==Number(employee_ids.length+payee_ids.length)&&cb){
						payroll.leaves_ids=ids.join(',');
						cb(payroll);
					}
				}
			});
		}
	}
}