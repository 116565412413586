export class NewAddress {
	constructor(
		public address_id?: number,
		public admin_id?: number,
		public employee_id?: number,
		public name?: string,
		public logo?: string,
		public address?: string,
		public address_2?: string,
		public city?: string,
		public province?: string,
		public postal_code?: string,
		public country?: string,
		public address_name?: string,
		public image?: string,
		public image_path?: string,
		public enabled?: boolean,
		public modified?: any,
		public created?: any
	){}
}
export const Create_Addresses = '\
	CREATE TABLE IF NOT EXISTS "Addresses" (\
		address_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER,\
		employee_id INTEGER,\
		name TEXT,\
		logo TEXT,\
		address TEXT,\
		address_2 TEXT,\
		city TEXT,\
		province TEXT,\
		postal_code TEXT,\
		country TEXT,\
		address_name TEXT,\
		image TEXT,\
		image_path TEXT,\
		enabled INTEGER DEFAULT 0,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';