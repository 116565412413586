import { SQLService } from '../../services/sql.service';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router,NavigationEnd,ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-select-date',
  templateUrl: './select-date.component.html',
  styleUrls: ['./select-date.component.scss'],
})
export class SelectDatePage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    public sql: SQLService,
    private location: Location
  ) {
    this.router.events.subscribe((eve) => {
      //if(eve instanceof NavigationEnd)
    });
  }
  count = -1;
  output = new EventEmitter();
  ngOnInit() {
  }
  onChange(e) {
    console.log(e);
  }
  select(e) {
    this.navCtrl.dismiss(e);
  }
  add() {
    this.navCtrl.dismiss();
  }
  off() {
    this.navCtrl.dismiss();
  }
}