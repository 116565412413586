import { environment } from '../../../../environments/environment';
import { FTPSettingsComponent } from '../../ftp-settings/ftp-settings.component';
import { SQLService } from '../../../services/sql.service';
import { NewImport } from '../../../models/import';
import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
@Component({
  selector: 'ng-cheques-new-import',
  templateUrl: './new-import.component.html',
  styleUrls: ['./new-import.component.styl']
})
export class NewImportComponent implements OnInit {
  constructor(
    public sql: SQLService,
    private zone: NgZone,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    public modalController: ModalController,
  ) { 

  }
  _c = -1;
  currency = null;
  import = new NewImport();
  currencies = Object.keys(environment.currencies);
  file: any;
  loading_Excel:boolean;
  loading_JSON:boolean;
  loading_SQLite:boolean;
  done_Excel: boolean;
  done_JSON: boolean;
  done_SQLite: boolean;
  show_retry: boolean;
  // @ViewChild('ExtraUploader') xlsxUploader: ElementRef;
  // @ViewChild('jsonUploader') jsonUploader: ElementRef;
  // @ViewChild('sqlUploader') sqlUploader: ElementRef;
  // ExtraUploader_click(){setTimeout( () => { this.xlsxUploader.nativeElement.click();},1000);}
  // jsonUploader_click(){setTimeout( () => { this.jsonUploader.nativeElement.click();},1000);}
  // sqlUploader_click(){setTimeout( () => { this.sqlUploader.nativeElement.click();},1000);}

  import_csv() {
    let id = 0;
    this.router.navigate(['folder','Import',id]);
  }
  getFileReader(): FileReader {
      return 
  }
  loadfile() {
    setTimeout(()=>{
      this.show_retry=true;
    },300);
    setTimeout( () => {
      this.loading_Excel = false;
      this.loading_JSON = false;
      this.loading_SQLite = false;
      this.show_retry=false;
    },1200);
  }
  xlsxUploaderChange(event) {
    var files: FileList = event.target.files;
    let results = [];
    if (files && files.length > 0) {
        const file: File = files.item(0);
        this.done_Excel = true;
        this.zone.run(()=>{
          var fileReader = new FileReader();
          var zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
          fileReader = zoneOriginalInstance || fileReader;
          fileReader.readAsArrayBuffer(file)
          fileReader.onload = (docsrc) => {
            let base64_file = (docsrc.target as FileReader).result; 
            this.sql.process_import_xls(base64_file,(spreadsheet)=>{
              var meta = {name:file.name,size:file.size,type:file.type}
              localStorage.setItem('spreadsheet_import_meta',JSON.stringify(meta));
              localStorage.setItem('spreadsheet_import',JSON.stringify(spreadsheet));
              setTimeout( () => {
                this.router.navigate(['folder','Import','New','Edit','Temp']);
                this.done_Excel = false;
              },1200);
            });
          }
       });
    }
  }
  jsonUploaderChange(event) {
    var files: FileList = event.target.files;
    let results = [];
    if (files && files.length > 0) {
        const file: File = files.item(0);
        this.done_JSON = true;
        this.zone.run(()=>{
          var fileReader = new FileReader();
          var zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
          fileReader = zoneOriginalInstance || fileReader;
          fileReader.readAsArrayBuffer(file)
          fileReader.onload = (docsrc) => {
            let base64_file = (docsrc.target as FileReader).result; 
            this.sql.process_import_json(base64_file,(spreadsheet)=>{
              var meta = {name:file.name,size:file.size,type:file.type}
              localStorage.setItem('spreadsheet_import_meta',JSON.stringify(meta));
              localStorage.setItem('spreadsheet_import',JSON.stringify(spreadsheet));
              setTimeout( () => {
                this.router.navigate(['folder','Import','New','Edit','Temp']);
                this.done_JSON = false;
              },1200);
            });
          }
       });
    }
  }
  sqlUploaderChange(event) {
    var files: FileList = event.target.files;
    let results = [];
    if (files && files.length > 0) {
        const file: File = files.item(0);
        this.done_SQLite = true;
        this.zone.run(()=>{
          var fileReader = new FileReader();
          var zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
          fileReader = zoneOriginalInstance || fileReader;
          fileReader.readAsArrayBuffer(file)
          fileReader.onload = (docsrc) => {
            let base64_file = (docsrc.target as FileReader).result; 
            this.sql.process_import_sql(base64_file,(spreadsheet)=>{
              var meta = {name:file.name,size:file.size,type:file.type}
              localStorage.setItem('spreadsheet_import_meta',JSON.stringify(meta));
              localStorage.setItem('spreadsheet_import',JSON.stringify(spreadsheet));
              setTimeout( () => {
                this.router.navigate(['folder','Import','New','Edit','Temp']);
                this.done_SQLite = false;
              },1200);
            });
          }
       });
    }
  }
  select_import(m) { 
    // m=m.toLower();
    this.import.type = m;
    if(m=='excel'||m=='xls'||m=='xlsx'||m=='xlsm'||m=='xlsb') {
      this.sql.import_excel();
    };
    if(m=='json') {
      this.sql.import_json();
    };
    if(m=='sql') {
      this.sql.import_sql();
    };
    if(m=='ftp') {
      this.sql.import_ftp();
    };
    if(m=='uri') {
      let p = '';
      if(p=prompt(this.sql.word.URL_File_Prompt,'http://')){
        this.sql.import_uri(p);
      } else { return; }
    };
    if(m=='sqlite') {
      let p = '';
      if(p=prompt(this.sql.word.URL_SQL_Prompt,'http://')){
        this.sql.import_uri(p);
      } else { return; }
    };
    if(m=='icloud') {
      this.sql.import_icloud();
    };
    if(m=='googledrive') {
      this.sql.import_googledrive();
    };
    if(m=='onedrive') {
      this.sql.import_onedrive();
    };
  }
  navigate(url) { this.router.navigate(['folder','Settings','Import',url])}
  export() { this.router.navigate(['folder','Export','New'])}
  save(end=true) {
    this.sql.add("Imports",this.import);
    if(end){
      this.import = new NewImport();
      this._c=0;
      this.end();
    }
  }
  end() {
    if (this._c>0) {
      if(confirm(this.sql.word.Discard_Confirmation)) {
        this.import = new NewImport();
        this.router.navigate(['folder','Imports']);
      }
    } else { 
      this.router.navigate(['folder','Imports']);
    }
  }

  async add_ftp() {
    const m = await this.modalController.create({component: FTPSettingsComponent, componentProps: this.import.ftp});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data)this.sql.hapticsImpactMedium();
    if(a.data){
      this.import.ftp = a.data;
    }
    this.sql.remove_temporary('page');
  }
  async edit_ftp() {
    const m = await this.modalController.create({component: FTPSettingsComponent, componentProps: this.import.ftp});
    await m.present();
  }
  delete_ftp() {
    this.import.ftp = '';
  }
  get_import() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida) return;
    this.sql.view('Imports','import_id',ida,(e)=>{
      var f=Object.entries(e[0]); let n = new NewImport();
      for(let t of f)n[t[0]]=t[1];
      this.import = n;
    });
  }
  ngOnInit() {
    this.get_import();
  }
}