import { NewPostage } from '../../models/postage';
import { environment } from '../../../environments/environment';
import { SelectAddressPage } from '../../addresses/select-address/select-address.page';
import { SelectEmployeePage } from '../../employees/select-employee/select-employee.page';
import { PopoverController} from '@ionic/angular';
import { SQLService } from '../../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { PostageMenuComponent } from './postage-menu/postage-menu.component';
import { Location } from '@angular/common';
@Component({
  selector: 'app-postage',
  templateUrl: './postage.component.html',
  styleUrls: ['./postage.component.scss'],
})
export class PostageComponent implements OnInit {
  constructor(
    public location: Location,
    public popoverController: PopoverController,
    public sql: SQLService,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_postage();
    });
  }
  count = -1; 
  postage = [];
  search:string;
  default_postage:string;
  filter(e) { this.search=e.target.value; }
  go(p) {this.router.navigate(['folder','Postage',p.postage_id]);}
  add() {this.router.navigate(['folder','Postage','New']);}
  end() {this.location.back()}
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:PostageMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='default')this.default(item);
      if(data=='delete')this.delete(item);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  get_postage(event?) {
    this.sql.fetch('Postage',(e)=>{
      this.postage=e;
      this.count=e.length;
      this.sql.get_setting('default_postage',(s)=>{
        if(!s) return;
        this.default_postage=s.option;
      });
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  edit(postage, item?) {
    this.router.navigate(['folder','Postage',postage.postage_id]);
  }
  default(postage, item?) {
    this.sql.save_setting('default_postage',postage.postage_id,(a)=>{
      this.get_postage();
    });
  }
  delete(postage, item?) {
    if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('postage','postage_id',postage.postage_id);
      this.get_postage();
    } else { if(item)item.closeSlidingItems(); }
  }
  ngOnInit() {
    this.get_postage();
  }
}