export class NewLogin {
	constructor(
		public login_id?: number,
		public device_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public username?: string,
		public email?: string,
		public auth_key?: string,
		public expiry?: any,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Logins = '\
	CREATE TABLE IF NOT EXISTS "Logins" (\
		login_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		device_id INTEGER DEFAULT 0,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		username TEXT,\
		email TEXT,\
		auth_key TEXT,\
		enabled INTEGER DEFAULT 0,\
		expiry TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';