import { SQLService } from '../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { PopoverController} from '@ionic/angular';
import { LeaveMenuComponent } from './leave-menu/leave-menu.component';
@Component({
  selector: 'ng-cheques-leaves',
  templateUrl: './leaves.component.html',
  styleUrls: ['./leaves.component.styl']
})
export class LeavesComponent implements OnInit {
  constructor(
    private router: Router,
    public popoverController: PopoverController,
    public sql: SQLService
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_leaves();
    });
  }
  leaves = [];
  count = 0;
  search: string;
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:LeaveMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='enable')this.enable(item);
      if(data=='disable')this.disable(item);
      if(data=='delete')this.delete(item, false);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  add() {
    this.router.navigate(['folder','Leave', 'New']);
  }
  filter(e) {
    this.search=e.target.value;
  }
  get_leaves(event?) {
    this.sql.fetch('Leaves',(e)=>{
      if(e)this.leaves=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  edit(a?,i?) {
    this.router.navigate(['folder','Leave',a.leave_id]);
    if(i)i.closeSlidingItems();
  }
  end() {this.router.navigate(['folder','Dashboard']);}
  disable(leave,i?){
    this.sql.set('Leaves','leave_id',leave.leave_id,'enabled',0,(res)=>{
      leave.enabled = false;
      if(i)i.closeSlidingItems();
    });
  }
  enable(leave,i?){
    this.sql.set('Leaves','leave_id',leave.leave_id,'enabled',1,(res)=>{
      leave.enabled = true;
      if(i)i.closeSlidingItems();
    });
  }
  delete(a?,i?) {
    if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('Leaves','leave_id',a.leave_id);
      this.get_leaves();
    } else { if(i)i.closeSlidingItems(); }
  }
  ngOnInit() {this.get_leaves();}
}
