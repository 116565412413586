export class NewLog {
	constructor(
		public log_id?: number,
		public title?: string,
		public message?: string,
		public admin_id?: number,
		public mod_id?: number,
		public employee_id?: number,
		public views?: number,
		public created?: any
	) {}
}
export const Create_Logs = '\
	CREATE TABLE IF NOT EXISTS "Logs" (\
		log_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		title TEXT,\
		message TEXT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		employee_id INTEGER DEFAULT 0,\
		views INTEGER DEFAULT 0,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';