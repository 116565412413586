export class NewAccount {
	constructor(
		public accounts_id: number,
		public admin_id: number,
		public katie_id: number,
		public mod_id: number,
		public uuid: string,
		public active: boolean,
		public name: string,
		public description: string,
		public number: number,
		public amount: number,
		public currency: string,
		public modified: any,
		public created: any,
	){}
}
export const Create_Accounts = '\
	CREATE TABLE IF NOT EXISTS "Accounts" (\
		accounts_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		katie_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		uuid TEXT,\
		active INTEGER DEFAULT 0,\
		name TEXT,\
		description TEXT,\
		number TEXT,\
		amount INTEGER DEFAULT 0,\
		currency VARCHAR(10),\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';