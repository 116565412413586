import { SQLService } from '../../services/sql.service';
import { PopoverController } from '@ionic/angular';
import { Component } from '@angular/core';
@Component({
  selector: 'ng-cheques-cheque-popover',
  templateUrl: './cheque-menu.component.html',
  styleUrls: ['./cheque-menu.component.styl']
})
export class ChequeMenuComponent {
  constructor(
    public sql: SQLService,
    public popover: PopoverController) {
  }
  dismissPopover(e) {if (this.popover) this.popover.dismiss(e);}
  delete() {this.dismissPopover('delete');}
  print() {this.dismissPopover('print');}
}