import { environment } from '../../../../../environments/environment';
import { SQLService } from '../../../../services/sql.service';
import { NewDesign } from '../../../../models/design';
import { MenuController, PopoverController} from '@ionic/angular';
import { PageMarginsPage } from '../../page-margins/page-margins.component';
import { SelectPaperPage } from '../../paper-picker/paper-picker.component';
import { SelectChequeDesignsPage } from '../../select-design/select-design.page';
import { ColorPickerComponent } from '../../color-picker/color-picker.component';
import { FontPickerComponent } from '../../font-picker/font-picker.component';
import { PositionMenuComponent } from './position-menu/position-menu.component';
import { ImagePreviewComponent } from '../../image-preview/image-preview.component';
import { fonts } from '../../font-picker/fonts';
import { DocugardColors } from '../../../templates_html';
import { Component, Directive, OnInit, Input, HostListener, Output, ViewChild, EventEmitter, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ActionSheetController, ModalController, AlertController } from '@ionic/angular';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
@Component({
  selector: 'cheque-designer-palate',
  templateUrl: './cheque-palate.component.html',
  styleUrls: ['./cheque-palate.component.styl']
})
export class ChequeDesignerPalate implements OnInit {
  constructor(
    public sql: SQLService,
    private router: Router,
    public popover: PopoverController,
    public alertController: AlertController,
    private sanitizer:DomSanitizer,
    private actionSheetCtrl: ActionSheetController,
    private changeDetector: ChangeDetectorRef,
    private navCtrl: ModalController,
    private location: Location,
    private route: ActivatedRoute,
  ) { 
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) {
        this.ControlLevel = 0;
        this.pallet.nativeElement.scrollLeft = 0;
      }
    });
  }
  colors = []; 
  FontLibrary = [];
  List = [];
  designs = [];
  SelectedBarcode = '';
  uploading = '';
  ControlLevel = 0;
  QR_Code: any;
  QR_Code_enabled: any;
  QR_Code_text: any;
  QR_Code_position: any;
  Barcode: any;
  Barcode_enabled: any;
  Barcode_text: any;
  Barcode_position: any;
  Identifier: any;
  Identifier_enabled: any;
  Identifier_text: any;
  Identifier_position: any;
  design_id: number;
  @Input('fontsize') fontsize = 0;
  @Input('bold') fontweight: boolean;
  @Input('italic') fontitalic: boolean;
  @Input('design') design: any;
  @Input('borders') borders = true;
  @Input('fold_line') fold_line: any;
  @Input('micr') micr = true;
  @Input('numbers') numbers = true;
  @Input('rotate') rotate = false;
  @Input('pro') pro = false;
  @Input('date') date = true;
  @Input('layers') layers = true;
  @Input('gradients') gradients = true;
  @Input('monochrome') monochrome = false;
  @Input('voidcheck') voidcheck = false;
  @Input('page_size') page_size = 'A4';
  @Input('page_slug') page_slug = 'A4';
  @Input('page_width') page_width = '210mm';
  @Input('page_height') page_height = '297mm';
  @Input('cheque_font') cheque_font: any;
  @Input('RenderMICR') RenderMICR = false
  @Input('docugard') docugard = false;
  @Input('ImageMICR') ImageMICR = true;
  @Input('Secret') secret = '';
  @Input('micr_top') micr_top: string;
  @Input('micr_right') micr_right: string;
  @Input('micr_bottom') micr_bottom: string;
  @Input('micr_left') micr_left: string;
  @Input('page_top') page_top: string;
  @Input('page_right') page_right: string;
  @Input('page_bottom') page_bottom: string;
  @Input('page_left') page_left: string;
  @Input('table_top') table_top: string;
  @Input('table_right') table_right: string;
  @Input('table_bottom') table_bottom: string;
  @Input('table_left') table_left: string;
  @Input('address_top') address_top: string;
  @Input('address_right') address_right: string;
  @Input('address_bottom') address_bottom: string;
  @Input('address_left') address_left: string;
  @Input('background_position') background_position: string;
  @Input('cheque_text_color') cheque_text_color: any;
  @Input('cheque_color') cheque_background_color: any;
  @Input('cheque_background_image_repeat') cheque_background_image_repeat: boolean;
  @Input('cheque_background_image') cheque_background_image: any;
  @Input('cheque_logo_image') cheque_logo_image: any;
  @Input('cheque_header_image') cheque_header_image: any;
  @Input('cheque_bank_image') cheque_bank_image: any;
  @Output('design') design_ = new EventEmitter();
  @Output('font') font = new EventEmitter();
  @Output('micr') micr_ = new EventEmitter();
  @Output('numbers') numbers_ = new EventEmitter();
  @Output('date') date_ = new EventEmitter();
  @Output('layers') layers_ = new EventEmitter();
  @Output('gradients') gradients_ = new EventEmitter();
  @Output('rotate') rotate_ = new EventEmitter();
  @Output('monochrome') monochrome_ = new EventEmitter();
  @Output('voidcheck') voidcheck_ = new EventEmitter();
  @Output('fontsize') fontsize_ = new EventEmitter();
  @Output('fontweight') fontweight_ = new EventEmitter();
  @Output('fontitalic') fontitalic_ = new EventEmitter();
  @Output('page_size') page_size_ = new EventEmitter();
  @Output('slug') page_slug_ = new EventEmitter();
  @Output('page_width') page_width_ = new EventEmitter();
  @Output('page_height') page_height_ = new EventEmitter();
  @Output('borders') borders_ = new EventEmitter();
  @Output('fold_line') fold_line_ = new EventEmitter();
  @Output('RenderMICR') RenderMICR_ = new EventEmitter();
  @Output('ImageMICR') ImageMICR_ = new EventEmitter();
  @Output('Secret') Secret_ = new EventEmitter();
  @Output('background_color') background_color = new EventEmitter();
  @Output('controls') controls = new EventEmitter();
  @Output('micr_top') micr_top_ = new EventEmitter();
  @Output('micr_right') micr_right_ = new EventEmitter();
  @Output('micr_bottom') micr_bottom_ = new EventEmitter();
  @Output('micr_left') micr_left_ = new EventEmitter();
  @Output('page_top') page_top_ = new EventEmitter();
  @Output('page_right') page_right_ = new EventEmitter();
  @Output('page_bottom') page_bottom_ = new EventEmitter();
  @Output('page_left') page_left_ = new EventEmitter();
  @Output('table_top') table_top_ = new EventEmitter();
  @Output('table_right') table_right_ = new EventEmitter();
  @Output('table_bottom') table_bottom_ = new EventEmitter();
  @Output('table_left') table_left_ = new EventEmitter();
  @Output('address_top') address_top_ = new EventEmitter();
  @Output('address_right') address_right_ = new EventEmitter();
  @Output('address_bottom') address_bottom_ = new EventEmitter();
  @Output('address_left') address_left_ = new EventEmitter();
  @Output('cheque_text_color') cheque_text_color_ = new EventEmitter();
  @Output('cheque_background_image_repeat') cheque_background_image_repeat_ = new EventEmitter();
  @Output('cheque_background_image') cheque_background_image_ = new EventEmitter();
  @Output('cheque_logo_image') cheque_logo_image_ = new EventEmitter();
  @Output('cheque_header_image') cheque_header_image_ = new EventEmitter();
  @Output('cheque_bank_image') cheque_bank_image_ = new EventEmitter();
  @Output('background_position') background_position_ = new EventEmitter();
  @ViewChild('image_background_uploader') image_background_uploader: ElementRef;
  @ViewChild('image_logo_uploader') image_logo_uploader: ElementRef;
  @ViewChild('image_header_uploader') image_header_uploader: ElementRef;
  @ViewChild('image_bank_uploader') image_bank_uploader: ElementRef;
  @ViewChild('pallet') pallet: ElementRef;
  MICR_top_margin_add_held = false;
  MICR_right_margin_add_held = false;
  MICR_bottom_margin_subtract_held = false;
  MICR_left_margin_add_held = false;
  MICR_top_margin_subtract_held = false;
  MICR_right_margin_subtract_held = false;
  MICR_bottom_margin_add_held = false;
  MICR_left_margin_subtract_held = false;
  margin_interval: any;
  timer_interval: any;
  @HostListener("wheel", ["$event"])
  public onScroll(event: WheelEvent) {
    this.pallet.nativeElement.scrollLeft += event.deltaY;
  }
  getBase64Image(event,n?) {
    let me = this;
    console.log('getBase64Image',n);
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      //me.modelvalue = reader.result;
      console.log(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
  button_press(i) {
    this.sql.hapticsImpactLight();
    if(this.List[i]==true){
      this.List[i]=false;
      return;
    }
    for(let i=0;i<10;i++)this.List[i]=false;
    this.List[i]=true;
    switch (i) {
      case 0:
        this.select_design();
      break;
      case 1:
        this.ControlLevel=2;
      break;
      case 2:
        this.ControlLevel=1;
      break;
      case 3:
        this.toggle_borders();
      break;
      case 4:
        this.ControlLevel=3;
      break;
      case 5:
        this.toggle_guide();
      break;
      case 6:
        this.ControlLevel=6.1;
      break;
      case 7:
        this.ControlLevel=7.1;
      break;
      case 8:
        this.toggle_numbers();
      break;
      case 9:
        this.toggle_date();
      break;
      case 10:
        this.select_paper();
      break;
      case 11:
        this.toggle_layers();
      break;
      case 12:
        this.toggle_gradients();
      break;
      case 13:
        this.toggle_voidcheck();
      break;
      case 14:
        this.adjust_margins();
      break;
      case 15:
        this.ControlLevel=15;
      break;
      case 16:
        this.toggle_design_title();
      break;
    }
    this.controls.emit({button:i});
  }
  toggle_design_title() {
    var n=environment.places[this.sql.Locale].show_docu_title?environment.places[this.sql.Locale].show_docu_title:0;
    if(this.sql.show_docu_title==0){
      this.number_entry(this.sql.word.Design_Title+': '+this.sql.Title,this.sql.word.Amount,(u)=>{
        if(u>14){
          alert(this.sql.word.Max_Amount_Message+' (14)');
          this.sql.show_docu_title=n;
          // if(!this.cheque_background_color)this.select_color('#000000');
          this.save_docu_title();
        } else {
          this.sql.show_docu_title=u;
          // if(!this.cheque_background_color)this.select_color('#000000');
          this.save_docu_title();
        }
      },2,this.sql.word.Default+': '+n);
    } else {
      this.sql.show_docu_title = 0;
      this.save_docu_title();
    }
  }
  save_docu_title(){
    this.sql.show_docu_title = this.sql.show_docu_title>0?this.sql.show_docu_title:0;
    this.sql.save_setting('show_docu_title',this.sql.show_docu_title);
  }
  image_position() {
    this.ControlLevel=4.12;
  }
  select_image_background() {
    if (this.cheque_background_image&&this.cheque_background_image!='0') {
       this.ControlLevel=4.1;
    } else {
       this.image_background();
     }
  }
  image_background() {
    if(!this.sql.Purchased){this.sql.show_purhchase_modal();return;}
    this.sql.hapticsImpactLight();
    this.uploading='background';
    if (this.cheque_background_image&&this.cheque_background_image!='0') {
      this.image_prompt(this.sql.word.Background_Image,this.sql.word.Edit,(result)=>{
        if(result=='image')this.image_lib();
        if(result=='destructive')this.clear_background_image();
      });
    } else {
      this.image_lib();
    }
  }
  image_logo() {
    if(!this.sql.Purchased){this.sql.show_purhchase_modal();return;}
    this.sql.hapticsImpactLight();
    this.uploading='logo';
    if (this.cheque_logo_image&&this.cheque_logo_image!='0') {
      this.image_prompt(this.sql.word.Logo_Image,this.sql.word.Edit,(result)=>{
        if(result=='image')this.image_lib();
        if(result=='destructive')this.clear_logo_image();
      });
    } else {
      this.image_lib();
    }
  }
  image_header() {
    if(!this.sql.Purchased){this.sql.show_purhchase_modal();return;}
    this.sql.hapticsImpactLight();
    this.uploading='header';
    if (this.cheque_header_image&&this.cheque_header_image!='0') {
      this.image_prompt(this.sql.word.Header_Image,this.sql.word.Edit,(result)=>{
        if(result=='image')this.image_lib();
        if(result=='destructive')this.clear_header_image();
      });
    } else {
      this.image_lib();
    }
  }
  image_bank() {
    if(!this.sql.Purchased){this.sql.show_purhchase_modal();return;}
    this.sql.hapticsImpactLight();
    this.uploading='bank';
    if (this.cheque_bank_image&&this.cheque_bank_image!='0') {
      this.image_prompt(this.sql.word.Bank_Image,this.sql.word.Edit,(result)=>{
        if(result=='image')this.image_lib();
        if(result=='destructive')this.clear_bank_image();
      });
    } else {
      this.image_lib();
    }
  }
  file_lib() {
    this.sql.hapticsImpactLight();
    this.sql.select_file((File)=>{
      if(this.uploading=='background'){
        this.cheque_background_image_.emit(File);
       }
    })
  }
  image_lib() { var w = 400, h = 400, q = this.sql.Purchased?93:92;
    if(this.uploading=='background'){ w = 605; h = 261; q = this.sql.Purchased?98:89; }
    this.sql.select_image((file)=>{
      if (file.length==0) {
          if(this.uploading=='background'){
            this.uploading='';
          } else if(this.uploading=='logo'){
            this.uploading='';
          } else if(this.uploading=='header'){
            this.uploading='';
          } else if(this.uploading=='bank'){
            this.uploading='';
          }
      } else {
        var base64_file='';
        if(file.type=='image/jpeg'||file.type=='image/jpg'){
          base64_file='data:image/jpeg;base64, '+file;
        } else {
          base64_file='data:image/png;base64, '+file;
        }
        if(this.uploading=='background'){
          this.cheque_background_color='transparent';
          this.sql.hapticsImpactLight();
          this.monochrome=false;
          this.background_color.emit(this.cheque_background_color);
          this.changeDetector.detectChanges();
          this.cheque_background_image=this.sanitizer.bypassSecurityTrustResourceUrl(base64_file);
          this.cheque_background_image_.emit(base64_file);  
          this.image_position()
          this.uploading='';
        } else if(this.uploading=='logo'){
          this.cheque_logo_image=this.sanitizer.bypassSecurityTrustResourceUrl(base64_file);
          this.cheque_logo_image_.emit(base64_file);  
          this.uploading='';
        } else if(this.uploading=='header'){
          this.cheque_header_image=this.sanitizer.bypassSecurityTrustResourceUrl(base64_file);
          this.cheque_header_image_.emit(base64_file);  
          this.uploading='';
        } else if(this.uploading=='bank'){
          this.cheque_bank_image=this.sanitizer.bypassSecurityTrustResourceUrl(base64_file);
          this.cheque_bank_image_.emit(base64_file);  
          this.uploading='';
        }
      }
    },w,h,q);
  }
  change_background_image_repeat() {
    this.background_repeat_prompt((img)=>{
      this.sql.hapticsImpactMedium();
      if(img=='repeat')this.cheque_background_image_repeat=true;
      if(img=='background')this.cheque_background_image_repeat=false;
      this.cheque_background_image_repeat_.emit(this.cheque_background_image_repeat);
    }); 
  }
  background_position_add() {
    if(this.background_position=='auto')this.background_position='0';
    this.sql.hapticsImpactMedium();
    this.background_position=String(this.background_position);
    if(!this.background_position)this.background_position='0';
    var m = this.background_position.replace(/[^0-9.-]/g, '');
    var s = this.background_position.replace(/[^a-zA-Z]+/g, '');
    var d = m=='0'?'5':Number(Number(m)+Number(5));
    this.background_position = ''+d+(s&&s!='undefined'&&s!=null&&s!=''&&s!=undefined?s:'');
    this.background_position_.emit(this.background_position);
  }
  background_position_subtract() {
    if(this.background_position=='auto')this.background_position='0';
    this.sql.hapticsImpactMedium();
    this.background_position=String(this.background_position);
    if(!this.background_position)this.background_position='0';
    var m = this.background_position.replace(/[^0-9.-]/g, '');
    var s = this.background_position.replace(/[^a-zA-Z]+/g, '');
    var d = m=='0'?'-5':Number(Number(m)-Number(5));
    this.background_position = ''+d+(s&&s!='undefined'&&s!=null&&s!=''&&s!=undefined?s:'');
    this.background_position_.emit(this.background_position);
  }
  custom_background_position() {
    this.sql.hapticsImpactLight();
    this.margin_prompt(this.sql.word.Background_Image+' '+this.sql.word.Position,(r)=>{
      if(r)this.background_position = r;
    },this.background_position);
    this.background_position_.emit(this.background_position);
  }
  background_position_reset() {
    this.sql.hapticsImpactLight();
    this.background_position = '0px';
    this.background_position_.emit(this.background_position);
  }
  set_background_position() {
    this.sql.hapticsImpactLight();
    this.ControlLevel=4.12;
  }
  toggle_background_repeat(img) {
    this.sql.hapticsImpactMedium();
    this.cheque_background_image_repeat=this.cheque_background_image_repeat?false:true;
    this.cheque_background_image_repeat_.emit(this.cheque_background_image_repeat);
  }
  clear_background_image() {
    this.cheque_background_image='0';
    this.cheque_background_image_.emit('0');  
  }
  clear_logo_image() {
    this.cheque_logo_image='0';
    this.cheque_logo_image_.emit('0');
  }
  clear_header_image(){
    this.cheque_header_image='0';
    this.cheque_header_image_.emit('0');
  }
  clear_bank_image() {   
    this.cheque_bank_image='0';
    this.cheque_bank_image_.emit('0');
  }
  print() {
    
  }
  adjust_secret() {

  }
  async adjust_margins() {
    const m = await this.navCtrl.create({
      component: PageMarginsPage,
      componentProps: {
        top: this.page_top,
        bottom: this.page_right,
        left: this.page_bottom,
        right: this.page_left,
        micr_bottom: this.micr_bottom,
        micr_left: this.micr_left,
        micr_right: this.micr_right,
        micr_top: this.micr_top,
        table_bottom: this.table_top,
        table_left: this.table_right,
        table_right: this.table_bottom,
        table_top: this.table_left,
        address_bottom: this.address_top,
        address_left: this.address_right,
        address_right: this.address_bottom,
        address_top: this.address_left
      }

    });
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      if(this.sql.verbose)console.log(a.data);
      if(a.data.top) {
        this.page_top=''+a.data.top;
        this.page_top_.emit(this.page_top);
      }
      if(a.data.bottom) {
        this.page_right=''+a.data.bottom;
        this.page_right_.emit(this.page_right);
      }
      if(a.data.left) {
        this.page_bottom=''+a.data.left
        this.page_bottom_.emit(this.page_bottom);
      }
      if(a.data.right) {
        this.page_left=''+a.data.right;
        this.page_left_.emit(this.page_left);
      }
      if(a.data.micr_bottom){
        this.micr_bottom=''+a.data.micr_bottom;
        this.micr_bottom_.emit(this.micr_bottom);
      }
      if(a.data.micr_left){
        this.micr_left=''+a.data.micr_left;
        this.micr_left_.emit(this.micr_left);
      }
      if(a.data.micr_right){
        this.micr_right=''+a.data.micr_right;
        this.micr_right_.emit(this.micr_right);
      }
      if(a.data.micr_top){
        this.micr_top=''+a.data.micr_top;
        this.micr_top_.emit(this.micr_top);
      }
      if(a.data.table_top) {
        this.table_top=''+a.data.table_top;
        this.table_top_.emit(this.table_top);
      }
      if(a.data.table_right) {
        this.table_right=''+a.data.table_right;
        this.table_right_.emit(this.table_right);
      }
      if(a.data.table_bottom) {
        this.table_bottom=''+a.data.table_bottom;
        this.table_bottom_.emit(this.table_bottom);
      }
      if(a.data.table_left) {
        this.table_left=''+a.data.table_left;
        this.table_left_.emit(this.table_left);
      }
      if(a.data.address_top) {
        this.address_top=''+a.data.address_top;
        this.address_top_.emit(this.address_top);
      }
      if(a.data.address_right) {
        this.address_right=''+a.data.address_right;
        this.address_right_.emit(this.address_right);
      }
      if(a.data.address_bottom) {
        this.address_bottom=''+a.data.address_bottom;
        this.address_bottom_.emit(this.address_bottom);
      }
      if(a.data.address_left) {
        this.address_left=''+a.data.address_left;
        this.address_left_.emit(this.address_left);
      }
      this.sql.hapticsImpactLight();
    }
  }
  toggle_voidcheck() {
    this.sql.hapticsImpactLight();
    this.voidcheck = this.voidcheck ? false : true;
    this.voidcheck_.emit(this.voidcheck);
  }
  toggle_layers() {
    this.sql.hapticsImpactLight();
    this.layers = this.layers ? false : true;
    this.set_micr(this.layers);
    this.set_numbers(this.layers);
    this.set_monochrome(this.layers?false:true)
    this.layers_.emit(this.layers);
  }
  toggle_gradients() {
    this.sql.hapticsImpactLight();
    this.gradients = this.gradients ? false : true;
    this.gradients_.emit(this.gradients); 
  }
  font_up() {
    this.sql.hapticsImpactLight();
    this.fontsize = Number(this.fontsize + 1);
    this.fontsize_.emit(this.fontsize);
  }
  font_reset() {
    this.sql.hapticsImpactLight();
    this.fontsize = Number(0);
    this.fontsize_.emit(this.fontsize);
  }
  font_down() {
    this.sql.hapticsImpactLight();
    this.fontsize = Number(this.fontsize - 1);
    this.fontsize_.emit(this.fontsize);
  }
  font_bold() {
    this.sql.hapticsImpactLight();
    this.fontweight = this.fontweight ? false : true;
    this.fontweight_.emit(this.fontweight);
  }
  font_italic() {
    this.sql.hapticsImpactLight();
    this.fontitalic = this.fontitalic ? false : true;
    this.fontitalic_.emit(this.fontitalic);
  }
  toggle_borders() {
    this.sql.hapticsImpactLight();
    this.borders = this.borders ? false : true;
    this.borders_.emit(this.borders);
  }
  borders_on() {
    this.sql.hapticsImpactLight();
    this.borders = true;
    this.borders_.emit(this.borders); 
  }
  toggle_guide() {
    this.sql.hapticsImpactLight();
    this.fold_line = this.fold_line ? false : true;
    this.fold_line_.emit(this.fold_line);
  }
  toggle_micr(){
    this.sql.hapticsImpactLight();
    this.micr = this.micr ? false : true;
    this.micr_.emit(this.micr);
  }
  set_micr(i=false){
    this.sql.hapticsImpactLight();
    this.micr = i;
    this.micr_.emit(this.micr);
  }
  toggle_numbers(){
    this.sql.hapticsImpactLight();
    this.numbers = this.numbers ? false : true;
    this.numbers_.emit(this.numbers);
  }
  set_numbers(i=false){
    this.sql.hapticsImpactLight();
    this.numbers = i;
    this.numbers_.emit(this.numbers);
  }
  toggle_monochrome(){ 
    this.sql.hapticsImpactLight();
      this.set_monochrome(this.monochrome?false:true);
  }
  set_monochrome(i=false){
    this.sql.hapticsImpactLight();
    this.monochrome = i;
    this.monochrome_.emit(this.monochrome);
    if(i==false){
      this.background_color.emit(this.cheque_background_color);
      this.changeDetector.detectChanges();
    }
  }
  toggle_date(){
    this.sql.hapticsImpactLight();
    this.date = this.date ? false : true;
    this.date_.emit(this.date);
  }
  toggle_RenderMICR(){
    this.sql.hapticsImpactLight();
    this.RenderMICR = this.RenderMICR ? false : true;
    this.RenderMICR_.emit(this.RenderMICR);
  }
  toggle_ImageMICR(){
    this.sql.hapticsImpactLight();
    this.ImageMICR = this.ImageMICR ? false : true;
    this.ImageMICR_.emit(this.ImageMICR);
  }
  set_ImageMICR(i=true){
    this.sql.hapticsImpactLight();
    if(!i)alert(this.sql.word.MICR_Warning);
    this.ImageMICR = i;
    this.ImageMICR_.emit(this.ImageMICR);
  }
  toggle_barcode(barcode) {
    this.sql.hapticsImpactLight();
    this[barcode+"_enabled"] = this[barcode+"_enabled"]?false:true;
  }
  barcode_text_prompt(barcode) {
    var text ='';
    if(text = prompt('Enter a Barcode')){
      this[barcode+"_text"] = text;
    } else {
      console.log('closed');
    }
  }
  cheque_secret(secret) {
    this.sql.hapticsImpactLight();
    this.secret = secret;
    this.Secret_.emit(this.secret);
  }
  c_() {
    if(this.timer_interval)clearInterval(this.timer_interval);
    if(this.margin_interval)clearInterval(this.margin_interval);
  }
  MICR_top_margin() {
    this.sql.hapticsImpactLight();
    this.margin_prompt(this.sql.word.MICR_Margin,(r)=>{
      if(r)this.micr_top = r;
    },this.micr_top);
    this.micr_top_.emit(this.micr_top);
  }
  MICR_top_margin_add(a?) {
    if(this.micr_top=='auto')this.micr_top='0';
    this.micr_bottom='auto';
    if(a)this.MICR_top_margin_add_held=true;
    this.sql.hapticsImpactMedium();
    this.micr_top=String(this.micr_top);
    if(!this.micr_top)this.micr_top='0';
    var m = this.micr_top.replace(/[^0-9.-]/g, '');
    var s = this.micr_top.replace(/[^a-zA-Z]+/g, '');
    var d = m=='0'?'1':Number(Number(m)+Number(1));
    this.micr_top = ''+d+(s&&s!='undefined'&&s!=null&&s!=''&&s!=undefined?s:'');
    this.micr_top_.emit(this.micr_top);
    this.micr_bottom_.emit(this.micr_bottom);
    if(!this.timer_interval)this.timer_interval=setTimeout(() => {
      this.margin_interval=setInterval(() => {
        if(!this.MICR_top_margin_add_held)clearInterval(this.margin_interval);
        if(this.MICR_top_margin_add_held)this.MICR_top_margin_add();
      },250);
    },1250);
  }
  MICR_top_margin_subtract(a?) {
    if(this.micr_top=='auto')this.micr_top='0';
    this.micr_bottom='auto';
    if(a)this.MICR_top_margin_subtract_held=true;
    this.sql.hapticsImpactMedium();
    this.micr_top=String(this.micr_top);
    if(!this.micr_top)this.micr_top='0';
    var m = this.micr_top.replace(/[^0-9.-]/g, '');
    var s = this.micr_top.replace(/[^a-zA-Z]+/g, '');
    var d = m=='0'?'-1':Number(Number(m)-Number(1));
    this.micr_top = ''+d+(s&&s!='undefined'&&s!=null&&s!=''&&s!=undefined?s:'');
    this.micr_top_.emit(this.micr_top);
    this.micr_bottom_.emit(this.micr_bottom);
    if(!this.timer_interval)this.timer_interval=setTimeout(()=>{
      this.margin_interval=setInterval(() => {
        if(!this.MICR_top_margin_subtract_held)clearInterval(this.margin_interval);
        if(this.MICR_top_margin_subtract_held)this.MICR_top_margin_subtract();
      },250);
    },1250);
  }
  MICR_right_margin() {
    this.sql.hapticsImpactMedium();
    this.margin_prompt(this.sql.word.MICR_Margin,(r)=>{
      if(r)this.micr_right = r;
    },this.micr_right);
    this.micr_right_.emit(this.micr_right);
  }
  MICR_right_margin_add(a?) {
    if(this.micr_right=='auto')this.micr_right='0';
    this.micr_left='auto';
    if(a)this.MICR_right_margin_add_held=true;
    this.sql.hapticsImpactMedium();
    this.micr_right=String(this.micr_right);
    if(!this.micr_right)this.micr_right='0';
    var m = this.micr_right.replace(/[^0-9.-]/g, '');
    var s = this.micr_right.replace(/[^a-zA-Z]+/g, '');
    var d = m=='0'?'1':Number(Number(m)+Number(1));
    this.micr_right = ''+d+(s&&s!='undefined'&&s!=null&&s!=''&&s!=undefined?s:'');
    this.micr_right_.emit(this.micr_right);
    this.micr_left_.emit(this.micr_left);
    if(!this.timer_interval)this.timer_interval=setTimeout(()=>{
      this.margin_interval=setInterval(() => {
        if(!this.MICR_right_margin_add_held)clearInterval(this.margin_interval);
        if(this.MICR_right_margin_add_held)this.MICR_right_margin_add();
      },250);
    },1250);
  }
  MICR_right_margin_subtract(a?) {
    if(this.micr_right=='auto')this.micr_right='0';
    this.micr_left='auto';
    if(a)this.MICR_right_margin_subtract_held=true;
    this.sql.hapticsImpactMedium();
    this.micr_right=String(this.micr_right);
    if(!this.micr_right)this.micr_right='0';
    var m = this.micr_right.replace(/[^0-9.-]/g, '');
    var s = this.micr_right.replace(/[^a-zA-Z]+/g, '');
    var d = m=='0'?'-1':Number(Number(m)-Number(1));
    this.micr_right = ''+d+(s&&s!='undefined'&&s!=null&&s!=''&&s!=undefined?s:'');
    this.micr_right_.emit(this.micr_right);
    this.micr_left_.emit(this.micr_left);
    if(!this.timer_interval)this.timer_interval=setTimeout(()=>{
      this.margin_interval=setInterval(() => {
        if(!this.MICR_right_margin_subtract_held)clearInterval(this.margin_interval);
        if(this.MICR_right_margin_subtract_held)this.MICR_right_margin_subtract();
      },250);
    },1250);
  }
  MICR_bottom_margin() {
    this.sql.hapticsImpactMedium();
    this.margin_prompt(this.sql.word.MICR_Margin,(r)=>{
      if(r)this.micr_bottom = r;
    },this.micr_bottom);
    this.micr_bottom_.emit(this.micr_bottom);
  }
  MICR_bottom_margin_add(a?) {
    if(this.micr_bottom=='auto')this.micr_bottom='0';
    this.micr_top='auto';
    if(a)this.MICR_bottom_margin_add_held=true;
    this.sql.hapticsImpactMedium();
    this.micr_bottom=String(this.micr_bottom);
    if(!this.micr_bottom)this.micr_bottom='0';
    var m = this.micr_bottom.replace(/[^0-9.-]/g, '');
    var s = this.micr_bottom.replace(/[^a-zA-Z]+/g, '');
    var d = m=='0'?'0.1':Number(Number(m)+Number(1));
    this.micr_bottom = ''+d+(s&&s!='undefined'&&s!=null&&s!=''&&s!=undefined?s:'');
    this.micr_bottom_.emit(this.micr_bottom);
    this.micr_top_.emit(this.micr_top);
    if(!this.timer_interval)this.timer_interval=setTimeout(()=>{
      this.margin_interval=setInterval(() => {
        if(!this.MICR_bottom_margin_add_held)clearInterval(this.margin_interval);
        if(this.MICR_bottom_margin_add_held)this.MICR_bottom_margin_add();
      },250);
    },1250);
  }
  MICR_bottom_margin_subtract(a?) {
    if(this.micr_bottom=='auto')this.micr_bottom='0';
    this.micr_top='auto';
    if(a)this.MICR_bottom_margin_subtract_held=true;
    this.sql.hapticsImpactMedium();
    this.micr_bottom=String(this.micr_bottom);
    if(!this.micr_bottom)this.micr_bottom='0';
    var m = this.micr_bottom.replace(/[^0-9.-]/g, '');
    var s = this.micr_bottom.replace(/[^a-zA-Z]+/g, '');
    var d = m=='0'?'-1':Number(Number(m)-Number(1));
    this.micr_bottom = ''+d+(s&&s!='undefined'&&s!=null&&s!=''&&s!=undefined?s:'');
    this.micr_bottom_.emit(this.micr_bottom);
    this.micr_top_.emit(this.micr_top);
    if(!this.timer_interval)this.timer_interval=setTimeout(()=>{
      this.margin_interval=setInterval(() => {
        if(!this.MICR_bottom_margin_subtract_held)clearInterval(this.margin_interval);
        if(this.MICR_bottom_margin_subtract_held)this.MICR_bottom_margin_subtract();
      },250);
    },1250);
  }
  MICR_left_margin() {
    this.sql.hapticsImpactMedium();
    this.margin_prompt(this.sql.word.MICR_Margin,(r)=>{
      if(r)this.micr_left = r;
    },this.micr_left);
    this.micr_left_.emit(this.micr_left);
  }
  MICR_left_margin_subtract(a?) {
    if(this.micr_left=='auto')this.micr_left='0';
    this.micr_right='auto';
    if(a)this.MICR_left_margin_subtract_held=true;
    this.sql.hapticsImpactMedium();
    this.micr_left=String(this.micr_left);
    if(!this.micr_left)this.micr_left='0';
    var m = this.micr_left.replace(/[^0-9.-]/g, '');
    var s = this.micr_left.replace(/[^a-zA-Z]+/g, '');
    var d = m=='0'?'1':Number(Number(m)+Number(1));
    this.micr_left = ''+d+(s&&s!='undefined'&&s!=null&&s!=''&&s!=undefined?s:'');
    this.micr_left_.emit(this.micr_left);
    this.micr_right_.emit(this.micr_right);
    if(!this.timer_interval)this.timer_interval=setTimeout(()=>{
      this.margin_interval=setInterval(() => {
        if(!this.MICR_left_margin_subtract_held)clearInterval(this.margin_interval);
        if(this.MICR_left_margin_subtract_held)this.MICR_left_margin_subtract();
      },250);
    },1250);
  }
  MICR_left_margin_add(a?) {
    if(this.micr_left=='auto')this.micr_left='0';
    this.micr_right='auto';
    if(a)this.MICR_left_margin_add_held=true;
    this.sql.hapticsImpactMedium();
    this.micr_left=String(this.micr_left);
    if(!this.micr_left)this.micr_left='0';
    var m = this.micr_left.replace(/[^0-9.-]/g, '');
    var s = this.micr_left.replace(/[^a-zA-Z]+/g, '');
    var d = m=='0'?'-1':Number(Number(m)-Number(1));
    this.micr_left = ''+d+(s&&s!='undefined'&&s!=null&&s!=''&&s!=undefined?s:'');
    this.micr_left_.emit(this.micr_left);
    this.micr_right_.emit(this.micr_right);
    if(!this.timer_interval)this.timer_interval=setTimeout(()=>{
      this.margin_interval=setInterval(() => {
        if(!this.MICR_left_margin_add_held)clearInterval(this.margin_interval);
        if(this.MICR_left_margin_add_held)this.MICR_left_margin_add();
      },250);
    },1250);
  }
  MICR_top_margin_reset() {
    this.sql.hapticsImpactMedium();
    this.micr_top='0';
    this.micr_top_.emit(this.micr_bottom);
  }
  MICR_right_margin_reset() {
    this.sql.hapticsImpactMedium();
    this.micr_right='0';
    this.micr_right_.emit(this.micr_bottom);
  }
  MICR_bottom_margin_reset() {
    this.sql.hapticsImpactMedium();
    this.micr_bottom='0';
    this.micr_bottom_.emit(this.micr_bottom);
  }
  MICR_left_margin_reet() {
    this.sql.hapticsImpactMedium();
    this.micr_left='0';
    this.micr_left_.emit(this.micr_bottom);
  }
  async margin_prompt(title=this.sql.word.Margins,cb?,inp?) {
    const alert = await this.alertController.create({
      header: title,
      buttons: [this.sql.word.Cancel,this.sql.word.Done],
      inputs: [
        {
          value:inp?inp:'',
          placeholder: this.sql.word.amount+' px/'+this.sql.word.Default_Printer_Unit+' '+this.sql.word.Unit,
          attributes: {type: 'text', autocapitalize: false, autocomplete: 'false', autocorrect: 'false'},
        }
      ],
    });
    await alert.present();
    let a = await alert.onDidDismiss();
    if (a.data) {
      if(cb)cb(a.data.values['0']);
    } else {if(cb)cb(false);}
  }
  barcode_position_prompt(barcode) {
    this.position_menu();
  }
  async position_menu(ev=null) {
    var menu = await this.popover.create({component: PositionMenuComponent,cssClass: 'dashboard-popover',event: ev,translucent: true});
    await menu.present();
    var role = await menu.onDidDismiss();
  } 
  async select_design() {
    for(let d of this.designs) {
      if(d.design_pattern==this.design)this.design_id=d.design_id;
    }
    const m = await this.navCtrl.create({
      component: SelectChequeDesignsPage,
      componentProps: { design_pattern:this.design_id }
    });
    await m.present();
    let a = await m.onDidDismiss();
    if (a.data) {
      this.design=a.data;
      this.design_.emit(this.design);
      this.sql.hapticsImpactLight();
      this.done();
    }
  }
  selected_design(d) {
    if(d && d.pro=='1' && this.sql.Trial){
      this.sql.show_purhchase_modal();
    } else {
      this.design=d.design_id;
      this.design_.emit(this.design);
    }
  }
  async fonts() {
    const m = await this.navCtrl.create({
      component: FontPickerComponent,
      componentProps: {font:this.cheque_font}
    });
    await m.present();
    let a = await m.onDidDismiss();
    if (a.data) this.select_font(a.data);
  }
  select_font(f) {
    this.sql.hapticsImpactLight();
    if(this.sql.verbose)console.log('Fonts', f);
    this.cheque_font=f;
    this.font.emit(this.cheque_font);
  }
  async background() {
    const m = await this.navCtrl.create({
      component: ColorPickerComponent,
      componentProps: {
        default:'#FFEFE2',
        mode:'swa',
        hex:this.cheque_background_color
      }
    });
    await m.present();
    let a = await m.onDidDismiss();
    if (a.data) this.select_color(a.data);
  }
  select_color(color) {
    this.sql.hapticsImpactLight();
    if(color) {
      if(this.pro) {
        this.select_text_color(color,1);
      } else {
        this.cheque_background_color=color;
        this.sql.hapticsImpactLight();
        this.monochrome_.emit(false);
        this.monochrome=false;
        this.background_color.emit(this.cheque_background_color);
        if(!this.docugard&&(!this.cheque_text_color||this.cheque_text_color=='#000000'||this.cheque_text_color=='#FFFFFF'||this.cheque_text_color=='#000'||this.cheque_text_color=='#FFF')) {
          this.set_text_contrast(color);
        }
        this.changeDetector.detectChanges();
      }
    } else {
      this.monochrome=true;
      this.monochrome_.emit(true);
    }
    this.borders = true;
    this.borders_.emit(this.borders);
  }
  async text() {
    const m = await this.navCtrl.create({
      component: ColorPickerComponent,
      componentProps: {
        default:'#000000',
        mode:'swa',
        hex:this.cheque_text_color?this.cheque_text_color:'#000000'
      }
    });
    await m.present();
    let a = await m.onDidDismiss();
    if (a.data) this.select_text_color(a.data);
  }
  select_text_color(color,h?) {
    if(!h)this.sql.hapticsImpactLight();
    if(color) {
      this.cheque_text_color=color;
      this.cheque_text_color_.emit(this.cheque_text_color);
    }
  }
  async select_paper() {
    const m = await this.navCtrl.create({
      component: SelectPaperPage, 
      componentProps: {
        printer:this.page_size,
        printer_width:this.page_width,
        printer_height:this.page_height,
        name:this.page_slug,
        width:this.page_width,
        height:this.page_height,
        rotate:this.rotate,
        monochrome:this.monochrome
      }
    });
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      if(this.sql.verbose)console.log('Paper picked: ',a.data);
      this.page_width = a.data.printer_width;
      this.page_height = a.data.printer_height;
      this.page_slug = a.data.slug;
      this.page_size = a.data.name;
      this.page_width = a.data.width;
      this.page_height = a.data.height;
      this.rotate = a.data.rotate;
      this.monochrome = a.data.monochrome;
      this.page_size_.emit(this.page_size); 
      this.page_slug_.emit(this.page_slug); 
      this.page_width_.emit(this.page_width); 
      this.page_height_.emit(this.page_height);
      this.monochrome_.emit(this.monochrome);
      this.rotate_.emit(this.rotate);
      this.sql.hapticsImpactLight();
    } else {
      if(this.sql.more_verbose)console.log(a.data,'No Paper Selected! Please check paper environment variables settings or select your page again.');
      this.sql.hapticsError();
    }
  }
  pattern() {

  }
  image() {

  }
  number_entry(title,subheader,cb?,max=2,amount=this.sql.word.Amount) {
    let number_entry = this.alertController.create({
      header:title,
      subHeader:subheader,
      inputs: [
        {name: 'number_entry',type: 'text', attributes:{placeholder: String(amount), maxlength:max, inputmode:"numeric"}},
      ],
      buttons: [
        { text: this.sql.word.Cancel, role: 'cancel', cssClass: 'secondary', handler: () => { 
          }
        },
        { text: this.sql.word.Confirm, handler: (a) => 
          {
            if(a.number_entry) {
              cb(a.number_entry);
            } else {
              cb(false);
            }
          }
        }
      ]
    }).then(res=>{
      res.present();
    })
  }
  async preview_cheque_background_image() {
    const m = await this.navCtrl.create({
      component: ImagePreviewComponent,
      componentProps: {
        trash:true,
        src:this.cheque_background_image
      }
    });
    await m.present();
    let a = await m.onDidDismiss();console.log(a);
    if(a.data==1){
      this.cheque_background_image='0';
      this.cheque_background_image_.emit('0');
    }
  }
  async preview_cheque_logo_image() {
    const m = await this.navCtrl.create({
      component: ImagePreviewComponent,
      componentProps: {
        trash:true,
        src:this.cheque_logo_image
      }
    });
    await m.present();
    let a = await m.onDidDismiss();console.log(a);
    if(a.data==1){
      this.cheque_logo_image='0';
      this.cheque_logo_image_.emit('0');
    }
  }
  async preview_cheque_header_image() {
    const m = await this.navCtrl.create({
      component: ImagePreviewComponent,
      componentProps: {
        trash:true,
        src:this.cheque_header_image
      }
    });
    await m.present();
    let a = await m.onDidDismiss();console.log(a);
    if(a.data==1){
      this.cheque_header_image='0';
      this.cheque_header_image_.emit('0');
    }
  }
  async preview_cheque_bank_image() {
    const m = await this.navCtrl.create({
      component: ImagePreviewComponent,
      componentProps: {
        trash:true,
        src:this.cheque_bank_image
      }
    });
    await m.present();
    let a = await m.onDidDismiss();console.log(a);
    if(a.data==1){
      this.cheque_bank_image='0';
      this.cheque_bank_image_.emit('0');
    }
  }
  done() {
    this.List = [];
    this.ControlLevel = 0;
  }
  get_designs(event?) {
    this.sql.fetch('Designs',(e)=>{
      this.designs=e;
    });
  }
  async background_repeat_prompt(cb?) {
    var items = {
      header: this.sql.word.Background_Image,
      subHeader: this.sql.word.Properties,
      buttons: [
        { text: this.sql.word.Background, role: 'background' },
        { text: this.sql.word.Repeat, role: 'repeat' },
        { text: this.sql.word.Cancel, role: 'cancel' },
      ],
    }
    const actionSheet = await this.actionSheetCtrl.create(items);
    await actionSheet.present();
    var result = await actionSheet.onDidDismiss();
    if(cb)cb(result.role);
  }
  async image_prompt(t=this.sql.word.Upload,s=this.sql.word.Edit,cb?) {
    var btnz = [
      {
        text: this.sql.word.Remove,
        role: 'destructive',
      },
      {
        text: this.sql.word.Upload_Image,
        role: 'image',
      },
      // {
      //   text: this.sql.word.Select_File,
      //   role: 'file',
      // },
      {
        text: this.sql.word.Cancel,
        role: 'cancel',
      },
    ];
    var items = {
      header: t,
      subHeader: s,
      buttons: btnz,
    }
    const actionSheet = await this.actionSheetCtrl.create(items);
    await actionSheet.present();
    var result = await actionSheet.onDidDismiss();
    if(cb)cb(result.role);
  }
  set_text_contrast(hexcolor) {
    if (hexcolor.slice(0, 1) === '#') {
      hexcolor = hexcolor.slice(1);
    }
    if (hexcolor.length === 3) {
      hexcolor = hexcolor.split('').map(function (hex) {
        return hex + hex;
      }).join('');
    }
    var r = parseInt(hexcolor.substr(0,2),16);
    var g = parseInt(hexcolor.substr(2,2),16);
    var b = parseInt(hexcolor.substr(4,2),16);
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    var color = (yiq >= 128) ? '#FFFFFF' : '#000000'; // @gomakethings.com
    this.select_text_color(color,1);
  }
  ngOnInit() {
    this.colors = DocugardColors;
    this.FontLibrary = fonts;
    this.get_designs();
    this.sql.get_setting('show_docu_title',(t)=>{
      if(t&&t.option)this.sql.show_docu_title = t.option;
    });
  }
}