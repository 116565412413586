import { Component, OnInit } from '@angular/core';
import { Papa } from "ngx-papaparse";
@Component({
  selector: 'app-view-csv',
  templateUrl: './view-csv.component.html',
  styleUrls: ['./view-csv.component.scss'],
})
export class ViewCsvComponent implements OnInit {
  constructor(private papa: Papa) { }
  data=[];
  headers=[];
  extractData(res: any) {
    const csvData = res || "";
    this.papa.parse(csvData, {
      complete: (parsedData) => {
        this.headers = parsedData.data.splice(0, 1)[0];
        this.data = parsedData.data;
        console.log("my array: ", this.data);
      }
    });
  }
  ngOnInit() {}

}
