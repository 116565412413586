import { SQLService } from '../../../services/sql.service';
import { Flip } from '../../../app.animations';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router,NavigationEnd,ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-select-import',
  templateUrl: './select-import-fields.page.html',
  styleUrls: ['./select-import-fields.page.styl'],
  animations: [ Flip ]
})
export class SelectImportFieldsPage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    public sql: SQLService,
    private location: Location
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_imports();
    });
  }
  id: number;
  count = -1;
  import_fields = [];
  output = new EventEmitter();
  search:string;
  filter(e) { this.search=e.target.value; }
  ngOnInit() {
    this.get_imports();
  }
  get_imports(event?) {
    this.sql.fetch('Imports',(e)=>{
      this.import_fields=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  select(e) { 
    if ( this.id ) {
      this.location.back();
    } else {
      this.navCtrl.dismiss(e);
    }
  }
  add() {
    this.router.navigate(['folder','Import','New']);
    this.navCtrl.dismiss();
  }
  off() {
    this.navCtrl.dismiss();
  }
}