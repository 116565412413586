import { environment } from '../../../../../environments/environment';
import { SQLService } from '../../../../services/sql.service';
import { NewImport } from '../../../../models/import';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
@Component({
  selector: 'ng-cheques-add-import',
  templateUrl: './add-import.component.html',
  styleUrls: ['./add-import.component.styl']
})
export class AddImportComponent implements OnInit, OnDestroy {
  constructor(
    public sql: SQLService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
  ) { 
    this.router.events.subscribe((eve) => {
      // if(eve instanceof NavigationEnd) this.open();
    });
  }
  _c = -1;
  ops =[];
  types = [
    'Addresses',
    'Administrators',
    'Bank_Accounts',
    'Cheques',
    'Deductions',
    'Employees',
    'Invoices',
    'Jobs',
    'Leaves_Of_Absence',
    'Moderators',
    'Payees',
    'Payments',
    'Payrolls',
    'Salaries',
    'Timesheets',
    'Taxes',
    'Vacations'
  ]
  options = {
    'Addresses': [
      'Name',
      'Address',
      'Address_2',
      'City',
      'State',
      'Postal',
      'Country' 
    ],
    'Administrators': [
      'Username',
      'Full_Name',
      'Date_of_Birth',
      'Email',
      'Phone',
      'Employee',
      'Address',
      'Payment_Limit',
      'Total_Limit',
      'Currency',
      'Description'
    ],
    'Bank_Accounts': [
      'Bank_Name',
      'Description',
      'Bank_Address',
      'Unit',
      'City}',
      'State',
      'Country',
      'ZIP',
      'Bank_Logo',
      'Account_Holder',
      'Starting_Cheque_ID',
      'Transit_Number',
      'Institution_Number',
      'Designation_Number',
      'Account_Number',
      'Currency',
      'Digital_Signature',
      'Payroll_Limit',
      'Payment_Limit',
      'Total_Limit'
    ],
    'Cheques': [
      'Name',
      'Amount',
      'Currency',
      'Date',
      'Memo',
      'Bank_Account',
      'Digital_Signature',
      'Address',
      'Address_2',
      'Address_Name',
      'Payee',
      'Employee',
      'Description',
      'Design'
    ],
    'Deductions': [
      'Title',
      'Description',
      'Amount',
      'Currency',
      'Frequency',
      'Perpetually_Enabled',
      'Start_Date',
      'End_Date',
    ],
    'Employees': [
      'Full_Name',
      'Email',
      'Phone',
      'Fax',
      'Description',
      'Address',
      'Salary',
      'Currency',
    ],
    'Invoices': [
      'Title',
      'Address',
      'Description',
      'Amount',
      'Currency',
      'Due_Date',
    ],
    'Jobs': [
      'Title',
      'Description',
      'Employees',
      'Salary',
      'Start_Date',
      'Start_Time',
      'End_Date',
      'End_Time',
    ],
    'Leaves_Of_Absence': [
      'Leave_Name',
      'Description',
      'Employee',
      'Payee',
      'Paid_Leave',
      'Bonus_Percentage',
      'Fixed_Amount',
      'Currency',
      'Schedule',
      'Start_Date',
      'End_Date',
    ],
    'Moderators': [
      'Username',
      'First_Name',
      'Last_Name',
      'Date_of_Birth',
      'Email',
      'Phone',
      'Employee',
      'Address',
      'Payment_Limit',
      'Total_Limit',
      'Currency',
      'Description',
      'Allow_Moderation',
      'Create_Paycheques',
      'Edit_Paycheques_and_Data',
      'Destroy_Data_and_Paycheques',
      'Day_Start_Time',
      'Day_End_Time',
    ],
    'Payees': [
      'Payee_Identifier',
      'Payee_Name',
      'Description',
      'Currency',
      'Payment_Memo',
      'Address',
      'Salary',
    ],
    'Payments': [
      'Title',
      'Description',
      'Name',
      'Employee',
      'Payee',
      'Address',
      'Amount',
      'Currency',
      'Bank_Account',
      'Memo',
      'Date',
      'Time',
      'Automatically_Process',
      'Mark_as_Paid',
    ],
    'Payrolls': [
      'Title',
      'Description',
      'Tabulate_Payments',
      'Bank_Account',
      'Currency',
      'Employees',
      'Payees',
      'Schedules',
      'Memo',
      'Frequency',
      'Repeat_Indefinitely',
      'Start_Date',
      'Start_Time',
      'End_Date',
      'End_Time'
    ],
    'Salaries': [
      'Salary_Name',
      'Description',
      'Salary_Amount',
      'Currency',
      'Frequency'
    ],
    'Timesheets': [
      'Title',
      'Description',
      'Employee',
      'Payee',
      'Start_Date',
      'Start_Time',
      'End_Date',
      'End_Time',
    ],
    'Taxes': [
      'Title',
      'Description',
      'Amount',
      'Currency',
      'Frequency',
      'Start_Date',
      'Perpetually_Enabled',
      'End_Date',
    ],
    'Vacations': [
      'Vacation_Name',
      'Description',
      'Employees',
      'Payees',
      'Paid_Vacation',
      'Bonus_Percentage',
      'Fixed_Amount',
      'Currency',
      'Repeat',
      'Indefinite_Leave',
      'Start_Date',
      'End_Date',
    ]
  };
  opts=[];
  spreadsheet = [];
  spreadsheet_metadata = [];
  currency = null;
  newimport = new NewImport();
  currencies = Object.keys(environment.currencies);
  save() {
    this.get_options();
    this.router.navigate(['folder','Import','Preview']);
    return;
    this.sql.add("Imports",this.newimport);
    this.newimport = new NewImport();
    this._c=0;
    this.end();
  }
  end() {
    if (this._c>0) {
      if(confirm(this.sql.word.Discard_Confirmation)) {
        this.newimport = new NewImport();
        this.router.navigate(['folder','Imports']);
      }
    } else { 
      this.router.navigate(['folder','Imports']);
    }
  }
  assign(s,o) {
    var si=[];
    for(let y of this.opts) {
      if(o!=y[0])si.push(y);
    }
    si.push([o,s]);
    this.opts=si;
  }
  get_options() {
    localStorage.setItem('import_type',this.newimport.type);
    var op = [],unlist=[];
    for(var o of this.opts){
      if(o[1]!='none'){
        op.push([o[0],o[1]]); 
      } else {
        unlist.push(o[0]);
      }
    }
    for(var o of this.options[this.newimport.type] ){
      for( let t of this.spreadsheet[0] ){
        if(t==o&&!unlist.includes(o)) op.push([o,t]);
      }
    }
    localStorage.setItem('import_settings',JSON.stringify(op))
    return op;
  }
  open() {
    this.get_import();
  }
  get_import() {
    let ida=this.route.snapshot.paramMap.get("id");
    if(!ida)return;
    if(!this.newimport.type){
      var import_type = localStorage.getItem('import_type');
      if(import_type){
        this.newimport.type=import_type;
      } else {
        this.newimport.type='Cheques'
      }
    }
    if(ida=='Temp'){
      var metadata = localStorage.getItem('spreadsheet_import_meta');
      var data = localStorage.getItem('spreadsheet_import');
      this.spreadsheet_metadata = JSON.parse(metadata);
      this.spreadsheet = JSON.parse(data);
      if(this.sql.verbose)console.log('Import Successful', this.spreadsheet);
      if(this.sql.verbose)console.log('Import Data', this.spreadsheet_metadata);
      localStorage.removeItem('spreadsheet_import_meta');
      localStorage.removeItem('spreadsheet_import');      
    }else{
      // this.sql.view('Imports','import_id',ida,(e)=>{
      //   var f=Object.entries(e[0]); let n = new NewImport();
      //   for(let t of f)n[t[0]]=t[1];
      //   this.newimport = n;
      // });
    }
  }
  backtoimport() { this.router.navigate(['folder','Import','New']); }
  ngOnInit() {
    this.open();
  }
  ngOnDestroy() {
    this.spreadsheet=[];
    this.opts=[];
  }
}