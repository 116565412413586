import { SQLService } from '../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { PopoverController} from '@ionic/angular';
import { DeductionMenuComponent } from './deduction-menu/deduction-menu.component';
@Component({
  selector: 'ng-cheques-deductions',
  templateUrl: './deductions.component.html',
  styleUrls: ['./deductions.component.styl']
})
export class DeductionsComponent implements OnInit {
  count = -1;
  deductions = [];
  search = '';
  constructor(
    public router: Router,
    public popoverController: PopoverController,
    public sql: SQLService) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_deductions();
    });
  }
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:DeductionMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='enable')this.enable(item);
      if(data=='disable')this.disable(item);
      if(data=='delete')this.delete_deduction(item);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  add() {this.router.navigate(['folder','Deduction','New']);}
  end() {this.router.navigate(['folder','Dashboard']);}
  filter(e) {this.search=e.target.value;}
  get_deductions(event?) {
    this.sql.fetch('Deductions',(e)=>{
      if(e)this.deductions=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  enable_disable(deduction,i?){
    if(deduction.enabled){
      this.disable(deduction,i);
    } else {
      this.enable(deduction,i);
    }
    if(i)i.closeSlidingItems();
  }
  disable(deduction,i?){
    this.sql.set('Deductions','deduction_id',deduction.deduction_id,'enabled',0,(res)=>{
      deduction.enabled = false;
       if(i)i.closeSlidingItems();
    });
  }
  enable(deduction,i?){
    this.sql.set('Deductions','deduction_id',deduction.deduction_id,'enabled',1,(res)=>{
      deduction.enabled = true;
       if(i)i.closeSlidingItems();
    });
  }
  edit_deduction(c?,i?) {
    this.router.navigate(['folder','Deduction',c.deduction_id]);
    if(i)i.closeSlidingItems();
  }
  delete_deduction(c?,i?) {
    if(confirm(this.sql.word.Delete_Deduction_Confirmation)) {
      this.sql.delete('Deductions','deduction_id',c.deduction_id);
      this.get_deductions();
    } else { if(i)i.closeSlidingItems(); }
  }
  ngOnInit() {
    this.get_deductions();
  }
}