import { SQLService } from './sql.service';
import { Injectable } from '@angular/core';
import { ethers } from 'ethers';
declare function get_address(name): any;
@Injectable()
export class CoinService {
  constructor(public sql: SQLService) {} 
  generate(symbol,cb?) {
    var w = {};
    if(symbol=='Ethereum') {
      const wallet=ethers.Wallet.createRandom();
      if(wallet&&wallet.address)w['public']=wallet.address;
      if(wallet&&wallet.privateKey)w['private']=wallet.privateKey;
    } else {
      w=get_address(symbol.toLowerCase());
    }
    if(cb)cb(w);
    return w;
  }
}