import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppInterceptor } from './app.interceptors';
import { SQLService } from './services/sql.service';
import { CoinService } from './services/coin.service';
import { PaymentsService } from './services/payments.service';
import { PayrollService } from './services/payroll.service';
import { TimesheetService } from './services/timesheet.service';
import { NotificationService } from './services/notification.service';
import { ChequeComponent } from './cheque/cheque.component';
import { ChartsComponent } from './charts/charts.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CustomizePage } from './dashboard/customize/customize.page';
import { PopoverComponent } from './dashboard/popover/popover.component';
import { IntroComponent } from './dashboard/intro/intro.component';
import { BonusesComponent } from './bonuses/bonuses.component';
import { NewBonusComponent } from './bonuses/new-bonus/new-bonus.component';
import { SelectBonusPage } from './bonuses/select-bonus/select-bonus.page';
import { IntroFirstPageComponent } from './dashboard/intro/pages/1-first/1-first.page';
import { IntroSecondPageComponent } from './dashboard/intro/pages/2-second/2-second.page';
import { IntroThirdPageComponent } from './dashboard/intro/pages/3-third/3-third.page';
import { IntroFourthPageComponent } from './dashboard/intro/pages/4-fourth/4-fourth.page';
import { IntroFithPageComponent } from './dashboard/intro/pages/5-fith/5-fith.page';
import { IntroSixthPageComponent } from './dashboard/intro/pages/6-sixth/6-sixth.page';
import { IntroSeventhPageComponent } from './dashboard/intro/pages/7-seventh/7-seventh.page';
import { IntroEighthPageComponent } from './dashboard/intro/pages/8-eighth/8-eighth.page';
import { IntroNinthPageComponent } from './dashboard/intro/pages/9-ninth/9-ninth.page';
import { PurchaseComponent } from './dashboard/purchase/purchase.component';
import { BitCoinPurchaseComponent } from './dashboard/purchase/bitcoin/bitcoin.component';
import { SplashComponent } from './dashboard/splash-screen/splash.component';
import { PrintChequesComponent } from './cheque/print-cheques/print-cheques.component';
import { GenerateInvoiceComponent } from './invoices/generate-invoices/generate-invoices.component';
import { GenerateChequeComponent } from './cheque/generate-cheque/generate-cheque.component';
import { GenerateMultipleChequesComponent } from './cheque/generate-cheque/multiple-cheques/multiple-cheques.component';
import { HTMLViewerComponent } from './cheque/generate-cheque/ng-html-viewer/ng-html-viewer.component';
import { ViewChequeComponent } from './cheque/generate-cheque/view-cheque.component';
import { SettingsComponent } from './settings/settings.component';
import { HolidaysComponent } from './settings/holidays/holidays.component';
import { NewHolidayComponent } from './settings/holidays/new-holiday/new-holiday.component';
import { SelectImportFieldsPage } from './settings/import/select-import-fields/select-import-fields.page';
import { NewImportComponent } from './settings/import/new-import/new-import.component';
import { PreviewImportFieldsPage } from './settings/import/new-import/preview-import/preview-field/preview-field.component';
import { FTPSettingsComponent } from './settings/ftp-settings/ftp-settings.component';
import { ChequeEntriesComponent } from './cheque/cheque-entries/cheque-entries.component';
import { NewChequeEntryComponent } from './cheque/cheque-entries/new-cheque-entry/new-cheque-entry.component';
import { ChequeEntryMenuComponent } from './cheque/cheque-entries/cheque-entry-menu/cheque-entry-menu.component';
import { NewPaymentEntryComponent } from './payments/payment-entries/new-payment-entry/new-payment-entry.component';
import { PaymentEntryMenuComponent } from './payments/payment-entries/payment-entry-menu/payment-entry-menu.component';
import { PaymentEntriesComponent } from './payments/payment-entries/payment-entries.component';
import { ChequeDesignerPalate } from './cheque/designs/cheque-designer/palate/cheque-palate.component';
import { ChequePropertiesComponent } from './cheque/designs/cheque-designer/properties/cheque-properties.component';
import { ChequeDesignerCanvasComponent } from './cheque/designs/cheque-designer/canvas/cheque-canvas.component';
import { BankAccountsComponent } from './settings/bank-accounts/bank-accounts.component';
import { ChequeDesignerComponent } from './cheque/designs/cheque-designer/cheque-designer.component';
import { ChequeDesignsComponent } from './cheque/designs/cheque-designs.component';
import { SelectChequeDesignsPage } from './cheque/designs/select-design/select-design.page';
import { AdministratorsComponent } from './settings/administrators/administrators.component';
import { DeductionsComponent } from './deductions/deductions.component';
import { EmployeesComponent } from './employees/employees.component';
import { LeavesComponent } from './leaves/leaves.component';
import { PayeesComponent } from './payees/payees.component';
import { AddressesComponent } from './addresses/addresses.component';
import { NewAddressPage } from './addresses/new-address/new-address.component';
import { SelectAddressPage } from './addresses/select-address/select-address.page';
import { SchedulesComponent } from './schedule/schedules.component';
import { NewSchedulePage } from './schedule/new-schedule/new-schedule.component';
import { ModeratorsComponent } from './settings/moderators/moderators.component';
import { NewModeratorComponent } from './settings/moderators/new-moderator/new-moderator.component';
import { SelectModeratorPage } from './settings/moderators/select-moderator/select-moderator.page';
import { SelectSchedulePage } from './schedule/select-schedule/select-schedule.page';
import { SelectPaperPage } from './cheque/designs/paper-picker/paper-picker.component';
import { PageMarginsPage } from './cheque/designs/page-margins/page-margins.component';
import { PaymentsComponent } from './payments/payments.component';
import { PayrollComponent } from './payroll/payroll.component';
import { SalaryComponent } from './salary/salary.component';
import { TimesheetComponent } from './timesheet/timesheet.component';
import { TaxesComponent } from './taxes/taxes.component';
import { VacationsComponent } from './vacations/vacations.component';
import { AddImportComponent } from './settings/import/new-import/add-import/add-import.component';
import { SubmitTimesheetComponent } from './timesheet/submit-timesheet/submit-timesheet.component';
import { NewBankAccountComponent } from './settings/bank-accounts/new-bank-account/new-bank-account.component';
import { JobsComponent } from './jobs/jobs.component';
import { NewJobComponent } from './jobs/new-job/new-job.component';
import { NewAdministratorComponent } from './settings/administrators/new-administrator/new-administrator.component';
import { SelectAdministratorPage } from './settings/administrators/select-administrator/select-administrator.page';
import { NewChequePage } from './cheque/new-cheque/new-cheque.component';
import { NewDeductionPage } from './deductions/new-deduction/new-deduction.component';
import { NewEmployeePage } from './employees/new-employee/new-employee.component';
import { InsuranceComponent } from './insurance/insurance.component';
import { NewInsurancePage } from './insurance/new-insurance/new-insurance.component';
import { NewInvoicePage } from './invoices/new-invoice/new-invoice.component';
import { NewLeavePage } from './leaves/new-leave/new-leave.component';
import { NewPaymentPage } from './payments/new-payment/new-payment.component';
import { NewPayrollPage } from './payroll/new-payroll/new-payroll.component';
import { PreviewPaymentFieldsPage } from './payroll/preview-payroll/preview-payment/preview-payment.component';
import { PreviewPayrollComponent } from './payroll/preview-payroll/preview-payroll.component';
import { NewSalaryPage } from './salary/new-salary/new-salary.component';
import { NewTaxPage } from './taxes/new-tax/new-tax.component';
import { ExportsComponent } from './settings/export/export.component';
import { NewExportComponent } from './settings/export/new-export/new-export.component';
import { AddExportComponent } from './settings/export/new-export/add-export/add-export.component';
import { NewTimesheetEntryComponent } from './timesheet/timesheet-entries/new-timesheet-entry/new-timesheet-entry.component';
import { TimesheetEntriesComponent } from './timesheet/timesheet-entries/timesheet-entries.component';
import { NewTimesheetPage } from './timesheet/new-timesheet/new-timesheet.component';
import { NewVacationPage } from './vacations/new-vacation/new-vacation.component';
import { SelectLeavesPage } from './leaves/select-leave/select-leave.page';
import { SelectDatePage } from './settings/select-date/select-date.component';
import { SelectHolidayPage } from './settings/holidays/select-holiday/select-holiday.page';
import { SelectDeductionPage } from './deductions/select-deduction/select-deduction.page';
import { SelectEmployeePage } from './employees/select-employee/select-employee.page';
import { SelectBankPage } from './settings/bank-accounts/select-bank/select-bank.page';
import { SelectSalaryPage } from './salary/select-salary/select-salary.page';
import { SelectTaxPage } from './taxes/select-tax/select-tax.page';
import { SelectJobPage } from './jobs/select-job/select-job.page';
import { IncomeComponent } from './income/income.component';
import { NewIncomeComponent } from './income/new-income/new-income.component';
import { SelectIncomeComponent } from './income/select-income/select-income.page';
import { NewPayeeComponent } from './payees/new-payee/new-payee.component'; 
import { SelectPayeePage } from './payees/select-payee/select-payee.page';
import { SelectInsurancePage } from './insurance/select-insurance/select-insurance.page';
import { PrintPayrollPage } from './payroll/print-payroll/print-payroll.component';
import { SelectBackupPage } from './settings/backup/select-backup/select-backup.page';
import { SelectBackupTypePage } from './settings/backup/select-backup-type/select-backup-type.page';
import { BackupSettingsComponent } from './settings/backup/backup-settings.component';
import { NewMySQLBackupComponent } from './settings/backup/new-mysql-backup/new-mysql-backup.component';
import { NewCloudBackupComponent } from './settings/backup/new-cloud-backup/new-cloud-backup.component';
import { NewFTPBackupComponent } from './settings/backup/new-ftp-backup/new-ftp-backup.component';
import { SelectSignaturePage } from './settings/select-signature/select-signature.page';
import { SignatureComponent } from './settings/signature-pad/signature-pad.component';
import { ViewSignatureComponent } from './settings/view-signature/view-signature.component';
import { PrintersComponent } from './settings/printers/printers.component';
import { NewPrinterComponent } from './settings/printers/new-printer/new-printer.component';
import { PrintJobsComponent } from './settings/printers/jobs/jobs.component';
import { ViewPrintJobComponent } from './settings/printers/view-printer/view-printer.component';
import { SelectPrintJobsPage } from './settings/printers/jobs/select-printjob/select-printjob.page';
import { ViewCsvComponent } from './settings/import/view-csv/view-csv.component';
import { NewFaxComponent } from './settings/faxes/new-fax/new-fax.component';
import { SelectFaxPage } from './settings/faxes/select-fax/select-fax.page';
import { FaxesComponent } from './settings/faxes/faxes.component';
import { PhoneComponent } from './settings/faxes/phone/phone.component';
import { DialpadComponent } from './settings/faxes/phone/dialpad/dialpad.component';
import { CallComponent } from './settings/faxes/phone/call/call.component';
import { AddressMenuComponent } from './addresses/address-menu/address-menu.component';
import { ChequeMenuComponent } from './cheque/cheque-menu/cheque-menu.component';
import { DeductionMenuComponent } from './deductions/deduction-menu/deduction-menu.component';
import { EmployeeMenuComponent } from './employees/employee-menu/employee-menu.component';
import { InvoiceMenuComponent } from './invoices/invoice-menu/invoice-menu.component';
import { JobMenuComponent } from './jobs/job-menu/job-menu.component';
import { LeaveMenuComponent } from './leaves/leave-menu/leave-menu.component';
import { PositionMenuComponent } from './cheque/designs/cheque-designer/palate/position-menu/position-menu.component';
import { PayeeMenuComponent } from './payees/payee-menu/payee-menu.component';
import { ExpensesMenuComponent } from './expenses/expenses-menu/expenses-menu.component';
import { PaymentsMenuComponent } from './payments/payment-menu/payment-menu.component';
import { PayrollMenuComponent } from './payroll/payroll-menu/payroll-menu.component';
import { SalaryMenuComponent } from './salary/salary-menu/salary-menu.component';
import { ScheduleMenuComponent } from './schedule/schedule-menu/schedule-menu.component';
import { AdministratiorMenuComponent } from './settings/administrators/administrator-menu/administrator-menu.component';
import { BackupMenuComponent } from './settings/backup/backup-menu/backup-menu.component';
import { BankMenuComponent } from './settings/bank-accounts/bank-menu/bank-menu.component';
import { DesignMenuComponent } from './cheque/designs/design-menu/design-menu.component';
import { DocumentsMenuComponent } from './settings/documents/documents-menu/documents-menu.component';
import { FaxMenuComponent } from './settings/faxes/fax-menu/fax-menu.component';
import { CallMenuComponent } from './settings/faxes/phone/call-menu/call-menu.component';
import { HolidayMenuComponent } from './settings/holidays/holiday-menu/holiday-menu.component';
import { ImportMenuComponent } from './settings/import/import-menu/import-menu.component';
import { ModeratorMenuComponent } from './settings/moderators/moderator-menu/moderator-menu.component';
import { PrinterMenuComponent } from './settings/printers/printer-menu/printer-menu.component';
import { PrintJobsMenuComponent } from './settings/printers/jobs/printjobs-menu/printjobs-menu.component';
import { InsuranceMenuComponent } from './insurance/insurance-menu/insurance-menu.component';
import { NewPaypalPaymentsComponent } from './settings/paypal-payments/new-paypal-payments/new-paypal-payments.component';
import { PayPalMenuComponent } from './settings/paypal-payments/paypal-menu/paypal-menu.component';
import { SelectPaypalPaymentsComponent } from './settings/paypal-payments/select-paypal-payments/select-paypal-payments.component';
import { PaypalPaymentsComponent } from './settings/paypal-payments/paypal-payments.component';
import { SendPayPalPage } from './payments/paypal-payment/paypal-payment.page';
import { PostageComponent } from './settings/postage/postage.component';
import { SelectPostageComponent } from './settings/postage/select-postage/select-postage.component';
import { PostageMenuComponent } from './settings/postage/postage-menu/postage-menu.component';
import { NewPostageComponent } from './settings/postage/new-postage/new-postage.component';
import { ListDepositsComponent } from './settings/deposit/list-deposits/list-deposits.component';
import { NewDepositComponent } from './settings/deposit/new-deposit/new-deposit.component';
import { ViewDepositComponent } from './settings/deposit/view-deposit/view-deposit.component';
import { BitcoinComponent } from './settings/deposit/bitcoin/bitcoin.component';
import { InteracComponent } from './settings/deposit/interac/interac.component';
import { PaypalComponent } from './settings/deposit/paypal/paypal.component';
import { TaxMenuComponent } from './taxes/tax-menu/tax-menu.component';
import { TimesheetMenuComponent } from './timesheet/timesheet-menu/timesheet-menu.component';
import { VacationsMenuComponent } from './vacations/vacations-menu/vacations-menu.component';
import { PrintingStatusbar } from './settings/printers/printing-statusbar/printing.statusbar';
import { ColorPickerComponent } from './cheque/designs/color-picker/color-picker.component';
import { FontPickerComponent } from './cheque/designs/font-picker/font-picker.component';
import { ImagePreviewComponent } from './cheque/designs/image-preview/image-preview.component';
import { KeysComponent } from './settings/keys/keys.component';
import { NewKeyComponent } from './settings/keys/new-key/new-key.component';
import { ScanKeyComponent } from './settings/keys/scan-key/scan-key.component';
import { MessagesComponent } from './settings/messages/messages.component';
import { NewMessageComponent } from './settings/messages/new-message/new-message.component';
import { DocugardMailerTopComponent } from './cheque/templates_html/docugard-mailer-top/docugard-mailer-top.component';
import { DocugardMailerMiddleComponent } from './cheque/templates_html/docugard-mailer-middle/docugard-mailer-middle.component';
import { DocugardMailerBottomComponent } from './cheque/templates_html/docugard-mailer-bottom/docugard-mailer-bottom.component';
import { DocugardTableTopComponent } from './cheque/templates_html/docugard-table-top/docugard-table-top.component';
import { DocugardTableMiddleComponent } from './cheque/templates_html/docugard-table-middle/docugard-table-middle.component';
import { DocugardTableBottomComponent } from './cheque/templates_html/docugard-table-bottom/docugard-table-bottom.component';
import { DocugardChequeTopComponent } from './cheque/templates_html/docugard-cheque-top/docugard-cheque-top.component';
import { DocugardChequeMiddleComponent } from './cheque/templates_html/docugard-cheque-middle/docugard-cheque-middle.component';
import { DocugardChequeBottomComponent } from './cheque/templates_html/docugard-cheque-bottom/docugard-cheque-bottom.component';
import { DocugardThreeChequesComponent } from './cheque/templates_html/docugard-three-cheques/docugard-three-cheques.component';
import { ChequeTableComponent } from './cheque/templates_html/cheque-table/cheque-table.component';
import { PostedChequeComponent } from './cheque/templates_html/posted-cheque/posted-cheque.component';
import { ThreeChequesComponent } from './cheque/templates_html/three-cheques/three-cheques.component';
import { PreviewImportComponent } from './settings/import/new-import/preview-import/preview-import.component';
import { SingleChequeComponent } from './cheque/templates_html/single-cheque/single-cheque.component';
import { PrintAddressComponent } from './addresses/print-address/print-address.component';
import { NewQRAdministratiorComponent } from './settings/administrators/new-qr-administrator/new-qr-administrator.component';
import { ScanAdministratiorComponent } from './settings/administrators/scan-administrator/scan-administrator.component';
import { NewQRModeratorComponent } from './settings/moderators/new-qr-moderator/new-qr-moderator.component';
import { ScanModeratorComponent } from './settings/moderators/scan-moderator/scan-moderator.component';
import { BiometricsComponent } from './dashboard/splash-screen/biometrics/biometrics.component';
import { GeolocationComponent } from './dashboard/splash-screen/geolocation/geolocation.component';
import { PinPadComponent } from './dashboard/splash-screen/pin-pad/pin-pad.component';
import { PasswordComponent } from './dashboard/splash-screen/password/password.component';
import { LoginComponent } from './dashboard/splash-screen/login/login.component';
import { PurchaseCompleteComponent } from './dashboard/purchase/purchase-complete/purchase-complete.component';
import { PopoverAccountsComponent } from './dashboard/popover-accounts/popover-accounts.component';
import { CompaniesComponent } from './settings/companies/companies.component';
import { NewCompanyComponent } from './settings/companies/new-company/new-company.component';
import { SelectCompanyComponent } from './settings/companies/select-company/select-company.component';
import { ChequeYellowComponent } from './cheque/templates_html/cheque-yellow/cheque-yellow.component';
import { ChequeRedComponent } from './cheque/templates_html/cheque-red/cheque-red.component';
import { ChequeStatueOfLibertyComponent } from './cheque/templates_html/cheque-statue-of-liberty/cheque-statue-of-liberty.component';
import { ChequeGreenWaveComponent } from './cheque/templates_html/cheque-green-wave/cheque-green-wave.component';
import { ChequeGreenSunComponent } from './cheque/templates_html/cheque-green-sun/cheque-green-sun.component';
import { ChequeGreenComponent } from './cheque/templates_html/cheque-green/cheque-green.component';
import { ChequeGoldenWeaveComponent } from './cheque/templates_html/cheque-golden-weave/cheque-golden-weave.component';
import { ChequeGoldenCheckersComponent } from './cheque/templates_html/cheque-golden-checkers/cheque-golden-checkers.component';
import { ChequeGoldenComponent } from './cheque/templates_html/cheque-golden/cheque-golden.component';
import { ChequeBlueCheckersComponent } from './cheque/templates_html/cheque-blue-checkers/cheque-blue-checkers.component';
import { ChequeAquaCheckersComponent } from './cheque/templates_html/cheque-aqua-checkers/cheque-aqua-checkers.component';
import { CashiersChequeComponent } from './cheque/templates_html/cashiers-cheque/cashiers-cheque.component';
import { DisclaimerComponent } from './settings/licences/disclaimer/disclaimer.component';
import { EULAComponent } from './settings/licences/eula/eula.component';
import { PrivacyPolicyComponent } from './settings/licences/privacy-policy/privacy-policy.component';
import { RefundsComponent } from './settings/licences/refunds/refunds.component';
import { TermsOfServiceComponent } from './settings/licences/terms-of-service/terms-of-service.component';
import { TermsOfUseComponent } from './settings/licences/terms-of-use/terms-of-use.component';
import { Broadcaster } from '@awesome-cordova-plugins/broadcaster/ngx';
import { ElectronService } from 'ngx-electron';
import { MyHammerConfig } from './hammer-config';
import { NamePipe } from './pipes/name.pipe';
import { ModePipe } from './pipes/mode.pipe';
import { PurchasedPipe } from './pipes/purchased.pipe';
import { AddressPipe } from './pipes/address.pipe';
import { ProPipe } from './pipes/pro.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { TimeAgoPipe } from './pipes/timeago.pipe';
import { Globalization } from '@ionic-native/globalization/ngx';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { IonicModule, IonicRouteStrategy,NavParams } from '@ionic/angular';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { SQLitePorter } from '@ionic-native/sqlite-porter/ngx';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClient, HttpParams, HttpHeaders, HttpHandler, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { File } from '@ionic-native/file/ngx';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx'; 
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { AES256 } from '@awesome-cordova-plugins/aes-256/ngx';
import { Downloader } from '@ionic-native/downloader/ngx';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { Printer, PrintOptions, HeaderFooterLabelOptions, FontOptions } from '../../pdfmake/printer/ngx';
import { Papa } from "ngx-papaparse";
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { CalendarModule } from 'ion2-calendar';
import { NgxPayPalModule } from 'ngx-paypal';
import { QRCodeModule } from 'angularx-qrcode';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { PinchZoomModule } from '../../plugins/ngx-pinch-zoom';
import { LongPressModule } from 'ionic-long-press';
import { CookieService } from 'ngx-cookie-service';
@NgModule({
  declarations: [
    AppComponent,
    ChartsComponent,
    ChequeComponent,
    SettingsComponent,
    FTPSettingsComponent,
    PrintAddressComponent,
    HolidaysComponent,
    SplashComponent,
    IntroComponent,
    IntroFirstPageComponent,
    IntroSecondPageComponent,
    IntroThirdPageComponent,
    IntroFourthPageComponent,
    IntroFithPageComponent,
    IntroSixthPageComponent,
    IntroSeventhPageComponent,
    IntroEighthPageComponent,
    IntroNinthPageComponent,
    PurchaseComponent,
    BitCoinPurchaseComponent,
    AddressesComponent,
    NewAddressPage,
    SchedulesComponent,
    NewSchedulePage,
    ModeratorsComponent,
    NewModeratorComponent,
    SelectModeratorPage,
    NewQRAdministratiorComponent,
	ScanAdministratiorComponent,
	NewQRModeratorComponent,
	ScanModeratorComponent,
	BiometricsComponent,
	GeolocationComponent,
	PinPadComponent,
	PasswordComponent,
	LoginComponent,
	PurchaseCompleteComponent,
	PopoverAccountsComponent,
	CompaniesComponent,
	NewCompanyComponent,
	SelectCompanyComponent,
    AdministratorsComponent,
    GenerateChequeComponent,
    GenerateMultipleChequesComponent,
    PrintChequesComponent,
    BankAccountsComponent,
    BonusesComponent,
    NewBonusComponent,
    SelectBonusPage,
    ChequeDesignerComponent,
    SelectChequeDesignsPage,
    ChequeDesignsComponent,
    DeductionsComponent,
    EmployeesComponent,
    LeavesComponent,
    PayeesComponent,
    PaymentsComponent,
    HTMLViewerComponent,
    GenerateInvoiceComponent,
    BackupSettingsComponent,
    SelectBackupPage,
    SelectBackupTypePage,
    NewMySQLBackupComponent,
    NewCloudBackupComponent,
    NewFTPBackupComponent,
    InvoicesComponent,
    PayrollComponent,
    SalaryComponent,
    PopoverComponent,
    TimesheetComponent,
    TaxesComponent,
    VacationsComponent,
    ViewChequeComponent,
    DashboardComponent,
	KeysComponent,
	NewKeyComponent,
	ScanKeyComponent,
    MessagesComponent,
    NewMessageComponent,
    JobsComponent,
    SelectJobPage,
    SelectBankPage,
    SelectLeavesPage,
    SelectTaxPage,
    SelectPayeePage,
    SelectInsurancePage,
    SelectSalaryPage,
    SelectAddressPage,
    SelectSchedulePage,
    SelectPaperPage,
    PageMarginsPage,
    SelectEmployeePage,
    SelectDeductionPage,
    SelectHolidayPage,
    SelectDatePage,
    NewJobComponent,
    NewAdministratorComponent,
    SelectAdministratorPage,
    NewChequePage,
    NewBankAccountComponent,
    NewDeductionPage,
    NewEmployeePage,
    NewInsurancePage,
    NewInvoicePage,
    NewLeavePage,
    NewPaymentPage,
    NewPayrollPage,
    PreviewPaymentFieldsPage,
	PreviewPayrollComponent,
    PrintPayrollPage,
    NewSalaryPage,
    NewTaxPage,
    NewTimesheetPage,
    NewTimesheetEntryComponent,
	TimesheetEntriesComponent,
    NewVacationPage,
    CustomizePage,
    NewPayeeComponent,
    NewHolidayComponent,
    SelectImportFieldsPage,
    SubmitTimesheetComponent,
    AddExportComponent,
    ExportsComponent,
	NewExportComponent,
    NewImportComponent,
    PreviewImportFieldsPage,
    AddImportComponent,
    ViewSignatureComponent,
    PrintersComponent,
    NewPrinterComponent,
    PrintJobsComponent,
    ViewPrintJobComponent,
    SelectPrintJobsPage,
    ViewCsvComponent,
    SelectSignaturePage,
    SignatureComponent,
    AddressMenuComponent,
    ChequeMenuComponent,
    DeductionMenuComponent,
    EmployeeMenuComponent,
    InvoiceMenuComponent,
    JobMenuComponent,
    LeaveMenuComponent,
    PayeeMenuComponent,
    ExpensesMenuComponent,
    PaymentsMenuComponent,
    NewPaymentEntryComponent,
	PaymentEntryMenuComponent,
	PaymentEntriesComponent,
    PayrollMenuComponent,
    PositionMenuComponent,
    SalaryMenuComponent,
    ScheduleMenuComponent,
    AdministratiorMenuComponent,
    BackupMenuComponent,
    BankMenuComponent,
    DesignMenuComponent,
    DocumentsMenuComponent,
    FaxMenuComponent,
    CallMenuComponent,
    HolidayMenuComponent,
    ImportMenuComponent,
    ModeratorMenuComponent,
    PrinterMenuComponent,
    PrintJobsMenuComponent,
    InsuranceMenuComponent,
    NewPaypalPaymentsComponent,
    PayPalMenuComponent,
    InsuranceComponent,
    ChequeDesignerPalate,
    ChequeEntriesComponent,
	NewChequeEntryComponent,
	ChequeEntryMenuComponent,
    ChequePropertiesComponent,
    ChequeDesignerCanvasComponent,
    SelectPaypalPaymentsComponent,
    SelectIncomeComponent,
	DisclaimerComponent,
	EULAComponent,
	PrivacyPolicyComponent,
	RefundsComponent,
	TermsOfServiceComponent,
	TermsOfUseComponent,
    NewIncomeComponent,
    IncomeComponent,
    PaypalPaymentsComponent,
    SendPayPalPage,
    PostageComponent,
	SelectPostageComponent,
	PostageMenuComponent,
	NewPostageComponent,
    TaxMenuComponent,
    TimesheetMenuComponent,
    VacationsMenuComponent,
    PrintingStatusbar,
    NewFaxComponent,
    SelectFaxPage,
    FaxesComponent,
    PhoneComponent,
    DialpadComponent,
    ColorPickerComponent,
    FontPickerComponent,
    ImagePreviewComponent,
    CallComponent,
    ListDepositsComponent,
    NewDepositComponent,
    ViewDepositComponent,
    BitcoinComponent,
    InteracComponent,
    PaypalComponent,
    ChequeYellowComponent,
	ChequeRedComponent,
	ChequeGreenComponent,
	ChequeGoldenComponent,
	ChequeStatueOfLibertyComponent,
	ChequeGreenWaveComponent,
	ChequeGreenSunComponent,
	ChequeGoldenWeaveComponent,
	ChequeGoldenCheckersComponent,
	ChequeGoldenComponent,
	ChequeBlueCheckersComponent,
	ChequeAquaCheckersComponent,
	CashiersChequeComponent,
    DocugardMailerTopComponent,
    DocugardMailerMiddleComponent,
    DocugardMailerBottomComponent,
    DocugardTableTopComponent,
    DocugardTableMiddleComponent,
    DocugardTableBottomComponent,
    DocugardChequeTopComponent,
    DocugardChequeMiddleComponent,
    DocugardChequeBottomComponent,
    DocugardThreeChequesComponent,
    ChequeTableComponent,
    PostedChequeComponent,
    ThreeChequesComponent,
    SingleChequeComponent,
    PreviewImportComponent,
    ProPipe,
    SortPipe,
    AddressPipe,
    TimeAgoPipe,
   	PurchasedPipe,
    ModePipe,
    NamePipe
  ],
  entryComponents: [],
  imports: [
  	CommonModule,
    BrowserModule,
    AppRoutingModule, 
    FormsModule,
    HttpClientModule,
    NgxPayPalModule,
    PinchZoomModule,
    QRCodeModule,
    LongPressModule,
    ZXingScannerModule,
    ReactiveFormsModule,
    BrowserAnimationsModule, 
    IonicModule.forRoot(),
    AngularSignaturePadModule,
    CalendarModule.forRoot({closeIcon: true}),
  ],
  providers: [
    SQLite,
    SQLService,
    CoinService,
    TimesheetService,
    NotificationService,
    PayrollService,
    PaymentsService,
    CookieService,
    ScreenOrientation,
    Broadcaster,
    CallNumber,
    AES256,
    HTTP,
    File,
    Papa,
    Printer,
    DatePipe,
    Downloader,
    SQLitePorter,
    UniqueDeviceID,
    InAppPurchase2,
    EmailComposer,
    Globalization,
    IOSFilePicker,
    FileChooser,
    FileOpener,
    ImagePicker,
    FingerprintAIO,
    Keyboard,
    NavParams,
    ElectronService,
    AppInterceptor,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
/**
 * Copyright 2023 * Author: Liam Jackson Hogan  * Date Created: September 21, 2022
**/