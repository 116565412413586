import { environment } from '../../../environments/environment';
import { SQLService } from '../../services/sql.service';
import { Component, ViewChild, HostListener, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SignaturePadComponent,NgSignaturePadOptions } from '@almothafar/angular-signature-pad';
import { ModalController } from '@ionic/angular';
import { NewSignature } from '../../models/signature';
@Component({
  selector: 'ng-cheques-signature',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.styl']
})
export class SignatureComponent implements AfterViewInit {
  constructor(
    public sql: SQLService,
    private router: Router,
    private location: Location,
    private navCtrl: ModalController,
    private route: ActivatedRoute,
  ) { 
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) {
        this.open();
      }
    });
  }
  id;
  image;
  _c=0;
  isMobile=false;
  showmenubutton: boolean;
  screenHeight: number;
  screenWidth: number;
  sig = new NewSignature();
  @ViewChild('SIGN') signaturePad: SignaturePadComponent;
  @ViewChild('conta') container: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.resizeScreen(event);
  } resizeScreen(e?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if ( this.screenWidth<=425) {
      this.isMobile = true;
    } else {
      this.isMobile = false; 
    }
  }
  private signaturePadOptions: NgSignaturePadOptions = {
    dotSize: 0.6,
    minWidth: 0.5,
    maxWidth: 1,
    canvasWidth: (this.isMobile)?375:800,
    canvasHeight: 165
  };
  drawComplete(event: MouseEvent | Touch) {
    this.image = this.signaturePad.toDataURL();
  }
  drawStart(event: MouseEvent | Touch) {
    this._c=1;
  }
  save() {
    this.sig.image = this.image;
    this.sql.add("Signatures",this.sig, (id) =>{
      this.sig.signature_id = id;
      this.navCtrl.dismiss(this.sig);
      this.sig = new NewSignature();
      this._c=0;  
    });
  }
  end(d?) {
    this.navCtrl.dismiss(d);
    this.sig = new NewSignature();
  }
  delete() { this.end('delete'); }
  clear() { this._c=0; this.signaturePad.clear(); }
  get_signature() {
    if(!this.id) return;
    this.sql.view('Signatures','signature_id',this.id,(e)=>{
      var f = Object.entries(e[0]); let n=new NewSignature();
      for(let t of f) n[t[0]]=t[1]; this.sig = n;
    });
  }
  open() {
    this.sig = new NewSignature();
    this.sig.date = new Date(Date.now()).toISOString();
    this.sig.enabled = true;
    this.get_signature();
    if(window.innerWidth>767)this.isMobile=true;
    this.signaturePadOptions = {
      dotSize: 0.6,
      minWidth: 0.5,
      maxWidth: 1,
      canvasWidth: (this.isMobile)?375:800,
      canvasHeight: 165
    };
  }
  ngAfterViewInit() {
    this.open();
  }
}