export class NewDesignObjects {
	constructor(
		public design_object_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public design_pattern?: string,
		public type?: string,
		public parent_object_id?: number,
		public top?: number,
		public right?: number,
		public bottom?: number,
		public left?: number,
		public text?: string,
		public width?: number,
		public height?: number,
		public background?: string,
		public color?: string,
		public text_align?: string,
		public font_family?: string,
		public font_size?: string,
		public font_weight?: string,
		public text_style?: string,
		public image?: string,
		public image_width?: number,
		public image_height?: number,
		public padding?: string,
		public padding_top?: string,
		public padding_right?: string,
		public padding_bottom?: string,
		public padding_left?: string,
		public border?: string,
		public border_color?: string,
		public border_top?: string,
		public border_right?: string,
		public border_bottom?: string,
		public border_left?: string,
		public float?: string,
		public text_shadow?: string,
		public box_shadow?: string,
		public scale?: string,
		public z_index?: number,
		public enabled?: boolean,
		public modified?: any,
		public created?: any
	){}
}
export const Create_DesignObjects = '\
	CREATE TABLE IF NOT EXISTS "DesignObjects" (\
		design_object_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		design_pattern TEXT,\
		type TEXT,\
		parent_object_id INTEGER,\
		background TEXT,\
		color TEXT,\
		width INTEGER,\
		height INTEGER,\
		top INTEGER,\
		right INTEGER,\
		bottom INTEGER,\
		left INTEGER,\
		text TEXT,\
		text_align TEXT,\
		font_family TEXT,\
		font_size TEXT,\
		font_weight TEXT,\
		text_style TEXT,\
		image TEXT,\
		image_width INTEGER,\
		image_height INTEGER,\
		padding TEXT,\
		padding_top TEXT,\
		padding_right TEXT,\
		padding_bottom TEXT,\
		padding_left TEXT,\
		border TEXT,\
		border_color TEXT,\
		border_top TEXT,\
		border_right TEXT,\
		border_bottom TEXT,\
		border_left TEXT,\
		float TEXT,\
		text_shadow TEXT,\
		box_shadow TEXT,\
		scale TEXT,\
		z_index INTEGER,\
		enabled INTEGER DEFAULT 0,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';