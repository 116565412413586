import { Component, OnInit, Input, ViewChild, HostListener,Output,EventEmitter } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { SQLService } from '../../../../services/sql.service';
import { page,fade,fade_in,display } from '../../../../app.animations';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { MenuController } from '@ionic/angular';
import { NewSalary } from '../../../../models/salary';
@Component({
  selector: 'app-intro-page-7',
  templateUrl: './7-seventh.page.html',
  styleUrls: ['./7-seventh.page.styl'],
})
export class IntroSeventhPageComponent implements OnInit {
  @Input('save') save:boolean
  @ViewChild('intro_slider') slider: IonSlides;
  @Output('complete') complete = new EventEmitter();
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
  }
  constructor(
    public menu: MenuController,
    public sql: SQLService,
    public router: Router) { 
    this.router.events.subscribe((val) => {
      // 
    });
  }
  ngOnInit() {}
/**/
}