import { SQLService } from '../../services/sql.service';
import { PurchaseComponent } from '../../dashboard/purchase/purchase.component';
import { NewSettings, PlaceHolders } from '../../models/settings';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-product-key',
  templateUrl: './product-key.component.html',
  styleUrls: ['./product-key.component.scss'],
})
export class ProductKeyComponent implements OnInit {

  constructor(
    public sql: SQLService,
    private navCtrl: ModalController) { }
  settings = new NewSettings(true);
  status='start';
  verson='';
  set:boolean;
  ngOnInit() {
    if(this.sql.Subscription==true||this.sql.Unlimited==true)this.set=true;
    this.status='start';
    this.verson=environment.version;
  }
  save() {
   this.navCtrl.dismiss(1);
  }
  end() {
   this.navCtrl.dismiss();
  }
  serialin(event) {
    if(event.which==8) {
      this.settings.serial_key = this.settings.serial_key.substr(0,this.settings.serial_key.length-1);
    } else {
      var s = this.settings.serial_key ? this.settings.serial_key.trim().replace(/-/g,"") : '';
      s=s+event.key;
      var parts=s.match(/.{1,4}/g);
      s=parts.join('-');
      this.settings.serial_key=s.toUpperCase();  
    }
  }
  serialpaste(event) {
      this.settings.serial_key = event.clipboardData.getData('text');
      var s = this.settings.serial_key ? this.settings.serial_key.trim().replace(/-/g,"") : '';
      var parts=s.match(/.{1,4}/g);s=parts.join('-');
      this.settings.serial_key=s.toUpperCase();
      this.check(true);
  }
  check(end?) {
    if(this.set)this.navCtrl.dismiss(1);
    if(this.settings.serial_key){
      this.sql.check_product_key(this.settings.serial_key,(res)=>{
        if(res) {
          this.set=true;
          if(end)this.navCtrl.dismiss(1);
        } else {
          this.set=false;
          if(end)this.navCtrl.dismiss();
        }
      });
    } else { alert(this.sql.word.Enter_Your_Licence_Key); }
  }
  async purchase() {
    setTimeout(() => { this.sql.hapticsImpactHeavy(); },440);
    const m = await this.navCtrl.create({component: PurchaseComponent, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      this.sql.hapticsImpactLight();
    }
  }
}
