export class NewInvoice {
	constructor(
		public invoice_id?: number,
		public title?: string,
		public number?: string,
		public description?: string,
		public amount?: number,
		public currency?: string,
		public address_ids?: any,
		public alwaysEnabled?: boolean,
		public addresses?: any,
		public date?: any,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Invoices = '\
	CREATE TABLE IF NOT EXISTS "Invoices" (\
		invoice_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		title TEXT,\
		number TEXT,\
		description TEXT,\
		amount TEXT,\
		currency TEXT,\
		address_ids TEXT,\
		addresses TEXT,\
		alwaysEnabled INTEGER DEFAULT 0,\
		date TIMESTAMP DEFAULT NULL,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';