export const colours = [
    {"name":"Black","color":"#000000"},
    {"name":"White","color":"#FFFFFF"},
    {"name":"Absolute Zero","color":"#0048BA"},
    {"name":"Acid green","color":"#B0BF1A"},
    {"name":"Aero","color":"#7CB9E8"},
    {"name":"Aero blue","color":"#C0E8D5"},
    {"name":"African violet","color":"#B284BE"},
    {"name":"Air superiority blue","color":"#72A0C1"},
    {"name":"Alabaster","color":"#EDEAE0"},
    {"name":"Alice blue","color":"#F0F8FF"},
    {"name":"Alloy orange","color":"#C46210"},
    {"name":"Almond","color":"#EFDECD"},
    {"name":"Amaranth","color":"#E52B50"},
    {"name":"Amaranth (M&P)","color":"#9F2B68"},
    {"name":"Amaranth pink","color":"#F19CBB"},
    {"name":"Amaranth purple","color":"#AB274F"},
    {"name":"Amaranth red","color":"#D3212D"},
    {"name":"Amazon","color":"#3B7A57"},
    {"name":"Amber","color":"#FFBF00"},
    {"name":"Amber (SAE/ECE)","color":"#FF7E00"},
    {"name":"Amethyst","color":"#9966CC"},
    {"name":"Android green","color":"#3DDC84"},
    {"name":"Antique brass","color":"#CD9575"},
    {"name":"Antique bronze","color":"#665D1E"},
    {"name":"Antique fuchsia","color":"#915C83"},
    {"name":"Antique ruby","color":"#841B2D"},
    {"name":"Antique white","color":"#FAEBD7"},
    {"name":"Ao (English)","color":"#008000"},
    {"name":"Apple green","color":"#8DB600"},
    {"name":"Apricot","color":"#FBCEB1"},
    {"name":"Aqua","color":"#00FFFF"},
    {"name":"Aquamarine","color":"#7FFFD4"},
    {"name":"Arctic lime","color":"#D0FF14"},
    {"name":"Army green","color":"#4B5320"},
    {"name":"Artichoke","color":"#8F9779"},
    {"name":"Arylide yellow","color":"#E9D66B"},
    {"name":"Ash gray","color":"#B2BEB5"},
    {"name":"Asparagus","color":"#87A96B"},
    {"name":"Atomic tangerine","color":"#FF9966"},
    {"name":"Auburn","color":"#A52A2A"},
    {"name":"Aureolin","color":"#FDEE00"},
    {"name":"Avocado ","color":"#568203"},
    {"name":"Azure","color":"#007FFF"},
    {"name":"Azure (X11/web color)","color":"#F0FFFF"},
    {"name":"Baby blue","color":"#89CFF0"},
    {"name":"Baby blue eyes","color":"#A1CAF1"},
    {"name":"Baby pink","color":"#F4C2C2"},
    {"name":"Baby powder","color":"#FEFEFA"},
    {"name":"Baker-Miller pink","color":"#FF91AF"},
    {"name":"Banana Mania","color":"#FAE7B5"},
    {"name":"Barbie Pink","color":"#DA1884"},
    {"name":"Barn red","color":"#7C0A02"},
    {"name":"Battleship grey","color":"#848482"},
    {"name":"Beau blue","color":"#BCD4E6"},
    {"name":"Beaver","color":"#9F8170"},
    {"name":"Beige","color":"#F5F5DC"},
    {"name":"B'dazzled blue","color":"#2E5894"},
    {"name":"Big dip o’ruby","color":"#9C2542"},
    {"name":"Bisque","color":"#FFE4C4"},
    {"name":"Bistre","color":"#3D2B1F"},
    {"name":"Bistre brown","color":"#967117"},
    {"name":"Bitter lemon","color":"#CAE00D"},
    {"name":"Bitter lime'","color":"#BFFF00"},
    {"name":"Bittersweet","color":"#FE6F5E"},
    {"name":"Bittersweet shimmer","color":"#BF4F51"},
    {"name":"Black","color":"#000000"},
    {"name":"Black bean","color":"#3D0C02"},
    {"name":"Black chocolate","color":"#1B1811"},
    {"name":"Black coffee","color":"#3B2F2F"},
    {"name":"Black coral","color":"#54626F"},
    {"name":"Black olive","color":"#3B3C36"},
    {"name":"Black Shadows","color":"#BFAFB2"},
    {"name":"Blanched almond","color":"#FFEBCD"},
    {"name":"Blast-off bronze","color":"#A57164"},
    {"name":"Bleu de France","color":"#318CE7"},
    {"name":"Blizzard blue","color":"#ACE5EE"},
    {"name":"Blond","color":"#FAF0BE"},
    {"name":"Blood red","color":"#660000"},
    {"name":"Blue","color":"#0000FF"},
    {"name":"Blue (Crayola)","color":"#1F75FE"},
    {"name":"Blue (Munsell)","color":"#0093AF"},
    {"name":"Blue (NCS)","color":"#0087BD"},
    {"name":"Blue (Pantone)","color":"#0018A8"},
    {"name":"Blue (pigment)","color":"#333399"},
    {"name":"Blue (RYB)","color":"#0247FE"},
    {"name":"Blue bell","color":"#A2A2D0"},
    {"name":"Blue-gray","color":"#6699CC"},
    {"name":"Blue-green","color":"#0D98BA"},
    {"name":"Blue-green (color wheel)","color":"#064E40"},
    {"name":"Blue jeans","color":"#5DADEC"},
    {"name":"Blue sapphire","color":"#126180"},
    {"name":"Blue-violet","color":"#8A2BE2"},
    {"name":"Blue-violet (Crayola)","color":"#7366BD"},
    {"name":"Blue-violet (color wheel)","color":"#4D1A7F"},
    {"name":"Blue yonder","color":"#5072A7"},
    {"name":"Bluetiful","color":"#3C69E7"},
    {"name":"Blush","color":"#DE5D83"},
    {"name":"Bole","color":"#79443B"},
    {"name":"Bone","color":"#E3DAC9"},
    {"name":"Bottle green","color":"#006A4E"},
    {"name":"Brandy","color":"#87413F"},
    {"name":"Brick red","color":"#CB4154"},
    {"name":"Bright green","color":"#66FF00"},
    {"name":"Bright lilac","color":"#D891EF"},
    {"name":"Bright maroon","color":"#C32148"},
    {"name":"Bright navy blue","color":"#1974D2"},
    {"name":"Bright yellow (Crayola)","color":"#FFAA1D"},
    {"name":"Brilliant rose","color":"#FF55A3"},
    {"name":"Brink pink","color":"#FB607F"},
    {"name":"British racing green","color":"#004225"},
    {"name":"Bronze","color":"#CD7F32"},
    {"name":"Brown","color":"#88540B"},
    {"name":"Brown sugar","color":"#AF6E4D"},
    {"name":"Brunswick green","color":"#1B4D3E"},
    {"name":"Bud green","color":"#7BB661"},
    {"name":"Buff","color":"#FFC680"},
    {"name":"Burgundy","color":"#800020"},
    {"name":"Burlywood","color":"#DEB887"},
    {"name":"Burnished brown","color":"#A17A74"},
    {"name":"Burnt orange","color":"#CC5500"},
    {"name":"Burnt sienna","color":"#E97451"},
    {"name":"Burnt umber","color":"#8A3324"},
    {"name":"Byzantine","color":"#BD33A4"},
    {"name":"Byzantium","color":"#702963"},
    {"name":"Cadet","color":"#536872"},
    {"name":"Cadet blue","color":"#5F9EA0"},
    {"name":"Cadet blue (Crayola)","color":"#A9B2C3"},
    {"name":"Cadet grey","color":"#91A3B0"},
    {"name":"Cadmium green","color":"#006B3C"},
    {"name":"Cadmium orange","color":"#ED872D"},
    {"name":"Cadmium red","color":"#E30022"},
    {"name":"Cadmium yellow","color":"#FFF600"},
    {"name":"Café au lait","color":"#A67B5B"},
    {"name":"Café noir","color":"#4B3621"},
    {"name":"Cambridge blue","color":"#A3C1AD"},
    {"name":"Camel","color":"#C19A6B"},
    {"name":"Cameo pink","color":"#EFBBCC"},
    {"name":"Canary","color":"#FFFF99"},
    {"name":"Canary yellow","color":"#FFEF00"},
    {"name":"Candy apple red","color":"#FF0800"},
    {"name":"Candy pink","color":"#E4717A"},
    {"name":"Capri","color":"#00BFFF"},
    {"name":"Caput mortuum","color":"#592720"},
    {"name":"Cardinal","color":"#C41E3A"},
    {"name":"Caribbean green","color":"#00CC99"},
    {"name":"Carmine","color":"#960018"},
    {"name":"Carmine (M&P)","color":"#D70040"},
    {"name":"Carnation pink","color":"#FFA6C9"},
    {"name":"Carnelian","color":"#B31B1B"},
    {"name":"Carolina blue","color":"#56A0D3"},
    {"name":"Carrot orange","color":"#ED9121"},
    {"name":"Castleton green","color":"#00563F"},
    {"name":"Catawba","color":"#703642"},
    {"name":"Cedar Chest","color":"#C95A49"},
    {"name":"Celadon","color":"#ACE1AF"},
    {"name":"Celadon blue","color":"#007BA7"},
    {"name":"Celadon green","color":"#2F847C"},
    {"name":"Celeste","color":"#B2FFFF"},
    {"name":"Celtic blue","color":"#246BCE"},
    {"name":"Cerise","color":"#DE3163"},
    {"name":"Cerulean","color":"#007BA7"},
    {"name":"Cerulean blue","color":"#2A52BE"},
    {"name":"Cerulean frost","color":"#6D9BC3"},
    {"name":"Cerulean (Crayola)","color":"#1DACD6"},
    {"name":"CG blue","color":"#007AA5"},
    {"name":"CG red","color":"#E03C31"},
    {"name":"Champagne","color":"#F7E7CE"},
    {"name":"Champagne pink","color":"#F1DDCF"},
    {"name":"Charcoal","color":"#36454F"},
    {"name":"Charleston green","color":"#232B2B"},
    {"name":"Charm pink","color":"#E68FAC"},
    {"name":"Chartreuse (traditional)","color":"#DFFF00"},
    {"name":"Chartreuse (web)","color":"#7FFF00"},
    {"name":"Cherry blossom pink","color":"#FFB7C5"},
    {"name":"Chestnut","color":"#954535"},
    {"name":"Chili red","color":"#E23D28"},
    {"name":"China pink","color":"#DE6FA1"},
    {"name":"China rose","color":"#A8516E"},
    {"name":"Chinese red","color":"#AA381E"},
    {"name":"Chinese violet","color":"#856088"},
    {"name":"Chinese yellow","color":"#FFB200"},
    {"name":"Chocolate (traditional)","color":"#7B3F00"},
    {"name":"Chocolate (web)","color":"#D2691E"},
    {"name":"Chocolate Cosmos","color":"#58111A"},
    {"name":"Chrome yellow","color":"#FFA700"},
    {"name":"Cinereous","color":"#98817B"},
    {"name":"Cinnabar","color":"#E34234"},
    {"name":"Cinnamon Satin","color":"#CD607E"},
    {"name":"Citrine","color":"#E4D00A"},
    {"name":"Citron","color":"#9FA91F"},
    {"name":"Claret","color":"#7F1734"},
    {"name":"Cobalt blue","color":"#0047AB"},
    {"name":"Cocoa brown","color":"#D2691E"},
    {"name":"Coffee","color":"#6F4E37"},
    {"name":"Columbia Blue","color":"#B9D9EB"},
    {"name":"Congo pink","color":"#F88379"},
    {"name":"Cool grey","color":"#8C92AC"},
    {"name":"Copper","color":"#B87333"},
    {"name":"Copper (Crayola)","color":"#DA8A67"},
    {"name":"Copper penny","color":"#AD6F69"},
    {"name":"Copper red","color":"#CB6D51"},
    {"name":"Copper rose","color":"#996666"},
    {"name":"Coquelicot","color":"#FF3800"},
    {"name":"Coral","color":"#FF7F50"},
    {"name":"Coral pink","color":"#F88379"},
    {"name":"Cordovan","color":"#893F45"},
    {"name":"Corn","color":"#FBEC5D"},
    {"name":"Cornell red","color":"#B31B1B"},
    {"name":"Cornflower blue","color":"#6495ED"},
    {"name":"Cornsilk","color":"#FFF8DC"},
    {"name":"Cosmic cobalt","color":"#2E2D88"},
    {"name":"Cosmic latte","color":"#FFF8E7"},
    {"name":"Coyote brown","color":"#81613C"},
    {"name":"Cotton candy","color":"#FFBCD9"},
    {"name":"Cream","color":"#FFFDD0"},
    {"name":"Crimson","color":"#DC143C"},
    {"name":"Crimson (UA)","color":"#9E1B32"},
    {"name":"Crystal","color":"#A7D8DE"},
    {"name":"Cultured","color":"#F5F5F5"},
    {"name":"Cyan","color":"#00FFFF"},
    {"name":"Cyan (process)","color":"#00B7EB"},
    {"name":"Cyber grape","color":"#58427C"},
    {"name":"Cyber yellow","color":"#FFD300"},
    {"name":"Cyclamen","color":"#F56FA1"},
    {"name":"Dark blue-gray","color":"#666699"},
    {"name":"Dark brown","color":"#654321"},
    {"name":"Dark byzantium","color":"#5D3954"},
    {"name":"Dark cornflower blue","color":"#26428B"},
    {"name":"Dark cyan","color":"#008B8B"},
    {"name":"Dark electric blue","color":"#536878"},
    {"name":"Dark goldenrod","color":"#B8860B"},
    {"name":"Dark green","color":"#013220"},
    {"name":"Dark green (X11)","color":"#006400"},
    {"name":"Dark jungle green","color":"#1A2421"},
    {"name":"Dark khaki","color":"#BDB76B"},
    {"name":"Dark lava","color":"#483C32"},
    {"name":"Dark liver","color":"#534B4F"},
    {"name":"Dark liver (horses)","color":"#543D37"},
    {"name":"Dark magenta","color":"#8B008B"},
    {"name":"Dark moss green","color":"#4A5D23"},
    {"name":"Dark olive green","color":"#556B2F"},
    {"name":"Dark orange","color":"#FF8C00"},
    {"name":"Dark orchid","color":"#9932CC"},
    {"name":"Dark pastel green","color":"#03C03C"},
    {"name":"Dark purple","color":"#301934"},
    {"name":"Dark red","color":"#8B0000"},
    {"name":"Dark salmon","color":"#E9967A"},
    {"name":"Dark sea green","color":"#8FBC8F"},
    {"name":"Dark sienna","color":"#3C1414"},
    {"name":"Dark sky blue","color":"#8CBED6"},
    {"name":"Dark slate blue","color":"#483D8B"},
    {"name":"Dark slate gray","color":"#2F4F4F"},
    {"name":"Dark spring green","color":"#177245"},
    {"name":"Dark turquoise","color":"#00CED1"},
    {"name":"Dark violet","color":"#9400D3"},
    {"name":"Dartmouth green","color":"#00703C"},
    {"name":"Davy's grey","color":"#555555"},
    {"name":"Deep cerise","color":"#DA3287"},
    {"name":"Deep champagne","color":"#FAD6A5"},
    {"name":"Deep chestnut","color":"#B94E48"},
    {"name":"Deep jungle green","color":"#004B49"},
    {"name":"Deep pink","color":"#FF1493"},
    {"name":"Deep saffron","color":"#FF9933"},
    {"name":"Deep sky blue","color":"#00BFFF"},
    {"name":"Deep Space Sparkle","color":"#4A646C"},
    {"name":"Deep taupe","color":"#7E5E60"},
    {"name":"Denim","color":"#1560BD"},
    {"name":"Denim blue","color":"#2243B6"},
    {"name":"Desert","color":"#C19A6B"},
    {"name":"Desert sand","color":"#EDC9AF"},
    {"name":"Dim gray","color":"#696969"},
    {"name":"Dodger blue","color":"#1E90FF"},
    {"name":"Dogwood rose","color":"#D71868"},
    {"name":"Drab","color":"#967117"},
    {"name":"Duke blue","color":"#00009C"},
    {"name":"Dutch white","color":"#EFDFBB"},
    {"name":"Earth yellow","color":"#E1A95F"},
    {"name":"Ebony","color":"#555D50"},
    {"name":"Ecru","color":"#C2B280"},
    {"name":"Eerie black","color":"#1B1B1B"},
    {"name":"Eggplant","color":"#614051"},
    {"name":"Eggshell","color":"#F0EAD6"},
    {"name":"Egyptian blue","color":"#1034A6"},
    {"name":"Eigengrau","color":"#16161D"},
    {"name":"Electric blue","color":"#7DF9FF"},
    {"name":"Electric green","color":"#00FF00"},
    {"name":"Electric indigo","color":"#6F00FF"},
    {"name":"Electric lime","color":"#CCFF00"},
    {"name":"Electric purple","color":"#BF00FF"},
    {"name":"Electric violet","color":"#8F00FF"},
    {"name":"Emerald","color":"#50C878"},
    {"name":"Eminence","color":"#6C3082"},
    {"name":"English green","color":"#1B4D3E"},
    {"name":"English lavender","color":"#B48395"},
    {"name":"English red","color":"#AB4B52"},
    {"name":"English vermillion","color":"#CC474B"},
    {"name":"English violet","color":"#563C5C"},
    {"name":"Erin","color":"#00FF40"},
    {"name":"Eton blue","color":"#96C8A2"},
    {"name":"Fallow","color":"#C19A6B"},
    {"name":"Falu red","color":"#801818"},
    {"name":"Fandango","color":"#B53389"},
    {"name":"Fandango pink","color":"#DE5285"},
    {"name":"Fashion fuchsia","color":"#F400A1"},
    {"name":"Fawn","color":"#E5AA70"},
    {"name":"Feldgrau","color":"#4D5D53"},
    {"name":"Fern green","color":"#4F7942"},
    {"name":"Field drab","color":"#6C541E"},
    {"name":"Fiery rose","color":"#FF5470"},
    {"name":"Firebrick","color":"#B22222"},
    {"name":"Fire engine red ","color":"#CE2029"},
    {"name":"Fire opal","color":"#E95C4B"},
    {"name":"Flame","color":"#E25822"},
    {"name":"Flax","color":"#EEDC82"},
    {"name":"Flirt","color":"#A2006D"},
    {"name":"Floral white","color":"#FFFAF0"},
    {"name":"Fluorescent blue","color":"#15F4EE"},
    {"name":"Forest green (Crayola)","color":"#5FA777"},
    {"name":"Forest green (traditional)","color":"#014421"},
    {"name":"Forest green (web)","color":"#228B22"},
    {"name":"French beige","color":"#A67B5B"},
    {"name":"French bistre","color":"#856D4D"},
    {"name":"French blue","color":"#0072BB"},
    {"name":"French fuchsia","color":"#FD3F92"},
    {"name":"French lilac","color":"#86608E"},
    {"name":"French lime","color":"#9EFD38"},
    {"name":"French mauve","color":"#D473D4"},
    {"name":"French pink","color":"#FD6C9E"},
    {"name":"French raspberry","color":"#C72C48"},
    {"name":"French rose","color":"#F64A8A"},
    {"name":"French sky blue","color":"#77B5FE"},
    {"name":"French violet","color":"#8806CE"},
    {"name":"Frostbite","color":"#E936A7"},
    {"name":"Fuchsia","color":"#FF00FF"},
    {"name":"Fuchsia (Crayola)","color":"#C154C1"},
    {"name":"Fuchsia purple","color":"#CC397B"},
    {"name":"Fuchsia rose","color":"#C74375"},
    {"name":"Fulvous ","color":"#E48400"},
    {"name":"Fuzzy Wuzzy","color":"#87421F"},
    {"name":"Gainsboro", "color":"#DCDCDC"},
    {"name":"Gamboge", "color":"#E49B0F"},
    {"name":"Generic viridian", "color":"#007F66"},
    {"name":"Ghost white", "color":"#F8F8FF"},
    {"name":"Glaucous", "color":"#6082B6"},
    {"name":"Glossy grape", "color":"#AB92B3"},
    {"name":"GO green", "color":"#00AB66"},
    {"name":"Gold", "color":"#A57C00"},
    {"name":"Gold (metallic)", "color":"#D4AF37"},
    {"name":"Gold (web) (Golden)", "color":"#FFD700"},
    {"name":"Gold (Crayola)", "color":"#E6BE8A"},
    {"name":"Gold Fusion", "color":"#85754E"},
    {"name":"Golden brown", "color":"#996515"},
    {"name":"Golden poppy", "color":"#FCC200"},
    {"name":"Golden yellow", "color":"#FFDF00"},
    {"name":"Goldenrod", "color":"#DAA520"},
    {"name":"Gotham green  " ,"color":"#00573F"},
    {"name":"Granite gray", "color":"#676767"},
    {"name":"Granny Smith apple", "color":"#A8E4A0"},
    {"name":"Gray (web)", "color":"#808080"},
    {"name":"Gray (X11 gray)", "color":"#BEBEBE"},
    {"name":"Green", "color":"#00FF00"},
    {"name":"Green (Crayola)", "color":"#1CAC78"},
    {"name":"Green (web)", "color":"#008000"},
    {"name":"Green (Munsell)", "color":"#00A877"},
    {"name":"Green (NCS)", "color":"#009F6B"},
    {"name":"Green (Pantone)", "color":"#00AD43"},
    {"name":"Green (pigment)", "color":"#00A550"},
    {"name":"Green (RYB)", "color":"#66B032"},
    {"name":"Green-blue", "color":"#1164B4"},
    {"name":"Green-blue (Crayola)", "color":"#2887C8"},
    {"name":"Green-cyan", "color":"#009966"},
    {"name":"Green Lizard", "color":"#A7F432"},
    {"name":"Green Sheen", "color":"#6EAEA1"},
    {"name":"Green-yellow", "color":"#ADFF2F"},
    {"name":"Green-yellow (Crayola)", "color":"#F0E891"},
    {"name":"Grullo", "color":"#A99A86"},
    {"name":"Gunmetal", "color":"#2a3439"},
    {"name":"Han blue", "color":"#446CCF"},
    {"name":"Han purple", "color":"#5218FA"},
    {"name":"Hansa yellow", "color":"#E9D66B"},
    {"name":"Harlequin", "color":"#3FFF00"},
    {"name":"Harvest gold", "color":"#DA9100"},
    {"name":"Heat Wave", "color":"#FF7A00"},
    {"name":"Heliotrope", "color":"#DF73FF"},
    {"name":"Heliotrope gray", "color":"#AA98A9"},
    {"name":"Hollywood cerise", "color":"#F400A1"},
    {"name":"Honeydew", "color":"#F0FFF0"},
    {"name":"Honolulu blue", "color":"#006DB0"},
    {"name":"Hooker's green", "color":"#49796B"},
    {"name":"Hot magenta", "color":"#FF1DCE"},
    {"name":"Hot pink", "color":"#FF69B4"},
    {"name":"Hunter green", "color":"#355E3B"},
    {"name":"Iceberg", "color":"#71A6D2"},
    {"name":"Icterine", "color":"#FCF75E"},
    {"name":"Illuminating emerald", "color":"#319177"},
    {"name":"Imperial red", "color":"#ED2939"},
    {"name":"Inchworm", "color":"#B2EC5D"},
    {"name":"Independence", "color":"#4C516D"},
    {"name":"India green", "color":"#138808"},
    {"name":"Indian red", "color":"#CD5C5C"},
    {"name":"Indian yellow", "color":"#E3A857"},
    {"name":"Indigo", "color":"#4B0082"},
    {"name":"Indigo dye", "color":"#00416A"},
    {"name":"Infrared", "color":"#FF496C"},
    {"name":"International Klein Blue", "color":"#130a8f"},
    {"name":"International orange (aerospace)", "color":"#FF4F00"},
    {"name":"International orange (engineering)", "color":"#BA160C"},
    {"name":"International orange (Golden Gate Bridge)", "color":"#C0362C"},
    {"name":"Iris", "color":"#5A4FCF"},
    {"name":"Irresistible", "color":"#B3446C"},
    {"name":"Isabelline", "color":"#F4F0EC"},
    {"name":"Italian sky blue", "color":"#B2FFFF"},
    {"name":"Ivory", "color":"#FFFFF0"},
    {"name":"Jade", "color":"#00A86B"},
    {"name":"Japanese carmine", "color":"#9D2933"},
    {"name":"Japanese violet", "color":"#5B3256"},
    {"name":"Jasmine", "color":"#F8DE7E"},
    {"name":"Jazzberry jam", "color":"#A50B5E"},
    {"name":"Jet", "color":"#343434"},
    {"name":"Jonquil", "color":"#F4CA16"},
    {"name":"June bud", "color":"#BDDA57"},
    {"name":"Jungle green", "color":"#29AB87"},
    {"name":"Kelly green", "color":"#4CBB17"},
    {"name":"Keppel", "color":"#3AB09E"},
    {"name":"Key lime", "color":"#E8F48C"},
    {"name":"Khaki (web)", "color":"#C3B091"},
    {"name":"Khaki (X11) (Light khaki)", "color":"#F0E68C"},
    {"name":"Kobe", "color":"#882D17"},
    {"name":"Kobi", "color":"#E79FC4"},
    {"name":"Kobicha", "color":"#6B4423"},
    {"name":"Kombu green", "color":"#354230"},
    {"name":"KSU purple", "color":"#512888"},
    {"name":"Languid lavender", "color":"#D6CADD"},
    {"name":"Lapis lazuli", "color":"#26619C"},
    {"name":"Laser lemon", "color":"#FFFF66"},
    {"name":"Laurel green", "color":"#A9BA9D"},
    {"name":"Lava", "color":"#CF1020"},
    {"name":"Lavender (floral)", "color":"#B57EDC"},
    {"name":"Lavender (web)", "color":"#E6E6FA"},
    {"name":"Lavender blue", "color":"#CCCCFF"},
    {"name":"Lavender blush", "color":"#FFF0F5"},
    {"name":"Lavender gray", "color":"#C4C3D0"},
    {"name":"Lawn green", "color":"#7CFC00"},
    {"name":"Lemon", "color":"#FFF700"},
    {"name":"Lemon chiffon", "color":"#FFFACD"},
    {"name":"Lemon curry", "color":"#CCA01D"},
    {"name":"Lemon glacier", "color":"#FDFF00"},
    {"name":"Lemon meringue", "color":"#F6EABE"},
    {"name":"Lemon yellow", "color":"#FFF44F"},
    {"name":"Lemon yellow (Crayola)", "color":"#FFFF9F"},
    {"name":"Liberty", "color":"#545AA7"},
    {"name":"Light blue", "color":"#ADD8E6"},
    {"name":"Light coral", "color":"#F08080"},
    {"name":"Light cornflower blue", "color":"#93CCEA"},
    {"name":"Light cyan", "color":"#E0FFFF"},
    {"name":"Light French beige", "color":"#C8AD7F"},
    {"name":"Light goldenrod yellow", "color":"#FAFAD2"},
    {"name":"Light gray", "color":"#D3D3D3"},
    {"name":"Light green", "color":"#90EE90"},
    {"name":"Light orange", "color":"#FED8B1"},
    {"name":"Light periwinkle", "color":"#C5CBE1"},
    {"name":"Light pink", "color":"#FFB6C1"},
    {"name":"Light salmon", "color":"#FFA07A"},
    {"name":"Light sea green", "color":"#20B2AA"},
    {"name":"Light sky blue", "color":"#87CEFA"},
    {"name":"Light slate gray", "color":"#778899"},
    {"name":"Light steel blue", "color":"#B0C4DE"},
    {"name":"Light yellow", "color":"#FFFFE0"},
    {"name":"Lilac", "color":"#C8A2C8"},
    {"name":"Lilac Luster", "color":"#AE98AA"},
    {"name":"Lime (color wheel)", "color":"#BFFF00"},
    {"name":"Lime (web) (X11 green)", "color":"#00FF00"},
    {"name":"Lime green", "color":"#32CD32"},
    {"name":"Lincoln green", "color":"#195905"},
    {"name":"Linen", "color":"#FAF0E6"},
    {"name":"Lion", "color":"#C19A6B"},
    {"name":"Liseran purple", "color":"#DE6FA1"},
    {"name":"Little boy blue", "color":"#6CA0DC"},
    {"name":"Liver", "color":"#674C47"},
    {"name":"Liver (dogs)", "color":"#B86D29"},
    {"name":"Liver (organ)", "color":"#6C2E1F"},
    {"name":"Liver chestnut", "color":"#987456"},
    {"name":"Livid", "color":"#6699CC"},
    {"name":"Macaroni and Cheese", "color":"#FFBD88"},
    {"name":"Madder Lake", "color":"#CC3336"},
    {"name":"Magenta", "color":"#FF00FF"},
    {"name":"Magenta (Crayola)", "color":"#F653A6"},
    {"name":"Magenta (dye)", "color":"#CA1F7B"},
    {"name":"Magenta (Pantone)", "color":"#D0417E"},
    {"name":"Magenta (process)", "color":"#FF0090"},
    {"name":"Magenta haze", "color":"#9F4576"},
    {"name":"Magic mint", "color":"#AAF0D1"},
    {"name":"Magnolia", "color":"#F2E8D7"},
    {"name":"Mahogany", "color":"#C04000"},
    {"name":"Maize", "color":"#FBEC5D"},
    {"name":"Maize (Crayola)", "color":"#F2C649"},
    {"name":"Majorelle blue", "color":"#6050DC"},
    {"name":"Malachite", "color":"#0BDA51"},
    {"name":"Manatee", "color":"#979AAA"},
    {"name":"Mandarin", "color":"#F37A48"},
    {"name":"Mango", "color":"#FDBE02"},
    {"name":"Mango Tango", "color":"#FF8243"},
    {"name":"Mantis", "color":"#74C365"},
    {"name":"Mardi Gras", "color":"#880085"},
    {"name":"Marigold", "color":"#EAA221"},
    {"name":"Maroon (Crayola)", "color":"#C32148"},
    {"name":"Maroon (web)", "color":"#800000"},
    {"name":"Maroon (X11)", "color":"#B03060"},
    {"name":"Mauve", "color":"#E0B0FF"},
    {"name":"Mauve taupe", "color":"#915F6D"},
    {"name":"Mauvelous", "color":"#EF98AA"},
    {"name":"Maximum blue", "color":"#47ABCC"},
    {"name":"Maximum blue green", "color":"#30BFBF"},
    {"name":"Maximum blue purple", "color":"#ACACE6"},
    {"name":"Maximum green", "color":"#5E8C31"},
    {"name":"Maximum green yellow", "color":"#D9E650"},
    {"name":"Maximum purple", "color":"#733380"},
    {"name":"Maximum red", "color":"#D92121"},
    {"name":"Maximum red purple", "color":"#A63A79"},
    {"name":"Maximum yellow", "color":"#FAFA37"},
    {"name":"Maximum yellow red", "color":"#F2BA49"},
    {"name":"May green", "color":"#4C9141"},
    {"name":"Maya blue", "color":"#73C2FB"},
    {"name":"Medium aquamarine", "color":"#66DDAA"},
    {"name":"Medium blue", "color":"#0000CD"},
    {"name":"Medium candy apple red", "color":"#E2062C"},
    {"name":"Medium carmine", "color":"#AF4035"},
    {"name":"Medium champagne", "color":"#F3E5AB"},
    {"name":"Medium orchid", "color":"#BA55D3"},
    {"name":"Medium purple", "color":"#9370DB"},
    {"name":"Medium sea green", "color":"#3CB371"},
    {"name":"Medium slate blue", "color":"#7B68EE"},
    {"name":"Medium spring green", "color":"#00FA9A"},
    {"name":"Medium turquoise", "color":"#48D1CC"},
    {"name":"Medium violet-red", "color":"#C71585"},
    {"name":"Mellow apricot", "color":"#F8B878"},
    {"name":"Mellow yellow", "color":"#F8DE7E"},
    {"name":"Melon", "color":"#FEBAAD"},
    {"name":"Metallic gold", "color":"#D3AF37"},
    {"name":"Metallic Seaweed", "color":"#0A7E8C"},
    {"name":"Metallic Sunburst", "color":"#9C7C38"},
    {"name":"Mexican pink", "color":"#E4007C"},
    {"name":"Middle blue", "color":"#7ED4E6"},
    {"name":"Middle blue green", "color":"#8DD9CC"},
    {"name":"Middle blue purple", "color":"#8B72BE"},
    {"name":"Middle grey", "color":"#8B8680"},
    {"name":"Middle green", "color":"#4D8C57"},
    {"name":"Middle green yellow", "color":"#ACBF60"},
    {"name":"Middle purple", "color":"#D982B5"},
    {"name":"Middle red", "color":"#E58E73"},
    {"name":"Middle red purple", "color":"#A55353"},
    {"name":"Middle yellow", "color":"#FFEB00"},
    {"name":"Middle yellow red", "color":"#ECB176"},
    {"name":"Midnight", "color":"#702670"},
    {"name":"Midnight blue", "color":"#191970"},
    {"name":"Midnight green (eagle green)", "color":"#004953"},
    {"name":"Mikado yellow", "color":"#FFC40C"},
    {"name":"Mimi pink", "color":"#FFDAE9"},
    {"name":"Mindaro", "color":"#E3F988"},
    {"name":"Ming", "color":"#36747D"},
    {"name":"Minion yellow", "color":"#F5E050"},
    {"name":"Mint", "color":"#3EB489"},
    {"name":"Mint cream", "color":"#F5FFFA"},
    {"name":"Mint green", "color":"#98FF98"},
    {"name":"Misty moss", "color":"#BBB477"},
    {"name":"Misty rose", "color":"#FFE4E1"},
    {"name":"Mode beige", "color":"#967117"},
    {"name":"Morning blue", "color":"#8DA399"},
    {"name":"Moss green", "color":"#8A9A5B"},
    {"name":"Mountain Meadow", "color":"#30BA8F"},
    {"name":"Mountbatten pink", "color":"#997A8D"},
    {"name":"MSU green", "color":"#18453B"},
    {"name":"Mulberry", "color":"#C54B8C"},
    {"name":"Mulberry (Crayola)", "color":"#C8509B"},
    {"name":"Mustard", "color":"#FFDB58"},
    {"name":"Myrtle green", "color":"#317873"},
    {"name":"Mystic", "color":"#D65282"},
    {"name":"Mystic maroon", "color":"#AD4379"},
    {"name":"Nadeshiko pink", "color":"#F6ADC6"},
    {"name":"Naples yellow", "color":"#FADA5E"},
    {"name":"Navajo white", "color":"#FFDEAD"},
    {"name":"Navy blue", "color":"#000080"},
    {"name":"Navy blue (Crayola)", "color":"#1974D2"},
    {"name":"Neon blue", "color":"#4666FF"},
    {"name":"Neon Carrot", "color":"#FFA343"},
    {"name":"Neon green", "color":"#39FF14"},
    {"name":"Neon fuchsia", "color":"#FE4164"},
    {"name":"New York pink", "color":"#D7837F"},
    {"name":"Nickel", "color":"#727472"},
    {"name":"Non-photo blue", "color":"#A4DDED"},
    {"name":"Nyanza", "color":"#E9FFDB"},
    {"name":"Ocean Blue", "color":"#4F42B5"},
    {"name":"Ocean green", "color":"#48BF91"},
    {"name":"Ochre", "color":"#CC7722"},
    {"name":"Old burgundy", "color":"#43302E"},
    {"name":"Old gold", "color":"#CFB53B"},
    {"name":"Old lace", "color":"#FDF5E6"},
    {"name":"Old lavender", "color":"#796878"},
    {"name":"Old mauve", "color":"#673147"},
    {"name":"Old rose", "color":"#C08081"},
    {"name":"Old silver", "color":"#848482"},
    {"name":"Olive", "color":"#808000"},
    {"name":"Olive Drab (#3)", "color":"#6B8E23"},
    {"name":"Olive Drab #7", "color":"#3C341F"},
    {"name":"Olive green", "color":"#B5B35C"},
    {"name":"Olivine", "color":"#9AB973"},
    {"name":"Onyx", "color":"#353839"},
    {"name":"Opal", "color":"#A8C3BC"},
    {"name":"Opera mauve", "color":"#B784A7"},
    {"name":"Orange", "color":"#FF7F00"},
    {"name":"Orange (Crayola)", "color":"#FF7538"},
    {"name":"Orange (Pantone)", "color":"#FF5800"},
    {"name":"Orange (web)", "color":"#FFA500"},
    {"name":"Orange peel", "color":"#FF9F00"},
    {"name":"Orange-red", "color":"#FF681F"},
    {"name":"Orange-red (Crayola)", "color":"#FF5349"},
    {"name":"Orange soda", "color":"#FA5B3D"},
    {"name":"Orange-yellow", "color":"#F5BD1F"},
    {"name":"Orange-yellow (Crayola)", "color":"#F8D568"},
    {"name":"Orchid", "color":"#DA70D6"},
    {"name":"Orchid pink", "color":"#F2BDCD"},
    {"name":"Orchid (Crayola)", "color":"#E29CD2"},
    {"name":"Outer space (Crayola)", "color":"#2D383A"},
    {"name":"Outrageous Orange", "color":"#FF6E4A"},
    {"name":"Oxblood", "color":"#4A0000"},
    {"name":"Oxford blue", "color":"#002147"},
    {"name":"OU Crimson red", "color":"#841617"},
    {"name":"Pacific blue", "color":"#1CA9C9"},
    {"name":"Pakistan green", "color":"#006600"},
    {"name":"Palatinate purple", "color":"#682860"},
    {"name":"Pale aqua", "color":"#BCD4E6"},
    {"name":"Pale cerulean", "color":"#9BC4E2"},
    {"name":"Pale Dogwood", "color":"#ED7A9B"},
    {"name":"Pale pink", "color":"#FADADD"},
    {"name":"Pale purple (Pantone)", "color":"#FAE6FA"},
    {"name":"Pale silver", "color":"#C9C0BB"},
    {"name":"Pale spring bud", "color":"#ECEBBD"},
    {"name":"Pansy purple", "color":"#78184A"},
    {"name":"Paolo Veronese green", "color":"#009B7D"},
    {"name":"Papaya whip", "color":"#FFEFD5"},
    {"name":"Paradise pink", "color":"#E63E62"},
    {"name":"Parchment", "color":"#F1E9D2"},
    {"name":"Paris Green", "color":"#50C878"},
    {"name":"Pastel pink", "color":"#DEA5A4"},
    {"name":"Patriarch", "color":"#800080"},
    {"name":"Payne's grey", "color":"#536878"},
    {"name":"Peach", "color":"#FFE5B4"},
    {"name":"Peach (Crayola)", "color":"#FFCBA4"},
    {"name":"Peach puff", "color":"#FFDAB9"},
    {"name":"Pear", "color":"#D1E231"},
    {"name":"Pearly purple", "color":"#B768A2"},
    {"name":"Periwinkle", "color":"#CCCCFF"},
    {"name":"Periwinkle (Crayola)", "color":"#C3CDE6"},
    {"name":"Permanent Geranium Lake", "color":"#E12C2C"},
    {"name":"Persian blue", "color":"#1C39BB"},
    {"name":"Persian green", "color":"#00A693"},
    {"name":"Persian indigo", "color":"#32127A"},
    {"name":"Persian orange", "color":"#D99058"},
    {"name":"Persian pink", "color":"#F77FBE"},
    {"name":"Persian plum", "color":"#701C1C"},
    {"name":"Persian red", "color":"#CC3333"},
    {"name":"Persian rose", "color":"#FE28A2"},
    {"name":"Persimmon", "color":"#EC5800"},
    {"name":"Pewter Blue", "color":"#8BA8B7"},
    {"name":"Phlox", "color":"#DF00FF"},
    {"name":"Phthalo blue", "color":"#000F89"},
    {"name":"Phthalo green", "color":"#123524"},
    {"name":"Picotee blue", "color":"#2E2787"},
    {"name":"Pictorial carmine", "color":"#C30B4E"},
    {"name":"Piggy pink", "color":"#FDDDE6"},
    {"name":"Pine green", "color":"#01796F"},
    {"name":"Pine tree", "color":"#2A2F23"},
    {"name":"Pink", "color":"#FFC0CB"},
    {"name":"Pink (Pantone)", "color":"#D74894"},
    {"name":"Pink flamingo", "color":"#FC74FD"},
    {"name":"Pink lace", "color":"#FFDDF4"},
    {"name":"Pink lavender", "color":"#D8B2D1"},
    {"name":"Pink Sherbet", "color":"#F78FA7"},
    {"name":"Pistachio", "color":"#93C572"},
    {"name":"Platinum", "color":"#E5E4E2"},
    {"name":"Plum", "color":"#8E4585"},
    {"name":"Plum (web)", "color":"#DDA0DD"},
    {"name":"Plump Purple", "color":"#5946B2"},
    {"name":"Polished Pine", "color":"#5DA493"},
    {"name":"Pomp and Power", "color":"#86608E"},
    {"name":"Popstar", "color":"#BE4F62"},
    {"name":"Portland Orange", "color":"#FF5A36"},
    {"name":"Powder blue", "color":"#B0E0E6"},
    {"name":"Princeton orange", "color":"#F58025"},
    {"name":"Process yellow", "color":"#FFEF00"},
    {"name":"Prune", "color":"#701C1C"},
    {"name":"Prussian blue", "color":"#003153"},
    {"name":"Psychedelic purple", "color":"#DF00FF"},
    {"name":"Puce", "color":"#CC8899"},
    {"name":"Pullman Brown (UPS Brown)", "color":"#644117"},
    {"name":"Pumpkin", "color":"#FF7518"},
    {"name":"Purple", "color":"#6A0DAD"},
    {"name":"Purple (web)", "color":"#800080"},
    {"name":"Purple (Munsell)", "color":"#9F00C5"},
    {"name":"Purple (X11)", "color":"#A020F0"},
    {"name":"Purple mountain majesty", "color":"#9678B6"},
    {"name":"Purple navy", "color":"#4E5180"},
    {"name":"Purple pizzazz", "color":"#FE4EDA"},
    {"name":"Purple Plum", "color":"#9C51B6"},
    {"name":"Purpureus", "color":"#9A4EAE"},
    {"name":"Queen blue", "color":"#436B95"},
    {"name":"Queen pink", "color":"#E8CCD7"},
    {"name":"Quick Silver", "color":"#A6A6A6"},
    {"name":"Quinacridone magenta", "color":"#8E3A59"},
    {"name":"Radical Red", "color":"#FF355E"},
    {"name":"Raisin black", "color":"#242124"},
    {"name":"Rajah", "color":"#FBAB60"},
    {"name":"Raspberry", "color":"#E30B5D"},
    {"name":"Raspberry glace", "color":"#915F6D"},
    {"name":"Raspberry rose", "color":"#B3446C"},
    {"name":"Raw sienna", "color":"#D68A59"},
    {"name":"Raw umber", "color":"#826644"},
    {"name":"Razzle dazzle rose", "color":"#FF33CC"},
    {"name":"Razzmatazz", "color":"#E3256B"},
    {"name":"Razzmic Berry", "color":"#8D4E85"},
    {"name":"Rebecca Purple", "color":"#663399"},
    {"name":"Red", "color":"#FF0000"},
    {"name":"Red (Crayola)", "color":"#EE204D"},
    {"name":"Red (Munsell)", "color":"#F2003C"},
    {"name":"Red (NCS)", "color":"#C40233"},
    {"name":"Red (Pantone)", "color":"#ED2939"},
    {"name":"Red (pigment)", "color":"#ED1C24"},
    {"name":"Red (RYB)", "color":"#FE2712"},
    {"name":"Red-orange", "color":"#FF5349"},
    {"name":"Red-orange (Crayola)", "color":"#FF681F"},
    {"name":"Red-orange (Color wheel)", "color":"#FF4500"},
    {"name":"Red-purple", "color":"#E40078"},
    {"name":"Red Salsa", "color":"#FD3A4A"},
    {"name":"Red-violet", "color":"#C71585"},
    {"name":"Red-violet (Crayola)", "color":"#C0448F"},
    {"name":"Red-violet (Color wheel)", "color":"#922B3E"},
    {"name":"Redwood", "color":"#A45A52"},
    {"name":"Resolution blue", "color":"#002387"},
    {"name":"Rhythm", "color":"#777696"},
    {"name":"Rich black", "color":"#004040"},
    {"name":"Rich black (FOGRA29)", "color":"#010B13"},
    {"name":"Rich black (FOGRA39)", "color":"#010203"},
    {"name":"Rifle green", "color":"#444C38"},
    {"name":"Robin egg blue", "color":"#00CCCC"},
    {"name":"Rocket metallic", "color":"#8A7F80"},
    {"name":"Rojo Spanish red", "color":"#A91101"},
    {"name":"Roman silver", "color":"#838996"},
    {"name":"Rose", "color":"#FF007F"},
    {"name":"Rose bonbon", "color":"#F9429E"},
    {"name":"Rose Dust", "color":"#9E5E6F"},
    {"name":"Rose ebony", "color":"#674846"},
    {"name":"Rose madder", "color":"#E32636"},
    {"name":"Rose pink", "color":"#FF66CC"},
    {"name":"Rose Pompadour", "color":"#ED7A9B"},
    {"name":"Rose quartz", "color":"#AA98A9"},
    {"name":"Rose red", "color":"#C21E56"},
    {"name":"Rose taupe", "color":"#905D5D"},
    {"name":"Rose vale", "color":"#AB4E52"},
    {"name":"Rosewood", "color":"#65000B"},
    {"name":"Rosso corsa", "color":"#D40000"},
    {"name":"Rosy brown", "color":"#BC8F8F"},
    {"name":"Royal blue (dark)", "color":"#002366"},
    {"name":"Royal blue (light)", "color":"#4169E1"},
    {"name":"Royal purple", "color":"#7851A9"},
    {"name":"Royal yellow", "color":"#FADA5E"},
    {"name":"Ruber", "color":"#CE4676"},
    {"name":"Rubine red", "color":"#D10056"},
    {"name":"Ruby", "color":"#E0115F"},
    {"name":"Ruby red", "color":"#9B111E"},
    {"name":"Rufous", "color":"#A81C07"},
    {"name":"Russet", "color":"#80461B"},
    {"name":"Russian green", "color":"#679267"},
    {"name":"Russian violet", "color":"#32174D"},
    {"name":"Rust", "color":"#B7410E"},
    {"name":"Rusty red", "color":"#DA2C43"},
    {"name":"Sacramento State green", "color":"#043927"},
    {"name":"Saddle brown", "color":"#8B4513"},
    {"name":"Safety orange", "color":"#FF7800"},
    {"name":"Safety orange (blaze orange)", "color":"#FF6700"},
    {"name":"Safety yellow", "color":"#EED202"},
    {"name":"Saffron", "color":"#F4C430"},
    {"name":"Sage", "color":"#BCB88A"},
    {"name":"St. Patrick's blue", "color":"#23297A"},
    {"name":"Salmon", "color":"#FA8072"},
    {"name":"Salmon pink", "color":"#FF91A4"},
    {"name":"Sand", "color":"#C2B280"},
    {"name":"Sand dune", "color":"#967117"},
    {"name":"Sandy brown", "color":"#F4A460"},
    {"name":"Sap green", "color":"#507D2A"},
    {"name":"Sapphire", "color":"#0F52BA"},
    {"name":"Sapphire blue", "color":"#0067A5"},
    {"name":"Sapphire (Crayola)", "color":"#2D5DA1"},
    {"name":"Satin sheen gold", "color":"#CBA135"},
    {"name":"Scarlet", "color":"#FF2400"},
    {"name":"Schauss pink", "color":"#FF91AF"},
    {"name":"School bus yellow", "color":"#FFD800"},
    {"name":"Screamin' Green", "color":"#66FF66"},
    {"name":"Sea green", "color":"#2E8B57"},
    {"name":"Sea green (Crayola)", "color":"#00FFCD"},
    {"name":"Seal brown", "color":"#59260B"},
    {"name":"Seashell", "color":"#FFF5EE"},
    {"name":"Selective yellow", "color":"#FFBA00"},
    {"name":"Sepia", "color":"#704214"},
    {"name":"Shadow", "color":"#8A795D"},
    {"name":"Shadow blue", "color":"#778BA5"},
    {"name":"Shamrock green", "color":"#009E60"},
    {"name":"Sheen green", "color":"#8FD400"},
    {"name":"Shimmering Blush", "color":"#D98695"},
    {"name":"Shiny Shamrock", "color":"#5FA778"},
    {"name":"Shocking pink", "color":"#FC0FC0"},
    {"name":"Shocking pink (Crayola)", "color":"#FF6FFF"},
    {"name":"Sienna", "color":"#882D17"},
    {"name":"Silver", "color":"#C0C0C0"},
    {"name":"Silver (Crayola)", "color":"#C9C0BB"},
    {"name":"Silver (Metallic)", "color":"#AAA9AD"},
    {"name":"Silver chalice", "color":"#ACACAC"},
    {"name":"Silver pink", "color":"#C4AEAD"},
    {"name":"Silver sand", "color":"#BFC1C2"},
    {"name":"Sinopia", "color":"#CB410B"},
    {"name":"Sizzling Red", "color":"#FF3855"},
    {"name":"Sizzling Sunrise", "color":"#FFDB00"},
    {"name":"Skobeloff", "color":"#007474"},
    {"name":"Sky blue", "color":"#87CEEB"},
    {"name":"Sky blue (Crayola)", "color":"#76D7EA"},
    {"name":"Sky magenta", "color":"#CF71AF"},
    {"name":"Slate blue", "color":"#6A5ACD"},
    {"name":"Slate gray", "color":"#708090"},
    {"name":"Slimy green", "color":"#299617"},
    {"name":"Smitten", "color":"#C84186"},
    {"name":"Smoky black", "color":"#100C08"},
    {"name":"Snow", "color":"#FFFAFA"},
    {"name":"Solid pink", "color":"#893843"},
    {"name":"Sonic silver", "color":"#757575"},
    {"name":"Space cadet", "color":"#1D2951"},
    {"name":"Spanish bistre", "color":"#807532"},
    {"name":"Spanish blue", "color":"#0070B8"},
    {"name":"Spanish carmine", "color":"#D10047"},
    {"name":"Spanish gray", "color":"#989898"},
    {"name":"Spanish green", "color":"#009150"},
    {"name":"Spanish orange", "color":"#E86100"},
    {"name":"Spanish pink", "color":"#F7BFBE"},
    {"name":"Spanish red", "color":"#E60026"},
    {"name":"Spanish sky blue", "color":"#00FFFF"},
    {"name":"Spanish violet", "color":"#4C2882"},
    {"name":"Spanish viridian", "color":"#007F5C"},
    {"name":"Spring bud", "color":"#A7FC00"},
    {"name":"Spring Frost", "color":"#87FF2A"},
    {"name":"Spring green", "color":"#00FF7F"},
    {"name":"Spring green (Crayola)", "color":"#ECEBBD"},
    {"name":"Star command blue", "color":"#007BB8"},
    {"name":"Steel blue", "color":"#4682B4"},
    {"name":"Steel pink", "color":"#CC33CC"},
    {"name":"Steel Teal", "color":"#5F8A8B"},
    {"name":"Stil de grain yellow", "color":"#FADA5E"},
    {"name":"Straw", "color":"#E4D96F"},
    {"name":"Strawberry", "color":"#FA5053"},
    {"name":"Strawberry Blonde", "color":"#FF9361"},
    {"name":"Sugar Plum", "color":"#914E75"},
    {"name":"Sunglow", "color":"#FFCC33"},
    {"name":"Sunray", "color":"#E3AB57"},
    {"name":"Sunset", "color":"#FAD6A5"},
    {"name":"Super pink", "color":"#CF6BA9"},
    {"name":"Sweet Brown", "color":"#A83731"},
    {"name":"Syracuse Orange", "color":"#D44500"},
    {"name":"Tan", "color":"#D2B48C"},
    {"name":"Tan (Crayola)", "color":"#D99A6C"},
    {"name":"Tangerine", "color":"#F28500"},
    {"name":"Tango pink", "color":"#E4717A"},
    {"name":"Tart Orange", "color":"#FB4D46"},
    {"name":"Taupe", "color":"#483C32"},
    {"name":"Taupe gray", "color":"#8B8589"},
    {"name":"Tea green", "color":"#D0F0C0"},
    {"name":"Tea rose", "color":"#F4C2C2"},
    {"name":"Teal", "color":"#008080"},
    {"name":"Teal blue", "color":"#367588"},
    {"name":"Telemagenta", "color":"#CF3476"},
    {"name":"Tenné (tawny)", "color":"#CD5700"},
    {"name":"Terra cotta", "color":"#E2725B"},
    {"name":"Thistle", "color":"#D8BFD8"},
    {"name":"Thulian pink", "color":"#DE6FA1"},
    {"name":"Tickle Me Pink", "color":"#FC89AC"},
    {"name":"Tiffany Blue", "color":"#0ABAB5"},
    {"name":"Timberwolf", "color":"#DBD7D2"},
    {"name":"Titanium yellow", "color":"#EEE600"},
    {"name":"Tomato", "color":"#FF6347"},
    {"name":"Tropical rainforest", "color":"#00755E"},
    {"name":"True Blue", "color":"#2D68C4"},
    {"name":"Trypan Blue", "color":"#1C05B3"},
    {"name":"Tufts blue", "color":"#3E8EDE"},
    {"name":"Tumbleweed", "color":"#DEAA88"},
    {"name":"Turquoise", "color":"#40E0D0"},
    {"name":"Turquoise blue", "color":"#00FFEF"},
    {"name":"Turquoise green", "color":"#A0D6B4"},
    {"name":"Turtle green", "color":"#8A9A5B"},
    {"name":"Tuscan", "color":"#FAD6A5"},
    {"name":"Tuscan brown", "color":"#6F4E37"},
    {"name":"Tuscan red", "color":"#7C4848"},
    {"name":"Tuscan tan", "color":"#A67B5B"},
    {"name":"Tuscany", "color":"#C09999"},
    {"name":"Twilight lavender", "color":"#8A496B"},
    {"name":"Tyrian purple", "color":"#66023C"},
    {"name":"UA blue", "color":"#0033AA"},
    {"name":"UA red", "color":"#D9004C"},
    {"name":"Ultramarine", "color":"#3F00FF"},
    {"name":"Ultramarine blue", "color":"#4166F5"},
    {"name":"Ultra pink", "color":"#FF6FFF"},
    {"name":"Ultra red", "color":"#FC6C85"},
    {"name":"Umber", "color":"#635147"},
    {"name":"Unbleached silk", "color":"#FFDDCA"},
    {"name":"United Nations blue", "color":"#5B92E5"},
    {"name":"University of Pennsylvania red", "color":"#A50021"},
    {"name":"Unmellow yellow", "color":"#FFFF66"},
    {"name":"UP Forest green", "color":"#014421"},
    {"name":"UP maroon", "color":"#7B1113"},
    {"name":"Upsdell red", "color":"#AE2029"},
    {"name":"Uranian blue", "color":"#AFDBF5"},
    {"name":"USAFA blue", "color":"#004F98"},
    {"name":"Van Dyke brown", "color":"#664228"},
    {"name":"Vanilla", "color":"#F3E5AB"},
    {"name":"Vanilla ice", "color":"#F38FA9"},
    {"name":"Vegas gold", "color":"#C5B358"},
    {"name":"Venetian red", "color":"#C80815"},
    {"name":"Verdigris", "color":"#43B3AE"},
    {"name":"Vermilion", "color":"#E34234"},
    {"name":"Vermilion", "color":"#D9381E"},
    {"name":"Veronica", "color":"#A020F0"},
    {"name":"Violet", "color":"#8F00FF"},
    {"name":"Violet (color wheel)", "color":"#7F00FF"},
    {"name":"Violet (crayola)", "color":"#963D7F"},
    {"name":"Violet (RYB)", "color":"#8601AF"},
    {"name":"Violet (web)", "color":"#EE82EE"},
    {"name":"Violet-blue", "color":"#324AB2"},
    {"name":"Violet-blue (Crayola)", "color":"#766EC8"},
    {"name":"Violet-red", "color":"#F75394"},
    {"name":"Viridian", "color":"#40826D"},
    {"name":"Viridian green", "color":"#009698"},
    {"name":"Vivid burgundy", "color":"#9F1D35"},
    {"name":"Vivid sky blue", "color":"#00CCFF"},
    {"name":"Vivid tangerine", "color":"#FFA089"},
    {"name":"Vivid violet", "color":"#9F00FF"},
    {"name":"Volt", "color":"#CEFF00"},
    {"name":"Warm black", "color":"#004242"},
    {"name":"Weezy Blue", "color":"#189BCC"},
    {"name":"Wheat", "color":"#F5DEB3"},
    {"name":"Wild blue yonder", "color":"#A2ADD0"},
    {"name":"Wild orchid", "color":"#D470A2"},
    {"name":"Wild Strawberry", "color":"#FF43A4"},
    {"name":"Wild watermelon", "color":"#FC6C85"},
    {"name":"Windsor tan", "color":"#A75502"},
    {"name":"Wine", "color":"#722F37"},
    {"name":"Wine dregs", "color":"#673147"},
    {"name":"Winter Sky", "color":"#FF007C"},
    {"name":"Wintergreen Dream", "color":"#56887D"},
    {"name":"Wisteria", "color":"#C9A0DC"},
    {"name":"Wood brown", "color":"#C19A6B"},
    {"name":"Xanadu", "color":"#738678"},
    {"name":"Xanthic", "color":"#EEED09"},
    {"name":"Xanthous", "color":"#F1B42F"},
    {"name":"Yale Blue", "color":"#00356B"},
    {"name":"Yellow", "color":"#FFFF00"},
    {"name":"Yellow (Crayola)", "color":"#FCE883"},
    {"name":"Yellow (Munsell)", "color":"#EFCC00"},
    {"name":"Yellow (NCS)", "color":"#FFD300"},
    {"name":"Yellow (Pantone)", "color":"#FEDF00"},
    {"name":"Yellow (process)", "color":"#FFEF00"},
    {"name":"Yellow (RYB)", "color":"#FEFE33"},
    {"name":"Yellow-green", "color":"#9ACD32"},
    {"name":"Yellow-green (Crayola)", "color":"#C5E384"},
    {"name":"Yellow-green (Color Wheel)", "color":"#30B21A"},
    {"name":"Yellow Orange", "color":"#FFAE42"},
    {"name":"Yellow Orange (Color Wheel)", "color":"#FF9505"},
    {"name":"Yellow Sunshine", "color":"#FFF700"},
    {"name":"YInMn Blue", "color":"#2E5090"},
    {"name":"Zaffre", "color":"#0014A8"},
    {"name":"Zomp", "color":"#39A78"}
]