export const SingleCheque_Properties = {
	id:2,
	top: '0mm',
	bottom: '0mm',
	title: 'Basic_Cheque',
	borders: false,
	monochrome: false,
	font: 'Helvetica',
	enabled: true,
	default: false,
	docugard: false,
	pro:false,
	background: '#fff1da',
	preview: '/assets/backgrounds/Cheque.png',
	description: 'Basic_Cheque_Print'
}
export function SingleCheque(cheque,words) {
	return [{
		style: 'Cheque',
		layout: 'noBorders',
		table: {
			widths: [ 470 ],
			body: [
				[
					{
						layout: 'noBorders',
						margin: [ 18, 12, 9, 0 ],
						table: {
							widths: [200,147,50],
							body: [
								[
									{
										layout: 'noBorders',
										margin: [ 0, 0, 0, 0 ],
										table: {
											widths: ['*'],
											body: [
												[
													{ fontSize: 10, bold: true, text: cheque.company_name }
												]
											]
										}
									},
									{
										width: 40,
										margin: [ 0, 0, 0, 0 ],
										image: cheque.cheque_logo_image?cheque.cheque_logo_image:''
									},
									{
										layout: 'noBorders',
										margin: [ 0, 0, 0, 0 ],
										table: {
											widths: ['*'],
											body: [
												[
													{ fontSize: 11, bold: true, text: cheque.number }
												]
											]
										}
									}
								],
							],
						}
					},
				],
				[
					{
						margin: [ 0, 0, 0, 0 ],
						columns: [
							{
								width: 328,
								margin: [ 2, 3, 0, 0 ],
								text: { fontSize: 9, text: words.CHEQUE_PRINT_DATE, alignment: 'right' }
							},
							{
								margin: [ 0, 0, 23, 0 ],
								text: { fontSize: 12, characterSpacing:5, text: cheque.date, alignment: 'right' }
							}
						]
					},
				],
				[
					{
						margin: [ 0, 0, 0, 0 ],
						columns: [
							{
								margin: [ 0, 0, 24, 0 ],
								text: { fontSize: 7, characterSpacing:0.65, text: 'Y  Y  Y  Y   M  M   D  D', alignment: 'right' }
							}
						]
					},
				],
				[
					{
						margin: [ 0, 0, 0, 0 ],
						columns: [
							{
							  width: 70,
							  margin: [ 18, 0, 0, 0 ],
							  text: { fontSize: 8, text: words.CHEQUE_PRINT_PAYTOTHEORDEROF },
							},
							{
							  width: 190,
							  margin: [ 2, 2, 0, 0 ],
							  text: { fontSize: 12, text: cheque.name },
							},
							{
							  margin: [ 2, 2, 23, 0 ],
							  text: { fontSize: 12, text: cheque.amount, alignment:'right' }
							}
						]
					},
				],
				[
					{
						margin: [ 18, 0, 0, 0 ],
						columns: [
							{
							  width: 364,
							  margin: [ 0, 0, 0, 0 ],
							  text: { fontSize: 9, text:cheque.written_amount },
							},
							{
							  width: 80,
							  margin: [ 0, 0, 0, 5 ],
							  text: { fontSize: 9, text: cheque.fob }
							}
						]
					},
				],
				[
					{
						layout: 'noBorders',
						margin: [ 18, 0, 0, 0 ],
						table: {
							widths: [30,300],
							body: [
								[
									cheque.bank_image?{
										width: cheque.bank_image?29:0,
										margin: [ 0, 0, 0, 0 ],
										image: cheque.bank_image
									}:{},
									{
										layout: 'noBorders',
										margin: [ 0, 0, 0, 0 ],
										table: {
											widths: ['*'],
											body: [
												[
													{ lineHeight: 0.6, fontSize: 11, text: cheque.bank_name }
												],
												[
													{ lineHeight: 0.6, fontSize: 9, text: cheque.bank_address+' '+cheque.bank_address_2 }
												],
												[
													{ 
														lineHeight: 0.6, 
														fontSize: 9, 
														text: (cheque.bank_city?cheque.bank_city+', ':'')+(cheque.bank_province?cheque.bank_province+', ':'')+(cheque.bank_country?cheque.bank_country+' ':'')+(cheque.bank_postal_code?cheque.bank_postal_code:'')
													}
												],
											]
										}
									}
								],
							]
						}
					},
				],
				[
					{
						layout: 'noBorders',
						margin: [ 18, 0, 0, 0 ],
						table: {
							widths: [30,220,10,160],
							body: [
								[
									{ margin: [ 0, 5, 0, 0 ], fontSize: 9, text: words.CHEQUE_PRINT_MEMO },
									{ margin: [ 0, 2, 0, 0 ], fontSize: 12, text: cheque.printed_memo },
									{ fontSize: 5, text: words.CHEQUE_PRINT_SIGNATUREMP, fillColor: '#fff', lineHeight:4 },
									cheque.signature_image?{
										width: 160,
										margin: [ 0, 0, 0, 0 ],
										image: cheque.signature_image
									}:{},
								],
							]
						}
					},
				],
				[
					{
						layout: 'noBorders',
						margin: [ 11, 0, 10, 13 ],
						table: {
							widths: [270,150],
							body: [
								[
									{ margin: [ 1, 0, 0, 0 ], font: "MICR", fillColor: '#fff', fontSize: 19, alignment: 'left', text: cheque.identifier },
									{ margin: [ 0, 0, 0, 0 ], fontSize: 9, text: words.CHEQUE_PRINT_CREDIT, opacity: 0.33, alignment: 'right' }
								],
							]
						}
					}
				],
			]
		}
	}]
}