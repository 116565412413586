import { NewKey } from '../../../models/key';
import { environment } from '../../../../environments/environment';
import { SelectAddressPage } from '../../../addresses/select-address/select-address.page';
import { SelectEmployeePage } from '../../../employees/select-employee/select-employee.page';
import { SQLService } from '../../../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { Location } from '@angular/common';
@Component({
  selector: 'app-new-key',
  templateUrl: './new-key.component.html',
  styleUrls: ['./new-key.component.scss'],
})
export class NewKeyComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public sql: SQLService,
    private location: Location,
    public modalController: ModalController
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) {
        if(!this.sql.account_keys)this.end();
      }
    });
  }
  _c=0;
  key = new NewKey();
  generate_key() {
    this.key = new NewKey();
    this.key.enabled = true;
    this.sql.add(this.key,(done)=>{
      this.generate_qr(this.key.iv,(qr)=>{
        if(!qr)return;
        this.key.qr_image=qr;
      });
    });
  }
  generate_qr(k,b?) {
    // this.barcode.encode(this.barcode.Encode.TEXT_TYPE,k).then((q) => {
    //   if(b)b(q);return q;
    // }).catch(err => {console.error(err);});
  }
  save(end=true) {
    this.sql.add("Keys",this.key);
    if(end){
      this.key = new NewKey();
      this._c=0;
      this.end();
    }
  }
  end() {
    if (this._c>0) {
      if(confirm(this.sql.word.Discard_Confirmation)) {
        this.key = new NewKey();
        this.router.navigate(['folder','Keys']);
      }
    } else {
      this.router.navigate(['folder','Keys']);
    }
  }  
  ngOnInit() {
    this.generate_key();
  }
}
