import { SQLService } from '../../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router,NavigationEnd } from '@angular/router';
@Component({
  selector: 'ng-cheques-backup-settings',
  templateUrl: './backup-settings.component.html',
  styleUrls: ['./backup-settings.component.styl']
})
export class BackupSettingsComponent implements OnInit {
  constructor(
    public sql: SQLService,
    private location: Location,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_backup_settings();
    });
  }
  count = -1;
  backups = [];
  show_new:boolean;
  search: string;
  filter(e){this.search=e.target.value;}
  add(){this.router.navigate(['folder','Settings','Backup','Type']);}
  get_backup_settings(event?) {
    this.sql.fetch('Backups',(b)=>{
      this.backups=b;
      this.count=b.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  end(){this.location.back();}
  download(){this.sql.download();}
  export(){this.sql.export();}
  edit(backup, item?) {
    this.router.navigate(['folder','Backup',backup.backup_id]);
  }
  delete(backup, item) { 
    if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('Backups','backup_id',backup.backup_id);
      this.get_backup_settings();
    } else { item.closeSlidingItems(); }
  }
  ngOnInit() {
    this.get_backup_settings();
  }
}