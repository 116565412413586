import { SQLService } from '../../services/sql.service';
import { SelectEmployeePage } from '../../employees/select-employee/select-employee.page';
import { SelectBankPage } from '../../settings/bank-accounts/select-bank/select-bank.page';
import { SelectPayeePage } from '../../payees/select-payee/select-payee.page';
import { Flip } from '../../app.animations';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
@Component({
  selector: 'ng-cheques-print-payroll',
  templateUrl: './print-payroll.component.html',
  styleUrls: ['./print-payroll.component.styl'],
  animations: [ Flip ]
})
export class PrintPayrollPage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public sql: SQLService,
    public modalController: ModalController,
    public platform: Platform
  ) { }
  count=-1;
  showInfo=[];
  payrolls=[];
  payroll = new NewPayroll(0);
  get_payroll() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida) this.get_payrolls();
    this.sql.view('Payrolls','payroll_id',ida,(e)=>{
      var m=Object.entries(e[0]); let p = new NewPayroll(0);
      for(let j of m)p[j[0]]=j[1];
      this.payroll = p;
    });
  }
  get_payrolls(event?) {
    this.sql.fetch('Payrolls',(e)=>{
      this.payrolls=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  print() {

  }
  end() {
    this.payroll = new NewPayroll(0);
    this.router.navigate(['folder','Payroll']);
  }
  cheque() {
    this.router.navigate(['folder','Cheque','New']);
  }
  ngOnInit() {
   this.get_payroll();
  }
}
export class NewPayroll {
  constructor(
    public admin_id?: number,
    public payroll_id?: any,
    public employee_ids?: [number],
    public payee_ids?: [number],
    public deduction_ids?: [number],
    public cheque_ids?: [number],
    public leave_ids?: [number],
    public timesheet_ids?: [number],
    public vacation_ids?: [number],
    public tax_ids?: [number],
    public bank_ids?: [number],
    public accounts_count?: number,
    public employee_count?: number,
    public companies_count?: number,
    public amount?: number,
    public currency?: string,
    public title?: string,
    public date?: any,
    public description?: string,
    public enabled?: boolean,
    public printed_memo?: string,
    public modified?: any,
    public created?: any
  ) {}
}  