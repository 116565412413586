import { SQLService } from './sql.service';
import { Injectable } from '@angular/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotifications } from '@capacitor/push-notifications';
@Injectable()
export class NotificationService {
    constructor(public sql: SQLService) {}
    async send(title='Title', body='Body', future_date?) {
        console.log('Notification Service', title, body, future_date);

        // const push = LocalNotifications;
        // push.setDefaults({ 
        //     led: { 
        //         color: '#6457FF' 
        //     }, 
        //     vibrate: false 
        // });
        // push.register();
        // push.requestPermissions().then((answer)=>{
        //     console.log(answer);
        //     if (answer) {
        //         // push.createChannel({
        //         //     id: 'relay',
        //         //     name: 'relay',
        //         //     description: '',
        //         //     sound: '',
        //         //     visibility: 1,
        //         //     lights: true,
        //         //     lightColor: "#6457FF",
        //         //     vibration: true,
        //         // });
        //         // // push.addListener('pushNotificationReceived', (notification) => {
        //         // //     console.log(notification);
        //         // // });
        //     }
        // });
    }
}