import { environment } from '../../../../environments/environment';
import { SQLService } from '../../../services/sql.service';
import { NewHoliday } from '../../../models/holiday';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'ng-cheques-new-holiday',
  templateUrl: './new-holiday.component.html',
  styleUrls: ['./new-holiday.component.styl']
})
export class NewHolidayComponent implements OnInit {
  constructor(
    public sql: SQLService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.open();
    });
    
  }
  _c = -1;
  currency = null;
  holiday = new NewHoliday();
  currencies = Object.keys(environment.currencies);
  save(end=true) {
    this.sql.add("Holidays",this.holiday,(insertId)=>{
      if(!insertId)return
      if(end) {
        this.holiday = new NewHoliday();
        this._c=0;
        this.end();  
      }
    });
  }
  delete_holiday() {
    if(!this.holiday.holiday_id){
      this.end();
    } else if(confirm(this.sql.word.Discard_Confirmation)) {
      this.sql.delete('Holidays','holiday_id',this.holiday.holiday_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  end() {
    if (this._c>0) {
      if(confirm(this.sql.word.Discard_Confirmation)) {
        this.holiday = new NewHoliday();
        this.router.navigate(['folder','Holidays']);
      }
    } else {
      this.router.navigate(['folder','Holidays']);
    }
  }
  get_holiday() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida) return;
    this.sql.view('Holidays','holiday_id',ida,(e)=>{
      var f=Object.entries(e[0]); let n = new NewHoliday();
      for(let t of f)n[t[0]]=t[1];
      this.holiday = n;
    });
  }
  open() {
    this.holiday.currency=this.sql.Currency;
    this.holiday.enabled=true;
    this.get_holiday();
  }
  ngOnInit() {
    this.open();
  }
}