import { NewSettings, PlaceHolders } from '../../../../models/settings';
import { Component, OnInit,Input,  ViewChild, HostListener,Output,EventEmitter } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { SQLService } from '../../../../services/sql.service';
import { page,fade,fade_in,display } from '../../../../app.animations';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { MenuController } from '@ionic/angular';
@Component({
  selector: 'app-intro-page-4',
  templateUrl: './4-fourth.page.html',
  styleUrls: ['./4-fourth.page.styl'],
})
export class IntroFourthPageComponent implements OnInit {
  @Input('save') save:boolean
  @Output('complete') complete = new EventEmitter();
  @ViewChild('intro_slider') slider: IonSlides;
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
  }
  constructor(
    public menu: MenuController,
    public sql: SQLService,
    public router: Router) { 
    this.router.events.subscribe((val) => {
      // 
    });
  }

  ngOnInit() {}
/**/
}