import { environment } from '../../../../environments/environment';
import { FTPSettingsComponent } from '../../ftp-settings/ftp-settings.component';
import { SQLService } from '../../../services/sql.service';
import { NewImport } from '../../../models/import';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
@Component({
  selector: 'ng-cheques-new-export',
  templateUrl: './new-export.component.html',
  styleUrls: ['./new-export.component.styl']
})
export class NewExportComponent implements OnInit {
  constructor(
    public sql: SQLService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    public modalController: ModalController,
  ) { }
  _c = -1;
  currency = null;
  import = new NewImport();
  currencies = Object.keys(environment.currencies);
  file: any;
  xlsx_export() {
    console.log('xlsx_export')
  }
  json_export() {
    this.sql.download();
  }
  sql_export() {
    this.sql.export();
  }
  navigate(url) { 
    this.router.navigate(['folder','Settings','Import',url])
  }
  save(end=true) {
    this.sql.add("Imports",this.import);
    this.import = new NewImport();
    this._c=0;
    if(end)this.end();
  }
  end() {
    if (this._c>0) {
      if(confirm(this.sql.word.Discard_Confirmation)) {
        this.import = new NewImport();
        this.router.navigate(['folder','Imports']);
      }
    } else { 
      this.router.navigate(['folder','Imports']);
    }
  }

  async add_ftp() {
    const m = await this.modalController.create({component: FTPSettingsComponent, componentProps: this.import.ftp});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data)this.sql.hapticsImpactMedium();
    if(a.data){
      this.import.ftp = a.data;
    }
    this.sql.remove_temporary('page');
  }
  async edit_ftp() {
    const m = await this.modalController.create({component: FTPSettingsComponent, componentProps: this.import.ftp});
    await m.present();
  }
  delete_ftp() {
    this.import.ftp = '';
  }
  get_import() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida) return;
    this.sql.view('Imports','import_id',ida,(e)=>{
      var f=Object.entries(e[0]); let n = new NewImport();
      for(let t of f)n[t[0]]=t[1];
      this.import = n;
    });
  }
  import_() { this.router.navigate(['folder','Import','New']); }
  ngOnInit() {
    this.get_import();
  }
}