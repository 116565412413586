import { SQLService } from '../../../services/sql.service';
import { PopoverController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'ng-cheques-documents-menu',
  templateUrl: './documents-menu.component.html',
  styleUrls: ['./documents-menu.component.styl']
})
export class DocumentsMenuComponent implements OnInit {
  constructor(
    public sql: SQLService,
    public popover: PopoverController,
    private router: Router) {
  }
  list:string;
  dismissPopover(e) {
    if (this.popover) this.popover.dismiss(e);
  }
  import() {
    this.router.navigate(['folder','Import','New']);
    this.dismissPopover('import');
  }
  import_xls() {
    this.router.navigate(['folder','Import','New','xls']);
    this.dismissPopover('import_xls');
  }
  import_csv() {
    this.router.navigate(['folder','Import','New','csv']);
    this.dismissPopover('import_csv');
  }
  import_json() {
    this.router.navigate(['folder','Import','New','csv']);
    this.dismissPopover('import_csv');
  }
  import_sql() {
    this.router.navigate(['folder','Import','New','sql']);
    this.dismissPopover('import_sql');
  }
  import_uri() {
    this.router.navigate(['folder','Import','New','uri']);
    this.dismissPopover('import_uri');
  }
  administration() {
    this.router.navigate(['folder','Administrators']);
    this.dismissPopover('administration');
  }
  privacy() {
    this.router.navigate(['folder','Privacy']);
    this.dismissPopover('privacy');
  }
  settings() {
    this.router.navigate(['folder','Settings']);
    this.dismissPopover('settings');
  }
  logout() {
    this.router.navigate(['folder','Splash']);
    this.dismissPopover('logout');
  }
  ngOnInit() {
  }
}