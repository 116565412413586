export class NewTimesheetEntry {
	constructor(
		public timesheet_entry_id?: number,
		public timesheet_id?: number,
		public admin_id?: number,
		public mod_id?: number,
		public employee_id?: number,
		public payee_id?: number,
		public title?: string,
		public description?: string,
		public timer_active?: string,
		public start_date?: any,
		public start_time?: any,
		public end_date?: any,
		public end_time?: any,
		public timer?: any,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_TimesheetEntry = '\
	CREATE TABLE IF NOT EXISTS "TimesheetEntry" (\
		timesheet_entry_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		timesheet_id INTEGER DEFAULT 0,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		employee_id INTEGER DEFAULT 0,\
		payee_id INTEGER DEFAULT 0,\
		title TEXT,\
		description TEXT,\
		timer_active INTEGER DEFAULT 0,\
		start_date TIMESTAMP,\
		start_time TIMESTAMP,\
		end_date TIMESTAMP,\
		end_time TIMESTAMP,\
		timer TEXT,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';