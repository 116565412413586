export const logos = {
  bma:"data:image/png;base64,I=",
  bmo:"data:image/png;base64,==",
  bny:"data:image/png;base64,CC",
  capitalone:"data:image/png;base64,CC",
  cibc:"data:image/png;base64,k=",
  citi:"data:image/png;base64,==",
  citizens:"data:image/png;base64,I=",
  fifty:"data:image/png;base64,==",
  goldman:"data:image/png;base64,==",
  hsbc:"data:image/png;base64,k=",
  jp:"data:image/png;base64,I=",
  nbc:"data:image/png;base64,I=",
  pnc:"data:image/png;base64,CC",
  rbc:"data:image/png;base64,I=",
  scotia:"data:image/png;base64,CC",
  statestreet:"data:image/png;base64,==",
  td:"data:image/png;base64,I=",
  truist:"data:image/png;base64,/Z",
  usbank:"data:image/png;base64,CC",
  wellsfargo:"data:image/png;base64,mCC"
}
export const backgrounds_lowrez = {
  aquacheckers:"data:image/png;base64,",
  bluecheckers:"data:image/png;base64,",
  cashierscheque:"data:image/png;base64,",
  goldencheckers:"data:image/png;base64,",
  golden:"data:image/png;base64,",
  goldenweave:"data:image/png;base64,",
  greencheckers:"data:image/png;base64,",
  green:"data:image/png;base64,",
  greensun:"data:image/png;base64,",
  greenwave:"data:image/png;base64,",
  red:"data:image/jpeg;base64,",
  statueofliberty:"data:image/png;base64,",
  yellow:"data:image/jpeg;base64,Z"
}
export const backgrounds = {
  cashierscheque:"data:image/png;base64,==",
  aquacheckers:"data:image/png;base64,==",
  bluecheckers:"data:image/png;base64,I=",
  golden:"data:image/png;base64,CC",
  goldencheckers:"data:image/png;base64,CC",
  goldenweave:"data:image/png;base64,==",
  green:"data:image/png;base64,I=",
  greensun:"data:image/png;base64,==",
  greenwave:"data:image/png;base64,I=",
  red:"data:image/png;base64,k=",
  statueofliberty:"data:image/png;base64,CC",
  yellow:"data:image/png;base64,k=",
}