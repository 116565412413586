import { NewPostage } from '../../../models/postage';
import { environment } from '../../../../environments/environment';
import { SelectAddressPage } from '../../../addresses/select-address/select-address.page';
import { SelectEmployeePage } from '../../../employees/select-employee/select-employee.page';
import { SQLService } from '../../../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { Location } from '@angular/common';

@Component({
  selector: 'app-select-postage',
  templateUrl: './select-postage.component.html',
  styleUrls: ['./select-postage.component.scss'],
})
export class SelectPostageComponent implements OnInit {
  constructor(
    private navCtrl: ModalController,
    public location: Location,
    public sql: SQLService,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_postage();
    });
  }
  count = -1; 
  postage = [];
  search:string;
  default_postage:string;
  filter(e) { this.search=e.target.value; }
  go(p) {this.router.navigate(['folder','Postage',p.postage_id]);}
  add() {this.router.navigate(['folder','Postage','New']);}
  end() {this.location.back()}
  get_postage(event?) {
    this.sql.fetch('Postage',(e)=>{
      this.postage=e;
      this.count=e.length;
      this.sql.get_setting('default_postage',(s)=>{
        if(!s) return;
        this.default_postage=s.option;
      });
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  select_none() {this.navCtrl.dismiss(-1);}
  edit(postage, item?) {
    this.router.navigate(['folder','Postage',postage.postage_id]);
  }
  default(postage, item?) {
    this.sql.save_setting('default_postage',postage.postage_id,(a)=>{
      this.get_postage();
    });
  }
  delete(postage, item) {
    if(confirm(this.sql.word.Delete_Confirmation)) {
      this.sql.delete('postage','postage_id',postage.postage_id);
      this.get_postage();
    } else { item.closeSlidingItems(); }
  }
  ngOnInit() {
    this.get_postage();
  }
}
