import { SQLService } from '../../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { PopoverController} from '@ionic/angular';
import { ModeratorMenuComponent } from './moderator-menu/moderator-menu.component';
import { ScanModeratorComponent } from './scan-moderator/scan-moderator.component';
import { NewQRModeratorComponent } from './new-qr-moderator/new-qr-moderator.component';
@Component({
  selector: 'ng-cheques-moderators',
  templateUrl: './moderators.component.html',
  styleUrls: ['./moderators.component.styl']
})
export class ModeratorsComponent implements OnInit {
  constructor(
    public sql: SQLService,
    private modalController: ModalController,
    public popoverController: PopoverController,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_moderators();
    });
  }
  count = -1; 
  mods = [];
  search:string;
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:ModeratorMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {enabled: item.enabled},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='disable')this.disable(item);
      if(data=='enable')this.enable(item);
      if(data=='delete')this.delete_moderator(item);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  
  filter(e) { this.search=e.target.value; }
  add() {this.router.navigate(['folder','Moderators','New']);}
  get_moderators(event?) {
    this.sql.fetch('Moderators',(e)=>{
      this.mods=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  edit_moderator(mod, item?) {
    this.router.navigate(['folder','Moderator',mod.mod_id]);
  }
  delete_moderator(mod, item?) {
    if(confirm(this.sql.word.Discard_Confirmation)) {
      this.sql.delete('Moderators','mod_id',mod.mod_id);
      this.get_moderators();
    } else { if(item)item.closeSlidingItems(); }
  }
  disable(mod,i?){
    this.sql.set('Moderators','mod_id',mod.mod_id,'enabled',0,(res)=>{
      mod.enabled = false;
      if(i)i.closeSlidingItems();
    });
  }
  enable(mod,i?){
    this.sql.set('Moderators','mod_id',mod.mod_id,'enabled',1,(res)=>{
      mod.enabled = true;
      if(i)i.closeSlidingItems();
    });
  }
  async scan() {
    const m = await this.modalController.create({component: ScanModeratorComponent, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data)return;
  }
  async add_qr() {
    const m = await this.modalController.create({component: NewQRModeratorComponent, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data)return;
  }
  ngOnInit() {
    this.get_moderators();
  }
}