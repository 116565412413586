import { SQLService } from '../../services/sql.service';
import { NewBonus } from '../../models/bonus';
import { environment } from '../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SelectEmployeePage } from '../../employees/select-employee/select-employee.page';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
@Component({
  selector: 'ng-cheques-new-bonus',
  templateUrl: './new-bonus.component.html',
  styleUrls: ['./new-bonus.component.styl']
})
export class NewBonusComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public modalController: ModalController,
    public sql: SQLService
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd)this.open();
    });
  }
  _c = 0;
  bonus = new NewBonus();
  currencies = Object.keys(environment.currencies);
  end() {
    if (this._c > 0) {
      if (confirm(this.sql.word.Delete_Confirmation)) {
        this.bonus = new NewBonus();
        this.router.navigate(['folder','Bonuses']);
      }
    } else {
      this.router.navigate(['folder','Bonuses']);
    }
  }
  save() {
    this.sql.add('Bonuses',this.bonus,(insertId)=>{
      if(!insertId) return;
      this.bonus = new NewBonus();
      this._c=0;
      this.end();
    });
  }
  delete_bonus() {
    if(!this.bonus.bonus_id){
      this.end();
    } else if(confirm(this.sql.word.Delete_Bonus_Confirmation)) {
      this.sql.delete('Bonuses','bonus_id',this.bonus.bonus_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  async add_employee() {
    const m = await this.modalController.create({component: SelectEmployeePage, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1){
        this.delete_employee();
        this.sql.hapticsImpactLight();
      } else {
        this._c=1;
        var employeeid = a.data;
        if(employeeid){
          this.sql.view('Employees','employee_id',employeeid,(a)=>{
            if(a){
              this.bonus.employees=a;
              this.bonus.employee_name= a[0].name;
              this.bonus.employee_id = a[0].employee_id;
            }
          });
        }
        this.sql.hapticsImpactMedium();
      }
      this.sql.remove_temporary('page');
    }
  }
  edit_employee(employee) {
    this.router.navigate(['folder','Employee', employee.employee_id]); 
  }
  delete_employee() {
    this._c=1;
    this.bonus.employee_id = -1;
    this.bonus.employees = [];
    // this.sql.set( 'Cheques', 'cheque_id', this.bonus.cheque_id, 'employees', null);
  }
  open() {
    this.bonus.start_date=new Date(Date.now()).toISOString();;
    this.bonus.enabled=true;
    this.bonus.always_enabled=true;
    this.bonus.currency=this.sql.Currency;
    this.get_bonus();
  }
  get_bonus() {
    let ida = this.route.snapshot.paramMap.get("id");
    if(!ida) return;
    this.sql.view('Bonuses','bonus_id',ida,(e)=>{
      var f=Object.entries(e[0]);let n=new NewBonus();
      for(let t of f)n[t[0]]=t[1];
      this.bonus=n;
      if(this.bonus.employee_id)this.sql.view('Employees','employee_id',this.bonus.employee_id,(a)=>{
        if(a){
          this.bonus.employees=a;
          this.bonus.employee_name= a[0].name;
          this.bonus.employee_id = a[0].employee_id;
        }
      });
    });
  }
  ngOnInit() {
    this.open();
  }
}