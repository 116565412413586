import { SQLService } from '../../../services/sql.service';
import { Flip } from '../../../app.animations';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute ,NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController, NavParams } from '@ionic/angular';
@Component({
  selector: 'app-select-administrators',
  templateUrl: './select-administrator.page.html',
  styleUrls: ['./select-administrator.page.styl'],
  animations: [ Flip ]
})
export class SelectAdministratorPage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    public sql: SQLService,
    private location: Location,
    private params: NavParams
  ) {
    var s = params.get('selected');
    if(s)this.selected = s.split(',');
    this.multiple = params.get('select_multiple');
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_administrators();
    });
  }
  multiple:boolean;
  count:number;
  admins = [];
  selected = [];
  output = new EventEmitter();
  @Input('set_table') table:string;
  @Input('set_model') model:string;
  @Input('set_model_id') id:string;
  ngOnInit() {
    this.get_administrators();
    this.id = this.route.snapshot.paramMap.get("id");
    this.table = this.route.snapshot.paramMap.get("table");
    this.model = this.route.snapshot.paramMap.get("model");
  }
  get_administrators(event?) {
    this.sql.fetch('Administrators',(e)=>{
      this.admins=e;
      this.count=e.length; 
      setTimeout(()=>{if (event) event.target.complete();},1234);
    });
  }
  select_none() {this.navCtrl.dismiss(-1);}
  select(e) { 
    if ( this.id ) {
      let a = 'admin';
      this.sql.query('UPDATE '+this.table+' '+( this.id?'WHERE '+this.model+' = '+this.id:'' )+' SET '+a+'_id = '+e[a+'_id']);
      this.location.back();
    } else {
      this.navCtrl.dismiss(e);
    }
  }
  add() {
    this.router.navigate(['folder','Administrator','New']);
    this.navCtrl.dismiss();
  }
  off() {
    this.navCtrl.dismiss();
  }
}