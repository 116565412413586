import { SQLService } from '../../../services/sql.service';
import { Flip } from '../../../app.animations';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-select-moderator',
  templateUrl: './select-moderator.page.html',
  styleUrls: ['./select-moderator.page.styl'],
  animations: [ Flip ]
})
export class SelectModeratorPage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: ModalController,
    public sql: SQLService,
    private location: Location
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_moderators();
    });
  }
  count: number;
  multiple: boolean;
  selected = [];
  moderators = [];
  output = new EventEmitter();
  @Input('set_table') table:string;
  @Input('set_model') model:string;
  @Input('set_model_id') id:string;
  ngOnInit() {
    this.get_moderators();
    this.id = this.route.snapshot.paramMap.get("id");
    this.table = this.route.snapshot.paramMap.get("table");
    this.model = this.route.snapshot.paramMap.get("model");
  }
  get_moderators(event?) {
    this.sql.fetch('Moderators',(e)=>{
      this.moderators=e;
      this.count=e.count;
      setTimeout(()=>{if (event) event.target.complete();},1234);
    });
  }
  select_none() {this.navCtrl.dismiss(-1);}
  select(e) { 
    if ( this.id ) {
      let a = 'mod';
      this.sql.query('UPDATE '+this.table+' '+( this.id?'WHERE '+this.model+' = '+this.id:'' )+' SET '+a+'_id = '+e[a+'_id']);
      this.location.back();
    } else {
      this.navCtrl.dismiss(e);
    }
  }
  add() {
    this.router.navigate(['folder','Moderator','New']);
    this.navCtrl.dismiss();
  }
  off() {
    this.navCtrl.dismiss();
  }
}