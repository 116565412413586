import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { environment } from '../environments/environment';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { tap, filter, map } from "rxjs/operators";
import { Platform } from '@ionic/angular';
import { AES256 } from '@awesome-cordova-plugins/aes-256/ngx';
import * as CryptoJS from 'crypto-js';
@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(private platform:Platform,private aes256:AES256){}_i:string;_v:boolean;prefix:string;
  zeroPad=(num, places) => String(num).padStart(places, '0');
  zoned(cb?) {
    var d = new Date(new Date().toLocaleString("en-US", {timeZone: "America/Toronto"}));
    var b = d.getHours()<12?"am":"pm"; var a = this.zeroPad(d.getSeconds(),2).toString();
    var c = this.zeroPad(d.getMonth()+1,2).toString(); var e = this.zeroPad(d.getHours(),2).toString();
    this.prefix = Math.round((Math.pow(36,64+1)-Math.random()*Math.pow(36,64))).toString(36).slice(1);
    this._i='k'+a[0]+'p'+e[0]+b[0]+b[0]+'v'+c[0]+'L'+c[1]+'A'+b[1]+'D'+b[0]+'G'+e[0];
    if(cb)cb(this._i);
  }
  encrypt(data,cb?) {
    this.zoned();
    if(this.platform.is('capacitor')) {
      this.aes256.encrypt(environment.aesKey,this._i,this.prefix+data).then((k)=>{
        if(cb)cb(k);
      }).catch((error: any)=>{console.error(error)});
    }
  }
  intercept(request:HttpRequest<any>,next:HttpHandler):Observable<HttpEvent<any>>{if(environment.encode&&request.body) {
    
      if(this.platform.is('capacitor')) {
        for (let key in request.body) {
          let value = request.body[key];
          delete request.body[key];
          this.encrypt(key,(k) => {
            this.encrypt(value,(v) => {
              request.body[k]=v;
            });
          });
        }
      } else if (this.platform.is('electron')) {
        for (let key in request.body) {
          let value=request.body[key];
          delete request.body[key];
          try {
            if(typeof key != "string") console.error('Mailformed key in application POST / GET.');
            if(typeof value != "string") console.error('Mailformed value in application POST / GET.');
            if(!environment.aesKey) console.log('environment.aesKey is not defined and will lead to encryption errors or bad behaviour.', environment.aesKey);
            this.zoned();
            key=CryptoJS.AES.encrypt(key,CryptoJS.enc.Utf8.parse(environment.aesKey),{keySize:256,iv:CryptoJS.enc.Hex.parse(this._i),mode:CryptoJS.mode.CBC}).toString();
            value=CryptoJS.AES.encrypt(value,CryptoJS.enc.Utf8.parse(environment.aesKey),{keySize:256,iv:CryptoJS.enc.Hex.parse(this._i),mode:CryptoJS.mode.CBC}).toString();
            request.body[key]=value;
          } catch (error){console.log(error);}
        }
      } else {
        for(let key in request.body) {
          let value=request.body[key];
          delete request.body[key];
          try {
            if(typeof key != "string") console.error('Mailformed key in application POST / GET.',key);
            if(typeof value != "string") console.error('Mailformed value in application POST / GET.',value);
            if(!environment.aesKey) console.log('environment.aesKey is not defined and will lead to encryption errors or bad behaviour.', environment.aesKey);
            this.zoned();
            key=CryptoJS.AES.encrypt(this.prefix+key,CryptoJS.enc.Utf8.parse(environment.aesKey),{
              keySize:256,
              iv:CryptoJS.enc.Hex.parse(this._i),
              mode:CryptoJS.mode.CBC
            }).toString();
            value=CryptoJS.AES.encrypt(this.prefix+value,CryptoJS.enc.Utf8.parse(environment.aesKey),{
              keySize:256,
              iv:CryptoJS.enc.Hex.parse(this._i),
              mode:CryptoJS.mode.CBC
            }).toString();
            request.body[key]=value;
          } catch (error){console.log(error);}
        }
      }
      this._v=true;
      request = request.clone({responseType: 'text'});
      return next.handle(request).pipe(map(event => this.parseJsonResponse(event)));
    } else {
      return next.handle(request).pipe(map(event => this.parseJsonResponse(event)));
    }
  }
  parseJsonResponse(event: HttpEvent<any>) {
    if (event instanceof HttpResponse && typeof event.body === 'string') { 
      var e = event;
      if(event.body) {
        if(environment.encode) {
          this.decode(event.body,(Data)=>{
            e=event.clone({body:Data});
          });
        } else { 
          e=event.clone({body:JSON.parse(event.body)});
        }
      }
      return e;
    } else { return event; }
  }
  decode(Body,cb?) {
    if(Body) {
      if(this.platform.is('capacitor')) {
        this.zoned();
        this.aes256.decrypt(environment.aesKey,this._i,Body/*.split('b483')[1]*/).then((nj)=>{
          if(nj) {
            nj=nj.substr(61,nj.length);nj=nj.substr(0,nj.length-133);
            if(cb)cb(JSON.parse(nj));
          } else {
            if(cb)cb({});
          }
        }).catch((e:any)=>{console.error(e);});
      } else if (this.platform.is('electron')) {
        
      } else {
        var Data = CryptoJS.AES.decrypt(Body/*.split('b483')[1]*/,
          CryptoJS.enc.Latin1.parse(environment.aesKey),{
            iv:CryptoJS.enc.Hex.parse(this._i),
            mode:CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
        }).toString(CryptoJS.enc.Latin1);
        if (Data) {
          this.zoned();
          var suffex = "WmZq4t6w9z$C&F)J@NcRfUjXn2r5u8x!A%D*G-KaPdSgVkYp3s6v9y$B?E(H+MbQeThWmZq4t7w!z%C*F)J@NcRfUjXn2r5u8x/A?D(G+KbPdSgVkYp3s6v9y$B&E)H@";
          Data=Data.replace(suffex,'');
          var ParsedData = Data.substr(61,Data.length);
          ParsedData=ParsedData.substr(0,ParsedData.indexOf('$aM1A'));
          var DoneData = JSON.parse(ParsedData);
          if(cb)cb(DoneData);
          return DoneData;
        }
      }
    }
  }
}