import { SQLService } from '../../services/sql.service';
import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { PopoverController} from '@ionic/angular';
import { Router,NavigationEnd } from '@angular/router';
import { CompanyMenuComponent } from './company-menu/company-menu.component';
@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.styl'],
})
export class CompaniesComponent implements OnInit {
 constructor(
    public sql: SQLService,
    public popoverController: PopoverController,
    public location: Location,
    private router: Router) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.get_companies();
    });
  }
  count = -1;
  companies = [];
  search: string;
  showing_popover:boolean;lpevent:any;
  _md(e){this.lpevent=e;}
  async popover(item?) {
    if(this.showing_popover)return;
    this.showing_popover=true;
    this.sql.hapticsImpactMedium();
    var popover = await this.popoverController.create({
      component:CompanyMenuComponent,
      cssClass: 'popover_container',
      translucent: true,
      componentProps: {selected: (item.company_id==this.sql.selected_company)?true:false},
      event: this.lpevent?this.lpevent:false
    });
    popover.present();
    var { data } = await popover.onWillDismiss();
    if (data && item) {
      this.sql.hapticsImpactLight();
      if(data=='select')this.select(item);
      if(data=='delete')this.delete(item);
    }
    this.showing_popover=false;
    this.lpevent=null;
  }
  filter(e) { this.search=e.target.value; }
  end() {this.location.back()}
  get_companies(event?) {
    this.sql.b_fetch('Companies',(e)=>{
      this.companies=e;
      this.count=e.length;
      setTimeout(()=>{
        if (event) event.target.complete();
      },1234);
    });
  }
  edit(company, item?) {
    this.router.navigate(['folder','Company',company.company_id]);
    if(item)item.closeSlidingItems();
  }
  select(company,item?) {
    this.sql.change_default_company(company.company_id,()=>{
      this.get_companies();
      if(item)item.closeSlidingItems();
    });
  }
  delete(company, item?) {
    if(company.company_id==1){
      alert(this.sql.word.Cant_Delete_Default_Company_Word);
      // if(confirm(this.sql.word.Delete_Default_Company_Word)) {
      //   this.sql.reset(false,(z)=>{
      //     setTimeout(()=>{this.get_companies();},765);
      //     if(item)item.closeSlidingItems();
      //   });
      // }
    } else {
      this.sql.reset(false,()=>{
        this.sql.b_delete('Companies','company_id',company.company_id,()=>{
          this.sql.change_default_company(1);
          this.get_companies();
          if(item)item.closeSlidingItems();
        });
        // setTimeout(()=>{this.sql.flush(company.company_id);},3000);  
      },this.sql.word.Delete_Company_Confirmation);
    }
  }
  add() {
    this.router.navigate(['folder','Company','New']);
  }
  ngOnInit() {
    if(!this.sql.account)this.end();
    this.get_companies();
  }
}
