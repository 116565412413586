import { PaperSizes } from '../../../../../environments/papersizes';
import { Printer } from '../../../../../../pdfmake/printer/ngx';
import { NewPrinter } from '../../../../models/printers';
import { Component, OnInit, Input, ViewChild, HostListener,Output,EventEmitter } from '@angular/core';
import { ColorPickerComponent } from '../../../../cheque/designs/color-picker/color-picker.component';
import { FontPickerComponent } from '../../../../cheque/designs/font-picker/font-picker.component';
import { environment } from '../../../../../environments/environment';
import { SQLService } from '../../../../services/sql.service';
import { page,fade,fade_in,display } from '../../../../app.animations';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { MenuController } from '@ionic/angular';
import { ModalController, Platform } from '@ionic/angular';
import { Location } from '@angular/common';
@Component({
  selector: 'app-intro-page-2',
  templateUrl: './2-second.page.html',
  styleUrls: ['./2-second.page.styl'],
})
export class IntroSecondPageComponent implements OnInit {
  @Input('save') _save:boolean
  @Output('complete') complete = new EventEmitter();
  @ViewChild('intro_slider') slider: IonSlides;
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
  }
    constructor(
      private router: Router,
      private route: ActivatedRoute,
      public sql: SQLService,
      private print: Printer,
      private location: Location,
      public modalController: ModalController
    ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) {
        this.open();
      }
    });
  }
  _c=0;
  count=1;
  advanced:boolean;
  default:boolean;
  papers = [];
  printer = new NewPrinter();
  toggle_default() {this.default=this.default?false:true;}
  select() {
    this.print.pick().then((picker)=>{
      var p=picker.split('://');
      var s=p[1].split('.');
      if(!this.printer.title)this.printer.title=s[0];
      this.printer.printer=picker;
    }, (err) =>{
      console.error('PRINTER PICKER UI ERROR');
    });
  }
  end() {
    if (this._c>0) {
      if(confirm(this.sql.word.Discard_Printer_Confirmation)) {
        this.printer = new NewPrinter();
        this.location.back();
      }
    } else {this.location.back();}
  }
  add_uri(p="ipp://"){if(p==""||p==null)p="ipp://";
    if(this.printer.printer=prompt('Add the Hostname or IP address for your printer.',p)) {
    }else{this.printer.printer=this.printer.printer;}
  }
  save() {
    this.sql.fetchOne("Printers WHERE title = '"+this.printer.title+"'",(c)=>{
      if(this.printer.title!=c){
        this.sql.add("Printers",this.printer,(e)=>{
          if(this.default){this.sql.save_setting('default_printer',this.printer.printer_id);}
          this.printer=new NewPrinter();
          this._c=0;
          this.end();
        });    
      } else { alert(this.sql.word.Printer_Exists); }
    });
  }
  update(){this.sql.update("Printers",this.printer);}
  get_printers() {
    this.sql.count('Printers',(c)=>{this.default=c==0?true:false;});
    var ida = this.route.snapshot.paramMap.get("id");
    if(!ida)return;
    this.sql.view('Printers','printer_id',ida,(e)=>{
      if(!e[0]) return false;
      var f=Object.entries(e[0]);var n=new NewPrinter();
      for(var t of f)n[t[0]]=t[1];this.printer=n;
      this.sql.get_setting('default_printer',(s)=>{
        this.default=(s&&this.printer.printer_id==s.option)?true:false;
      });
    });
  }
  open() {
    this.papers=PaperSizes;
    this.printer.protocol='airprint';
    this.printer.enabled=true;
    this.get_printers();
  }
  ngOnInit() {this.open();}
  async select_header_color() {
    const m = await this.modalController.create({component: ColorPickerComponent, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data){
      this._c=1;
      this.printer.header_label_font_color = a.data;
    }
  }
  async select_header_font() {
    const m = await this.modalController.create({component: FontPickerComponent, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data){
      this._c=1;
      this.printer.header_label_font = a.data;
    }
  }
  async select_footer_color() {
    const m = await this.modalController.create({component: ColorPickerComponent, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data){
      this._c=1;
      this.printer.footer_label_font_color = a.data;
    }
  }
  async select_footer_font() {
    const m = await this.modalController.create({component: FontPickerComponent, componentProps: {}});
    await m.present();
    let a = await m.onDidDismiss();
    if(a.data){
      this._c=1;
      this.printer.footer_label_font = a.data;
    }
  }
}