import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'purchased'
})
export class PurchasedPipe implements PipeTransform {
  transform(value: any, args?: any): any {
	  if ( args && args!=null && args!='') {
			let values = [];
			for (var i = 0; i < value.length; i++) {
				if(value[i].purchased==args) values.push(value[i]);
			}
			return values;
		} else {
			return value;
		}
  }
}
