export class NewPayee {
	constructor(
		public payee_id?: any,
		public admin_id?: number,
		public mod_id?: number,
		public title?: string,
		public name?: string,
		public email?: string,
		public fax?: string,
		public phone?: string,
		public skype?: string,
		public description?: string,
		public addresses?: any,
		public address_ids?: any,
		public address_id?: any,
		public timesheets?: any,
		public schedules?: any,
		public schedule_ids?: any,
		public salary_ids?: any,
		public salaries?: any,
		public deductions?: any,
		public leaves?: any,
		public vacations?: any,
		public holidays?: any,
		public taxes?: any,
		public address?: any,
		public payments?: any,
		public currency?: any,
		public verified?: boolean,
		public enabled?: boolean,
		public yearly_totals?: string,
		public printed_memo?: string,
		public modified?: any,
		public created?: any
	) {}
}
export const Create_Payees = '\
	CREATE TABLE IF NOT EXISTS "Payees" (\
		payee_id INTEGER PRIMARY KEY AUTOINCREMENT,\
		admin_id INTEGER DEFAULT 0,\
		mod_id INTEGER DEFAULT 0,\
		address_id INTEGER DEFAULT 0,\
		schedule_id TEXT,\
		timesheet_id TEXT,\
		title TEXT,\
		name TEXT,\
		email TEXT,\
		fax TEXT,\
		phone TEXT,\
		skype TEXT,\
		description TEXT,\
		addresses TEXT,\
		address_ids TEXT,\
		timesheets TEXT,\
		schedules TEXT,\
		schedule_ids TEXT,\
		salary_ids TEXT,\
		salaries TEXT,\
		deductions TEXT,\
		leaves TEXT,\
		vacations TEXT,\
		holidays TEXT,\
		taxes TEXT,\
		address TEXT,\
		payments TEXT,\
		currency TEXT,\
		verified INTEGER DEFAULT 0,\
		enabled INTEGER DEFAULT 0,\
		yearly_totals TEXT,\
		printed_memo TEXT,\
		modified TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\
		created TIMESTAMP DEFAULT CURRENT_TIMESTAMP\
);';