import { environment } from '../../../environments/environment';
import { SelectEmployeePage } from '../../employees/select-employee/select-employee.page';
import { SelectPayeePage } from '../../payees/select-payee/select-payee.page';
import { NewVacation } from '../../models/vacation';
import { SQLService } from '../../services/sql.service';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';

@Component({
  selector: 'ng-cheques-new-vacation',
  templateUrl: './new-vacation.component.html',
  styleUrls: ['./new-vacation.component.styl']
})
export class NewVacationPage implements OnInit {
  constructor(
    private router: Router,
    public modalController: ModalController,
    private route: ActivatedRoute,
    private location: Location,
    public sql: SQLService
  ) {
    this.router.events.subscribe((eve) => {
      if(eve instanceof NavigationEnd) this.open();
    });
  }
  _c=0;
  currencies = Object.keys(environment.currencies);
  vacation = new NewVacation();
  async add_employee() {
    this.set_temp();
    const m = await this.modalController.create({component: SelectEmployeePage, componentProps: {select_multiple: true}});
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data){
      if(a.data==-1){
        this.delete_employee();
        this.sql.hapticsImpactLight();
      } else {
        this.vacation.employee_ids = a.data;
        this.vacation.employee_id = a.data;
        this.get_employees();
        this._c=1;
        this.sql.hapticsImpactMedium();
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_employee(employee) {
    this.router.navigate(['folder','Employee', employee.employee_id]); 
  }
  delete_employee() {
    this.vacation.employees = null;
    this.vacation.employee_ids=null;
    // this.sql.set( 'Payrolls', 'vacation_id', this.vacation.vacation_id, 'employees', null);
  }
  get_employees() {
    if(!this.vacation.employee_ids)return;
    if(typeof this.vacation.employee_ids === 'string'){
      this.vacation.employee_ids=this.vacation.employee_ids.split(',');
    } else if (typeof this.vacation.employee_ids === 'number') {
      this.vacation.employee_ids=[this.vacation.employee_ids];
    }
    this.vacation.employees=[];
    for(let a of this.vacation.employee_ids) {
      this.sql.view('Employees','employee_id',a,(data)=>{
        for(let d of data)this.vacation.employees.push(d);
      });
    }
  }
  async add_payee() {
    this.set_temp();
    const m = await this.modalController.create({
      component: SelectPayeePage, 
      componentProps: {select_multiple: true}
    });
    await m.present();
    var a = await m.onDidDismiss();
    if(a.data) {
      if(a.data==-1){
        this.delete_payee();
        this.sql.hapticsImpactLight();  
      } else {
        this.vacation.payee_id = a.data;
        this.vacation.payee_ids = [a.data];
        this.get_payees();
        this._c=1;
        this.sql.hapticsImpactMedium();  
      }
    }
    this.sql.remove_temporary('page');
  }
  edit_payee(payee) {
    this.router.navigate(['folder','Payee', payee.payee_id]); 
  }
  delete_payee() {
    this.vacation.payees   =null;
    this.vacation.payee_ids=null;
    // this.sql.set( 'Payrolls', 'vacation_id', this.vacation.vacation_id, 'payees', null);
  }
  get_payees() {
    if(!this.vacation.payee_ids)return;
    if(typeof this.vacation.payee_ids === 'string'){
      this.vacation.payee_ids=this.vacation.payee_ids.split(',');
    } else if (typeof this.vacation.payee_ids === 'number') {
      this.vacation.payee_ids=[this.vacation.payee_ids];
    }
    this.vacation.payees=[];
    for(let a of this.vacation.payee_ids) {
      this.sql.view('Payees','payee_id',a,(data)=>{
        for(let d of data)this.vacation.payees.push(d);
      });
    }
  }
  set_temp() {
    if (this.vacation.vacation_id) {
      this.sql.set_temporary('page',{location:'Vacation',id:this.vacation.vacation_id});
    } else {
      this.save(false,(id) =>{
        if(!id)return;
        this.vacation.vacation_id=id;
        this.sql.set_temporary('page',{location:'Vacation',id:id});
      });
    }
  }
  end() {
    if (this._c>0) {
      if (confirm(this.sql.word.Discard_Confirmation)) {
        this.vacation = new NewVacation();
        this.router.navigate(['folder','Vacations']);
      }
    } else {
      this.router.navigate(['folder','Vacations']);
    }
  }
  get_vacation() {
    let ida = this.route.snapshot.paramMap.get("id");
    if (!ida) return;
    this.sql.view('Vacations','vacation_id',ida,(e)=>{
      var f = Object.entries(e[0]); let n =new NewVacation();
      for( let t of f) n[t[0] ]=t[1]; this.vacation = n;
      this.get_employees();
      this.get_payees();
    });
  }
  save(end=true,cb?) {
    this.sql.add("Vacations",this.vacation,(id) => {
      if(!id)return;
      this._c=0;
      if(cb){
        cb(id);
      } else {
        if(end){
          this.end();
          this.vacation=new NewVacation();
          this.open();  
        }
      }
    });
  }
  delete_vacation() {
    if(!this.vacation.vacation_id){
      this.end();
    } else if(confirm(this.sql.word.Delete_Vacation_Confirmation)) {
      this.sql.delete('Vacations','vacation_id',this.vacation.vacation_id,()=>{
        this._c=0;
        this.end();
      });
    }
  }
  open() {
    var d = new Date(Date.now()).toISOString();
    this.vacation.start_date = d;
    var date = new Date();
    var start_time = date.setHours(9,0,0);
    this.vacation.start_time = new Date(start_time).toISOString();
    var date = new Date();
    var end_time = date.setHours(17,0,0);
    this.vacation.end_time = new Date(end_time).toISOString();
    this.vacation.paytype='percentage';
    this.vacation.currency=this.sql.Currency;
    this.vacation.paid=false;
    this.vacation.enabled=true;
    this.get_vacation();
  }
  ngOnInit() {
    // this.open();
  }
}